// Importar React y los componentes necesarios
import React, { useState, useEffect } from "react";
import { faEllipsis, faUserPlus, faMicrophone, faGrip, faHand, faPhoneFlip, faDeleteLeft, faMicrophoneSlash, faHandshakeSimpleSlash, faArrowRightArrowLeft, faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DropdownMenu, ButtonDropdown, DropdownToggle, DropdownItem } from 'reactstrap';
import call from "../../logic/call";
import CallTimer from "./CallerTimer"
import { UserAgent } from "sip.js"
import "./OnCall.css"
// Definir el componente TransferScreen
const OnCall = ({ handleBye, sessionIncall, unHoldCall, holdCall, sipconfiguration, setCallSessions, callSessions, setSessionIncall, handleAnswerOnInvite, agent, setCallSessionsFunctionEnd, handleByeOnInvite, conferenseSession,setConferenceSession,setCloseSettings,callAudio,setCallAudio }) => {
    // Definir el estado del número de teléfono
    const [phoneNumber, setPhoneNumber] = useState("3006006602");
    const [number, setNumber] = useState('');
    const [onCallOpen, setOnCallOpen] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [addNumber, setAddNumber] = useState(true);
    const [transferNumber, setTransferNumber] = useState(false);
    const [parkingCall, setParkingCall] = useState(false);

    const [mute, setMute] = useState(false)
    const [callDuration, setCallDuration] = useState(0);
    const [holdButton, setHoldButton] = useState(false)
    // const [callAudio] = useState(new Audio());
    const Numbers = [
        { number: "1", text: "/" },
        { number: "2", text: "A B C" },
        { number: "3", text: "D E F" },
        { number: "4", text: "G H I" },
        { number: "5", text: "J K L" },
        { number: "6", text: "M N O" },
        { number: "7", text: "P Q R S" },
        { number: "8", text: "T U V" },
        { number: "9", text: "W X Y Z" },
        { number: "*", text: " " },
        { number: "0", text: " " },
        { number: "#", text: " " },
    ];
    // Definir la función que se ejecuta al presionar una tecla
    const handleKeyPress = (key) => {
        // Añadir el símbolo al número de teléfono
        setNumber(number + key);
        console.log(addNumber);
        console.log(transferNumber);
        if(!addNumber && !transferNumber){
            sendDTMF(key)

        }
    };

    // Definir la función que se ejecuta al presionar el botón de transferir
    const handleGreenButton = () => {
        if (addNumber) {
            console.log("add number")
            setConferenceSession(true)
            call(number, sipconfiguration, setCallSessions, agent, callSessions, callAudio, sessionIncall, handleAnswerOnInvite, setCallSessionsFunctionEnd, true)
        } else {
            if (transferNumber) {
                if (sessionIncall.length > 0) {
                    let target = UserAgent.makeURI(
                        `sip:${number}@${sipconfiguration.realm}`
                    );
                    transferNumber.refer(target);
                    transferNumber.transfered = true
                    setTimeout(() => {
                        setCallSessionsFunctionEnd(transferNumber)
                    }, 5000);
                }
            } else {

                call(number, sipconfiguration, setCallSessions, agent, callSessions, callAudio, sessionIncall, handleAnswerOnInvite, setCallSessionsFunctionEnd)
                console.log("transfer number")
            }
        }
        // Llamar a la función que realiza la transferencia de llamada
        // con el número de teléfono introducido
        // transferCall(phoneNumber);
    };

    // Definir la función que se ejecuta al presionar el botón de cancelar
    const handleCancel = () => {
        setOnCallOpen(false);
        setAddNumber(true)
        setTransferNumber(false)
        setParkingCall(false)
    };

    const handleNumberDelete = () => {
        if (number.length > 0) {
            // Elimina el último carácter utilizando slice
            const newNumber = number.slice(0, -1);
            setNumber(newNumber);
        }
    };

    const toggle = () => {
        setDropdownOpen(!dropdownOpen)
    }



    useEffect(() => {
        const updateCallDuration = () => {
            // Calcula la diferencia entre Date.now() y sessionIncall[0].CallTimer
            const startTime = sessionIncall[0].CallTimer; // Asegúrate de que sessionIncall[0].CallTimer sea un timestamp válido
            const currentTime = Date.now();
            const differenceInSeconds = Math.floor((currentTime - startTime) / 1000);
            // Actualiza el estado de la duración de la llamada
            setCallDuration(differenceInSeconds);
        };

        // Actualiza la duración de la llamada cada segundo
        const intervalId = setInterval(updateCallDuration, 1000);

        // Limpia el intervalo cuando el componente se desmonta
        return () => clearInterval(intervalId);
    }, [sessionIncall]);



    const formatTime = (timeInSeconds) => {
        const minutes = Math.floor(timeInSeconds / 60).toString().padStart(2, "0");
        const seconds = (timeInSeconds % 60).toString().padStart(2, "0");
        return `${minutes}:${seconds}`;
    };

    const holdButtonActions = () => {
        setHoldButton(!holdButton)
        if (!sessionIncall[sessionIncall.length - 1].onHold) {
            sessionIncall.forEach(el => {
                holdCall(el)
            })
        } else {
            unHoldCall(sessionIncall[sessionIncall.length - 1])

        }
    }

    const toggleMute = () => {
        setMute(!mute)
        if (!mute) {
            muteCall()
        } else {
            unmuteCall()
        }
    }

    function muteCall() {
        sessionIncall.forEach(el => {
            el.sessionDescriptionHandler.peerConnection
                .getSenders()
                .forEach(function (receiver) {
                    console.log(receiver)
                    if (receiver.track != null) {
                        receiver.track.enabled = false;
                    }
                });
        })

    }

    function unmuteCall() {
        sessionIncall.forEach(el => {
            el.sessionDescriptionHandler.peerConnection
                .getSenders()
                .forEach(function (receiver) {
                    console.log(receiver)
                    if (receiver.track != null) {
                        receiver.track.enabled = true;
                    }
                });
        })
    }

    function sendDTMF(DTMFNumber) {
        if (sessionIncall.length != 0) {
            const options = {
                requestOptions: {
                    body: {
                        contentDisposition: "render",
                        contentType: "application/dtmf-relay",
                        content:
                            "Signal=" + DTMFNumber + "\r\nDuration=1000",
                    },
                },
            };
            sessionIncall.forEach((element) => {
                element.info(options);
            });
        }
    }

    const handleTransfer = (element) => {
        setTransferNumber(element);
        setOnCallOpen(true)
        setAddNumber(false)
    }

    const handleParkingCall = (element) => {
        setParkingCall(true)
        if (sessionIncall.length > 0) {
            let target = UserAgent.makeURI(
                `sip:70@${sipconfiguration.realm}`
            );
            element.refer(target);
            element.parked = true
            setTimeout(() => {
                setCallSessionsFunctionEnd(element)
            }, 5000);
        }
    }


    const handleByeOnSwitch = (index) => {
        sessionIncall.forEach((element, i) => {
            if (i === index) {
                element.onHold = true
                holdCall(element)
            } else {
                element.onHold = false
                unHoldCall(element)
            }
        })
    }



    // Retornar el elemento JSX que representa la pantalla de transferencia
    return (
        <div className={`oncall ${!onCallOpen ? "oncall-close" : "oncall-open"}`}>
            <div className="oncall-header">
                <div className="oncall-top-data"><div>{conferenseSession && sessionIncall.length > 1 ? "Conference": "On Call"} <span className="oncall-span">{formatTime(callDuration)}</span>
                </div>
                    {/* <div id="Popover1" className="oncall-ellipsis" onClick={() => setPopoverOpen(!popoverOpen)}>
                        <FontAwesomeIcon icon={faEllipsis} />
                    </div> */}
                    <ButtonDropdown isOpen={dropdownOpen} toggle={toggle}>
                        <DropdownToggle className="onCall-dropdown">
                            <FontAwesomeIcon icon={faEllipsis} />
                        </DropdownToggle>
                        <DropdownMenu>
                            {sessionIncall.map((element, i) => (
                                <DropdownItem onClick={() => handleTransfer(element)} key={i}>{"Transfer " + element.remoteIdentity.uri.normal.user}</DropdownItem>
                            ))}
                            {sessionIncall.map((element, i) => (
                                <DropdownItem onClick={() => handleParkingCall(element)} key={i}>{"Park call " + element.remoteIdentity.uri.normal.user}</DropdownItem>
                            ))}
                            {onCallOpen && <DropdownItem onClick={() => { handleCancel() }}>Minimize</DropdownItem>}
                            <DropdownItem onClick={()=>{setCloseSettings(false)}}>{"Config"}</DropdownItem>
                        </DropdownMenu>
                    </ButtonDropdown>
                </div>
            </div>
            {sessionIncall.map((element, index) => (
                <div className="oncall-call-info" key={index}><div>{element.remoteIdentity.uri.normal.user}<span className="oncall-span">{element.parked ? "Parked" : element.transfered ? "Transfered" : element.onHold ? "On Hold" : element.CallTimer ? "Connected" : "Connecting"}</span>
                    <FontAwesomeIcon className="oncall-button-bye-call rotate" icon={faPhoneFlip} onClick={() => { handleByeOnInvite(element) }} />
                    {!element.onHold && sessionIncall.length > 1 && <FontAwesomeIcon className="oncall-button-bye-call" icon={faArrowRightArrowLeft} onClick={() => { handleByeOnSwitch(index) }} />}

                </div>
                </div>
            ))}
            {conferenseSession && sessionIncall.length > 1 && <div className="onConference">
                <FontAwesomeIcon icon={faUsers} onClick={() => { }} />

            </div>}



            {sessionIncall.length === 1 && <>
                {<div className="oncall-call-info"><div><FontAwesomeIcon icon={faUserPlus} /> <span className="oncall-span" onClick={() => { setAddNumber(true); setOnCallOpen(true) }}>Add Call</span>
                </div>
                </div>
                }</>}


            {!onCallOpen && <div className="oncall-buttons">
                <div className="oncall-buttons-item oncall-buttons-item-mic" onClick={toggleMute}>{mute ? <FontAwesomeIcon icon={faMicrophoneSlash} /> : <FontAwesomeIcon icon={faMicrophone} />}</div>

                <div className="oncall-buttons-item oncall-buttons-item-grip" onClick={() => { setOnCallOpen(!onCallOpen); setAddNumber(false) }}><FontAwesomeIcon icon={faGrip} /></div>

                <div className="oncall-buttons-item oncall-buttons-item-hand" onClick={holdButtonActions}><FontAwesomeIcon icon={sessionIncall[sessionIncall.length - 1].onHold ? faHandshakeSimpleSlash : faHand} /></div>

                <div className="oncall-buttons-item oncall-buttons-item-bye" onClick={handleBye}><FontAwesomeIcon className="oncall-buttons-item-bye" icon={faPhoneFlip} /></div>

            </div>}
            {onCallOpen && <div className="oncall-number">
                <span className="oncall-number-span">{number}</span>
                <div className="oncall-number-delete" >
                    <FontAwesomeIcon icon={faDeleteLeft} onClick={handleNumberDelete} />
                </div>

            </div>}
            {(onCallOpen) && <>   <div className="oncall-keypad">
                {Numbers.map(element => (
                    <div className="oncall-button-keyboard" onClick={() => handleKeyPress(element.number)}>{element.number}</div>
                ))}

            </div>
                <div className="buttons">
                    <div className="oncall-buttons-actions oncall-transfer" onClick={handleGreenButton}>
                        {addNumber ? "Add" : transferNumber ? "Transfer" : "Call"}
                    </div>
                    <div className="oncall-buttons-actions oncall-cancel" onClick={handleCancel}>
                        Cancel
                    </div>
                </div></>}
        </div>
    );
};

export default OnCall
