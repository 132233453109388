import axios from "axios";
import json from "../config.json";

export default function petitionGet(key, value) {
  const urlBase = json.prod ? json.urlBase.prod : json.urlBase.dev;
  const id = JSON.parse(localStorage.getItem("userDetails"))?.api_id;
  let token = "";
  if (key === "refresh") {
    token = value;
  } else {
    token = JSON.parse(localStorage.getItem("userDetails"))?.access_token;
  }
  var url;

  if (token) {
    switch (key) {
      case "allServices":
        url = urlBase + "crm/services";
        break;
      case "refresh":
        url = urlBase + "/crm/auth/refresh";
        break;
      case "contacts":
        url =
          urlBase +
          `/360/accounts/${id}/my-account/contacts${value.parameter || ""}`;
        break;
      case "contactList":
        url = urlBase + `/360/accounts/${id}/my-account/contact-lists`;
        break;
      case "tags":
        url = urlBase + `/360/accounts/${id}/my-account/tags`;
        break;
      case "customFields":
        url = urlBase + `/360/accounts/${id}/my-account/contacts/custom-fields`;
        break;
      case "users":
        url = urlBase + `/itpvoice/v2/${id}/my-extension/users`;
        break;
      case "userDevices":
        url = urlBase + `/itpvoice/v2/${id}/my-extension/devices`;
        break;
      case "userDetails":
        url = urlBase + `/itpvoice/v2/${id}/my-extension`;
        break;
      case "contactNotes":
        url =
          urlBase +
          `/360/accounts/${id}/my-account/contact/notes${
            value.parameter || ""
          }`;
        break;
      case "verify":
        url =
          `${urlBase ? urlBase : "https://api.itpscorp.com/dev/portal"}` +
          `/crm/auth/verify`;
        break;
      case "importJobs":
        url = urlBase + `/360/accounts/${id}/my-account/import-jobs`;
        break;
      case "importJobsErrors":
        url =
          urlBase +
          `/360/accounts/${id}/my-account/import-jobs/${value.jobs_id}`;
        break;
      case "leads":
        url =
          urlBase +
          `/360/accounts/${id}/my-account/leads${value.parameter || ""}`;
        break;
      case "sources":
        url =
          urlBase + `/360/accounts/${id}/leads/sources${value.parameter || ""}`;
        break;
      case "funnels":
        url =
          urlBase +
          `/360/accounts/${id}/my-account/funnels${value.parameter || ""}`;
        break;
      case "stages":
        url =
          urlBase +
          `/360/accounts/${id}/my-account/funnels/stages${
            value.parameter || ""
          }`;
        break;
      case "stagesAdmin":
        url = urlBase + `/360/accounts/${id}/funnels/${value.funnel_id}/stages`;
        break;
      case "leadsInFunnel":
        url =
          urlBase + `/360/accounts/${id}/my-account/funnels/${value.funnel_id}`;
        break;
      case "dashboardFunnel":
        url =
          urlBase +
          `/360/accounts/${id}/funnels/${value.funnel_id}/dashboard${
            value.parameter || ""
          }`;
        break;
      case "assignedNumbers":
        url = urlBase + `/itpvoice/v2/${id}/my-extension/chat/numbers`;
        break;
      case "listSms":
        url =
          urlBase +
          `/itpvoice/v2/${id}/my-extension/chat/sms/${value.number}${
            value?.parameter || ""
          }`;
        break;
      case "messageThread":
        url =
          urlBase +
          `/itpvoice/v2/${id}/my-extension/chat/sms/${value.number}/${
            value.thread
          }${value.parameter || ""}`;
        break;
      case "voicemails":
        url =
          urlBase +
          `/itpvoice/v2/${id}/my-extension/user-voice-messages${
            value.parameter || ""
          }`;
        break;
      case "voicemailDetails":
        url =
          urlBase +
          `/itpvoice/v2/${id}/my-extension/voicemail-messages${
            value.parameter || ""
          }`;
        break;
      case "imagesThread":
        url =
          urlBase +
          `/itpvoice/v2/${id}/my-extension/chat/sms/${value.number}/${value.thread}/images`;
        break;
      case "operationPanel":
        url = urlBase + `/itpvoice/v2/${id}/my-extension/operator-panel`;
        break;
      case "numbers":
        url = urlBase + `/itpvoice/v2/${id}/my-extension/numbers`;
        break;
      case "intercom":
        url =
          urlBase +
          `/itpvoice/v2/${id}/my-extension/callflow?type=UserIntercom`;
        break;
      case "accountDetails":
        url = urlBase + `/itpvoice/v2/${id}`;
        break;
      case "musicOnHold":
        url = urlBase + `/itpvoice/v2/${id}/media${value?.parameter || ""}`;
        break;
      case "apiKeys":
        url = urlBase + `/itpvoice/v2/${id}/apikeys${value.parameter || ""}`;
        break;
      case "webhooks":
        url = urlBase + `/itpvoice/v2/${id}/webhooks`;
        break;
      case "brands":
        url = urlBase + `/itpvoice/v2/${id}/brands?limit=99999`;
        break;
      case "campaign":
        url = urlBase + `/itpvoice/v2/${id}/csp-campaign`;
        break;
      case "campaignDetails":
        url = urlBase + `/itpvoice/v2/${id}/csp-campaign/${value.campaing_id}`;
        break;
      case "allCallflows":
        url = urlBase + `/itpvoice/v2/${id}/callflows?paginate=false`;
        break;
      case "viewNumbers":
        url = urlBase + `/itpvoice/v2/${id}/numbers?pagination=false`;
        break;
      case "viewNumberDetails":
        url = urlBase + `/itpvoice/v2/${id}/numbers/${value.phoneNumber}`;
        break;
      case "cities":
        url =
          urlBase +
          `/itpvoice/v2/${id}/bandwidth/rate-centers?state=${value.stateCode}`;
        break;
      case "services":
        url = urlBase + "/crm/services";
        break;
      case "orderNumbers":
        if (value.type === "NPA") {
          url =
            urlBase +
            `/itpvoice/v2/${id}/bandwidth/search?npa=${value.code}&quantity=100`;
        } else if (value.type === "City/State") {
          url =
            urlBase +
            `/itpvoice/v2/${id}/bandwidth/search?state=${value.code.state}&rate_center=${value.code.city}&quantity=100`;
        } else if (value.type === "NPANXX") {
          url =
            urlBase +
            `/itpvoice/v2/${id}/bandwidth/search?npanxx=${
              value.code.npa + value.code.nxx
            }&quantity=100`;
        }
        break;
      case "prefix":
        let newValue = value.code.substr(0, 2);

        newValue = newValue + "*";

        url =
          urlBase +
          `/itpvoice/v2/itp_voice/bandwidth/search?toll_free_wildcard=${newValue}&quantity=100`;
        break;
      case "callflowsDetails":
        url = urlBase + `/itpvoice/v2/${id}/callflows/${value.callflow}`;
        break;
      case "cities2":
        url = urlBase + `/crm/misc/locations/cities?us_state_id=${value}`;
        break;
      case "estates":
        url = urlBase + `/crm/misc/locations/states`;
        break;
      case "portRequests":
        url = urlBase + `/itpvoice/v2/${id}/port-requests`;
        break;
      case "timeGroups":
        url = urlBase + `/itpvoice/v2/${id}/time-groups`;
        break;
      case "timeRules":
        url =
          urlBase + `/itpvoice/v2/${id}/time-rules${value?.parameter || ""}`;
        break;
      case "queues":
        url = urlBase + `/itpvoice/v2/${id}/queues`;
        break;
      case "musicOnHoldMedia":
        url = urlBase + `/itpvoice/v2/${id}/media?pagination=false`;
        break;
      case "voicemail-v2":
        url = urlBase + `/itpvoice/v2/${id}/voicemails`;
        break;
      case "itpvoiceUsers":
        url = urlBase + `/itpvoice/v2/${id}/users`;
        break;
      case "switches":
        url = urlBase + `/itpvoice/v2/${id}/switches`;
        break;
      case "devicesAdmin":
        url = urlBase + `/itpvoice/v2/${id}/devices?owner_id=${value.owner_id}`;
        break;
      case "userDetailsAdmin":
        url = urlBase + `/itpvoice/v2/${id}/users/${value.user_id}`;
        break;
      case "intercomAdmin":
        url =
          urlBase +
          `/itpvoice/v2/${id}/callflows?type=UserIntercom&owner_id=${value.user_id}`;
        break;
      case "queueMembers":
        url = urlBase + `/itpvoice/v2/${id}/queues/${value.queue_id}/members`;
        break;
      case "messageTemplates":
        url = urlBase + `/360/accounts/${id}/message-templates`;
        break;
      case "customFieldAdmin":
        url = urlBase + `/360/accounts/${id}/contacts/custom-fields`;
        break;
      case "blockedCalls":
        url = urlBase + `/itpvoice/v2/${id}/blocked-calls`;
        break;
      case "allCountries":
        url = urlBase + `/itpvoice/v2/${id}/countries`;
        break;
      case "blockedCountries":
        url = urlBase + `/itpvoice/v2/${id}/blocked-countries`;
        break;
      case "mediaGroups":
        url = urlBase + `/itpvoice/v2/${id}/media-groups`;
        break;
      case "operationPanelGroups":
        url = urlBase + `/itpvoice/v2/${id}/operator-panel/groups`;
        break;
      case "operationPanelGroupsUsers":
        url =
          urlBase +
          `/itpvoice/v2/${id}/operator-panel/groups/${value.group_id}/users`;
        break;
      case "operationPanelGroupsQueues":
        url =
          urlBase +
          `/itpvoice/v2/${id}/operator-panel/groups/${value.group_id}/queues`;
        break;
      case "operationPanelGroupsPermissions":
        url =
          urlBase +
          `/itpvoice/v2/${id}/operator-panel/groups/${value.group_id}/permissions`;
        break;
      case "voicemailNumber":
        url =
          urlBase + `/itpvoice/v2/${id}/numbers/${value.number}/voice-messages`;
        break;
      case "sipRegistration":
        url = urlBase + `/itpvoice/v2/${id}/troubleshooting/registrations`;
        break;
      case "dashboard":
        url = urlBase + `/itpvoice/v2/${id}/my-extension/dashboard`;
        break;
      default:
        break;
    }

    var config = {
      headers: {
        Authorization: "Bearer " + token.replace(/['"]+/g, ""),
      },
    };

    return axios.get(url, config);
  } else {
    window.location.href = "/login";
  }
}
