export const timeZones = [
  { label: "US/Alaska GMT-9:00", value: "US/Alaska" },
  { label: "US/Arizona GMT-7:00", value: "US/Arizona" },
  { label: "US/Central GMT-6:00", value: "US/Central" },
  { label: "US/Eastern GMT-5:00", value: "US/Eastern" },
  { label: "US/Hawaii GMT-10:00", value: "US/Hawaii" },
  { label: "US/Indiana-Starke GMT-6:00", value: "US/Indiana-Starke" },
  { label: "US/Michigan GMT-5:00", value: "US/Michigan" },
  { label: "US/Mountain GMT-7:00", value: "US/Mountain" },
  { label: "US/Pacific GMT-8:00", value: "US/Pacific" },
  { label: "US/Samoa GMT-11:00", value: "US/Samoa" },
  { label: "Asia/Hong_Kong GMT+8:00", value: "Asia/Hong_Kong" },
  { label: "Europe/Berlin GMT+1:00", value: "Europe/Berlin" },
  { label: "Europe/Madrid GMT+1:00", value: "Europe/Madrid" },
  { label: "Europe/Rome GMT+1:00", value: "Europe/Rome" },
];
