import { call, put, takeEvery } from "redux-saga/effects";
import { loginService } from "../../services/login.service";

function* fetchLogin(action) {
  try {
    const login = yield call(loginService, action.data);
    delete login.expireDate;
    yield put({ type: "POST_LOGIN_SUCCESS", login: login });
    if (login.access_token) {
      window.location.href = window.location.origin + "/Dashboard";
    }
  } catch (e) {
    yield put({ type: "POST_LOGIN_FAILED", message: e.message });
  }
}

function* loginSaga() {
  yield takeEvery("POST_LOGIN_REQUESTED", fetchLogin);
}

export default loginSaga;
