import React from "react";

const CalendarIntegration = () => {
  return (
    <>
      <h1>Calendar Integration</h1>
    </>
  );
};

export default CalendarIntegration;
