import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Col, Row } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useMenuDispatch } from "../../../../contexts/MenuContext.js";
import {
  CustomTable,
  Loading,
  LoadingButton,
  MenuRight,
} from "../../../../components/index.js";
import Switch from "react-switch";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import UpdateIcon from "@mui/icons-material/Update";
import arrowleft from "../../../../assets/icons/arrow-left.svg";
import AddNumber from "./AddNumber/AddNumber.js";
import TransferNumber from "./TransferNumber/TransferNumber.js";
import EditNumber from "./EditNumber/EditNumber.js";
import ModalDelete from "../ModalUtility/ModalDelete.js";
import petitionGet from "../../../../services/petitionGet.js";
import petitionPatch from "../../../../services/petitionPatch.js";
import petitionDelete from "../../../../services/petitionDelete.js";
import "./PhoneNumbers.css";

const PhoneNumbers = () => {
  const myExtension = useSelector((state) => state.myExtension.userDetails);

  const navigate = useNavigate();
  const dispatch = useMenuDispatch();

  const [loading, setLoading] = useState(true);
  const [numbers, setNumbers] = useState([]);
  const [dataTableNumbers, setDataTableNumbers] = useState(null);
  const [modalDelete, setModalDelete] = useState(false);
  const [info, setInfo] = useState(null);
  const [textModalDelete, setTextModalDelete] = useState("");
  const [disabledSaveButton, setDisabledSaveButton] = useState(false);
  const [loadingNumber, setLoadingNumber] = useState(false);
  const [openTransferNumber, setOpenTransferNumber] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [dataTablePortRequest, setDataTablePortRequest] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [form, setForm] = useState({});
  const [infoPortRequest, setInfoPortRequest] = useState(null);
  const [loadingPortRequest, setLoadingPortRequest] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [firstLoad, setFirstLoad] = useState(true);

  const [addNumber, setAddNumber] = useState(false);
  const [stepAddNumber, setStepAddNumber] = useState(0);
  const [listOfNumbers, setListOfNumbers] = useState(null);
  const [errorNumbers, setErrorNumbers] = useState(null);
  const [selectedNumbers, setSelectedNumbers] = useState([]);
  const [resetActive, setResetActive] = useState(false);
  const [pricePerDid, setPricePerDid] = useState(null);
  const [checkbox, setCheckBox] = useState(false);
  const [errorChecked, setErrorChecked] = useState(null);

  const [filterNumbers, setFilterNumbers] = useState({
    country: "selected",
    category: "selected",
    type: "",
  });

  const [prefix, setPrefix] = useState({
    code: "selected",
  });

  const [npa, setNpa] = useState({
    code: "",
  });

  const [cityState, setCityState] = useState({
    state: "",
    city: "",
  });

  const [npaNxx, setNpaNxx] = useState({
    npa: "",
    nxx: "",
  });

  const resetAll = () => {
    setFilterNumbers({
      country: "selected",
      category: "",
      type: "",
    });
    setCityState({
      state: "",
      city: "",
    });
    setNpa({
      code: "",
    });
    setNpaNxx({
      npa: "",
      nxx: "",
    });

    setStepAddNumber(0)
    setErrorChecked(false)
    setCheckBox(false)
    setPricePerDid(null)
    setResetActive(false)
    setErrorNumbers(null)
    setListOfNumbers([])  
    setListOfNumbers(null);
    setSelectedNumbers([]);
    setResetActive(false);
  };

  const resetStep = () => {
    setStepAddNumber(0);
  };

  const activeAddNewNumber = () => {
    setAddNumber(true);
    setOpenMenu(true);
  };

  const handleBackClick = () => {
    if (!openTransferNumber && !openEdit) {
      dispatch({ type: "SET_DEFAULT_MENU" });
      navigate("/Dashboard");
    } else {
      reloadData();
    }
  };

  const closeModal = () => {
    setAddNumber(false);
    setInfoPortRequest(null);
    setOpenMenu(false);
    setOpenEdit(false);
    setTextModalDelete("");
    setDisabledSaveButton(false);
    setInfo(null);
    setModalDelete(false);
    setOpenTransferNumber(false);
  };

  const activeUpdatePortRequest = (e) => {
    setInfoPortRequest(e);
  };

  const activeEditNumber = (e) => {
    setInfo(e);
    setOpenEdit(true);
  };

  const activeModalDelete = (e) => {
    setInfo(e);
    setModalDelete(true);
  };

  const activeTransferNumber = () => {
    setOpenTransferNumber(true);
  };

  const reloadData = () => {
    resetAll()
    closeModal();
    petition();
  };

  const searchNumber = (value) => {
    setSearchInput(value);
  
    let numbersTemp = numbers;
  
    if (value !== "") {
      numbersTemp = numbersTemp.filter((element) => {
        // Check if element.number and element.description are not null or undefined
        const number = element.number || "";
        const description = element.description || "";
  
        return (
          number.toLowerCase().includes(value.toLowerCase()) ||
          description.toLowerCase().includes(value.toLowerCase())
        );
      });
  
      setDataTableNumbers({
        ...dataTableNumbers,
        content: [...numbersTemp],
      });
    } else {
      setDataTableNumbers({
        ...dataTableNumbers,
        content: [...numbers],
      });
    }
  };

  const deleteNumber = async () => {
    setLoadingNumber(true);
    petitionDelete("number", { phone: info.number })
      .then(({ data: result }) => {
        setLoadingNumber(false);
        reloadData();
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 400) {
          setLoadingNumber(false);
          setDisabledSaveButton(true);
          setTextModalDelete(error.response.data.error.error_message);
        }
      });
  };

  const submitData = () => {
    if (form.porting_billing_name && form.porting_billing_name.length > 25)
      return;

    if (form.porting_zip_code && form.porting_zip_code.length > 5) return;

    setLoadingPortRequest(true);
    petitionPatch("orderItemUpdate", {
      order_item_id: infoPortRequest.pk,
      data: { metadata: form },
    })
      .then((result) => {
        setLoadingPortRequest(false);
        reloadData();
      })
      .catch((error) => console.log(error));
  };

  const petitionPortRequest = async () => {
    await petitionGet("portRequests")
      .then(({ data: result }) => {
        result.result.forEach((element) => {
          element.tableNumber = element.metadata.did;
          element.porting_actual_foc_date =
            element?.porting_actual_foc_date || "";
        });

        setDataTablePortRequest({
          title: [
            { title: "Number", key: "tableNumber" },
            { title: "Status", key: "provision_status", noInput: true },
            {
              title: "Porting Status",
              key: "provision_result",
              style: { whiteSpace: "normal" },
            },
            { title: "FOC Date", key: "porting_actual_foc_date" },
          ],
          content: [...result.result],
          actions: {
            title: "Actions",
            content: [
              {
                name: "Update",
                icon: <UpdateIcon />,
                handleClick: activeUpdatePortRequest,
              },
            ],
          },
        });
      })
      .catch((error) => console.log(error));
  };

  const petition = async () => {
    setLoading(true);

    await petitionPortRequest();

    await petitionGet("viewNumbers")
      .then(({ data: result }) => {
        result.result.forEach((element) => {
          element.description = element?.description || ""

          element.statusReal = element.itpvoice_metadata
            ? element.itpvoice_metadata.state.replace("_", " ")
            : "null";
        });

        setNumbers(result.result);
        setDataTableNumbers({
          title: [
            { title: "Number", key: "number" },
            { title: "Status Number", key: "status" },
            {
              title: "Description",
              key: "description",
            },
          ],
          content: [...result.result],
          actions: {
            title: "Actions",
            content: [
              {
                icon: <FontAwesomeIcon icon={faPenToSquare} />,
                name: "Edit",
                handleClick: activeEditNumber,
              },
              {
                icon: <FontAwesomeIcon icon={faTrash} />,
                name: "Delete",
                handleClick: activeModalDelete,
              },
            ],
          },
          addButton: {
            label: "Add Number",
            handleClick: activeAddNewNumber,
          },
        });
        setFirstLoad(false);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if (infoPortRequest) {
      setForm(infoPortRequest?.metadata);
      setOpenMenu(true);
    }
  }, [infoPortRequest]);

  useEffect(() => {
    if (
      Object.keys(myExtension).length > 0 &&
      myExtension.priv_level !== "admin"
    ) {
      handleBackClick();
    } else if (
      Object.keys(myExtension).length > 0 &&
      firstLoad &&
      myExtension.priv_level === "admin"
    ) {
      petition();
    }
  }, [myExtension]);

  return (
    <>
      {stepAddNumber <= 1 && (
        <div onClick={handleBackClick} className="itp-tex-back-container">
          <div className="itp-text-container-funnel-details">
            <img src={arrowleft} alt="arrow-left" />
            <span className="itp-text-back-funnel-details">
              {!openTransferNumber && !openEdit
                ? "Back to Dashboard"
                : "Back to table"}
            </span>
          </div>
        </div>
      )}

      {loading ? (
        <Loading />
      ) : (
        <>
          {modalDelete && (
            <ModalDelete
              modal={modalDelete}
              closeModal={closeModal}
              loading={loadingNumber}
              modalFunction={deleteNumber}
              module="Number"
              text={textModalDelete}
              disabledSaveButton={disabledSaveButton}
            />
          )}

          {!openTransferNumber &&
            !openEdit &&
            stepAddNumber <= 1 && (
              <>
                <div id="numbers">
                  <div className="itp-table-container-centered overflow-hidden">
                    <Row>
                      <Col>
                        <div
                          className="text-align-with-table"
                          style={{ paddingLeft: "0px" }}
                        >
                          <span className="table-alt-text-title">
                            Phone Numbers
                          </span>
                          <br></br>
                          <span className="table-alt-text-title-description">
                            Manage all phone numbers in your workspace
                          </span>
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="phone-numbers-container-search-input d-flex">
                              <SearchIcon className="itp-custom-table-search-icon" />
                              <input
                                className="itp-custom-table-search-input"
                                placeholder="Search Number, description or assigned user"
                                value={searchInput}
                                onChange={(e) => searchNumber(e.target.value)}
                              />
                            </div>
                            <button
                              className="form-select-itp loading itp-phone-numbers-transfer-button"
                              onClick={activeTransferNumber}
                            >
                              <ArrowCircleRightOutlinedIcon
                                style={{
                                  color: "#437097",
                                  width: "20px",
                                  height: "20px",
                                }}
                              />
                              Transfer Number
                            </button>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <CustomTable
                    centered={true}
                    data={dataTableNumbers}
                    noPagBack={true}
                  />
                </div>
                <br></br>
                <div>
                  <Row>
                    <Col>
                      <div className="itp-user-settings-separator-line"></div>
                    </Col>
                  </Row>
                </div>
                <br></br>
                <div id="campaigns">
                  <div className="itp-table-container-centered overflow-hidden">
                    <Row>
                      <Col>
                        <div className="text-align-with-table">
                          <span className="table-alt-text-title"></span>
                          <br></br>

                          <span className="table-alt-text-title-description"></span>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <CustomTable
                    centered={true}
                    data={dataTablePortRequest}
                    noPagBack={true}
                  />
                </div>
              </>
            )}

          {openTransferNumber && (
            <>
              <TransferNumber />
            </>
          )}

          {openEdit && (
            <>
              <EditNumber
                info={info}
                setInfo={setInfo}
                activeModalDelete={activeModalDelete}
              />
            </>
          )}

          {stepAddNumber > 1 && (
            <AddNumber
            step={stepAddNumber}
            setStep={setStepAddNumber}
            closeModal={closeModal}
            activeAddNewNumber={activeAddNewNumber}
            listOfNumbers={listOfNumbers}
            setListOfNumbers={setListOfNumbers}
            errorNumbers={errorNumbers}
            setErrorNumbers={setErrorNumbers}
            selectedNumbers={selectedNumbers}
            setSelectedNumbers={setSelectedNumbers}
            resetActive={resetActive}
            setResetActive={setResetActive}
            pricePerDid={pricePerDid}
            setPricePerDid={setPricePerDid}
            filterNumbers={filterNumbers}
            setFilterNumbers={setFilterNumbers}
            prefix={prefix}
            setPrefix={setPrefix}
            npa={npa}
            setNpa={setNpa}
            cityState={cityState}
            setCityState={setCityState}
            npaNxx={npaNxx}
            setNpaNxx={setNpaNxx}
            checkbox={checkbox}
            setCheckBox={setCheckBox}
            errorChecked={errorChecked}
            setErrorChecked={setErrorChecked}
            resetAll={resetAll}
            reloadData={reloadData}
            />
          )}

          <MenuRight
            show={openMenu}
            closeMenu={stepAddNumber === 1 ? resetStep : closeModal}
            title={openMenu && !addNumber ? "Update" : "Add New Number"}
            to={stepAddNumber === 1 ? "to Step 1" : "to Phone Numbers"}
          >
            {openMenu && !addNumber && (
              <>
                {Object.keys(infoPortRequest.metadata).map((element) => (
                  <Row
                    key={element}
                    className={`itp-container-label-${
                      infoPortRequest.metadata[element] === true ||
                      infoPortRequest.metadata[element] === false
                        ? "select"
                        : "input"
                    }`}
                  >
                    <Col
                      xs={5}
                      sm={5}
                      md={5}
                      lg={5}
                      xl={5}
                      xxl={5}
                      className="itp-label-input-col"
                    >
                      <label className="itp-label-input__label">
                        {element}
                      </label>
                    </Col>
                    <Col
                      xs={7}
                      sm={7}
                      md={7}
                      lg={7}
                      xl={7}
                      xxl={7}
                      className="itp-label-input-col"
                    >
                      {infoPortRequest.metadata[element] === true ||
                      infoPortRequest.metadata[element] === false ? (
                        <>
                          <Switch
                            className="itp-custom-table-switch"
                            onColor="#626ed4"
                            height={20} // Ajusta la altura según tus necesidades
                            width={40}
                            onChange={() => {
                              setForm({
                                ...form,
                                [element]:
                                  form[element] === true ? false : true,
                              });
                            }}
                            checked={form[element]}
                          />
                        </>
                      ) : (
                        <input
                          type="text"
                          placeholder=""
                          className="form-control-itp itp-label-input__input"
                          onChange={(e) =>
                            setForm({
                              ...form,
                              [element]: e.target.value,
                            })
                          }
                          value={form[element] || ""}
                        />
                      )}
                    </Col>
                  </Row>
                ))}
                <button
                  className="btn-primary loading add-lead-new-contact"
                  onClick={submitData}
                >
                  {loadingPortRequest && <LoadingButton />}
                  Save
                </button>
              </>
            )}

            {openMenu && addNumber && (
              <AddNumber
                step={stepAddNumber}
                setStep={setStepAddNumber}
                closeModal={closeModal}
                activeAddNewNumber={activeAddNewNumber}
                listOfNumbers={listOfNumbers}
                setListOfNumbers={setListOfNumbers}
                errorNumbers={errorNumbers}
                setErrorNumbers={setErrorNumbers}
                selectedNumbers={selectedNumbers}
                setSelectedNumbers={setSelectedNumbers}
                resetActive={resetActive}
                setResetActive={setResetActive}
                pricePerDid={pricePerDid}
                setPricePerDid={setPricePerDid}
                filterNumbers={filterNumbers}
                setFilterNumbers={setFilterNumbers}
                prefix={prefix}
                setPrefix={setPrefix}
                npa={npa}
                setNpa={setNpa}
                cityState={cityState}
                setCityState={setCityState}
                npaNxx={npaNxx}
                setNpaNxx={setNpaNxx}
                checkbox={checkbox}
                setCheckBox={setCheckBox}
                errorChecked={errorChecked}
                setErrorChecked={setErrorChecked}
                resetAll={resetAll}
                reloadData={reloadData}
              />
            )}
          </MenuRight>
        </>
      )}
    </>
  );
};

export default PhoneNumbers;
