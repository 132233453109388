import React, { useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Row, Col, Dropdown, Card } from "react-bootstrap";
import { ToggleImage, Loading } from "../index";
import { formatDate } from "../../utils/getDate";
import useScreenSize from "../../hooks/useScreenSize"
import "./ColumnsOfDraggableCards.css";

const ColumnsOfDraggableCards = ({
  activeFunctionDetails,
  loading,
  dataColumns,
  setDataColumns,
  textNoData,
  dragEndFunction,
  actions,
  dragDisabled,
}) => {
  const { width } = useScreenSize();
  const [dropdownIndex, setDropdownIndex] = useState(null)

  const handleCardHover = (item) => {
    setDropdownIndex(item.id);
  };

  const handleCardLeave = () => {
    setDropdownIndex(null);
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;

    if (
      !destination ||
      (source.droppableId === destination.droppableId &&
        source.index === destination.index)
    ) {
      return;
    }

    const sourceColumn = dataColumns.columns[source.droppableId];
    const destColumn = dataColumns.columns[destination.droppableId];
    const sourceItems = [...sourceColumn.items];
    const destItems = [...destColumn.items];

    if (sourceColumn.id !== destColumn.id) {
      const [removed] = sourceItems.splice(source.index, 1);
      removed.newItem = true;
      destItems.splice(destination.index, 0, removed);

      const newData = {
        ...dataColumns,
        columns: {
          ...dataColumns.columns,
          [source.droppableId]: {
            ...sourceColumn,
            items: sourceItems,
          },
          [destination.droppableId]: {
            ...destColumn,
            items: destItems,
          },
        },
      };

      const column_id = dataColumns.columns[destColumn.id].info.pk;
      destItems.map((item, i) => {
        item.newPosition = i;
      });

      setDataColumns(newData);
      dragEndFunction(column_id, destItems);
    } else {
      const [removed] = sourceItems.splice(source.index, 1);
      sourceItems.splice(destination.index, 0, removed);
      const newData = {
        ...dataColumns,
        columns: {
          ...dataColumns.columns,
          [source.droppableId]: {
            ...sourceColumn,
            items: sourceItems,
          },
        },
      };

      const column_id = dataColumns.columns[destColumn.id].info.pk;

      sourceItems.map((item, i) => {
        item.newPosition = i;
      });
      setDataColumns(newData);
      dragEndFunction(column_id, sourceItems);
    }
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          {!Object.keys(dataColumns.columns).length ? (
            <Card>
              <Card.Body className="d-flex justify-content-center align-items-center">
                <h1>{textNoData}</h1>
              </Card.Body>
            </Card>
          ) : (
            <DragDropContext onDragEnd={onDragEnd}>
              <Row className="itp-dragdrop-container">
                {Object.keys(dataColumns.columns).map((columnId, i) => {
                  const column = dataColumns.columns[columnId];
                  const numberColumn = Object.keys(dataColumns.columns);

                  return (
                    <Droppable key={columnId} droppableId={columnId}>
                      {(provided) => (
                        <Col
                          {...provided.droppableProps}
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}
                          className={`itp-dragdrop-column-container${
                            i !== numberColumn.length - 1
                              ? " itp-dragdrop-space-columns"
                              : ""
                          }`}
                          xs={width < 462 ? 9 : 7}
                          sm={6}
                          md={width < 823 ? 5 : 4}
                          lg={width < 1077 ? 5 : 4}
                          xl={3}
                          xxl={3}
                        >
                          <Row className="d-flex justify-content-between">
                            <Col xs={12}>
                              <h4 className="itp-dragdrop-column-title">
                                {column.title}
                              </h4>
                              <div
                                className="d-flex align-items-center flex-wrap"
                              >
                                <span className="itp-drapdrop-subtitle mr-subtitle">
                                  {column.subtitle.split("&")[0]}
                                </span>
                                <span className="itp-drapdrop-subtitle">
                                  {column.subtitle.split("&")[1] || ""}
                                </span>
                              </div>
                            </Col>
                          </Row>
                          {column.items.map((item, index) => (
                            <Draggable
                              key={item.id}
                              draggableId={item.id}
                              index={index}
                              isDragDisabled={dragDisabled}
                            >
                              {(provided, snapshot) => (
                                <Row
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  ref={provided.innerRef}
                                  style={{
                                    marginBottom: "0.2rem",
                                    borderRadius: snapshot.isDragging ? "20px" : "",
                                    backgroundColor: snapshot.isDragging
                                      ? "rgba(20, 98, 181, 0.2)"
                                      : "transparent",
                                    ...provided.draggableProps.style,
                                  }}
                                >
                                  <Col>
                                    <Row
                                      className={`itp-dragdrop-row-card${
                                        index === column.items.length - 1
                                          ? " mb-dragdrop"
                                          : ""
                                      }`}
                                    >
                                      <Card
                                        onMouseLeave={handleCardLeave}
                                        onMouseEnter={() => handleCardHover(item)}
                                        onClick={() => {
                                          if(activeFunctionDetails){
                                            activeFunctionDetails(item);
                                          }                                         
                                        }}
                                        className={`itp-dragdrop-card-container${
                                          !item.smallLabel1 || !item.smallLabel2
                                            ? " h-card-drag"
                                            : ""
                                        }`}
                                      >
                                        <Card.Body className="itp-dragdrop-cardbody-container">
                                          <div style={{ marginTop: "8px" }}>
                                            <div className="d-flex align-items-center justify-content-between">
                                              <div className="d-flex align-items-center">
                                              {item.image && <>{item.image}</>}

                                              {item.title && (
                                                <p className="itp-dragdrop-cardbody-title">
                                                  {item.title}
                                                </p>
                                              )}
                                              </div>
                                                  <Dropdown style={{ visibility: item.actions && dropdownIndex === item.id ? "visible" : "hidden" }}>
                                                  <Dropdown.Toggle className="nav-link btn-secondary">
                                                    <ToggleImage />
                                                  </Dropdown.Toggle>
                                                  <Dropdown.Menu>
                                                    {item.actions.map((element, i) => (
                                                      <Dropdown.Item key={i} onClick={() => element.handleClick(item)}>
                                                        {element.icon}{" "}{element.name}
                                                      </Dropdown.Item>
                                                    ))}
                                                  </Dropdown.Menu>
                                                </Dropdown>                             
                                            </div>
                                            <div
                                              style={{ marginLeft: "2.15rem" }}
                                            >
                                              {item.subtitle && (
                                                <p className="itp-dragdrop-cardbody-subtitle">
                                                  {item.subtitle}
                                                </p>
                                              )}

                                              {item.description && (
                                                <p className="itp-dragdrop-cardbody-subtitle mt-1">
                                                  {item.description}
                                                </p>
                                              )}

                                              {item.labelFooter && (
                                                <p className="itp-dragdrop-cardbody-labelFooter mt-1">
                                                 {item.labelFooter}
                                                </p>                                               
                                              )}

                                              {item.tags && (
                                                <div className="d-flex flex-wrap">
                                                  {item.tags.map((element, indexTag) => (
                                                   <span className="itp-dragdrop-tag" key={indexTag}>
                                                     {element}
                                                   </span>             
                                                  ))}
                                                </div>
                                              )}

                                              {item.date && (
                                                <span className="itp-dragdrop-date">{formatDate(item.date)}</span>
                                              )}                                              
                                            </div>
                                          </div>
                                        </Card.Body>
                                      </Card>
                                    </Row>
                                  </Col>
                                </Row>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </Col>
                      )}
                    </Droppable>
                  );
                })}
              </Row>
            </DragDropContext>
          )}
        </>
      )}
    </>
  );
};

export default ColumnsOfDraggableCards;
