import React, { useState, useRef, useEffect } from "react";
import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import VolumeUpOutlinedIcon from "@mui/icons-material/VolumeUpOutlined";
import VolumeMuteOutlinedIcon from "@mui/icons-material/VolumeMuteOutlined";
import Slider from "@mui/material/Slider";
import profile from "../../assets/images/profile.png";
import WaveSurfer from "wavesurfer.js";
import UserAvatar from "../UserAvatar/UserAvatar";
import VoicemailIcon from "@mui/icons-material/Voicemail";
import "./AudioMessage.css";

const AudioMessage = ({
  audioUrl,
  duration,
  firstname,
  lastname,
  avatar,
  icon,
  hiddenTime,
  w100,
  noBorderContainer,
  outVolume,
  ml0,
  styles,
  noFetch,
  barHeight,
}) => {
  const audioRef = useRef(null);
  const progressBarRef = useRef(null);
  const waveformRef = useRef(null); // Referencia al elemento del canvas
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [dragOffset, setDragOffset] = useState(0);
  const [volume, setVolume] = useState(100);
  const [disabled, setDisabled] = useState(true);

  const togglePlay = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const updateTime = () => {
    if (!isDragging) {
      setCurrentTime(audioRef.current.currentTime);
    }
  };

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    return `${minutes < 10 ? "0" : ""}${minutes}:${
      seconds < 10 ? "0" : ""
    }${seconds}`;
  };

  const handleProgressBarClick = (e) => {
    // if (!isPlaying) {
    const progressBarRect = progressBarRef.current.getBoundingClientRect();
    const offsetX = e.clientX - progressBarRect.left;
    const seekTime = (offsetX / progressBarRect.width) * duration;
    setCurrentTime(seekTime);
    audioRef.current.currentTime = seekTime;
    // }
  };

  const handleMouseDown = (e) => {
    setIsDragging(true);
    const progressBarRect = progressBarRef.current.getBoundingClientRect();
    const offsetX = e.clientX - progressBarRect.left;
    setDragOffset(offsetX);
    // También actualiza la posición del icono redondo según el desplazamiento del mouse
    setCurrentTime((offsetX / progressBarRect.width) * duration);
  };

  const handleMouseMove = (e) => {
    if (isDragging) {
      const progressBarRect = progressBarRef.current.getBoundingClientRect();
      const offsetX = e.clientX - progressBarRect.left;
      const newPosition = Math.min(
        Math.max(offsetX - dragOffset, 0),
        progressBarRect.width
      );
      setCurrentTime((newPosition / progressBarRect.width) * duration);
    }
  };

  const handleMouseUp = () => {
    if (isDragging) {
      setIsDragging(false);
      updateTime(); // Update the current time after dragging
    }
  };

  const handleVolumeChange = (event, newValue) => {
    // Actualiza el estado del volumen
    setVolume(newValue);

    // Actualiza el atributo volume del elemento <audio> (rango: 0 a 1)
    audioRef.current.volume = newValue / 100;
  };

  const onClickVolumenIcon = () => {
    if (volume > 0) {
      setVolume(0);

      audioRef.current.volume = 0;
    } else if (volume === 0) {
      setVolume(100);

      audioRef.current.volume = 1;
    }
  };

  useEffect(() => {
    if (noFetch) {
      const waveSurfer = WaveSurfer.create({
        container: waveformRef.current,
        barWidth: 3, // Ajusta el ancho de cada elemento
        barHeight: barHeight || 0.4, // Ajusta el alto de cada elemento
        barGap: 1,
        waveColor: "rgba(0, 0, 0, 0.2)", // Color de la onda
        progressColor: "transparent", // Color de la parte progresada
        cursorWidth: 0, // Desactiva el cursor interno de WaveSurfer
        barRadius: 0, // Desactiva la curvatura de las barras
        // normalize: true, // Normaliza los valores de onda
      });

      // Carga el archivo de audio
      waveSurfer.load(audioUrl);

      // Escucha el evento de actualización del tiempo
      waveSurfer.on("audioprocess", (time) => {
        setCurrentTime(time);

        // Cambia el color de fondo de las barras según la posición del punto
        const progressBarElements =
          progressBarRef.current.querySelectorAll(".progress-bar");
        const currentPosition = (time / duration) * 100;

        progressBarElements.forEach((bar) => {
          if (parseFloat(bar.style.left) <= currentPosition) {
            bar.classList.add("active");
          } else {
            bar.classList.remove("active");
          }
        });
      });

      setDisabled(false);
      return () => {
        // Limpia al desmontar el componente
        waveSurfer.destroy();
      };
    } else {
      const checkAudioValidity = async () => {
        try {
          const response = await fetch(audioUrl);
          if (response.ok) {
            const contentType = response.headers.get("Content-Type");
  
            if (contentType && contentType.startsWith("audio/")) {
              const audioContext = new AudioContext();
              const audioBuffer = await fetch(audioUrl)
                .then((response) => response.arrayBuffer())
                .then((data) => audioContext.decodeAudioData(data));
  
              if (audioBuffer) {
                // Crea una instancia de WaveSurfer
                const waveSurfer = WaveSurfer.create({
                  container: waveformRef.current,
                  barWidth: 3, // Ajusta el ancho de cada elemento
                  barHeight: 0.4, // Ajusta el alto de cada elemento
                  barGap: 1,
                  waveColor: "rgba(0, 0, 0, 0.2)", // Color de la onda
                  progressColor: "transparent", // Color de la parte progresada
                  cursorWidth: 0, // Desactiva el cursor interno de WaveSurfer
                  barRadius: 0, // Desactiva la curvatura de las barras
                  // normalize: true, // Normaliza los valores de onda
                });
  
                // Carga el archivo de audio
                waveSurfer.load(audioUrl);
  
                // Escucha el evento de actualización del tiempo
                waveSurfer.on("audioprocess", (time) => {
                  setCurrentTime(time);
  
                  // Cambia el color de fondo de las barras según la posición del punto
                  const progressBarElements =
                    progressBarRef.current.querySelectorAll(".progress-bar");
                  const currentPosition = (time / duration) * 100;
  
                  progressBarElements.forEach((bar) => {
                    if (parseFloat(bar.style.left) <= currentPosition) {
                      bar.classList.add("active");
                    } else {
                      bar.classList.remove("active");
                    }
                  });
                });
  
                setDisabled(false);
                return () => {
                  // Limpia al desmontar el componente
                  waveSurfer.destroy();
                };
              }
            }
          }
        } catch (error) {
          console.error(error);
        }
      };
  
      checkAudioValidity();
    }
  }, [audioUrl]);

  return (
    <div style={styles || {}} className={`audio-message ${noBorderContainer ? "border0" : ""}`}>
      {icon ? (
        <VoicemailIcon className="audio-message-image rounded-circle" />
      ) : (
        <>
          {!avatar ? (
            <img
              className="audio-message-image rounded-circle voicemail-icon"
              src={profile}
              alt="profile"
            />
          ) : (
            <UserAvatar
              firstname={firstname}
              lastname={lastname}
              className="audio-message-image rounded-circle"
            />
          )}
        </>
      )}

      <div className={`container-audio ${w100 ? "w100" : "0"}`}>
        <audio
          ref={audioRef}
          src={audioUrl}
          onTimeUpdate={updateTime}
          onEnded={() => setIsPlaying(false)}
          volume={volume / 100}
        />
        <div className="audio-controls">
          <div
            className="play-pause"
            onClick={disabled ? () => {} : togglePlay}
          >
            {isPlaying ? (
              <PauseIcon className={`audio-message-icon ${ml0 ? "ml-0" : ""}`} />
            ) : (
              <PlayArrowIcon className={`audio-message-icon ${ml0 ? "ml-0" : ""}`} />
            )}
          </div>
          <div
            className={`progress-bar-container ${w100 ? "w100" : "0"}`}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseUp}
            onMouseMove={handleMouseMove}
            ref={progressBarRef}
          >
            <div className="progress-bar-background" />
            <div ref={waveformRef} className="waveform-canvas" />
            <div
              className={`progress-bar ${
                currentTime > 0 && (currentTime / duration) * 100 >= 0
                  ? "active"
                  : ""
              }`}
              onClick={handleProgressBarClick}
            >
              <div
                className="progress-filled"
                style={{ width: `${(currentTime / duration) * 100}%` }}
              >
                <div
                  className="progress-marker"
                  style={{
                    left: `${(currentTime / duration) * 100}%`,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        {!outVolume && (
                <div className="container-volumen-icon">
                {volume === 0 && (
                  <VolumeMuteOutlinedIcon
                    className="volume-icon"
                    onClick={onClickVolumenIcon}
                  />
                )}
                {volume > 0 && (
                  <VolumeUpOutlinedIcon
                    className="volume-icon"
                    onClick={onClickVolumenIcon}
                  />
                )}
        
                <div className="volume-slider">
                  <Slider
                    value={volume}
                    onChange={(event, newValue) => handleVolumeChange(event, newValue)}
                    aria-label="Custom slider"
                    classes={{
                      root: "custom-slider-root", // Aplica la clase al elemento raíz
                      track: "custom-slider-track", // Aplica la clase a la línea de seguimiento
                      thumb: "custom-slider-thumb", // Aplica la clase al icono redondo
                    }}
                    sx={{
                      // Aplica estilos personalizados aquí
                      fontSize: "0.75rem", // Puedes ajustar el valor según tus necesidades
                      height: 2, // Puedes ajustar el valor según tus necesidades
                    }}
                  />
                </div>
              </div>
        )}
        <div className={`time-info ${hiddenTime ? "d-none" : ""}`}>
          {formatTime(Math.floor(currentTime))} / {formatTime(duration)}
        </div>
      </div>
      {outVolume && (
      <div className="container-volumen-icon out">
      {volume === 0 && (
        <VolumeMuteOutlinedIcon
          className="volume-icon"
          onClick={onClickVolumenIcon}
        />
      )}
      {volume > 0 && (
        <VolumeUpOutlinedIcon
          className="volume-icon"
          onClick={onClickVolumenIcon}
        />
      )}

      <div className="volume-slider out">
        <Slider
          value={volume}
          onChange={(event, newValue) => handleVolumeChange(event, newValue)}
          aria-label="Custom slider"
          classes={{
            root: "custom-slider-root", // Aplica la clase al elemento raíz
            track: "custom-slider-track", // Aplica la clase a la línea de seguimiento
            thumb: "custom-slider-thumb", // Aplica la clase al icono redondo
          }}
          sx={{
            // Aplica estilos personalizados aquí
            fontSize: "0.75rem", // Puedes ajustar el valor según tus necesidades
            height: 2, // Puedes ajustar el valor según tus necesidades
          }}
        />
      </div>
    </div>
      )}
    </div>
  );
};

export default AudioMessage;
