import React, { useEffect, useState } from "react";
import { Loading } from "../../../../../../components";
import petitionGet from "../../../../../../services/petitionGet";
import Select from "react-select";

const Voicemails = ({ saveModule, edit, data, editModule }) => {
  const [voicemails, setVoicemails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [valueVoicemail, setValueVoicemail] = useState(null);

  const saveVoicemail = () => {
    const sendData = {
      id: valueVoicemail.pk,
      timeout: "20",
    };

    if (edit) {
      editModule(sendData, "voicemail")
    } else {
      saveModule(sendData, "voicemail")
    }
  };

  const petition = () => {
    setLoading(true);
    petitionGet("voicemail-v2")
      .then(({ data: result }) => {
        result.result
          .sort((a, b) => a.owner.firstname.localeCompare(b.owner.firstname))
          .forEach((element) => {
            element.label = `${element.owner.firstname} ${element.owner.lastname} (${element.owner.presence_id})`;
            element.value = element.pk;
          });

        if (edit) {
          const defaultVoicemail = result.result.find((element) => element.pk === data.data.id)
          setValueVoicemail(defaultVoicemail)
        }

        setVoicemails(result.result);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if (data) petition();
  }, [data]);

  return loading ? (
    <Loading />
  ) : (
    <>
      <div className="itp-callFlow-container-module">
        <label className="itp-label-input__label mb-2">User Voicemail</label>
        <Select
          onChange={(e) => setValueVoicemail(e)}
          className="basic-single"
          classNamePrefix="select"
          value={valueVoicemail}
          isSearchable={true}
          name="queue"
          placeholder="Select a Voicemail"
          options={voicemails}
        />
        <button
          onClick={saveVoicemail}
          className="btn-primary mt-4"
          disabled={valueVoicemail ? false : true}
        >
          {!edit ? "Save" : "Edit"}
        </button>
      </div>
    </>
  );
};

export default Voicemails;
