import React, { Fragment, useEffect, useRef, useState } from "react";
import { Row, Col } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPenToSquare,
  faPen,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  CustomTable,
  Flows,
  Loading,
  LoadingButton,
  MenuRight,
  PanelRight,
} from "../../../../components";
import { useMenuDispatch } from "../../../../contexts/MenuContext";
import {
  defaultSidebar,
  hiddenSidebar,
} from "../../../../redux/actions/sidebar";
import { defaultHeader, hiddenHeader } from "../../../../redux/actions/header";
import {
  transformDataCallFlows,
  updateRoute,
  updateChildRoute,
  convertToNestedObject,
} from "./transformDataCallFlows/transformDataCallFlows";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import useScreenSize from "../../../../hooks/useScreenSize";
import arrowleft from "../../../../assets/icons/arrow-left.svg";
import fullScreen from "../../../../assets/icons/fullScreen.svg";
import moreZoom from "../../../../assets/icons/moreZoom.svg";
import lessZoom from "../../../../assets/icons/lessZoom.svg";
import ModalDelete from "./ModalUtitlity/ModalDelete";
import TimeGroupsDetails from "./TimeGroupsDetails/TimeGroupsDetails";
import EditCallFlowNumbers from "./EditCallFlowNumbers/EditCallFlowNumbers";
import QueueDetails from "./QueueDetails/QueueDetails";
import ChartMenuContent from "./ChartMenuContent/ChartMenuContent";
import petitionGet from "../../../../services/petitionGet";
import petitionPost from "../../../../services/petitionPost";
import petitionPatch from "../../../../services/petitionPatch";
import petitionDelete from "../../../../services/petitionDelete";
import "./AdvancedCallFlows.css";

const AdvancedCallFlows = () => {
  const id = JSON.parse(localStorage.getItem("userDetails"))?.api_id;

  const timeGroups = useRef([]);
  const callFlowDetails = useRef(null);

  const { width } = useScreenSize();
  const dispatch = useDispatch();
  const dispatchMenu = useMenuDispatch();
  const navigate = useNavigate();

  const toggleMenu = useSelector((state) => state.sidebar.isDefault);
  const showHeader = useSelector((state) => state.header.show);
  const myExtension = useSelector((state) => state.myExtension.userDetails);

  const [loading, setLoading] = useState(true);
  const [dataTableTimeGroups, setDataTableTimeGroups] = useState(null);
  const [info, setInfo] = useState(null);
  const [modalDelete, setModalDelete] = useState(false);
  const [loadingTimeGroup, setLoadingTimeGroup] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [showAddTimeGroup, setShowAddTimeGroup] = useState(false);
  const [formNameTimeGroup, setFormNameTimeGroup] = useState({ name: "" });
  const [timeGroupDetails, setTimeGroupDetails] = useState(null);
  const [dataTableCallFlows, setDataTableCallFlows] = useState(null);
  const [deleteCallFlows, setDeleteCallFlows] = useState(false);
  const [loadingCallFlows, setLoadingCallFlows] = useState(false);
  const [showAddCallFlow, setShowAddCallFlow] = useState(false);
  const [valueNameCallFlow, setValueNameCallFlow] = useState("");
  const [showEditCallFlow, setShowEditCallFlow] = useState(null);
  const [openPanelCallFlows, setOpenPanelCallFlows] = useState(null);
  const [activeEditChartCallFlow, setActiveEditChartCallFlow] = useState(false);
  const [editDetails, setEditDetails] = useState(null);
  const [isEnd, setIsEnd] = useState(false);
  const [selectedAction, setSelectedAction] = useState(null);
  const [loadingActiveEditCallFlowChart, setloadingActiveEditCallFlowChart] =
    useState(false);
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  //voicemails
  const voicemails = useRef([]);

  //users
  const users = useRef([]);

  //plays
  const plays = useRef([]);

  //queues
  const queues = useRef([]);
  const [dataTableQueues, setDataTableQueues] = useState(null);
  const [showAddQueue, setShowAddQueue] = useState(false);
  const [loadingQueue, setLoadingQueue] = useState(false);
  const [activeDeleteQueue, setActiveDeleteQueue] = useState(false);
  const [showEditQueue, setShowEditQueue] = useState(null);
  const [formQueue, setFormQueue] = useState({
    queue_name: "",
    queue_description: "",
    timeout: "300",
    joinempty: "yes",
  });

  //switches
  const switches = useRef([]);
  const [dataTableSwitches, setDataTableSwitches] = useState(null);
  const [showAddSwitch, setShowAddSwitch] = useState(false);
  const [showEditSwitch, setShowEditSwitch] = useState(false);
  const [activeDeleteSwitch, setActiveDeleteSwitch] = useState(false);
  const [loadingSwitch, setLoadingSwitch] = useState(false);
  const [checkValue, setCheckValue] = useState(null);
  const [error, setError] = useState(false);
  const [formSwitch, setFormSwitch] = useState({
    name: "",
    status: "",
  });

  const numberActionMenuOptions = 12;

  const wheelOptions = {
    step: 0.05,
  };

  const handleBackClick = () => {
    if (
      !timeGroupDetails &&
      !showEditCallFlow &&
      !callFlowDetails.current &&
      !showEditQueue
    ) {
      dispatchMenu({ type: "SET_DEFAULT_MENU" });
      navigate("/Dashboard");
    } else {
      reloadData();
    }
  };

  const onInit = (ref) => {
    /*     ref.zoomIn(); */
    /*   updateSetting("zoomPanPinch", ref); */
  };

  const activePanelCallFlows = (data, end, edit) => {
    if (
      data.module !== "timecondition" ||
      end ||
      (data.module === "timecondition" && data.child.length === 1)
    ) {
      setIsEnd(end);
      setActiveEditChartCallFlow(edit);
      setOpenPanelCallFlows(data);
    }
  };

  const closePanelCallFlows = () => {
    setSelectedAction(null);
    setActiveEditChartCallFlow(false);
    setOpenPanelCallFlows(null);
  };

  const closeModal = () => {
    setActiveDeleteSwitch(false);
    setShowAddSwitch(false);
    setOpenMenu(false);
    setShowEditQueue(null);
    setActiveDeleteQueue(false);
    setShowAddQueue(false);
    setEditDetails(null);
    setShowEditCallFlow(null);
    setValueNameCallFlow("");
    setShowAddCallFlow(false);
    setTimeGroupDetails(null);
    setFormNameTimeGroup({ name: "" });
    setShowAddTimeGroup(false);
    setOpenMenu(false);
    setInfo(null);
    setModalDelete(false);
    callFlowDetails.current = null;
  };

  const activeDeleteCallFlow = (e) => {
    setDeleteCallFlows(true);
    setInfo(e);
    setModalDelete(true);
  };

  const activeModalDelete = (e) => {
    setInfo(e);
    setModalDelete(true);
  };

  const activeAddCallFlow = () => {
    setShowAddCallFlow(true);
    setOpenMenu(true);
  };

  const activeAddTimeGroup = () => {
    setOpenMenu(true);
    setShowAddTimeGroup(true);
  };

  const activeEditCallFlowNumbers = (e) => {
    setShowEditCallFlow(e);
  };

  const toggleFullScreen = () => {
    if ((!toggleMenu && showHeader) || (toggleMenu && showHeader)) {
      dispatch(hiddenSidebar());
      dispatch(hiddenHeader());
    } else if ((toggleMenu && !showHeader) || (!toggleMenu && !showHeader)) {
      dispatch(defaultSidebar());
      dispatch(defaultHeader());
    }
  };

  const activeEditTimeGroup = (e) => {
    setTimeGroupDetails(e);
  };

  const selectSubTitle = () => {
    if (showAddTimeGroup) return "";

    if (showAddCallFlow) return "Add the name for this call flow";

    if (showEditCallFlow) {
      return "Edit the name for this call flow & please select phone number or unique\nextension number in the system that triggers this call flow.";
    }
  };

  const selectTitle = () => {
    if (showAddTimeGroup) return "Add New time group";

    if (showAddCallFlow) return "Add Call Flow";

    if (showEditCallFlow) return "Edit Call Flow";
  };

  const reloadData = (noLoading) => {
    closeModal();
    petition(noLoading);
  };

  const editFlowChart = () => {
    setLoading(true);

    const newData = convertToNestedObject(callFlowDetails.current.child[0]);
    const secondNewData = updateRoute(newData);

    callFlowDetails.current = null;

    const editData = {
      type: "CustomByUser",
      numbers: [],
      name: editDetails.name,
      callflow_object: {
        flow: secondNewData,
        type: "CustomByUser",
      },
    };

    petitionPatch("callFlow", { callflow_id: editDetails.pk, data: editData })
      .then(({ data: result }) => {
        reloadData();
      })
      .catch((error) => console.log(error));
  };

  const deleteModule = (element) => {
    const route = element.route;
    const newSegments = route.split(".");
    const segments = newSegments.slice(0, newSegments.length - 2);
    let newGeneralObject = { ...callFlowDetails.current };
    let pointer = newGeneralObject;

    for (const segment of segments) {
      pointer = pointer[segment];
    }

    const currentChild = pointer.child;

    let newChild = [];

    currentChild.forEach((element, i) => {
      if (i !== parseInt(newSegments[newSegments.length - 1])) {
        newChild.push(element);
      }
    });

    pointer.child = newChild;

    const formatedData = updateChildRoute(newGeneralObject);

    callFlowDetails.current = formatedData;
    forceUpdate();
    closePanelCallFlows();
  };

  const editModuleWithBranch = (element) => {
    if (element.module === "timecondition") {
      if (element.child.length !== timeGroups.current.length + 1) {
        setIsEnd(false);
        setActiveEditChartCallFlow(false);
        setOpenPanelCallFlows(element);
      }
    }

    if (element.module === "menu") {
      if (element.child.length !== numberActionMenuOptions) {
        setIsEnd(false);
        setActiveEditChartCallFlow(false);
        setOpenPanelCallFlows(element);
      }
    }
  };

  const activeEditCallFlow = async (e) => {
    setEditDetails(e);
    setloadingActiveEditCallFlowChart(true);

    let voicemailsTemp = [];
    let usersTemp = [];
    let playsTemp = [];

    await petitionGet("musicOnHoldMedia")
      .then(({ data: result }) => {
        playsTemp = result.result;

        plays.current = playsTemp;
      })
      .catch((error) => console.log(error));

    await petitionGet("voicemail-v2")
      .then(({ data: result }) => {
        result.result
          .sort((a, b) => a.owner.firstname.localeCompare(b.owner.firstname))
          .forEach((element) => {
            element.label = `${element.owner.firstname} ${element.owner.lastname} (${element.owner.presence_id})`;
            element.value = element.pk;
          });

        voicemailsTemp = result.result;
        voicemails.current = voicemailsTemp;
      })
      .catch((error) => console.log(error));

    await petitionGet("itpvoiceUsers")
      .then(({ data: result }) => {
        result.result
          .sort((a, b) => a.firstname.localeCompare(b.firstname))
          .forEach((element) => {
            element.label = `${element.firstname} ${element.lastname} (${element.presence_id})`;
            element.value = element.pk;
          });

        usersTemp = result.result;

        const newDetails = {
          route: "",
          module: `Name: ${e.name}`,
          data: {
            id: e.pk,
          },
          children: e.flow,
          isFirst: true,
        };

        const data = transformDataCallFlows(
          newDetails,
          false,
          timeGroups.current,
          activePanelCallFlows,
          queues.current,
          switches.current,
          voicemailsTemp,
          usersTemp,
          playsTemp
        );

        const updatedRoute = updateChildRoute(data);
        callFlowDetails.current = updatedRoute;

        users.current = usersTemp;
        setloadingActiveEditCallFlowChart(false);
      })
      .catch((error) => console.log(error));
  };

  const deleteCallFlow = () => {
    setLoadingCallFlows(true);
    petitionDelete("callFlow", { callflow_id: info.pk })
      .then(({ data: result }) => {
        setLoadingCallFlows(false);
        reloadData();
      })
      .catch((error) => console.log(error));
  };

  const deleteTimeGroup = () => {
    setLoadingTimeGroup(true);
    petitionGet("timeRules", { parameter: `?timegroup_id=${info.id}` })
      .then(({ data: result }) => {
        let petitions = [];

        result.result.forEach((element) => {
          petitions.push(
            petitionDelete("deleteTimeRules", { time_id: element.pk })
          );
        });

        if (info.forced_disable_code_callflow_id) {
          petitions.push(
            petitionDelete("callFlow", {
              callflow_id: info.forced_disable_code_callflow_id,
            })
          );
        }

        if (info.forced_enable_code_callflow_id) {
          petitions.push(
            petitionDelete("callFlow", {
              callflow_id: info.forced_enable_code_callflow_id,
            })
          );
        }

        if (info.forced_reset_code_callflow_id) {
          petitions.push(
            petitionDelete("callFlow", {
              callflow_id: info.forced_reset_code_callflow_id,
            })
          );
        }

        Promise.all(petitions)
          .then((result) => {
            petitionDelete("timeGroup", { time_id: info.id })
              .then(({ data: result }) => {
                setLoadingTimeGroup(false);
                reloadData(true);
              })
              .catch((error) => console.log(error));
          })
          .catch((error) => console.log(error));
      })
      .catch((error) => console.log(error));
  };

  const addCallFlow = () => {
    setLoadingCallFlows(true);

    let data = {
      name: valueNameCallFlow,
      numbers: [],
      type: "CustomByUser",
      callflow_object: {
        type: "CustomByUser",
        flow: {},
      },
    };

    petitionPost("callflow", { data })
      .then(({ data: result }) => {
        setLoadingCallFlows(false);
        reloadData();
      })
      .catch((error) => console.log(error));
  };

  const addTimeGroup = () => {
    setLoadingTimeGroup(true);
    petitionPost("timeGroup", { data: formNameTimeGroup })
      .then(({ data: result }) => {
        setLoadingTimeGroup();
        reloadData(true);
      })
      .catch((error) => console.log(error));
  };

  const activeAddQueue = () => {
    setOpenMenu(true);
    setShowAddQueue(true);
  };

  const onChangeFormQueue = (e) => {
    setFormQueue({ ...formQueue, [e.target.name]: e.target.value });
  };

  const addQueue = () => {
    setLoadingQueue(true);
    petitionPost("queue", { data: formQueue })
      .then(({ data: result }) => {
        setLoadingQueue(false);
        reloadData();
      })
      .catch((error) => console.log(error));
  };

  const deleteQueue = () => {
    setLoadingTimeGroup(true);
    petitionDelete("queue", { queue_id: info.pk })
      .then(({ data: result }) => {
        setLoadingTimeGroup(false);
        reloadData();
      })
      .catch((error) => console.log(error));
  };

  const activeModalDeleteQueue = (e) => {
    setInfo(e);
    setActiveDeleteQueue(true);
    setModalDelete(true);
  };

  const activeEditQueue = (e) => {
    setShowEditQueue(e);
  };

  const petitionQueue = async () => {
    await petitionGet("queues")
      .then(({ data: result }) => {
        queues.current = result.result;
        setDataTableQueues({
          title: [
            { title: "Name", key: "queue_name" },
            { title: "Description", key: "queue_description" },
          ],
          content: [...result.result],
          actions: {
            title: "Actions",
            content: [
              {
                name: "Edit",
                icon: <FontAwesomeIcon icon={faPenToSquare} />,
                handleClick: activeEditQueue,
              },
              {
                name: "Delete",
                icon: <FontAwesomeIcon icon={faTrash} />,
                handleClick: activeModalDeleteQueue,
              },
            ],
          },
          titleTable: "Queues",
          label: "Create & manage your queues for your advanced call flow",
          addButton: {
            label: "Add Queue",
            handleClick: activeAddQueue,
          },
        });
      })
      .catch((error) => console.log(error));
  };

  const activeAddSwitch = () => {
    setShowAddSwitch(true);
    setOpenMenu(true);
  };

  const addSwitch = () => {
    setLoadingSwitch(true);

    const data = {
      name: formSwitch.name,
    };

    petitionPost("switch", { data })
      .then(({ data: result }) => {
        setLoadingSwitch(false);
        reloadData();
      })
      .catch((error) => console.log(error));
  };

  const activeModalDeleteSwitch = (e) => {
    setInfo(e);
    setActiveDeleteSwitch(true);
    setModalDelete(true);
  };

  const deleteSwitch = () => {
    setLoadingTimeGroup(true);
    petitionDelete("switch", { switch_id: info.pk })
      .then(({ data: result }) => {
        setLoadingTimeGroup(false);
        reloadData();
      })
      .catch((error) => console.log(error));
  };

  const activeEditSwitch = (e) => {
    setFormSwitch({
      name: e.name,
      status: e.status,
      extensioNumber: e.callflow ? e.callflow.numbers[0] : "",
    });
    setCheckValue(e.callflow_id ? true : false);
    setInfo(e);
    setShowEditSwitch(true);
    setOpenMenu(true);
  };

  const onChangeFormSwitch = (e) => {
    if (e.target.name === "status") {
      setFormSwitch({
        ...formSwitch,
        status: e.target.value === "true" ? true : false,
      });
    } else if (e.target.name === "extensioNumber") {
      setFormSwitch({ ...formSwitch, extensioNumber: `*${e.target.value}` });
    } else {
      setFormSwitch({ ...formSwitch, [e.target.name]: e.target.value });
    }
  };

  const editSwitch = async () => {
    setLoadingSwitch(true);
    if (
      checkValue === info.callflow_id ||
      (info.callflow_id && checkValue) ||
      (!info.callflow_id && !checkValue)
    ) {
      if (!checkValue) {
        const data = {
          name: formSwitch.name,
          status: formSwitch.status,
        };
        petitionPatch("switch", {
          switch_id: info.pk,
          data,
        })
          .then(({ data: result }) => {
            setLoadingSwitch(false);
            reloadData();
          })
          .catch((error) => console.log(error));
      } else {
        if (
          (!info.callflow.numbers &&
            (!formSwitch.extensioNumber ||
              formSwitch.extensioNumber === "*")) ||
          (info.callflow.numbers &&
            (!formSwitch.extensioNumber ||
              formSwitch.extensioNumber === info.callflow.numbers[0]))
        ) {
          const data = {
            name: formSwitch.name,
            status: formSwitch.status,
          };
          petitionPatch("switch", {
            switch_id: info.pk,
            data,
          })
            .then(({ data: result }) => {
              setLoadingSwitch(false);
              reloadData();
            })
            .catch((error) => console.log(error));
        } else if (info.callflow.numbers && formSwitch.extensioNumber !== "*") {
          const dataForm = {
            switch_id: info.pk,
            data: {
              name: formSwitch.name,
              status: formSwitch.status,
            },
          };

          const dataExtensionNumber = {
            callflow_id: info.callflow_id,
            data: { numbers: [formSwitch.extensioNumber] },
          };

          Promise.all([
            petitionPatch("switch", dataForm),
            petitionPatch("callFlow", dataExtensionNumber),
          ])
            .then(({ data: result }) => {
              setLoadingSwitch(false);
              reloadData();
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
    } else {
      if (checkValue && formSwitch.extensioNumber !== "*") {
        const dataForm = {
          switch_id: info.pk,
          data: {
            name: formSwitch.name,
            status: formSwitch.status,
          },
        };
        const dataNewCallFlow = {
          id: id,
          data: {
            numbers: [formSwitch.extensioNumber],
            type: "featureCode",
            name: "Switch Feature Code",
            callflow_object: {
              flow: {
                children: {},
                data: {
                  id: info.pk,
                  action: "switch",
                },
                module: "switch",
              },
            },
          },
        };
        await petitionPatch("switch", dataForm)
          .then(({ data: result }) => {})
          .catch((error) => console.log(error));

        await petitionPost("callflow", dataNewCallFlow)
          .then(({ data: result }) => {
            petitionPatch("switch", {
              switch_id: info.pk,
              data: {
                callflow_id: result.result.pk,
              },
            })
              .then(({ data: response }) => {
                setLoadingSwitch(false);
                reloadData();
              })
              .catch((error) => console.log(error));
          })
          .catch((error) => {
            console.log(error);
            if (error.response.status === 400) {
              setError(
                error.response.data.error.error_detailed.error.error_message
              );
              setLoadingSwitch(false);
            }
          });
      } else if (!checkValue) {
        const dataForm = {
          switch_id: info.pk,
          data: {
            name: formSwitch.name,
            status: formSwitch.status,
          },
        };

        const deleteCallFlow = {
          callflow_id: info.callflow_id,
        };

        petitionDelete("callFlow", deleteCallFlow)
          .then(({ data: result }) => {
            petitionPatch("switch", dataForm)
              .then(({ data: response }) => {
                setLoadingSwitch(false);
                reloadData();
              })
              .catch((error) => console.log(error));
          })
          .catch((error) => console.log(error));
      }
    }
  };

  const petitionSwitch = async () => {
    await petitionGet("switches")
      .then(({ data: result }) => {
        switches.current = result.result;

        setDataTableSwitches({
          title: [{ title: "Switch Name", key: "name" }],
          content: [...result.result],
          actions: {
            title: "Actions",
            content: [
              {
                icon: <FontAwesomeIcon icon={faPenToSquare} />,
                name: "Edit",
                handleClick: activeEditSwitch,
              },
              {
                icon: <FontAwesomeIcon icon={faTrash} />,
                name: "Delete",
                handleClick: activeModalDeleteSwitch,
              },
            ],
          },
          titleTable: "Switches",
          label: "Create & manage your switches for your advanced call flow",
          addButton: {
            label: "Add Switch",
            handleClick: activeAddSwitch,
          },
        });
      })
      .catch((error) => console.log(error));
  };

  const petitionTimeGroups = async () => {
    await petitionGet("timeGroups")
      .then(({ data: result }) => {
        result.result.forEach((element) => {
          element.label = element.name;
          element.value = element.pk.toString();

          element.id = element.pk.toString();
          element.pk = element.pk.toString();
        });

        timeGroups.current = result.result;

        setDataTableTimeGroups({
          title: [{ title: "Groups", key: "name" }],
          content: [...result.result],
          actions: {
            title: "Actions",
            content: [
              {
                name: "Edit Time Group",
                icon: <FontAwesomeIcon icon={faPenToSquare} />,
                handleClick: activeEditTimeGroup,
              },
              {
                name: "Delete",
                icon: <FontAwesomeIcon icon={faTrash} />,
                handleClick: activeModalDelete,
              },
            ],
          },
          titleTable: "Time Groups for flows",
          label: "Create & manage team groups for your advanced call flow",
          addButton: {
            label: "Add Time group",
            handleClick: activeAddTimeGroup,
          },
        });

        setLoading(false);
      })
      .catch((error) => console.log(error));
  };

  const petitonCallFlows = async () => {
    await petitionGet("allCallflows")
      .then(({ data: result }) => {
        let lengthNumbers = [];

        result.result.forEach((element) => {
          if (element.type === "CustomByUser") {
            if (element.numbers.length === 0) {
              element.numberString = "Not ASsigned";
            } else {
              element.numberString = element.numbers.join();

              if (element.numbers.length > 5) {
                lengthNumbers = element.numbers.slice(0, 5);
                lengthNumbers.push("...");
                element.numberString = lengthNumbers.join();
                element.numberFilter = element.numbers.join();
              } else {
                element.numberString = element.numbers.join();
                element.numberFilter = element.numbers.join();
              }
            }
          }
        });

        let tempResult = [];

        result.result.forEach((element) => {
          if (element.type === "CustomByUser") {
            tempResult.push({
              ...element,
              numbers: element.numbers.map((elementTwo) => elementTwo),
              numbersText:
                element.numbers.length > 0
                  ? element.numbers.length > 5
                    ? lengthNumbers
                    : element.numbers.map((elementTwo) => elementTwo)
                  : "Not assigned numbers",
            });
          }
        });

        setDataTableCallFlows({
          title: [
            { title: "My Call flows", key: "name" },
            { title: "Numbers involved", key: "numberString" },
          ],
          content: [...tempResult],
          actions: {
            title: "Actions",
            content: [
              {
                name: "Edit Numbers",
                icon: <FontAwesomeIcon icon={faPenToSquare} />,
                handleClick: activeEditCallFlowNumbers,
              },
              {
                name: "Edit Call flow Details",
                icon: <FontAwesomeIcon icon={faPen} />,
                handleClick: activeEditCallFlow,
              },
              {
                name: "Delete",
                icon: <FontAwesomeIcon icon={faTrash} />,
                handleClick: activeDeleteCallFlow,
              },
            ],
          },
          titleTable: "Advanced Call Flows",
          label: "Create & manage your advanced call flow",
          addButton: {
            label: "Create New Call Flow",
            handleClick: activeAddCallFlow,
          },
        });
      })
      .catch((error) => console.log(error));
  };

  const petition = async (noLoading) => {
    if (!noLoading) setLoading(true);

    await petitionSwitch();

    await petitionQueue();

    await petitonCallFlows();

    await petitionTimeGroups();
  };

  useEffect(() => {
    if (
      Object.keys(myExtension).length > 0 &&
      myExtension.priv_level !== "admin"
    ) {
      handleBackClick();
    }
  }, [myExtension]);

  useEffect(() => {
    petition();
  }, []);

  return (
    <>
      {(!toggleMenu || showHeader) && (
        <div onClick={handleBackClick} className="itp-tex-back-container">
          <div className="itp-text-container-funnel-details">
            <img src={arrowleft} alt="arrow-left" />
            <span className="itp-text-back-funnel-details">
              {!callFlowDetails.current &&
              !timeGroupDetails &&
              !showEditCallFlow &&
              !showEditQueue
                ? "Back to Dashboard"
                : "Back to Advance Call Flows"}
            </span>
          </div>
        </div>
      )}

      {loading ? (
        <Loading />
      ) : (
        <div className="itp-test">
          {modalDelete && (
            <ModalDelete
              modal={modalDelete}
              closeModal={closeModal}
              loading={!deleteCallFlows ? loadingTimeGroup : loadingCallFlows}
              modalFunction={() => {
                if (deleteCallFlows && !activeDeleteQueue) deleteCallFlow();
                if (!deleteCallFlows && !activeDeleteQueue) deleteTimeGroup();
                if (activeDeleteQueue && !deleteCallFlows) deleteQueue();
                if (activeDeleteSwitch && !deleteCallFlows) deleteSwitch();
              }}
              module={deleteCallFlows ? "Call Flow" : ""}
            />
          )}

          {!timeGroupDetails &&
            !showEditCallFlow &&
            !showEditQueue &&
            !loadingActiveEditCallFlowChart &&
            !callFlowDetails.current && (
              <>
                <div>
                  <CustomTable
                    data={dataTableCallFlows}
                    centered={width > 991 ? true : false}
                    noPagBack={true}
                    overflowCard="inherit"
                  />
                </div>

                <br></br>
                <div>
                  <Row>
                    <Col>
                      <div className="itp-user-settings-separator-line"></div>
                    </Col>
                  </Row>
                </div>
                <br></br>

                <div className="mt-4">
                  <CustomTable
                    data={dataTableTimeGroups}
                    centered={width > 991 ? true : false}
                    noPagBack={true}
                    overflowCard="inherit"
                  />
                </div>

                <br></br>
                <div>
                  <Row>
                    <Col>
                      <div className="itp-user-settings-separator-line"></div>
                    </Col>
                  </Row>
                </div>
                <br></br>

                <div className="mt-4 mb-4">
                  <CustomTable
                    data={dataTableQueues}
                    centered={width > 991 ? true : false}
                    noPagBack={true}
                    overflowCard={width > 991 ? "inherit" : "auto"}
                  />
                </div>

                <br></br>
                <div>
                  <Row>
                    <Col style={{ zIndex: "-1" }}>
                      <div className="itp-user-settings-separator-line"></div>
                    </Col>
                  </Row>
                </div>
                <br></br>

                <div className="mt-4 mb-4">
                  <CustomTable
                    data={dataTableSwitches}
                    centered={width > 991 ? true : false}
                    noPagBack={true}
                    overflowCard={width > 991 ? "inherit" : "auto"}
                  />
                </div>
              </>
            )}

          {showEditQueue && !loadingActiveEditCallFlowChart && <QueueDetails details={showEditQueue} />}

          {timeGroupDetails && !loadingActiveEditCallFlowChart && (
            <TimeGroupsDetails timeGroupDetails={timeGroupDetails} />
          )}

          {showEditCallFlow && !loadingActiveEditCallFlowChart && (
            <EditCallFlowNumbers callFlowDetails={showEditCallFlow} />
          )}

          {(callFlowDetails.current || loadingActiveEditCallFlowChart) && (
            <>
              <div>
                <div
                  style={{height:"853px"}}
                  className={`itp-advanced-callFlows__flows ${
                    toggleMenu ? "full-screen" : ""
                  } ${openPanelCallFlows ? "open-panel" : ""}`}
                >
                    <TransformWrapper
                      maxScale={1.8}
                      minScale={0.4}
                      initialScale={1}
                      wheel={wheelOptions}
                      onInit={onInit}
                      onClick={(e) => console.log(e)}
                      centerOnInit={true}
                      disabled={false}
                    >
                      {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                        <Fragment>
                          <div
                            className={`itp-advanced-callFlows__flow-top-bar ${
                              toggleMenu ? "full-screen" : ""
                            }`}
                          >
                            <label className="itp-advanced-callFlows__flow-top-bar-label">
                              Work Flow Chart
                            </label>
                            <div className="itp-advanced-callFlows__flow-top-bar-actions">
                              {!showHeader ? (
                                <CloseFullscreenIcon
                                  onClick={toggleFullScreen}
                                  className="itp-advanced-callFlows__flow-top-bar-icon white"
                                />
                              ) : (
                                <img
                                  onClick={toggleFullScreen}
                                  src={fullScreen}
                                  alt="Full"
                                  className="itp-advanced-callFlows__flow-top-bar-icon"
                                />
                              )}

                              <img
                                src={moreZoom}
                                alt="More"
                                className="itp-advanced-callFlows__flow-top-bar-icon"
                                onClick={() => zoomIn()}
                              />
                              <img
                                src={lessZoom}
                                alt="Less"
                                className="itp-advanced-callFlows__flow-top-bar-icon"
                                onClick={() => zoomOut()}
                              />
                              <button
                                onClick={editFlowChart}
                                className="itp-advanced-callFlows__flow-top-bar-button"
                              >
                                Save Changes
                              </button>
                            </div>
                          </div>
                          <TransformComponent
                            wrapperStyle={{
                              width: "100%",
                              height: "90%",
                            }}
                            disabled={true}
                          >
                            <Flows
                              activeMenu={() => console.log("activeMenu")}
                              menu={false}
                              addFirstAction={() =>
                                console.log("addFirstAction")
                              }
                              deleteFunction={deleteModule}
                              editModuleWithBranch={editModuleWithBranch}
                              onMenuTriger={() => console.log("onMenuTriger")}
                              data={callFlowDetails.current || {}}
                              setSelectedAction={setSelectedAction}
                              selectedAction={selectedAction}
                              loading={loadingActiveEditCallFlowChart}
                            />
                          </TransformComponent>
                        </Fragment>
                      )}
                    </TransformWrapper>
                </div>
                {callFlowDetails.current && (
                  <PanelRight
                    show={openPanelCallFlows}
                    closeMenu={closePanelCallFlows}
                    title="Actions"
                    subtitle="Choose an action for a current state"
                    to=""
                  >
                    <ChartMenuContent
                      edit={activeEditChartCallFlow}
                      data={openPanelCallFlows}
                      callFlowDetails={callFlowDetails}
                      activePanelCallFlows={activePanelCallFlows}
                      closePanelCallFlows={closePanelCallFlows}
                      timeGroups={timeGroups.current}
                      isEnd={isEnd}
                      queues={queues.current}
                      switches={switches.current}
                      voicemails={voicemails.current}
                      users={users.current}
                      plays={plays.current}
                    />
                  </PanelRight>
                )}
              </div>
            </>
          )}

          <MenuRight
            show={openMenu}
            closeMenu={closeModal}
            title={selectTitle()}
            sizeSubtitle={10}
            subtitle={selectSubTitle()}
            to=""
          >
            {openMenu && showAddTimeGroup && (
              <>
                <Row className="itp-container-label-input">
                  <Col
                    xs={5}
                    sm={5}
                    md={5}
                    lg={5}
                    xl={5}
                    xxl={5}
                    className="itp-label-input-col"
                  >
                    <label className="itp-label-input__label">
                      Time Group Name
                    </label>
                  </Col>
                  <Col
                    xs={7}
                    sm={7}
                    md={7}
                    lg={7}
                    xl={7}
                    xxl={7}
                    className="itp-label-input-col"
                  >
                    <input
                      type="text"
                      placeholder=": Add Name to this time group"
                      className="form-control-itp itp-label-input__input"
                      name="name"
                      value={formNameTimeGroup.name}
                      onChange={(e) => {
                        setFormNameTimeGroup({ name: e.target.value });
                      }}
                    />
                  </Col>
                </Row>

                <button
                  onClick={addTimeGroup}
                  className="btn-primary itp-lead-detail-button-save loading"
                  disabled={formNameTimeGroup.name ? false : true}
                >
                  {loadingTimeGroup && <LoadingButton />}
                  Save
                </button>
              </>
            )}

            {openMenu && showAddCallFlow && (
              <>
                <Row className="itp-container-label-input">
                  <Col
                    xs={5}
                    sm={5}
                    md={5}
                    lg={5}
                    xl={5}
                    xxl={5}
                    className="itp-label-input-col"
                  >
                    <label className="itp-label-input__label">
                      Call flow Name
                    </label>
                  </Col>
                  <Col
                    xs={7}
                    sm={7}
                    md={7}
                    lg={7}
                    xl={7}
                    xxl={7}
                    className="itp-label-input-col"
                  >
                    <input
                      type="text"
                      placeholder=": Add a Name here"
                      className="form-control-itp itp-label-input__input"
                      name="name"
                      value={valueNameCallFlow}
                      onChange={(e) => setValueNameCallFlow(e.target.value)}
                    />
                  </Col>
                </Row>

                <button
                  onClick={addCallFlow}
                  className="btn-primary itp-lead-detail-button-save loading"
                  disabled={valueNameCallFlow === "" ? true : false}
                >
                  {loadingCallFlows && <LoadingButton />}
                  Save
                </button>
              </>
            )}

            {openMenu && showAddQueue && (
              <>
                <Row className="itp-container-label-input">
                  <Col
                    xs={5}
                    sm={5}
                    md={5}
                    lg={5}
                    xl={5}
                    xxl={5}
                    className="itp-label-input-col"
                  >
                    <label className="itp-label-input__label">Queue Name</label>
                  </Col>
                  <Col
                    xs={7}
                    sm={7}
                    md={7}
                    lg={7}
                    xl={7}
                    xxl={7}
                    className="itp-label-input-col"
                  >
                    <input
                      type="text"
                      placeholder=": Add a Name here"
                      className="form-control-itp itp-label-input__input"
                      name="queue_name"
                      value={formQueue.queue_name}
                      onChange={onChangeFormQueue}
                    />
                  </Col>
                </Row>
                <Row className="itp-container-label-input">
                  <Col
                    xs={5}
                    sm={5}
                    md={5}
                    lg={5}
                    xl={5}
                    xxl={5}
                    className="itp-label-input-col"
                  >
                    <label className="itp-label-input__label">
                      Queue Description
                    </label>
                  </Col>
                  <Col
                    xs={7}
                    sm={7}
                    md={7}
                    lg={7}
                    xl={7}
                    xxl={7}
                    className="itp-label-input-col"
                  >
                    <input
                      type="text"
                      placeholder=": Add a Description here"
                      className="form-control-itp itp-label-input__input"
                      name="queue_description"
                      value={formQueue.queue_description}
                      onChange={onChangeFormQueue}
                    />
                  </Col>
                </Row>

                <button
                  onClick={addQueue}
                  className="btn-primary itp-lead-detail-button-save loading"
                  disabled={
                    !formQueue.queue_name ||
                    !formQueue.queue_description ||
                    loadingQueue
                  }
                >
                  {loadingQueue && <LoadingButton />}
                  Save
                </button>
              </>
            )}

            {openMenu && showAddSwitch && (
              <>
                <Row className="itp-container-label-input">
                  <Col
                    xs={5}
                    sm={5}
                    md={5}
                    lg={5}
                    xl={5}
                    xxl={5}
                    className="itp-label-input-col"
                  >
                    <label className="itp-label-input__label">
                      Switch Name
                    </label>
                  </Col>
                  <Col
                    xs={7}
                    sm={7}
                    md={7}
                    lg={7}
                    xl={7}
                    xxl={7}
                    className="itp-label-input-col"
                  >
                    <input
                      type="text"
                      placeholder=": Add Name to this switch"
                      className="form-control-itp itp-label-input__input"
                      name="name"
                      value={formSwitch.name}
                      onChange={onChangeFormSwitch}
                    />
                  </Col>
                </Row>

                <button
                  onClick={addSwitch}
                  className="btn-primary itp-lead-detail-button-save loading"
                  disabled={formSwitch.name ? false : true}
                >
                  {loadingSwitch && <LoadingButton />}
                  Save
                </button>
              </>
            )}

            {openMenu && showEditSwitch && (
              <>
                <Row className="itp-container-label-input">
                  <Col
                    xs={5}
                    sm={5}
                    md={5}
                    lg={5}
                    xl={5}
                    xxl={5}
                    className="itp-label-input-col"
                  >
                    <label className="itp-label-input__label">
                      Switch Name
                    </label>
                  </Col>
                  <Col
                    xs={7}
                    sm={7}
                    md={7}
                    lg={7}
                    xl={7}
                    xxl={7}
                    className="itp-label-input-col"
                  >
                    <input
                      type="text"
                      placeholder=": Add Name to this switch"
                      className="form-control-itp itp-label-input__input"
                      name="name"
                      value={formSwitch.name}
                      onChange={onChangeFormSwitch}
                    />
                  </Col>
                </Row>
                <Row className="itp-container-label-select">
                  <Col
                    xs={5}
                    sm={5}
                    md={5}
                    lg={5}
                    xl={5}
                    xxl={5}
                    className="itp-label-input-col"
                  >
                    <label className="itp-label-input__label">
                      Switch Status
                    </label>
                  </Col>
                  <Col
                    xs={7}
                    sm={7}
                    md={7}
                    lg={7}
                    xl={7}
                    xxl={7}
                    className="itp-label-input-col"
                  >
                    <select
                      onChange={onChangeFormSwitch}
                      value={formSwitch.status}
                      name="status"
                      className="form-select-itp"
                    >
                      <option value={true}> True </option>
                      <option value={false}> False </option>
                    </select>
                  </Col>
                </Row>
                <Row className="itp-container-label-select">
                  <Col
                    xs={5}
                    sm={5}
                    md={5}
                    lg={5}
                    xl={5}
                    xxl={5}
                    className="itp-label-input-col"
                  >
                    <label className="itp-label-input__label">
                      Enable Feature Code
                    </label>
                  </Col>
                  <Col
                    xs={7}
                    sm={7}
                    md={7}
                    lg={7}
                    xl={7}
                    xxl={7}
                    className="itp-label-input-col"
                  >
                    <input
                      checked={checkValue}
                      name=""
                      onChange={() => setCheckValue(!checkValue)}
                      type="checkbox"
                      className="form-check-input itp-custom-checkbox-contact-details"
                    />
                  </Col>
                </Row>
                {checkValue && (
                  <Row className="itp-container-label-input">
                    <Col
                      xs={7}
                      sm={7}
                      md={7}
                      lg={7}
                      xl={7}
                      xxl={7}
                      className="itp-label-input-col"
                    >
                      <label className="itp-label-input__label">
                        Feature Code To Enable or Disable
                      </label>
                    </Col>
                    <Col
                      xs={5}
                      sm={5}
                      md={5}
                      lg={5}
                      xl={5}
                      xxl={5}
                      className="itp-label-input-col"
                    >
                      <input
                        type="number"
                        placeholder=": Enter an extension number"
                        className="form-control-itp itp-label-input__input"
                        name="extensioNumber"
                        value={formSwitch.extensioNumber.slice(1)}
                        onChange={onChangeFormSwitch}
                      />
                    </Col>
                  </Row>
                )}
                {error && <p className="text-danger">{error}</p>}
                <button
                  onClick={editSwitch}
                  className="btn-primary itp-lead-detail-button-save loading"
                  disabled={
                    !formSwitch.name ||
                    (checkValue &&
                      (!formSwitch.extensioNumber ||
                        formSwitch.extensioNumber === "*"))
                  }
                >
                  {loadingSwitch && <LoadingButton />}
                  Save
                </button>
              </>
            )}
          </MenuRight>
        </div>
      )}
    </>
  );
};

export default AdvancedCallFlows;
