import React from "react";
import "./SmartCampaigns.css"

function SmartCampaigns() {


    return <>
        <div className="itp-test"  >Smart Campaigns</div>
    </>;
}

export default SmartCampaigns;