import React, { useEffect, useState } from "react";
import {
  Loading,
  LoadingButton,
  FullscreenImage,
} from "../../../../../components";
import {
  convertLocalTimeToTimezoneTimeOnFormated,
  formatDate,
} from "../../../../../utils/getDate";
import petitionGet from "../../../../../services/petitionGet";
import json from "../../../../../config.json";
import "../ChatMenu/ChatMenu.css";

const ImagesDetails = ({ chatWindowData, number, timeZone, openMenu }) => {
  const api_id = JSON.parse(localStorage.getItem("userDetails")).api_id;
  const token = JSON.parse(localStorage.getItem("userDetails")).access_token;
  const urlBase = json.prod ? json.urlBase.prod : json.urlBase.dev;
  const [loading, setLoading] = useState(true);
  const [imageList, setImageList] = useState([]);
  const [loadingImages, setLoadingImages] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState({
    firstLevel: null,
    secondLevel: true,
  });
  const [showFullscreen, setShowFullscreen] = useState(false);

  const currentDate = convertLocalTimeToTimezoneTimeOnFormated(timeZone);
  const currentYear = currentDate.split(" ")[0].split("-")[0];
  const currentMonth = currentDate.split(" ")[0].split("-")[1];

  const openFullscreen = (i, i2) => {
    setCurrentImageIndex({ firstLevel: i, secondLevel: i2 });
    setShowFullscreen(true);
  };

  const closeFullscreen = () => {
    setShowFullscreen(false);
    setCurrentImageIndex({ firstLevel: null, secondLevel: null });
  };

  const handleNextImage = () => {
    if (currentImageIndex.firstLevel < imageList.length - 1) {
      if (
        currentImageIndex.secondLevel <
        imageList[currentImageIndex.firstLevel].images.length - 1
      ) {
        setCurrentImageIndex({
          ...currentImageIndex,
          secondLevel: currentImageIndex?.secondLevel + 1,
        });
      } else {
        setCurrentImageIndex({
          firstLevel: currentImageIndex.firstLevel + 1,
          secondLevel: 0,
        });
      }
    }
  };

  const handlePrevImage = () => {
    if (
      currentImageIndex?.firstLevel === 0 &&
      currentImageIndex?.secondLevel === 0
    ) {
      return;
    }

    if (
      (currentImageIndex?.firstLevel === 0 &&
        currentImageIndex?.secondLevel > 0) ||
      (currentImageIndex?.firstLevel > 0 && currentImageIndex?.secondLevel > 0)
    ) {
      let newObject = {
        ...currentImageIndex,
        secondLevel: currentImageIndex.secondLevel - 1,
      };

      setCurrentImageIndex(newObject);
    }

    if (
      currentImageIndex?.firstLevel > 0 &&
      currentImageIndex?.secondLevel === 0
    ) {
      let newObject = {
        firstLevel: currentImageIndex.firstLevel - 1,
        secondLevel:
          imageList[currentImageIndex.firstLevel - 1].images.length - 1,
      };

      setCurrentImageIndex(newObject);
    }

  };

  const returnImages = (imageGroups) => {
    const thisMonthImages = [];
    const otherMonths = [];

    const numberMonth = {
      January: "01",
      February: "02",
      March: "03",
      April: "04",
      May: "05",
      June: "06",
      July: "07",
      August: "08",
      September: "09",
      October: "10",
      November: "11",
      December: "12",
    };

    for (const year in imageGroups) {
      for (const month in imageGroups[year]) {
        const images = imageGroups[year][month];

        if (year === currentYear && numberMonth[month] === currentMonth) {
          thisMonthImages.push({ year, month, images });
        } else {
          otherMonths.push({ year, month, images });
        }
      }
    }

    const sortedImageGroups = [
      ...thisMonthImages,
      ...otherMonths.sort((a, b) =>
        a.year === b.year ? b.month.localeCompare(a.month) : b.year - a.year
      ),
    ];

    let imageElements = [];
    let ids = [];
    for (const { year, month, images } of sortedImageGroups) {
      let title = "";

      if (year === currentYear && numberMonth[month] === currentMonth) {
        title = "This Month";
      } else if (year === currentYear && numberMonth[month] !== currentMonth) {
        title = `${month}`;
      } else if (year !== currentYear && numberMonth[month] !== currentMonth) {
        title = `${month} ${year}`;
      }

      images.forEach((image) => {
        let formatedDate = formatDate(image.message_timestamp);

        image.src = image.message_image;
        image.timeStap = formatedDate;
      });

      ids = ids.concat(images.map((image) => image.pk));

      imageElements.push({
        title,
        images,
      });
    }

    setLoadingImages(ids);
    setImageList(imageElements);
  };

  const petition = () => {
    setLoading(true);
    petitionGet("imagesThread", { number, thread: chatWindowData?.current?.pk })
      .then(({ data: result }) => {
        const messages = result?.result?.messages;

        const imageGroups = messages.reduce((groups, message) => {
          const date = new Date(message.message_timestamp);
          const year = date.getFullYear();
          const month = date.toLocaleString("en-US", { month: "long" });

          if (!groups[year]) {
            groups[year] = {};
          }

          if (!groups[year][month]) {
            groups[year][month] = [];
          }

          groups[year][month].push(message);

          return groups;
        }, {});

        returnImages(imageGroups);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if (openMenu) {
      petition();
    }
  }, [openMenu]);

  return loading ? (
    <Loading />
  ) : (
    <>
      {imageList.map((element, i) => (
        <div
          className="itp-chat-menu__tab-images-details__container-timestap-img"
          key={i}
        >
          <h6>{element.title}</h6>
          <div className="itp-chat-menu__tab-images-details__container-img">
            {element.images.map((image, i2) => (
              <div
                className={`${
                  loadingImages.includes(image.pk)
                    ? "d-flex justify-content-center align-items-center itp-chat-menu__tab-images-details__img"
                    : ""
                }`}
                key={image.pk}
              >
                {loadingImages.includes(image.pk) && <LoadingButton />}

                <img
                  // src={`${urlBase}/itpvoice/v2/${api_id}/my-extension/chat/media/${image.message_mms_media}?token=${token}`}
                  src={image.message_image}
                  alt=""
                  className={`itp-chat-menu__tab-images-details__img ${
                    loadingImages.includes(image.pk) ? "v-hidden" : ""
                  }`}
                  onClick={() => openFullscreen(i, i2)}
                  onLoad={() =>
                    setLoadingImages((prevLoading) =>
                      prevLoading.filter((pk) => pk !== image.pk)
                    )
                  }
                />
              </div>
            ))}
          </div>
        </div>
      ))}

      {showFullscreen &&
        currentImageIndex?.firstLevel !== null &&
        currentImageIndex?.secondLevel !== null && (
          <FullscreenImage
            closeFullscreen={closeFullscreen}
            image={
              currentImageIndex?.firstLevel !== null &&
              currentImageIndex?.secondLevel !== null
                ? imageList[currentImageIndex?.firstLevel].images[
                    currentImageIndex?.secondLevel
                  ]
                : {}
            }
            handlePrevImage={handlePrevImage}
            handleNextImage={handleNextImage}
          />
        )}
    </>
  );
};

export default ImagesDetails;
