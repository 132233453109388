import React, { useEffect, useState } from "react";
import { TabMenu } from "../../../../../components/index";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import userImage from "../../../../../assets/images/userImage.png";
import details from "../../../../../assets/icons/details.svg";
import phone from "../../../../../assets/icons/phone.svg";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import ContactDetails from "../TabsMenuDetails/ContactDetails";
import ImagesDetails from "../TabsMenuDetails/ImagesDetails";
import "./ChatMenu.css";

const ChatMenu = ({
  openMenu,
  toggleMenu,
  chatWindowData,
  setEditedContact,
  number,
  timeZone,
  handleActiveCall,
}) => {
  const [editName, setEditName] = useState(false);
  const [formName, setFormName] = useState({
    firstname: "",
    lastname: "",
  });

  const tabs = [
    {
      title: (
        <img src={phone} alt="" className="itp-chat-menu-body__icon-header" />
      ),
      component: (
        <ContactDetails
          chatWindowData={chatWindowData}
          openMenu={openMenu}
          formName={formName}
          setFormName={setFormName}
          setEditedContact={setEditedContact}
          handleActiveCall={handleActiveCall}
        />
      ),
    },
    {
      title: (
        <img
          src={details}
          alt=""
          className="itp-chat-menu-body__icon-header margin-tab"
        />
      ),
      component: (
        <ImagesDetails 
          chatWindowData={chatWindowData} 
          number={number} 
          timeZone={timeZone}
          openMenu={openMenu}
        />
      ),
    },
  ];

  const onChangeName = (e) => {
    setFormName({ ...formName, [e.target.name]: e.target.value });
  };

  const toggleEdit = () => {
    setEditName(!editName);
  };

  useEffect(() => {
    setFormName(formName);
  }, [formName]);

  useEffect(() => {
    if (openMenu) {
      setFormName({
        firstname:
          chatWindowData?.current?.firstname ||
          chatWindowData?.current?.numberChat,
        lastname: chatWindowData?.current?.lastname || "",
      });
    }
  }, [openMenu]);

  return (
    <>
      <div className="itp-chat-menu">
        <div className={`${openMenu ? "back-icon" : "d-none"}`}>
          <KeyboardDoubleArrowRightIcon
            onClick={toggleMenu}
            style={{ width: "20px", height: "20px" }}
            className={`itp-chat-windiow__header-show-profile
                        ${openMenu ? " open-menu-data" : ""}
                      `}
          />
        </div>
        <div className="itp-chat-menu-header">  
          <img
            src={userImage}
            alt="Chat"
            className="itp-chat-window__header-user-img"
          />
          <div className="mt-3">
            {!editName && (
              <div
                className={`${editName ? "" : " itp-chat-menu-container-name"}`}
              >
                <p className="itp-chat-window__header-user-name">
                  {chatWindowData.current.isContact
                    ? `${formName.firstname} ${formName.lastname || ""}`
                    : chatWindowData.current.numberChat}
                </p>
                <div className="itp-chat-list-edit-icon-container">
                  <EditIcon
                    className="itp-chat-list-edit-icon"
                    onClick={toggleEdit}
                  />
                </div>
              </div>
            )}

            {editName && (
              <>
                <input
                  className="form-control-itp itp-chat-menu-input"
                  placeholder="Enter a contact first name"
                  onChange={onChangeName}
                  value={formName?.firstname}
                  name="firstname"
                />
                <input
                  className="form-control-itp itp-chat-menu-input"
                  placeholder="Enter a contact last name"
                  onChange={onChangeName}
                  value={formName?.lastname}
                  name="lastname"
                />
              </>
            )}

            <div className="itp-chat-window__header-last-message-container">
              <p className="itp-chat-window__header-last-message">
                {chatWindowData?.current?.last_message}
              </p>
              {editName && (
                <div className="d-flex">
                  <CloseIcon
                    className="itp-chat-list-header__save-icons"
                    onClick={toggleEdit}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="itp-chat-manu-body">
          <div className="itp-chat-menu-body__header">
            <TabMenu tabs={tabs} />
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatMenu;
