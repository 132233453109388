import React from "react";
import { Table, Card } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

const Step4 = ({ form, setForm, allData, setAllData }) => {
  const handleDelete = (index) => {
    const allForm = form.filter((element, i) => i !== index);
    setForm(allForm);

    const newAllData = allData.filter((element, i) => i !== index);
    setAllData(newAllData);
  };

  return (
    <div>
      <span style={{ fontSize: "1rem", margin: "1rem 0" }}>
        List all Numbers
      </span>
      <Card className="itp-table-container-all">
        <table className="itp-custom-table">
          <thead>
            <tr className="itp-custom-table-thead-styles">
              <th className="itp-custom-table-th-styles">Location</th>
              <th className="itp-custom-table-th-styles">Number Type </th>
              <th className="itp-custom-table-th-styles">Number </th>
              <th className="itp-custom-table-th-styles text-center">Action</th>
            </tr>
          </thead>
          <tbody>
            {allData.map((element, i) => (
              <tr className="itp-custom-table-row" key={i}>
                <th className="itp-custom-table-td-styles">
                  Domestic - US/CANADA
                </th>
                <th className="itp-custom-table-td-styles">{element.type}</th>
                <th className="itp-custom-table-td-styles">{element.did}</th>
                <th className="itp-custom-table-td-styles text-center">
                  <FontAwesomeIcon
                    onClick={() => {
                      handleDelete(i);
                    }}
                    className="icon-hover"
                    icon={faTrash}
                  />{" "}
                </th>
              </tr>
            ))}
          </tbody>
        </table>
      </Card>
    </div>
  );
};

export default Step4;
