import React, { useEffect, useState } from "react";
import { Loading } from "../../../../../../components";
import petitionGet from "../../../../../../services/petitionGet";
import Select from "react-select";

const Menu = ({ edit, data, saveModule, editModule }) => {
  const [loading, setLoading] = useState(true);
  const [media, setMedia] = useState([]);
  const [valueMedia, setValueMedia] = useState(null);
  const [form, setForm] = useState({
    mediaId: edit ? data.data.mediaId : "",
    retries: edit ? data.data.retries : "1",
    timeout_retries: edit ? data.data.timeout_retries : "3",
    max_digits: edit ? data.data.max_digits : "4",
    timeout: edit ? `${data.data.timeout}` : "8000",
    direct_dialing: edit ? data.data.direct_dialing : false,
  });

  const onChangeForm = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const sendMenu = () => {
    const sendData = {
      ...form,
      id: `${form.mediaId}`,
      media_id: `${form.mediaId}`,
      timeout: parseInt(form.timeout || "8000"),
    };

    if (edit) {
      editModule(sendData)
    } else {
      saveModule(sendData, "menu")
    }
  };

  const petition = () => {
    setLoading(true);
    petitionGet("musicOnHoldMedia")
      .then(({ data: result }) => {
        result.result.forEach((element) => {
          element.label = element.name;
          element.value = element.pk;
        });

        if (edit) {
          const defaultMedia = result.result.find(
            (element) => element.pk.toString() === data.data.media_id
          );

          setValueMedia(defaultMedia)
        }

        setMedia(result.result);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if (data) petition();
  }, [data]);

  return loading ? (
    <Loading />
  ) : (
    <>
      <div className="itp-callFlow-container-module">
        <label className="itp-label-input__label mb-2">Select Media:</label>
        <Select
          onChange={(e) => {
            setValueMedia(e);
            setForm({ ...form, mediaId: e.pk });
          }}
          className="basic-single"
          classNamePrefix="select"
          value={valueMedia}
          isSearchable={true}
          name="mediaId"
          placeholder="Select a Media File"
          options={media}
        />

        <label className="itp-label-input__label mt-4">Invalid Attempts</label>
        <select
          onChange={onChangeForm}
          value={form.retries}
          name="retries"
          className="form-select-itp"
        >
          <option value="" disabled>
            Select option
          </option>
          <option value="0">0</option>
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
        </select>

        <label className="itp-label-input__label mt-4">Timeout retries</label>
        <select
          onChange={onChangeForm}
          value={form.timeout_retries}
          name="timeout_retries"
          className="form-select-itp"
        >
          <option value="" disabled>
            Select option
          </option>
          <option value="0">0</option>
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
        </select>

        <label className="itp-label-input__label mt-4">Max digits</label>
        <select
          onChange={onChangeForm}
          value={form.max_digits}
          name="max_digits"
          className="form-select-itp"
        >
          <option value="" disabled>
            Select option
          </option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
          <option value="6">6</option>
          <option value="7">7</option>
          <option value="8">8</option>
        </select>

        <label className="itp-label-input__label mt-4">Timeout (seconds)</label>
        <select
          onChange={onChangeForm}
          value={form.timeout}
          name="timeout"
          className="form-select-itp"
        >
          <option value="" disabled>
            Select option
          </option>
          <option value="2000">2</option>
          <option value="2500">2.5</option>
          <option value="3000">3</option>
          <option value="3500">3.5</option>
          <option value="4000">4</option>
          <option value="4500">4.5</option>
          <option value="5000">5</option>
          <option value="5500">5.5</option>
          <option value="6000">6</option>
          <option value="6500">6.5</option>
          <option value="7000">7</option>
          <option value="7500">7.5</option>
          <option value="8000">8</option>
          <option value="8500">8.5</option>
          <option value="9000">9</option>
          <option value="9500">9.5</option>
          <option value="10000">10</option>
        </select>

        <label className="itp-label-input__label mt-4">
          Direct Extension Dialing
        </label>
        <select
          onChange={(e) => {
            setForm({
              ...form,
              direct_dialing: e.target.value === "true" ? true : false,
            });
          }}
          value={form.direct_dialing}
          name="direct_dialing"
          className="form-select-itp"
        >
          <option value="" disabled>
            Select option
          </option>
          <option value={true}>Yes</option>
          <option value={false}>No</option>
        </select>

        <button
          onClick={sendMenu}
          className="btn-primary mt-4"
          disabled={valueMedia ? false : true}
        >
          {!edit ? "Save" : "Edit"}
        </button>
      </div>
    </>
  );
};

export default Menu;
