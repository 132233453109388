import axios from "axios";
import json from "../config.json";

export default function petitionPost(key, value) {
  const urlBase = json.prod ? json.urlBase.prod : json.urlBase.dev;
  const token = JSON.parse(localStorage.getItem("userDetails")).access_token;
  const id = JSON.parse(localStorage.getItem("userDetails"))?.api_id;
  var url;
  var data;

  if (token) {
    switch (key) {
      case "contact":
        url = urlBase + `/360/accounts/${id}/my-account/contacts`;
        data = value.data;
        break;
      case "addTags":
        url = urlBase + `/360/accounts/${id}/my-account/tags`;
        data = value.data;
        break;
      case "addNoteContact":
        url = urlBase + `/360/accounts/${id}/my-account/contact/notes`;
        data = value.data;
        break;
      case "customFieldMyExtension":
        url = urlBase + `/360/accounts/${id}/my-account/contacts/custom-fields`;
        data = value.data;
        break;
      case "contactList":
        url = urlBase + `/360/accounts/${id}/my-account/contact-lists`;
        data = value.data;
        break;
      case "importContacts":
        url = urlBase + `/360/accounts/${id}/my-account/contacts/import`;
        data = value.data;
        break;
      case "addContactsList":
        url = urlBase + `/360/accounts/${id}/my-account/contact-lists`;
        data = value.data;
        break;
      case "tag":
        url = urlBase + `/360/accounts/${id}/my-account/tags`;
        data = value.data;
        break;
      case "leads":
        url = urlBase + `/360/accounts/${id}/my-account/leads`;
        data = value.data;
        break;
      case "funnel":
        url = urlBase + `/360/accounts/${id}/funnels`;
        data = value.data;
        break;
      case "stage":
        url = urlBase + `/360/accounts/${id}/funnels/${value.funnel_id}/stages`;
        data = value.data;
        break;
      case "markRead":
        url =
          urlBase +
          `/itpvoice/v2/${id}/my-extension/chat/sms/${value.number}/${value.thread}/mark-read`;
        data = value.data;
        break;
      case "sendSms":
        url =
          urlBase + `/itpvoice/v2/${id}/my-extension/chat/sms/${value.number}`;
        data = value.data;
        break;
      case "intercom":
        url = urlBase + `/itpvoice/v2/${id}/my-extension/callflow`;
        data = value.data;
        break;
      case "apiKeys":
        url = urlBase + `/itpvoice/v2/${id}/apikeys`;
        data = value.data;
        break;
      case "webhook":
        url = urlBase + `/itpvoice/v2/${id}/webhooks`;
        data = value.data;
        break;
      case "brands":
        url = urlBase + `/itpvoice/v2/${id}/brands`;
        data = value.data;
        break;
      case "campaign":
        url = urlBase + `/itpvoice/v2/${id}/csp-campaign`;
        data = value.data;
        break;
      case "orders":
        url = urlBase + `/itpvoice/v2/${id}/numbers`;
        data = value.data;
        break;
      case "verifyTransferNumbers":
        url = urlBase + `/itpvoice/v2/${id}/lnp-verify`;
        data = value.data;
        break;
      case "transferNumbers":
        url = urlBase + `/itpvoice/v2/${id}/numbers/lnp`;
        data = value.data;
        break;
      case "timeGroup":
        url = urlBase + `/itpvoice/v2/${id}/time-groups`;
        data = value.data;
        break;
      case "timeRules":
        url = urlBase + `/itpvoice/v2/${id}/time-rules`;
        data = value.data;
        break;
      case "callflow":
        url = urlBase + `/itpvoice/v2/${id}/callflows`;
        data = value.data;
        break;
      case "reports":
        url =
          urlBase + `/itpvoice/v2/${id}/call-reports${value?.parameter || ""}`;
        data = value.data;
        break;
      case "numberCallReports":
        url =
          urlBase +
          `/itpvoice/v2/${id}/numbers-call-reports${value?.parameter || ""}`;
        data = value.data;
        break;
      case "queue":
        url = urlBase + `/itpvoice/v2/${id}/queues`;
        data = value.data;
        break;
      case "queueMember":
        url = urlBase + `/itpvoice/v2/${id}/queues/${value.queue_id}/members`;
        data = value.data;
        break;
      case "switch":
        url = urlBase + `/itpvoice/v2/${id}/switches`;
        data = value.data;
        break;
      case "messageTemplates":
        url = urlBase + `/360/accounts/${id}/message-templates`;
        data = value.data;
        break;
      case "leadSource":
        url = urlBase + `/360/accounts/${id}/leads/sources`;
        data = value.data;
        break;
      case "callparking":
        url = urlBase + `/itpvoice/v2/${id}/callparking`;
        data = value.data;
        break;
      case "blockedCalls":
        url = urlBase + `/itpvoice/v2/${id}/blocked-calls`;
        data = value.data;
        break;
      case "blockedCountries":
        url = urlBase + `/itpvoice/v2/${id}/blocked-countries`;
        data = value.data;
        break;
      case "mediaGroup":
        url = urlBase + `/itpvoice/v2/${id}/media-groups`;
        data = value.data;
        break;
      case "mediaTTS":
        url =
          urlBase +
          `/itpvoice/v2/${id}/media-groups/${value.media_group_id}/tts`;
        data = value.data;
        break;
      case "operationPanelGroups":
        url = urlBase + `/itpvoice/v2/${id}/operator-panel/groups`;
        data = value.data;
        break;
      case "basicModeCallFlowNumber":
        url = urlBase + `/itpvoice/v2/${id}/numbers/${value.number}/default-callflow`;
        data = value.data;
        break;
      default:
        return "error";
    }

    var config = {
      timeout: 120000,
      headers: {
        Authorization: "Bearer " + token.replace(/['"]+/g, ""),
      },
    };

    return axios.post(url, data, config);
  }
}
