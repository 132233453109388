// Importar React y los componentes necesarios
import React, { useState } from "react";
import { faClose, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./IncomingCall.css"
// Definir el componente TransferScreen
const IncomingCall = ({answerFunction,rejectedFunction,session}) => {
    // Definir el estado del número de teléfono
    const [phoneNumber, setPhoneNumber] = useState("3006006602");

    // Definir la función que se ejecuta al presionar una tecla
    const handleKeyPress = (key) => {
        // Añadir el símbolo al número de teléfono
        setPhoneNumber(phoneNumber + key);
    };

    // Definir la función que se ejecuta al presionar el botón de transferir
    const handleTransfer = () => {
        // Llamar a la función que realiza la transferencia de llamada
        // con el número de teléfono introducido
        // transferCall(phoneNumber);
    };

    // Definir la función que se ejecuta al presionar el botón de cancelar
    const handleCancel = () => {
        // Llamar a la función que cancela la transferencia de llamada
        // cancelTransfer();
    };

    // Retornar el elemento JSX que representa la pantalla de transferencia
    return (
        <div className="icoming-call">
            <div className="header-icoming-call">
                <div className="top-data-icoming-call"><div className="left-top-date-incoming-call">Incoming Call
                </div>
                    <div className="right-top-date-incoming-call">
                        <div className="incoming-call-number">{session.remoteIdentity.uri.normal.user}</div>
                        <div className="x-button" onClick={()=>rejectedFunction()}><FontAwesomeIcon icon={faClose} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="incoming-call-data">
                <div className="incoming-call-user"><FontAwesomeIcon icon={faUser} />
                </div>

                <h3>You have a call</h3>
                <h5>{session.remoteIdentity.uri.normal.user}</h5>
                <h5>{session.remoteIdentity.uri.normal.user}</h5>
            </div>
            <div className="buttons">
                <div className="buttons-actions-incoming-call reject" onClick={()=>rejectedFunction()}>
                    Reject
                </div>
                <div className="buttons-actions-incoming-call answer" onClick={()=>answerFunction()}>
                    Answer
                </div>
            </div>
        </div>
    );
};

export default IncomingCall
