import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Card, Col, Row } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faDownload } from "@fortawesome/free-solid-svg-icons";
import { Loading, MenuRight } from "../../../components";
import { convertTimeStapToTimeZoneOnFormated } from "../../../utils/getDate";
import Select from "react-select";
import useScreenSize from "../../../hooks/useScreenSize";
import petitionGet from "../../../services/petitionGet";
import DataReport from "./DataReport/DataReport";
import ModalError from "./ModalUtility/ModalError";
import json from "../../../config.json"
import "./Analytics.css";

const Analytics = () => {
  const navigate = useNavigate();

  const { width } = useScreenSize();

  const myExtension = useSelector((state) => state.myExtension.userDetails);

  const [loading, setLoading] = useState(true);
  const [openMenu, setOpenMenu] = useState(false);
  const [selectedReport, setSelectedReport] = useState("");
  const [optionsUserReport, setOptionsUserReport] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [optionsNumberReport, setOptionsNumberReport] = useState([]);
  const [selectedNumbers, setSelectedNumbers] = useState([]);
  const [dataReport, setDataReport] = useState(false);
  const [viewLogs, setViewLogs] = useState(null);
  const [loadingCall, setLoadingCall] = useState(null)
  const [modalError, seTModalError] = useState(false)
  const [completedLogs, setCompletedLogs] = useState({})
  const [timeframe, setTimeframe] = useState({
    timeStart: "",
    timeEnd: "",
  });

  const closeModal = () => {
    seTModalError(false)
  }

  const downloadCall = async (value) => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoadingCall(value);
        const token = JSON.parse(
          localStorage.getItem("userDetails")
        ).access_token;

        const urlB = json.prod ? json.urlBase.prod : json.urlBase.dev;

        const id = JSON.parse(localStorage.getItem("userDetails")).api_id;

        const downloadFile = async () =>
          fetch(
            `${urlB}/itpvoice/v2/${id}/cdr/${value.pk}/download-recording?accept=audio/mpeg`,
            {
              headers: {
                Authorization: "Bearer " + token.replace(/['"]+/g, ""),
              },
            }
          ).then((res) => {
            if (res.status === 200) {
              return res.blob();
            } else if (res.status === 404) {
              return res.status;
            }
          });
        const createURL = (blob) => URL.createObjectURL(blob);

        const blob = await downloadFile();
        if (blob === 404)
          return setLoadingCall(null), seTModalError(true);
        const create = createURL(blob);

        let link = document.createElement("a");
        link.download = `${value.call_recording_filename}.mp3`;
        link.href = create;
        link.style.display = "none";
        document.body.appendChild(link);
        link.click();

        setLoadingCall(false);

        resolve(true);
      } catch (error) {
        setLoadingCall(false)
        reject(error);
      }
    });
  };

  const activeViewLogs = (e) => {
    e.all_calls.forEach((element) => {
      element.fromNumber = element.forwarded_original_caller_id
        ? element.forwarded_original_caller_id
        : element.src;

      element.toNumber = element.forwarded_to_number
        ? element.forwarded_to_number
        : element.dst;

      element.saveDurationCall = new Date(element.billsec * 1000)
        .toISOString()
        .substr(
          element.billsec - element.billing_seconds < 3600 ? 14 : 11,
          element.billsec - element.billing_seconds < 3600 ? 5 : 8
        );

      element.datetime = convertTimeStapToTimeZoneOnFormated(
        element.calldate,
        myExtension.time_zone
      );

      if (element.disposition === "ANSWERED") {
        element.style = {
          background: "#F6F6F9 0% 0% no-repeat padding-box",
          borderRadius: "12px",
          font: "normal normal normal 12px/16px Instrument Sans",
          letterSpacing: "0px",
          color: "#199F28",
          opacity: "1",
        }
      } else {
        element.style = {
          background: "#1F3D571E 0% 0% no-repeat padding-box",
          borderRadius: "12px",
          font: "normal normal normal 12px/16px Instrument Sans",
          letterSpacing: "0px",
          color: "#3A3A3ACC",
          opacity: "1",
        }
      }      
    });

    if (e.incoming_calls) {
      e.incoming_calls.forEach((element) => {
        element.fromNumber = element.forwarded_original_caller_id
          ? element.forwarded_original_caller_id
          : element.src;
  
        element.toNumber = element.forwarded_to_number
          ? element.forwarded_to_number
          : element.dst;
  
        element.saveDurationCall = new Date(element.billsec * 1000)
          .toISOString()
          .substr(
            element.billsec - element.billing_seconds < 3600 ? 14 : 11,
            element.billsec - element.billing_seconds < 3600 ? 5 : 8
          );
  
        element.datetime = convertTimeStapToTimeZoneOnFormated(
          element.calldate,
          myExtension.time_zone
        );
  
        if (element.disposition === "ANSWERED") {
          element.style = {
            background: "#F6F6F9 0% 0% no-repeat padding-box",
            borderRadius: "12px",
            font: "normal normal normal 12px/16px Instrument Sans",
            letterSpacing: "0px",
            color: "#199F28",
            opacity: "1",
          }
        } else {
          element.style = {
            background: "#1F3D571E 0% 0% no-repeat padding-box",
            borderRadius: "12px",
            font: "normal normal normal 12px/16px Instrument Sans",
            letterSpacing: "0px",
            color: "#3A3A3ACC",
            opacity: "1",
          }
        }      
      });
    } else {
      e.incoming_calls = []
    }

    if (e.outgoing_calls) {
      e.outgoing_calls.forEach((element) => {
        element.fromNumber = element.forwarded_original_caller_id
          ? element.forwarded_original_caller_id
          : element.src;
  
        element.toNumber = element.forwarded_to_number
          ? element.forwarded_to_number
          : element.dst;
  
        element.saveDurationCall = new Date(element.billsec * 1000)
          .toISOString()
          .substr(
            element.billsec - element.billing_seconds < 3600 ? 14 : 11,
            element.billsec - element.billing_seconds < 3600 ? 5 : 8
          );
  
        element.datetime = convertTimeStapToTimeZoneOnFormated(
          element.calldate,
          myExtension.time_zone
        );
  
        if (element.disposition === "ANSWERED") {
          element.style = {
            background: "#F6F6F9 0% 0% no-repeat padding-box",
            borderRadius: "12px",
            font: "normal normal normal 12px/16px Instrument Sans",
            letterSpacing: "0px",
            color: "#199F28",
            opacity: "1",
          }
        } else {
          element.style = {
            background: "#1F3D571E 0% 0% no-repeat padding-box",
            borderRadius: "12px",
            font: "normal normal normal 12px/16px Instrument Sans",
            letterSpacing: "0px",
            color: "#3A3A3ACC",
            opacity: "1",
          }
        }      
      });
    } else {
      e.outgoing_calls = []
    }


    setCompletedLogs(e)
    setViewLogs({
      title: [
        { title: "Date & Time", key: "datetime", isDate: true },
        { title: "From", key: "fromNumber" },
        { title: "To", key: "toNumber" },
        { title: "Billable Time", key: "billsec" },
        { title: "Ring Time", key: "duration" },
        {
          title: "Disposition",
          key: "disposition",
          isBadge: true,
          keyStyles: "style",
        },
      ],
      content: [...e.all_calls],
      iconActions: {
        title: "Download recording",
        icon: <FontAwesomeIcon icon={faDownload} />,
        handleClick: downloadCall
      },
    });
  };

  const handleBackClick = () => {
    if (!viewLogs) {
      setDataReport(false);
      petition();
    } else {
      setViewLogs(null);
      setCompletedLogs({})
    }
  };

  const activeDataReport = () => {
    setOpenMenu(false);
    setDataReport(true);
  };

  const onChangeDate = (e) => {
    if (e.target.name === "timeStart") {
      let newTimeEnd;
      if (
        e.target.value.split("-")[1] === timeframe.timeEnd.split("-")[1] &&
        parseInt(e.target.value.split("-")[2]) >
          parseInt(timeframe.timeEnd.split("-")[2])
      ) {
        newTimeEnd = e.target.value;
      } else if (
        parseInt(e.target.value.split("-")[1]) >
        parseInt(timeframe.timeEnd.split("-")[1])
      ) {
        newTimeEnd = e.target.value;
      } else {
        newTimeEnd = timeframe.timeEnd;
      }

      setTimeframe({
        timeStart: e.target.value,
        timeEnd: newTimeEnd,
      });
    } else {
      setTimeframe({ ...timeframe, [e.target.name]: e.target.value });
    }
  };

  const onChangeNumbersReport = (e) => {
    setSelectedNumbers(e);
  };

  const onChangeUsersReport = (e) => {
    setSelectedUsers(e);
  };

  const handleChangeSelectReport = (e) => {
    setSelectedReport(e.target.value);
  };

  const closeMenu = () => {
    setSelectedNumbers(optionsNumberReport);
    setSelectedUsers(optionsUserReport);
    setSelectedReport("");
    setOpenMenu(false);
  };

  const activeMenuReport = () => {
    setOpenMenu(true);
  };

  const petition = async () => {
    setLoading(true);

    await petitionGet("viewNumbers")
      .then(({ data: result }) => {
        result.result.forEach((element) => {
          element.label = element.number;
          element.value = element.number;
        });

        const dateTimeframe = new Date();

        const getTimeStart = `${dateTimeframe.getFullYear()}-${
          dateTimeframe.getMonth() + 1 < 10
            ? `0${dateTimeframe.getMonth() + 1}`
            : `${dateTimeframe.getMonth() + 1}`
        }-${
          dateTimeframe.getDate() < 10
            ? `0${dateTimeframe.getDate() - 1}`
            : `${dateTimeframe.getDate() - 1}`
        }`;

        const getTimeEnd = `${dateTimeframe.getFullYear()}-${
          dateTimeframe.getMonth() + 1 < 10
            ? `0${dateTimeframe.getMonth() + 1}`
            : `${dateTimeframe.getMonth() + 1}`
        }-${
          dateTimeframe.getDate() < 10
            ? `0${dateTimeframe.getDate()}`
            : `${dateTimeframe.getDate()}`
        }`;

        setTimeframe({
          timeStart: getTimeStart,
          timeEnd: getTimeEnd,
        });

        setOptionsNumberReport(result.result);
        setSelectedNumbers(result.result);
      })
      .catch((error) => console.log(error));

    await petitionGet("itpvoiceUsers")
      .then(({ data: result }) => {
        result.result
          .sort((a, b) => a.firstname.localeCompare(b.firstname))
          .forEach((element) => {
            element.label = `${element.firstname} ${element?.lastname || ""}`;
            element.value = element.pk;
          });

        setSelectedUsers(result.result);
        setOptionsUserReport(result.result);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if (
      Object.keys(myExtension).length > 0 &&
      myExtension.priv_level !== "admin"
    ) {
      navigate("/Dashboard");
    }
  }, [myExtension]);

  useEffect(() => {
    petition();
  }, []);

  return (
    <div className="itp-test">
      {loading ? (
        <Loading />
      ) : (
        <>
          {modalError && (
            <ModalError 
              modal={modalError}
              closeModal={closeModal}
            />
          )}

          {dataReport ? (
            <>
              <DataReport
                handleBackClick={handleBackClick}
                selectedReport={selectedReport}
                selectedUsers={selectedUsers}
                selectedNumbers={selectedNumbers}
                timeframe={timeframe}
                viewLogs={viewLogs}
                activeViewLogs={activeViewLogs}
                loadingCall={loadingCall}
                width={width}
                timeZone={myExtension.time_zone}
                completedLogs={completedLogs}
              />
            </>
          ) : (
            <>           
              <Card
                className={`itp-table-container-all itp-alone-property itp-analytics-card-report mt-3 itp-w-entire${
                  width < 992 ? "overflow-hidden" : ""
                }`}
              >
                <div className="d-flex justify-content-between align-items-center">
                  <h2 className="itp-analytics-title mb-0">Call Reports</h2>
                  <button
                    onClick={activeMenuReport}
                    className="btn-primary itp-analytics-button-report"
                  >
                    Run Report
                  </button>
                </div>
                <div className="mt-2">
                  <label className="itp-analytics-label">User Reports</label>
                  <p className="itp-analytics-description mb-0">
                    Generate a report by adding few details / filters to get a
                    report of your preferred time
                  </p>
                </div>
                <div className="mt-3">
                  <label className="itp-analytics-label">Number Reports</label>
                  <p className="itp-analytics-description mb-0">
                    Generate a report by adding few details / filters to get a
                    report of your preferred time
                  </p>
                </div>
              </Card>
            </>
          )}

          <MenuRight
            show={openMenu}
            closeMenu={closeMenu}
            title="Run a Report"
            subtitle="Fill the details below related to the needed report"
            to=""
          >
            <label className="itp-label-input__label">Set Date</label>
            <p className="itp-analytics-description mb-2">
              Set a date to run a report for
            </p>
            <Col lg={9} xl={9} xxl={8}>
              <div className="container-date-range">
                <label className="date-range-label">From:</label>
                <input
                  type="date"
                  className="date-range-input"
                  name="timeStart"
                  value={timeframe.timeStart}
                  onChange={onChangeDate}
                />
                <div>
                  <FontAwesomeIcon
                    icon={faArrowRight}
                    className="date-range-separator"
                  />
                </div>
                <label className="date-range-label">To:</label>
                <input
                  type="date"
                  className="date-range-input"
                  name="timeEnd"
                  value={timeframe.timeEnd}
                  onChange={onChangeDate}
                />
              </div>
            </Col>
            <div className="itp-analytics-container-select-report mt-4 mb-4">
              <label className="itp-label-input__label">Set Date</label>
              <Row className="itp-container-label-select">
                <Col
                  xs={6}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={6}
                  xxl={6}
                  className="itp-label-input-col"
                >
                  <label className="itp-label-input__label itp-analytics-label-select">
                    Select the report type you need
                  </label>
                </Col>
                <Col
                  xs={6}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={6}
                  xxl={6}
                  className="itp-label-input-col"
                >
                  <select
                    className="form-select-itp"
                    onChange={handleChangeSelectReport}
                    name="selectedReport"
                    value={selectedReport}
                  >
                    <option value="" disabled>
                      Select Option
                    </option>
                    <option value="Users">User Report</option>
                    <option value="Numbers">Number Report</option>
                  </select>
                </Col>
              </Row>
            </div>
            {selectedReport && (
              <>
                <div className="itp-analytics-container-select-report mt-4 mb-4">
                  <label className="itp-label-input__label">
                    {selectedReport}
                  </label>
                  <Row className="itp-container-label-select">
                    <Col
                      className="itp-label-input-col"
                      xs={4}
                      sm={4}
                      md={4}
                      lg={4}
                      xl={4}
                      xxl={4}
                    >
                      <label className="itp-label-input__label itp-analytics-label-select">
                        Select the {selectedReport} you need the report for
                      </label>
                    </Col>
                    <Col
                      className="itp-label-input-col"
                      xs={8}
                      sm={8}
                      md={8}
                      lg={8}
                      xl={8}
                      xxl={8}
                    >
                      <Select
                        isMulti
                        onChange={
                          selectedReport === "Users"
                            ? onChangeUsersReport
                            : onChangeNumbersReport
                        }
                        className="basic-single mb-4"
                        classNamePrefix="select"
                        value={
                          selectedReport === "Users"
                            ? selectedUsers
                            : selectedNumbers
                        }
                        isSearchable={true}
                        name=""
                        placeholder={`Select a ${selectedReport}`}
                        options={
                          selectedReport === "Users"
                            ? optionsUserReport
                            : optionsNumberReport
                        }
                      />
                    </Col>
                  </Row>
                </div>
              </>
            )}
            <button
              disabled={
                !selectedReport ||
                !timeframe.timeStart ||
                !timeframe.timeEnd ||
                (selectedReport === "Users" && selectedUsers.length === 0) ||
                (selectedReport === "Numbers" && selectedNumbers.length === 0)
              }
              className="btn-primary loading itp-w-buton mb-2"
              onClick={activeDataReport}
            >
              Run Report
            </button>
          </MenuRight>
        </>
      )}
    </div>
  );
};

export default Analytics;
