import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import {
  faCalendarAlt,
  faCheckSquare,
  faFileText,
  faMapMarkerAlt,
  faPaperclip,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LoadingButton, Loading } from "../../../../../components";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import PhoneInput from "react-phone-input-2";
import Select from "react-select";
import phoneInput from "../../../../../assets/icons/phoneInput.svg";
import email from "../../../../../assets/icons/email.svg";
import tag from "../../../../../assets/icons/tag.svg";
import petitionGet from "../../../../../services/petitionGet";
import petitionPost from "../../../../../services/petitionPost";
import petitionPatch from "../../../../../services/petitionPatch";
import "../../Conversations.css";

const ContactDetails = ({
  chatWindowData,
  openMenu,
  formName,
  setFormName,
  setEditedContact,
  handleActiveCall,
}) => {
  const filter = createFilterOptions();
  const [openDropdown, setOpenDropdown] = useState(false);
  const [fieldType, setFieldType] = useState("");
  const [newField, setNewField] = useState("");
  const [loadingAddField, setLoadingAddField] = useState(false);
  const [form, setForm] = useState({});
  const [valueContactList, setValueContactList] = useState(null);
  const [valueAssignedUser, setValueAssignedUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [tags, setTags] = useState([]);
  const [optionsTags, setOptionsTags] = useState([]);
  const [customFields, setCustomFields] = useState([]);
  const [contactList, setContactList] = useState([]);
  const [users, setUsers] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [newTags, setNewTags] = useState([]);
  const [loadingSave, setLoadingSave] = useState(false);
  const [addContact, setAddContact] = useState(false);
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const toggle = () => {
    setOpenDropdown(!openDropdown);
  };

  const defaultValueAddField = (field) => {
    switch (field) {
      case "address":
        return "Address";
      case "url":
        return "URL";
      case "date":
        return "Date";
      case "text/note":
        return "Text / Note";
      case "checkbox":
        return "Checkbox";
      default:
        return "error";
    }
  };

  const onchangeForm = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleTag = (newValue) => {
    if (newValue.length === 0) {
      setOptionsTags(optionsTags);
      setTags([]);
    } else {
      if (newValue[newValue.length - 1]?.inputValue) {
        let dataFilter = optionsTags.filter(
          (element) =>
            element.name === newValue[newValue.length - 1]?.inputValue
        );
        if (dataFilter.length == 0) {
          setDisabled(true);
          addTag(newValue[newValue.length - 1]?.inputValue);
          setTags(newValue);
        } else {
          setTags(newValue);
        }
      } else {
        setTags(newValue);
      }
    }
  };

  const addTag = (tagName) => {
    petitionPost("addTags", {
      data: {
        name: tagName,
      },
    })
      .then(({ data: result }) => {
        let data = newTags;
        petitionTag();
        setDisabled(false);
        data.push(result.result);
        setNewTags(data);
      })
      .catch((error) => console.log(error));
  };

  const formatTags = () => {
    let dataTagsNew = [];
    tags.map((element) => {
      if (typeof element !== "string") {
        if (element.inputValue) {
          let dataFilter = newTags.filter(
            (elementTwo) => elementTwo?.name === element?.inputValue
          );
          dataTagsNew.push(dataFilter[0]?.pk);
        } else {
          dataTagsNew.push(element.pk);
        }
      }
    });

    let result = dataTagsNew.filter((item, index) => {
      return dataTagsNew.indexOf(item) === index;
    });

    return result;
  };

  const returnCheck = (state) => {
    if (state === true || state === "true" || state === "True") {
      return true;
    } else if (state === false || state === "false" || state === "False") {
      return false;
    } else {
      return false;
    }
  };

  const handleCheck = (state) => {
    if (state === true || state === "true" || state === "True") {
      return false;
    } else if (state === false || state === "false" || state === "False") {
      return true;
    } else {
      return false;
    }
  };

  const addCustomField = () => {
    setLoadingAddField(true);
    let data = {
      name: newField || defaultValueAddField(fieldType),
      field_type: fieldType,
      field_metadata: {
        max_length: 150,
      },
    };
    petitionPost("customFieldMyExtension", { data })
      .then(({ data: result }) => {
        petitionGet("customFields")
          .then(({ data: result }) => {
            result.result.forEach((element) => {
              element.label = element.name;
              element.value = element.pk;
            });

            setCustomFields(result.result);
            setLoadingAddField(false);
            setFieldType("");
            setNewField("");
          })
          .catch((error) => console.log(error));
      })
      .catch((error) => {
        console.log(error);
        setLoadingAddField(false);
      });
  };

  const onSaveChange = () => {
    setLoadingSave(true);
    let data = {
      ...formName,
      tags: formatTags(),
      contact_list_id: valueContactList.value,
      phone: form.phone === "+" || form.phone.length === 2 ? "" : form.phone,
      ...form,
    };

    if (valueAssignedUser) data.owner_id = valueAssignedUser.value;

    editContact(data);
  };

  const onSaveAdd = () => {
    setLoadingSave(true);

    let data = {
      ...formName,
      contact_list_id: valueContactList.value,
      phone: form.phone === "+" || form.phone.length === 2 ? "" : form.phone,
      ...form,
    };

    petitionPost("contact", { data })
      .then(({ data: result }) => {
        chatWindowData.current.notIsSelf.contact = result.result;

        chatWindowData.current.numberChat = result.result.phone;

        chatWindowData.current.firstname = result.result.firstname;

        chatWindowData.current.lastname = result.result.lastname;

        setFormName({
          firstname: result.result.firstname,
          lastname: result.result.lastname,
        });
        setEditedContact(chatWindowData?.current?.notIsSelf?.message_thread_id);
        forceUpdate();
        petition(false, chatWindowData?.current);
      })
      .catch((error) => {
        console.log(error);
        setLoadingSave(false);
      });
  };

  const editContact = (form) => {
    petitionPatch("contact", {
      data: form,
      contact_id: chatWindowData?.current?.notIsSelf?.contact?.pk,
    })
      .then(({ data: result }) => {
        chatWindowData.current.notIsSelf.contact = result.result;

        chatWindowData.current.numberChat = result.result.phone;

        chatWindowData.current.firstname = result.result.firstname;

        chatWindowData.current.lastname = result.result.lastname;

        setFormName({
          firstname: result.result.firstname,
          lastname: result.result.lastname,
        });
        setEditedContact(chatWindowData?.current?.notIsSelf?.message_thread_id);
        forceUpdate();
        petition(false, chatWindowData?.current);
      })
      .catch((error) => {
        console.log(error);
        setLoadingSave(false);
      });
  };

  const petitionTag = async () => {
    try {
      const response = await petitionGet("tags");
      const { data: result } = response;

      result.result.forEach((element) => {
        element.title = element.name;
        element.disabled = false;
        element.label = element.name;
        element.value = element.pk;
      });

      setOptionsTags(result.result);

      return result.result; // Devuelve la respuesta del endpoint
    } catch (error) {
      console.log(error);
    }
  };

  const petition = async (noLoading, dataContact) => {
    if (!noLoading) setLoading(true);

    await petitionTag();

    await petitionGet("users")
      .then(({ data: result }) => {
        result.result.forEach((element) => {
          element.label = `${element.firstname} ${element.lastname}`;
          element.value = element.pk;
        });

        let dataFind = result.result.find(
          (user) => user.pk === dataContact?.notIsSelf?.contact?.owner_id
        );

        if (dataFind) {
          setValueAssignedUser(dataFind);
        }

        setUsers(result.result);
      })
      .catch((error) => console.log(error));

    await petitionGet("contactList")
      .then(({ data: result }) => {
        result.result.forEach((element) => {
          element.label = `${element.list_name} (${element.contact_count})`;
          element.value = element.pk;
        });

        let dataFind = result.result.find(
          (user) => user.pk === dataContact?.notIsSelf?.contact?.contact_list_id
        );

        setValueContactList(dataFind);
        setContactList(result.result);
      })
      .catch((error) => console.log(error));

    await petitionGet("customFields")
      .then(({ data: result }) => {
        result.result.forEach((element) => {
          element.label = element.name;
          element.value = element.pk;
        });

        let currentData = dataContact?.notIsSelf?.contact || [];

        let formatedTags = [];

        if (currentData?.tags) {
          formatedTags = currentData?.tags.map((tag) => ({
            ...tag,
            title: tag.tag.name,
            pk: tag.tag_id,
          }));
        }

        let valueCustomField = {};

        result.result.forEach((element) => {
          valueCustomField[element.name] = currentData[element.name];
        });

        currentData.tags = formatedTags;

        setForm({
          phone: dataContact?.notIsSelf?.contact?.phone || "",
          email: dataContact?.notIsSelf?.contact?.email || "",
          ...valueCustomField,
        });
        setCustomFields(result.result);
        setTags(formatedTags);
        setLoading(false);
        setLoadingSave(false);
      })
      .catch((error) => console.log(error));
  };

  const petitionAddContact = (dataContact) => {
    petitionGet("contactList")
      .then(({ data: result }) => {
        result.result.forEach((element) => {
          element.label = `${element.list_name} (${element.contact_count})`;
          element.value = element.pk;
        });

        setForm({
          phone: dataContact.numberChat,
          email: "",
        });

        setValueContactList(result.result[0]);
        setContactList(result.result);
        setAddContact(true);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if (openMenu) {
      if (chatWindowData?.current?.isContact) {
        petition(false, chatWindowData?.current);
      } else {
        petitionAddContact(chatWindowData?.current);
      }
    } else {
      setOpenDropdown(false);
      setFieldType("");
      setNewField("");
      setLoadingAddField(false);
      setForm({});
      setValueContactList(null);
      setValueAssignedUser(null);
      setLoading(true);
      setTags([]);
      setOptionsTags([]);
      setCustomFields([]);
      setContactList([]);
      setUsers([]);
      setDisabled(false);
      setNewTags([]);
      setLoadingSave(false);
      setAddContact(false);
    }
  }, [openMenu]);

  return loading ? (
    <Loading />
  ) : (
    <>
      {!addContact && (
        <>
          <Row className="itp-container-label-input itp-chat-menu-body__set-account">
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              xxl={12}
              className="itp-label-input-col"
            >
              <input
                type="text"
                placeholder=": Enter contact First Name"
                className="form-control-itp itp-label-input__input"
                name="firstname"
                disabled
                defaultValue="Set an Account"
              />
            </Col>
          </Row>
          <Row className="itp-container-label-select margin itp-chat-menu-body__set-account">
            <Col
              xs={1}
              sm={1}
              md={1}
              lg={1}
              xl={1}
              xxl={1}
              className="itp-label-input-col"
            >
              <img
                src={phoneInput}
                alt=""
                className="itp-contact-details-menu cursor-pointer"
                onClick={() => handleActiveCall(true, form.phone)}
              />
            </Col>
            <Col
              xs={10}
              sm={10}
              md={10}
              lg={10}
              xl={10}
              xxl={10}
              className="itp-label-input-col"
            >
              <PhoneInput
                country={"us"}
                value={form.phone}
                disableCountryFlags={true}
                enableSearch={true}
                countryCodeEditable={false}
                onChange={(e) => {
                  setForm({ ...form, phone: `+${e}` });
                }}
              />
            </Col>
          </Row>

          <Row className="itp-container-label-input">
            <Col
              xs={1}
              sm={1}
              md={1}
              lg={1}
              xl={1}
              xxl={1}
              className="itp-label-input-col"
            >
              <img src={email} className="itp-contact-details-menu" />
            </Col>
            <Col
              xs={11}
              sm={11}
              md={11}
              lg={11}
              xl={11}
              xxl={11}
              className="itp-label-input-col"
            >
              <input
                type="text"
                placeholder=": Enter a email"
                className="form-control-itp itp-label-input__input"
                name="email"
                value={form.email}
                onChange={onchangeForm}
              />
            </Col>
          </Row>

          <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
              <div className="itp-component-tag-container">
                <img
                  src={tag}
                  alt="Add Tag"
                  className="itp-component-tag-img"
                />
                <div className="itp-component-tag-width">
                  <Stack>
                    <Autocomplete
                      disableClearable
                      disablePortal
                      filterSelectedOptions
                      disabled={disabled}
                      freeSolo
                      multiple
                      defaultValue={tags}
                      getOptionLabel={(option) => {
                        // Value selected with enter, right from the input
                        if (typeof option === "string") {
                          return "error";
                        }
                        // Add "xxx" option created dynamically
                        if (option.inputValue) {
                          return option.inputValue;
                        }

                        // Regular option
                        return option.title;
                      }}
                      getOptionDisabled={(option) => !!option.disabled}
                      options={optionsTags}
                      id="tags-standard"
                      isOptionEqualToValue={(option, value) =>
                        option.title === value.title
                      }
                      onChange={(event, newValue) => handleTag(newValue)}
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        const { inputValue } = params;
                        // Suggest the creation of a new value
                        const isExisting = options.some(
                          (option) => inputValue === option.title
                        );
                        if (inputValue !== "" && !isExisting) {
                          filtered.push({
                            inputValue,
                            title: `Add ${inputValue}`,
                          });
                        }

                        return filtered;
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          placeholder="Add Tags"
                        />
                      )}
                      renderOption={(props, option) => (
                        <li {...props}>{option.title}</li>
                      )}
                    />
                  </Stack>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="itp-contact-details-row-select">
            <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
              <label className="itp-contact-details-label chat-menu-label">
                Contact List
              </label>
            </Col>
            <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
              <Select
                onChange={(e) => {
                  setValueContactList(e);
                }}
                className="basic-single"
                classNamePrefix="select"
                value={valueContactList}
                isSearchable={true}
                name="contact_list_id"
                options={contactList}
              />
            </Col>
          </Row>
          <Row className="itp-contact-details-row-select">
            <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
              <label className="itp-contact-details-label">Assigned User</label>
            </Col>
            <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
              <Select
                onChange={(e) => setValueAssignedUser(e)}
                className="basic-single"
                classNamePrefix="select"
                value={valueAssignedUser}
                isSearchable={true}
                name="owner_id"
                placeholder="Select a User"
                options={users}
              />
            </Col>
          </Row>

          {customFields.map((element) => (
            <Row
              key={element.name}
              className={`itp-container-label-${
                element.field_type !== "list" &&
                element.field_type !== "checkbox"
                  ? "input"
                  : "select"
              }`}
            >
              <Col
                xs={5}
                sm={5}
                md={5}
                lg={5}
                xl={5}
                xxl={5}
                className="itp-label-input-col"
              >
                <label className="itp-label-input__label">{element.name}</label>
              </Col>
              <Col
                xs={7}
                sm={7}
                md={7}
                lg={7}
                xl={7}
                xxl={7}
                className="itp-label-input-col"
              >
                {element.field_type !== "list" &&
                  element.field_type !== "checkbox" && (
                    <input
                      type="text"
                      placeholder=": Enter address"
                      className="form-control-itp itp-label-input__input"
                      name={element.name}
                      value={form[element.name] || ""}
                      onChange={onchangeForm}
                    />
                  )}

                {element.field_type === "list" && (
                  <select
                    className="form-select-itp"
                    onChange={onchangeForm}
                    name={element.name}
                    value={form[element.name] || ""}
                  >
                    <option value="" disabled>
                      Select Option
                    </option>
                    {element.field_metadata.list_values.map((val, i) => (
                      <option key={i} value={val}>
                        {val}
                      </option>
                    ))}
                  </select>
                )}

                {element.field_type === "checkbox" && (
                  <input
                    checked={returnCheck(form[element.name]) || false}
                    name={element.name}
                    onChange={(e) => {
                      setForm({
                        ...form,
                        [e.target.name]: handleCheck(
                          form[element.name] || false
                        ),
                      });
                    }}
                    type="checkbox"
                    className="form-check-input itp-custom-checkbox-contact-details"
                  />
                )}
              </Col>
            </Row>
          ))}

          {fieldType === "" && (
            <Dropdown
              isOpen={openDropdown}
              toggle={toggle}
              className="itp-contact-details-add-field"
            >
              <DropdownToggle className="btn-light">
                {<FontAwesomeIcon icon={faPlus} className="itp-icon-plus" />}{" "}
                Add Field
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  onClick={() => setFieldType("address")}
                  className="dropdown-item"
                >
                  {<FontAwesomeIcon icon={faMapMarkerAlt} />} Address
                </DropdownItem>
                <DropdownItem
                  onClick={() => setFieldType("url")}
                  className="dropdown-item"
                >
                  {<FontAwesomeIcon icon={faPaperclip} />} URL
                </DropdownItem>
                <DropdownItem
                  onClick={() => setFieldType("date")}
                  className="dropdown-item"
                >
                  {<FontAwesomeIcon icon={faCalendarAlt} />} Date
                </DropdownItem>
                <DropdownItem
                  onClick={() => setFieldType("text/note")}
                  className="dropdown-item"
                >
                  {<FontAwesomeIcon icon={faFileText} />} Text / Note
                </DropdownItem>
                <DropdownItem
                  onClick={() => setFieldType("checkbox")}
                  className="dropdown-item"
                >
                  {<FontAwesomeIcon icon={faCheckSquare} />} Checkbox
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          )}

          {fieldType !== "" && (
            <div className="itp-contact-details-new-field">
              <input
                className="itp-contact-details-new-field__input"
                defaultValue={defaultValueAddField(fieldType)}
                onChange={(e) => setNewField(e.target.value)}
              />
              {loadingAddField ? (
                <LoadingButton />
              ) : (
                <button
                  onClick={addCustomField}
                  className="itp-contact-details-new-field__button save"
                >
                  Save
                </button>
              )}
              <button
                className="itp-contact-details-new-field__button"
                onClick={() => setFieldType("")}
                disabled={loadingAddField}
              >
                Delete
              </button>
            </div>
          )}

          <button
            onClick={onSaveChange}
            className="btn-primary itp-contact-detail-button-save loading"
            disabled={loadingSave}
          >
            {loadingSave && <LoadingButton />}
            Save Changes
          </button>
        </>
      )}

      {addContact && (
        <>
          <p className="mt-3">Add Contact</p>
          <Row className="itp-container-label-select margin itp-chat-menu-body__set-account">
            <Col
              xs={1}
              sm={1}
              md={1}
              lg={1}
              xl={1}
              xxl={1}
              className="itp-label-input-col"
            >
              <img
                src={phoneInput}
                alt=""
                className="itp-contact-details-menu cursor-pointer"
                onClick={() => handleActiveCall(true, form.phone)}
              />
            </Col>
            <Col
              xs={10}
              sm={10}
              md={10}
              lg={10}
              xl={10}
              xxl={10}
              className="itp-label-input-col"
            >
              <PhoneInput
                country={"us"}
                value={form.phone}
                disableCountryFlags={true}
                enableSearch={true}
                countryCodeEditable={false}
                onChange={(e) => {
                  setForm({ ...form, phone: `+${e}` });
                }}
              />
            </Col>
          </Row>
          <Row className="itp-container-label-input"> 
            <Col
              xs={1}
              sm={1}
              md={1}
              lg={1}
              xl={1}
              xxl={1}
              className="itp-label-input-col"
            >
              <img src={email} className="itp-contact-details-menu" />
            </Col>
            <Col
              xs={11}
              sm={11}
              md={11}
              lg={11}
              xl={11}
              xxl={11}
              className="itp-label-input-col"
            >
              <input
                type="text"
                placeholder=": Enter a email"
                className="form-control-itp itp-label-input__input"
                name="email"
                value={form.email}
                onChange={onchangeForm}
              />
            </Col>
          </Row>
          <Row className="itp-contact-details-row-select">
            <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
              <label className="itp-contact-details-label chat-menu-label">
                Contact List
              </label>
            </Col>
            <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
              <Select
                onChange={(e) => {
                  setValueContactList(e);
                }}
                className="basic-single"
                classNamePrefix="select"
                value={valueContactList}
                isSearchable={true}
                name="contact_list_id"
                options={contactList}
              />
            </Col>
          </Row>

          <button
            onClick={onSaveAdd}
            className="btn-primary itp-contact-detail-button-save loading"
            disabled={
              loadingSave ||
              !formName?.firstname ||
              !formName?.firstname ||
              (form?.phone &&
                (form?.phone === "+" || form?.phone?.length === 2))
            }
          >
            {loadingSave && <LoadingButton />}
            Save Changes
          </button>
        </>
      )}
    </>
  );
};

export default ContactDetails;
