import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbtack } from "@fortawesome/free-solid-svg-icons";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import {
  TabMenu,
  MenuRight,
  CustomTableDefault,
  Loading,
  Filter,
} from "../../../components";
import SearchIcon from "@mui/icons-material/Search";
import getQueryString from "../../../utils/getQueryString";
import ContactDetails from "./ContactDetails/ContactDetails";
import AddContact from "./AddContact/AddContact";
import ModalDelete from "./AddContact/ModalUtility/ModalDelete";
import ModalTags from "./AddContact/ModalUtility/ModalTags";
import petitionGet from "../../../services/petitionGet";
import petitionPost from "../../../services/petitionPost";
import petitionPatch from "../../../services/petitionPatch";
import petitionDelete from "../../../services/petitionDelete";
import ContactList from "./Tabs/ContactList/ContactList";
import Tags from "./Tabs/Tags/Tags";
import pin from "../../../assets/icons/pin.svg";
// import email from "../../../assets/icons/email.svg";
// import voicemail from "../../../assets/icons/voicemail.svg";
// import message from "../../../assets/icons/message.svg";
import addTag from "../../../assets/icons/addTag.svg";
import removeTag from "../../../assets/icons/removeTag.svg";
import deleteIcon from "../../../assets/icons/deleteIcon.svg";
import axios from "axios";
import json from "../../../config.json";
import "./Contacts.css";

function Contacts({ handleActiveCall }                                                                                                                                       ) {
  const filterInput = useRef("");
  const filterRef = useRef("");
  const firstLoad = useRef(true);
  const customFields = useRef([]);
  const contactListRef = useRef([]);
  const myExtension = useRef(null);
  const itemsPerPage = useRef("10");
  const pagination = useRef({
    offset: 0,
    limit: 10,
  });
  const navigate = useNavigate();
  const [defaultSelectedOptions, setDefaultSelectedOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tab, setTab] = useState(3);
  const [openMenu, setOpenMenu] = useState(false);
  const [showAddContactMenu, setShowAddContactMenu] = useState(false);
  const [contactDetailsMenu, setContactDetailsMenu] = useState(null);
  const [dataTable, setDataTable] = useState(null);
  const [contactList, setContactList] = useState([]);
  const [loadingMenuButton, setLoadingMenuButton] = useState(false);
  const [tags, setTags] = useState([]);
  const [modalDelete, setModalDelete] = useState(false);
  const [allResult, setAllResult] = useState(null);
  const [text, setText] = useState("");
  const [infoContactsSelected, setInfoContactsSelected] = useState([]);
  const [dataFilter, setDataFilter] = useState(null);
  const [openFilter, setOpenFilter] = useState(false);
  const [loadingFilter, setLoadingFilter] = useState(false);
  const [importContacts, setImportContacts] = useState(false);
  const [importedContacts, setImportedContacts] = useState(false);
  const [addedContactListImort, setAddedContactListImort] = useState(false);
  const [activePincontact, setActivePincontact] = useState(false);
  const [modalTag, setModalTag] = useState(false);
  const [titleModalTag, setTitleModalTag] = useState("");
  const [params, setParams] = useState(window.location.search);
  const [loadingChangeItemsPerPage, setLoadingChangeItemsPerPage] =
    useState(false);
  const [
    addedCustomFieldInContactDetails,
    setAddedCustomFieldInContactDetails,
  ] = useState(false);
  const [options, setOptions] = useState([
    { value: "Name", label: "Name" },
    { value: "E-mail", label: "E-mail" },
    { value: "Phone Number", label: "Phone Number" },
    { value: "Last Activity", label: "Last Activity" },
    { value: "Tags", label: "Tags" },
  ]);

  const [inputFocused, setInputFocused] = useState(false);
  const [valueSearchInput, setValueSearchInput] = useState("");

  const tabs = [
    {
      title: "Contact List",
      component: <ContactList />,
    },
    {
      title: "Tags",
      component: <Tags />,
    },
  ];

  const handleChangeInput = (value) => {
    let parameter = "";
    let parameterInput = "";

    if (value !== "") {
      parameterInput = `&firstname=${value}&lastname=${value}&mobile=${value}&phone=${value}`;
    }

    let paginationTemp = {
      ...pagination.current,
      offset: 0,
    };

    parameter =
      getQueryString(paginationTemp) +
      filterRef.current +
      parameterInput;

    petition(parameter, true);
    pagination.current = paginationTemp;
    filterInput.current = parameterInput;
  };

  const handleInputFocus = () => {
    setInputFocused(true);
  };

  const handleInputBlur = () => {
    setInputFocused(false);
  };

  const activeContactDetails = (e) => {
    setContactDetailsMenu(e);
    setOpenMenu(true);
  };

  const activeFilterDetails = () => {
    setOpenFilter(true);
    setOpenMenu(true);
  };

  const activeModalDelete = (e) => {
    setModalDelete(e);
  };

  const activeAddContact = () => {
    setShowAddContactMenu(true);
    setOpenMenu(true);
  };

  const clickChange = (selected, data, titles) => {
    let noViewColumns = [];
    titles.forEach((element) => {
      if (selected.includes(element.title)) {
        element.viewColumn = true;
      } else {
        element.viewColumn = false;
        noViewColumns.push(element.title);
      }
    });
    showColumns(noViewColumns);
    setDataTable({ ...data, title: titles });
  };

  const closeMenu = (reload) => {
    setTitleModalTag("");
    setModalTag(false);
    setImportContacts(false);
    setOpenFilter(false);
    setModalDelete(false);
    setContactDetailsMenu(null);
    setShowAddContactMenu(false);
    setOpenMenu(false);

    if (params) {
      navigate("/Contacts");
      setParams("");
    }

    if (reload) {
      reloadData(false, false);
    }
  };

  const backFunction = () => {
    setTab(3);
  };

  const reloadData = (noLoading, pag) => {
    closeMenu(false);
    if (pag) {
      let parameter =
        getQueryString(pag) + filterRef.current + filterInput.current;
      petition(parameter, noLoading);
      pagination.current = pag;
    } else {
      let parameter =
        getQueryString(pagination.current) +
        filterRef.current +
        filterInput.current;
      petition(parameter, noLoading);
    }
  };

  const addContact = async (form) => {
    setLoadingMenuButton(true);
    await petitionPost("contact", { data: form })
      .then(({ data: result }) => {
        setLoadingMenuButton(false);
        reloadData(false, false);
      })
      .catch((error) => {
        console.log(error);
        setLoadingMenuButton(false);
      });
  };

  const editContact = (form) => {
    setLoadingMenuButton(true);
    petitionPatch("contact", { data: form, contact_id: contactDetailsMenu.pk })
      .then(({ data: result }) => {
        setLoadingMenuButton(false);
        reloadData(false, false);
      })
      .catch((error) => {
        console.log(error);
        setLoadingMenuButton(false);
      });
  };

  const deleteContact = () => {
    setLoadingMenuButton(true);
    petitionDelete("contact", { contact_id: modalDelete.pk })
      .then(({ data: result }) => {
        setLoadingMenuButton(false);
        if (allResult.result.length === 1 && parseInt(allResult.offset) !== 0) {
          let paginationTemp = {
            limit: pagination.current.limit,
            offset: pagination.current.offset - pagination.current.limit,
          };
          reloadData(false, paginationTemp);
        } else {
          reloadData(false, false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoadingMenuButton(false);
      });
  };

  const petitionTag = async () => {
    try {
      const response = await petitionGet("tags");
      const { data: result } = response;

      result.result.forEach((element) => {
        element.title = element.name;
        element.disabled = false;
        element.label = element.name;
        element.value = element.pk;
      });

      setTags(result.result);

      return result.result; // Devuelve la respuesta del endpoint
    } catch (error) {
      console.log(error);
    }
  };

  const changePage = (page) => {
    let paginationTemp = {
      limit: pagination.current.limit,
      offset: page === 1 ? 0 : (page - 1) * pagination.current.limit,
    };

    let parameter = getQueryString(paginationTemp) + filterRef.current + filterInput.current;

    petition(parameter, true, true);
    pagination.current = paginationTemp;
  };

  const activeDeleteMultiplecontacts = (contactSelected) => {
    let string = `Are you sure you want to delete ${contactSelected.length} contacts`;
    setText(string);
    setInfoContactsSelected(contactSelected);
    setModalDelete(true);
  };

  const activePinMultipleContacts = (contactSelected) => {
    let string = `Are you sure you want to pin ${contactSelected.length} contacts`;
    setText(string);
    setActivePincontact(true);
    setInfoContactsSelected(contactSelected);
    setModalDelete(true);
  };

  const activeAddOrDeleteTagsInContacts = (contactSelected, title) => {
    setInfoContactsSelected(contactSelected);
    setModalTag(true);
    setTitleModalTag(title);
  };

  const pinMultipleContacts = () => {
    setLoadingMenuButton(true);
    let data = {
      contacts: infoContactsSelected,
      payload: {
        pinned: true,
      },
    };

    petitionPatch("updateMultipleContacts", { data })
      .then(({ data: result }) => {
        setLoadingMenuButton(false);
        reloadData(false, false);
      })
      .catch((error) => console.log(error));
  };

  const pinOnlyOneContact = (pk, addOrRemove) => {
    let data = {
      contacts: [pk],
      payload: {
        pinned: addOrRemove,
      },
    };

    petitionPatch("updateMultipleContacts", { data })
      .then(({ data: result }) => {
        reloadData(true, false);
      })
      .catch((error) => console.log(error));
  };

  const addOrDeleteTagsInContacts = (form) => {
    setLoadingMenuButton(true);
    let data = {
      contacts: infoContactsSelected,
      payload: form,
    };

    petitionPatch("updateMultipleContacts", { data })
      .then(({ data: result }) => {
        setLoadingMenuButton(false);
        reloadData(false, false);
      })
      .catch((error) => console.log(error));
  };

  const deleteMultipleContacts = () => {
    setLoadingMenuButton(true);
    const token = JSON.parse(localStorage.getItem("userDetails")).access_token;
    const id = JSON.parse(localStorage.getItem("userDetails")).api_id;
    const urlBase = json.prod ? json.urlBase.prod : json.urlBase.dev;
    const url = urlBase + `/360/accounts/${id}/my-account/contacts/batch`;

    let data = {
      contact_ids: infoContactsSelected,
    };

    let headers = {
      Authorization: "Bearer " + token.replace(/['"]+/g, ""),
    };

    axios
      .delete(url, { headers, data })
      .then(({ data: result }) => {
        setLoadingMenuButton(false);
        closeMenu(false);

        let paginationTemp = {};

        if (allResult.result.length === 1 && pagination.current.offset > 0) {
          paginationTemp = {
            ...pagination.current,
            offset: pagination.current.offset - 10,
          };
        } else {
          paginationTemp = pagination.current;
        }

        let parameter = getQueryString(paginationTemp) + filterRef.current + filterInput.current;
        petition(parameter);
        pagination.current = paginationTemp;
      })
      .catch((error) => console.log(error));
  };

  const showOrNotShowcolumns = (name, arr) => {
    if (arr.includes(name)) {
      return false;
    } else {
      return true;
    }
  };

  const showColumns = (arrayColumns) => {
    petitionPatch("userDetails", {
      data: {
        contacts_settings_display_columns: {
          columns: arrayColumns,
        },
      },
    })
      .then(({ data: result }) => {})
      .catch((error) => console.log(error));
  };

  const selectTitleMenu = () => {
    if (showAddContactMenu && !contactDetailsMenu && !openFilter) {
      return "Add New Contacts";
    } else if (contactDetailsMenu && !showAddContactMenu && !openFilter) {
      return "Contact Details";
    } else if (openFilter && !contactDetailsMenu && !showAddContactMenu) {
      return "Add Filters";
    }
  };

  const onSaveFunction = (form) => {
    setLoadingFilter(true);
    let paginationTemp = {
      limit: pagination.current.limit,
      offset: 0,
    };

    if (!form.owner_id) {
      delete form.owner_id;
    } else {
      form.owner_id = myExtension.current.pk;
    }

    let filterModal = getQueryString(form).replaceAll("?", "&");
    let parameter = getQueryString(paginationTemp) + filterModal + filterInput.current;

    let filterCustomFields = [];

    customFields.current.forEach((element) => {
      if (
        element.name !== "stuffed" &&
        element.field_type &&
        element.field_metadata
      ) {
        if (element.field_type === "list") {
          let values = [];
          element.field_metadata.list_values.forEach((value) => {
            values.push({
              label: value,
              value: value,
            });
          });
          filterCustomFields.push({
            label: element.name,
            input: "select",
            inputValue: form[`custom_${element.name}`] || "",
            checkbox: true,
            checkboxValue: form[`custom_${element.name}`] ? true : false,
            checkboxName: `checkbox_${element.name}`,
            options: values,
            key: `custom_${element.name}`,
          });
        } else if (element.field_type === "checkbox") {
          filterCustomFields.push({
            label: element.name,
            input: "checkbox",
            inputValue: form[`custom_${element.name}`] ? true : false,
            checkbox: true,
            checkboxValue:
              typeof form[`custom_${element.name}`] === "boolean"
                ? true
                : false,
            checkboxName: `checkbox_${element.name}`,
            key: `custom_${element.name}`,
          });
        } else {
          filterCustomFields.push({
            label: element.name,
            input: "text",
            inputValue: form[`custom_${element.name}`] || "",
            checkbox: true,
            checkboxValue: form[`custom_${element.name}`] ? true : false,
            checkboxName: `checkbox_${element.name}`,
            key: `custom_${element.name}`,
          });
        }
      }
    });

    petitionPatch("userDetails", {
      data: {
        crm_filters: {
          contacts: form,
        },
      },
    })
      .then(({ data: result }) => {
        setLoadingFilter(false);
        closeMenu(false);
        firstLoad.current = false;
        setDataFilter({
          onSaveFunction: (form) => onSaveFunction(form),
          onHidde: () => console.log("hidde"),
          parameters: [
            {
              title: "Date",
              inputs: [
                {
                  label: "Contact Added Within",
                  input: "date",
                  inputValue_added_after: form.added_after || "",
                  inputValue_added_before: form.added_before || "",
                  checkbox: true,
                  checkboxValue:
                    form.added_after && form.added_before ? true : false,
                  checkboxName: "checkbox_date",
                  key_added_after: "added_after",
                  key_added_before: "added_before",
                },
              ],
            },
            {
              title: "Assigned User",
              inputs: [
                {
                  label: "Only Show Contacts Assigned to Me",
                  input: "switch",
                  inputValue: form.owner_id ? true : false,
                  key: "owner_id",
                },
              ],
            },
            {
              title: "Custom Fields",
              inputs: filterCustomFields,
            },
            {
              title: "Tags",
              inputs: [
                {
                  label: "Tags Added to a Contact",
                  input: "multiSelect",
                  inputValue: form.tags || [],
                  checkbox: true,
                  checkboxValue: form.tags ? true : false,
                  checkboxName: `checkbox_tags`,
                  options: tags,
                  key: "tags",
                },
              ],
            },
            {
              title: "Contact List",
              inputs: [
                {
                  label: "Contact List",
                  input: "selectAutoComplete",
                  inputValue: form.contact_list_id || [],
                  checkbox: true,
                  checkboxValue: form.contact_list_id ? true : false,
                  checkboxName: `checkbox_contact_list`,
                  options: contactListRef.current,
                  key: "contact_list_id",
                },
              ],
            },
          ],
        });
        petition(parameter);
        pagination.current = paginationTemp;
        filterRef.current = filterModal;
      })
      .catch((error) => console.log(error));
  };

  const activeItemsPerPage = (data) => {
    setLoadingChangeItemsPerPage(true);
    let paginationTemp = {
      offset: 0,
      limit: parseInt(data),
    };

    let parameter =
      getQueryString(paginationTemp) +
      (filterRef.current || "") +
      filterInput.current;
    petition(parameter);
    itemsPerPage.current = data;
    pagination.current = paginationTemp;
  };

  const modalDeleteFunction = () => {
    if (!text && !activePincontact) deleteContact();
    if (!activePincontact) deleteMultipleContacts();
    if (activePincontact) pinMultipleContacts();
  };

  const handleCopyNumber = (number) => {
    navigator.clipboard
      .writeText(number)
      .then(() => {})
      .catch((error) => {
        console.error(error);
      });
  };

  const onClickPhone = (element) => {
    handleActiveCall(true, element.phone)
  }

  const petition = async (
    parameter,
    noLoading,
    changePag,
    parameterContact
  ) => {
    if (!noLoading) setLoading(true);

    let contactListTemp = [];
    let customFieldsColumns = [];
    let noViewColumns = [];
    let customFieldsTemp = [];
    let tagsTemp = [];
    let myExtensionTemp = {};
    let defaultColumnsSelected = [];

    await petitionGet("contactList")
      .then(({ data: result }) => {
        result.result.forEach((element) => {
          element.label = `${element.list_name} (${element.contact_count})`;
          element.value = element.pk;
          if (element.list_name !== "Default") {
            element.icon = <FontAwesomeIcon icon={faTrash} />;
          }
        });
        contactListTemp = result.result;
        contactListRef.current = result.result;
        setContactList(result.result);
      })
      .catch((error) => console.log(error));

    if (!changePag) {
      tagsTemp = await petitionTag();

      await petitionGet("userDetails")
        .then(({ data: result }) => {
          noViewColumns =
            result?.result?.contacts_settings_display_columns?.columns || [];

          myExtensionTemp = result?.result;

          if (firstLoad.current && myExtensionTemp?.crm_filters?.contacts) {
            if (myExtensionTemp?.crm_filters?.contacts?.owner_id) {
              myExtensionTemp.crm_filters.contacts.owner_id =
                myExtensionTemp.pk;
            }

            let filterModal = getQueryString(
              myExtensionTemp.crm_filters.contacts
            ).replaceAll("?", "&");

            parameter = parameter + filterModal + filterInput.current;
            filterRef.current = filterModal;
          }

          options.forEach((element) => {
            if (!noViewColumns.includes(element?.label)) {
              defaultColumnsSelected.push(element?.label);
            }
          });

          myExtension.current = myExtensionTemp;
        })
        .catch((error) => console.log(error));

      await petitionGet("customFields")
        .then(({ data: result }) => {
          console.log(result.result);
          let names = options;
          result.result.forEach((element) => {
            element.label = element.name;
            element.value = element.pk;

            if (!noViewColumns.includes(element.name)) {
              defaultColumnsSelected.push(element.name);
            }

            if (firstLoad.current || addedCustomFieldInContactDetails) {
              names.push({ label: element.name, value: element.name });
            }
            customFieldsColumns.push({
              title: element.name,
              key: element.name,
              viewColumn: noViewColumns.includes(element.name) ? false : true,
              check: element.field_type === "checkbox" ? true : false,
            });
          });

          customFieldsTemp = result.result;
          setDefaultSelectedOptions(defaultColumnsSelected);
          setOptions(names);
          customFields.current = result.result;
        })
        .catch((error) => console.log(error));
    }

    if (!parameterContact) {
      await petitionGet("contacts", { parameter })
        .then(({ data: result }) => {
          setAllResult(result);
          result.result.forEach((element) => {
            if (element.pinned) {
              element.hasIcon = true;
            }

            element.contactName = `${element.firstname} ${
              element.lastname || ""
            }`;

            element.contactListName = contactListTemp.find(
              (list) => list.pk === element.contact_list_id
            )?.label;

            element.arrayTags = element.tags.map((tag) => tag.tag.name);

            element.tags = element.tags.map((tag) => ({
              ...tag,
              title: tag.tag.name,
              pk: tag.tag_id,
            }));
          });

          let titles = [
            {
              title: "Name",
              key: "contactName",
              viewColumn: showOrNotShowcolumns("Name", noViewColumns),
              hasIcon: true,
              icon: <img src={pin} alt="Pin" className="icon-hover" />,
            },
            {
              title: "E-mail",
              key: "email",
              viewColumn: showOrNotShowcolumns("E-mail", noViewColumns),
              isEmail: true,
            },
            {
              title: "Phone Number",
              key: "phone",
              viewColumn: showOrNotShowcolumns("Phone Number", noViewColumns),
              isEmail: true,
              isPhone: true,
            },
            {
              title: "Last Activity",
              key: "date_modified",
              viewColumn: showOrNotShowcolumns("Last Activity", noViewColumns),
              isDate: true,
              spliter: "T",
            },
            {
              title: "Tags",
              key: "arrayTags",
              viewColumn: showOrNotShowcolumns("Tags", noViewColumns),
              isArray: true,
            },
            ...customFieldsColumns,
          ];

          if (!myExtensionTemp?.crm_filters?.contacts) {
            let filterCustomFields = [];
            customFieldsTemp.forEach((element) => {
              if (
                element.name !== "stuffed" &&
                element.field_type &&
                element.field_metadata
              ) {
                if (element.field_type === "list") {
                  let values = [];
                  element?.field_metadata?.list_values.forEach((value) => {
                    values.push({
                      label: value,
                      value: value,
                    });
                  });
                  filterCustomFields.push({
                    label: element.name,
                    input: "select",
                    inputValue: "one",
                    checkbox: true,
                    checkboxValue: false,
                    checkboxName: `checkbox_${element.name}`,
                    options: values,
                    key: `custom_${element.name}`,
                  });
                } else if (element.field_type === "checkbox") {
                  filterCustomFields.push({
                    label: element.name,
                    input: "checkbox",
                    inputValue: false,
                    checkbox: true,
                    checkboxValue: false,
                    checkboxName: `checkbox_${element.name}`,
                    key: `custom_${element.name}`,
                  });
                } else {
                  filterCustomFields.push({
                    label: element.name,
                    input: "text",
                    inputValue: "",
                    checkbox: true,
                    checkboxValue: false,
                    checkboxName: `checkbox_${element.name}`,
                    key: `custom_${element.name}`,
                  });
                }
              }
            });

            setDataFilter({
              onSaveFunction: (form) => onSaveFunction(form),
              onHidde: () => console.log("hidde"),
              parameters: [
                {
                  title: "Date",
                  inputs: [
                    {
                      label: "Contact Added Within",
                      input: "date",
                      inputValue_added_after: "",
                      inputValue_added_before: "",
                      checkbox: true,
                      checkboxValue: false,
                      checkboxName: "checkbox_date",
                      key_added_after: "added_after",
                      key_added_before: "added_before",
                    },
                  ],
                },
                {
                  title: "Assigned User",
                  inputs: [
                    {
                      label: "Only Show Contacts Assigned to Me",
                      input: "switch",
                      inputValue: false,
                      key: "owner_id",
                    },
                  ],
                },
                {
                  title: "Custom Fields",
                  inputs: filterCustomFields,
                },
                {
                  title: "Tags",
                  inputs: [
                    {
                      label: "Tags Added to a Contact",
                      input: "multiSelect",
                      inputValue: [],
                      checkbox: true,
                      checkboxValue: false,
                      checkboxName: `checkbox_tags`,
                      options: tagsTemp,
                      key: "tags",
                    },
                  ],
                },
                {
                  title: "Contact List",
                  inputs: [
                    {
                      label: "Contact List",
                      input: "selectAutoComplete",
                      inputValue: [],
                      checkbox: true,
                      checkboxValue: false,
                      checkboxName: `checkbox_contact_list`,
                      options: contactListTemp,
                      key: "contact_list_id",
                    },
                  ],
                },
              ],
            });
          } else if (myExtensionTemp.crm_filters.contacts) {
            let filterCustomFields = [];

            customFieldsTemp.forEach((element) => {
              if (
                element.name !== "stuffed" &&
                element.field_type &&
                element.field_metadata
              ) {
                if (element.field_type === "list") {
                  let values = [];
                  element.field_metadata.list_values.forEach((value) => {
                    values.push({
                      label: value,
                      value: value,
                    });
                  });
                  filterCustomFields.push({
                    label: element.name,
                    input: "select",
                    inputValue:
                      myExtensionTemp?.crm_filters?.contacts[
                        `custom_${element.name}`
                      ] || "",
                    checkbox: true,
                    checkboxValue: myExtensionTemp.crm_filters.contacts[
                      `custom_${element.name}`
                    ]
                      ? true
                      : false,
                    checkboxName: `checkbox_${element.name}`,
                    options: values,
                    key: `custom_${element.name}`,
                  });
                } else if (element.field_type === "checkbox") {
                  filterCustomFields.push({
                    label: element.name,
                    input: "checkbox",
                    inputValue: myExtensionTemp?.crm_filters?.contacts[
                      `custom_${element.name}`
                    ]
                      ? true
                      : false,
                    checkbox: true,
                    checkboxValue:
                      typeof myExtensionTemp?.crm_filters?.contacts[
                        `custom_${element.name}`
                      ] === "boolean"
                        ? true
                        : false,
                    checkboxName: `checkbox_${element.name}`,
                    key: `custom_${element.name}`,
                  });
                } else {
                  filterCustomFields.push({
                    label: element.name,
                    input: "text",
                    inputValue:
                      myExtensionTemp?.crm_filters?.contacts[
                        `custom_${element.name}`
                      ] || "",
                    checkbox: true,
                    checkboxValue: myExtensionTemp.crm_filters.contacts[
                      `custom_${element.name}`
                    ]
                      ? true
                      : false,
                    checkboxName: `checkbox_${element.name}`,
                    key: `custom_${element.name}`,
                  });
                }
              }
            });

            setDataFilter({
              onSaveFunction: (form) => onSaveFunction(form),
              onHidde: () => console.log("hidde"),
              parameters: [
                {
                  title: "Date",
                  inputs: [
                    {
                      label: "Contact Added Within",
                      input: "date",
                      inputValue_added_after:
                        myExtensionTemp?.crm_filters?.contacts?.added_after ||
                        "",
                      inputValue_added_before:
                        myExtensionTemp?.crm_filters?.contacts?.added_before ||
                        "",
                      checkbox: true,
                      checkboxValue:
                        myExtensionTemp.crm_filters.contacts.added_after &&
                        myExtensionTemp.crm_filters.contacts.added_before
                          ? true
                          : false,
                      checkboxName: "checkbox_date",
                      key_added_after: "added_after",
                      key_added_before: "added_before",
                    },
                  ],
                },
                {
                  title: "Assigned User",
                  inputs: [
                    {
                      label: "Only Show Contacts Assigned to Me",
                      input: "switch",
                      inputValue: myExtensionTemp.crm_filters.contacts.owner_id
                        ? true
                        : false,
                      key: "owner_id",
                    },
                  ],
                },
                {
                  title: "Custom Fields",
                  inputs: filterCustomFields,
                },
                {
                  title: "Tags",
                  inputs: [
                    {
                      label: "Tags Added to a Contact",
                      input: "multiSelect",
                      inputValue:
                        myExtensionTemp.crm_filters.contacts.tags || [],
                      checkbox: true,
                      checkboxValue: myExtensionTemp.crm_filters.contacts.tags
                        ? true
                        : false,
                      checkboxName: `checkbox_tags`,
                      options: tagsTemp,
                      key: "tags",
                    },
                  ],
                },
                {
                  title: "Contact List",
                  inputs: [
                    {
                      label: "Contact List",
                      input: "selectAutoComplete",
                      inputValue:
                        myExtensionTemp.crm_filters.contacts.contact_list_id ||
                        [],
                      checkbox: true,
                      checkboxValue: myExtensionTemp.crm_filters.contacts
                        .contact_list_id
                        ? true
                        : false,
                      checkboxName: `checkbox_contact_list`,
                      options: contactListTemp,
                      key: "contact_list_id",
                    },
                  ],
                },
              ],
            });
          }

          setDataTable({
            title: titles,
            content: [...result.result],
            actions: {
              title: "Action",
              content: [
                {
                  name: "Pin Contact",
                  handleClick: (e) => pinOnlyOneContact(e.pk, true),
                  icon: <FontAwesomeIcon icon={faThumbtack} />,
                },
                {
                  name: "Delete Contact",
                  handleClick: activeModalDelete,
                  icon: <FontAwesomeIcon icon={faTrash} />,
                },
                {
                  name: "Copy Number",
                  handleClick: (e) => handleCopyNumber(e.phone || ""),
                  icon: <FontAwesomeIcon icon={faCopy} />,
                },
                {
                  name: "Edit Details",
                  handleClick: activeContactDetails,
                  icon: <FontAwesomeIcon icon={faPenToSquare} />,
                },
              ],
            },
            columnCheckbox: {
              labelBulkActions: "Actions :",
              actions: [
                {
                  icon: <img src={pin} alt="Pin" />,
                  name: "Pin contacts",
                  handleClick: activePinMultipleContacts,
                },
               
                {
                  icon: <img src={addTag} alt="Add Tag" />,
                  name: "Add Tags",
                  handleClick: (arr) =>
                    activeAddOrDeleteTagsInContacts(arr, "Add Tags"),
                },
                {
                  icon: <img src={removeTag} alt="Remove Tag" />,
                  name: "Remove Tags",
                  handleClick: (arr) =>
                    activeAddOrDeleteTagsInContacts(arr, "Removed Tags"),
                },
                {
                  icon: <img src={deleteIcon} alt="Delete Contact" />,
                  name: "Delete contact",
                  handleClick: activeDeleteMultiplecontacts,
                },
              ],
            },
            viewMultiSelect: true,
            check: (e, data) => {
              clickChange(e, data, titles);
            },
            showFilter: {
              handleClick: activeFilterDetails,
            },
            addButton: {
              label: "Add New Contact",
              handleClick: activeAddContact,
            },
            clickRow: activeContactDetails,
            changePage: (page) => changePage(page),
            itemsPerPage: (e) => activeItemsPerPage(e),
            iconFunction: (e) => pinOnlyOneContact(e.pk, false),
          });
          setLoading(false);
          setLoadingChangeItemsPerPage(false);
          setAddedCustomFieldInContactDetails(false);
          setImportedContacts(false);
          setAddedContactListImort(false);
          firstLoad.current = false;
        })
        .catch((error) => console.log(error));
    } else {
      await petitionGet("contacts", { parameter: parameterContact })
        .then(({ data: result }) => {
          result.result.contactListName = contactListTemp.find(
            (list) => list.pk === result.result.contact_list_id
          )?.list_name;

          result.result.tags = result.result.tags.map((tag) => ({
            ...tag,
            title: tag.tag.name,
            pk: tag.tag_id,
          }));

          activeContactDetails(result.result);
          setLoading(false);
        })
        .catch((error) => console.log(error));
    }
  };

  useEffect(() => {
    if (tab === 3) {
      if (!params) {
        petition(getQueryString(pagination.current));
      } else {
        let parameterContact = "/" + params.slice(1);
        petition(
          getQueryString(pagination.current),
          false,
          false,
          parameterContact
        );
      }
    }
  }, [tab]);

  return (
    <div className="itp-test">
      {loading ? (
        <Loading />
      ) : (
        <>
          <TabMenu
            tabs={tabs}
            defaultTab={3}
            textBack="Back to Contacts"
            backFunction={backFunction}
            setTab={setTab}
          />
          {modalDelete && (
            <ModalDelete
              modal={modalDelete}
              closeModal={() => closeMenu(false)}
              loading={loadingMenuButton}
              modalFunction={modalDeleteFunction}
              text={text}
              title={activePincontact ? "Pin Contact" : false}
            />
          )}

          {modalTag && (
            <ModalTags
              modal={modalTag}
              closeModal={() => closeMenu(false)}
              loading={loadingMenuButton}
              modalFunction={addOrDeleteTagsInContacts}
              tagsExternal={tags}
              title={titleModalTag}
            />
          )}
          <div>
           
            {tab === 3 && dataTable && (
              <>
              <div>
                <div
                style={{ marginLeft: "2rem" }}
                className={`itp-custom-table-container-search-input mt-4
                          ${inputFocused ? " focused-input" : ""}`}
                >
                  <SearchIcon className="itp-custom-table-search-icon" />
                  <input
                    className="itp-custom-table-search-input"
                    placeholder="Search Contact"
                    onChange={(e) => {
                      setValueSearchInput(e.target.value);
                      handleChangeInput(e.target.value);
                    }}
                    onFocus={handleInputFocus}
                    onBlur={handleInputBlur}
                    value={valueSearchInput}
                  />
                </div>
                <CustomTableDefault              
                  data={dataTable}
                  optionSelectCheckbox={options}
                  defaultSelectedCheckbox={defaultSelectedOptions}
                  resize={false}
                  allResult={allResult}
                  paginationData={itemsPerPage.current}
                  loading={loadingChangeItemsPerPage}
                  onClickPhone={onClickPhone}
                />
                
              </div>
              </>
            )}
          </div>

          <MenuRight
            show={openMenu}
            closeMenu={() =>
              closeMenu(
                addedCustomFieldInContactDetails ||
                  importedContacts ||
                  addedContactListImort ||
                  params
              )
            }
            title={selectTitleMenu()}
            to="to Contacts"
            w50={importContacts}
          >
            {showAddContactMenu && !contactDetailsMenu && !openFilter && (
              <AddContact
                addFunction={addContact}
                contactList={contactList}
                loadingMenuButton={loadingMenuButton}
                setImportContacts={setImportContacts}
                setImportedContacts={setImportedContacts}
                setAddedContactListImort={setAddedContactListImort}
                customFields={customFields.current}
              />
            )}

            {contactDetailsMenu && !showAddContactMenu && !openFilter && (
              <ContactDetails
                contactDetailsMenu={contactDetailsMenu}
                tagsExternal={tags}
                petitionTag={petitionTag}
                contactList={contactList}
                customFields={customFields}
                editContact={editContact}
                loadingEdit={loadingMenuButton}
                myExtension={myExtension.current}
                setAddedCustomField={setAddedCustomFieldInContactDetails}
              />
            )}

            {openFilter && !contactDetailsMenu && !showAddContactMenu && (
              <Filter info={dataFilter} loading={loadingFilter} />
            )}
          </MenuRight>
        </>
      )}
    </div>
  );
}

export default Contacts;
