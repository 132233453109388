import React, { useEffect, useState } from "react";
import { Row, Col, Badge } from "reactstrap";
import { Element } from "react-scroll";
import { LoadingButton } from "../../../../../../components";
import { states } from "../../../../../../const/states";
import petitionGet from "../../../../../../services/petitionGet";

const Step1 = ({
  setFilterNumbers,
  filterNumbers,
  resetActive,
  setErrorNumbers,
  setCityState,
  setNpaNxx,
  setNpa,
  npa,
  cityState,
  npaNxx,
  onSubmitNumbers,
  errorNumbers,
  loading,
  setLoading,
  resetAll,
  setPrefix,
}) => {
  const [cities, setCities] = useState(null);
  const [loadingUbication, setLoadingUbication] = useState(false);

  const onChangeRadio = (e) => {
    if (resetActive) return null;

    setFilterNumbers({ ...filterNumbers, type: e.target.value });
  };

  const onChangeSelect = (e) => {
    if (resetActive) return null;

    if (e.target.value === "selected") {
      return setFilterNumbers({
        ...filterNumbers,
        country: e.target.value,
        type: "",
      });
    }
    setFilterNumbers({ ...filterNumbers, country: e.target.value });
  };

  const onChangeSelectType = (e) => {
    if (resetActive) {
      return null;
    }
    setErrorNumbers(null);
    if (e.target.value === "selected") {
      return setFilterNumbers({
        ...filterNumbers,
        type: "",
        category: "",
      });
    }
    setFilterNumbers({ ...filterNumbers, type: "", category: e.target.value });
  };

  const onChangeCityState = async (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setErrorNumbers(null);
    if (name === "state") {
      setCities(null);
      setLoading("cities");
      const stateInfo = states.find(
        (state) => state.shortcode === e.target.value
      );

      await petitionGet("cities", { stateCode: stateInfo.shortcode })
        .then(({ data: result }) => {
          if (result.result.RateCenterResponse.ResultCount === "1")
            setCities([
              { ...result.result.RateCenterResponse.RateCenters.RateCenter },
            ]);
          else
            setCities(result.result.RateCenterResponse.RateCenters.RateCenter);
        })
        .catch((error) => {
          console.log(error.response);
        });
    }

    setCityState({ ...cityState, [name]: value });
    setLoading(false);
  };

  const onChangeNpa = (e) => {
    setErrorNumbers(null);
    if (e.target.value.length <= 3) {
      return setNpa({ ...npa, [e.target.name]: e.target.value });
    }
  };

  const onChangeNpaNxx = (e) => {
    setErrorNumbers(null);
    if (e.target.value.length <= 3) {
      return setNpaNxx({ ...npaNxx, [e.target.name]: e.target.value });
    }
  };

  const onChangePrefix = (e) => {
    setErrorNumbers(null);
    if (e.target.value.length <= 3) {
      return setPrefix({ code: e.target.value });
    }
  };

  useEffect(() => {
    if (loading === "submit") {
      setLoadingUbication(true);
    } else {
      setLoadingUbication(false);
    }
  }, [loading]);

  useEffect(() => {
    setCityState({
      state: "",
      city: "",
    });

    setNpa({
      code: "",
    });

    setNpaNxx({
      npa: "",
      nxx: "",
    });
  }, [filterNumbers.type]);

  return (
    <>
      <div className="mt-4">
        <form action="" id="form1">
          <label className="itp-label-input__label mb-0">Country</label>
          <select
            className="form-select-itp mt-1"
            disabled={resetActive ? true : false}
            onChange={onChangeSelect}
            value={filterNumbers.country}
            id="country"
          >
            <option value="selected">Select one...</option>
            <option value="UUEE">United States (+1)</option>
          </select>

          {filterNumbers.country !== "selected" && (
            <>
              <label className="itp-label-input__label mb-0 mt-4">
                Number Type
              </label>
              <select
                className="form-select-itp mt-1"
                onChange={onChangeSelectType}
                value={filterNumbers.category}
                name=""
                id=""
              >
                <option value="selected">Select one ...</option>
                <option value="tool">Toll Free</option>
                <option value="local">Local</option>
              </select>

              {filterNumbers.category === "local" && (
                <div style={{ gap: "12px" }} className="d-flex mt-4">
                  <div className="custom-control custom-radio pr-3">
                    <input
                      type="radio"
                      id="customRadio1"
                      name="customRadio"
                      className="custom-control-input"
                      onChange={onChangeRadio}
                      value="NPA"
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customRadio1"
                      style={{ paddingTop: "1px" }}
                    >
                      Area Code
                    </label>
                  </div>
                  <div className="custom-control custom-radio pr-3">
                    <input
                      type="radio"
                      id="customRadio3"
                      name="customRadio"
                      onChange={onChangeRadio}
                      className="custom-control-input"
                      value="City/State"
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customRadio3"
                      style={{ paddingTop: "1px" }}
                    >
                      City/State
                    </label>
                  </div>
                </div>
              )}
            </>
          )}
        </form>

        {filterNumbers.category === "tool" && (
          <form
            id="form5"
            onSubmit={(e) => {
              e.preventDefault();
              onSubmitNumbers();
            }}
          >
            <label className="itp-label-input__label mt-4">Select Prefix</label>
            <select
              disabled={resetActive ? true : false}
              onChange={onChangePrefix}
              name="code"
              className="form-select-itp mt-1"
            >
              <option value="select">Select one...</option>
              <option value="888">888</option>
              <option value="877">877</option>
              <option value="866">866</option>
              <option value="855">855</option>
              <option value="844">844</option>
              <option value="833">833</option>
              <option value="800">800</option>
              <option value="8**">8**</option>
            </select>

            <Row>
              <Col style={{ margin: "2rem 0" }}>
                <Element name="numbers" className="btn-numbers ">
                  <button
                    style={{ width: "9rem" }}
                    className="btn-primary loading"
                    disabled={loading === "submit" ? true : false}
                    onClick={() => {
                      if (resetActive) {
                        resetAll();
                        document.getElementById("form1").reset();
                        switch (filterNumbers.type) {
                          case "NPA":
                            document.getElementById("form2").reset();
                            break;
                          case "NPANXX":
                            document.getElementById("form3").reset();
                            break;
                          case "City/State":
                            document.getElementById("form4").reset();
                            break;

                          default:
                            break;
                        }
                      } else {
                        onSubmitNumbers();
                      }
                    }}
                  >
                    {loadingUbication && <LoadingButton />}
                    {resetActive ? "Reset Form" : "Next"}
                  </button>
                  <Badge
                    color="danger"
                    style={{
                      marginLeft: "2em",
                      display: errorNumbers ? "initial" : "none",
                    }}
                  >
                    {errorNumbers}
                  </Badge>
                </Element>
              </Col>
            </Row>
          </form>
        )}

        {filterNumbers.category !== "tool" && filterNumbers.type !== "" && (
          <>
            {filterNumbers.type === "NPA" && (
              <form
                id="form2"
                onSubmit={(e) => {
                  e.preventDefault();
                  onSubmitNumbers();
                }}
              >
                <label className="itp-label-input__label mt-4">
                  Select Area Code
                </label>
                <input
                  disabled={resetActive ? true : false}
                  name="code"
                  onChange={onChangeNpa}
                  value={npa.code}
                  type="number"
                  placeholder="Example: 305"
                  className="form-control-itp mt-1"
                />
              </form>
            )}

            {filterNumbers.type === "NPANXX" && (
              <form
                id="form3"
                onSubmit={(e) => {
                  e.preventDefault();
                  onSubmitNumbers();
                }}
              >
                <label className="itp-label-input__label mt-4">
                  Select Area Code
                </label>
                <input
                  disabled={resetActive ? true : false}
                  id="npa"
                  name="npa"
                  onChange={onChangeNpaNxx}
                  value={npaNxx.npa}
                  type="number"
                  placeholder="Example: 305"
                  className="form-control-itp"
                />

                <label className="itp-label-input__label mt-4">
                  Select NXX Code
                </label>
                <input
                  disabled={resetActive ? true : false}
                  id="nxx"
                  name="nxx"
                  onChange={onChangeNpaNxx}
                  value={npaNxx.nxx}
                  type="number"
                  placeholder="Example: 299"
                  className="form-control-itp"
                />
              </form>
            )}

            {filterNumbers.type === "City/State" && (
              <form
                id="form4"
                onSubmit={(e) => {
                  e.preventDefault();
                  onSubmitNumbers();
                }}
              >
                <label className="itp-label-input__label mt-4">
                  Select State
                </label>
                <select
                  disabled={resetActive ? true : false}
                  onChange={onChangeCityState}
                  name="state"
                  id=""
                  className="form-select-itp"
                >
                  <option value="">Select one...</option>

                  {states
                    .sort(function (a, b) {
                      if (a.name > b.name) {
                        return 1;
                      }
                      if (a.name < b.name) {
                        return -1;
                      }
                      // a must be equal to b
                      return 0;
                    })
                    .map((state) => {
                      return (
                        <option key={state.id} value={state.shortcode}>
                          {state.name.toUpperCase()}
                        </option>
                      );
                    })}
                </select>

                {loading === "cities" && <LoadingButton />}

                {cityState.state !== "" && cities && (
                  <>
                    <label className="itp-label-input__label mt-4">
                      Select City
                    </label>
                    <select
                      disabled={resetActive ? true : false}
                      onChange={onChangeCityState}
                      name="city"
                      id=""
                      className="form-select-itp"
                    >
                      <option value="">Select one...</option>
                      {cities.map((city, i) => {
                        return (
                          <option key={i} value={city.Abbreviation}>
                            {city.Name}
                          </option>
                        );
                      })}
                    </select>
                  </>
                )}
              </form>
            )}

            <Row>
              {" "}
              <Col style={{ margin: "2rem 0" }}>
                <Element name="numbers" className="btn-numbers ">
                  <button
                    style={{ width: "9rem" }}
                    className="btn-primary loading"
                    disabled={loading === "submit" ? true : false}
                    onClick={onSubmitNumbers}
                  >
                    {loadingUbication && <LoadingButton />}
                    Next
                  </button>
                  <Badge
                    color="danger"
                    style={{
                      marginLeft: "2em",
                      display: errorNumbers ? "initial" : "none",
                    }}
                  >
                    {errorNumbers}
                  </Badge>
                </Element>
              </Col>{" "}
            </Row>
          </>
        )}
      </div>
    </>
  );
};

export default Step1;
