import React from "react";

const Step2 = ({ form, setForm }) => {
  return (
    <>
      <h3>Sample Messages</h3>
      <br></br>
      <label className="itp-label-input__label">
        Please provide 3 sample messages that my be send to a contact
      </label>
      <br></br>
      <label className="itp-label-input__label">Message #1</label>
      <textarea
        className="form-control-itp"
        onChange={(e) => setForm({ ...form, sample1: e.target.value })}
        value={form.sample1}
      />
      <br></br>
      <label className="itp-label-input__label">Message #2</label>
      <textarea
        className="form-control-itp"
        onChange={(e) => setForm({ ...form, sample2: e.target.value })}
        value={form.sample2}
      ></textarea>
      <br></br>
      <label className="itp-label-input__label">Message #3</label>
      <textarea
        className="form-control-itp"
        onChange={(e) => setForm({ ...form, sample3: e.target.value })}
        value={form.sample3}
      ></textarea>
      <br></br>
    </>
  );
};

export default Step2;
