import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Row, Col } from "reactstrap";
import { useMenuDispatch } from "../../../../contexts/MenuContext";
import {
  Loading,
  CustomTable,
  MenuRight,
  LoadingButton,
} from "../../../../components";
import Select from "react-select";
import CallSplitIcon from "@mui/icons-material/CallSplit";
import arrowleft from "../../../../assets/icons/arrow-left.svg";
import petitionGet from "../../../../services/petitionGet";
import useScreenSize from "../../../../hooks/useScreenSize";
import ModalDelete from "../ModalUtility/ModalDelete";
import petitionDelete from "../../../../services/petitionDelete";
import petitionPost from "../../../../services/petitionPost";
import petitionPatch from "../../../../services/petitionPatch";
import petitionPut from "../../../../services/petitionPut";

const OperatorPanel = () => {
  const { width } = useScreenSize();

  const myExtension = useSelector((state) => state.myExtension.userDetails);

  const navigate = useNavigate();
  const dispatch = useMenuDispatch();

  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [queues, setQueues] = useState([]);
  const [dataTable, setDataTable] = useState(null);
  const [viewUsers, setViewUsers] = useState(false);
  const [edit, setEdit] = useState(false);
  const [add, setAdd] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [viewQueue, setViewQueue] = useState(false);
  const [viewPermissions, setViewPermissions] = useState(false);
  const [infoGroup, setInfoGroup] = useState(null);
  const [actualPk, setActualPk] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [loadMenu, setLoadMenu] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const [name, setName] = useState("");
  const [loadingMenu, setLoadingMenu] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedQueues, setSelectedQueues] = useState([]);
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [errorPermissions, setErrorPermissions] = useState(null)

  const permissionsArray = [
    { label: "listen", value: "listen" },
    { label: "whisper", value: "whisper" },
  ];

  const handleBackClick = () => {
    dispatch({ type: "SET_DEFAULT_MENU" });
    navigate("/Dashboard");
  };

  const selectTitleMenu = () => {
    if (edit) return "Edit Group";
    if (viewUsers) return "View Users";
    if (viewQueue) return "View Queue";
    if (viewPermissions) return "View Permissions";
    if (add) return "Add Group";
  };

  const closeModal = () => {
    setName("");
    setAdd(false);
    setViewPermissions(false);
    setViewQueue(false);
    setEdit(false);
    setViewUsers(false);
    setOpenMenu(false);
    setActualPk(null);
    setModalDelete(false);
    setInfoGroup(null);
  };

  const activeAdd = () => {
    setAdd(true);
    setOpenMenu(true);
  };

  const activeDeleteModal = (e) => {
    setModalDelete(true);
    setInfoGroup(e);
  };

  const activeEdit = (e) => {
    setName(e.name);
    setEdit(true);
    setOpenMenu(true);
    setInfoGroup(e);
  };

  const activeViewUsers = (e) => {
    setViewUsers(true);
    setOpenMenu(true);
    setLoadMenu(true);
    setActualPk(e.pk);
    petitionGet("operationPanelGroupsUsers", { group_id: e.pk })
      .then(({ data: result }) => {
        setInfoGroup(result.result.result);
        setLoadMenu(false);
      })
      .catch((error) => console.log(error));
  };

  const defaultUsers = () => {
    let defaultOption = [];
    users.forEach((element) => {
      infoGroup.forEach((el) => {
        if (element.pk === el.pk) {
          defaultOption.push(element);
        }
      });
    });

    setSelectedUsers(defaultOption);
    return defaultOption;
  };

  const defaultQueues = () => {
    let defaultOption = [];
    queues.forEach((element) => {
      infoGroup.forEach((el) => {
        if (element.pk === el.pk) {
          defaultOption.push(element);
        }
      });
    });

    setSelectedQueues(defaultOption);
    return defaultOption;
  };

  const defaultPermissions = () => {
    let defaultOption = [];
    permissionsArray.forEach((element) => {
      infoGroup.forEach((el) => {
        if (element.value === el.permission_name) {
          defaultOption.push(element);
        }
      });
    });

    setSelectedPermissions(defaultOption);
    return defaultOption;
  };

  const handleChangeUsers = (options) => {
    setSelectedUsers(options);
  };

  const handleChangeQueues = (options) => {
    setSelectedQueues(options);
  };

  const handleChangePermission = (options) => {
    setSelectedPermissions(options);
  };

  const activeViewQueues = (e) => {
    setViewQueue(true);
    setOpenMenu(true);
    setLoadMenu(true);
    setActualPk(e.pk);
    petitionGet("operationPanelGroupsQueues", { group_id: e.pk })
      .then(({ data: result }) => {
        setInfoGroup(result.result.result);
        setLoadMenu(false);
      })
      .catch((error) => console.log(error));
  };

  const activeViewPermissions = (e) => {
    setViewPermissions(true);
    setOpenMenu(true);
    setLoadMenu(true);
    setActualPk(e.pk);
    petitionGet("operationPanelGroupsPermissions", { group_id: e.pk })
      .then(({ data: result }) => {
        setInfoGroup(result.result);
        setLoadMenu(false);
      })
      .catch((error) => console.log(error));
  };

  const reloadData = () => {
    closeModal();
    petition();
  };

  const deleteGroup = () => {
    setLoadingModal(true);
    petitionDelete("operationPanelGroups", {
      group_id: infoGroup.pk,
    })
      .then((result) => {
        setLoadingModal(false);
        reloadData();
      })
      .catch((error) => console.log(error));
  };

  const addEditGroup = () => {
    if (edit) {
      setLoadingMenu(true);
      petitionPatch("operationPanelGroups", {
        data: { name: name },
        group_id: infoGroup.pk,
      })
        .then((result) => {
          setLoadingMenu(false);
          reloadData();
        })
        .catch((error) => console.log(error));
    } else {
      setLoadingMenu(true);
      petitionPost("operationPanelGroups", {
        data: { name: name },
      })
        .then((result) => {
          setLoadingMenu(false);
          reloadData();
        })
        .catch((error) => console.log(error));
    }
  };

  const actionAddUsers = () => {
    setLoadingMenu(true);

    let payload = {};
    let pks = [];
    selectedUsers.forEach((element) => {
      pks.push(element.pk);
    });
    payload = {
      user_id_list: pks,
    };
    petitionPut("operationPanelGroupsUsers", {
      data: payload,
      group_id: actualPk,
    })
      .then((result) => {
        setLoadingMenu(false);
        reloadData();
      })
      .catch((error) => console.log(error));
  };

  const actionAddQueues = () => {
    setLoadingMenu(true);

    let payload = {};
    let pks = [];
    selectedQueues.forEach((element) => {
      pks.push(element.pk);
    });
    payload = {
      queue_id_list: pks,
    };
    petitionPut("operationPanelGroupsQueue", {
      data: payload,
      group_id: actualPk,
    })
      .then((result) => {
        setLoadingMenu(false);
        reloadData();
      })
      .catch((error) => console.log(error));
  };

  const actionAddPermissions = () => {
    setLoadingMenu(true);

    let payload = {};
    let permissions = [];
    selectedPermissions.forEach((element) => {
      permissions.push({ permission_name: element.value });
    });
    payload = {
      permission_list: permissions,
    };
    petitionPut("operationPanelGroupsPermissions", {
      data: payload,
      group_id: actualPk,
    })
      .then((result) => {
        setLoadingMenu(false);
        reloadData();
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 400) {
          setErrorPermissions(error.response.data.message);
        }
      });
  };

  const petition = async () => {
    setLoading(true);

    await petitionGet("itpvoiceUsers")
      .then(({ data: res }) => {
        res.result
          .sort((a, b) => a?.firstname.localeCompare(b?.firstname))
          .forEach((element) => {
            element.check = true;
            element.label = `${element.firstname} ${element.lastname}`;
            element.value = element.pk;
          });

        setUsers(res.result);
      })
      .catch((error) => console.log(error));
    await petitionGet("queues")
      .then(({ data: res }) => {
        res.result
          .sort((a, b) => a?.queue_name.localeCompare(b?.queue_name))
          .forEach((element) => {
            element.check = true;
            element["label"] = `${element.queue_name}`;
            element.value = element.pk;
          });

        setQueues(res.result);
      })
      .catch((error) => console.log(error));

    await petitionGet("operationPanelGroups")
      .then(({ data: result }) => {
        setDataTable({
          title: [{ title: "Name", key: "name" }],
          content: [...result.result.result],
          actions: {
            title: "Actions",
            content: [
              {
                icon: <FontAwesomeIcon icon={faPenToSquare} />,
                name: "Edit",
                handleClick: activeEdit,
              },
              {
                icon: <FontAwesomeIcon icon={faTrash} />,
                name: "Delete",
                handleClick: activeDeleteModal, //Function
              },
              {
                icon: <CallSplitIcon />, //Svg icon
                name: "View Users",
                handleClick: activeViewUsers, //Function
              },
              {
                icon: <CallSplitIcon />, //Svg icon
                name: "View Queues",
                handleClick: activeViewQueues, //Function
              },
              {
                icon: <CallSplitIcon />, //Svg icon
                name: "View Permissions",
                handleClick: activeViewPermissions, //Function
              },
            ],
          },
          label: "Operator Panel",
          labelIsTitle: true,
          addButton: {
            label: "Add Group Name",
            handleClick: activeAdd,
          },
        });
        setLoading(false);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if (
      Object.keys(myExtension).length > 0 &&
      myExtension.priv_level !== "admin"
    ) {
      handleBackClick();
    }
  }, [myExtension]);

  useEffect(() => {
    petition();
  }, []);

  return (
    <>
      <div onClick={handleBackClick} className="itp-tex-back-container">
        <div className="itp-text-container-funnel-details">
          <img src={arrowleft} alt="arrow-left" />
          <span className="itp-text-back-funnel-details">Back to Dashboard</span>
        </div>
      </div>

      {modalDelete && (
        <ModalDelete
          modal={modalDelete}
          closeModal={closeModal}
          loading={loadingModal}
          modalFunction={deleteGroup}
          module="Delete Group"
        />
      )}

      {loading ? (
        <Loading />
      ) : (
        <>
          <div className="mb-2">
            <CustomTable
              data={dataTable}
              noPagBack={true}
              centered={width > 991 ? true : false}
              overflowCard={width > 991 ? "inherit" : "auto"}
            />
          </div>

          <MenuRight
            show={openMenu}
            closeMenu={closeModal}
            title={selectTitleMenu()}
            to=""
          >
            {openMenu && (add || edit) && (
              <>
                <Row className="itp-container-label-input">
                  <Col
                    xs={5}
                    sm={5}
                    md={5}
                    lg={5}
                    xl={5}
                    xxl={5}
                    className="itp-label-input-col"
                  >
                    <label className="itp-label-input__label">Name</label>
                  </Col>
                  <Col
                    xs={7}
                    sm={7}
                    md={7}
                    lg={7}
                    xl={7}
                    xxl={7}
                    className="itp-label-input-col"
                  >
                    <input
                      type="text"
                      placeholder=": Add Name"
                      className="form-control-itp itp-label-input__input"
                      name="name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </Col>
                </Row>
                <button
                  onClick={addEditGroup}
                  className="btn-primary itp-lead-detail-button-save loading mb-4"
                  disabled={!name || loadingMenu}
                >
                  {loadingMenu && <LoadingButton />}
                  Save
                </button>
              </>
            )}

            {openMenu && viewUsers && (
              <>
                {loadMenu ? (
                  <Loading />
                ) : (
                  <>
                    <label className="itp-label-input__label">Users</label>
                    <Select
                      isMulti
                      placeholder="Select Option(s)"
                      className="basic-single"
                      classNamePrefix="select"
                      defaultValue={defaultUsers}
                      onChange={handleChangeUsers}
                      isSearchable={true}
                      name="users"
                      options={users}
                    />
                    <button
                      onClick={actionAddUsers}
                      className="btn-primary itp-lead-detail-button-save loading mb-4"
                      disabled={selectedUsers.length === 0 || loadingMenu}
                    >
                      {loadingMenu && <LoadingButton />}
                      Save
                    </button>
                  </>
                )}
              </>
            )}

            {openMenu && viewQueue && (
              <>
                {loadMenu ? (
                  <Loading />
                ) : (
                  <>
                    <label className="itp-label-input__label">Queues</label>
                    <Select
                      isMulti
                      placeholder="Select Option(s)"
                      className="basic-single"
                      classNamePrefix="select"
                      defaultValue={defaultQueues}
                      onChange={handleChangeQueues}
                      isSearchable={true}
                      name="users"
                      options={queues}
                    />
                    <button
                      onClick={actionAddQueues}
                      className="btn-primary itp-lead-detail-button-save loading mb-4"
                      disabled={selectedQueues.length === 0 || loadingMenu}
                    >
                      {loadingMenu && <LoadingButton />}
                      Save
                    </button>
                  </>
                )}
              </>
            )}

            {openMenu && viewPermissions && (
              <>
                {loadMenu ? (
                  <Loading />
                ) : (
                  <>
                    <label className="itp-label-input__label">Queues</label>
                    <Select
                      isMulti
                      placeholder="Select Option(s)"
                      className="basic-single"
                      classNamePrefix="select"
                      defaultValue={defaultPermissions}
                      onChange={handleChangePermission}
                      isSearchable={true}
                      name="users"
                      options={permissionsArray}
                    />
                    {errorPermissions && (
                      <p className="text-danger">{errorPermissions}</p>
                    )}
                    <button
                      onClick={actionAddPermissions}
                      className="btn-primary itp-lead-detail-button-save loading mb-4"
                      disabled={selectedPermissions.length === 0 || loadingMenu}
                    >
                      {loadingMenu && <LoadingButton />}
                      Save
                    </button>
                  </>
                )}
              </>
            )}
          </MenuRight>
        </>
      )}
    </>
  );
};

export default OperatorPanel;
