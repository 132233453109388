import React, { useEffect, useState } from 'react';
import SipComponent from './logic/SipComponent';
import Keyboard from './views/Keyboard/Keyboard'
import IncomingCall from './views/IncomingCall/IncomingCall'
import OnCall from './views/OnCall/OnCall'
import CallTimer from "./views/OnCall/CallerTimer"
import "./WebPhone.css"
import {
    SessionState, Web
} from "sip.js";
import Settings from './views/Settings/Settings';
// import ringtoneSound from "../../assets/audio/ringtone2.mpeg";
import ringtoneSound from "./assets/audio/ringtone2.mpeg";


const WebPhone = ({ sipconfiguration, showWebphone, isClickCall, numberToCallOnClick, handleCloseActiveCall }) => {
    const [reject, setReject] = useState(0);
    const [call, setCall] = useState(0);
    const [bye, setBye] = useState(null);
    const [answer, setAnswer] = useState(0);
    const [callSessions, setCallSessions] = useState([]);
    const [answerCount, setAnswerCount] = useState([]);
    const [sessionIncall, setSessionIncall] = useState([]);
    const [numberToCall, setNumberTocall] = useState("");
    const [showOnCall, setShowOnCall] = useState(false);
    const [forceRender, setForceRender] = useState(false);
    const [agent, setAgent] = useState(null);
    const [conferenseSession, setConferenceSession] = useState(false)
    const [webphonePosition, setWebphonePosition] = useState({ x: 0, y: 0 });
    const [incomingCallPosition, setIncomingCallPosition] = useState({ x: 0, y: 0 });
    const [onCallPosition, setOncallPosition] = useState({ x: 0, y: 0 });
    const [onSettingsPosition, setOnSettingsPosition] = useState({ x: -200, y: 0 });
    const [devices, setDevices] = useState({ input: [], output: [] })
    const [notDevices, setNotDevices] = useState(false);
    const [volume, setVolume] = useState(100);
    const [audioInputSelected, setAudioInputSeleced] = useState("default");
    const [audioOutputSelected, setAudioOutputSelected] = useState("default");
    const [callAudio, setCallAudio] = useState(new Audio());
    const [closeSettings, setCloseSettings] = useState(true);
    const [enablePhone, setEnablePhone] = useState(true);

    const [ringtone ]=  useState(new Audio(ringtoneSound));

    ringtone.loop = true;

    const handleCall = () => {
        setCall(prevCounter => prevCounter + 1);
    };

    const handleReject = () => {
        setReject(prevCounter => prevCounter + 1);
    };

    const handleBye = () => {
        setConferenceSession(false)
        setBye(prevCounter => prevCounter + 1);
    };

    const handleAnswer = () => {
        setAnswer(prevCounter => prevCounter + 1);
    };


    const handleByeOnInvite = (element, index) => {
        switch (element.state) {
            case SessionState.Initial:
                element.reject();
                break;
            case SessionState.Establishing:
                element.cancel();
                break;
            case SessionState.Established:
                element.bye();
                break;
            case SessionState.Terminating:
            case SessionState.Terminated:
                break;
            default:
                break;
        }

        // answerCount.splice(index, 1)
    };

    useEffect(() => {
        if (callSessions.length === 0) {
            console.log("0000000000000000000000000")
            setShowOnCall(false)
            setForceRender(prevForceRender => !prevForceRender);
        }
    }, [callSessions])

    //////////// ultia actualizacion
    const handleAnswerOnInvite = (element, index, makecall, conference) => {
        if (!makecall) {
            element.accept()
        }
        // const timestamp = Date.now();
        if (!conference) {
            sessionIncall.forEach(el => {
                holdCall(el)
            })
        }

        element.CallTimer = Date.now()
        element.onHold = false
        setSessionIncall([...sessionIncall, element])
        answerCount.splice(index, 1)
        setShowOnCall(true)
        setForceRender(prevForceRender => !prevForceRender);
    };

    function holdCall(session) {
        session.onHold = true
        const options = {
            sessionDescriptionHandlerModifiers: [Web.holdModifier],
        };
        session.invite(options);
    }

    function unHoldCall(session) {
        session.onHold = false
        const options = {
            sessionDescriptionHandlerModifiers: [],
        };
        session.invite(options);
    }

    function changeRingtoneVolume(vol) {
        console.log(vol/100) 
        ringtone.volume = vol/100
      }



    const setCallSessionsFunctionEnd = (element, sendingCall) => {
        // Filtrar y actualizar el array callSessions
        setCallSessions((prevSessions) => {
            const updatedCallSessions = prevSessions.filter(session => session !== element);
            return updatedCallSessions
        })

        if (!sendingCall) {
            setAnswerCount((prevSessions) => {
                const updatedAnswerCount = prevSessions.filter(session => session !== element);
                return updatedAnswerCount
            })
        }


        setSessionIncall((prevSessions) => {
            const updatedSessionInCall = prevSessions.filter(session => session !== element);
            const sessionInCallEnded = prevSessions.filter(session => session === element);
            const position = prevSessions.findIndex(session => session === sessionInCallEnded[0]);
            // setTimers((prevTimer) => {
            //   const updateTimers = prevTimer.splice(position, 1)
            //   return updateTimers
            // })
            return updatedSessionInCall
        })

    };

    const handleDragStart = (e, val, setVal) => {
        const startX = e.clientX - val.x;
        const startY = e.clientY - val.y;

        const handleDragMove = (e) => {
            const newX = e.clientX - startX;
            const newY = e.clientY - startY;

            setVal({ x: newX, y: newY });
        };

        const handleDragEnd = () => {
            window.removeEventListener("mousemove", handleDragMove);
            window.removeEventListener("mouseup", handleDragEnd);
        };

        window.addEventListener("mousemove", handleDragMove);
        window.addEventListener("mouseup", handleDragEnd);
    };

    useEffect(() => {
        if (isClickCall) {
            console.log("click call")
            setNumberTocall(numberToCallOnClick)
            handleCall()
            handleCloseActiveCall()
        }
    }, [isClickCall])


    useEffect(() => {
        const Devices = async () => {
            try {
                if (!navigator.mediaDevices?.enumerateDevices()) {
                    setNotDevices(true);
                } else {
                    let devices = await navigator.mediaDevices?.enumerateDevices();
                    let outputsAudioDevices = devices.filter(
                        (device) => device.kind === "audiooutput"
                    );
                    let inputsAudioDevices = devices.filter(
                        (device) => device.kind === "audioinput"
                    );

                    if (inputsAudioDevices.length === 0 || outputsAudioDevices === 0)
                        setNotDevices(true);
                    setDevices({
                        input: inputsAudioDevices,
                        output: outputsAudioDevices,
                    });
                }
            } catch (error) {
                console.log(error);
            }
        };
        Devices();
    }, []);


    return (
        <div className={showWebphone ? "showWebphone" : "hideWebphone"}>
            <div className='111111111'
                style={{
                    position: "absolute",
                    left: webphonePosition.x + "px",
                    top: webphonePosition.y + "px",
                }}
                onMouseDown={(e) => handleDragStart(e, webphonePosition, setWebphonePosition)}>
                {callSessions.length === 0 && <Keyboard sipconfiguration={sipconfiguration} numberToCall={numberToCall} setNumberTocall={setNumberTocall} handleCall={handleCall} setCloseSettings={setCloseSettings} enablePhone={enablePhone}></Keyboard>}
                {/* <OnCall ></OnCall> */}
            </div>

            <div>
                <SipComponent
                    sipconfiguration={sipconfiguration}
                    call={call}
                    setCall={setCall}
                    reject={reject}
                    setReject={setReject}
                    bye={bye}
                    setBye={setBye}
                    answer={answer}
                    setAnswer={setAnswer}
                    numberToCall={numberToCall}
                    setAnswerCount={setAnswerCount}
                    answerCount={answerCount}
                    setCallSessions={setCallSessions}
                    callSessions={callSessions}
                    setSessionIncall={setSessionIncall}
                    handleAnswerOnInvite={handleAnswerOnInvite}
                    sessionIncall={sessionIncall}
                    agent={agent}
                    setAgent={setAgent}
                    setCallSessionsFunctionEnd={setCallSessionsFunctionEnd}
                    callAudio={callAudio}
                    setCallAudio={setCallAudio}
                    ringtone={ringtone}
                    enablePhone={enablePhone}
                />

                {answerCount.map((element, index) => (

                    <div key={index} style={{
                        position: "absolute",
                        left: incomingCallPosition.x + "px",
                        top: incomingCallPosition.y + "px",
                    }}
                        onMouseDown={(e) => handleDragStart(e, incomingCallPosition, setIncomingCallPosition)}>
                        <IncomingCall session={element} rejectedFunction={() => handleByeOnInvite(element, index)} answerFunction={() => handleAnswerOnInvite(element, index)}></IncomingCall>
                        <br />
                    </div>
                ))}
                {
                    (callSessions.length > 0 && showOnCall) && <div style={{
                        position: "absolute",
                        left: onCallPosition.x + "px",
                        top: onCallPosition.y + "px",
                    }}
                        onMouseDown={(e) => handleDragStart(e, onCallPosition, setOncallPosition)}>
                        <OnCall
                            callSessions={callSessions}
                            handleBye={handleBye}
                            sessionIncall={sessionIncall}
                            setSessionIncall={setSessionIncall}
                            unHoldCall={unHoldCall}
                            holdCall={holdCall}
                            sipconfiguration={sipconfiguration}
                            setCallSessions={setCallSessions}
                            handleAnswerOnInvite={handleAnswerOnInvite}
                            agent={agent}
                            setAgent={setAgent}
                            setCallSessionsFunctionEnd={setCallSessionsFunctionEnd}
                            handleByeOnInvite={handleByeOnInvite}
                            conferenseSession={conferenseSession}
                            setConferenceSession={setConferenceSession}
                            setCloseSettings={setCloseSettings}
                            callAudio={callAudio}
                            setCallAudio={setCallAudio}
                        ></OnCall>
                    </div>
                }
                <div style={{
                    position: "absolute",
                    left: onSettingsPosition.x + "px",
                    top: onSettingsPosition.y + "px",
                }}
                >

                    {!closeSettings && <Settings devices={devices} setVolume={setVolume} volume={volume} sessionIncall={sessionIncall} setCloseSettings={setCloseSettings} callAudio={callAudio} setCallAudio={setCallAudio} changeRingtoneVolume={changeRingtoneVolume} setEnablePhone={setEnablePhone} enablePhone={enablePhone}/>}
                </div>

            </div>
        </div >
    );
};

export default WebPhone;
