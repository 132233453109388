import React, { useEffect, useState, useRef } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
  Navigate,
} from "react-router-dom";
import { Test } from "../pages/public";
import {
  Analytics,
  Contacts,
  Dashboard,
  Funnels,
  Leads,
  LivePanel,
  SmartCampaigns,
  SupportCenter,
  Conversations,
  UserSettings,
  GeneralSettings,
  APIKeys,
  Forms,
  Webhooks,
  DLC10,
  PhoneNumbers,
  WorkFlows,
  AdvancedCallFlows,
  Users,
  OperatorPanel,
  CalendarIntegration,
  Troubleshooting,
} from "../pages/private";
import { useDispatch, useSelector } from "react-redux";
import { itemsMenu, secondItemsMenu } from "../const/itemsMenu";
import { Header, Sidebar, Footer } from "../components/index";
import { logoutRequest } from "../redux/actions/login";
import { defaultSidebar , hiddenSidebar } from "../redux/actions/sidebar";
import { saveDataInLocalStorage } from "../services/login.service";
import useScreenSize from "../hooks/useScreenSize";
import petitionGet from "../services/petitionGet";
import "./index.css";

function Index({ setShowWebphone, handleActiveCall }) {
  const dispatch = useDispatch()
  const navigate = useNavigate();

  const { width } = useScreenSize()

  const container1Ref = useRef(null);
  const container2Ref = useRef(null);

  const toggleMenu = useSelector((state) => state.sidebar.isDefault)
  const showHeader = useSelector((state) => state.header.show)

  const [isPublic, setIsPublic] = useState(false);
  const [activeMenuItems, setActiveMenuItems] = useState(itemsMenu);

  const onLogout = () => {
    dispatch(logoutRequest())
  };

  const handleToggle = () => {
    if (toggleMenu) {
      dispatch(defaultSidebar())
    } else {
      dispatch(hiddenSidebar())
    }
  };

  const handleMenuSwitch = () => {
    if (activeMenuItems === itemsMenu) {
      setActiveMenuItems(secondItemsMenu);
    } else {
      setActiveMenuItems(itemsMenu);
    }
  };

  const routes = [
    {
      url: "test",
      component: Test,
      public: false,
    },
    {
      url: "Analytics",
      component: Analytics,
      public: false,
    },
    {
      url: "Contacts",
      component: Contacts,
      public: false,
    },
    {
      url: "Dashboard",
      component: Dashboard,
      public: false,
    },
    {
      url: "Funnels",
      component: Funnels,
      public: false,
    },
    {
      url: "Leads",
      component: Leads,
      public: false,
    },
    {
      url: "Live-Panel",
      component: LivePanel,
      public: false,
    },
    {
      url: "Smart-Campaigns",
      component: SmartCampaigns,
      public: false,
    },
    {
      url: "Support-Center",
      component: SupportCenter,
      public: false,
    },
    {
      url: "Conversations",
      component: Conversations,
      public: false,
    },
    {
      url: "My-Inbox",
      component: Conversations,
      public: false,
    },
    {
      url: "Devices",
      component: UserSettings,
      public: false,
    },
    {
      url: "Call-Forwarding",
      component: UserSettings,
      public: false,
    },
    {
      url: "Caller-ID",
      component: UserSettings,
      public: false,
    },
    {
      url: "Call-Recordings",
      component: UserSettings,
      public: false,
    },
    {
      url: "Intercom",
      component: UserSettings,
      public: false,
    },
    {
      url: "Fax",
      component: UserSettings,
      public: false,
    },
    {
      url: "General-Settings",
      component: GeneralSettings,
      public: false,

    },
    {
      url: "API-Keys",
      component: APIKeys,
      public: false,
    },
    //////////////////
    {
      url: "Forms",
      component: Forms,
      public: false,
    },
    {
      url: "10dlc",
      component: DLC10,
      public: false
    },
    {
      url: "Webhooks",
      component: Webhooks,
      public: false,
    },
    {
      url: "Phone-Numbers",
      component: PhoneNumbers,
      public: false,
    },
    {
      url: "Workflows",
      component: WorkFlows,
      public: false,
    },
    {
      url: "Advanced-Call-flows",
      component: AdvancedCallFlows,
      public: false,
    },
    {
      url: "Users",
      component: Users,
      public: false,
    },
    {
      url: "Operator-Panel",
      component: OperatorPanel,
      public: false,
    },
    {
      url: "Calender-Integration",
      component: CalendarIntegration,
      public: false,
    },
    {
      url: "Troubleshooting",
      component: Troubleshooting,
      public: false,
    },
  ];

  petitionGet("verify")
  .then(({ data: result }) => {})
  .catch((error) => {
    console.log(error);
    if(error?.response?.status === 401) {
      onLogout();
    }
  });

  useEffect(() => {
    routes.forEach((element) => {
      if (
        window.location.pathname === "/" + element.url &&
        element.public === true
      ) {
        setIsPublic(true);
      }
    });

    const container1 = container1Ref.current;
    const container2 = container2Ref.current;

    if (container1 && container2) {
      const heightContainer1 = container1.offsetHeight;
      const heightContainer2 = `calc(100% - ${heightContainer1}px)`;

      container2.style.height = heightContainer2;
    }

    const autoRefresh = () => {
      const token = JSON.parse(
        localStorage.getItem("userDetails")
      ).access_token;

      petitionGet("refresh", token)
        .then(({ data: result }) => {
          let dataLocalStorage = JSON.parse(
            localStorage.getItem("userDetails")
          );
          result.result.api_id = dataLocalStorage.api_id;
          saveDataInLocalStorage(result.result);
        })
        .catch((error) => {
          console.log(error);
          onLogout();
        });
    };
    
    setInterval(autoRefresh, 60000);

    if (width < 991) {
      dispatch(defaultSidebar())
    } 
  }, []);

  return (
    <>
      {!isPublic && showHeader && (
        <Header
          refHEader={container1Ref}
          handleToggle={handleToggle}
          toggleMenu={toggleMenu}
          setShowWebphone={setShowWebphone}

        />
      )}
      <div className={`itp-page-container ${toggleMenu && !showHeader ? "mt-0" : ""}`}>
        {!isPublic && (
          <Sidebar
            items={itemsMenu}
            navigate={navigate}
            refSidebar={container2Ref}
            toggleMenu={toggleMenu}
            onUserSettingsClick={handleMenuSwitch}
          />
        )}
        <div className={`${!isPublic && !toggleMenu ? "itp-width-sidebar" : "itp-width-100"}`}>
          <Routes>
            <Route
              path="/login"
              element={<Navigate to="/Dashboard" replace />}
            />
            <Route
              path="/Forget-Password"
              element={<Navigate to="/Dashboard" replace />}
            />
            <Route
              path="/Change-Password"
              element={<Navigate to="/Dashboard" replace />}
            />
            <Route
              path="/"
              element={<Navigate to="/Dashboard" replace />}
            />
            {routes.map((data, i) => (
              <Route
                key={i}
                path={`/${data.url}`}
                element={<data.component handleActiveCall={handleActiveCall} /> }
              />
            ))}
          </Routes>
          {/* {!isPublic && <Footer />} */}
        </div>
      </div>
    </>
  );
}

function App({ setShowWebphone, handleActiveCall}) {
  return (
    <Router>
      <Index setShowWebphone={setShowWebphone} handleActiveCall={handleActiveCall} />
    </Router>
  );
}

export default App;
