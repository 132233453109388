import { put, takeLatest } from "redux-saga/effects";
import { hiddenSidebarSucces } from "../actions/sidebar";

function* hiddentSidebarMenu() {
  yield put(hiddenSidebarSucces());
}

function* hiddenSidebarSaga() {
  yield takeLatest("HIDDEN_SIDEBAR", hiddentSidebarMenu);
}

export default hiddenSidebarSaga;
