import petitionGet from "./petitionGet";

export const myExtensionService = async () => {
  return await petitionGet("userDetails")
    .then(async ({ data: result }) => {
      const userDetails = result.result;
      let realm = result.result.voice_account.realm
      console.log("---------->",result.result)

      let newData = {};

      await petitionGet("userDevices")
        .then(({ data: result }) => {
          const dataFind = result.result.find(
            (element) => element.device_type === "web_phone"
          );


          newData = {
            ...userDetails,
            userDevices: result.result,
            voice_account: {
              ...userDetails.voice_account,
              web_phone: {
                ...dataFind,
              },
            },
          };
          localStorage.setItem("webphone", JSON.stringify({...dataFind,realm}));
        })
        .catch((error) => console.log(error));

      return newData;
    })
    .catch((error) => console.log(error));
};
