import React, { useEffect } from "react";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import "./PaginationITP.css"

export default function PaginationITP({ size, pages, active, setActive }) {
  useEffect(() => {}, []);

  const items = () => {
    let items = [];

    let startPage;
    let endPage;

    if (pages <= 3) {
      startPage = 1;
      endPage = pages;
    } else if (active === 1) {
      startPage = 1;
      endPage = 3;
    } else if (active === pages) {
      startPage = pages - 2;
      endPage = pages;
    } else {
      startPage = active - 1;
      endPage = active + 1;
    }

    if (active > 1) {
      items.push(
        <PaginationItem key={"back"} onClick={() => setActive(active > 1 ? active - 1 : 1)}>
          <PaginationLink previous href="" />
        </PaginationItem>
      );
    } else {
      items.push(<PaginationItem key={"back"} disabled><PaginationLink previous href="" /></PaginationItem>);
    }

    for (let number = startPage; number <= endPage; number++) {
      items.push(
        <PaginationItem
          key={number}
          active={number === active}
          onClick={() => setActive(number)}
        >
          <PaginationLink href="">{number}</PaginationLink>
        </PaginationItem>
      );
    }

    if (active < pages) {
      items.push(
        <PaginationItem
          key={"next"}
          onClick={() => setActive(active < pages ? active + 1 : {})}
        >
          <PaginationLink next href="" />
        </PaginationItem>
      );
    } else {
      items.push(<PaginationItem key={"next"} disabled><PaginationLink next href="" /></PaginationItem>);
    }

    return items;
  };

  return (
    <Pagination size={size} aria-label="Page navigation example">
      {items()}
    </Pagination>
  );
}
