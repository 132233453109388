import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Col, Row } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useMenuDispatch } from "../../../../contexts/MenuContext.js";
import {
  CustomTable,
  Loading,
  MenuRight,
  LoadingButton,
} from "../../../../components/index.js";
import AddOrEdit from "./AddOrEdit.js";
import AddOrEditCampaign from "./AddOrEditCampaign.js";
import ModalDelete from "../ModalUtility/ModalDelete.js";
import petitionGet from "../../../../services/petitionGet.js";
import petitionPatch from "../../../../services/petitionPatch.js";
import petitionPost from "../../../../services/petitionPost.js";
import petitionDelete from "../../../../services/petitionDelete.js";
import arrowleft from "../../../../assets/icons/arrow-left.svg";

const Dlc = () => {
  const myExtension = useSelector((state) => state.myExtension.userDetails);

  const navigate = useNavigate();
  const dispatch = useMenuDispatch();

  const [loading, setLoading] = useState(true);
  const [dataTableBrands, setDataTableBrands] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [info, setInfo] = useState(null);
  const [loadingBrand, setLoadingBrand] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [dataTableCampaign, setDataTableCampaign] = useState(null);
  const [openCampaignMenu, setOpenCampaignMenu] = useState(false);
  const [brands, setBrands] = useState([]);
  const [loadingCampaign, setLoadingCampaign] = useState(false);
  const [modalDeleteCampaign, setModalDeleteCampaign] = useState(false);
  const [arrError, setArrError] = useState([]);
  const [errorText, setErrorText] = useState("");
  const [form, setForm] = useState({
    displayName: "",
    companyName: "",
    ein: "",
    phone: "",
    street: "",
    city: "",
    state: "",
    postalCode: "",
    country: "US",
    email: "",
    vertical: "",
    entityType: "",
    vertical: "",
    website: "",
  });

  const validateFields = (obj) => {
    let invalidFields = [];
    for (const field in obj) {
      if (
        (field !== "ein" || obj["entityType"] !== "SOLE_PROPRIETOR") &&
        field !== "website" &&
        (!obj[field] || obj[field].trim() === "")
      ) {
        invalidFields.push(field);
      }
    }

    if (invalidFields.length === 0) {
      return true;
    } else {
      setArrError(invalidFields);
      return false;
    }
  };

  const onchangeForm = (e, key) => {
    const { name, value } = e.target;

    // Manejo de errores
    if (arrError.includes(name)) {
      const newArrError = arrError.filter((item) => item !== name);
      setArrError(newArrError);
    }

    // Actualización del formulario
    const updatedForm = { ...form };

    if (name === "country") {
      updatedForm[name] = value;
      if (value === "US") {
        updatedForm["state"] = "";
      }
    } else if (name !== "entityType") {
      updatedForm[name] = value;
      if (key) {
        updatedForm[key] = value;
      }
    } else if (name === "entityType") {
      updatedForm[name] = value;
      if (value === "SOLE_PROPRIETOR") {
        updatedForm["ein"] = "";
      }
    }

    setForm(updatedForm);
    setErrorText("");
  };

  const reloadForm = () => {
    setArrError([])
    setErrorText("")
    setForm({
      displayName: "",
      companyName: "",
      ein: "",
      phone: "",
      street: "",
      city: "",
      state: "",
      postalCode: "",
      country: "US",
      email: "",
      vertical: "",
      entityType: "",
      vertical: "",
      website: "",
    });
  };

  const closeMenu = () => {
    reloadForm();
    setModalDeleteCampaign(false);
    setOpenCampaignMenu(false);
    setModalDelete(false);
    setInfo(null);
    setOpenMenu(false);
  };

  const handleBackClick = () => {
    dispatch({ type: "SET_DEFAULT_MENU" });
    navigate("/Dashboard");
  };

  const activeAddorEditBrand = (e) => {
    if (e) {
      setInfo(e);
      setForm({
        displayName: e.displayName,
        companyName: e.companyName,
        ein: e.ein,
        phone: e.phone,
        street: e.street,
        city: e.city,
        state: e.state,
        postalCode: e.postalCode,
        country: e.country,
        email: e.email,
        vertical: e.vertical,
        entityType: e.entityType,
        vertical: e.vertical,
        website: e.website,
      });
    }

    setOpenMenu(true);
  };

  const activeDeleteModal = (e) => {
    setInfo(e);
    setModalDelete(true);
  };

  const activeAddCampaign = () => {
    setOpenCampaignMenu(true);
    setOpenMenu(true);
  };

  const activeDeleteCampaign = (e) => {
    setInfo(e);
    setModalDeleteCampaign(true);
  };

  const selectTitleMenu = () => {
    if (!openCampaignMenu && !info) return "Add Brand";
    if (!openCampaignMenu && info) return "Edit Brand";
    if (openCampaignMenu && !info) return "Add Brand / Company";
    if (openCampaignMenu && info) return "Edit Brand / Company";
  };

  const reloadData = () => {
    closeMenu();
    petition();
  };

  const deleteCampaign = () => {
    setLoadingCampaign(true);
    petitionDelete("campaign", { brand_id: info.pk })
      .then(({ data: result }) => {
        setLoadingCampaign(false);
        reloadData();
      })
      .catch((err) => console.log(err));
  };

  const deleteBrand = () => {
    setLoadingBrand(true);
    petitionDelete("brands", { brand_id: info.pk })
      .then(({ data: result }) => {
        setLoadingBrand(false);
        reloadData();
      })
      .catch((err) => console.log(err));
  };

  const editBrand = () => {
    if (validateFields(form)) {
      setLoadingBrand(true);
      petitionPatch("brands", { data: form, brand_id: info.pk })
        .then(({ data: result }) => {
          setLoadingBrand(false);
          reloadData();
        })
        .catch((err) => {
          console.log(err)
          setLoadingBrand(false);
          const errors = err.response.data.error.error_detailed.map((element) => element.description).join("\n")
          setErrorText(errors)
        });
    }
  };

  const addBrand = () => {
    if (validateFields(form)) {
      setLoadingBrand(true);
      petitionPost("brands", { data: form })
        .then(({ data: result }) => {
          setLoadingBrand(false);
          reloadData();
        })
        .catch((err) => {
          console.log(err)
          setLoadingBrand(false);
          const errors = err.response.data.error.error_detailed.map((element) => element.description).join("\n")
          setErrorText(errors)
        });
    }
  };

  const saveFunctionCampaing = (data) => {
    if (info) {
      setLoadingCampaign(true);
      petitionPatch("campaign", { data, brand_id: info.pk })
        .then(({ data: result }) => {
          setLoadingCampaign(false);
          reloadData();
        })
        .catch((err) => console.log(err));
    } else {
      setLoadingCampaign(true);
      petitionPost("campaign", { data })
        .then(({ data: result }) => {
          setLoadingCampaign(false);
          reloadData();
        })
        .catch((err) => console.log(err));
    }
  };

  const petition = async () => {
    setLoading(true);

    await petitionGet("brands")
      .then(({ data: result }) => {
        result.result.forEach((element) => {
          element.statusDetails =
            element.status === "UNVERIFIED"
              ? "PENDING, NO ACTION REQUIRED"
              : "VERIFIED, PLEASE ASSING NUMBERS";
        });

        setDataTableBrands({
          title: [
            { title: "Brand or Company Name", key: "companyName" },
            { title: "Brand Type", key: "entityType" },
            { title: "Status", key: "status" },
            { title: "Status Details", key: "statusDetails" },
          ],
          content: [...result.result],
          actions: {
            title: "Actions",
            content: [
              {
                icon: <FontAwesomeIcon icon={faPenToSquare} />,
                name: "Edit",
                handleClick: activeAddorEditBrand,
              },
              {
                icon: <FontAwesomeIcon icon={faTrash} />,
                name: "Delete",
                handleClick: activeDeleteModal,
              },
            ],
          },
          addButton: {
            label: "Create Brand / Company",
            handleClick: () => activeAddorEditBrand(),
          },
        });
        setBrands(result.result);
      })
      .catch((error) => console.log(error));

    await petitionGet("campaign")
      .then(({ data: result }) => {
        setDataTableCampaign({
          title: [
            { title: "Brand or Company Name", key: "brand_name" },
            { title: "Campaign ID", key: "campaignId" },
            { title: "Use Case", key: "usecase" },
            { title: "Status", key: "status" },
          ],
          content: [...result.result],
          actions: {
            title: "Actions",
            content: [
              {
                icon: <FontAwesomeIcon icon={faPenToSquare} />,
                name: "Edit",
                handleClick: (e) => {
                  setInfo(e);
                  petitionGet("campaignDetails", { campaing_id: e.pk })
                    .then(({ data: result }) => {
                      setInfo({ ...e, mms_media1: result.result.mms_media1 });
                      setOpenCampaignMenu(true);
                      setOpenMenu(true);
                    })
                    .catch((err) => console.log(err));
                },
              },
              {
                icon: <FontAwesomeIcon icon={faTrash} />,
                name: "Delete",
                handleClick: activeDeleteCampaign,
              },
            ],
          },
          addButton: {
            label: "Register Campaign",
            handleClick: activeAddCampaign,
          },
        });
        setLoading(false);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if (
      Object.keys(myExtension).length > 0 &&
      myExtension.priv_level !== "admin"
    ) {
      handleBackClick()
    }
  }, [myExtension]);

  useEffect(() => {
    petition();
  }, []);

  return (
    <>
      <div onClick={handleBackClick} className="itp-tex-back-container">
        <div className="itp-text-container-funnel-details">
          <img src={arrowleft} alt="arrow-left" />
          <span className="itp-text-back-funnel-details">Back to Dashboard</span>
        </div>
      </div>

      {loading ? (
        <Loading />
      ) : (
        <>
          {(modalDelete || modalDeleteCampaign) && (
            <ModalDelete
              modal={modalDelete ? modalDelete : modalDeleteCampaign}
              closeModal={closeMenu}
              loading={modalDelete ? loadingBrand : loadingCampaign}
              modalFunction={modalDelete ? deleteBrand : deleteCampaign}
              module={modalDelete ? "Brand" : "Campaign"}
            />
          )}

          <div id="brands">
            <div className="itp-table-container-centered overflow-hidden">
              <Row>
                <Col>
                  <div className="text-align-with-table" style={{paddingLeft:"0px"}}>
                    <span className="table-alt-text-title">
                      10DLC Registration
                    </span>
                    <br></br>

                    <span className="table-alt-text-title-description">
                      Register your Brand before creating a campaign
                    </span>
                  </div>
                </Col>
              </Row>
            </div>
            <CustomTable
              centered={true}
              data={dataTableBrands}
              noPagBack={true}
            />
          </div>

          <br></br>

          <div>
            <Row>
              <Col>
                <div className="itp-user-settings-separator-line"></div>
              </Col>
            </Row>
          </div>

          <br></br>

          <div id="campaigns">
            <div className="itp-table-container-centered overflow-hidden">
              <Row>
                <Col>
                  <div className="text-align-with-table" style={{paddingLeft:"0px"}}>
                    <span className="table-alt-text-title">Campaigns</span>
                    <br></br>

                    <span className="table-alt-text-title-description">
                      Create a campaign and once verified assign the numbers you
                      will be using
                    </span>
                  </div>
                </Col>
              </Row>
            </div>
            <CustomTable
              centered={true}
              data={dataTableCampaign}
              noPagBack={true}
            />
          </div>

          <MenuRight
            show={openMenu}
            closeMenu={closeMenu}
            title={selectTitleMenu()}
            subtitle=""
            to="to 10dlc"
          >
            {openMenu && !openCampaignMenu && (
              <AddOrEdit
                form={form}
                handleChange={onchangeForm}
                errorText={errorText}
                arrError={arrError}
              />
            )}

            {openMenu && openCampaignMenu && (
              <AddOrEditCampaign
                saveFunction={(data) => saveFunctionCampaing(data)}
                info={info}
                brands={brands}
                loading={loadingCampaign}
              />
            )}

            {openMenu && !openCampaignMenu && (
              <button
                className="btn-primary loading add-lead-new-contact"
                onClick={!info ? addBrand : editBrand}
                disabled={loadingBrand}
              >
                {loadingBrand && <LoadingButton />}
                {!info ? "Add Brand" : "Edit Brand"}
              </button>
            )}
          </MenuRight>
        </>
      )}
    </>
  );
};

export default Dlc;
