export function setupRemoteMedia(sessions, callAudio) {
    let receivedTracksTemp = [];
    sessions.forEach(function (session) {
      if (session !== null && session !== undefined) {
        session.sessionDescriptionHandler.peerConnection
          .getReceivers()
          .forEach(function (receiver) {
            receivedTracksTemp.push(receiver.track);
          });
      }
    });
  
    let context = new AudioContext();
    let allReceivedMediaStreams = new MediaStream();
    sessions.forEach(function (session) {
      if (session !== null && session !== undefined) {
        let mixedOutput = context.createMediaStreamDestination();
        session.sessionDescriptionHandler.peerConnection
          .getReceivers()
          .forEach(function (receiver) {
            receivedTracksTemp.forEach(function (track) {
              allReceivedMediaStreams.addTrack(receiver.track);
              if (receiver.track.id !== track.id) {
                let sourceStream = context.createMediaStreamSource(
                  new MediaStream([track])
                );
                sourceStream.connect(mixedOutput);
              }
            });
          });
        //mixing your voice with all the received audio
  
        session.sessionDescriptionHandler.peerConnection
          .getSenders()
          .forEach(function (sender) {
            let sourceStreamTwo = context.createMediaStreamSource(
              new MediaStream([sender.track])
            );
            sourceStreamTwo.connect(mixedOutput);
          });
        session.sessionDescriptionHandler.peerConnection
          .getSenders()[0]
          .replaceTrack(mixedOutput.stream.getTracks()[0])
          .then(async(e) => {
            await callAudio.pause();
            callAudio.srcObject = allReceivedMediaStreams;
            await callAudio.play();
            console.log(callAudio)
            // setReceivedTracks(receivedTracksTemp);
          })
          .catch((e) => { });
      }
    });
  }

  export function cleanupMedia(sessions, callAudio) {
    if (sessions.length === 1) {
      console.log("Sessions cleanup media");
  
      // Detener la reproducción de audio, si es necesario
      callAudio.pause();
  
      // Obtener el MediaStream actual del elemento audio
      const currentStream = callAudio.srcObject;
  
      // Detener todos los tracks del MediaStream (incluyendo el micrófono)
      if (currentStream) {
        const tracks = currentStream.getTracks();
        tracks.forEach((track) => track.stop());
      }
  
      // Eliminar la referencia del MediaStream del elemento audio
      callAudio.srcObject = null;
    }
  }