import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Tooltip  } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";


const SipDetailsModal = ({ modal, closeModal, device, myExtension }) => {
  
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [copiedField, setCopiedField] = useState('');

  const toggleTooltip = () => {
    setTooltipOpen(!tooltipOpen);
  };

  const copyToClipboard = (text, field) => {
    navigator.clipboard.writeText(text);
    setCopiedField(field);
    toggleTooltip();
    setTimeout(toggleTooltip, 1500); // hide after 1.5 seconds
  };
  

  return (
    <Modal isOpen={modal} className="info">
      <ModalHeader>SIP Details</ModalHeader>
      <ModalBody>
        <p><strong>Device Type:</strong> {device?.device_type}</p>
        <p><strong>SIP Username:</strong> {device?.sip_username}
        <FontAwesomeIcon 
            icon={faCopy} 
            id="copyUsername" 
            onClick={() => copyToClipboard(device?.sip_username, 'username')}
            style={{ cursor: 'pointer', marginLeft: '5px', outline: 'none' }}
          />
        
        </p>

        <p><strong>SIP Password:</strong> {device?.sip_password}
        <FontAwesomeIcon 
            icon={faCopy} 
            id="copyPassword" 
            onClick={() => copyToClipboard(device?.sip_password, 'password')}
            style={{ cursor: 'pointer', marginLeft: '5px', outline: 'none' }}
          />
         
        </p>
        <p><strong>Domain:</strong> {myExtension?.voice_account?.realm}
        <FontAwesomeIcon 
          icon={faCopy} 
          id="copyDomain" 
          onClick={() => copyToClipboard(myExtension?.voice_account?.realm, 'domain')}
          style={{ cursor: 'pointer', marginLeft: '5px', outline: 'none' }}
        />
        </p>

      </ModalBody>
      <ModalFooter>
        <button className="btn-light" onClick={closeModal}>
          Close
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default SipDetailsModal;