import React, { useState } from "react";
import Manually from "./options/Manually/Manually";
import Import from "./options/Import/Import";
import { LoadingButton } from "../../../../components";
import "./AddContact.css";

const AddContact = ({
  addFunction,
  contactList,
  loadingMenuButton,
  setImportContacts,
  setImportedContacts,
  setAddedContactListImort,
  customFields,
}) => {
  const [addContact, setAddContact] = useState("");
  const [optionButton, setOptionButton] = useState("");
  const [valueContactList, setValueContactList] = useState(contactList[0]);
  const [form, setForm] = useState({
    contactname: "",
    phone: "",
    email: "",
    contact_list_id: contactList[0]?.pk,
  });

  const handleChange = (e) => {
    setAddContact(e.target.value);
  };

  const onChangeForm = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const selectAddContact = () => {
    setOptionButton(addContact);
    if (addContact === "importContact") {
      setImportContacts(true);
    }
    setAddContact("");
  };

  const back = () => {
    setImportContacts(false);
    setForm({
      firstname: "",
      lastname: "",
      phone: "",
      email: "",
      contact_list_id: contactList[0]?.pk,
    });
    setValueContactList(contactList[0]);
    setOptionButton("");
  };

  const handleAdd = async () => {
    let data = {
      firstname: form.contactname.split(" ")[0],
      lastname: form.contactname.split(" ")[1] || "",
      phone: form.phone === "+" || form.phone.length === 2 ? "" : form.phone,
      email: form.email,
      contact_list_id: form.contact_list_id,
    };

    await addFunction(data);
    back();
  };

  return (
    <>
      <p className="itp-add-contact-subtitle">
        Add the details asked to add new contact
      </p>
      {optionButton === "" && (
        <>
          <label className="itp-select-add-contact-label">
            Add new contact through
          </label>
          <br />
          <select
            onChange={handleChange}
            className="form-select-itp itp-add-contact-select"
          >
            <option value="">Select Option</option>
            <option value="addManually">Add Manually</option>
            <option value="importContact">Import Contact</option>
          </select>
          <button
            disabled={addContact === "" ? true : false}
            className="btn-primary itp-contact-button"
            onClick={selectAddContact}
          >
            Next
          </button>
        </>
      )}

      {optionButton === "addManually" && (
        <Manually
          form={form}
          setForm={setForm}
          contactList={contactList}
          valueContactList={valueContactList}
          setValueContactList={setValueContactList}
          onChangeForm={onChangeForm}
        />
      )}

      {optionButton === "importContact" && (
        <Import 
          defaultContactList={contactList} 
          backFunction={back} 
          setImportedContacts={setImportedContacts}
          setAddedContactListImort={setAddedContactListImort}
          customFields={customFields}
        />
      )}

      {optionButton === "addManually" && (
        <div className="itp-add-contact-buttons">
          <button
            className="btn-light itp-contact-button"
            onClick={back}
            disabled={loadingMenuButton}
          >
            Back
          </button>
          <button
            className="btn-primary itp-contact-button"
            onClick={optionButton === "addManually" ? handleAdd : back}
            disabled={loadingMenuButton}
          >
            {loadingMenuButton && <LoadingButton />}
            Save
          </button>
        </div>
      )}
    </>
  );
};

export default AddContact;
