import React, { useEffect, useState } from "react";
import { Row, Col, Card } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { v4 as uuidv4 } from "uuid";
import { Loading, LoadingButton, MenuRight } from "../../../../../components";
import petitionGet from "../../../../../services/petitionGet";
import petitionPatch from "../../../../../services/petitionPatch";
import useScreenSize from "../../../../../hooks/useScreenSize";
import SearchIcon from "@mui/icons-material/Search";
import Switch from "react-switch";
import "./EditCallFlowNumbers.css";

const EditCallFlowNumbers = ({ callFlowDetails }) => {
  const { width } = useScreenSize();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [allData, setAllData] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [inputFocused, setInputFocused] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [firstRender, setFirstRender] = useState(true);
  const [formNumbersSwitch, setFormNumbersSwitch] = useState({});
  const [openMenu, setOpenMenu] = useState(false);
  const [addInputNumber, setAddInputNumber] = useState("");
  const [error, setError] = useState("");
  const [loadingAddNumber, setLoadingAddNumber] = useState(false);
  const [formNameCallFlow, setFormNameCallFlow] = useState({
    name: callFlowDetails?.name || "",
  });

  const closeMenu = () => {
    setOpenMenu(false);
  };

  const activeAddNumbers = () => {
    setOpenMenu(true);
  };

  const handleInputFocus = () => {
    setInputFocused(true);
  };

  const handleInputBlur = () => {
    setInputFocused(false);
  };

  const handleChangeSearch = (e) => {
    const { value } = e.target;

    setSearchValue(value);

    let numbersTemp = [];

    if (value !== "") {
      allData.forEach((element) => {
        if (element.number.number.includes(value)) {
          numbersTemp.push(element);
        }
      });

      setData(numbersTemp);
    } else {
      setData(allData);
    }
  };

  const handleChangeInputNumber = (e) => {
    setAddInputNumber(e.target.value);
    setError("");
  };

  const handleAddNumber = () => {
    if (data.find((element) => element.number.number === addInputNumber)) {
      setError("This Number is Repeated");
      setAddInputNumber("");
    } else {
      setLoadingAddNumber(true);
      let generateId = uuidv4();

      const sendNumbers = selectedUsers.map((element) => element.number.number);

      const sendData = {
        numbers: [...sendNumbers, addInputNumber],
        type: "CustomByUser",
        name: formNameCallFlow.name,
        callflow_object: {
          flow: callFlowDetails.flow,
          type: "CustomByUser",
        },
      };

      petitionPatch("callFlow", {
        data: sendData,
        callflow_id: callFlowDetails.pk,
      })
        .then(({ data: result }) => {
          setFormNumbersSwitch({
            ...formNumbersSwitch,
            [addInputNumber]: true,
          });

          setSelectedUsers([
            ...selectedUsers,
            {
              delete: true,
              number: { number: addInputNumber },
              id: generateId,
            },
          ]);

          setData([
            ...data,
            {
              delete: true,
              number: { number: addInputNumber },
              id: generateId,
            },
          ]);

          setAddInputNumber("");
          setLoadingAddNumber(false);
          closeMenu();
        })
        .catch((error) => console.log(error));
    }
  };

  const handleCheck = (element) => {
    const findOne = selectedUsers.find(
      (elementTwo) => elementTwo.number.number === element.number.number
    );

    let newSelectedUsers;
    if (findOne) {
      newSelectedUsers = selectedUsers.filter(
        (elementTwo) => elementTwo.number.number !== element.number.number
      );
    } else {
      newSelectedUsers = selectedUsers;
      newSelectedUsers.push(element);
    }

    let formSwitchs = {};

    newSelectedUsers.forEach((element) => {
      formSwitchs[element.number.number] = true;
    });

    setFormNumbersSwitch(formSwitchs);
    setSelectedUsers(newSelectedUsers);

    const data = {
      numbers: newSelectedUsers.map((element) => element.number.number),
      type: "CustomByUser",
      name: formNameCallFlow.name,
      callflow_object: {
        flow: callFlowDetails.flow,
        type: "CustomByUser",
      },
    };

    petitionPatch("callFlow", { data, callflow_id: callFlowDetails.pk })
      .then(({ data: result }) => {})
      .catch((error) => console.log(error));
  };

  const petition = () => {
    setLoading(true);
    petitionGet("viewNumbers")
      .then(({ data: result }) => {
        let numbersCopy = [];
        let dataUsersCopy = [];
        let selectedUsersCopy = [];

        result.result.forEach((element) => {
          if (!element.used_by) {
            numbersCopy.push({ number: element });
          }
        });

        callFlowDetails?.numbers.forEach((element) => {
          let findOne = numbersCopy.find(
            (elementTwo) => elementTwo.number.number === element
          );

          if (findOne) {
            selectedUsersCopy.push(findOne);
          } else {
            let generateId = uuidv4();

            selectedUsersCopy.push({
              delete: true,
              number: { number: element },
              id: generateId,
            });

            dataUsersCopy.push({
              delete: true,
              number: { number: element },
              id: generateId,
            });
          }
        });

        let formSwitchs = {};

        selectedUsersCopy.forEach((element) => {
          formSwitchs[element.number.number] = true;
        });

        numbersCopy.forEach((element) => {
          formSwitchs[element.number.number] = false;
        });

        setFormNumbersSwitch(formSwitchs);
        setSelectedUsers(selectedUsersCopy);

        setData([...numbersCopy, ...dataUsersCopy]);
        setAllData([...numbersCopy, ...dataUsersCopy]);

        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (!firstRender) {
      const timer = setTimeout(() => {
        const data = {
          numbers: selectedUsers.map((element) => element.number.number),
          type: "CustomByUser",
          name: formNameCallFlow.name,
          callflow_object: {
            flow: callFlowDetails.flow,
            type: "CustomByUser",
          },
        };

        petitionPatch("callFlow", { data, callflow_id: callFlowDetails.pk })
          .then(({ data: result }) => {})
          .catch((error) => console.log(error));
      }, 1000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [formNameCallFlow]);

  useEffect(() => {
    if (callFlowDetails) {
      petition();
      setFirstRender(false);
    } else {
      setFirstRender(true);
    }
  }, [callFlowDetails]);

  return loading ? (
    <Loading />
  ) : (
    <>
      <h1
        className={`itp-custom-table-title${
          width > 991 ? " centered" : ""
        } itp-time-groups-details-title`}
      >
        Call flow Details : Manage settings for this Call flow
      </h1>

      <div className="itp-custom-table-container-actions label">
        <div className="itp-custom-table-actions">
          <label
            className={`itp-custom-table-label${
              width > 991 ? " centered" : ""
            } itp-time-groups-details-subtitle`}
          >
            {`Call flow : ${callFlowDetails?.name}`}
          </label>
        </div>
      </div>

      <div className="itp-custom-table-container-actions label mt-3">
        <div className="itp-custom-table-actions">
          <div
            className={`itp-custom-table-container-search-input${
              width > 991 ? " centered" : ""
            }${inputFocused ? " focused-input" : ""}`}
          >
            <SearchIcon className="itp-custom-table-search-icon" />
            <input
              className="itp-custom-table-search-input"
              placeholder="Search Number"
              onChange={handleChangeSearch}
              onFocus={handleInputFocus}
              onBlur={handleInputBlur}
              value={searchValue}
            />
          </div>
        </div>
      </div>

      <br></br>
      <div>
        <Row>
          <Col>
            <div className="itp-user-settings-separator-line"></div>
          </Col>
        </Row>
      </div>
      <br></br>

      <Card
        className={
          width > 991
            ? `itp-table-container-centered overflow-hidden itp-edit-number-card mb-4`
            : `itp-table-container-all itp-edit-number-card mb-4`
        }
      >
        <div className="d-flex justify-content-between">
          <div className="d-flex flex-column">
            <label className="itp-edit-number-label-1">Name</label>
            <label className="itp-edit-number-label-2">
              Change the name of the specific call flow
            </label>
          </div>
          <input
            placeholder="Add Name"
            className="form-control-itp itp-edit-number-input"
            name="name"
            value={formNameCallFlow.name}
            onChange={(e) => {
              setFormNameCallFlow({ name: e.target.value });
            }}
          />
        </div>
        <div className="d-flex justify-content-between mt-4">
          <div className="d-flex flex-column">
            <label className="itp-edit-number-label-1">Numbers Involved</label>
            <label className="itp-edit-number-label-2">
              Numbers related to this specific call flow
            </label>
          </div>
          <button
            onClick={activeAddNumbers}
            className="btn-primary itp-add-button itp-edit-callFlow-numbers-add-button"
          >
            <FontAwesomeIcon icon={faPlus} className="itp-icon-plus" /> Add
            Number
          </button>
        </div>
        <div className="itp-edit-callFlow-numbers__numbers">
          {data.map((element, i) => (
            <div key={i} className="d-flex justify-content-between mt-4">
              <label className="itp-edit-number-label-1">
                {element.number.number}
              </label>
              <div className="itp-custom-table-container-switchs d-flex align-items-center pl-0 justify-content-end">
                <Switch
                  className="itp-custom-table-switch itp-edit-callFlow-numbers-switch"
                  onColor="#626ed4"
                  height={20} // Ajusta la altura según tus necesidades
                  width={40}
                  checked={
                    formNumbersSwitch[element?.number?.number] !== undefined
                      ? formNumbersSwitch[element?.number?.number]
                      : false
                  }
                  onChange={() => handleCheck(element)}
                />
              </div>
            </div>
          ))}
        </div>
      </Card>

      <MenuRight
        show={openMenu}
        closeMenu={closeMenu}
        title="Add New Number to the call flow"
        to=""
      >
        <Row className="itp-container-label-input">
          <Col
            xs={5}
            sm={5}
            md={5}
            lg={5}
            xl={5}
            xxl={5}
            className="itp-label-input-col"
          >
            <label className="itp-label-input__label">Add Number</label>
          </Col>
          <Col
            xs={7}
            sm={7}
            md={7}
            lg={7}
            xl={7}
            xxl={7}
            className="itp-label-input-col"
          >
            <input
              type="number"
              placeholder=": Add a number here"
              className="form-control-itp itp-label-input__input"
              name="addInputNumber"
              value={addInputNumber}
              onChange={handleChangeInputNumber}
            />
          </Col>
        </Row>
        <button
          onClick={handleAddNumber}
          className="btn-primary itp-lead-detail-button-save loading"
          disabled={addInputNumber === "" || loadingAddNumber ? true : false}
        >
          {loadingAddNumber && <LoadingButton />}
          Save
        </button>
        {error && <p className="text-danger">{error}</p>}
      </MenuRight>
    </>
  );
};

export default EditCallFlowNumbers;
