import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { LoadingButton } from "../../../../../components";

const ModalDelete = ({
  modal,
  closeModal,
  loading,
  modalFunction,
  text,
  title,
  noSaveButton,
}) => {
  return (
    <Modal isOpen={modal ? true : false} className="info">
      <ModalHeader>{title ? title : "Delete Contact"}</ModalHeader>
      <ModalBody>{text ? text : "Are You Sure?"}</ModalBody>
      <ModalFooter>
        <button disabled={loading} className="btn-light" onClick={closeModal}>
          No
        </button>
        {!noSaveButton && (
          <button
            disabled={loading}
            className="btn-primary loading"
            onClick={modalFunction}
          >
            {loading && <LoadingButton />}
            Yes
          </button>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default ModalDelete;
