import React, { useEffect, useState } from "react"
import {
  CustomTable,
  Loading,
  MenuRight,
  LoadingButton,
} from "../../../../../components";
import SearchIcon from "@mui/icons-material/Search";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { Col, Row } from "reactstrap";
import useScreenSize from "../../../../../hooks/useScreenSize";
import petitionGet from "../../../../../services/petitionGet";
import petitionPost from "../../../../../services/petitionPost";
import petitionPatch from "../../../../../services/petitionPatch";
import petitionDelete from "../../../../../services/petitionDelete";
import ModalDelete from "../ModalUtility/ModalDelete";
import "../../Contacts.css"

const Tags = () => {
  const { width } = useScreenSize();
  const [loading, setLoading] = useState(true);
  const [dataTable, setDataTable] = useState(null);
  const [info, setInfo] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [tagName, setTagName] = useState("");
  const [loadingMenuButton, setLoadingMenuButton] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);

  const activeMenu = (e) => {
    if (e.name) {
      setInfo(e);
    }
    setOpenMenu(true);
  };

  const closeMenu = () => {
    setModalDelete(false);
    setTagName("");
    setInfo(null);
    setOpenMenu(false);
  };

  const activeModalDelete = (e) => {
    setInfo(e);
    setModalDelete(true);
  };

  const petition = () => {
    setLoading(true);
    petitionGet("tags")
      .then(({ data: result }) => {
        setDataTable({
          title: [
            { title: "Tag Name", key: "name" },
            // { title: "No of Contacts Used the Tags", key: "contact_count" },
          ],
          content: [...result.result],
          actions: {
            title: "Actions",
            content: [
              {
                icon: <FontAwesomeIcon icon={faPenToSquare} />,
                name: "Edit",
                handleClick: activeMenu,
              },
              {
                icon: <FontAwesomeIcon icon={faTrash} />,
                name: "Delete",
                handleClick: activeModalDelete,
              },
            ],
          },
          addButton: {
            label: "Add New Tag",
            handleClick: activeMenu,
          },
          label: "Tags used for the contacts",
          titleTable: "Tags",
        });
        setLoading(false);
      })
      .catch((error) => console.log(error));
  };

  const handleChange = (e) => {
    setTagName(e.target.value);
  };

  const reloadData = () => {
    closeMenu();
    petition();
  };

  const saveFunctionMenu = () => {
    setLoadingMenuButton(true);
    let data = {
      name: tagName,
    };
    if (info) {
      petitionPatch("tag", {
        data,
        tag_id: info.pk,
      })
        .then(({ data: result }) => {
          setLoadingMenuButton(false);
          reloadData();
        })
        .catch((error) => console.log(error));
    } else {
      petitionPost("tag", { data })
        .then(({ data: result }) => {
          setLoadingMenuButton(false);
          reloadData();
        })
        .catch((error) => console.log(error));
    }
  };

  const deleteFunction = () => {
    setLoadingMenuButton(true);
    petitionDelete("tag", { tag_id: info.pk })
      .then(({ data: result }) => {
        setLoadingMenuButton(false);
        reloadData();
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if (info && !modalDelete) {
      setTagName(info.name);
    }
  }, [info]);

  useEffect(() => {
    petition();
  }, []);

  return loading ? (
    <Loading />
  ) : (
    <>
      {modalDelete && (
        <ModalDelete
          modal={modalDelete}
          closeModal={closeMenu}
          loading={loadingMenuButton}
          modalFunction={deleteFunction}
          info={info}
        />
      )}
       <div
      style={{ marginLeft: "2rem", marginLeft:"410px"}}
      className={`itp-custom-table-container-search-input mt-4
      ${false ? " focused-input" : ""}`}
      >
        <SearchIcon className="itp-custom-table-search-icon" />
        <input
        className="itp-custom-table-search-input"
        placeholder="Search Contact"
        />
      </div>
      <div className="container-table-contact-list">
      <CustomTable
        data={dataTable}
        noPagBack={true}
        centered={width > 991 ? true : false}
      />
      </div>

      <MenuRight
        show={openMenu}
        closeMenu={closeMenu}
        title="Create New Tag"
        subtitle="Add a tag name which can appear in contact list"
        to="to Tags"
      >
        {info?.is_default ? (
          <h6>It is not possible to edit the default list</h6>
        ) : (
          <>
            <Row className="itp-container-label-input">
              <Col
                xs={5}
                sm={5}
                md={5}
                lg={5}
                xl={5}
                xxl={5}
                className="itp-label-input-col"
              >
                <label className="itp-label-input__label">Tag Name</label>
              </Col>
              <Col
                xs={7}
                sm={7}
                md={7}
                lg={7}
                xl={7}
                xxl={7}
                className="itp-label-input-col"
              >
                <input
                  type="text"
                  placeholder=": Add a name for tag"
                  className="form-control-itp itp-label-input__input"
                  name="name"
                  value={tagName}
                  onChange={handleChange}
                />
              </Col>
            </Row>
            <button
              disabled={loadingMenuButton}
              className="btn-primary loading"
              onClick={saveFunctionMenu}
            >
              {loadingMenuButton && <LoadingButton />}
              Save
            </button>
          </>
        )}
      </MenuRight>
    </>
  );
};

export default Tags;
