import { put, takeLatest } from "redux-saga/effects";
import { defaultSidebarSucces } from "../actions/sidebar";

function* defaultSidebarMenu() {
  yield put(defaultSidebarSucces());
}

function* defaultSidebarSaga() {
  yield takeLatest("DEFAULT_SIDEBAR", defaultSidebarMenu);
}

export default defaultSidebarSaga;
