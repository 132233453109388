import React, { useEffect, useState } from "react";
import {
  Routes,
  Route,
  Navigate,
  BrowserRouter as Router,
} from "react-router-dom";
import { useDispatch } from "react-redux";
import { logoutRequest } from "./redux/actions/login";
import { getMyExtention } from "./redux/actions/myExtension";
import { Login, ForgetPassword, ChangePassword } from "./pages/public";
import Index from "./routes/index";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { WebPhone } from "./components";
import { Alert } from "react-bootstrap";
import { useSelector } from "react-redux";

function App() {
  const dispatch = useDispatch();
  const [showWebphone, setShowWebphone] = useState(false);
  const [webphoneData, setWebphoneData] = useState(false);
  const [sipconfiguration, setSipConfiguration] = useState(false);
  const [webphonePosition, setWebphonePosition] = useState({ x: 0, y: 0 });
  const [visible, setVisible] = useState(false);
  const [message, setMessage] = useState("");
  const [variant, setVariant] = useState("");

  const [isClickCall, setIsClickCall] = useState(false);
  const [numberToCallOnClick, setNumberToCallOnClick] = useState("");

  const onDismiss = () => setVisible(false);
  let path = window.location.pathname;
  const [isLoggedIn, setIsLoggedIn] = useState(
    localStorage.getItem("userDetails") !== null ? true : false
  );

  const json = require("./config.json");

  const wssServer = json.prod ? json.wssServer.prod : json.wssServer.dev;

  useEffect(() => {
    let webphoneDataString = localStorage.getItem("webphone");
    let userDetailsString = localStorage.getItem("userDetails");
    if (webphoneDataString) {
      try {
        // Analizar la cadena JSON en un objeto JavaScript
        let webphoneData = JSON.parse(webphoneDataString);

        let userDetails = userDetailsString
          ? JSON.parse(userDetailsString)
          : { firstname: "", lastname: "" };

        setWebphoneData(webphoneData);
        // console.log(
        //   "------------------------------------------------>",
        //   webphoneData
        // );
        // Ahora puedes utilizar webphoneData en tu aplicación
        // console.log("Información del webphone:", webphoneData);

        // Ahora puedes utilizar webphoneData en tu aplicación
        let sipconfigurationTemp = {
          username: webphoneData.sip_username,
          password: webphoneData.sip_password,
          realm: webphoneData.realm,
          transportOptions: { server: wssServer },
          name: userDetails.firstname + " " + userDetails.lastname,
          enableCallWaiting: true,
          onInvite: () => {
            console.log("invite");
          },
          onDisconnect: () => {
            setMessage("Disconnected");
            setVisible(true);
            setVariant("danger");
          },
          onUnregistered: () => {
            setMessage("Unregistered");
            setVisible(true);
            setVariant("danger");
          },
          onRegistered: () => {
            setMessage("Registered");
            setVisible(true);
            setVariant("success");
          },
          maxCalls: 2,
          enableCall: true,
          onChangeEnableCall: () => {
            console.log("enable");
          },
          enableCallWaiting: true,
          onChangeEnableCallWaiting: () => {
            console.log("enable Call waiting");
          },
        };
        setSipConfiguration(sipconfigurationTemp);
        // Hacer lo que necesites con webphoneData
      } catch (error) {
        console.error("Error al analizar los datos del webphone:", error);
      }
    } else {
      console.log("No se encontraron datos del webphone en el localStorage.");
    }
  }, []);

  useEffect(() => {
    if (variant != "danger") {
      setTimeout(() => {
        // console.log("ppppppppppppppppppppp2");
        onDismiss();
      }, 5000);
    }
  }, [visible]);

  const handleDragStart = (e) => {
    const startX = e.clientX - webphonePosition.x;
    const startY = e.clientY - webphonePosition.y;

    const handleDragMove = (e) => {
      const newX = e.clientX - startX;
      const newY = e.clientY - startY;

      setWebphonePosition({ x: newX, y: newY });
    };

    const handleDragEnd = () => {
      window.removeEventListener("mousemove", handleDragMove);
      window.removeEventListener("mouseup", handleDragEnd);
    };

    window.addEventListener("mousemove", handleDragMove);
    window.addEventListener("mouseup", handleDragEnd);
  };

  const onLogout = () => {
    dispatch(logoutRequest());
  };

  const handleActiveCall = (boolean, number) => {
    console.log(boolean);
    console.log(number);
    setIsClickCall(boolean);
    setNumberToCallOnClick(number);
    setShowWebphone(true);
  };

  const handleCloseActiveCall = () => {
    setIsClickCall(false);
    setNumberToCallOnClick("");
  };

  useEffect(() => {
    const checkSession = () => {
      if (!localStorage.getItem("userDetails")) {
        setIsLoggedIn(false);
        onLogout();
      }
    };

    let params = new URL(document.location).searchParams;
    var logoutVar = params.get("logout");
    if (logoutVar === "true") onLogout();

    if (isLoggedIn) {
      dispatch(getMyExtention());
    }

    window.addEventListener("storage", checkSession);
  }, []);

  if (isLoggedIn) {
    return (
      <div className="App">
        <div
          style={{
            position: "absolute",
            width: "300px",
            zIndex: "1000",
            right: "0px",
          }}
        >
          <Alert
            variant={variant}
            className="alert-dismissible fade show"
            show={visible}
            onClose={onDismiss}
            dismissible={true}
          >
            {message}
          </Alert>
        </div>

        <Index
          setShowWebphone={setShowWebphone}
          handleActiveCall={handleActiveCall}
        />
        <div className="webphone-component">
          {sipconfiguration && (
            <WebPhone
              sipconfiguration={sipconfiguration}
              showWebphone={showWebphone}
              isClickCall={isClickCall}
              numberToCallOnClick={numberToCallOnClick}
              handleCloseActiveCall={handleCloseActiveCall}
            />
          )}
        </div>
      </div>
    );
  } else {
    return (
      <div className="App">
        <Router>
          <Routes>
            <Route path="/login" element={!isLoggedIn && <Login />} />
            <Route
              path="/"
              element={!isLoggedIn && <Navigate to="/login" replace />}
            />
            <Route
              path="/Forget-Password"
              element={!isLoggedIn && <ForgetPassword />}
            />

            <Route
              path="/Change-Password"
              element={!isLoggedIn && <ChangePassword />}
            />

            {path !== "/login" &&
              path !== "/" &&
              path !== "/Forget-Password" &&
              path !== "Change-Password" && (
                <Route
                  path={path}
                  element={!isLoggedIn && <Navigate to="/login" replace />}
                />
              )}
          </Routes>
        </Router>
      </div>
    );
  }
}

export default App;
