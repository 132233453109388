import React, { useState, useEffect } from "react";
import { Row, Col, Input, Button } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { postLogin, logoutRequest } from "../../../redux/actions/login";
import { LoadingButton } from "../../../components";
import petitionGet from "../../../services/petitionGet";
import voice360Logo from "../../../assets/images/voice360square.png";
import "./Login.css";

function Login() {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.login.loading);
  const [form, setForm] = useState({});
  const [isAutoLogin, setIsAutoLogin] = useState(false);

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const onLogout = () => {
    dispatch(logoutRequest());
  };

  const handlesubmit = (e) => {
    e.preventDefault();
    dispatch(postLogin(form));
  };

  useEffect(() => {
    const params = new URL(document.location).searchParams;
    const token = params.get("token");
    const api_id = params.get("v2");
    const redirect = params.get("redirect");

    if (token && !redirect) {
      setIsAutoLogin(true);

      localStorage.setItem(
        "userDetails",
        JSON.stringify({ access_token: token })
      );

      petitionGet("refresh", token)
        .then((result) => {
          localStorage.setItem(
            "userDetails",
            JSON.stringify({
              ...result.data.result,
              access_token: token,
              api_id,
            })
          );
          // debugger
          window.location.href = window.location.origin + "/Dashboard";
        })
        .catch((err) => {
          console.log(err.response);
          // debugger
          onLogout();
        });
    }
  }, []);

  return (
    <>
      <Row className="ipt-login-row">
        <Col
          sm={12}
          md={7}
          xl={8}
          xxl={8}
          className="ipt-login-left d-flex align-items-center"
        >
          <Row>
            <Col sm={12} md={12} lg={8} xl={6} xxl={6} className="mx-auto">
              <div className="itp-login-logo-container">
                <img
                  src={voice360Logo}
                  alt="Voice360"
                  className="itp-login-logo"
                />
              </div>
              <div className="itp-login-login-text">Hello Again!</div>

              <div className="itp-login-welcome">
                Welcome back! Securely access your account and explore the world
                of automated communication.
              </div>
              {isAutoLogin ? (
                <>
                  <div className="itp-login-login-text">
                    Sit tight, we are logging you in...
                  </div>
                </>
              ) : (
                <>
                  <form onSubmit={(e) => handlesubmit(e)}>
                    <div className="itp-login-user-container">
                      <Input
                        onChange={handleChange}
                        id="exampleEmail"
                        name="username"
                        placeholder="test@example.com"
                        type="email"
                        className="itp-login-input"
                      />
                    </div>
                    <div className="itp-login-email-container">
                      <Input
                        onChange={handleChange}
                        id="examplePassword"
                        name="password"
                        placeholder="password"
                        type="password"
                        className="itp-login-input"
                      />
                    </div>
                    <div className="itp-login-button-container">
                      <Button
                        className="itp-login-button d-flex justify-content-center align-items-center"
                        type="submit"
                      >
                        <span className="itp-login-button-span">Log In</span>
                        {loading && <LoadingButton />}
                      </Button>
                    </div>
                  </form>
                  <Row className="itp-login-options">
                    <Col xl={7}>
                      {/* <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      role="switch"
                      id="flexSwitchCheckDefault"
                    />
                    <label
                      className="form-check-label itp-login-remember"
                      htmlFor="flexSwitchCheckDefault"
                    >
                      Remember me
                    </label>
                  </div> */}
                    </Col>
                    <Col xl={5}>
                      <Link to="/Forget-Password" className="itp-login-forgot">
                        Forget Password ?
                      </Link>
                    </Col>
                  </Row>
                  <div
                    id="google-button"
                    className="login-button-google-container"
                  >
                    <button className="login-button-google">
                      <img
                        src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"
                        alt="G-logo"
                        className="g-logo"
                      />
                      Sign in with Google
                    </button>
                  </div>
                  <div className="itp-login-create-account">
                    Don't have an account?{" "}
                    <a className="itp-login-signup">Sign Up</a>
                  </div>
                </>
              )}
            </Col>
          </Row>
        </Col>
        <Col sm={12} md={5} xl={4} xxl={4} className="ipt-login-right">
          <br />
          <br />
          <br />
          <br />
          <Row className="itp-login-text"></Row>
        </Col>
      </Row>
    </>
  );
}

export default Login;
