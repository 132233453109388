import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { LoadingButton } from "../index";
import Switch from "react-switch";
import Select from "react-select";
import "./Filter.css";

const Filter = ({ info, loading }) => {
  const [formInputs, setFormInputs] = useState({});
  const [formCheckbox, setFormCheckbox] = useState({});
  const [change, setChange] = useState(false);
  const [render, setRender] = useState(false);
  const colourStyles = {
    input: () => ({ color: "white", padding: "0" }),
  };

  const Offsymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        No
      </div>
    );
  };

  const OnSymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        Yes
      </div>
    );
  };

  const saveFunction = () => {
    if (change) {
      let newFormCheckbox = {};
      let formLabels = {};
      info.parameters.map((element) => {
        element.inputs.map((input) => {
          if (typeof input.checkbox !== "undefined") {
            if (formCheckbox[input.checkboxName]) {
              if (input.input === "date") {
                if (
                  formInputs[input.key_added_after] &&
                  formInputs[input.key_added_before]
                ) {
                  newFormCheckbox[input.key_added_after] =
                    formInputs[input.key_added_after];
                  newFormCheckbox[input.key_added_before] =
                    formInputs[input.key_added_before];
                }
              } else {
                newFormCheckbox[input.key] = formInputs[input.key];
                formLabels[input.label] = formInputs[input.key];
              }
            }
          } else {
            newFormCheckbox[input.key] = formInputs[input.key];
            formLabels[input.label] = formInputs[input.key];
          }
        });
      });

      info.onSaveFunction(newFormCheckbox, formLabels);
    }
  };

  useEffect(() => {
    setFormInputs({});
    let newFormInputs = {};
    let newFormCheckbox = {};
    info.parameters.map((element) => {
      element.inputs.map((input) => {
        if (input.checkbox) {
          newFormCheckbox = {
            ...newFormCheckbox,
            [input.checkboxName]: input.checkboxValue,
          };
        }
        if (input.input && input.input === "date") {
          newFormInputs = {
            ...newFormInputs,
            [input.key_added_after]: input.inputValue_added_after,
            [input.key_added_before]: input.inputValue_added_before,
          };
        } else if (input.input && input.input !== "date") {
          newFormInputs = {
            ...newFormInputs,
            [input.key]: input.inputValue,
          };
        }
      });
    });

    setFormInputs(newFormInputs);
    setFormCheckbox(newFormCheckbox);
    setRender(true);
  }, []);

  return render && (
    <>
      <div>
        {info.parameters.map((element) => (
          <div key={element.title} className="container-filter">
            <p style={{ marginBottom: "0" }} className="itp-filter-title">
              {element.title}
            </p>
            {element.inputs.map((input, pos) => (
              <div key={pos}>
                {input.input === "checkbox" &&
                typeof input.checkbox === "undefined" ? (
                  <Row key={input.label} style={{ marginBottom: "1rem" }}>
                    <Col xs={7} sm={7} md={7} lg={7} xl={7} xxl={7}>
                      <div
                        style={{
                          display: "flex",
                          height: "100%",
                          alignItems: "center",
                        }}
                      >
                        <input
                          checked={formInputs[input.key]}
                          name={input.key}
                          onChange={(e) => {
                            setFormInputs({
                              ...formInputs,
                              [e.target.name]: !formInputs[e.target.name],
                            });
                            setChange(true);
                          }}
                          type="checkbox"
                          className="form-check-input"
                          style={{ marginRight: "5px" }}
                        />
                        <span className="itp-filter-label">{input.label}</span>
                      </div>
                    </Col>
                  </Row>
                ) : (
                  <Row key={input.label} style={{ marginBottom: "1rem" }}>
                    <Col
                      xs={input.input === "date" ? 6 : 7}
                      sm={input.input === "date" ? 6 : 7}
                      md={input.input === "date" ? 6 : 7}
                      lg={input.input === "date" ? 6 : 7}
                      xl={input.input === "date" ? 6 : 7}
                      xxl={input.input === "date" ? 4 : 7}
                    >
                      <div
                        style={{
                          display: "flex",
                          height: "100%",
                          alignItems: "center",
                        }}
                      >
                        {input.checkbox && (
                          <input
                            checked={formCheckbox[input.checkboxName]}
                            name={input.checkboxName}
                            onChange={(e) => {
                              setFormCheckbox({
                                ...formCheckbox,
                                [e.target.name]: !formCheckbox[e.target.name],
                              });
                              setChange(true);
                            }}
                            type="checkbox"
                            className="form-check-input"
                            style={{ marginRight: "5px" }}
                          />
                        )}
                        <span className="itp-filter-label">{input.label}</span>
                      </div>
                    </Col>
                    {input.input && (
                      <Col
                        xs={input.input === "date" ? 10 : input.input === "multiSelect" ? 7 : 5}
                        sm={input.input === "date" ? 10 : 5}
                        md={input.input === "date" ? 10 : 5}
                        lg={input.input === "date" ? 10 : 5}
                        xl={input.input === "date" ? 10 : 5}
                        xxl={input.input === "date" ? 8 : 5}
                      >
                        {input.input === "checkbox" && (
                          <input
                            type="checkbox"
                            name={input.key}
                            onChange={(e) => {
                              setFormInputs({
                                ...formInputs,
                                [e.target.name]: !formInputs[input.key],
                              });
                              setChange(true);
                            }}
                            checked={formInputs[input.key] || false}
                            className="form-check-input checbox-filter"
                          />
                        )}

                        {input.input === "switch" && (
                          <Switch
                            uncheckedIcon={<Offsymbol />}
                            className="mr-1"
                            checkedIcon={<OnSymbol />}
                            onColor="#626ed4"
                            onChange={() => {
                              setFormInputs({
                                ...formInputs,
                                [input.key]: !formInputs[input.key],
                              });
                              setChange(true);
                            }}
                            checked={formInputs[input.key]}
                          />
                        )}

                        {input.input === "select" && (
                          <select
                            className="form-select-itp"
                            value={formInputs[input.key] || ""}
                            name={input.key}
                            onChange={(e) => {
                              setFormInputs({
                                ...formInputs,
                                [e.target.name]: e.target.value,
                              });
                              setChange(true);
                            }}
                          >
                            <option value="" disabled>
                              Select Option
                            </option>
                            {input.options.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                        )}

                        {input.input === "multiSelect" && (
                          <Select
                            placeholder="Select Option(s)"
                            isMulti
                            name="multi_select"
                            options={input.options}
                            className="basic-multi-select"
                            classNamePrefix="my-multiselect"
                            onChange={(elements) => {
                              setFormInputs({
                                ...formInputs,
                                [input.key]: elements,
                              });
                              setChange(true);
                            }}
                            defaultValue={input.inputValue}
                            styles={colourStyles}
                          />
                        )}

                        {input.input === "selectAutoComplete" && (
                          <Select
                            placeholder="Select Option"
                            options={input.options}
                            className="basic-single"
                            classNamePrefix="my-select"
                            onChange={(elements) => {
                              setFormInputs({
                                ...formInputs,
                                [input.key]: elements,
                              });
                              setChange(true);
                            }}
                            defaultValue={input.inputValue}
                            styles={colourStyles}
                          />
                        )}

                        {input.input === "date" && (
                          <div className="container-date-range">
                            <label className="date-range-label">From:</label>
                            <input
                              type="date"
                              className="date-range-input"
                              name={input.key_added_after}
                              value={formInputs[input.key_added_after] || ""}
                              onChange={(e) => {
                                setFormInputs({
                                  ...formInputs,
                                  [e.target.name]: e.target.value,
                                });
                                setChange(true);
                              }}
                            />
                            <div>
                              <FontAwesomeIcon
                                icon={faArrowRight}
                                className="date-range-separator"
                              />
                            </div>
                            <label className="date-range-label">To:</label>
                            <input
                              type="date"
                              className="date-range-input"
                              name={input.key_added_before}
                              value={formInputs[input.key_added_before] || ""}
                              onChange={(e) => {
                                setFormInputs({
                                  ...formInputs,
                                  [e.target.name]: e.target.value,
                                });
                                setChange(true);
                              }}
                            />
                          </div>
                        )}

                        {input.input === "text" && (
                          <input
                            type={input.input}
                            name={input.key}
                            onChange={(e) => {
                              setFormInputs({
                                ...formInputs,
                                [e.target.name]: e.target.value,
                              });
                              setChange(true);
                            }}
                            defaultValue={formInputs[input.key] || ""}
                            className="form-control-itp"
                          />
                        )}
                      </Col>
                    )}
                  </Row>
                )}
              </div>
            ))}
          </div>
        ))}
      </div>
      <div>
        <button
          disabled={loading}
          className="btn-primary loading"
          onClick={saveFunction}
        >
          {loading && <LoadingButton />}
          Apply
        </button>
      </div>
    </>
  );
};

export default Filter;
