import React, { useState } from "react";
import { Col, Row } from "reactstrap";
import "./SelectCheckbox.css";

const SelectCheckbox = ({ name, options, defaultSelected, checkFunction, style }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState(defaultSelected || []);

  const toggleSelect = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (value) => {
    if (selectedOptions.includes(value)) {
      checkFunction(selectedOptions.filter((option) => option !== value));
      setSelectedOptions(selectedOptions.filter((option) => option !== value));
    } else {
      checkFunction([...selectedOptions, value]);
      setSelectedOptions([...selectedOptions, value]);
    }
  };

  return (
    <div className="select-container" style={{marginLeft:"1.8rem"}}>
      <div
        className={`select-header${isOpen ? " active-menu" : ""}`}
        onClick={toggleSelect}
      >
        {name}
      </div>
      {isOpen && (
        <div className="select-options">
          {options.map((option, i) => (
            <Row
              key={i}
              className={`select-option ${
                selectedOptions.includes(option.value) ? "selected" : ""
              }`}
              onClick={() => handleOptionClick(option.value)}
            >
              <Col lg={8}>
                <span>{option.label}</span>
              </Col>
              <Col lg={4}>
                <input
                  type="checkbox"
                  className="form-check-input"
                  checked={selectedOptions.includes(option.value)}
                  onChange={() => {}}
                />
              </Col>
            </Row>
          ))}
        </div>
      )}
    </div>
  );
};

export default SelectCheckbox;
