import React from "react";
import { Row, Col } from "reactstrap";

const Step1 = ({ form, setForm, brands }) => {
  return (
    <>
      <h3>Campaign Details</h3>
      <br></br>

      <Row className="itp-container-label-select">
        <Col
          xs={5}
          sm={5}
          md={5}
          lg={5}
          xl={5}
          xxl={5}
          className="itp-label-input-col"
        >
          <label className="itp-label-input__label">Brands *</label>
        </Col>
        <Col
          xs={7}
          sm={7}
          md={7}
          lg={7}
          xl={7}
          xxl={7}
          className="itp-label-input-col"
        >
          <select
            className="form-select-itp"
            onChange={(e) => setForm({ ...form, brandId: e.target.value })}
            value={form.brandId}
            name="brandId"
          >
            <option value="" disabled>
              Select Option
            </option>
            {brands.map(
              (element, i) =>
                element.brandId && (
                  <option value={element.brandId} key={i}>
                    {element.displayName}
                  </option>
                )
            )}
          </select>
        </Col>
      </Row>

      <Row className="itp-container-label-select">
        <Col
          xs={5}
          sm={5}
          md={5}
          lg={5}
          xl={5}
          xxl={5}
          className="itp-label-input-col"
        >
          <label className="itp-label-input__label">Use Case *</label>
        </Col>
        <Col
          xs={7}
          sm={7}
          md={7}
          lg={7}
          xl={7}
          xxl={7}
          className="itp-label-input-col"
        >
          <select
            className="form-select-itp"
            onChange={(e) => setForm({ ...form, usecase: e.target.value })}
            value={form.usecase}
            name="usecase"
          >
            <option value="" disabled>
              Select Option
            </option>
            <option value="LOW_VOLUME">
              Low Volume $1.50/Month (Use if you send less than 500 messages per
              day)
            </option>
            <option value="MARKETING">
              Marketing $10.00/Month (Use if you have more than 500 messages per
              day and send out marketing campaigns)
            </option>
          </select>
        </Col>
      </Row>

      <br></br>
      <label className="itp-label-input__label">Campaign Description *</label>
      <textarea
        className="form-control-itp"
        onChange={(e) => setForm({ ...form, description: e.target.value })}
        value={form.description}
      />
      <br></br>
      <label className="itp-label-input__label">How do end users consent to messaages?</label>
      <textarea
        className="form-control-itp"
        onChange={(e) => setForm({ ...form, messageFlow: e.target.value })}
        value={form.messageFlow}
      ></textarea>
    </>
  );
};

export default Step1;
