import React, { useEffect, useState } from "react";
import "./styles.css";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
} from "reactstrap";
import UndoIcon from "@mui/icons-material/Undo";
import RedoIcon from "@mui/icons-material/Redo";

export default function TextAreaVarSelect({
  options,
  onChangeTextArea,
  textAreaDefaultValue,
}) {
  const [dropdownOpen, setDropdownOpen] = useState([]);
  const [textAreaValue, setTextAreaValue] = useState("");

  const toggleDropdown = (index) => {
    const newDropdownOpen = [...dropdownOpen];
    newDropdownOpen[index] = !newDropdownOpen[index];
    setDropdownOpen(newDropdownOpen);
  };

  const onUndoClick = () => document.execCommand("undo", false, null);
  const onRedoClick = () => document.execCommand("redo", false, null);

  const handleOptionClick = (optionLabel) => {
    setTextAreaValue((prevTextAreaValue) => prevTextAreaValue + optionLabel);
  };

  useEffect(() => {
    if (textAreaDefaultValue) {
      setTextAreaValue(textAreaDefaultValue);
    }
  }, [textAreaDefaultValue]);

  useEffect(() => {
    onChangeTextArea(textAreaValue);
  }, [textAreaValue]);

  return (
    <>
      <div style={{ width: "100%" }}>
        <div
          style={{
            height: "30px",
            border: "1px solid #ccc",
            borderBottom: "none",
            background: "#F1F1F1",
            display: "flex",
          }}
        >
          <Button className="CustomDropDown undo" onClick={onUndoClick}>
            <UndoIcon />
          </Button>
          <Button className="CustomDropDown redo" onClick={onRedoClick}>
            <RedoIcon />
          </Button>
          {options.map((element, index) => (
            <Dropdown
              isOpen={dropdownOpen[index]}
              toggle={() => toggleDropdown(index)}
              key={index}
              className="dropDownTextAreaSelect"
            >
              <DropdownToggle caret className="CustomDropDown">
                {element.title}
              </DropdownToggle>
              <DropdownMenu>
                {element.options.map((group, i) => (
                  <React.Fragment key={i}>
                    <DropdownItem header>{group.title}</DropdownItem>
                    {group.options.map((option, index) => (
                      <DropdownItem
                        key={index}
                        onClick={() => handleOptionClick(option.value)}
                      >
                        {option.label}
                      </DropdownItem>
                    ))}
                    {i !== element.options.length - 1 && (
                      <DropdownItem divider />
                    )}
                  </React.Fragment>
                ))}
              </DropdownMenu>
            </Dropdown>
          ))}
        </div>
        <textarea
          rows="10"
          style={{ width: "100%", border: "1px solid #ccc" }}
          value={textAreaValue}
          onChange={(e) => setTextAreaValue(e.target.value)}
        ></textarea>
      </div>
    </>
  );
}
