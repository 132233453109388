import React, { useState, useEffect } from "react";
import { TabMenu } from "../../../../../../components";
import ImportContacts from "./Tabs/ImportContacts";
import ViewJobs from "./Tabs/ViewJobs";

const Import = ({ 
  defaultContactList, 
  backFunction,
  setImportedContacts,
  setAddedContactListImort, 
  customFields,
}) => {
  const [changeTab, setChangeTab] = useState(null)

  const tabs = [
    {
      title: "Import Contacts",
      component: (
        <ImportContacts
          defaultContactList={defaultContactList}
          backFunction={backFunction}
          setChangeTab={setChangeTab}
          setAddedContactListImort={setAddedContactListImort}
          customFields={customFields}
        />
      ),
    },
    {
      title: "Jobs",
      component: <ViewJobs />,
    },
  ];

  useEffect(() => {
    if(changeTab){
      setChangeTab(null)
      setImportedContacts(true)
    }
  }, [changeTab])
  return (
    <>
      <TabMenu tabs={tabs} changeTab={changeTab} />
    </>
  );
};

export default Import;
