import React, { useEffect, useState } from "react";
import { Loading, LoadingButton, ToggleImage } from "../../../../components";
import {
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarAlt,
  faCheckSquare,
  faCopy,
  faFileText,
  faMapMarkerAlt,
  faPaperclip,
  faPenToSquare,
  faPlus,
  faThumbtack,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { formatDate } from "../../../../utils/getDate";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import PhoneInput from "react-phone-input-2";
import Select from "react-select";
import Tag from "../../../../assets/icons/addTag.svg";
import userImage from "../../../../assets/images/userImage.png";
import petitionGet from "../../../../services/petitionGet";
import petitionPost from "../../../../services/petitionPost";
import petitionDelete from "../../../../services/petitionDelete";
import petitionPatch from "../../../../services/petitionPatch";
import "./LeadDetails.css";

const LeadDetails = ({
  leadDetails,
  setLeadDetails,
  tagsExternal,
  myExtension,
  petitionTag,
  loadingEdit,
  editLead,
  funnels,
}) => {
  const filter = createFilterOptions();
  const [disabled, setDisabled] = useState(false);
  const [tags, setTags] = useState([]);
  const [optionsTags, setOptionsTags] = useState([]);
  const [newTags, setNewTags] = useState([]);
  const [loading, setLoading] = useState(true);
  const [notes, setNotes] = useState([]);
  const [editNote, setEditNote] = useState(null);
  const [customFields, setCustomFields] = useState([]);
  const [contactList, setContactList] = useState([]);
  const [valueContactList, setValueContactList] = useState(null);
  const [addNote, setAddNote] = useState(false);
  const [deleteNote, setDeleteNote] = useState(null);
  const [loadingNote, setLoadingNote] = useState(false);
  const [noteContent, setNoteContent] = useState("");
  const [openDropdownNotes, setOpenDropdownNotes] = useState(null);
  const [changeEditNote, setChangeEditNote] = useState(null);
  const [users, setUsers] = useState([]);
  const [asignedUser, setAsignedUser] = useState(null);
  const [fieldType, setFieldType] = useState("");
  const [newField, setNewField] = useState("");
  const [loadingAddField, setLoadingAddField] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [stages, setStages] = useState([]);
  const [valueFunnel, setValueFunnel] = useState(null);
  const [valueStage, setValueStage] = useState(null);
  const [loadingChangeFunnel, setLoadingChangeFunnel] = useState(false);
  const [formLead, setFormLead] = useState({
    name: leadDetails.name,
    amount: leadDetails.amount,
    funnel_pipeline_stage_id: leadDetails.funnel_pipeline_stage_id,
    funnel_id: leadDetails.funnel_pipeline_stage.funnel.pk,
  });
  const [formContact, setFormContact] = useState({
    firstname: leadDetails.contact.firstname,
    lastname: leadDetails.contact.lastname || "",
    email: leadDetails.contact.email || "",
    phone: leadDetails.contact.phone || "",
    address: leadDetails.contact.address || "",
  });

  const toggle = () => {
    setOpenDropdown(!openDropdown);
  };

  const defaultValueAddField = (field) => {
    switch (field) {
      case "address":
        return "Address";
      case "url":
        return "URL";
      case "date":
        return "Date";
      case "text/note":
        return "Text / Note";
      case "checkbox":
        return "Checkbox";
      default:
        return "error";
    }
  };

  const addCustomField = () => {
    setLoadingAddField(true);
    let data = {
      name: newField || defaultValueAddField(fieldType),
      field_type: fieldType,
      field_metadata: {
        max_length: 150,
      },
    };
    petitionPost("customFieldMyExtension", { data })
      .then(({ data: result }) => {
        reloadData();
      })
      .catch((error) => {
        console.log(error);
        setLoadingAddField(false);
      });
  };

  const onchangeFormContact = (e) => {
    setFormContact({ ...formContact, [e.target.name]: e.target.value });
  };

  const onchangeFormLead = (e) => {
    setFormLead({ ...formLead, [e.target.name]: e.target.value });
  };

  const onChangeFunnel = (e) => {
    setLoadingChangeFunnel(true);
    petitionGet("stages", { parameter: `?funnel_id=${e.pk}` })
      .then(({ data: result }) => {
        if (result.result.length > 0) {
          result.result.forEach((stage) => {
            stage.label = stage.name;
            stage.value = stage.pk;
          });

          setStages(result.result);
        } else {
          setStages([]);
        }

        setFormLead({
          ...formLead,
          funnel_id: e.pk,
          funnel_pipeline_stage_id: "",
        });
        setValueFunnel(e);
        setValueStage(null);
        setLoadingChangeFunnel(false);
      })
      .catch((error) => console.log(error));
  };

  const handleTag = (newValue) => {
    if (newValue.length === 0) {
      setOptionsTags(tagsExternal);
      setTags([]);
    } else {
      if (newValue[newValue.length - 1]?.inputValue) {
        let dataFilter = tagsExternal.filter(
          (element) =>
            element.name === newValue[newValue.length - 1]?.inputValue
        );
        if (dataFilter.length === 0) {
          setDisabled(true);
          addTag(newValue[newValue.length - 1]?.inputValue);
          setTags(newValue);
        } else {
          setTags(newValue);
        }
      } else {
        setTags(newValue);
      }
    }
  };

  const addTag = (tagName) => {
    petitionPost("addTags", {
      data: {
        name: tagName,
      },
    })
      .then(({ data: result }) => {
        let data = newTags;
        petitionTag();
        setDisabled(false);
        data.push(result.result);
        setNewTags(data);
      })
      .catch((error) => console.log(error));
  };

  const formatTags = () => {
    let dataTagsNew = [];
    tags.forEach((element) => {
      if (typeof element !== "string") {
        if (element.inputValue) {
          let dataFilter = newTags.filter(
            (elementTwo) => elementTwo?.name === element?.inputValue
          );
          dataTagsNew.push(dataFilter[0]?.pk);
        } else {
          dataTagsNew.push(element.pk);
        }
      }
    });

    let result = dataTagsNew.filter((item, index) => {
      return dataTagsNew.indexOf(item) === index;
    });

    return result;
  };

  const getNotes = async () => {
    await petitionGet("contactNotes", {
      parameter: `?contact_id=${leadDetails.contact.pk}`,
    })
      .then(({ data: result }) => {
        let showsMenus = {};
        let editNote = {};
        result.result.forEach((note) => {
          showsMenus[note.pk] = false;
          editNote[note.pk] = {
            pk: note.pk,
            edit: false,
            note_message: note.note_message,
          };
        });
        setNotes(result.result);
        setEditNote(editNote);
      })
      .catch((error) => console.log(error));
  };

  const noShowEditNote = () => {
    let showEditTextBox = editNote;
    Object.keys(showEditTextBox).forEach((element) => {
      showEditTextBox[element].edit = false;
    });
    setChangeEditNote(showEditTextBox);
  };

  const backNote = () => {
    setAddNote(false);
    setDeleteNote(false);
  };

  const saveNote = () => {
    setLoadingNote(true);
    let dataSend = {
      contact_id: leadDetails.contact.pk,
      note_message: noteContent,
    };
    petitionPost("addNoteContact", { data: dataSend })
      .then(({ data: result }) => {
        setLoadingNote(false);
        setNoteContent("");
        backNote();
        getNotes();
      })
      .catch((error) => console.log(error));
  };

  const removeNote = () => {
    setLoadingNote(true);
    petitionDelete("deleteNote", { note_id: deleteNote })
      .then(({ data: result }) => {
        setLoadingNote(false);
        backNote();
        getNotes();
      })
      .catch((error) => console.log(error));
  };

  const updateNote = (note_id) => {
    setLoadingNote(true);
    let dataSend = {
      contact_id: leadDetails.contact.pk,
      note_message: noteContent,
    };
    petitionPatch("editNote", {
      note_id,
      data: dataSend,
    })
      .then(({ data: result }) => {
        setLoadingNote(false);
        noShowEditNote();
        getNotes();
      })
      .catch((error) => console.log(error));
  };

  const addNewNote = () => {
    setAddNote(true);
  };

  const toggleNotes = (event, index) => {
    event.stopPropagation();
    setOpenDropdownNotes(index === openDropdownNotes ? null : index);
  };

  const showEditNote = (note_id) => {
    let showEditTextBox = editNote;
    showEditTextBox[note_id].edit = true;
    setChangeEditNote(showEditTextBox);
  };

  const handleCopy = (note) => {
    navigator.clipboard
      .writeText(note)
      .then(() => {})
      .catch((error) => {
        console.error(error);
      });
  };

  const onSaveChange = () => {
    let dataContact = {
      tags: formatTags(),
      contact_list_id: valueContactList.value,
      firstname: formContact.firstname,
      lastname: formContact.lastname,
      ...formContact,
    };

    if (asignedUser) dataContact.owner_id = asignedUser.value;

    editLead(dataContact, formLead);
  };

  const returnCheck = (state) => {
    if (state === true || state === "true" || state === "True") {
      return true;
    } else if (state === false || state === "false" || state === "False") {
      return false;
    } else {
      return false;
    }
  };

  const handleCheck = (state) => {
    if (state === true || state === "true" || state === "True") {
      return false;
    } else if (state === false || state === "false" || state === "False") {
      return true;
    } else {
      return false;
    }
  };

  const reloadData = async () => {
    await petitionGet("customFields")
      .then(({ data: result }) => {
        result.result.forEach((element) => {
          element.label = element.name;
          element.value = element.pk;
        });

        setCustomFields(result.result);
        setFieldType("");
        setNewField("");
      })
      .catch((error) => console.log(error));

    await petitionGet("contacts", { parameter: `/${leadDetails.contact.pk}` })
      .then(({ data: result }) => {
        setLeadDetails({
          ...leadDetails,
          contact: result?.result,
        });
        setLoadingAddField(false);
      })
      .catch((error) => console.log(error));
  };

  const petition = async () => {
    setLoading(true);

    let contactDetailsTemp = {};
    let defaultFunnelId = leadDetails.funnel_pipeline_stage.funnel.pk;

    await getNotes();

    await petitionGet("stages", { parameter: `?funnel_id=${defaultFunnelId}` })
      .then(({ data: result }) => {
        if (result.result.length > 0) {
          result.result.forEach((stage) => {
            stage.label = stage.name;
            stage.value = stage.pk;
          });

          let defaultValueStage = result.result.find(
            (element) => element.pk === leadDetails.funnel_pipeline_stage_id
          );

          setValueStage(defaultValueStage);
          setStages(result.result);
        }
      })
      .catch((error) => console.log(error));

    await petitionGet("users")
      .then(({ data: result }) => {
        result.result.forEach((element) => {
          element.label = `${element.firstname} ${element.lastname}`;
          element.value = element.pk;
        });
        let dataFind = result.result.find(
          (user) => user.pk === leadDetails.contact.owner_id
        );
        setAsignedUser(dataFind);
        setUsers(result.result);
      })
      .catch((error) => console.log(error));

    await petitionGet("contactList")
      .then(({ data: result }) => {
        result.result.forEach((element) => {
          element.label = `${element.list_name} (${element.contact_count})`;
          element.value = element.pk;
        });

        let defaultContactList = result.result.find(
          (element) => element.pk === leadDetails.contact.contact_list_id
        );

        setValueContactList({
          label: defaultContactList.label,
          value: defaultContactList.value,
        });

        setContactList(result.result);
      })
      .catch((error) => console.log(error));

    await petitionGet("contacts", { parameter: `/${leadDetails.contact.pk}` })
      .then(({ data: result }) => {
        result.result.tags = result.result.tags.map((tag) => ({
          ...tag,
          title: tag.tag.name,
          pk: tag.tag_id,
        }));

        contactDetailsTemp = result?.result;

        setTags(result.result.tags);

        setLeadDetails({
          ...leadDetails,
          contact: result?.result,
        });
      })
      .catch((error) => console.log(error));

    await petitionGet("customFields")
      .then(({ data: result }) => {
        let newform = formContact;

        result.result.forEach((element) => {
          element.label = element.name;
          element.value = element.pk;
          newform[element.name] = contactDetailsTemp[element.name];
        });

        
        let defaultValueFunnel = funnels.find(
          (element) =>
            element.pk === leadDetails.funnel_pipeline_stage.funnel.pk
        );

        setValueFunnel(defaultValueFunnel);
        setFormContact(newform);
        setCustomFields(result.result);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    setOptionsTags(tagsExternal);
  }, [tagsExternal]);

  useEffect(() => {
    if (changeEditNote) {
      setEditNote(changeEditNote);
      setChangeEditNote(null);
    }
  }, [changeEditNote]);

  useEffect(() => {
    petition();
  }, []);

  return loading ? (
    <Loading />
  ) : (
    <>
      <Row>
        <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}></Col>
        <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
          <label className="itp-component-tag-label">Tags</label>
          <div className="itp-component-tag-container">
            <img src={Tag} alt="Add Tag" className="itp-component-tag-img" />
            <div className="itp-component-tag-width">
              <Stack>
                <Autocomplete
                  disableClearable
                  disablePortal
                  filterSelectedOptions
                  disabled={disabled}
                  freeSolo
                  multiple
                  defaultValue={tags}
                  getOptionLabel={(option) => {
                    // Value selected with enter, right from the input
                    if (typeof option === "string") {
                      return "error";
                    }
                    // Add "xxx" option created dynamically
                    if (option.inputValue) {
                      return option.inputValue;
                    }

                    // Regular option
                    return option.title;
                  }}
                  getOptionDisabled={(option) => !!option.disabled}
                  options={optionsTags}
                  id="tags-standard"
                  isOptionEqualToValue={(option, value) =>
                    option.title === value.title
                  }
                  onChange={(event, newValue) => handleTag(newValue)}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    const { inputValue } = params;
                    // Suggest the creation of a new value
                    const isExisting = options.some(
                      (option) => inputValue === option.title
                    );
                    if (inputValue !== "" && !isExisting) {
                      filtered.push({
                        inputValue,
                        title: `Add ${inputValue}`,
                      });
                    }

                    return filtered;
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      placeholder="Add Tags"
                    />
                  )}
                  renderOption={(props, option) => (
                    <li {...props}>{option.title}</li>
                  )}
                />
              </Stack>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="itp-lead-details-row-select">
        <Col xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
          <label className="itp-lead-details-label">Contact List</label>
          <p className="itp-lead-details-p">
            Select the Contact List this Contact belongs to
          </p>
        </Col>
        <Col xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
          <Select
            onChange={(e) => {
              setValueContactList(e);
            }}
            className="basic-single"
            classNamePrefix="select"
            value={valueContactList}
            isSearchable={true}
            name="contact_list_id"
            options={contactList}
          />
        </Col>
      </Row>
      <Row className="itp-container-label-input">
        <Col
          xs={5}
          sm={5}
          md={5}
          lg={5}
          xl={5}
          xxl={5}
          className="itp-label-input-col"
        >
          <label className="itp-label-input__label">First Name</label>
        </Col>
        <Col
          xs={7}
          sm={7}
          md={7}
          lg={7}
          xl={7}
          xxl={7}
          className="itp-label-input-col"
        >
          <input
            type="text"
            placeholder=": Enter contact First Name"
            className="form-control-itp itp-label-input__input"
            name="firstname"
            value={formContact.firstname}
            onChange={onchangeFormContact}
          />
        </Col>
      </Row>
      <Row className="itp-container-label-input">
        <Col
          xs={5}
          sm={5}
          md={5}
          lg={5}
          xl={5}
          xxl={5}
          className="itp-label-input-col"
        >
          <label className="itp-label-input__label">Last Name</label>
        </Col>
        <Col
          xs={7}
          sm={7}
          md={7}
          lg={7}
          xl={7}
          xxl={7}
          className="itp-label-input-col"
        >
          <input
            type="text"
            placeholder=": Enter contact Last Name"
            className="form-control-itp itp-label-input__input"
            name="lastname"
            value={formContact.lastname}
            onChange={onchangeFormContact}
          />
        </Col>
      </Row>
      <Row className="itp-container-label-input">
        <Col
          xs={5}
          sm={5}
          md={5}
          lg={5}
          xl={5}
          xxl={5}
          className="itp-label-input-col"
        >
          <label className="itp-label-input__label">E-mail</label>
        </Col>
        <Col
          xs={7}
          sm={7}
          md={7}
          lg={7}
          xl={7}
          xxl={7}
          className="itp-label-input-col"
        >
          <input
            type="text"
            placeholder=": Enter email address"
            className="form-control-itp itp-label-input__input"
            name="email"
            value={formContact.email}
            onChange={onchangeFormContact}
          />
        </Col>
      </Row>
      <Row className="itp-container-label-select margin">
        <Col
          xs={5}
          sm={5}
          md={5}
          lg={5}
          xl={5}
          xxl={5}
          className="itp-label-input-col"
        >
          <label className="itp-label-input__label">Phone Number</label>
        </Col>
        <Col
          xs={7}
          sm={7}
          md={7}
          lg={7}
          xl={7}
          xxl={7}
          className="itp-label-input-col"
        >
          <PhoneInput
            country={"us"}
            value={formContact.phone}
            disableCountryFlags={true}
            enableSearch={true}
            countryCodeEditable={false}
            onChange={(e) => {
              setFormContact({ ...formContact, phone: `+${e}` });
            }}
          />
        </Col>
      </Row>
      <Row className="itp-container-label-input">
        <Col
          xs={5}
          sm={5}
          md={5}
          lg={5}
          xl={5}
          xxl={5}
          className="itp-label-input-col"
        >
          <label className="itp-label-input__label">Address</label>
        </Col>
        <Col
          xs={7}
          sm={7}
          md={7}
          lg={7}
          xl={7}
          xxl={7}
          className="itp-label-input-col"
        >
          <input
            type="text"
            placeholder=": Enter address"
            className="form-control-itp itp-label-input__input"
            name="address"
            value={formContact.address}
            onChange={onchangeFormContact}
          />
        </Col>
      </Row>
      <Row className="itp-lead-details-row-select margin">
        <Col xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
          <label className="itp-lead-details-label">Assigned User</label>
          <p className="itp-lead-details-p">
            Select the assigned user for this contact
          </p>
        </Col>
        <Col xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
          <Select
            onChange={(e) => setAsignedUser(e)}
            className="basic-single"
            classNamePrefix="select"
            value={asignedUser}
            isSearchable={true}
            name="owner_id"
            placeholder="Select a User"
            options={users}
          />
        </Col>
      </Row>

      {customFields.map((element) => (
        <Row
          key={element.name}
          className={`itp-container-label-${
            element.field_type !== "list" && element.field_type !== "checkbox"
              ? "input"
              : "select"
          }`}
        >
          <Col
            xs={5}
            sm={5}
            md={5}
            lg={5}
            xl={5}
            xxl={5}
            className="itp-label-input-col"
          >
            <label className="itp-label-input__label">{element.name}</label>
          </Col>
          <Col
            xs={7}
            sm={7}
            md={7}
            lg={7}
            xl={7}
            xxl={7}
            className="itp-label-input-col"
          >
            {element.field_type !== "list" &&
              element.field_type !== "date" &&
              element.field_type !== "checkbox" && (
                <input
                  type="text"
                  placeholder=": Enter address"
                  className="form-control-itp itp-label-input__input"
                  name={element.name}
                  value={formContact[element.name] || ""}
                  onChange={onchangeFormContact}
                />
              )}

            {element.field_type === "list" && (
              <select
                className="form-select-itp"
                onChange={onchangeFormContact}
                name={element.name}
                value={formContact[element.name] || ""}
              >
                <option value="" disabled>
                  Select Option
                </option>
                {element.field_metadata.list_values.map((val, i) => (
                  <option key={i} value={val}>
                    {val}
                  </option>
                ))}
              </select>
            )}

            {element.field_type === "checkbox" && (
              <input
                checked={returnCheck(formContact[element.name]) || false}
                name={element.name}
                onChange={(e) => {
                  setFormContact({
                    ...formContact,
                    [e.target.name]: handleCheck(
                      formContact[element.name] || false
                    ),
                  });
                }}
                type="checkbox"
                className="form-check-input itp-custom-checkbox-lead-details"
              />
            )}

            {element.field_type === "date" && (
              <input
                type="date"
                placeholder=": Enter address"
                className="form-control-itp itp-label-input__input"
                name={element.name}
                value={formContact[element.name] || ""}
                onChange={onchangeFormContact}
              />
            )}
          </Col>
        </Row>
      ))}

      {fieldType === "" && (
        <Dropdown
          isOpen={openDropdown}
          toggle={toggle}
          className="itp-lead-details-add-field"
        >
          <DropdownToggle className="btn-light">
            {<FontAwesomeIcon icon={faPlus} className="itp-icon-plus" />} Add
            Field
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem
              onClick={() => setFieldType("address")}
              className="dropdown-item"
            >
              {<FontAwesomeIcon icon={faMapMarkerAlt} />} Address
            </DropdownItem>
            <DropdownItem
              onClick={() => setFieldType("url")}
              className="dropdown-item"
            >
              {<FontAwesomeIcon icon={faPaperclip} />} URL
            </DropdownItem>
            <DropdownItem
              onClick={() => setFieldType("date")}
              className="dropdown-item"
            >
              {<FontAwesomeIcon icon={faCalendarAlt} />} Date
            </DropdownItem>
            <DropdownItem
              onClick={() => setFieldType("text/note")}
              className="dropdown-item"
            >
              {<FontAwesomeIcon icon={faFileText} />} Text / Note
            </DropdownItem>
            <DropdownItem
              onClick={() => setFieldType("checkbox")}
              className="dropdown-item"
            >
              {<FontAwesomeIcon icon={faCheckSquare} />} Checkbox
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      )}

      {fieldType !== "" && (
        <div className="itp-lead-details-new-field">
          <input
            className="itp-lead-details-new-field__input"
            defaultValue={defaultValueAddField(fieldType)}
            onChange={(e) => setNewField(e.target.value)}
          />
          {loadingAddField ? (
            <LoadingButton />
          ) : (
            <button
              onClick={addCustomField}
              className="itp-lead-details-new-field__button save"
            >
              Save
            </button>
          )}
          <button
            className="itp-lead-details-new-field__button"
            onClick={() => setFieldType("")}
            disabled={loadingAddField}
          >
            Delete
          </button>
        </div>
      )}

      {/* <div className="itp-lead-details-separator"></div> */}

      <Row className="itp-container-label-input">
        <Col
          xs={5}
          sm={5}
          md={5}
          lg={5}
          xl={5}
          xxl={5}
          className="itp-label-input-col"
        >
          <label className="itp-label-input__label">Lead Name</label>
        </Col>
        <Col
          xs={7}
          sm={7}
          md={7}
          lg={7}
          xl={7}
          xxl={7}
          className="itp-label-input-col"
        >
          <input
            type="text"
            placeholder=": Enter a lead name"
            className="form-control-itp itp-label-input__input"
            name="name"
            value={formLead.name === "Unassigned" ? "" : formLead.name}
            onChange={onchangeFormLead}
          />
        </Col>
      </Row>
      <Row className="itp-container-label-input">
        <Col
          xs={5}
          sm={5}
          md={5}
          lg={5}
          xl={5}
          xxl={5}
          className="itp-label-input-col"
        >
          <label className="itp-label-input__label">Amount</label>
        </Col>
        <Col
          xs={7}
          sm={7}
          md={7}
          lg={7}
          xl={7}
          xxl={7}
          className="itp-label-input-col"
        >
          <input
            type="text"
            placeholder=": Enter a amount"
            className="form-control-itp itp-label-input__input"
            name="amount"
            value={formLead.amount}
            onChange={onchangeFormLead}
          />
        </Col>
      </Row>
      <Row className="itp-lead-details-row-select margin">
        <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
          <label className="itp-lead-details-label">Funnel</label>
        </Col>
        <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
          <Select
            onChange={(e) => onChangeFunnel(e)}
            className="basic-single"
            classNamePrefix="select"
            value={valueFunnel}
            isSearchable={true}
            name="funnel_id"
            placeholder="Select a Funnel"
            options={funnels}
          />
        </Col>
      </Row>
      {loadingChangeFunnel ? (
        <Loading />
      ) : (
        <>
          {formLead.funnel_id && (
            <>
              {stages.length > 0 ? (
                <Row className="itp-lead-details-row-select margin">
                  <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                    <label className="itp-lead-details-label">Stage</label>
                  </Col>
                  <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                    <Select
                      onChange={(e) => {
                        setFormLead({
                          ...formLead,
                          funnel_pipeline_stage_id: e.value,
                        });
                        setValueStage(e);
                      }}
                      className="basic-single"
                      classNamePrefix="select"
                      value={valueStage}
                      isSearchable={true}
                      name="funnel_pipeline_stage_id"
                      placeholder="Select a Stage"
                      options={stages}
                    />
                  </Col>
                </Row>
              ) : (
                <p>There are no stages created for this funnel</p>
              )}
            </>
          )}
        </>
      )}

      {/* <div className="itp-lead-details-separator"></div> */}

      {addNote && !deleteNote && (
        <div className="itp-lead-detail-add-note">
          <textarea
            spellCheck={false}
            onChange={(e) => setNoteContent(e.target.value)}
          />
          <div className="itp-lead-details-add-note-buttons">
            <button
              onClick={backNote}
              disabled={loadingNote}
              className="btn-light"
            >
              Back
            </button>
            <button
              className="btn-primary itp-lead-details-save-notes-button loading"
              disabled={noteContent === "" || loadingNote}
              onClick={saveNote}
            >
              {loadingNote && <LoadingButton />}
              Save
            </button>
          </div>
        </div>
      )}

      {!addNote && deleteNote && (
        <div className="d-flex flex-column justify-content-center align-items-center">
          <p>Do you want to Delete this note?</p>
          <div className="itp-lead-details-add-note-buttons">
            <button
              onClick={backNote}
              disabled={loadingNote}
              className="btn-light"
            >
              Back
            </button>
            <button
              className="btn-primary itp-lead-details-save-notes-button loading"
              disabled={loadingNote}
              onClick={removeNote}
            >
              {loadingNote && <LoadingButton />}
              Delete
            </button>
          </div>
        </div>
      )}

      {!addNote && !deleteNote && (
        <>
          <label className="itp-lead-details-notes">Notes</label>
          <div className="itp-lead-details-note-title">
            <label className="itp-lead-details-notes-label">
              Add or view the notes related to this specific contact
            </label>
            <button
              onClick={addNewNote}
              className="btn-primary itp-lead-details-notes-button"
            >
              <FontAwesomeIcon icon={faPlus} className="itp-icon-plus" /> Add
              Note
            </button>
          </div>
          {notes.map((note, i) => (
            <div key={note.pk} className="itp-lead-details-note-content">
              <div className="itp-lead-details-note-content__tile">
                <div className="title-note-left-section">
                  <img
                    src={userImage}
                    alt="Profile"
                    className="itp-lead-detail-note-img"
                  />
                  <label className="itp-lead-details-note-text">
                    {myExtension.firstname} {myExtension.lastname}
                  </label>
                  <span className="itp-lead-details-note-text">
                    {formatDate(note.date_added)}
                  </span>
                </div>
                {!editNote[note.pk].edit && (
                  <Dropdown
                    isOpen={i === openDropdownNotes}
                    toggle={(event) => toggleNotes(event, i)}
                    className="title-note-right-section"
                  >
                    <DropdownToggle className="nav-link">
                      <ToggleImage />
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem
                        onClick={() => console.log(note)}
                        className="dropdown-item"
                      >
                        {<FontAwesomeIcon icon={faThumbtack} />} Pin Note
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => setDeleteNote(note.pk)}
                        className="dropdown-item"
                      >
                        {<FontAwesomeIcon icon={faTrash} />} Delete Note
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => handleCopy(note.note_message)}
                        className="dropdown-item"
                      >
                        {<FontAwesomeIcon icon={faCopy} />} Copy Note
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => showEditNote(note.pk)}
                        className="dropdown-item"
                      >
                        {<FontAwesomeIcon icon={faPenToSquare} />} Edit Note
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                )}
              </div>
              {editNote[note.pk].edit && (
                <>
                  <textarea
                    className="itp-lead-details-edit-note"
                    defaultValue={editNote[note.pk].note_message}
                    spellCheck={false}
                    onChange={(e) => setNoteContent(e.target.value)}
                  />
                  <div className="itp-lead-details-add-note-buttons">
                    <button
                      onClick={noShowEditNote}
                      disabled={loadingNote}
                      className="btn-light"
                    >
                      Back
                    </button>
                    <button
                      className="btn-primary itp-lead-details-save-notes-button loading"
                      disabled={
                        noteContent === "" ||
                        noteContent === editNote[note.pk].note_message
                      }
                      onClick={() => updateNote(note.pk)}
                    >
                      {loadingNote && <LoadingButton />}
                      Edit
                    </button>
                  </div>
                </>
              )}

              {!editNote[note.pk].edit && (
                <div className="itp-lead-details-note-content__content">
                  {
                    <FontAwesomeIcon
                      icon={faFileText}
                      className="icon-text-note"
                    />
                  }
                  <p className="itp-lead-details-p">{note.note_message}</p>
                </div>
              )}
            </div>
          ))}
        </>
      )}

      <button
        onClick={onSaveChange}
        className="btn-primary itp-lead-detail-button-save loading"
        disabled={
          loading || addNote || deleteNote || !formLead.funnel_pipeline_stage_id
        }
      >
        {loadingEdit && <LoadingButton />}
        Save Changes
      </button>
    </>
  );
};

export default LeadDetails;
