import React, { useEffect, useRef, useState } from "react";
import { Card, Row, Col } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import {
  CustomTable,
  LoadingButton,
  MenuRight,
  AudioMessage,
  Loading,
} from "../../../../../components";
import Switch from "react-switch";
import Select from "react-select";
import axios from "axios";
import json from "../../../../../config.json";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import PauseIcon from "@mui/icons-material/Pause";
import useScreenSize from "../../../../../hooks/useScreenSize";
import petitionGet from "../../../../../services/petitionGet";
import petitionPost from "../../../../../services/petitionPost";
import petitionPatch from "../../../../../services/petitionPatch";
import petitionDelete from "../../../../../services/petitionDelete";
import petitionPut from "../../../../../services/petitionPut";
import ModalDelete from "../../ModalUtility/ModalDelete";
import ModalConfirm from "../../ModalUtility/ModalConfirm";
import "./EditNumber.css";

const EditNumber = ({ info, setInfo, activeModalDelete }) => {
  const { width } = useScreenSize();

  const inputRef = useRef();

  const [advanceCallFlows, setAdvanceCallFlows] = useState(false);
  const [firstRender, setFirstRender] = useState(true);
  const [dataTableUSers, setDataTableUsers] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [usersSelected, setUsersSelected] = useState([]);
  const [loadingNumber, setLoadingNumber] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [modalConfirm, setModalConfirm] = useState(false);
  const [voicemailURL, setVoicemailURL] = useState("");
  const [loading, setLoading] = useState(true);
  const [invalidStrategy, setInvalidStrategy] = useState(null);
  const [loadingConfirm, setLoadingConfirm] = useState(false);
  const [showLoadVoicemail, setShowLoadVoicemail] = useState(false);
  const [loadingVoicemail, setLoadingVoicemail] = useState(false);
  const [selectedOptionVoicemail, setSelectedOptionVoicemail] = useState("");
  const [recording, setRecording] = useState(false);
  const [audioBlob, setAudioBlob] = useState(null);
  const [audioUrl, setAudioUrl] = useState("");
  const [mediaRecorder, setMediaRecorder] = useState([]);
  const [reload, setReload] = useState(1);
  const [audioDuration, setAudioDuration] = useState(0);
  const [allUsers, setAllUsers] = useState([]);
  const [form, setForm] = useState({
    description: info?.description || "",
    caller_id_name: info?.caller_id_name || "",
    timeout:
      info.timeout !== null && info.timeout !== undefined ? info.timeout : "",
    ai_enabled: info?.ai_enabled || false,
    support_center: info?.support_center || false,
    ai_lead_manager: info?.ai_lead_manager || false,
    manage_appointments: info?.manage_appointments || false,
    manage_products: info?.manage_products || false,
    forward_all_calls: info?.forward_all_calls || false,
    ring_strategy: info?.ring_strategy || "",
    callforward_enable: info?.callforward_enable || false,
    callforward_number: info?.callforward_number || "",
    callforward_keep_caller_caller_id:
      info?.callforward_keep_caller_caller_id || false,
    callforward_call_confirmation: info?.callforward_call_confirmation || false,
  });

  let audioChunks = [];

  const onChangeOptionLoadVoicemail = (e) => {
    inputRef.current = null;
    setRecording(null);
    setAudioBlob(null);
    setMediaRecorder([]);
    setAudioUrl("");
    setReload(1);
    audioChunks = [];
    setSelectedOptionVoicemail(e.target.value);
  };

  const startRecording = () => {
    let mediaRecorderTemp;

    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        mediaRecorderTemp = new MediaRecorder(stream);

        mediaRecorderTemp.ondataavailable = (e) => {
          if (e.data.size > 0) {
            audioChunks.push(e.data);
          }
        };

        mediaRecorderTemp.onstop = () => {
          const audioBlob = new Blob(audioChunks, { type: "audio/wav" });
          setAudioBlob(audioBlob);
          const audioUrl = URL.createObjectURL(audioBlob);
          setAudioUrl(audioUrl);
        };

        mediaRecorderTemp.start();
        setRecording(true);
        setMediaRecorder(mediaRecorderTemp);
      })
      .catch((error) => {
        console.error("Error accessing microphone:", error);
      });
  };

  const stopRecording = () => {
    if (mediaRecorder && recording) {
      mediaRecorder.stop();
      setRecording(false);
    }
  };

  const activeLoadVoicemail = () => {
    setOpenMenu(true);
    setShowLoadVoicemail(true);
  };

  const handleChangeswitch = (name) => {
    setForm({ ...form, [name]: !form[name] });

    let data = {};

    if (
      name === "forward_all_calls" ||
      name === "callforward_enable" ||
      name === "callforward_keep_caller_caller_id" ||
      name === "callforward_call_confirmation"
    ) {

      if (name === "forward_all_calls" && form.forward_all_calls) {
        data = {
          forward_all_calls: false,
          ring_strategy: form.ring_strategy,
          timeout: form.timeout,
          callforward_enable: false,
          callforward_number: "",
          callforward_keep_caller_caller_id: false,
          callforward_call_confirmation: false,
        };
        setForm({ ...form, ...data })
      } else {
        data = {
          forward_all_calls: !form.forward_all_calls,
          ring_strategy: form.ring_strategy,
          timeout: form.timeout,
          callforward_enable: form.callforward_enable,
          callforward_number: form.callforward_number,
          callforward_keep_caller_caller_id:
            form.callforward_keep_caller_caller_id,
          callforward_call_confirmation: form.callforward_call_confirmation,
          [name]: !form[name],
        };
      }

    } else if (name === "ai_enabled" && form.ai_enabled) {
      data = {
        ai_enabled: false,
        support_center: false,
        ai_lead_manager: false,
        manage_appointments: false,
        manage_products: false,
      };

      setForm({
        ...form,
        ai_enabled: false,
        support_center: false,
        ai_lead_manager: false,
        manage_appointments: false,
        manage_products: false,
      });
    } else {
      data = {
        ...form,
        [name]: !form[name],
      };

      delete data.ring_strategy;
      delete data.forward_all_calls;
      delete data.caller_id_name;
      delete data.description;
    }

    petitionPatch("numberEdit", { number: info.number, data })
      .then(({ data: result }) => {})
      .catch((error) => {
        console.log(error);
        if (
          error.response.status === 400 &&
          error.response.data.error.error_detailed.error.error_message ===
            "A Required Field is missing or is an invalid data type."
        ) {
          setInvalidStrategy(
            "You must choose a valid data for the Ring strategy."
          );
          setModalConfirm(true);
          setForm({ ...form, [name]: form[name] });
        }
      });
  };

  const activeConfirmModal = () => {
    setModalConfirm(true);
  };

  const activeModalDeleteAssignedUser = (e) => {
    setUsersSelected((prevState) =>
      prevState.filter((element) => element.pk !== e.pk)
    );
    setModalDelete(true);
  };

  const toggleCallFlows = () => {
    if (advanceCallFlows) {
      setLoadingConfirm(true);
      petitionPost("basicModeCallFlowNumber", {
        data: null,
        number: info.number,
      })
        .then(({ data: result }) => {
          setLoadingConfirm(false);
          closeMenu();
          setAdvanceCallFlows(!advanceCallFlows);
        })
        .catch((error) => console.log(error));
    } else {
      setLoadingConfirm(true);
      petitionDelete("basicModeCallFlowNumber", { number: info.number })
        .then(({ data: result }) => {
          setLoadingConfirm(false);
          closeMenu();
          setAdvanceCallFlows(!advanceCallFlows);
          petition();
        })
        .catch((error) => console.log(error));
    }
  };

  const handleChangeForm = (e) => {
    if (e.target.name === "timeout") {
      setForm({ ...form, timeout: parseInt(e.target.value) });
    } else {
      setForm({ ...form, [e.target.name]: e.target.value });
    }

    if (e.target.name === "ring_strategy") {
      const data = {
        forward_all_calls: form.forward_all_calls,
        timeout: form.timeout === null ? "" : form.timeout,
        ring_strategy: e.target.value,
      };

      petitionPatch("numberEdit", { number: info.number, data })
        .then(({ data: result }) => {})
        .catch((error) => {
          console.log(error);
          if (
            error.response.status === 400 &&
            error.response.data.error.error_detailed.error.error_message ===
              "A Required Field is missing or is an invalid data type."
          ) {
            setInvalidStrategy(
              "You must choose a valid data for the Ring strategy."
            );
            setModalConfirm(true);
            setForm({ ...form, [e.target.name]: "" });
          }
        });
    }
  };

  const closeMenu = () => {
    setShowLoadVoicemail(false);
    setInvalidStrategy(null);
    setModalConfirm(false);
    setModalDelete(false);
    setOpenMenu(false);
  };

  const activeAddUsers = () => {
    setOpenMenu(true);
  };

  const saveNumbers = () => {
    setLoadingNumber(true);

    const data = {
      sms_users: usersSelected.map((user) => user.pk),
    };

    petitionPatch("numberEdit", { number: info.number, data })
      .then(({ data: result }) => {
        let newData = info;
        newData.sms_assigned_users = usersSelected;

        setInfo(newData);
        organizeDataUsers(newData, allUsers);
        setLoadingNumber(false);
        closeMenu();
      })
      .catch((error) => console.log(error));
  };

  const organizeDataUsers = (dataNumber, users) => {
    let assignedUsers = [];

    users.forEach((user) => {
      let dataFind = dataNumber.sms_assigned_users.find(
        (element) => element.pk === user.pk
      );

      if (dataFind) {
        assignedUsers.push(user);
      }
    });

    setUsersSelected(assignedUsers);
    setDataTableUsers({
      title: [
        { title: "Name", key: "name" },
        { title: "Email", key: "email" },
        { title: "Extension", key: "presence_id" },
        { title: "Permissions", key: "priv_level" },
      ],
      content: [...assignedUsers],
      actions: {
        title: "Actions",
        content: [
          {
            name: "Delete",
            icon: <FontAwesomeIcon icon={faTrash} />,
            handleClick: activeModalDeleteAssignedUser,
          },
        ],
      },
      addButton: {
        label: "Add User",
        handleClick: activeAddUsers,
      },
      titleTable: "Users",
      label: "Assigned user are able to receive calls, SMS & Faxes",
    });
  };

  const saveLoadVoicemail = () => {
    setLoadingVoicemail(true);

    const formData = new FormData();

    if (selectedOptionVoicemail === "upload") {
      formData.append("file", inputRef.current.files[0]);
    } else if (selectedOptionVoicemail === "record") {
      const audioFile = new File([audioBlob], "audiofile.mp3", {
        type: "audio/mp3",
      });
      formData.append("file", audioFile);
    }

    petitionPut("loadVoicemailNumber", { data: formData, number: info.number })
      .then(({ data: result }) => {
        setLoadingVoicemail(false);
        petition();
        closeMenu();
        inputRef.current = null;
        setRecording(null);
        setAudioBlob(null);
        setMediaRecorder([]);
        setAudioUrl("");
        setReload(1);
        audioChunks = [];
      })
      .catch((error) => console.log(error));
  };

  const petition = async () => {
    setLoading(true);

    let allUsersTemp = []

    const urlBase = json.prod ? json.urlBase.prod : json.urlBase.dev;
    const id = JSON.parse(localStorage.getItem("userDetails"))?.api_id;
    const token = JSON.parse(localStorage.getItem("userDetails"))?.access_token;
    const url =
      urlBase + `/itpvoice/v2/${id}/numbers/${info.number}/voice-messages`;

    const config = {
      headers: {
        Authorization: "Bearer " + token.replace(/['"]+/g, ""),
      },
      responseType: "arraybuffer", // Indica que esperamos datos binarios
    };

    await petitionGet("users")
    .then(({ data: result }) => {
      result.result.forEach((user) => {
        user.name = `${user.firstname} ${user.lastname || ""}`;
        user.label = `${user.firstname} ${user.lastname || ""}`;
        user.value = user.pk;
      });

      allUsersTemp = result.result
      setAllUsers(result.result);
    })
    .catch((error) => console.log(error));

    await petitionGet("viewNumberDetails", { phoneNumber: info.number })
      .then(({ data: result }) => {
        if (!result.result.number_callflow) {
          setAdvanceCallFlows(true);
        }

        if (result.result.number_callflow && !result.result.number_callflow.internal_use) {
          setAdvanceCallFlows(true);
        }

        if (result.result.number_callflow && result.result.number_callflow.internal_use) {
          setAdvanceCallFlows(false);
        }

        const newInfo = { ...info, ...result.result }

        organizeDataUsers(newInfo, allUsersTemp);
        setInfo(newInfo)
      })
      .catch((error) => console.log(error))

    await axios
      .get(url, config)
      .then(({ data: result }) => {
        if (result) {
          const data = result;
          const blob = new Blob([data], { type: "audio/wav" });
          const audioURL = URL.createObjectURL(blob);
          setVoicemailURL(audioURL);

          const audioElement = new Audio(audioURL);
          let duration;
          audioElement.addEventListener("loadedmetadata", () => {
            // Obtén la duración y actualiza el estado
            setAudioDuration(audioElement.duration);
          });
        }

        setFirstRender(false);
        setLoading(false);
      })
      .catch((error) => {
        if (
          error?.response?.status === 400 ||
          error?.response?.status === 500
        ) {
          setVoicemailURL("");
          setFirstRender(false);
          setLoading(false);
        }
      });
  };

  useEffect(() => {
    if (!firstRender) {
      const timer = setTimeout(() => {
        let data = {
          forward_all_calls: form.forward_all_calls,
          ring_strategy: form.ring_strategy,
          timeout: parseInt(form.timeout),
        };

        petitionPatch("numberEdit", { number: info.number, data })
          .then(({ data: result }) => {})
          .catch((error) => console.log(error));
      }, 1000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [form.callforward_number]);

  useEffect(() => {
    if (!firstRender) {
      const timer = setTimeout(() => {
        let data = {
          forward_all_calls: form.forward_all_calls,
          ring_strategy: form.ring_strategy,
          timeout: parseInt(form.timeout),
          callforward_enable: form.callforward_enable,
          callforward_number: form.callforward_number,
          callforward_keep_caller_caller_id: form.callforward_keep_caller_caller_id,
          callforward_call_confirmation: form.callforward_call_confirmation,
        };

        petitionPatch("numberEdit", { number: info.number, data })
          .then(({ data: result }) => {})
          .catch((error) => console.log(error));
      }, 1000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [form.timeout, form.callforward_number]);

  useEffect(() => {
    if (!firstRender) {
      const timer = setTimeout(() => {
        let data = {
          description: form.description,
          caller_id_name: form.caller_id_name,
        };

        petitionPatch("numberEdit", { number: info.number, data })
          .then(({ data: result }) => {})
          .catch((error) => console.log(error));
      }, 1000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [form.description, form.caller_id_name]);

  useEffect(() => {
    setForm({ ...form, timeout: info?.timeout || "" });
    petition();
  }, []);

  return loading ? (
    <Loading />
  ) : (
    <>
      {modalDelete && (
        <ModalDelete
          modal={modalDelete}
          closeModal={closeMenu}
          loading={loadingNumber}
          modalFunction={saveNumbers}
          module="Assigned User"
        />
      )}

      {modalConfirm && (
        <ModalConfirm
          modal={modalConfirm}
          closeModal={closeMenu}
          loading={loadingConfirm}
          modalFunction={toggleCallFlows}
          invalidStrategy={invalidStrategy}
        />
      )}

      <p
        className={`itp-custom-table-title itp-edit-number-title${
          width > 991 ? " centered" : ""
        }`}
      >
        Phone Number Details : Manage settings for this number
      </p>
      <h6
        className={`itp-custom-table-title itp-edit-number-subtitle${
          width > 991 ? " centered" : ""
        }`}
      >
        {`Number : ${info.number}`}
      </h6>

      <Card
        className={
          width > 991
            ? "itp-table-container-centered mt-3 itp-edit-number-card"
            : "itp-table-container-all mt-3"
        }
      >
        <div className="d-flex justify-content-between">
          <div className="d-flex flex-column">
            <label className="itp-edit-number-label-1">Description</label>
            <label className="itp-edit-number-label-2">
              Add a description to this number
            </label>
          </div>
          <input
            placeholder="Add Name"
            className="form-control-itp itp-edit-number-input"
            name="description"
            value={form.description}
            onChange={handleChangeForm}
          />
        </div>
        <div className="d-flex justify-content-between mt-4">
          <div className="d-flex flex-column">
            <label className="itp-edit-number-label-1">Caller ID (CNAM)</label>
            <label className="itp-edit-number-label-2">
              Change the Name that appears while dialing other numbers
            </label>
          </div>
          <input
            placeholder="Add Name"
            className="form-control-itp itp-edit-number-input"
            name="caller_id_name"
            value={form.caller_id_name}
            onChange={handleChangeForm}
          />
        </div>
      </Card>

      <br></br>
      <div>
        <Row>
          <Col>
            <div className="itp-user-settings-separator-line"></div>
          </Col>
        </Row>
      </div>
      <br></br>

      <div className="itp-edit-number-container-table">
        {dataTableUSers && (
          <CustomTable
            data={dataTableUSers}
            noPagBack={true}
            centered={width > 991 ? true : false}
            textNoData="users assigned"
          />
        )}
      </div>

      <br></br>
      <div>
        <Row>
          <Col>
            <div className="itp-user-settings-separator-line"></div>
          </Col>
        </Row>
      </div>
      <br></br>

      {advanceCallFlows ? (
        <Card
          className={
            width > 991
              ? "itp-table-container-centered mt-3 mb-4 itp-edit-number-card"
              : "itp-table-container-all mt-3 mb-4"
          }
        >
          <div className="d-flex justify-content-between">
            <div className="d-flex flex-column">
              <label className="itp-edit-number-label-1">Call flow</label>
              <label className="itp-edit-number-label-2">
                Manage the way this number behaves when calling it
              </label>
            </div>
            <button
              onClick={activeConfirmModal}
              className="btn-primary itp-edit-number-button"
              style={{ width: "14rem" }}
            >
              Deactivate Advanced Callflow
            </button>
          </div>
        </Card>
      ) : (
        <>
          <Card
            className={
              width > 991
                ? "itp-table-container-centered mt-3 mb-4 itp-edit-number-card"
                : "itp-table-container-all mt-3 mb-4"
            }
          >
            <div className="d-flex justify-content-between">
              <div className="d-flex flex-column">
                <label className="itp-edit-number-label-1">Call flow</label>
                <label className="itp-edit-number-label-2">
                  Manage the way this number behaves when calling it
                </label>
              </div>
              <button
                onClick={activeConfirmModal}
                className="btn-primary itp-edit-number-button-2"
                style={{ width: "14rem" }}
              >
                Activate Advanced Callflow
              </button>
            </div>
            {/* <Row className="mt-4">
            <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
              <div className="d-flex flex-column">
                <label className="itp-edit-number-label-1">
                  Business Hours
                </label>
                <label className="itp-edit-number-label-2">
                  Manage the way this number behaves during different times
                </label>
              </div>
            </Col>
            <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
              <Switch
                className="itp-custom-table-switch mt-3"
                onColor="#626ed4"
                height={20} // Ajusta la altura según tus necesidades
                width={40}
                checked={true}
                // onChange={handleChangeswitch}
              />
            </Col>
          </Row> */}
            {/* <Row className="mt-4">
            <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
              <div className="d-flex flex-column">
                <label className="itp-edit-number-label-1">Phone Menu</label>
                <label className="itp-edit-number-label-2">
                  Manage the way this number behaves during different times
                </label>
              </div>
            </Col>
            <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
              <Switch
                className="itp-custom-table-switch mt-3"
                onColor="#626ed4"
                height={20} // Ajusta la altura según tus necesidades
                width={40}
                checked={true}
                // onChange={handleChangeswitch}
              />
            </Col>
          </Row> */}
            <Row className="mt-4">
              <Col xs={9} sm={9} md={9} lg={9} xl={9} xxl={9}>
                <div className="d-flex flex-column">
                  <label className="itp-edit-number-label-1">
                    Ring Strategy
                  </label>
                  <label className="itp-edit-number-label-2">
                    Manage how users assigned are dialed
                  </label>
                </div>
              </Col>
              <Col xs={3} sm={3} md={3} lg={3} xl={3} xxl={3}>
                <select
                  onChange={handleChangeForm}
                  value={form.ring_strategy}
                  name="ring_strategy"
                  className="form-select-itp itp-edit-number-select ms-4"
                >
                  <option value="" disabled>
                    Select Option
                  </option>
                  <option value="ringall">Ring all</option>
                  <option value="random">Random</option>
                  <option value="linear">Linear</option>
                </select>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col xs={9} sm={9} md={9} lg={9} xl={9} xxl={9}>
                <div className="d-flex flex-column">
                  <label className="itp-edit-number-label-1">Timeout</label>
                  <label className="itp-edit-number-label-2">
                    Number of seconds before going to voicemail
                  </label>
                </div>
              </Col>
              <Col xs={3} sm={3} md={3} lg={3} xl={3} xxl={3}>
                <input
                  placeholder="Add Timeout"
                  type="number"
                  className="form-control-itp itp-edit-number-input ms-2"
                  name="timeout"
                  value={form.timeout.toString()}
                  onChange={handleChangeForm}
                />
              </Col>
            </Row>
          </Card>

          <Card
            className={
              width > 991
                ? "itp-table-container-centered mt-3 mb-4 itp-edit-number-card"
                : "itp-table-container-all mt-3 mb-4"
            }
          >
            <Row className="mt-4">
              <Col xs={11} sm={11} md={11} lg={11} xl={11} xxl={11}>
                <label className="itp-edit-number-label-1">
                  Forward all calls
                </label>
              </Col>
              <Col xs={1} sm={1} md={1} lg={1} xl={1} xxl={1}>
                <Switch
                  className="itp-custom-table-switch"
                  onColor="#626ed4"
                  height={20} // Ajusta la altura según tus necesidades
                  width={40}
                  checked={form.forward_all_calls}
                  onChange={() => handleChangeswitch("forward_all_calls")}
                />
              </Col>
            </Row>
            {form.forward_all_calls && (
              <>
                <Row className="mt-4">
                  <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                    <div className="d-flex flex-column">
                      <label className="itp-edit-number-label-1">
                        Enable Call Forwarding
                      </label>
                      <label className="itp-edit-number-label-2">
                        Enabling this option will forward any incoming calls to
                        your user and will not ring any of your devices,
                        including the web phone
                      </label>
                    </div>
                  </Col>
                  <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                    <Switch
                      className="itp-custom-table-switch"
                      onColor="#626ed4"
                      height={20} // Ajusta la altura según tus necesidades
                      width={40}
                      checked={form.callforward_enable}
                      onChange={() => handleChangeswitch("callforward_enable")}
                    />
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                    <div className="d-flex flex-column">
                      <label className="itp-edit-number-label-1">
                        Forward the call to
                      </label>
                      <label className="itp-edit-number-label-2">
                        You can enter an extension number or a phone numbr to
                        forward calls to
                      </label>
                    </div>
                  </Col>
                  <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                    <input
                      type="text"
                      placeholder=""
                      className="form-control-itp itp-edit-number-input"
                      name="callforward_number"
                      onChange={handleChangeForm}
                      value={form.callforward_number}
                    />
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                    <div className="d-flex flex-column">
                      <label className="itp-edit-number-label-1">
                        Keep Original Caller ID
                      </label>
                      <label className="itp-edit-number-label-2">
                        If enabled, this will show the caller's phone number on
                        your screen. If disabled, the number that appears will
                        either be the default caller ID or your customized
                        outbound caller ID number.
                      </label>
                    </div>
                  </Col>
                  <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                    <Switch
                      className="itp-custom-table-switch"
                      onColor="#626ed4"
                      height={20} // Ajusta la altura según tus necesidades
                      width={40}
                      checked={form.callforward_keep_caller_caller_id}
                      onChange={() =>
                        handleChangeswitch("callforward_keep_caller_caller_id")
                      }
                    />
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                    <div className="d-flex flex-column">
                      <label className="itp-edit-number-label-1">
                        Call screening
                      </label>
                      <label className="itp-edit-number-label-2">
                        If enabled, when answering a forwarded call, a message
                        will prompt you to accept the call or deny
                      </label>
                    </div>
                  </Col>
                  <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                    <Switch
                      className="itp-custom-table-switch"
                      onColor="#626ed4"
                      height={20} // Ajusta la altura según tus necesidades
                      width={40}
                      checked={form.callforward_call_confirmation}
                      onChange={() =>
                        handleChangeswitch("callforward_call_confirmation")
                      }
                    />
                  </Col>
                </Row>
              </>
            )}
          </Card>
        </>
      )}

      {!advanceCallFlows && (
        <>
          <br></br>
          <div>
            <Row>
              <Col>
                <div className="itp-user-settings-separator-line"></div>
              </Col>
            </Row>
          </div>
          <br></br>

          <h1
            className={`itp-custom-table-title${
              width > 991 ? " centered" : ""
            }`}
          >
            Voicemail
          </h1>
          <div className="itp-custom-table-container-actions label">
            <div className="itp-custom-table-actions">
              <label
                className={`itp-custom-table-label${
                  width > 991 ? " centered" : ""
                }`}
              >
                Manage which voicemail calls will reach if no user answers the
                call
              </label>
            </div>
          </div>

          <Card
            className={
              width > 991
                ? "itp-table-container-centered mt-3 mb-4 itp-edit-number-card"
                : "itp-table-container-all mt-3 mb-4"
            }
          >
            <Row className="d-flex align-items-center">
              <Col xs={10} sm={10} md={10} lg={10} xl={10} xxl={10}>
                {!voicemailURL ? (
                  <div className="d-flex justify-content-center align-items-center">
                    <label className="itp-edit-number-label-1">
                      There is no saved audio file
                    </label>
                  </div>
                ) : (
                  //   <audio controls>
                  //   <source src={voicemailURL} type="audio/wav" />
                  //   Tu navegador no admite la reproducción de audio.
                  // </audio>
                  <AudioMessage
                    audioUrl={voicemailURL}
                    duration={audioDuration}
                    icon={true}
                    hiddenTime={true}
                    w100={true}
                    noBorderContainer={true}
                    outVolume={true}
                    ml0={true}
                    styles={{ marginLeft: "-12px" }}
                    noFetch={true}
                  />
                )}
              </Col>
              <Col xs={2} sm={2} md={2} lg={2} xl={2} xxl={2}>
                <button onClick={activeLoadVoicemail} className="btn-primary" style={{marginLeft:"40px"}}>
                  Modify
                </button>
              </Col>
            </Row>
          </Card>
        </>
      )}

      <br></br>
      <div>
        <Row>
          <Col>
            <div className="itp-user-settings-separator-line"></div>
          </Col>
        </Row>
      </div>
      <br></br>

      <Card
        className={
          width > 991
            ? "itp-table-container-centered mt-3 mb-4 itp-edit-number-card"
            : "itp-table-container-all mt-3 mb-4"
        }
      >
        <Row>
          <Col xs={11} sm={11} md={11} lg={11} xl={11} xxl={11}>
            <div className="d-flex flex-column">
              <label className="itp-edit-number-label-1">
                Artificial Intelligence
              </label>
              <label className="itp-edit-number-label-2">
                Enable auto response using AI
              </label>
            </div>
          </Col>
          <Col xs={1} sm={1} md={1} lg={1} xl={1} xxl={1}>
            <Switch
              className="itp-custom-table-switch mt-3"
              onColor="#626ed4"
              height={20} // Ajusta la altura según tus necesidades
              width={40}
              checked={form.ai_enabled}
              onChange={() => handleChangeswitch("ai_enabled")}
            />
          </Col>
        </Row>
        {form.ai_enabled && (
          <>
            <Row className="mt-4">
              <Col xs={11} sm={11} md={11} lg={11} xl={11} xxl={11}>
                <div className="d-flex flex-column">
                  <label className="itp-edit-number-label-1">
                    Support Center
                  </label>
                  <label className="itp-edit-number-label-2">
                    Ability to Create, Update Support Cases
                  </label>
                </div>
              </Col>
              <Col xs={1} sm={1} md={1} lg={1} xl={1} xxl={1}>
                <Switch
                  className="itp-custom-table-switch mt-3"
                  onColor="#626ed4"
                  height={20} // Ajusta la altura según tus necesidades
                  width={40}
                  checked={form.support_center}
                  onChange={() => handleChangeswitch("support_center")}
                />
              </Col>
            </Row>
            <Row className="mt-4">
              <Col xs={11} sm={11} md={11} lg={11} xl={11} xxl={11}>
                <div className="d-flex flex-column">
                  <label className="itp-edit-number-label-1">
                    Lead Management
                  </label>
                  <label className="itp-edit-number-label-2">
                    Ability to Create, Update Leads, New leads will be assigned
                    to Funnels and Stages which closely resemble the
                    conversation flow. Ensure accurate descriptions and names
                    are used in Funnels and Funnels Stages
                  </label>
                </div>
              </Col>
              <Col xs={1} sm={1} md={1} lg={1} xl={1} xxl={1}>
                <Switch
                  className="itp-custom-table-switch mt-3"
                  onColor="#626ed4"
                  height={20} // Ajusta la altura según tus necesidades
                  width={40}
                  checked={form.ai_lead_manager}
                  onChange={() => handleChangeswitch("ai_lead_manager")}
                />
              </Col>
            </Row>
            <Row className="mt-4">
              <Col xs={11} sm={11} md={11} lg={11} xl={11} xxl={11}>
                <div className="d-flex flex-column">
                  <label className="itp-edit-number-label-1">
                    Appointments
                  </label>
                  <label className="itp-edit-number-label-2">
                    Abilityto Create or Update appointments in the workspace
                  </label>
                </div>
              </Col>
              <Col xs={1} sm={1} md={1} lg={1} xl={1} xxl={1}>
                <Switch
                  className="itp-custom-table-switch mt-3"
                  onColor="#626ed4"
                  height={20} // Ajusta la altura según tus necesidades
                  width={40}
                  checked={form.manage_appointments}
                  onChange={() => handleChangeswitch("manage_appointments")}
                />
              </Col>
            </Row>
            <Row className="mt-4">
              <Col xs={11} sm={11} md={11} lg={11} xl={11} xxl={11}>
                <div className="d-flex flex-column">
                  <label className="itp-edit-number-label-1">Products</label>
                  <label className="itp-edit-number-label-2">
                    Ability to Converse about Products or Services you offer and
                    provide general information/support
                  </label>
                </div>
              </Col>
              <Col xs={1} sm={1} md={1} lg={1} xl={1} xxl={1}>
                <Switch
                  className="itp-custom-table-switch mt-3"
                  onColor="#626ed4"
                  height={20} // Ajusta la altura según tus necesidades
                  width={40}
                  checked={form.manage_products}
                  onChange={() => handleChangeswitch("manage_products")}
                />
              </Col>
            </Row>
          </>
        )}
      </Card>

      <br></br>
      <div>
        <Row>
          <Col>
            <div className="itp-user-settings-separator-line"></div>
          </Col>
        </Row>
      </div>
      <br></br>

      <Card
        className={
          width > 991
            ? "itp-table-container-centered mt-3 mb-4 itp-edit-number-card"
            : "itp-table-container-all mt-3 mb-4"
        }
      >
        <div className="d-flex justify-content-between">
          <div className="d-flex flex-column">
            <label className="itp-edit-number-label-1">Delete Number</label>
            <label className="itp-edit-number-label-2">
              Number will be deleted permantly from system
            </label>
          </div>
          <button
            onClick={() => activeModalDelete(info)}
            className="btn-primary itp-edit-number-button-3"
          >
            Delete
          </button>
        </div>
      </Card>

      <MenuRight
        show={openMenu}
        closeMenu={closeMenu}
        title={!showLoadVoicemail ? "Add Users" : "Load Voicemail"}
        to="to Edit Phone Number"
      >
        {openMenu && !showLoadVoicemail && (
          <>
            <label className="itp-label-input__label">Select Users</label>
            <Select
              isMulti
              onChange={(e) => {
                setUsersSelected(e);
              }}
              className="basic-single itp-add-contact-manually-select"
              classNamePrefix="select"
              value={usersSelected}
              isSearchable={true}
              name="contact_list_id"
              placeholder="Select Option"
              options={allUsers}
            />
            <button
              className="btn-primary loading add-lead-new-contact"
              disabled={usersSelected.length === 0 ? true : false}
              onClick={saveNumbers}
            >
              {loadingNumber && <LoadingButton />}
              Save
            </button>
          </>
        )}

        {openMenu && showLoadVoicemail && (
          <>
            <label className="itp-edit-number-label-1">
              Select the option to save a voicemail
            </label>
            <select
              onChange={onChangeOptionLoadVoicemail}
              value={selectedOptionVoicemail}
              className="form-select-itp"
            >
              <option value="" disabled>
                Select Option
              </option>
              <option value="upload">Upload File</option>
              <option value="record">Record Sound</option>
            </select>

            {selectedOptionVoicemail === "upload" && (
              <>
                <Col
                  className="d-flex col-12 pt-4"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <label
                      style={{
                        color: "white",
                        marginRight: "1rem",
                        marginBottom: "0",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "8rem",
                      }}
                      className="btn-primary"
                      htmlFor="input_file"
                    >
                      Browse Files
                    </label>

                    <input
                      onChange={() => {
                        setTimeout(() => {
                          setReload(reload + 1);
                        }, [100]);
                      }}
                      id="input_file"
                      className="d-none form-control"
                      type="file"
                      accept="audio/mp3,audio/wav"
                      ref={inputRef}
                    />
                    <span>Only MP3 or WAV Files Are Allowed</span>
                  </div>
                  <label className="mt-3 itp-label-input__label itp-analytics-label-select">
                    {inputRef.current &&
                      inputRef.current.files.length !== 0 &&
                      inputRef.current.files[0].name}
                  </label>
                </Col>
                <button
                  onClick={saveLoadVoicemail}
                  className="btn-primary itp-lead-detail-button-save loading mb-4"
                  disabled={
                    !inputRef.current ||
                    inputRef.current.files.length === 0 ||
                    !inputRef.current.files[0].name ||
                    loadingVoicemail
                  }
                >
                  {loadingVoicemail && <LoadingButton />}
                  Save
                </button>
              </>
            )}

            {selectedOptionVoicemail === "record" && (
              <>
                <label className="itp-label-input__label itp-analytics-label-select">
                  To start recording click on play button and to stop click on
                  pause
                </label>
                <div>
                  {!recording ? (
                    <PlayCircleOutlineIcon
                      style={{ cursor: "pointer" }}
                      onClick={startRecording}
                    />
                  ) : (
                    <PauseIcon
                      style={{ cursor: "pointer" }}
                      onClick={stopRecording}
                    />
                  )}
                  {audioBlob && (
                    <div className="mt-2">
                      <audio controls src={audioUrl}></audio>
                    </div>
                  )}
                </div>
                <button
                  onClick={saveLoadVoicemail}
                  className="btn-primary itp-lead-detail-button-save loading mb-4"
                  disabled={!audioBlob || !audioUrl || loadingVoicemail}
                >
                  {loadingVoicemail && <LoadingButton />}
                  Save
                </button>
              </>
            )}
          </>
        )}
      </MenuRight>
    </>
  );
};

export default EditNumber;
