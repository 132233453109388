import React, { useEffect, useState } from "react";
import { CustomTable, Loading } from "../../../components";
import { Card, CardBody, Col, Row } from "reactstrap";
// import sendWhisper from "../../../assets/icons/sendWhisper.svg"
// import listen from "../../../assets/icons/listen.svg"
import petitionGet from "../../../services/petitionGet";
import Extensions from "./Extensions/Extensions";
import CallParkingSlots from "./CallParkingSlots/CallParkingSlots";
import Queues from "./Queues/Queues";
import json from "../../../config.json";
import "./LivePanel.css";

const LivePanel = () => {
  const urlBase = json.prod
    ? json.webSocketPanel.prod
    : json.webSocketPanel.dev;
  const id = JSON.parse(localStorage.getItem("userDetails")).api_id;
  const urlWebSocket = `${urlBase}itpvoice?account_id=${id}`;
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState(null);
  const [parkingCall, setParkingCall] = useState(null);
  const [queues, setQueues] = useState([]);
  const [activeUsers, setActiveUsers] = useState(null);
  const [totalUsers, setTotalUsers] = useState([]);

  const petition = async () => {
    setLoading(true);
    await petitionGet("users")
      .then(({ data: result }) => {
        setTotalUsers(result.result);
      })
      .catch((error) => console.log(error));

    await petitionGet("operationPanel")
      .then(({ data: result }) => {
        const users = result.result.users;
        const parkedCalls = result.result.parked_calls;
        const queues = result.result.queues;

        users.forEach((element) => {
          element.callerID = `${element.firstname} ${element.lastname || ""}`;
        });

        setUsers(users);
        setParkingCall(parkedCalls);
        setQueues(queues);
        setActiveUsers(true);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if (users && parkingCall) {
      let saveUsers = users;
      let socket = new WebSocket(urlWebSocket);

      const onmessage = (socket) => {
        const token = JSON.parse(
          localStorage.getItem("userDetails")
        ).access_token;

        socket.onopen = (e) => {
          socket.send(
            JSON.stringify({
              action: "login",
              payload: {
                jwt_token: token.replace(/['"]+/g, ""),
                account_id: id,
              },
            })
          );
        };

        socket.onmessage = (e) => {
          let socketInfo = JSON.parse(e.data);
          try {
            if (typeof socketInfo === "string") {
              socketInfo = JSON.parse(socketInfo);
            }
          } catch (error) {
            console.log(error);
          }

          if (socketInfo.event_name) {
            let usersCopy;
            let { from_user_id, to_user_id, uniqueId, dialed_number } =
              socketInfo.metadata;

            let findFromNumber = saveUsers.find(
              (element) => element.pk == from_user_id
            );
            let findToNumber = saveUsers.find(
              (element) => element.pk == to_user_id
            );

            switch (socketInfo.event_name) {
              case "USER_DIAL":
                usersCopy = saveUsers.map((element) => {
                  if (
                    element.pk === from_user_id ||
                    element.pk === to_user_id
                  ) {
                    // let withNumber =
                    //   element.pk === from_user_id
                    //     ? `${findToNumber?.presence_id || to_user_id}`
                    //     : `${findFromNumber?.presence_id || from_user_id}`;

                    let withNumber =
                      (!from_user_id || !to_user_id) && dialed_number
                        ? dialed_number
                        : element.pk === from_user_id
                        ? `${findToNumber?.presence_id || to_user_id}`
                        : `${findFromNumber?.presence_id || from_user_id}`;

                    if (
                      element.channels.find(
                        (elementTwo) => elementTwo.withNumber === withNumber
                      )
                    )
                      return element;

                    element.devices_registered = true;
                    element.time_end = 0;
                    element.channels.push({
                      answered: false,
                      time: 0,
                      withNumber,
                      uniqueId,
                    });
                  }
                  return element;
                });
                saveUsers = usersCopy;
                setUsers(usersCopy);
                break;

              case "USER_ANSWER":
                usersCopy = saveUsers.map((element) => {
                  if (element.channels.length > 0) {
                    let saveIdChannels;
                    element.channels.map((elementTwo, i) => {
                      if (elementTwo.uniqueId === uniqueId) saveIdChannels = i;
                    });

                    if (element.channels[saveIdChannels])
                      element.channels[saveIdChannels].answered = true;
                  }

                  return element;
                });

                setUsers(usersCopy);
                break;

              /*  case "USER_HANGUP": */
              case "USER_HANGUP":
                usersCopy = saveUsers.map((element) => {
                  if (element.channels.length > 0) {
                    let saveIdChannels;
                    element.channels.map((elementTwo, i) => {
                      if (elementTwo.uniqueId === uniqueId) saveIdChannels = i;
                    });
                    if (element.channels[saveIdChannels])
                      element.channels[saveIdChannels].terminated = 1;
                  }
                  return element;
                });

                saveUsers = usersCopy;
                setUsers(usersCopy);
                break;

              case "CALLPARKING_PARKED":
                let findUser = users.find(
                  (elementTwo) =>
                    elementTwo.pk === parseInt(socketInfo.metadata.from_user_id)
                );

                if (findUser) {
                  setParkingCall((preview) =>
                    preview.map((element) =>
                      element.slot_number === socketInfo.metadata.parking_slot
                        ? {
                            ...element,
                            call_id: socketInfo.metadata.uniqueid,
                            callerid_num: findUser?.presence_id || "",
                          }
                        : element
                    )
                  );
                }
                break;

              case "CALLPARKING_ABANDONED":
                setParkingCall((preview) =>
                  preview.map((element) =>
                    element.slot_number === socketInfo.metadata.parking_slot
                      ? { ...element, call_id: "", callerid_num: "" }
                      : element
                  )
                );
                break;

              case "QUEUE_MEMBER_DIAL":
                usersCopy = saveUsers.map((element) => {
                  if (element.pk == to_user_id) {
                    let withNumber = `${
                      findToNumber?.presence_id || to_user_id
                    }`;

                    if (
                      element.channels.find(
                        (elementTwo) => elementTwo.withNumber == withNumber
                      )
                    )
                      return element;

                    element.devices_registered = true;
                    element.time_end = 0;
                    element.channels.push({
                      answered: false,
                      time: 0,
                      withNumber,
                      uniqueId,
                    });
                  }
                  return element;
                });
                saveUsers = usersCopy;
                setUsers(usersCopy);
                break;

              case "QUEUE_MEMBER_ANSWER":
                usersCopy = saveUsers.map((element) => {
                  if (element.channels.length > 0) {
                    let saveIdChannels;
                    element.channels.map((elementTwo, i) => {
                      if (elementTwo.uniqueId === uniqueId) saveIdChannels = i;
                    });

                    if (element.channels[saveIdChannels])
                      element.channels[saveIdChannels].answered = true;
                  }

                  return element;
                });

                setUsers(usersCopy);
                break;

              case "QUEUE_HANGUP":
                usersCopy = saveUsers.map((element) => {
                  if (element.channels.length > 0) {
                    let saveIdChannels;
                    element.channels.map((elementTwo, i) => {
                      if (elementTwo.uniqueId === uniqueId) saveIdChannels = i;
                    });
                    if (element.channels[saveIdChannels])
                      element.channels[saveIdChannels].terminated = 1;
                  }
                  return element;
                });

                saveUsers = usersCopy;
                setUsers(usersCopy);
                break;

              case "PING":
                socket.send(JSON.stringify({ type: "PONG" }));
                break;

              default:
                break;
            }
          }
        };

        socket.onclose = (event) => {
          // Try to reconnect after a certain amount of time
          setTimeout(() => {
            socket = new WebSocket(urlBase);
            onmessage(socket);
          }, 5000);
        };

        //Error
        socket.onerror = (event) => {
          // Try to reconnect after a certain amount of time
          setTimeout(() => {
            socket = new WebSocket(urlBase);
            onmessage(socket);
          }, 5000);
        };
      };

      onmessage(socket);

      //Interval of opening socket
      const intervalIdTwo = setInterval(() => {
        if (socket.readyState === 3) {
          socket = new WebSocket(urlBase);
          onmessage(socket);
        }
      }, 10000);

      const intervalIdOne = setInterval(() => {
        saveUsers.map((element) => {
          if (element.channels.length === 0) return element;

          if (!element.channels[0].terminated) {
            element.time_end = element.time_end + 1;
            element.channels[0].time = element.channels[0].time + 1;
          } else if (element.channels[0].terminated < 5)
            element.channels[0].terminated = element.channels[0].terminated + 1;
          else
            element.channels = element.channels.filter(
              (elementTwo) => !elementTwo.terminated
            );
        });

        setUsers(saveUsers);
        setQueues(queues);
      }, 1000);

      const intervalIdThree = setInterval(() => {
        socket.send(JSON.stringify({ action: "ping", payload: "pinging..." }));
      }, 30000);

      return () => {
        clearInterval(intervalIdOne);
        clearInterval(intervalIdTwo);
        clearInterval(intervalIdThree);
      };
    }
  }, [activeUsers]);

  useEffect(() => {
    petition();
  }, []);

  return (
    <div className="itp-test">
      {loading ? (
        <Loading />
      ) : (
        <>
          {users && <Extensions users={users} setUsers={setUsers} />}

          <div className="row">
            <div className="col-6">
              <div className="itp-livePanel-table-call-praking">
                {parkingCall && <CallParkingSlots parkingCall={parkingCall} />}
              </div>
            </div>
            <div className="col-6">
              <Card
                className="itp-livePanel-queues-col"
                style={{ maxWidth: "991px"}}
              >
                <CardBody>
                  <h1 className="itp-livePanel-queue-title">Queues</h1>
                  <p className="itp-livePanel-queue-subtitle">
                    View current queues & their user status
                  </p>
                  <Card className="itp-livePanel-card-container-queues">
                    <CardBody style={{ paddingLeft: "0" }}>
                      {users && (
                        <Queues
                          queues={queues}
                          users={users}
                          totalUsers={totalUsers}
                        />
                      )}
                    </CardBody>
                  </Card>
                </CardBody>
              </Card>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default LivePanel;
