import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useMenuDispatch } from "../../../../contexts/MenuContext.js";
import { Loading, CustomTable, MenuRight } from "../../../../components";
import useScreenSize from "../../../../hooks/useScreenSize.js";
import petitionGet from "../../../../services/petitionGet.js";
import arrowleft from "../../../../assets/icons/arrow-left.svg";
import view from "../../../../assets/icons/view.svg";

const Troubleshooting = () => {
  const { width } = useScreenSize();

  const myExtension = useSelector((state) => state.myExtension.userDetails);

  const navigate = useNavigate();
  const dispatch = useMenuDispatch();

  const [loading, setLoading] = useState(true);
  const [dataTable, setDataTable] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [devicesDetails, setDevicesDetails] = useState(null);
  const [dataDevices, setDataDevices] = useState(null);
  const [dataDetails, setDataDetails] = useState(null);

  const handleBackClick = () => {
    dispatch({ type: "SET_DEFAULT_MENU" });
    navigate("/Dashboard");
  };

  const closeMenu = () => {
    setOpenMenu(false);
    setDevicesDetails(null);
    setDataDevices(null);
    setDataDetails(null);
  };

  const handleClickDetails = (element) => {
    let allTitles = [];
    Object.keys(element).map((element) =>
      allTitles.push({ title: element, key: element })
    );

    setDevicesDetails(true);
    setDataDetails({
      title: allTitles,
      content: [{ ...element }],
    });
  };

  const backToDevicesTable = () => {
    setDevicesDetails(false);
  };

  const activeOpenMenu = (e) => {
    if (e) {
      let registeredDevicesTemp = [];
      e.registered_devices.map((element) => {
        let tempElement = {
          ...element,
          ...element.location,
          sourceIp: element.location.socket.split(":")[1],
          sourcePort: element.location.socket.split(":")[2],
        };
        delete tempElement.location;

        registeredDevicesTemp.push(tempElement);
      });
      setDataDevices({
        title: [{ title: "Device Type", key: "device_type" }],
        content: registeredDevicesTemp,
        actions: {
          title: "Details",
          content: [
            {
              icon: "Description",
              handleClick: handleClickDetails,
            },
          ],
        },
      });
      setOpenMenu(true);
    }
  };

  const petition = () => {
    setLoading(true);
    petitionGet("sipRegistration")
      .then(({ data: result }) => {
        result.result.forEach((element) => {
          element.name = `${element.firstname} ${element?.lastname || ""}`;
        });

        setDataTable({
          title: [
            { title: "Name", key: "name" },
            { title: "Presence Id", key: "presence_id" },
          ],
          content: [...result.result],
          actions: {
            title: "Devices",
            content: [
              {
                name: "Details",
                handleClick: activeOpenMenu,
                icon: (
                  <img
                    style={{ width: "20px", height: "20px" }}
                    src={view}
                    alt=""
                  />
                ),
              },
            ],
          },
        });
        setLoading(false);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if (
      Object.keys(myExtension).length > 0 &&
      myExtension.priv_level !== "admin"
    ) {
      handleBackClick();
    }
  }, [myExtension]);

  useEffect(() => {
    petition();
  }, []);

  return (
    <>
      <div onClick={handleBackClick} className="itp-tex-back-container">
        <div className="itp-text-container-funnel-details">
          <img src={arrowleft} alt="arrow-left" />
          <span className="itp-text-back-funnel-details">
            Back to Dashboard
          </span>
        </div>
      </div>

      {loading ? (
        <Loading />
      ) : (
        <>
          {dataTable && (
            <div style={{ marginTop: "-12px" }} className="mb-4">
              <CustomTable
                data={dataTable}
                centered={width > 991 ? true : false}
                noPagBack={true}
                overflowCard={width > 991 ? "inherit" : "auto"}
              />
            </div>
          )}

          <MenuRight
            show={openMenu}
            closeMenu={!devicesDetails ? closeMenu : backToDevicesTable}
            title="Details"
            to={devicesDetails ? "to Devices Table" : ""}
          >
            {devicesDetails ? (
              <>
                <div className="p-3">
                  {dataDetails &&
                    dataDetails.title.map((element, i) => {
                      return (
                        <label
                          style={{
                            width: "100%",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                          key={i}
                          className="itp-label-input__label"
                        >
                          {element.title} :{" "}
                          {dataDetails.content[0][element.key]}{" "}
                        </label>
                      );
                    })}
                </div>
              </>
            ) : (
              <>
                {" "}
                {dataDevices && (
                  <>
                    {dataDevices.content.length === 0 ? (
                      <label style={{ fontSize: "1rem", textAlign: "center" }}>
                        You do not have an active device
                      </label>
                    ) : (
                      <div style={{ marginTop: "-4rem" }} className="mb-4">
                        <CustomTable
                          noPagBack={true}
                          data={dataDevices}
                          centered={true}
                          overflowCard="inherit"
                        />
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </MenuRight>
        </>
      )}
    </>
  );
};

export default Troubleshooting;
