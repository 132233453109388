import React, { Fragment } from "react";
import Select, { components } from "react-select";

const SelectComponent = ({
  onChange,
  includeOption,
  defaultValue,
  placeholder,
  options,
  styles,
  delimiter,
  isSearchable,
  name,
  functionOption,
}) => {
  const { Option } = components;
  const IconOption = (props) => (
    <Option {...props}>
      <div className="d-flex justify-content-between">
        {props.data.label}
        <div
          className="icon-hover"
          onClick={(e) => functionOption(e, props.data)}
        >
          {props.data.icon}
        </div>
      </div>
    </Option>
  );

  return (
    <Fragment>
      {includeOption ? (
        <Select
          onChange={(e) => onChange(e)}
          className="basic-single"
          classNamePrefix="select"
          defaultValue={defaultValue}
          isSearchable={isSearchable}
          placeholder={placeholder}
          name={name}
          options={options}
          styles={styles}
          delimiter={delimiter}
          components={{
            Option: IconOption,
          }}
        />
      ) : (
        <Select
          onChange={(e) => onChange(e)}
          className="basic-single"
          classNamePrefix="select"
          defaultValue={defaultValue}
          isSearchable={isSearchable}
          placeholder={placeholder}
          name={name}
          options={options}
          styles={styles}
          delimiter={delimiter}
        />
      )}
    </Fragment>
  );
};

export default SelectComponent;
