// import { createStore, compose,applyMiddleware } from 'redux';
// import rootReducer from "./reducers/index.js"
// import createSagaMiddleware from '@redux-saga/core';
// import rootSaga from './sagas/index.js';

// const sagaMiddleware = createSagaMiddleware()
// const middlewares = [sagaMiddleware];
// const store =createStore(rootReducer,applyMiddleware(...middlewares))

// sagaMiddleware.run(rootSaga)

// export default store;

import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './reducers/index.js';
import createSagaMiddleware from 'redux-saga';
import rootSaga from './sagas/index.js';

const sagaMiddleware = createSagaMiddleware();
const middleware = (getDefaultMiddleware) => getDefaultMiddleware().concat(sagaMiddleware);

const store = configureStore({
  reducer: rootReducer,
  middleware: middleware,
});

sagaMiddleware.run(rootSaga);

export default store;

