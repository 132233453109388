import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Card, CardBody, Col, Row } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { useMenuDispatch } from "../../../contexts/MenuContext.js";
import { CustomTable, Loading } from "../../../components";
import { updateMyExtention } from "../../../redux/actions/myExtension.js";
import Switch from "react-switch";
import Select from "react-select";
import SipDetailsModal from "./SipDetailsModal.js";
import petitionGet from "../../../services/petitionGet";
import petitionPatch from "../../../services/petitionPatch.js";
import petitionPost from "../../../services/petitionPost.js";
import petitionDelete from "../../../services/petitionDelete";
import infoIcon from "../../../assets/icons/information-icon.svg";
import arrowleft from "../../../assets/icons/arrow-left.svg";
import "./UserSettings.css";

const UserSettings = () => {
  const id = JSON.parse(localStorage.getItem("userDetails")).api_id;

  const dispatch = useDispatch()
  const navigate = useNavigate();
  const dispatchMenu = useMenuDispatch();

  const myExtension = useSelector((state) => state.myExtension.userDetails);

  const [dataTableUserDevices, setDataTableUserDevices] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isSipDetailsModalOpen, setIsSipDetailsModalOpen] = useState(false);
  const [selectedSipDevice, setSelectedSipDevice] = useState(null);
  const [firstrender, setFirstRender] = useState(true);
  const [numberCallForwarding, setNumberCallForwarding] = useState("");
  const [formCallerId, setFormCallerId] = useState({
    default_outbound_callerid_number: "",
    default_outbound_callerid_name: "",
  });
  const [valuenumberCallerID, setValueNumberCallerID] = useState("");
  const [numbersCallerId, setNumbersCallerId] = useState([]);
  const [switchs, setSwitchs] = useState({
    callforward_enable: false,
    callforward_keep_caller_caller_id: false,
    callforward_queue_calls: false,
    callforward_call_confirmation: false,
    switchOverrideDefaultCallerID: false,
    call_recording_internal: false,
    call_recording_external: false,
    switchIntercomCalls: false,
    faxing_settings_enable_notifications: false,
  });
  const [intercom, setIntercom] = useState(null);

  const sectionRoutes = {
    devices: "/Devices",
    callForwarding: "/Call-Forwarding",
    callerID: "/Caller-ID",
    callRecordings: "/Call-Recordings",
    intercom: "/Intercom",
    fax: "/Fax",
  };

  const handleBackClick = () => {
    dispatchMenu({ type: "SET_DEFAULT_MENU" });
    navigate("/Dashboard");
  };

  const openSipDetailsModal = (device, myExtension) => {
    setSelectedSipDevice(device);
    setIsSipDetailsModalOpen(true);
  };

  const closeSipDetailsModal = () => {
    setSelectedSipDevice(null);
    setIsSipDetailsModalOpen(false);
  };

  const handleChangeSwitchCallForwarding = (switchName) => {
    setSwitchs({ ...switchs, [switchName]: !switchs[switchName] });

    let data = {};

    if (switchName === "callforward_enable" && switchs.callforward_enable) {
      data = {
        callforward_enable: false,
        callforward_keep_caller_caller_id: false,
        callforward_queue_calls: false,
        callforward_call_confirmation: false,
        callforward_number: "",
      };

      setSwitchs({
        ...switchs,
        callforward_enable: false,
        callforward_keep_caller_caller_id: false,
        callforward_queue_calls: false,
        callforward_call_confirmation: false,
      });
    } else {
      data = { [switchName]: !switchs[switchName] };
    }

    petitionPatch("userDetails", { data })
      .then(({ data: result }) => {
        const newUserDetails = {
          ...myExtension,
          ...result.result
        }

        dispatch(updateMyExtention(newUserDetails));
      })
      .catch((error) => console.log(error));
  };

  const handleChangeSwitchCallerID = () => {
    setSwitchs({
      ...switchs,
      switchOverrideDefaultCallerID: !switchs.switchOverrideDefaultCallerID,
    });

    if (switchs.switchOverrideDefaultCallerID) {
      let data = {
        default_outbound_callerid_number: "",
        default_outbound_callerid_name: "",
      };

      petitionPatch("userDetails", { data })
      .then(({ data: result }) => {
        const newUserDetails = {
          ...myExtension,
          ...result.result
        }

        dispatch(updateMyExtention(newUserDetails));
      })
      .catch((error) => console.log(error));
    }
  };

  const handleChangeSwitchCallRecording = (switchName) => {
    setSwitchs({ ...switchs, [switchName]: !switchs[switchName] });

    let data = {
      [switchName]: !switchs[switchName] ? "enabled" : "disabled",
    };

    petitionPatch("userDetails", { data })
      .then(({ data: result }) => {
        const newUserDetails = {
          ...myExtension,
          ...result.result
        }

        dispatch(updateMyExtention(newUserDetails));
      })
      .catch((error) => console.log(error));
  };

  const handleChangeNumberCallerID = (e) => {
    setValueNumberCallerID(e);
    setFormCallerId({
      ...formCallerId,
      default_outbound_callerid_number: e.value,
    });

    let data = {
      default_outbound_callerid_number: e.value,
    };

    petitionPatch("userDetails", { data })
      .then(({ data: result }) => {
        const newUserDetails = {
          ...myExtension,
          ...result.result
        }

        dispatch(updateMyExtention(newUserDetails));
      })
      .catch((error) => console.log(error));
  };

  const handleChangeOtherSwitch = (switchName) => {
    setSwitchs({ ...switchs, [switchName]: !switchs[switchName] });

    if (switchName === "switchIntercomCalls" && !switchs.switchIntercomCalls) {
      let data = {
        numbers: [`*80${myExtension.presence_id}`],
        type: "UserIntercom",
        callflow_object: {
          flow: {
            data: {
              id: `${myExtension.pk}`,
            },
            module: "intercom",
            children: {},
          },
        },
        enabled: true,
        owner_id: `${myExtension.pk}`,
      };

      petitionPost("intercom", { data })
        .then(({ data: result }) => {})
        .catch((error) => console.log(error));
    } else if (
      switchName === "switchIntercomCalls" &&
      switchs.switchIntercomCalls
    ) {
      petitionDelete("intercom", { pk: intercom.pk })
        .then(({ data: result }) => {})
        .catch((error) => console.log(error));
    } else if (switchName !== "switchIntercomCalls") {
      let data = {
        faxing_settings_enable_notifications:
          !switchs.faxing_settings_enable_notifications,
      };

      petitionPatch("userDetails", { data })
      .then(({ data: result }) => {
        const newUserDetails = {
          ...myExtension,
          ...result.result
        }

        dispatch(updateMyExtention(newUserDetails));
      })
      .catch((error) => console.log(error));
    }
  };

  const handleScroll = () => {
    const sectionKeys = Object.keys(sectionRoutes);

    let targetSection = null;

    sectionKeys.forEach((sectionKey) => {
      const sectionElement = document.getElementById(sectionKey);
      if (sectionElement) {
        const rect = sectionElement.getBoundingClientRect();
        if (rect.top > 0 && rect.top < 200) {
          targetSection = sectionKey;
        } else if (rect.top === 0) {
          targetSection = "/Devices";
        }
      }
    });

    if (targetSection !== null) {
      navigate(sectionRoutes[targetSection]);
    }
  };

  const handleSidebarItemClick = (sectionKey) => {
    const sectionElement = document.getElementById(sectionKey);

    if (sectionElement) {
      if (sectionKey !== Object.keys(sectionRoutes)[0]) {
        sectionElement.scrollIntoView({ behavior: "smooth" });
      } else {
        // Scroll to the top of the page instead of the first section
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    }
  };

  const petition = async (noLoading) => {
    if (!noLoading) setLoading(true);

    const userDevices = myExtension.userDevices.map((element) => {
      element.deviceName = element.device_type;
      element.details = "Details";
      return element;
    });

    setDataTableUserDevices({
      title: [
        {
          title: "Name",
          key: "deviceName",
        },
        { title: "Device Type", key: "device_type" },
        {
          title: "Details",
          key: "details",
          isLink: true,
          onChange: (event) => openSipDetailsModal(event, myExtension),
        },
      ],
      content: [...userDevices],
      actions: {
        title: "Action",
        content: [
          {
            name: "Edit",
            icon: <FontAwesomeIcon icon={faPenToSquare} />,
            handleClick: () => console.log("Edit"),
          },
        ],
      },
    });

    setNumberCallForwarding(myExtension.callforward_number);

    await petitionGet("intercom", { id })
      .then(({ data: result }) => {
        setIntercom(result.result);

        setSwitchs({
          ...switchs,

          faxing_settings_enable_notifications:
            myExtension.faxing_settings_enable_notifications,

          switchIntercomCalls: result.result ? true : false,

          call_recording_internal:
            myExtension?.call_recording_internal === "enabled",

          call_recording_external:
            myExtension?.call_recording_external === "enabled",

          callforward_enable: myExtension.callforward_enable || false,

          callforward_keep_caller_caller_id:
            myExtension.callforward_keep_caller_caller_id || false,

          callforward_queue_calls: myExtension.callforward_queue_calls || false,

          callforward_call_confirmation:
            myExtension.callforward_call_confirmation || false,

          switchOverrideDefaultCallerID:
            (myExtension.default_outbound_callerid_number === null ||
              myExtension.default_outbound_callerid_number === "") &&
            (myExtension.default_outbound_callerid_name === null ||
              myExtension.default_outbound_callerid_name === "")
              ? false
              : true,
        });
      })
      .catch((error) => console.log(error));

    await petitionGet("assignedNumbers")
      .then(({ data: result }) => {
        result.result.forEach((element) => {
          element.label = element.number;
          element.value = element.number;
        });

        let defaultNumber = result.result.find(
          (element) =>
            element.number === myExtension.default_outbound_callerid_number
        );

        setFormCallerId({
          default_outbound_callerid_name:
            myExtension.default_outbound_callerid_name || "",
          default_outbound_callerid_number:
            myExtension.default_outbound_callerid_number || "",
        });

        setValueNumberCallerID(defaultNumber);
        setNumbersCallerId(result.result);

        setLoading(false);
        setFirstRender(false);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if (formCallerId.default_outbound_callerid_name) {
      const timer = setTimeout(() => {
        let data = {
          default_outbound_callerid_name:
            formCallerId.default_outbound_callerid_name,
        };

        petitionPatch("userDetails", { data })
        .then(({ data: result }) => {
          const newUserDetails = {
            ...myExtension,
            ...result.result
          }
  
          dispatch(updateMyExtention(newUserDetails));
        })
        .catch((error) => console.log(error));
      }, 1000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [formCallerId.default_outbound_callerid_name]);

  useEffect(() => {
    if (numberCallForwarding) {
      const timer = setTimeout(() => {
        let data = {
          callforward_number: numberCallForwarding,
        };

        petitionPatch("userDetails", { data })
        .then(({ data: result }) => {
          const newUserDetails = {
            ...myExtension,
            ...result.result
          }
  
          dispatch(updateMyExtention(newUserDetails));
        })
        .catch((error) => console.log(error));
      }, 1000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [numberCallForwarding]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [navigate]);

  useEffect(() => {
    let path = window.location.pathname;
    path = path.split("/");
    path = path[path.length - 1];

    let parameter = window.location.search;
    let sectionKey = parameter.split("=")[1];

    if (sectionKey || firstrender) {
      handleSidebarItemClick(sectionKey || path);
    }
  }, [window.location.search]);

  useEffect(() => {
    if (myExtension && Object.keys(myExtension).length > 0 && firstrender) {
      petition();
    }
  }, [myExtension]);

  return (
    <>
      <div onClick={handleBackClick} className="itp-tex-back-container">
        <div className="itp-text-container-funnel-details">
          <img src={arrowleft} alt="arrow-left" />
          <span className="itp-text-back-funnel-details">
            Back to Dashboard
          </span>
        </div>
      </div>

      {loading ? (
        <Loading />
      ) : (
        <div style={{ paddingBottom: "100vh" }}>
          <div id="devices">
            <div className="itp-table-container-centered">
              <Row>
                <Col>
                  <div className="text-align-with-table">
                    <span className="table-alt-text-title">Devices</span>
                    <br></br>

                    <span className="table-alt-text-title-description">
                      Here is all the information related to devices integrated
                      with their respective SIP Credential details & You can
                      register the devices manually if you have a desk phone.
                    </span>
                  </div>

                  {dataTableUserDevices ? (
                    <CustomTable data={dataTableUserDevices} />
                  ) : (
                    <div className="no-data-message">No data available.</div>
                  )}
                </Col>
              </Row>
            </div>

            {isSipDetailsModalOpen && (
              <SipDetailsModal
                modal={isSipDetailsModalOpen}
                closeModal={closeSipDetailsModal}
                device={selectedSipDevice}
                myExtension={myExtension}
              />
            )}
          </div>
          <br></br>
          <br></br>
          <div>
            <Row>
              <Col>
                <div className="itp-user-settings-separator-line"></div>
              </Col>
            </Row>
          </div>

          {/* Section For Call Forwarding */}
          <br></br>
          <div id="callForwarding">
            <div className="itp-table-container-centered">
              <Row>
                <Col>
                  <div className="text-align-with-table">
                    <span className="table-alt-text-title">
                      Call Forwarding
                    </span>
                    <br></br>

                    <span className="table-alt-text-title-description">
                      Adjust call forwarding settings for direct calls or queue
                      calls
                    </span>
                  </div>

                  <Card className="itp-user-settings-card-col">
                    <CardBody>
                      <span className="itp-user-settings-card-title">
                        Enable Call Forwarding
                      </span>

                      <Row className="itp-user-settings-card-help-text">
                        <Col md={10} className="d-flex">
                          <div>
                            <img src={infoIcon} alt="myExtension-icon" />
                          </div>
                          <div className="ms-3">
                            <p>
                              Enabling this option will forward any incoming
                              calls to your user and will not ring any of your
                              devices, including the web phone
                            </p>
                          </div>
                        </Col>

                        <Col md={2}>
                          <div className="itp-custom-table-container-switchs d-flex align-items-center pl-0 justify-content-end">
                            <Switch
                              className="itp-custom-table-switch"
                              onColor="#626ed4"
                              height={20} // Ajusta la altura según tus necesidades
                              width={40}
                              checked={switchs.callforward_enable}
                              onChange={() =>
                                handleChangeSwitchCallForwarding(
                                  "callforward_enable"
                                )
                              }
                            />
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>

            {switchs.callforward_enable && (
              <div style={{ maxWidth: "991px", margin: "auto" }}>
                <Row>
                  <Col>
                    <Card className="itp-user-settings-card-col">
                      <CardBody>
                        <span className="itp-user-settings-card-title">
                          Forward the call to:
                        </span>

                        <Row className="itp-user-settings-card-help-text">
                          <Col md={10} className="d-flex">
                            <div>
                              <img src={infoIcon} alt="myExtension-icon" />
                            </div>
                            <div className="ms-3">
                              <p>
                                You can enter an extension number or a phone
                                numbr to forward calls to
                              </p>
                            </div>
                          </Col>

                          <Col md={2}>
                            <div className="itp-custom-table-container-switchs d-flex align-items-center pl-0 justify-content-end">
                              <input
                                type="text"
                                placeholder=""
                                className="form-control-itp"
                                name="numberCallForwarding"
                                value={numberCallForwarding}
                                onChange={(e) =>
                                  setNumberCallForwarding(e.target.value)
                                }
                              />
                            </div>
                          </Col>
                        </Row>

                        <span className="itp-user-settings-card-title">
                          Keep Original Caller ID
                        </span>

                        <Row className="itp-user-settings-card-help-text">
                          <Col md={10} className="d-flex">
                            <div>
                              <img src={infoIcon} alt="myExtension-icon" />
                            </div>
                            <div className="ms-3">
                              <p>
                                If enabled, this will show the caller's phone
                                number on your screen. If disabled, the number
                                that appears will either be the default caller
                                ID or your customized outbound caller ID number.
                              </p>
                            </div>
                          </Col>

                          <Col md={2}>
                            <div className="itp-custom-table-container-switchs d-flex align-items-center pl-0 justify-content-end">
                              <Switch
                                className="itp-custom-table-switch"
                                onColor="#626ed4"
                                height={20} // Ajusta la altura según tus necesidades
                                width={40}
                                checked={
                                  switchs.callforward_keep_caller_caller_id
                                }
                                onChange={() =>
                                  handleChangeSwitchCallForwarding(
                                    "callforward_keep_caller_caller_id"
                                  )
                                }
                              />
                            </div>
                          </Col>
                        </Row>

                        <span className="itp-user-settings-card-title">
                          Forward queue calls
                        </span>

                        <Row className="itp-user-settings-card-help-text">
                          <Col md={10} className="d-flex">
                            <div>
                              <img src={infoIcon} alt="myExtension-icon" />
                            </div>
                            <div className="ms-3">
                              <p>
                                If enabled, any queues you are a member of will
                                have calls forwarded. If disabled, queue calls
                                will not be forwarded and will only ring your
                                device or web phone.
                              </p>
                            </div>
                          </Col>

                          <Col md={2}>
                            <div className="itp-custom-table-container-switchs d-flex align-items-center pl-0 justify-content-end">
                              <Switch
                                className="itp-custom-table-switch"
                                onColor="#626ed4"
                                height={20} // Ajusta la altura según tus necesidades
                                width={40}
                                checked={switchs.callforward_queue_calls}
                                onChange={() =>
                                  handleChangeSwitchCallForwarding(
                                    "callforward_queue_calls"
                                  )
                                }
                              />
                            </div>
                          </Col>
                        </Row>

                        <span className="itp-user-settings-card-title">
                          Call screening
                        </span>

                        <Row className="itp-user-settings-card-help-text">
                          <Col md={10} className="d-flex">
                            <div>
                              <img src={infoIcon} alt="myExtension-icon" />
                            </div>
                            <div className="ms-3">
                              <p>
                                If enabled, when answering a forwarded call, a
                                message will prompt you to accept the call or
                                deny.
                              </p>
                            </div>
                          </Col>

                          <Col md={2}>
                            <div className="itp-custom-table-container-switchs d-flex align-items-center pl-0 justify-content-end">
                              <Switch
                                className="itp-custom-table-switch"
                                onColor="#626ed4"
                                height={20} // Ajusta la altura según tus necesidades
                                width={40}
                                checked={switchs.callforward_call_confirmation}
                                onChange={() =>
                                  handleChangeSwitchCallForwarding(
                                    "callforward_call_confirmation"
                                  )
                                }
                              />
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            )}
          </div>
          <div>
            <Row>
              <Col>
                <div className="itp-user-settings-separator-line"></div>
              </Col>
            </Row>
          </div>

          {/* Section For Caller ID Options */}
          <div id="callerID">
            <div className="itp-table-container-centered">
              <br></br>

              <Row>
                <Col>
                  <div className="text-align-with-table">
                    <span className="table-alt-text-title">Caller ID</span>
                    <br></br>

                    <span className="table-alt-text-title-description">
                      Adjust your outbound caller ID settings when placing calls
                    </span>
                  </div>

                  <Card className="itp-user-settings-card-col">
                    <CardBody>
                      <span className="itp-user-settings-card-title">
                        Override default Caller ID{" "}
                      </span>

                      <Row className="itp-user-settings-card-help-text">
                        <Col md={10} className="d-flex">
                          <div>
                            <img src={infoIcon} alt="myExtension-icon" />
                          </div>
                          <div className="ms-3">
                            <p>
                              Enabling this option will force your devices to
                              use a specific phone number when placing a call
                            </p>
                          </div>
                        </Col>

                        <Col md={2}>
                          <div className="itp-custom-table-container-switchs d-flex align-items-center pl-0 justify-content-end">
                            <Switch
                              className="itp-custom-table-switch"
                              onColor="#626ed4"
                              height={20} // Ajusta la altura según tus necesidades
                              width={40}
                              checked={switchs.switchOverrideDefaultCallerID}
                              onChange={() => handleChangeSwitchCallerID()}
                            />
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>

            {switchs.switchOverrideDefaultCallerID && (
              <div
                className="itp-user-settings-card-container-centered"
                style={{ width: "991px" }}
              >
                <Row>
                  <Col>
                    <Card className="itp-user-settings-card-col">
                      <CardBody>
                        <span className="itp-user-settings-card-title">
                          Caller ID Number:
                        </span>

                        <Row className="itp-user-settings-card-help-text">
                          <Col md={7} className="d-flex">
                            <div>
                              <img src={infoIcon} alt="myExtension-icon" />
                            </div>
                            <div className="ms-3">
                              <p>Select the number to use for outbound calls</p>
                            </div>
                          </Col>

                          <Col md={5}>
                            <div className="itp-custom-table-container-switchs d-flex align-items-center pl-0 justify-content-end">
                              <Select
                                onChange={(e) => {
                                  handleChangeNumberCallerID(e);
                                }}
                                className="basic-single switch-caller-id-number"
                                classNamePrefix="select"
                                value={valuenumberCallerID}
                                isSearchable={true}
                                name="contact_list_id"
                                options={numbersCallerId}
                              />
                            </div>
                          </Col>
                        </Row>

                        <span className="itp-user-settings-card-title">
                          Caller ID Name:
                        </span>

                        <Row className="itp-user-settings-card-help-text">
                          <Col md={9} className="d-flex">
                            <div>
                              <img src={infoIcon} alt="myExtension-icon" />
                            </div>
                            <div className="ms-3">
                              <p>
                                Type the name that appears when placing outbound
                                calls
                              </p>
                            </div>
                          </Col>

                          <Col md={3}>
                            <div className="itp-custom-table-container-switchs d-flex align-items-center pl-0 justify-content-end">
                              <input
                                type="text"
                                placeholder=""
                                className="form-control-itp"
                                name="contactname"
                                value={
                                  formCallerId.default_outbound_callerid_name
                                }
                                onChange={(e) => {
                                  setFormCallerId({
                                    ...formCallerId,
                                    default_outbound_callerid_name:
                                      e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            )}
          </div>

          <div>
            <Row>
              <Col>
                <div className="itp-user-settings-separator-line"></div>
              </Col>
            </Row>
          </div>

          {/* Section For Call Recording Options */}

          <div id="callRecordings" className="itp-table-container-centered">
            <br></br>

            <Row>
              <Col>
                <div className="text-align-with-table">
                  <span className="table-alt-text-title">Call Recording</span>
                  <br></br>

                  <span className="table-alt-text-title-description">
                    Adjust your call recording preferences
                  </span>
                </div>

                <Card className="itp-user-settings-card-col">
                  <CardBody>
                    <span className="itp-user-settings-card-title">
                      Internal Calls{" "}
                    </span>

                    <Row className="itp-user-settings-card-help-text">
                      <Col md={10} className="d-flex">
                        <div>
                          <img src={infoIcon} alt="myExtension-icon" />
                        </div>
                        <div className="ms-3">
                          <p>Record all internal calls (User to User)</p>
                        </div>
                      </Col>

                      <Col md={2}>
                        <div className="itp-custom-table-container-switchs d-flex align-items-center pl-0 justify-content-end">
                          <Switch
                            className="itp-custom-table-switch"
                            onColor="#626ed4"
                            height={20} // Ajusta la altura según tus necesidades
                            width={40}
                            checked={switchs?.call_recording_internal}
                            onChange={() =>
                              handleChangeSwitchCallRecording(
                                "call_recording_internal"
                              )
                            }
                          />
                        </div>
                      </Col>
                    </Row>

                    <span className="itp-user-settings-card-title">
                      External Calls{" "}
                    </span>

                    <Row className="itp-user-settings-card-help-text">
                      <Col md={10} className="d-flex">
                        <div>
                          <img src={infoIcon} alt="myExtension-icon" />
                        </div>
                        <div className="ms-3">
                          <p>
                            Record all external calls (Incoming and Outgoing)
                          </p>
                        </div>
                      </Col>

                      <Col md={2}>
                        <div className="itp-custom-table-container-switchs d-flex align-items-center pl-0 justify-content-end">
                          <Switch
                            className="itp-custom-table-switch"
                            onColor="#626ed4"
                            height={20} // Ajusta la altura según tus necesidades
                            width={40}
                            checked={switchs?.call_recording_external}
                            onChange={() =>
                              handleChangeSwitchCallRecording(
                                "call_recording_external"
                              )
                            }
                          />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>

          <div>
            <Row>
              <Col>
                <div className="itp-user-settings-separator-line"></div>
              </Col>
            </Row>
          </div>

          {/* Intercom / Paging Options */}

          <div id="intercom" className="itp-table-container-centered">
            <br></br>

            <Row>
              <Col>
                <div className="text-align-with-table">
                  <span className="table-alt-text-title">
                    Intercom / Paging
                  </span>
                  <br></br>

                  <span className="table-alt-text-title-description">
                    Intercom and Paging Settings
                  </span>
                </div>

                <Card className="itp-user-settings-card-col">
                  <CardBody>
                    <span className="itp-user-settings-card-title">
                      Intercom Calls{" "}
                    </span>

                    <Row className="itp-user-settings-card-help-text">
                      <Col md={10} className="d-flex">
                        <div>
                          <img src={infoIcon} alt="myExtension-icon" />
                        </div>
                        <div className="ms-3">
                          <p>
                            If enabled, any intercom attemps will automatically
                            be answered by your device if supported. Does not
                            work on Web Phone
                          </p>
                        </div>
                      </Col>

                      <Col md={2}>
                        <div className="itp-custom-table-container-switchs d-flex align-items-center pl-0 justify-content-end">
                          <Switch
                            className="itp-custom-table-switch"
                            onColor="#626ed4"
                            height={20} // Ajusta la altura según tus necesidades
                            width={40}
                            checked={switchs.switchIntercomCalls}
                            onChange={() =>
                              handleChangeOtherSwitch("switchIntercomCalls")
                            }
                          />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>

          <div>
            <Row>
              <Col>
                <div className="itp-user-settings-separator-line"></div>
              </Col>
            </Row>
          </div>

          {/* Fax Options*/}

          <div id="fax" className="itp-table-container-centered">
            <br></br>

            <Row>
              <Col>
                <div className="text-align-with-table">
                  <span className="table-alt-text-title">Faxing</span>
                  <br></br>

                  <span className="table-alt-text-title-description">
                    Adjust fax settings
                  </span>
                </div>

                <Card className="itp-user-settings-card-col">
                  <CardBody>
                    <span className="itp-user-settings-card-title">
                      Fax Notifications{" "}
                    </span>

                    <Row className="itp-user-settings-card-help-text">
                      <Col md={10} className="d-flex">
                        <div>
                          <img src={infoIcon} alt="myExtension-icon" />
                        </div>
                        <div className="ms-3">
                          <p>Send email when a fax has been received.</p>
                        </div>
                      </Col>

                      <Col md={2}>
                        <div className="itp-custom-table-container-switchs d-flex align-items-center pl-0 justify-content-end">
                          <Switch
                            className="itp-custom-table-switch"
                            onColor="#626ed4"
                            height={20} // Ajusta la altura según tus necesidades
                            width={40}
                            checked={
                              switchs.faxing_settings_enable_notifications
                            }
                            onChange={() =>
                              handleChangeOtherSwitch(
                                "faxing_settings_enable_notifications"
                              )
                            }
                          />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      )}
    </>
  );
};

export default UserSettings;
