import React, { useEffect, useState } from "react";
import { UserAgent, Registerer } from "sip.js";
import onInvite from "./onInvite"

function SipRegistration({ sipconfiguration, agent,setAgent, setInvitationSession, setCallSessionsFunctionEnd,callAudio,setCallAudio,ringtone,enablePhone }) {
  const [isMounted, setIsMounted] = useState(true);

  const registerSip = async () => {
    try {
      const uri = UserAgent.makeURI(`sip:${sipconfiguration.username}@${sipconfiguration.realm}`);
      const userAgentOptions = {
        authorizationPassword: sipconfiguration.password,
        authorizationUsername: sipconfiguration.username,
        transportOptions: sipconfiguration.transportOptions,
        delegate: {
          onInvite: (e) => { onInvite(e, setInvitationSession, setCallSessionsFunctionEnd,callAudio,setCallAudio,ringtone); },
          // onInvite: ()=>{console.log("lllllllllllllllllllllllllllllllllll")},
          onDisconnect: () => {
            // Manejar la desconexión aquí
            sipconfiguration.onUnregistered()
            // Intentar registrarse nuevamente después de un tiempo (por ejemplo, 5 segundos)
            setTimeout(() => {
              registerSip(); // Llamar a la función de registro nuevamente
            }, 5000); // Esperar 5 segundos antes de intentar de nuevo
          },
          onUnregistered: sipconfiguration.onUnregistered(),
        },
        uri,
      };

      const agent = new UserAgent(userAgentOptions);

      await agent.start();
      const registerer = new Registerer(agent);
      registerer.register();
      sipconfiguration.onRegistered()
      if (isMounted) {
        setAgent(agent);
      }
    } catch (error) {
      console.error("SIP registration failed:", error);
      // Manejar el error según sea necesario
    }
  };

  const unregisterSip = async () => {
    try {
      // Detener y desconectar el agente SIP
      await agent.stop();
      // Realizar cualquier otra limpieza necesaria

      // setIsRegistered(false);
    } catch (error) {
      console.error("SIP unregistration failed:", error);
      // Manejar el error según sea necesario
    }
  };

  useEffect(()=>{
    if(enablePhone){
      registerSip()
    }else{
      unregisterSip()
    }
  },[enablePhone])

  useEffect(() => {
    registerSip();

    return () => {
      setIsMounted(false);
    };
  }, [sipconfiguration, setAgent]);

  return null;
}

export default SipRegistration;