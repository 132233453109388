import ringbacktone from "../assets/audio/ringbacktone.mp3";
import { setupRemoteMedia, cleanupMedia } from "./media"
import {
  UserAgent,
  Inviter,
  SessionState
} from "sip.js";

const rinding = new Audio(ringbacktone);
rinding.loop = true;



function call(number, sipconfiguration, setCallSessions, agent, callSessions, callAudio,sessionIncall,handleAnswerOnInvite,setCallSessionsFunctionEnd,conference) {

  if (callSessions.length < sipconfiguration.maxCalls) {
    console.log("1111111111111111")
    const target = UserAgent.makeURI(`sip:${number}@${sipconfiguration.realm}`);
    const inviter = new Inviter(agent, target);
    // inviter.onAccept = (inviteResponse) => { console.log("11111111111111111111111w",inviteResponse) }

    inviter.invite();

    inviter.stateChange.addListener((state) => {

      console.log(`Sessions Call Session state changed to ${state}`);
      switch (state) {
        case SessionState.Initial:
          break;
        case SessionState.Establishing:
          rinding.play();
          break;
        case SessionState.Established:

          rinding.pause();
          setCallSessions([...callSessions, inviter]);
          handleAnswerOnInvite(inviter,sessionIncall.length -1,true,conference)
          setupRemoteMedia([...callSessions, inviter], callAudio);
          break;
        case SessionState.Terminating:
        // fall through
        case SessionState.Terminated:
          rinding.pause();
          // setIsRinding(false)
          setCallSessionsFunctionEnd(inviter,true)
          // callSessions.forEach((element, i) => {
          //   if (element.request.callId === inviter.request.callId) {
          //     callSessions.splice(i, 1);
          //   }
          // });
          if (callSessions.length === 0) {
            cleanupMedia(callSessions, callAudio)
            // setCallSessions([])
          }

          break;
        default:
          throw new Error("Unknown session state.");
      }
    });
  } else {
    console.log("zzzzzzzzzzzzz")
    console.log(callSessions.length)
    console.log(sipconfiguration.maxCalls)
    console.log(number)

  }

}





export default call 