import React, { Fragment, useEffect, useState } from "react";
import {
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import data from "@emoji-mart/data";
import {
  convertLocalTimeToTimezoneTime,
  formatHourof24at12,
} from "../../../utils/getDate";
import { ToggleImage, AudioMessage } from "../../index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  // faCopy,
  // faPencilAlt,
  // faThumbtack,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import Picker from "@emoji-mart/react";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
// import happyFace from "../../../assets/icons/happyFace.svg";
// import writing from "../../../assets/icons/writing.svg";
import copy from "../../../assets/icons/copy.svg";
import json from "../../../config.json";
import callReceived from "../../../assets/icons/callReceived.svg";
import callMinus from "../../../assets/icons/callMinus.svg";
import callOutgoing from "../../../assets/icons/callOutgoing.svg";
import "../ChatWindow.css";
import { MarginTwoTone } from "@mui/icons-material";

const ChatWindowContent = ({
  messagesContainerRef,
  messages,
  timeZone,
  chatWindowData,
  activeModalDelete,
  loading,
  getMoreMessages,
  noHover,
}) => {
  const api_id = JSON.parse(localStorage.getItem("userDetails")).api_id;
  const token = JSON.parse(localStorage.getItem("userDetails")).access_token;
  const urlBase = json.prod ? json.urlBase.prod : json.urlBase.dev;
  const [openPicker, setOpenPicker] = useState(null);
  const [openDropdownMessage, setOpenDropdownMessage] = useState(false);
  const [showFullScreen, setShowFullScreen] = useState(null);
  const [lastVisibleMessageId, setLastVisibleMessageId] = useState(null);
  const [stickySeparator, setStickySeparator] = useState(null);

  const selectorImage = {
    missed: callMinus,
    answeredIn: callReceived,
    answeredOut: callOutgoing,
  };

  const handleClick = (src) => {
    setShowFullScreen(src);
  };

  const handleClose = () => {
    setShowFullScreen(null);
  };

  const dateComparision = (dateString) => {
    if (dateString !== "") {
      const currentDate = convertLocalTimeToTimezoneTime(timeZone);
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth() + 1;
      const currentDay = currentDate.getDate();
      const currentDateSpliter = `${currentYear}-${currentMonth}-${currentDay}`;

      const providedDate = dateString.split(" ")[0];
      const providedYear = providedDate.split("-")[0];
      const providedMonth = providedDate.split("-")[1];
      const providedDay = providedDate.split("-")[2];

      const daysDifference = Math.floor(
        (Date.parse(currentDateSpliter) - Date.parse(providedDate)) /
          (1000 * 60 * 60 * 24)
      );

      let result;

      if (daysDifference === 0) {
        result = "Today";
      } else if (daysDifference === 1) {
        result = "Yesterday";
      } else if (daysDifference > 1 && daysDifference <= 7) {
        // Si es mayor a 1 y menor o igual a 7, determina el nombre del día de la semana
        const dayNames = [
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
          "Sunday",
        ];
        const dayOfWeek = dayNames[new Date(providedDate).getDay()];
        result = dayOfWeek;
      } else {
        result = `${providedDay}/${providedMonth}/${providedYear}`;
      }

      return result;
    } else {
      return "";
    }
  };

  const returnSeparator = (element, i, array) => {
    if (element.message_timestamp) {
      let messageTimestamp = element.message_timestamp
        .split(" ")[0]
        .replaceAll("-", "/");

      if (i === 0) {
        return (
          <div className="itp-chat-window-content__container-separator">
            <div className="itp-chat-window-content__line-separator"></div>
            <label className="itp-chat-window-content__text-separator">
              {dateComparision(element?.message_timestamp)}
            </label>
            <div className="itp-chat-window-content__line-separator"></div>
          </div>
        );
      } else {
        if (
          array[i - 1].message_timestamp.split(" ")[0].replaceAll("-", "/") ===
            messageTimestamp ||
          messageTimestamp === ""
        ) {
          return <></>;
        } else {
          return (
            <div className="itp-chat-window-content__container-separator">
              <div className="itp-chat-window-content__line-separator"></div>
              <label className="itp-chat-window-content__text-separator">
                {dateComparision(element?.message_timestamp)}
              </label>
              <div className="itp-chat-window-content__line-separator"></div>
            </div>
          );
        }
      }
    }
  };

  const toggleMessage = (event, index) => {
    event.stopPropagation();
    setOpenDropdownMessage(index === openDropdownMessage ? null : index);
  };

  const handleCopyEmail = (event, messageBody) => {
    event.stopPropagation();
    navigator.clipboard
      .writeText(messageBody)
      .then(() => {})
      .catch((error) => {
        console.error(error);
      });
  };

  const detectLinks = (message) => {
    const linkPattern = /(https?:\/\/[^\s]+)/g;
    const parts = message.split(linkPattern);

    return parts.map((part, index) =>
      part.match(linkPattern) ? (
        <a key={index} href={part} target="_blank" rel="noopener noreferrer">
          {part}
        </a>
      ) : (
        part
      )
    );
  };

  const calculateMessageHeight = (messageIndex) => {
    const messageElement = messagesContainerRef.current.querySelector(
      `#message-${messageIndex}`
    );
    return messageElement ? messageElement.clientHeight : 0;
  };

  // Calculate the scroll position based on the height of messages
  const calculateScrollPosition = (messageIndex) => {
    let scrollPosition = 0;
    for (let i = 0; i < messageIndex; i++) {
      scrollPosition += calculateMessageHeight(i);
    }
    return scrollPosition;
  };

  useEffect(() => {
    if (messages && messages.length > 20) {
      // Load new messages and update the state

      // Find the index of the last visible message among the new messages
      const newIndex = messages.findIndex(
        (message) => message.pk === lastVisibleMessageId
      );

      // Calculate the new scroll position to maintain the last visible message's position
      const newScrollPosition = calculateScrollPosition(newIndex);

      // Scroll the chat window to the new position
      messagesContainerRef.current.scrollTop = newScrollPosition;
    }
  }, [messages, lastVisibleMessageId]);

  useEffect(() => {
    const handleScroll = async () => {
      if (
        messagesContainerRef.current &&
        messagesContainerRef.current.scrollTop === 0
      ) {
        // Save the current last visible message's id
        const lastVisibleId = messages[0]?.pk;
        setLastVisibleMessageId(lastVisibleId);

        await getMoreMessages();
      }

      if (messagesContainerRef.current) {
        const separators = Array.from(
          messagesContainerRef.current.querySelectorAll(
            ".itp-chat-window-content__container-separator"
          )
        );

        for (let i = 0; i < separators.length - 1; i++) {
          const separator = separators[i];
          const nextSeparator = separators[i + 1];
          const rect = separator.getBoundingClientRect();
          const nextRect = nextSeparator.getBoundingClientRect();

          if (rect.bottom > 0 && rect.top < window.innerHeight) {
            separator.classList.add("sticky");

            if (nextRect.top > window.innerHeight) {
              nextSeparator.classList.remove("sticky");
            }
          } else {
            separator.classList.remove("sticky");
          }
        }
      }
    };

    if (messagesContainerRef.current) {
      messagesContainerRef.current.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (messagesContainerRef.current) {
        messagesContainerRef.current.removeEventListener(
          "scroll",
          handleScroll
        );
      }
    };
  }, [messages]);

  return (
    <>
      <div ref={messagesContainerRef} className="itp-chat-window-content">
        {loading && (
          <div className="itp-chat-window__container-text-loading">
            <span className="itp-chat-window__text-loading">Loading...</span>
          </div>
        )}

        <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
          {messages.map((element, i) => (
            <Fragment key={i}>
              {returnSeparator(element, i, messages)}
              {element.message_participant === chatWindowData.numberChat ? (
                <>
                  <div
                    id={`message-${i}`}
                    className="itp-chat-window-content__other-message-container"
                  >
                    {openPicker === i && (
                      <div className="picker-container-left">
                        <Picker
                          data={data}
                          onEmojiSelect={(e) => {
                            setOpenPicker(null);
                            console.log(e);
                          }}
                          previewPosition="none"
                          theme="light"
                          skinTonePosition="none"
                          set="Apple"
                        />
                      </div>
                    )}
                    <div className="itp-chat-window-content__other-message-content">
                      {element.message_body && element.item_type !== "call" && !element.audio_message && (
                        <p className="itp-chat-window-content__message message">
                          {/* {element.message_body} */}
                          {detectLinks(element.message_body)}
                        </p>
                      )}

                      {element.message_mms_media &&
                        element.item_type !== "call" && !element.audio_message && (
                          <img
                            src={`${urlBase}/itpvoice/v2/${api_id}/my-extension/chat/media/${element.message_mms_media}?token=${token}`}
                            className="img-fluid itp-chat-window-footer-img"
                            onClick={() =>
                              handleClick(element.message_mms_media)
                            }
                          />
                        )}

                      {element.url && element.item_type !== "call" && !element.audio_message && (
                        <img
                          src={element.url}
                          className="img-fluid itp-chat-window-footer-img"
                        />
                      )}

                      {element.item_type === "call" && !element.audio_message && (
                        <div className="itp-chat-window-content__download-message-content">
                          <div className="itp-chat-window-content__download-message-icon-text">
                            <img src={selectorImage[element.selectImage]} />
                            <div className="d-flex flex-column">
                              <p className="itp-chat-window-content__message mt-1">
                                {element.status}
                              </p>
                              <p
                                style={{ fontSize: "11px" }}
                                className="itp-chat-window-content__message mt-1"
                              >
                                {element.message_body} {element?.duration || ""}
                              </p>
                            </div>
                          </div>
                        </div>
                      )}

{element.audio_message &&
                        element.item_type !== "call" && (
                          <AudioMessage
                            audioUrl={element.audio_message}
                            duration={element.voicemail_duration}
                            firstname={chatWindowData.isContact ? chatWindowData.firstname : false}
                            lastname={chatWindowData.isContact ? chatWindowData.lastname : false}
                            avatar={chatWindowData.isContact}
                          />
                        )}

                      {showFullScreen &&
                        showFullScreen === element?.message_mms_media &&
                        element.item_type !== "call" && !element.audio_message && (
                          <div
                            className="container-img-full-screen"
                            onClick={handleClose}
                          >
                            <img
                              src={`${urlBase}/itpvoice/v2/${api_id}/my-extension/chat/media/${element.message_mms_media}?token=${token}`}
                              alt="FullScreen"
                              className="img-full-screen"
                            />
                          </div>
                        )}

                      <span className="itp-chat-window__message-date">
                        {!element?.sending ? (
                          formatHourof24at12(element?.message_timestamp)
                        ) : (
                          <TimerOutlinedIcon
                            style={{ width: "16px", height: "16px" }}
                          />
                        )}
                      </span>
                    </div>
                    {!noHover && element.item_type !== "call" && (
                      <div className="itp-chat-window-content__you-message-hover">
                        {/* <div>
                                            <img
                                              src={happyFace}
                                              alt="Face"
                                              onClick={() => setOpenPicker(i)}
                                              className="itp-chat-window-content__you-item-hover"
                                            />
                                          </div> */}

                        {/* <div className="itp-chat-window-content__you-item-hover-container">
                                            <img
                                              src={writing}
                                              alt="Writing"
                                              className="itp-chat-window-content__you-item-hover"
                                            />
                                            <div className="itp-chat-window-content__you-item-name-hover-container">
                                              <span className="itp-chat-window-content__you-item-name-hover">
                                                Reply to internal thread
                                              </span>
                                            </div>
                                          </div> */}
                        {!element.audio_message && (
                          <div
                            onClick={(event) =>
                              handleCopyEmail(event, element.message_body)
                            }
                            className="itp-chat-window-content__you-item-hover-container"
                          >
                            <img
                              src={copy}
                              alt="Copy"
                              className="itp-chat-window-content__you-item-hover"
                            />
                            <div className="itp-chat-window-content__you-item-name-hover-container">
                              <span className="itp-chat-window-content__you-item-name-hover">
                                Copy Message
                              </span>
                            </div>
                          </div>
                        )}

                        <Dropdown
                          className="itp-chat-windiow__header-show-profile-ropdown"
                          isOpen={i === openDropdownMessage}
                          toggle={(event) => toggleMessage(event, i)}
                        >
                          <DropdownToggle className="nav-link">
                            <ToggleImage />
                          </DropdownToggle>
                          <DropdownMenu>
                            {/* <DropdownItem
                                                onClick={() => console.log("Item 1")}
                                                className="dropdown-item"
                                              >
                                                <FontAwesomeIcon icon={faThumbtack} /> Pin Message
                                              </DropdownItem> */}
                            <DropdownItem
                              onClick={() => activeModalDelete(element)}
                              className="dropdown-item"
                            >
                              <FontAwesomeIcon icon={faTrash} /> Delete Message
                            </DropdownItem>
                            {/* <DropdownItem
                                                onClick={() => console.log("Item 3")}
                                                className="dropdown-item"
                                              >
                                                <FontAwesomeIcon icon={faCopy} /> Mention
                                              </DropdownItem>
                                              <DropdownItem
                                                onClick={() => console.log("Item 4")}
                                                className="dropdown-item"
                                              >
                                                <FontAwesomeIcon icon={faPencilAlt} /> Pin Message
                                              </DropdownItem> */}
                          </DropdownMenu>
                        </Dropdown>
                      </div>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <div
                    id={`message-${i}`}
                    className="itp-chat-window-content__you-message-container"
                  >
                    {!noHover && element.item_type !== "call" && (
                      <>
                        <div className="itp-chat-window-content__you-message-hover">
                          {/* <div>
                        <img
                          src={happyFace}
                          alt="Face"
                          onClick={() => setOpenPicker(i)}
                          className="itp-chat-window-content__you-item-hover"
                        />
                      </div> */}

                          {/* <div className="itp-chat-window-content__you-item-hover-container">
                        <img
                          src={writing}
                          alt="Writing"
                          className="itp-chat-window-content__you-item-hover"
                        />
                        <div className="itp-chat-window-content__you-item-name-hover-container">
                          <span className="itp-chat-window-content__you-item-name-hover">
                            Reply to internal thread
                          </span>
                        </div>
                      </div> */}

                          {!element.audio_message && (
                            <div
                              onClick={(event) =>
                                handleCopyEmail(event, element.message_body)
                              }
                              className="itp-chat-window-content__you-item-hover-container"
                            >
                              <img
                                src={copy}
                                alt="Copy"
                                className="itp-chat-window-content__you-item-hover"
                              />
                              <div className="itp-chat-window-content__you-item-name-hover-container">
                                <span className="itp-chat-window-content__you-item-name-hover">
                                  Copy Message
                                </span>
                              </div>
                            </div>
                          )}

                          <Dropdown
                            className="itp-chat-windiow__header-show-profile-ropdown"
                            isOpen={i === openDropdownMessage}
                            toggle={(event) => toggleMessage(event, i)}
                          >
                            <DropdownToggle className="nav-link">
                              <ToggleImage />
                            </DropdownToggle>
                            <DropdownMenu>
                              {/* <DropdownItem
                            onClick={() => console.log("Item 1")}
                            className="dropdown-item"
                          >
                            <FontAwesomeIcon icon={faThumbtack} /> Pin Message
                          </DropdownItem> */}
                              <DropdownItem
                                onClick={() => activeModalDelete(element)}
                                className="dropdown-item"
                              >
                                <FontAwesomeIcon icon={faTrash} /> Delete Message
                              </DropdownItem>
                              {/* <DropdownItem
                            onClick={() => console.log("Item 3")}
                            className="dropdown-item"
                          >
                            <FontAwesomeIcon icon={faCopy} /> Mention
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => console.log("Item 4")}
                            className="dropdown-item"
                          >
                            <FontAwesomeIcon icon={faPencilAlt} /> Pin Message
                          </DropdownItem> */}
                            </DropdownMenu>
                          </Dropdown>
                        </div>
                        {openPicker === i && (
                          <div className="picker-container">
                            <Picker
                              data={data}
                              onEmojiSelect={(e) => {
                                setOpenPicker(null);
                                console.log(e);
                              }}
                              previewPosition="none"
                              theme="light"
                              skinTonePosition="none"
                              set="Apple"
                            />
                          </div>
                        )}
                      </>
                    )}

                    <div className="itp-chat-window-content__you-message-content">
                      {element.message_body && element.item_type !== "call" && !element.audio_message && (
                        <p className="itp-chat-window-content__message message">
                          {/* {element.message_body} */}
                          {detectLinks(element.message_body)}
                        </p>
                      )}

                      {element.message_mms_media &&
                        element.item_type !== "call" && !element.audio_message && (
                          <img
                            src={`${urlBase}/itpvoice/v2/${api_id}/my-extension/chat/media/${element.message_mms_media}?token=${token}`}
                            className="img-fluid itp-chat-window-footer-img"
                            onClick={() =>
                              handleClick(element.message_mms_media)
                            }
                          />
                        )}

                      {element.url && element.item_type !== "call" && !element.audio_message && (
                        <img
                          src={element.url}
                          className="img-fluid itp-chat-window-footer-img"
                        />
                      )}

                      {element.audio_message &&
                        element.item_type !== "call" && (
                          <AudioMessage
                            audioUrl={element.audio_message}
                            duration={element.voicemail_duration}
                            firstname={chatWindowData.isContact ? chatWindowData.firstname : false}
                            lastname={chatWindowData.isContact ? chatWindowData.lastname : false}
                            avatar={chatWindowData.isContact}
                          />
                        )}

                      {element.item_type === "call" && !element.audio_message && (
                        <div className="itp-chat-window-content__download-message-content">
                          <div className="itp-chat-window-content__download-message-icon-text">
                            <img src={selectorImage[element.selectImage]} />
                            <div className="d-flex flex-column">
                              <p className="itp-chat-window-content__message mt-1">
                                {element.status}
                              </p>
                              <p
                                style={{ fontSize: "11px" }}
                                className="itp-chat-window-content__message mt-1"
                              >
                                {element.message_body} {element?.duration || ""}
                              </p>
                            </div>
                          </div>
                        </div>
                      )}

                      {showFullScreen &&
                        showFullScreen === element?.message_mms_media &&
                        element.item_type !== "call" && !element.audio_message && (
                          <div
                            className="container-img-full-screen"
                            onClick={handleClose}
                          >
                            <img
                              src={`${urlBase}/itpvoice/v2/${api_id}/my-extension/chat/media/${element.message_mms_media}?token=${token}`}
                              alt="FullScreen"
                              className="img-full-screen"
                            />
                          </div>
                        )}

                      <span className="itp-chat-window__message-date">
                        {!element?.sending ? (
                          formatHourof24at12(element?.message_timestamp)
                        ) : (
                          <TimerOutlinedIcon
                            style={{ width: "16px", height: "16px" }}
                          />
                        )}
                      </span>
                    </div>
                  </div>
                </>
              )}
            </Fragment>
          ))}
        </Col>
      </div>
      {/* <div style={{marginTop:"10px"}}>
        <div className="p-2" style={{boxShadow:"#000 0px 0px 2px", borderRadius:"4px"}}>
          <p style={{opacity:"0.5"}}>Write a message.</p>
          <hr/>
          <div className="mt-4" style={{width:"auto", height:"auto"}}>
						<div className="ms-3 d-flex">
              <div>
                <button style={{border:"none", backgroundColor:"white"}} onClick={()=>{document.getElementById('message-box').style.display="block";}}>
                  <img src="message-text-1.svg"></img>
                </button>
              </div>
              <div className="w-100">
                <input id="message-part" className="w-100" onClick={()=> {document.getElementById('message-part').style.border="none"}} style={{border:"none"}}></input>
              </div>
						</div>
					</div>
          <div className="p-2 messagetemplate-property" id="message-box" style={{marginLeft:"-7px", width:"500px", height:"300px", position:"absolute", bottom:"135px", borderRadius:"4px", backgroundColor:"#fffffc", opacity:"0.5", display:"none", boxShadow:"#000 0px 1px 3px"}}>
            <div>
              <input type="search" className="w-100 mt-2 ps-2" style={{borderRadius:"20px", backgroundColor:"#fffffe"}}></input>
            </div>
            <hr></hr>
            <div></div>
          </div>
        </div> 
      </div> */}
    </>
  );
};

export default ChatWindowContent;
