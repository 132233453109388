import React, { useEffect, useState } from "react";
import { UncontrolledCollapse } from "reactstrap";
import PersonIcon from "@mui/icons-material/Person";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import "../LivePanel.css";

const Timer = ({ initialTime }) => {
  const [currentTime, setCurrentTime] = useState(new Date(initialTime * 1000));

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime((prevTime) => new Date(prevTime.getTime() + 1000));
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return <>{currentTime.toISOString().slice(11, 19)}</>;
};

const Queues = ({ queues, users, totalUsers }) => {
  const [arrowDirection, setArrowDirection] = useState({});
  const [dataUsers, setDataUsers] = useState([])

  const returnUserInQueue = (pk) => {
    let user = totalUsers.find((element) => element?.pk == pk);
    return `${user?.firstname} ${user?.lastname || ""} (${user?.presence_id})`;
  };

  const getData = (pk) => {
    let user = dataUsers.find((element) => element.pk == pk);

    if (user) {
      if (user?.channels.length === 0) return "";

      if (user?.channels.length > 1) return "Conference";
      
      if (user?.channels[0]?.terminated) {
        return (
          <>
            Call Terminated <Timer initialTime={user.channels[0].time} />
          </>
        )
      };

      if (!user?.channels[0]?.answered) return "Calling...";

      return <Timer initialTime={user.channels[0].time} />;
    } else {
      return "";
    }
  };

  const selectColor = (pk) => {
    let user = dataUsers.find((element) => element.pk == pk);
    if (user) {
      let conferenceActive = false;

      if (user.channels.length !== 0) {
        dataUsers.map((elementTwo, i) => {
          if (elementTwo.channels.length > 1) {
            elementTwo.channels.map((elementThree, index) => {
              if (
                user.presence_id === elementThree.callee_id_number ||
                user.presence_id === elementThree.caller_id_number
              ) {
                conferenceActive = true;
              }
            });
          }
        });
      }
      const type = `${
        user.channels.length === 0
          ? "READY"
          : user.channels.length > 1 || conferenceActive
          ? "conference"
          : user.channels[0].answered
      }`;

      const selectColor = () => {
        if (user.devices_registered === false) return "#c8c8c8";
        else if (type === "READY") return "#199F28";
        else if (type === "true" || type === "false" || type === "conference")
          return "#D60C0C";
      };

      const color = selectColor();

      return color;
    } else {
      return "#c8c8c8";
    }
  };

  useEffect(() => {
    if (users) setDataUsers(users)
  }, [users])

  useEffect(() => {
    if (queues) {
      let newValues = arrowDirection;
      queues.forEach((element) => {
        newValues[element.name] = arrowDirection[element?.name] || false;
      });

      setArrowDirection(newValues);
    }
  }, [queues]);

  return (
    <>
      <div className="d-flex flex-column">
        {queues.map((element, i) => (
          <div key={i} className="itp-livePanel-queue-card">
            <div className="itp-livePanel-queue-div-container">
              <div
                color="primary"
                id={`ITPToggle${i}`}
                className={`d-flex justify-content-between${
                  arrowDirection[element.name] === true
                    ? " queue-change-background"
                    : ""
                }`}
                onClick={() => {
                  setArrowDirection({
                    ...arrowDirection,
                    [element.name]: !arrowDirection[element.name],
                  });
                }}
              >
                <span className="me-4 itp-livePanel-queue-name">{`${element.name} ${element.queue_description}`}</span>
                <div className="itp-livePanel-queue-toggle-collapse">
                  <label className="itp-livePanel-queue-label-collapse">
                    Call status
                  </label>
                  {arrowDirection[element.name] === true ? (
                    <KeyboardArrowUpIcon className="itp-livePanel-queue-icon-collapse" />
                  ) : (
                    <KeyboardArrowDownIcon className="itp-livePanel-queue-icon-collapse" />
                  )}
                </div>
              </div>
              <UncontrolledCollapse
                toggler={`#ITPToggle${i}`}
                id="test"
                className="itp-livePanel-queue-collapse"
              >
                {element.members.map((el, j) => (
                  <div className="d-flex justify-content-between" key={j}>
                    <div className="mb-1">
                      <span
                        className="text-align-center"
                        style={{
                          color: selectColor(el.membername),
                        }}
                      >
                        <PersonIcon style={{ marginBottom: "5px" }} />
                        {returnUserInQueue(el.membername)}
                      </span>
                    </div>
                    <div className="mt-1">
                      <span>{getData(el.membername)}</span>
                    </div>
                  </div>
                ))}
              </UncontrolledCollapse>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default Queues;
