import React, { useState, useMemo } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPlus, faThumbTack, faSquarePen, faClockRotateLeft } from "@fortawesome/free-solid-svg-icons";
import { useMenuDispatch } from "../../../../contexts/MenuContext.js";
import {
  CustomTable,
  Loading,
} from "../../../../components/index.js";
import arrowleft from "../../../../assets/icons/arrow-left.svg";
import "./WorkFlows.css";

export default function WorkFlows() {
  const [loading, setLoadingState] = useState(false);
	const [dataWorkFlows, setDataWorkFlows] = useState(null);
	const navigate = useNavigate();
  const dispatch = useMenuDispatch();
	const [centered, setCenter] = useState(false);
	const initWorkFlows = [
		{
			inherit: "false",
			my_work_flows: "New SMS when contact created",
			status: "Draft",
		},
		{
			inherit: "false",
			my_work_flows: "Testing webflow",
			status: "Published",
		},
		{
			inherit: "false",
			my_work_flows: "Send Email when contact created",
			status: "Published",
		},
		{
			inherit: "true",
			my_work_flows: "Workflow Folder2",
			status: "-",
		}
	];

	const handleBackClick = () => {
		dispatch({ type: "SET_DEFAULT_MENU" });
		navigate("/Dashboard");
  };

	useMemo(() => {
		setDataWorkFlows({
			title: [
				{ title: "My Work flows", key: "my_work_flows" },
				{ title: "Status", key: "status" },				
			],
			content: [...initWorkFlows],
			actions: {
				title: "Actions",
				content: [
					{
						icon: <FontAwesomeIcon icon={faThumbTack} style={{rotate:"45deg"}} />,
						name: "Pin Work form",
						handleClick: ()=>{},
					},
					{
						icon: <FontAwesomeIcon icon={faTrash} />,
						name: "Delete",
						handleClick: ()=>{},
					},
					{
						icon: <FontAwesomeIcon icon={faSquarePen} />,
						name: "Edit Workflow",
						handleClick: ()=>{},
					},
					{
						icon: <FontAwesomeIcon icon={faClockRotateLeft} />,
						name: "History",
						handleClick: ()=>{},
					},
				],
			}
	})
	}, []);

  return (
    <div>
			<div onClick={handleBackClick} className="itp-tex-back-container">
        <div className="itp-text-container-funnel-details">
          <img src={arrowleft} alt="arrow-left" />
          <span className="itp-text-back-funnel-details">
            Back to Dashboard
          </span>
        </div>
      </div>

			{loading ? (
        <Loading />
      ) : (
				<>
					<div id="forms">
						<div className="container" style={{maxWidth:"981px"}}>
							<div>
								<h2 className="h2-color workflows-letter-property">Work Flows</h2>
								<div className="d-flex">
									<div className="flex-grow-1 pe-5">
										<p className="text-align-justify" style={{color:"#3a3a3acc"}}>Create & manage your workflows to automate basic tasks within the system.	</p>
									</div>
									<div className="d-flex align-items-center">
										<button
											className={`btn-border centered m-0 hover-property`}
										>
											<FontAwesomeIcon className="itp-icon-plus" />{" "}
											Create Folder
										</button>
									</div>
									<div className="d-flex align-items-center">
										<button
											className={`btn-primary itp-add-button centered m-0`}
										>
											<FontAwesomeIcon icon={faPlus} className="itp-icon-plus" />{" "}
											Create Work flow
										</button>
									</div>
								</div>
								<div className="phone-numbers-container-search-input d-flex search-property" style={{width:"auto"}}>
									<SearchIcon className="itp-custom-table-search-icon" />
									<input
										className="itp-custom-table-search-input search-property"
										placeholder="Search Workflows"
									/>
								</div>
							</div>
						</div>
					</div>
					<div style={{maxWidth:"991px"}} className="m-auto table">
								<CustomTable
									centered={false}
									data={dataWorkFlows}
									page="spec"
									noPagBack={true}
								/>
					</div>
				</>
			)};
		</div>
  )
}



