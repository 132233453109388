import React, { useEffect, useState } from "react";
import petitionGet from "../../../../../../../services/petitionGet";
import { Loading, CustomTable } from "../../../../../../../components";
import "../Import.css";

const Jobs = () => {
  const [loading, setLoading] = useState(true);
  const [dataTable, setDataTable] = useState(null);
  const [dataTableError, setDataTableError] = useState(null);
  const [infoContactError, setInfoContactError] = useState(null);

  const showContactsWithError = (e) => {
    setInfoContactError(e);
  };

  const petitionContactsWithError = () => {
    setLoading(true);
    petitionGet("importJobsErrors", { jobs_id: infoContactError.pk })
      .then(({ data: result }) => {
        if(
          result.result.data.contacts_not_imported &&
          result.result.data.contacts_not_imported.length > 0
        ) {
          let dataContactsError = Object.keys(
            result.result.data.contacts_not_imported[0].csv_contact
          );
  
          dataContactsError.map((element) => {
            result.result.data.contacts_not_imported.map((contact) => {
              contact[element] = !contact.csv_contact[element]
                ? ""
                : contact.csv_contact[element];
            });
          });
  
          setDataTableError({
            title: [
              { title: "Reason", key: "reason" },
              { title: "First Name", key: "firstname" },
              { title: "Last Name", key: "lastname" },
              { title: "Phone", key: "phone" },
              { title: "Email", key: "email" },
            ],
            content: [...result.result.data.contacts_not_imported],
          });
        } else {
          setDataTableError({
            title: [
              { title: "Reason", key: "reason" },
              { title: "First Name", key: "firstname" },
              { title: "Last Name", key: "lastname" },
              { title: "Phone", key: "phone" },
              { title: "Email", key: "email" },
            ],
            content: [],
          });
        }

        setLoading(false);
      })
      .catch((error) => console.log(error));
  };

  const back = () => {
    setLoading(true)
    setInfoContactError(null)
    setDataTableError(null)
  }

  const petition = () => {
    setLoading(true);
    petitionGet("importJobs")
      .then(({ data: result }) => {
        setDataTable({
          title: [
            { title: "Status", key: "status" },
            { title: "Start Date", key: "job_start_date", isDate: true },
            { title: "Imported", key: "imported" },
            { title: "Not Imported", key: "not_imported" },
          ],
          content: [...result.result],
          actions: {
            title: "Actions",
            content: [
              {
                name: "View Results",
                handleClick: showContactsWithError,
              },
            ],
          },
        });
        setLoading(false);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if (infoContactError) {
      petitionContactsWithError();
    }
  }, [infoContactError]);

  useEffect(() => {
    if(!infoContactError){
      petition();
    }
  }, [infoContactError]);

  return loading ? (
    <Loading />
  ) : (
    <>
      {infoContactError && dataTableError ? (
        <>
          <div className="itp-jobs-container-table">
          <CustomTable data={dataTableError} noPagBack={true} textNoData="Contacts With Error" />
          </div>
          <button className="btn-light itp-button-jobs-error" onClick={back}>
            Back
          </button>
        </>
      ) : (
        <>
          <div className="itp-jobs-container-table">
            <CustomTable data={dataTable} noPagBack={true} />
          </div>
        </>
      )}
    </>
  );
};

export default Jobs;
