import React, { useEffect, useState } from "react";
import { Card } from "reactstrap";
import "./CallParkingSlots.css";

const CallParkingSlots = ({ parkingCall }) => {
  const [dataParkingCall, setDataParkingCall] = useState(null);

  const selectColor = (element) => {
    if (element.callerid_num) {
      return "#D60C0C";
    } else {
      return "#199F28";
    }
  };

  const selectName = (element) => {
    if (element.callerid_num) {
      return "Busy";
    } else {
      return "Available";
    }
  };

  useEffect(() => {
    if (parkingCall.length > 0) setDataParkingCall(parkingCall);
  }, [parkingCall]);

  return (
    dataParkingCall && (
      <>
        <Card className="important itp-table-container-all">
          <div>
            <h1 className="itp-custom-table-title-card">Call Parking slots</h1>

            <p className="itp-custom-table-subtitle-card">
              Slots visible siwhe
            </p>

            <table className="itp-custom-table">
              <thead>
                <tr className="itp-custom-table-thead-styles">
                  <th className="itp-custom-table-th-styles">
                    Parking Slot No
                  </th>
                  <th className="itp-custom-table-th-styles">Caller ID</th>
                  <th className="itp-custom-table-th-styles">Status</th>
                </tr>
              </thead>
              <tbody>
                {dataParkingCall.map((element, i) => (
                  <tr className="itp-custom-table-row" key={i}>
                    <td
                      style={{
                        borderLeft: `6px solid ${selectColor(element)}`,
                      }}
                      className="itp-custom-table-td-styles"
                    >
                      {element.slot_number}
                    </td>
                    <td className="itp-custom-table-td-styles">
                      {element.callerid_num}
                    </td>
                    <td
                      style={{ color: selectColor(element) }}
                      className="itp-custom-table-td-styles"
                    >
                      {selectName(element)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Card>
      </>
    )
  );
};

export default CallParkingSlots;
