import React from "react";
import { Row, Col } from "reactstrap";

const Step4 = ({ form, setForm }) => {
  return (
    <>
      <h3>Final Questions</h3>
      <Row className="itp-container-label-input">
        <Col
          xs={5}
          sm={5}
          md={5}
          lg={5}
          xl={5}
          xxl={5}
          className="itp-label-input-col"
        >
          <label className="itp-label-input__label">Opt-in Keywords</label>
        </Col>
        <Col
          xs={7}
          sm={7}
          md={7}
          lg={7}
          xl={7}
          xxl={7}
          className="itp-label-input-col"
        >
          <input
            type="text"
            placeholder=": Enter Opt-in Keywords"
            className="form-control-itp itp-label-input__input"
            onChange={(e) =>
              setForm({
                ...form,
                optinKeywords: e.target.value.replaceAll(" ", ","),
              })
            }
            value={form.optinKeywords}
          />
        </Col>
      </Row>
      <Row className="itp-container-label-select">
        <Col
          xs={5}
          sm={5}
          md={5}
          lg={5}
          xl={5}
          xxl={5}
          className="itp-label-input-col"
        >
          <label className="itp-label-input__label">
            Will you be sending Phone Numbers in your messages??
          </label>
        </Col>
        <Col
          xs={7}
          sm={7}
          md={7}
          lg={7}
          xl={7}
          xxl={7}
          className="itp-label-input-col"
        >
          <select
            className="form-select-itp"
            onChange={(e) =>
              setForm({
                ...form,
                embeddedPhone: e.target.value === "true" ? true : false,
              })
            }
            value={form.embeddedPhone}
          >
            <option value={false}>No</option>
            <option value={true}>Yes</option>
          </select>
        </Col>
      </Row>
      <Row className="itp-container-label-input">
        <Col
          xs={5}
          sm={5}
          md={5}
          lg={5}
          xl={5}
          xxl={5}
          className="itp-label-input-col"
        >
          <label className="itp-label-input__label">Opt-out Keywords</label>
        </Col>
        <Col
          xs={7}
          sm={7}
          md={7}
          lg={7}
          xl={7}
          xxl={7}
          className="itp-label-input-col"
        >
          <input
            type="text"
            placeholder=": Enter Opt-out Keywords"
            className="form-control-itp itp-label-input__input"
            onChange={(e) =>
              setForm({
                ...form,
                optoutKeywords: e.target.value.replaceAll(" ", ","),
              })
            }
            value={form.optoutKeywords}
          />
        </Col>
      </Row>
      <Row className="itp-container-label-select">
        <Col
          xs={5}
          sm={5}
          md={5}
          lg={5}
          xl={5}
          xxl={5}
          className="itp-label-input-col"
        >
          <label className="itp-label-input__label">
            Will you be sending any Age Restricted Content?
          </label>
        </Col>
        <Col
          xs={7}
          sm={7}
          md={7}
          lg={7}
          xl={7}
          xxl={7}
          className="itp-label-input-col"
        >
          <select
            className="form-select-itp"
            onChange={(e) =>
              setForm({
                ...form,
                ageGated: e.target.value === "true" ? true : false,
              })
            }
            value={form.ageGated}
          >
            <option value={false}>No</option>
            <option value={true}>Yes</option>
          </select>
        </Col>
      </Row>
      <Row className="itp-container-label-input">
        <Col
          xs={5}
          sm={5}
          md={5}
          lg={5}
          xl={5}
          xxl={5}
          className="itp-label-input-col"
        >
          <label className="itp-label-input__label">Help Keywords</label>
        </Col>
        <Col
          xs={7}
          sm={7}
          md={7}
          lg={7}
          xl={7}
          xxl={7}
          className="itp-label-input-col"
        >
          <input
            type="text"
            placeholder=": Enter Help Keywords"
            className="form-control-itp itp-label-input__input"
            onChange={(e) =>
              setForm({
                ...form,
                helpKeywords: e.target.value.replaceAll(" ", ","),
              })
            }
            value={form.helpKeywords}
          />
        </Col>
      </Row>
      <Row className="itp-container-label-select">
        <Col
          xs={5}
          sm={5}
          md={5}
          lg={5}
          xl={5}
          xxl={5}
          className="itp-label-input-col"
        >
          <label className="itp-label-input__label">
            Please confirm this compaign will not be used for Affiliate
            Marketing
          </label>
        </Col>
        <Col
          xs={7}
          sm={7}
          md={7}
          lg={7}
          xl={7}
          xxl={7}
          className="itp-label-input-col"
        >
          <select
            className="form-select-itp"
            onChange={(e) =>
              setForm({
                ...form,
                affiliateMarketing: e.target.value === "true" ? true : false,
              })
            }
            value={form.affiliateMarketing}
          >
            <option value={false}>No</option>
            <option value={true}>Yes</option>
          </select>
        </Col>
      </Row>
      <Row className="itp-container-label-input">
        <Col
          xs={5}
          sm={5}
          md={5}
          lg={5}
          xl={5}
          xxl={5}
          className="itp-label-input-col"
        >
          <label className="itp-label-input__label">Help Message</label>
        </Col>
        <Col
          xs={7}
          sm={7}
          md={7}
          lg={7}
          xl={7}
          xxl={7}
          className="itp-label-input-col"
        >
          <input
            type="text"
            placeholder=": Enter Help Message"
            className="form-control-itp itp-label-input__input"
            onChange={(e) =>
              setForm({
                ...form,
                helpMessage: e.target.value,
              })
            }
            value={form.helpMessage}
          />
        </Col>
      </Row>
      <Row className="itp-container-label-select">
        <Col
          xs={5}
          sm={5}
          md={5}
          lg={5}
          xl={5}
          xxl={5}
          className="itp-label-input-col"
        >
          <label className="itp-label-input__label">
            UWill you be sending Links (URLs) in your messages?
          </label>
        </Col>
        <Col
          xs={7}
          sm={7}
          md={7}
          lg={7}
          xl={7}
          xxl={7}
          className="itp-label-input-col"
        >
          <select
            className="form-select-itp"
            onChange={(e) =>
              setForm({
                ...form,
                embeddedLink: e.target.value === "true" ? true : false,
              })
            }
            value={form.embeddedLink}
          >
            <option value={false}>No</option>
            <option value={true}>Yes</option>
          </select>
        </Col>
      </Row>
      <Row className="itp-container-label-input">
        <Col
          xs={5}
          sm={5}
          md={5}
          lg={5}
          xl={5}
          xxl={5}
          className="itp-label-input-col"
        >
          <label className="itp-label-input__label">Output Message</label>
        </Col>
        <Col
          xs={7}
          sm={7}
          md={7}
          lg={7}
          xl={7}
          xxl={7}
          className="itp-label-input-col"
        >
          <input
            type="text"
            placeholder=": Enter Output Message"
            className="form-control-itp itp-label-input__input"
            onChange={(e) =>
              setForm({
                ...form,
                optoutMessage: e.target.value,
              })
            }
            value={form.optoutMessage}
          />
        </Col>
      </Row>
      <Row className="itp-container-label-select">
        <Col
          xs={5}
          sm={5}
          md={5}
          lg={5}
          xl={5}
          xxl={5}
          className="itp-label-input-col"
        >
          <label className="itp-label-input__label">
            Do you offer Direct Lending or Lean Arrangement Services ?
          </label>
        </Col>
        <Col
          xs={7}
          sm={7}
          md={7}
          lg={7}
          xl={7}
          xxl={7}
          className="itp-label-input-col"
        >
          <select
            className="form-select-itp"
            onChange={(e) =>
              setForm({
                ...form,
                directLending: e.target.value === "true" ? true : false,
              })
            }
            value={form.directLending}
          >
            <option value={false}>No</option>
            <option value={true}>Yes</option>
          </select>
        </Col>
      </Row>
    </>
  );
};

export default Step4;
