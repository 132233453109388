import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { LoadingButton, Loading } from "../../../../../components";
import PhoneInput from "react-phone-input-2";
import Select from "react-select";
import petitionGet from "../../../../../services/petitionGet";
import "../AddLead.css";

const AddContactAndLead = ({
  loadingAddLead,
  addNewContactAndLead,
  funnels,
  leadSource
}) => {
  const [valueContactList, setValueContactList] = useState(null);
  const [valueLeadSource, setValueLeadSource] = useState(null);
  const [valueFunnel, setValueFunnel] = useState(null);
  const [valueStage, setValueStage] = useState(null);
  const [loading, setLoading] = useState(true);
  const [contactList, setContactList] = useState([]);
  const [stages, setStages] = useState([]);
  const [loadingChangeFunnel, setLoadingChangeFunnel] = useState(false);
  const [form, setForm] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    contact_list_id: "",
    lead_source_id: "",
    funnel_id: "",
    funnel_pipeline_stage_id: "",
  });

  const onChangeForm = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const onChangeFunnel = (e) => {
    setLoadingChangeFunnel(true);
    petitionGet("stages", { parameter: `?funnel_id=${e.pk}` })
      .then(({ data: result }) => {
        if (result.result.length > 0) {
          result.result.forEach((stage) => {
            stage.label = stage.name;
            stage.value = stage.pk;
          });

          setStages(result.result);
        }
        setForm({ ...form, funnel_id: e.pk, funnel_pipeline_stage_id: "" });
        setValueFunnel(e);
        setValueStage(null);
        setLoadingChangeFunnel(false);
      })
      .catch((error) => console.log(error));
  };

  const petition = () => {
    setLoading(true);
    petitionGet("contactList", {})
      .then(({ data: result }) => {
        result.result.forEach((element) => {
          element.label = `${element.list_name} (${element.contact_count})`;
          element.value = element.pk;
        });

        setForm({ ...form, contact_list_id: result.result[0].pk });
        setValueContactList(result.result[0]);
        setContactList(result.result);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    petition();
  }, []);

  return loading ? (
    <Loading />
  ) : (
    <>
      <Row className="itp-container-label-input">
        <Col
          xs={5}
          sm={5}
          md={5}
          lg={5}
          xl={5}
          xxl={5}
          className="itp-label-input-col"
        >
          <label className="itp-label-input__label">First Name</label>
        </Col>
        <Col
          xs={7}
          sm={7}
          md={7}
          lg={7}
          xl={7}
          xxl={7}
          className="itp-label-input-col"
        >
          <input
            type="text"
            placeholder=": Add a first name of contact"
            className="form-control-itp itp-label-input__input"
            onChange={onChangeForm}
            name="firstname"
          />
        </Col>
      </Row>
      <Row className="itp-container-label-input">
        <Col
          xs={5}
          sm={5}
          md={5}
          lg={5}
          xl={5}
          xxl={5}
          className="itp-label-input-col"
        >
          <label className="itp-label-input__label">Last Name</label>
        </Col>
        <Col
          xs={7}
          sm={7}
          md={7}
          lg={7}
          xl={7}
          xxl={7}
          className="itp-label-input-col"
        >
          <input
            type="text"
            placeholder=": Add a last name of contact"
            className="form-control-itp itp-label-input__input"
            onChange={onChangeForm}
            name="lastname"
          />
        </Col>
      </Row>
      <Row className="itp-container-label-input">
        <Col
          xs={5}
          sm={5}
          md={5}
          lg={5}
          xl={5}
          xxl={5}
          className="itp-label-input-col"
        >
          <label className="itp-label-input__label">Email</label>
        </Col>
        <Col
          xs={7}
          sm={7}
          md={7}
          lg={7}
          xl={7}
          xxl={7}
          className="itp-label-input-col"
        >
          <input
            type="text"
            placeholder=": Enter a email address"
            className="form-control-itp itp-label-input__input"
            onChange={onChangeForm}
            name="email"
          />
        </Col>
      </Row>
      <div className="add-lead-container-field">
        <label className="itp-label-input__label">Phone Number</label>
        <PhoneInput
          disableCountryFlags={true}
          enableSearch={true}
          countryCodeEditable={false}
          country={"us"}
          placeholder="Enter a Phone Number"
          className="itp-add-lead-new-contact"
          onChange={(e) => {
            setForm({ ...form, phone: `+${e}` });
          }}
        />
      </div>
      <div className="add-lead-container-field">
        <label className="itp-label-input__label">Contact List</label>
        <Select
          onChange={(e) => {
            setForm({ ...form, contact_list_id: e.value });
            setValueContactList(e);
          }}
          className="basic-single add-lead-select"
          classNamePrefix="select"
          value={valueContactList}
          isSearchable={true}
          name="contact_list_id"
          placeholder="Choose Contact List"
          options={contactList}
        />
      </div>
      <div className="add-lead-container-field">
        <label className="itp-label-input__label">Lead Source</label>
        <Select
          onChange={(e) => {
            setForm({ ...form, lead_source_id: e.value });
            setValueLeadSource(e);
          }}
          className="basic-single add-lead-select"
          classNamePrefix="select"
          value={valueLeadSource}
          isSearchable={true}
          name="lead_source_id"
          placeholder="Choose lead source"
          options={leadSource}
        />
      </div>
      <div className="add-lead-container-field">
        <label className="itp-label-input__label">Funnel</label>
        <Select
          onChange={(e) => onChangeFunnel(e)}
          className="basic-single add-lead-select"
          classNamePrefix="select"
          value={valueFunnel}
          isSearchable={true}
          name="funnel_id"
          placeholder="Choose a funnel"
          options={funnels}
        />
      </div>
      {loadingChangeFunnel ? (
        <Loading />
      ) : (
        <>
          {form.funnel_id && (
            <>
              {stages.length > 0 ? (
                <div className="add-lead-container-field">
                  <label className="itp-label-input__label">Funnel Stage</label>
                  <Select
                    onChange={(e) => {
                      setForm({ ...form, funnel_pipeline_stage_id: e.value });
                      setValueStage(e);
                    }}
                    className="basic-single add-lead-select"
                    classNamePrefix="select"
                    value={valueStage}
                    isSearchable={true}
                    name="funnel_pipeline_stage_id"
                    placeholder="Choose funnel stage"
                    options={stages}
                  />
                </div>
              ) : (
                <p>There are no stages created for this funnel</p>
              )}
            </>
          )}
        </>
      )}

      <button
        className="btn-primary loading add-lead-new-contact"
        disabled={
          !form.firstname ||
          !form.contact_list_id ||
          !form.lead_source_id ||
          !form.funnel_id ||
          !form.funnel_pipeline_stage_id
        }
        onClick={() => addNewContactAndLead(form)}
      >
        {loadingAddLead && <LoadingButton />}
        Save
      </button>
    </>
  );
};

export default AddContactAndLead;
