import React, { useEffect, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPlus, faThumbTack } from "@fortawesome/free-solid-svg-icons";
import { useMenuDispatch } from "../../../../contexts/MenuContext.js";
import {
  CustomTable,
  Loading,
} from "../../../../components/index.js";
import arrowleft from "../../../../assets/icons/arrow-left.svg";
import "./Forms.css";

export default function Forms() {
  const [loading, setLoadingState] = useState(false);
	const [dataTableForms, setDataTableForms] = useState(null);
	const navigate = useNavigate();
  	const dispatch = useMenuDispatch();
	const [centered, setCenter] = useState(false);
	const initForms = [
		{
			page: "form",
			form_name: "3hk-47kl-8923",
			created_on: "May 17, 2023",
			status: "enabled",
		},
		{
			page: "form",
			form_name: "3hk-47kl-8923",
			created_on: "May 17, 2023",
			status: "enabled",
		}
	];

	const page = "form";

	const handleBackClick = () => {
		dispatch({ type: "SET_DEFAULT_MENU" });
		navigate("/Dashboard");
  };

	useMemo(() => {
		setDataTableForms({
			title: [
				{ title: "Form Name", key: "form_name" },
				{ title: "Created on", key: "created_on"},
				{ title: "Status", key: "status" },				
			],
			content: [...initForms],
			actions: {
				title: "Actions",
				content: [
					{
						icon: <FontAwesomeIcon icon={faThumbTack} style={{rotate:"45deg"}} />,
						name: "Pin form",
						handleClick: ()=>{},
					},
					{
						icon: <FontAwesomeIcon icon={faTrash} />,
						name: "Delete form",
						handleClick: ()=>{},
					},
				],
			},
			noActions: true,
	})
	}, []);

  return (
    <div>
			<div onClick={handleBackClick} className="itp-tex-back-container">
        <div className="itp-text-container-funnel-details">
          <img src={arrowleft} alt="arrow-left" />
          <span className="itp-text-back-funnel-details">
            Back to Dashboard
          </span>
        </div>
      </div>

			{loading ? (
        <Loading />
      ) : (
				<>
					<div id="forms">
						<div className="container" style={{maxWidth:"981px"}}>
							<h2 className="form-letter-property">Form</h2>
							<div className="d-flex">
								<div className="flex-grow-1 pe-5">
									<p className="text-align-justify" style={{color:"#3a3a3acc"}}>Forms Allow you to create Web Forms that can be integrated with existing
									websites to gather leads. Forms will be assigned to a specific funnel and
									stage and will create leads automatically.</p>
								</div>
								<div className="d-flex align-items-center">
									<button
										className={`btn-primary itp-add-button centered m-0`}
									>
										<FontAwesomeIcon icon={faPlus} className="itp-icon-plus" />{" "}
										Add New Form
									</button>
								</div>
							</div>
						</div>
					</div>
					<div>
							<CustomTable
								centered={false}
								page={page}
								data={dataTableForms}
								noPagBack={true}
							/>
					</div>
				</>
			)}

		</div>
  )
}
