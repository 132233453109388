import * as types from "../types";

export function postLogin(data) {
  return {
    type: types.POST_LOGIN_REQUESTED,
    data,
  };
}

export const logoutRequest = () => {
  return {
    type: types.LOGOUT_REQUEST,
  };
};

export const logoutSuccess = () => {
  return {
    type: types.LOGOUT_SUCCESS,
  };
};
