import React, { useEffect, useState } from "react";
import { Loading } from "../../../../../../components";
import petitionGet from "../../../../../../services/petitionGet";
import Select from "react-select";
import "./Modules.css";

const Queues = ({ saveModule, edit, data, editModule }) => {
  const [queues, setQueues] = useState([]);
  const [loading, setLoading] = useState(true);
  const [valueQueue, setValueQueue] = useState(null);

  const petition = () => {
    setLoading(true);
    petitionGet("queues")
      .then(({ data: result }) => {
        result.result.forEach((element) => {
          element.label = element.queue_name;
          element.value = element.pk;
        });

        if (edit) {
          const defaultQueue = result.result.find((element) => element.pk === data.data.id)
          setValueQueue(defaultQueue)
        }

        setQueues(result.result);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  };

  const saveQueue = () => {
    const sendData = {
      id: valueQueue.pk
    }

    if (edit) {
      editModule(sendData, "queue")
    } else {
      saveModule(sendData, "queue")
    }
  }

  useEffect(() => {
    if (data) petition();
  }, [data]);

  return loading ? (
    <Loading />
  ) : (
    <>
      <div className="itp-callFlow-container-module">
        <label className="itp-label-input__label mb-2">Queue</label>
        <Select
          onChange={(e) => setValueQueue(e)}
          className="basic-single"
          classNamePrefix="select"
          value={valueQueue}
          isSearchable={true}
          name="queue"
          placeholder="Select a Queue"
          options={queues}
        />
        <button
          onClick={saveQueue}
          className="btn-primary mt-4"
          disabled={valueQueue ? false : true}
        >
          {!edit ? "Save" : "Edit"}
        </button>
      </div>
    </>
  );
};

export default Queues;
