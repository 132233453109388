import React, { useEffect, useState } from 'react';
import { Tabs, Tab, Button } from "react-bootstrap";
import Switch from "react-switch";

import "./Settings.css"


const Settings = ({ devices, setVolume, volume, sessionIncall, setCloseSettings, callAudio, setCallAudio, changeRingtoneVolume, setEnablePhone,enablePhone }) => {
    const [enableWebphone, setEnableWebphone] = useState(true)
    const [enablecallWaiting, setEnableCallwaiting] = useState(false)
    // const [callAudio] = useState(new Audio());

    // console.log("----------------", devices)

    const onChangeAudioInput = async (audioInput) => {
        if (audioInput) {
            const audioSource = audioInput;
            const constraints = {
                audio: { deviceId: audioSource ? { exact: audioSource } : "undefined" },
                video: false,
            };
            console.log(constraints)
            try {
                if (sessionIncall.length !== 0) {

                    const stream = await navigator.mediaDevices.getUserMedia(constraints);

                    gotStream(stream);
                } else await navigator.mediaDevices.getUserMedia(constraints);
            } catch (error) {
                console.log(error);
            }
        }
    }

    function gotStream(stream) {
        sessionIncall.forEach((element) => {
            element.sessionDescriptionHandler.peerConnection
                .getSenders()[0]
                .replaceTrack(stream.getAudioTracks()[0]);
        });
        return navigator.mediaDevices?.enumerateDevices();
    }

    const onChangeAudio = async (sinkId) => {
        console.log(sinkId)
        if (typeof callAudio.sinkId !== "undefined") {
            callAudio
                .setSinkId(sinkId)
                .then(() => {
                    console.log(`Success, audio output device attached: ${sinkId}`);
                })
                .catch((error) => {
                    let errorMessage = error;
                    if (error.name === "SecurityError") {
                        errorMessage = `You need to use HTTPS for selecting audio output device: ${error}`;
                    }
                    console.error(errorMessage);
                    // state.audioOutputSelect.selectedIndex = 0;
                });
        } else {
            console.warn("Browser does not support output device selection.");
        }
    }



    return (
        <div className="audio-settings">
            <h3>Audio</h3>
            <br></br>

            <Tabs defaultActiveKey={1} id="uncontrolled-tab-example">
                <Tab eventKey={1} title="General">
                    <div>
                        <br></br>

                        {enableWebphone ? "Enable Web Phone" : "Disable Web Phone"}
                        <br></br>
                        <br></br>

                        <div className="itp-custom-table-container-switchs d-flex align-items-center pl-0">

                            <Switch
                                className="itp-custom-table-switch"
                                onColor="#626ed4"
                                height={20} // Ajusta la altura según tus necesidades
                                width={40}
                                checked={enablePhone}
                                onChange={() => {
                                    setEnableWebphone(!enableWebphone)
                                    setEnablePhone(!enablePhone)
                                }
                                }
                            />
                        </div>
                    </div>
                </Tab>
                <Tab eventKey={2} title="Audio">
                    <br></br>

                    <div className='selects-audio-settings'>
                        <label htmlFor="">Inputs Audio:</label>
                        <select
                            onChange={(e) => onChangeAudioInput(e.target.value)}
                            disabled={false}
                            className="form-select-itp"
                        >
                            {devices.input.map((element, i) => (
                                <option key={i} value={element.deviceId}>
                                    {" "}
                                    {element.label}{" "}
                                </option>
                            ))}{" "}
                        </select>
                    </div>
                    <br></br>

                    <div className='selects-audio-settings'>
                        <label htmlFor="">Outputs Audio:</label>
                        <select onChange={(e) => onChangeAudio(e.target.value)} className="form-select-itp">
                            {devices.output.map((element, i) => (
                                <option key={i} value={element.deviceId}>
                                    {" "}
                                    {element.label}{" "}
                                </option>
                            ))}{" "}
                        </select>
                    </div>

                    <br></br>

                    <div>
                        <label className="w-100" htmlFor="">
                            Ringer Volume:
                        </label>
                        <input
                            type="range"
                            onChange={(e) => {
                                setVolume(e.target.value);
                                changeRingtoneVolume(e.target.value)
                                // updateVolumen(parseInt(e.target.value));
                            }}
                            value={volume}
                            color="primary"
                            style={{
                                height: "3px",
                                padding: "0",
                                margin: "0",
                                width: "200px",
                            }}
                        />
                    </div>

                </Tab>
                <Tab eventKey={3} title="Enable Call Waiting" >
                    <div>
                        <br></br>

                        Enable Call Waiting
                        <br></br>
                        <br></br>

                        <div className="itp-custom-table-container-switchs d-flex align-items-center pl-0">

                            <Switch
                                className="itp-custom-table-switch"
                                onColor="#626ed4"
                                height={20} // Ajusta la altura según tus necesidades
                                width={40}
                                checked={enablecallWaiting}
                                onChange={() =>
                                    setEnableCallwaiting(!enablecallWaiting)
                                }
                            />
                        </div>
                    </div>
                </Tab>
            </Tabs>
            <br></br>
            <br></br>
            <div className='audio-settings-close'>
                <button className="btn-light" onClick={() => { setCloseSettings(true) }}>Close</button>

            </div>
        </div>
    );
};

export default Settings;
