import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGripVertical, faPlus } from "@fortawesome/free-solid-svg-icons";
import { Col, Row } from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { Loading, LoadingButton } from "../../../../components";
import deleteIcon from "../../../../assets/icons/deleteIcon.svg";
import petitionGet from "../../../../services/petitionGet";
import petitionDelete from "../../../../services/petitionDelete";
import petitionPost from "../../../../services/petitionPost";
import petitionPatch from "../../../../services/petitionPatch";
import "./Stages.css";

const Stages = ({ funnelInfo, backFunction }) => {
  const [stages, setStages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [value, setValue] = useState({});
  const [existStateWon, setExistStateWon] = useState(false);
  const [existStateLost, setExistStateLost] = useState(false);
  const [existStateLead, setExistStateLead] = useState(false);
  const [deletedStagesList, setDeletedStagesList] = useState([]);
  const [loadingButton, setLoadingButton] = useState(false);
  const [stepAddStage, setStepAddStage] = useState(1);
  const [newFormStage, setNewFormState] = useState({
    name: "",
    status: "",
  });

  const onChangeSelect = (e, val, newStage) => {
    let newValueFormStagesExist = value;
    let newForm = newFormStage;

    if (newStage) {
      newForm = {
        ...newFormStage,
        [e.target.name]: val,
      };
    } else {
      newValueFormStagesExist = {
        ...value,
        [e.target.name]: val,
      };
    }

    Object.keys(newValueFormStagesExist).forEach((key) => {
      if (
        key !== e.target.name &&
        newValueFormStagesExist[key] === "active is_won" &&
        val === "active is_won"
      ) {
        newValueFormStagesExist[key] = "";
      } else if (
        key !== e.target.name &&
        newValueFormStagesExist[key] === "active is_lost" &&
        val === "active is_lost"
      ) {
        newValueFormStagesExist[key] = "";
      } else if (
        key !== e.target.name &&
        newValueFormStagesExist[key] === "active is_lead" &&
        val === "active is_lead"
      ) {
        newValueFormStagesExist[key] = "";
      }
    });

    let existWonStagesExist = Object.values(newValueFormStagesExist).some(
      (content) => content === "active is_won"
    );

    let existLostStagesExist = Object.values(newValueFormStagesExist).some(
      (content) => content === "active is_lost"
    );

    let existLeadStagesExist = Object.values(newValueFormStagesExist).some(
      (content) => content === "active is_lead"
    );

    let existWonInNewStage = Object.values(newForm).some(
      (content) => content === "active is_won"
    );

    let existLostInNewStage = Object.values(newForm).some(
      (content) => content === "active is_lost"
    );

    let existLeadInNewStage = Object.values(newForm).some(
      (content) => content === "active is_lead"
    );

    if (existWonStagesExist || existWonInNewStage) {
      setExistStateWon(true);
    } else {
      setExistStateWon(false);
    }

    if (existLostStagesExist || existLostInNewStage) {
      setExistStateLost(true);
    } else {
      setExistStateLost(false);
    }

    if (existLeadStagesExist || existLeadInNewStage) {
      setExistStateLead(true);
    } else {
      setExistStateLead(false);
    }

    if (newStage) {
      setNewFormState(newForm);
    } else {
      let resetNewForm = {};
      Object.keys(newForm).forEach((key) => {
        resetNewForm[key] = "";
      });
      setValue(newValueFormStagesExist);
    }
  };

  const onChangeinput = (e) => {
    setValue({ ...value, [e.target.name]: e.target.value });
  };

  const removedStagesList = (stage) => {
    let newSetValue = {};
    let newStages = stages.filter((element) => element.pk !== stage.pk);
    let newDeleted = deletedStagesList;

    if (!stage.added_frontend) {
      newDeleted.push(stage);
    }

    newStages.sort(compare).map((stage) => {
      newSetValue = {
        ...newSetValue,
        [`value_${stage.name}_${stage.pk}`]: value[
          `value_${stage.name}_${stage.pk}`
        ]
          ? value[`value_${stage.name}_${stage.pk}`]
          : stage.name,
        [`select_${stage.name}_${stage.pk}`]:
          value[`select_${stage.name}_${stage.pk}`] === "active is_won" ||
          value[`select_${stage.name}_${stage.pk}`] === "active is_lost" ||
          value[`select_${stage.name}_${stage.pk}`] === "active is_lead" ||
          value[`select_${stage.name}_${stage.pk}`] === ""
            ? value[`select_${stage.name}_${stage.pk}`]
            : stage.is_won
            ? "active is_won"
            : stage.is_lost
            ? "active is_lost"
            : stage.is_lead
            ? "active is_lead"
            : "",
      };
    });

    let existWon = Object.values(newSetValue).some(
      (content) => content === "active is_won"
    );

    let existLost = Object.values(newSetValue).some(
      (content) => content === "active is_lost"
    );

    let existLead = Object.values(newSetValue).some(
      (content) => content === "active is_lead"
    );

    if (!existWon) setExistStateWon(false);
    if (!existLost) setExistStateLost(false);
    if (!existLead) setExistStateLead(false);

    setValue(newSetValue);
    setStages(newStages);
    setDeletedStagesList(newDeleted);
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    const copyIems = [...stages];
    const [draggedItem] = copyIems.splice(source.index, 1);
    copyIems.splice(destination.index, 0, draggedItem);

    copyIems.map((stage, key) => {
      stage.stage_position = key;
    });

    setStages(copyIems);
  };

  const compare = (a, b) => {
    if (a.stage_position < b.stage_position) {
      return -1;
    }
    if (a.stage_position > b.stage_position) {
      return 1;
    }
    return 0;
  };

  const newStage = () => {
    if (newFormStage.name !== "") {
      const identifier = uuidv4();
      let newStages = stages;
      let newSetValue = {};

      const biggerNumber = newStages.reduce((higher, obj) => {
        return obj.stage_position > higher ? obj.stage_position : higher;
      }, 0);

      newStages.push({
        ...newFormStage,
        name: newFormStage.name,
        is_won: newFormStage.status === "active is_won" ? true : false,
        is_lost: newFormStage.status === "active is_lost" ? true : false,
        is_lead: newFormStage.status === "active is_lead" ? true : false,
        added_frontend: true,
        pk: identifier,
        id: identifier,
        stage_position: biggerNumber + 1,
        default_stage_position: biggerNumber + 1,
      });

      newStages.sort(compare).map((stage) => {
        newSetValue = {
          ...newSetValue,
          [`value_${stage.name}_${stage.pk}`]: value[
            `value_${stage.name}_${stage.pk}`
          ]
            ? value[`value_${stage.name}_${stage.pk}`]
            : stage.name,
          [`select_${stage.name}_${stage.pk}`]:
            value[`select_${stage.name}_${stage.pk}`] === "active is_won" ||
            value[`select_${stage.name}_${stage.pk}`] === "active is_lost" ||
            value[`select_${stage.name}_${stage.pk}`] === "active is_lead" ||
            value[`select_${stage.name}_${stage.pk}`] === ""
              ? value[`select_${stage.name}_${stage.pk}`]
              : stage.is_won
              ? "active is_won"
              : stage.is_lost
              ? "active is_lost"
              : stage.is_lead
              ? "active is_lead"
              : "",
        };
      });

      setStages(newStages);
      setValue(newSetValue);

      resetFormNewStage(newSetValue);
      selectStepAddStage("back");
    }
  };

  const resetFormNewStage = (currentForm) => {
    let existWon = Object.values(currentForm).some(
      (content) => content === "active is_won"
    );

    let existLost = Object.values(currentForm).some(
      (content) => content === "active is_lost"
    );

    let existLead = Object.values(currentForm).some(
      (content) => content === "active is_lead"
    );

    if (!existWon) setExistStateWon(false);
    if (!existLost) setExistStateLost(false);
    if (!existLead) setExistStateLead(false);

    setNewFormState({
      name: "",
      status: "",
    });
  };

  const lessThanThreeStages = () => {
    let jsxArray = [];
    let difference = 3 - stages.length;
    for (let i = 0; i < difference; i++) {
      jsxArray.push(
        <Row
          key={i}
          style={{ marginBottom: "12px" }}
          className="d-flex align-items-center"
        >
          <Col className="d-flex justify-content-between align-items-center">
            <div className="d-flex justify-content-between">
              <input disabled type="text" className="form-control-itp" />
              <FontAwesomeIcon
                className="itp-stages-drag-icon"
                icon={faGripVertical}
              />
            </div>
          </Col>
          <Col style={{ flex: "0 0 auto", width: "4%", visibility: "hidden" }}>
            <img
              src={deleteIcon}
              alt="Delete"
              className="itp-stages-delete-icon"
            />
          </Col>
          <Col style={{ visibility: "hidden" }}>
            <select className="form-select-itp" disabled>
              <option value="">Select Status</option>
              <option value="active is_won">Won</option>
              <option value="active is_lost">Lost</option>
              <option value="active is_lead">Lead</option>
            </select>
          </Col>
        </Row>
      );
    }

    return jsxArray;
  };

  const selectStepAddStage = (selector) => {
    if (selector === "next") {
      setStepAddStage(stepAddStage + 1);
    } else if (selector === "back") {
      setStepAddStage(stepAddStage - 1);
    }
  };

  const editStage = (stage, i) => {
    let data = {
      name: value[`value_${stage.name}_${stage.pk}`],
      is_won:
        value[`select_${stage.name}_${stage.pk}`] === "active is_won"
          ? true
          : false,
      is_lead:
        value[`select_${stage.name}_${stage.pk}`] === "active is_lead"
          ? true
          : false,
      is_lost:
        value[`select_${stage.name}_${stage.pk}`] === "active is_lost"
          ? true
          : false,
      stage_position: stage.stage_position,
    };
    petitionPatch("stage", {
      funnel_id: funnelInfo.pk,
      stage_id: stage.pk,
      data,
    })
      .then(({ data: result }) => {})
      .catch((error) => console.log(error));
  };

  const addStage = (stage, i) => {
    let data = {
      name: value[`value_${stage.name}_${stage.pk}`],
      is_won:
        value[`select_${stage.name}_${stage.pk}`] === "active is_won"
          ? true
          : false,
      is_lead:
        value[`select_${stage.name}_${stage.pk}`] === "active is_lead"
          ? true
          : false,
      is_lost:
        value[`select_${stage.name}_${stage.pk}`] === "active is_lost"
          ? true
          : false,
      stage_position: stage.stage_position,
    };
    petitionPost("stage", { funnel_id: funnelInfo.pk, data })
      .then(({ data: result }) => {})
      .catch((error) => console.log(error));
  };

  const deleteStage = (stage, i) => {
    petitionDelete("stage", {
      funnel_id: funnelInfo.pk,
      stage_id: stage.pk,
    })
      .then(({ data: result }) => {
        if (i === deletedStagesList.length - 1) {
          setLoadingButton(false);
        }
      })
      .catch((error) => console.log(error));
  };

  const saveFunction = async () => {
    setLoadingButton(true);
    const promises = [];

    if (deletedStagesList.length > 0) {
      deletedStagesList.forEach((stage, i) => {
        promises.push(deleteStage(stage, i));
      });
    }

    stages.forEach((stage, i) => {
      if (stage.added_frontend) {
        promises.push(addStage(stage, i));
      } else if (
        value[`value_${stage.name}_${stage.pk}`] !== stage.name ||
        stage.stage_position !== stage.default_stage_position ||
        (!stage.is_won &&
          value[`select_${stage.name}_${stage.pk}`] === "active is_won") ||
        (stage.is_won &&
          (value[`select_${stage.name}_${stage.pk}`] === "active is_lost" ||
            value[`select_${stage.name}_${stage.pk}`] === "active is_lead" ||
            value[`select_${stage.name}_${stage.pk}`] === "")) ||
        (!stage.is_lost &&
          value[`select_${stage.name}_${stage.pk}`] === "active is_lost") ||
        (stage.is_lost &&
          (value[`select_${stage.name}_${stage.pk}`] === "active is_won" ||
            value[`select_${stage.name}_${stage.pk}`] === "active is_lead" ||
            value[`select_${stage.name}_${stage.pk}`] === "")) ||
        (!stage.is_lead &&
          value[`select_${stage.name}_${stage.pk}`] === "active is_lead") ||
        (stage.is_lead &&
          (value[`select_${stage.name}_${stage.pk}`] === "active is_won" ||
            value[`select_${stage.name}_${stage.pk}`] === "active is_lost" ||
            value[`select_${stage.name}_${stage.pk}`] === ""))
      ) {
        promises.push(editStage(stage, i));
      }
    });

    await Promise.all(promises);
    await backFunction(false);
  };

  const petition = () => {
    setLoading(true);
    petitionGet("stagesAdmin", { funnel_id: funnelInfo.pk })
      .then(({ data: result }) => {
        let newSetValue = value;
        result.result.sort(compare).forEach((stage) => {
          stage.id = `${uuidv4()}`;
          stage.default_stage_position = stage.stage_position;

          if (stage.is_won) {
            setExistStateWon(true);
          } else if (stage.is_lost) {
            setExistStateLost(true);
          } else if (stage.is_lead) {
            setExistStateLead(true);
          }

          newSetValue = {
            ...newSetValue,
            [`value_${stage.name}_${stage.pk}`]: stage.name,
            [`select_${stage.name}_${stage.pk}`]: stage.is_won
              ? "active is_won"
              : stage.is_lost
              ? "active is_lost"
              : stage.is_lead
              ? "active is_lead"
              : "",
          };

          delete stage.leads;
          delete stage.funnel;
        });

        setDeletedStagesList([]);
        setStages(result.result);
        setValue(newSetValue);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    petition();
  }, []);

  return loading ? (
    <Loading />
  ) : (
    <>
      <div
        className={`itp-stages-add-container${
          stepAddStage === 2 ? " step2" : ""
        }`}
      >
        <label className="itp-stage-add-title">Add a Stage</label>
        {stepAddStage === 1 && (
          <div className="d-flex justify-content-between align-items-center">
            <label className="itp-stage-add-label">
              No stage is added in the funnel yet.
            </label>
            <button
              onClick={() => selectStepAddStage("next")}
              className="btn-primary itp-stage-add-button"
            >
              <FontAwesomeIcon icon={faPlus} className="itp-icon-plus" />
              Add Stage
            </button>
          </div>
        )}

        {stepAddStage === 2 && (
          <>
            <Row className="itp-container-label-input">
              <Col
                xs={5}
                sm={5}
                md={5}
                lg={5}
                xl={5}
                xxl={5}
                className="itp-label-input-col"
              >
                <label className="itp-label-input__label">Stage Name</label>
              </Col>
              <Col
                xs={7}
                sm={7}
                md={7}
                lg={7}
                xl={7}
                xxl={7}
                className="itp-label-input-col"
              >
                <input
                  type="text"
                  placeholder=": Add a name for the stage"
                  className="form-control-itp itp-label-input__input itp-add-stage-input"
                  name="name"
                  onChange={(e) => {
                    setNewFormState({ ...newFormStage, name: e.target.value });
                  }}
                  value={newFormStage.name}
                />
              </Col>
            </Row>
            <label className="itp-stage-add-title">Number</label>
            <div className="d-flex justify-content-between align-items-center">
              <label className="itp-stage-add-label">
                Select the status of stage
              </label>
              <select
                className="form-select-itp itp-add-stage-select"
                name="status"
                value={newFormStage.status}
                onChange={(e) => onChangeSelect(e, e.target.value, true)}
              >
                <option value="">Select Status</option>
                <option value="active is_won" disabled={existStateWon}>
                  Won
                </option>
                <option value="active is_lost" disabled={existStateLost}>
                  Lost
                </option>
                <option value="active is_lead" disabled={existStateLead}>
                  Lead
                </option>
              </select>
            </div>
          </>
        )}
      </div>
      {stepAddStage === 2 && (
        <div className="itp-add-stage-buttons">
          <button
            className="btn-light itp-contact-button"
            onClick={() => selectStepAddStage("back")}
          >
            Back
          </button>
          <button className="btn-primary itp-contact-button" onClick={newStage}>
            Save
          </button>
        </div>
      )}
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="element-column">
          {(provided) => (
            <div
              {...provided.droppableProps}
              {...provided.dragHandleProps}
              ref={provided.innerRef}
            >
              {stages.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided) => (
                    <div
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      ref={provided.innerRef}
                    >
                      <Row
                        style={{ marginBottom: "12px" }}
                        className="d-flex align-items-center"
                      >
                        <Col className="d-flex justify-content-between align-items-center">
                          <div className="d-flex justify-content-between">
                            <input
                              name={`value_${item.name}_${item.pk}`}
                              value={value[`value_${item.name}_${item.pk}`]}
                              onChange={(e) => onChangeinput(e)}
                              type="text"
                              className="form-control-itp"
                            />
                            <FontAwesomeIcon
                              className="itp-stages-drag-icon"
                              icon={faGripVertical}
                            />
                          </div>
                        </Col>
                        <Col style={{ flex: "0 0 auto", width: "4%" }}>
                          <img
                            src={deleteIcon}
                            alt="Delete"
                            onClick={() => removedStagesList(item)}
                            className="icon-hover itp-stages-delete-icon"
                          />
                        </Col>
                        <Col>
                          <select
                            className="form-select-itp"
                            name={`select_${item.name}_${item.pk}`}
                            value={value[`select_${item.name}_${item.pk}`]}
                            onChange={(e) => onChangeSelect(e, e.target.value)}
                          >
                            <option value="">Select Status</option>
                            <option value="active is_won">Won</option>
                            <option value="active is_lost">Lost</option>
                            <option value="active is_lead">Lead</option>
                          </select>
                        </Col>
                      </Row>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {stages.length < 3 && stages.length > 0 && lessThanThreeStages()}
      <button
        onClick={saveFunction}
        className="btn-primary itp-lead-detail-button-save loading"
        disabled={loadingButton || stages.length < 3}
      >
        {loadingButton && <LoadingButton />}
        Save Changes
      </button>
    </>
  );
};

export default Stages;
