import React, { useEffect, useState } from "react";
import { Loading } from "../../../../../../components";
import petitionGet from "../../../../../../services/petitionGet";
import Select from "react-select";

const Play = ({ saveModule, edit, data, editModule }) => {
  const [media, setMedia] = useState([]);
  const [loading, setLoading] = useState(true);
  const [valueMedia, setValueMedia] = useState(null);

  const saveMedia = () => {
    const sendData = {
      id: valueMedia.pk
    }

    if (edit) {
      editModule(sendData, "play")
    } else {
      saveModule(sendData, "play")
    }
  }

  const petition = () => {
    setLoading(true);
    petitionGet("musicOnHoldMedia")
      .then(({ data: result }) => {
        result.result.forEach((element) => {
          element.label = element.name;
          element.value = element.pk;
        });

        if (edit) {
          const defaultMedia = result.result.find((element) => element.pk === data.data.id)
          setValueMedia(defaultMedia)
        }

        setMedia(result.result);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if (data) petition();
  }, [data]);

  return loading ? (
    <Loading />
  ) : (
    <>
      <div className="itp-callFlow-container-module">
        <label className="itp-label-input__label mb-2">Media File</label>
        <Select
          onChange={(e) => setValueMedia(e)}
          className="basic-single"
          classNamePrefix="select"
          value={valueMedia}
          isSearchable={true}
          name="queue"
          placeholder="Select a Media File"
          options={media}
        />
        <button
          onClick={saveMedia}
          className="btn-primary mt-4"
          disabled={valueMedia ? false : true}
        >
          {!edit ? "Save" : "Edit"}
        </button>
      </div>
    </>
  );
};

export default Play;
