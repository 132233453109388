import React, { useEffect, useState } from "react";
import { Badge, Card } from "reactstrap";
import { PaginationITP } from "../../../../../../components";
import arrowleft from "../../../../../../assets/icons/arrow-left.svg";

const Step4 = ({
  selectedNumbers,
  handleBackClick,
  pricePerDid,
  setCheckBox,
  errorChecked,
  setErrorChecked,
}) => {
  const cantPagination = 10;
  const [pagination, setPagination] = useState(cantPagination);
  const [paginationITP, setPaginationITP] = useState(cantPagination);

  const onChangeCheck = (e) => {
    setCheckBox(e.target.checked);
    setErrorChecked(null);
  };

  useEffect(() => {
    if (paginationITP != pagination)
      setPagination(paginationITP * cantPagination);
  }, [paginationITP, pagination]);

  return (
    <>
      <div onClick={handleBackClick} className="itp-tex-back-container">
        <div className="itp-text-container-funnel-details">
          <img src={arrowleft} alt="arrow-left" />
          <span className="itp-text-back-funnel-details">Back View Order</span>
        </div>
      </div>

      <h1 className="itp-custom-table-title centered">Call Sumary</h1>
      <div className="itp-custom-table-container-actions label">
        <div>
          <label className="itp-custom-table-label centered">
            View & Confirm the Numbers summary
          </label>
        </div>
      </div>
      <Card
        style={{
          zIndex: "0",
          marginTop: "15px",
          overflow: "",
        }}
        className="itp-table-container-centered"
      >
        <table className="itp-custom-table">
          <thead>
            <tr className="itp-custom-table-thead-styles">
              <th className="itp-custom-table-th-styles">Number</th>
              <th className="itp-custom-table-th-styles">Charges & Credits</th>
              <th className="itp-custom-table-th-styles">Unit Price</th>
              <th className="itp-custom-table-th-styles">Qunatity</th>
              <th className="itp-custom-table-th-styles">Amount</th>
            </tr>
          </thead>
          <tbody>
            {selectedNumbers.map((element, i) => {
              if (
                (i < pagination && i >= pagination - cantPagination) ||
                !pagination
              ) {
                return (
                  <tr key={i} className="itp-custom-table-row">
                    <td className="itp-custom-table-td-styles">
                      +1{element.number}
                    </td>
                    <td className="itp-custom-table-td-styles">
                      Additional Local Number(new purchase)
                    </td>
                    <td className="itp-custom-table-td-styles">
                      ${pricePerDid}
                    </td>
                    <td className="itp-custom-table-td-styles">1</td>
                    <td className="itp-custom-table-td-styles">
                      ${pricePerDid}
                    </td>
                  </tr>
                );
              }
            })}

            <tr>
              <td className="itp-custom-table-td-styles"></td>
              <td className="itp-custom-table-td-styles"></td>
              <td className="itp-custom-table-td-styles"></td>
              <td className="itp-custom-table-td-styles"></td>
            </tr>
            <tr>
              <td className="itp-custom-table-td-styles"></td>
              <td className="itp-custom-table-td-styles"></td>
              <td className="itp-custom-table-td-styles"></td>
              <td className="itp-custom-table-td-styles"></td>
            </tr>
            <tr>
              <td className="itp-custom-table-td-styles"></td>
              <td className="itp-custom-table-td-styles"></td>
              <td className="itp-custom-table-td-styles"></td>
              <td className="itp-custom-table-td-styles"></td>
            </tr>
            <tr>
              <td className="itp-custom-table-td-styles"></td>
              <td className="itp-custom-table-td-styles"></td>
              <td className="itp-custom-table-td-styles"></td>
              <td className="itp-custom-table-td-styles"></td>
            </tr>
            <tr>
              <td className="itp-custom-table-td-styles"></td>
              <td className="itp-custom-table-td-styles"></td>
              <td className="itp-custom-table-td-styles"></td>
              <td className="itp-custom-table-td-styles"></td>
            </tr>
            <tr style={{ borderTop: "1px solid rgba(0, 0, 0, 0.3)" }}>
              <td className="itp-label-input__label">Estimated Total</td>
              <td className="itp-custom-table-td-styles"></td>
              <td className="itp-label-input__label">
                ${pricePerDid * selectedNumbers.length}
              </td>
            </tr>
          </tbody>
        </table>
      </Card>
      {selectedNumbers && selectedNumbers.length > cantPagination && (
        <div style={{ maxWidth: "991px", margin: "auto" }} className="mt-4">
          <PaginationITP
            pages={
              (selectedNumbers.length / cantPagination) % 1 === 0
                ? Math.floor(selectedNumbers.length / cantPagination)
                : Math.floor(selectedNumbers.length / cantPagination + 1)
            }
            active={pagination / cantPagination}
            setActive={setPaginationITP}
          />
        </div>
      )}
      
      <div
        style={{
          zIndex: "0",
          marginTop: "15px",
          overflow: "",
        }}
        className="itp-table-container-centered"
      >
        <div style={{ gap: "12px" }} className="d-flex">
          <input
            style={{ width: "2rem" }}
            onChange={onChangeCheck}
            type="checkbox"
            className="form-check-input itp-period-card-checkbox"
            name=""
            id="exampleCheck1"
          />
          <label
            className="form-check-label itp-custom-table-td-styles"
            htmlFor="exampleCheck1"
          >
            <strong>
              I acknowledge that I have read this information and understand the
              itemized charges listed above. I authorize the total amount of $
              {pricePerDid * selectedNumbers.length} to be charged to my credit
              card on file. I further authorize the monthly recurring charges of
              ${pricePerDid * selectedNumbers.length} until canceled, which is in
              addition to my regular charges.
              * Prices do not include taxes, fees or discounts <br />
            </strong>
            <Badge
              color="danger"
              style={{
                marginTop: "2em",
                padding: "0.3rem 1rem",
                opacity: errorChecked ? "1" : "0",
              }}
            >
              Accept Terms...
            </Badge>
          </label>
        </div>
      </div>
    </>
  );
};

export default Step4;
