import React, { useEffect, useState } from "react";
import { Loading, LoadingButton } from "../../../../../../components";
import petitionGet from "../../../../../../services/petitionGet";
import Select from "react-select";

const User = ({ saveModule, edit, data, editModule }) => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [valueUser, setValueUser] = useState(null);
  const [loadingUser, setLoadingUser] = useState(false);
  const [form, setForm] = useState({
    flow: edit ? data.data.flow : "callflow",
    userId: edit ? data.data.idUser : "",
    timeout: edit ? data.data.timeout : "20",
    can_call_self: edit ? data.data.can_call_self : false,
  });

  const onChangeForm = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const saveVoicemail = () => {
    setLoadingUser(true);

    petitionGet("allCallflows")
      .then(({ data: result }) => {
        const callflowId = result.result.find(
          (element) => element.owner_id === form.userId.toString()
        );

        const sendData = {
          id: form.flow === "callflow" ? callflowId.pk : form.userId,
          idUser: form.userId,
          idCallflow: callflowId.pk,
          can_call_self: form.can_call_self,
          flow: form.flow,
          timeout: form.timeout,
        };

        if (edit) {
          editModule(sendData, form.flow === "callflow" ? "callflow" : "user")
        } else {
          saveModule(sendData, form.flow === "callflow" ? "callflow" : "user")
        }
        setLoadingUser(false);
      })
      .catch((error) => console.log(error));
  };

  const petition = () => {
    setLoading(true);
    petitionGet("itpvoiceUsers")
      .then(({ data: result }) => {
        result.result
          .sort((a, b) => a.firstname.localeCompare(b.firstname))
          .forEach((element) => {
            element.label = `${element.firstname} ${element.lastname} (${element.presence_id})`;
            element.value = element.pk;
          });

        if (edit) {
          const defaultUser = result.result.find(
            (element) => element.pk === data.data.idUser
          );

          setValueUser(defaultUser)
        }

        setUsers(result.result);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if (data) petition();
  }, [data]);

  return loading ? (
    <Loading />
  ) : (
    <>
      <div className="itp-callFlow-container-module">
        <label className="itp-label-input__label mb-2">User</label>
        <Select
          onChange={(e) => {
            setValueUser(e);
            setForm({ ...form, userId: e.pk });
          }}
          className="basic-single"
          classNamePrefix="select"
          value={valueUser}
          isSearchable={true}
          name="queue"
          placeholder="Select a Voicemail"
          options={users}
        />

        <label className="itp-label-input__label mt-4">
          If the number is not available
        </label>
        <select
          onChange={onChangeForm}
          value={form.flow}
          name="flow"
          className="form-select-itp"
        >
          <option value="" disabled>
            Select option
          </option>
          <option value="callflow">Go to User's Voicemail</option>
          <option value="custom">Custom option</option>
        </select>

        {form.flow === "custom" && (
          <>
            <label className="itp-label-input__label mt-4">Ring time</label>
            <input
              className="form-control-itp"
              value={form.timeout}
              name="timeout"
              onChange={onChangeForm}
              type="number"
            />

            <div
              style={{ gap: "12px" }}
              className="d-flex align-items-center mt-4"
            >
              <input
                className="form-check-input mt-1"
                type="checkbox"
                checked={form.can_call_self}
                onChange={() => {
                  setForm({
                    ...form,
                    can_call_self: !form.can_call_self,
                  });
                }}
              />
              <label className="itp-label-input__label">
                Allow call themselves
              </label>
            </div>
          </>
        )}

        <button
          onClick={saveVoicemail}
          className="btn-primary mt-4 loading"
          disabled={valueUser ? false : true}
        >
          {loadingUser && <LoadingButton />}
          {!edit ? "Save" : "Edit"}
        </button>
      </div>
    </>
  );
};

export default User;
