import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Loading, LoadingButton, ToggleImage } from "../../../../components";
import { formatDate } from "../../../../utils/getDate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { faPaperclip } from "@fortawesome/free-solid-svg-icons";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { faFileText } from "@fortawesome/free-solid-svg-icons";
import { faCheckSquare } from "@fortawesome/free-solid-svg-icons";
import { faThumbtack } from "@fortawesome/free-solid-svg-icons";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import PhoneInput from "react-phone-input-2";
import Tag from "../../../../assets/icons/addTag.svg";
import userImage from "../../../../assets/images/userImage.png";
import Select from "react-select";
import petitionPost from "../../../../services/petitionPost";
import petitionGet from "../../../../services/petitionGet";
import petitionDelete from "../../../../services/petitionDelete";
import petitionPatch from "../../../../services/petitionPatch";
import ModalDelete from "../AddContact/ModalUtility/ModalDelete";
import "./ContactDetails.css";

const ContactDetails = ({
  contactDetailsMenu,
  tagsExternal,
  petitionTag,
  contactList,
  customFields,
  editContact,
  loadingEdit,
  myExtension,
  setAddedCustomField,
}) => {
  const filter = createFilterOptions();
  const [disabled, setDisabled] = useState(false);
  const [tags, setTags] = useState([...contactDetailsMenu.tags]);
  const [optionsTags, setOptionsTags] = useState([]);
  const [newTags, setNewTags] = useState([]);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [openDropdownNotes, setOpenDropdownNotes] = useState(null);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [asignedUser, setAsignedUser] = useState(null);
  const [notes, setNotes] = useState([]);
  const [editNote, setEditNote] = useState(null);
  const [addNote, setAddNote] = useState(false);
  const [deleteNote, setDeleteNote] = useState(null);
  const [loadingNote, setLoadingNote] = useState(false);
  const [noteContent, setNoteContent] = useState("");
  const [changeEditNote, setChangeEditNote] = useState(null);
  const [fieldType, setFieldType] = useState("");
  const [newField, setNewField] = useState("");
  const [loadingAddField, setLoadingAddField] = useState(false);
  const [modalDeleteCustomField, setModalDeleteCustomField] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const [editCustomField, setEditCustomField] = useState(null);
  const [infoCustomField, setInfoCustomField] = useState(null)
  const [loadingEditField, setLoadingEditField] = useState(false);
  const [notEdit, setNotEdit] = useState(false)
  const [form, setForm] = useState({
    firstname: contactDetailsMenu.firstname,
    lastname: contactDetailsMenu.lastname || "",
    email: contactDetailsMenu.email || "",
    phone: contactDetailsMenu.phone || "",
    address: contactDetailsMenu.address || "",
  });
  const [valueContactList, setValueContactList] = useState({
    label: contactDetailsMenu.contactListName,
    value: contactDetailsMenu.contact_list_id,
  });

  const handleTag = (newValue) => {
    if (newValue.length === 0) {
      setOptionsTags(tagsExternal);
      setTags([]);
    } else {
      if (newValue[newValue.length - 1]?.inputValue) {
        let dataFilter = tagsExternal.filter(
          (element) =>
            element.name === newValue[newValue.length - 1]?.inputValue
        );
        if (dataFilter.length == 0) {
          setDisabled(true);
          addTag(newValue[newValue.length - 1]?.inputValue);
          setTags(newValue);
        } else {
          setTags(newValue);
        }
      } else {
        setTags(newValue);
      }
    }
  };

  const addTag = (tagName) => {
    petitionPost("addTags", {
      data: {
        name: tagName,
      },
    })
      .then(({ data: result }) => {
        let data = newTags;
        petitionTag();
        setDisabled(false);
        data.push(result.result);
        setNewTags(data);
      })
      .catch((error) => console.log(error));
  };

  const toggle = () => {
    setOpenDropdown(!openDropdown);
  };

  const toggleNotes = (event, index) => {
    event.stopPropagation();
    setOpenDropdownNotes(index === openDropdownNotes ? null : index);
  };

  const onchangeForm = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const formatTags = () => {
    let dataTagsNew = [];
    tags.map((element) => {
      if (typeof element !== "string") {
        if (element.inputValue) {
          let dataFilter = newTags.filter(
            (elementTwo) => elementTwo?.name === element?.inputValue
          );
          dataTagsNew.push(dataFilter[0]?.pk);
        } else {
          dataTagsNew.push(element.pk);
        }
      }
    });

    let result = dataTagsNew.filter((item, index) => {
      return dataTagsNew.indexOf(item) === index;
    });

    return result;
  };

  const getNotes = async () => {
    await petitionGet("contactNotes", {
      parameter: `?contact_id=${contactDetailsMenu.pk}`,
    })
      .then(({ data: result }) => {
        let showsMenus = {};
        let editNote = {};
        result.result.map((note) => {
          showsMenus[note.pk] = false;
          editNote[note.pk] = {
            pk: note.pk,
            edit: false,
            note_message: note.note_message,
          };
        });
        setNotes(result.result);
        setEditNote(editNote);
      })
      .catch((error) => console.log(error));
  };

  const petition = async () => {
    setLoading(true);
    await getNotes();

    await petitionGet("users")
      .then(({ data: result }) => {
        result.result.forEach((element) => {
          element.label = `${element.firstname} ${element.lastname}`;
          element.value = element.pk;
        });
        let dataFind = result.result.find(
          (user) => user.pk === contactDetailsMenu.owner_id
        );
        setAsignedUser(dataFind);
        setUsers(result.result);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  };

  const onSaveChange = () => {
    let data = {
      tags: formatTags(),
      contact_list_id: valueContactList.value,
      firstname: form.firstname,
      lastname: form.lastname,
      ...form,
    };

    if (asignedUser) data.owner_id = asignedUser.value;

    editContact(data);
  };

  const backNote = () => {
    setAddNote(false);
    setDeleteNote(false);
  };

  const saveNote = () => {
    setLoadingNote(true);
    let dataSend = {
      contact_id: contactDetailsMenu.pk,
      note_message: noteContent,
    };
    petitionPost("addNoteContact", { data: dataSend })
      .then(({ data: result }) => {
        setLoadingNote(false);
        setNoteContent("");
        backNote();
        getNotes();
      })
      .catch((error) => console.log(error));
  };

  const removeNote = () => {
    setLoadingNote(true);
    petitionDelete("deleteNote", { note_id: deleteNote })
      .then(({ data: result }) => {
        setLoadingNote(false);
        backNote();
        getNotes();
      })
      .catch((error) => console.log(error));
  };

  const updateNote = (note_id) => {
    setLoadingNote(true);
    let dataSend = {
      contact_id: contactDetailsMenu.pk,
      note_message: noteContent,
    };
    petitionPatch("editNote", {
      note_id,
      data: dataSend,
    })
      .then(({ data: result }) => {
        setLoadingNote(false);
        noShowEditNote();
        getNotes();
      })
      .catch((error) => console.log(error));
  };

  const addNewNote = () => {
    setAddNote(true);
  };

  const defaultValueAddField = (field) => {
    switch (field) {
      case "address":
        return "Address";
      case "url":
        return "URL";
      case "date":
        return "Date";
      case "text/note":
        return "Text / Note";
      case "checkbox":
        return "Checkbox";
      default:
        return "error";
    }
  };

  const showEditNote = (note_id) => {
    let showEditTextBox = editNote;
    showEditTextBox[note_id].edit = true;
    setChangeEditNote(showEditTextBox);
  };

  const noShowEditNote = () => {
    let showEditTextBox = editNote;
    Object.keys(showEditTextBox).map((element) => {
      showEditTextBox[element].edit = false;
    });
    setChangeEditNote(showEditTextBox);
  };

  const handleCopy = (note) => {
    navigator.clipboard
      .writeText(note)
      .then(() => {})
      .catch((error) => {
        console.error(error);
      });
  };

  const addCustomField = () => {
    setLoadingAddField(true);
    let data = {
      name: newField || defaultValueAddField(fieldType),
      field_type: fieldType,
      field_metadata: {
        max_length: 150,
      },
    };
    petitionPost("customFieldMyExtension", { data })
      .then(({ data: result }) => {
        setAddedCustomField(true);
        petitionGet("customFields")
          .then(({ data: result }) => {
            result.result.forEach((element) => {
              element.label = element.name;
              element.value = element.pk;
            });

            customFields.current = result.result;
            setLoadingAddField(false);
            setFieldType("");
            setNewField("");
          })
          .catch((error) => console.log(error));
      })
      .catch((error) => {
        console.log(error);
        setLoadingAddField(false);
      });
  };

  const returnCheck = (state) => {
    if (state === true || state === "true" || state === "True") {
      return true;
    } else if (state === false || state === "false" || state === "False") {
      return false;
    } else {
      return false;
    }
  };

  const handleCheck = (state) => {
    if (state === true || state === "true" || state === "True") {
      return false;
    } else if (state === false || state === "false" || state === "False") {
      return true;
    } else {
      return false;
    }
  };

  const closeModal = () => {
    setModalDeleteCustomField(false);
    setEditCustomField(null);
    setInfoCustomField(null)
    setNotEdit(false)
  };

  const activeModalDelete = (e) => {
    setModalDeleteCustomField(e);
  };

  const deleteCustomField = () => {
    setLoadingModal(true);
    petitionDelete("customField", {
      custom_field_id: modalDeleteCustomField.pk,
    })
      .then(({ data: result }) => {
        petitionGet("customFields")
          .then(({ data: result }) => {
            result.result.forEach((element) => {
              element.label = element.name;
              element.value = element.pk;
            });

            customFields.current = result.result;
            setLoadingModal(false);
            closeModal();
          })
          .catch((error) => console.log(error));
      })
      .catch((error) => console.log(error));
  };

  const activeEditCustomField = (element) => {
    if (myExtension.priv_level !== "admin") {
      setNotEdit(true);
      activeModalDelete(element);
    } else {
      setInfoCustomField(element)
      setEditCustomField(element)
      updateCustomField(element);
    }
  }

  const updateCustomField = (infoCustomField) => {
    setLoadingEditField(true)
    const data = infoCustomField
    const tempPk = data.pk
    delete data.label
    delete data.value
    delete data.pk
    delete data.account_id
    data.name = document.getElementById(tempPk).value;
    petitionPatch("customField", { data, custom_field_id: tempPk })
      .then(({ data: result }) => {
        const data = document.getElementsByClassName('itp-edit-contact-custom-field-actions')
        for (let index = 0; index < data.length; index++) {
          const element = data[index];
          element.style.visibility = "hidden";
        }    
        petitionGet("customFields")
        .then(({ data: result }) => {
          
          result.result.forEach((element) => {
            element.label = element.name;
            element.value = element.pk;
          });

          customFields.current = result.result;
          setLoadingEditField(false)
          closeModal();
        })
        .catch((error) => console.log(error));
      })
      .catch((error) => console.log(error))
  };

  useEffect(() => {
    if (changeEditNote) {
      setEditNote(changeEditNote);
      setChangeEditNote(null);
    }
  }, [changeEditNote]);

  useEffect(() => {
    let newform = form;
    customFields.current.forEach((element) => {
      newform[element.name] = contactDetailsMenu[element.name];
    });
    setForm(newform);
  }, [contactDetailsMenu]);

  useEffect(() => {
    setOptionsTags(tagsExternal);
  }, [tagsExternal]);

  useEffect(() => {
    petition();
  }, []);

  return loading ? (
    <Loading />
  ) : (
    <>
      {modalDeleteCustomField && (
        <ModalDelete
          modal={modalDeleteCustomField}
          closeModal={closeModal}
          loading={loadingModal}
          modalFunction={deleteCustomField}
          text={
            notEdit && myExtension.priv_level !== "admin"
              ? "It is not possible to edit this custom field because you are not an administrator"
              : myExtension.priv_level !== "admin"
              ? "It is not possible to delete this custom field because you are not an administrator"
              : "Delete Custom Field"
          }
          title={notEdit ? "Edit Custom Field" : "Delete Custom Field"}
          noSaveButton={myExtension.priv_level !== "admin" ? true : false}
        />
      )}

      <Row>
        <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}></Col>
        <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
          <label className="itp-component-tag-label">Tags</label>
          <div className="itp-component-tag-container">
            <img src={Tag} alt="Add Tag" className="itp-component-tag-img" />
            <div className="itp-component-tag-width">
              <Stack>
                <Autocomplete
                  disableClearable
                  disablePortal
                  filterSelectedOptions
                  disabled={disabled}
                  freeSolo
                  multiple
                  defaultValue={tags}
                  getOptionLabel={(option) => {
                    // Value selected with enter, right from the input
                    if (typeof option === "string") {
                      return "error";
                    }
                    // Add "xxx" option created dynamically
                    if (option.inputValue) {
                      return option.inputValue;
                    }

                    // Regular option
                    return option.title;
                  }}
                  getOptionDisabled={(option) => !!option.disabled}
                  options={optionsTags}
                  id="tags-standard"
                  isOptionEqualToValue={(option, value) =>
                    option.title === value.title
                  }
                  onChange={(event, newValue) => handleTag(newValue)}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    const { inputValue } = params;
                    // Suggest the creation of a new value
                    const isExisting = options.some(
                      (option) => inputValue === option.title
                    );
                    if (inputValue !== "" && !isExisting) {
                      filtered.push({
                        inputValue,
                        title: `Add ${inputValue}`,
                      });
                    }

                    return filtered;
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      placeholder="Add Tags"
                    />
                  )}
                  renderOption={(props, option) => (
                    <li {...props}>{option.title}</li>
                  )}
                />
              </Stack>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="itp-contact-details-row-select">
        <Col xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
          <label className="itp-contact-details-label">Contact List</label>
          <p className="itp-contact-details-p">
            Select the Contact List this Contact belongs to
          </p>
        </Col>
        <Col xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
          <Select
            onChange={(e) => {
              setValueContactList(e);
            }}
            className="basic-single"
            classNamePrefix="select"
            value={valueContactList}
            isSearchable={true}
            name="contact_list_id"
            options={contactList}
          />
        </Col>
      </Row>
      <Row className="itp-container-label-input">
        <Col
          xs={5}
          sm={5}
          md={5}
          lg={5}
          xl={5}
          xxl={5}
          className="itp-label-input-col"
        >
          <label className="itp-label-input__label">First Name</label>
        </Col>
        <Col
          xs={7}
          sm={7}
          md={7}
          lg={7}
          xl={7}
          xxl={7}
          className="itp-label-input-col"
        >
          <input
            type="text"
            placeholder=": Enter contact First Name"
            className="form-control-itp itp-label-input__input"
            name="firstname"
            value={form.firstname}
            onChange={onchangeForm}
          />
        </Col>
      </Row>
      <Row className="itp-container-label-input">
        <Col
          xs={5}
          sm={5}
          md={5}
          lg={5}
          xl={5}
          xxl={5}
          className="itp-label-input-col"
        >
          <label className="itp-label-input__label">Last Name</label>
        </Col>
        <Col
          xs={7}
          sm={7}
          md={7}
          lg={7}
          xl={7}
          xxl={7}
          className="itp-label-input-col"
        >
          <input
            type="text"
            placeholder=": Enter contact Last Name"
            className="form-control-itp itp-label-input__input"
            name="lastname"
            value={form.lastname}
            onChange={onchangeForm}
          />
        </Col>
      </Row>
      <Row className="itp-container-label-input">
        <Col
          xs={5}
          sm={5}
          md={5}
          lg={5}
          xl={5}
          xxl={5}
          className="itp-label-input-col"
        >
          <label className="itp-label-input__label">E-mail</label>
        </Col>
        <Col
          xs={7}
          sm={7}
          md={7}
          lg={7}
          xl={7}
          xxl={7}
          className="itp-label-input-col"
        >
          <input
            type="text"
            placeholder=": Enter email address"
            className="form-control-itp itp-label-input__input"
            name="email"
            value={form.email}
            onChange={onchangeForm}
          />
        </Col>
      </Row>
      <Row className="itp-container-label-select">
        <Col
          xs={5}
          sm={5}
          md={5}
          lg={5}
          xl={5}
          xxl={5}
          className="itp-label-input-col"
        >
          <label className="itp-label-input__label">Phone Number</label>
        </Col>
        <Col
          xs={7}
          sm={7}
          md={7}
          lg={7}
          xl={7}
          xxl={7}
          className="itp-label-input-col"
        >
          <PhoneInput
            country={"us"}
            value={form.phone}
            disableCountryFlags={true}
            enableSearch={true}
            countryCodeEditable={false}
            onChange={(e) => {
              setForm({ ...form, phone: `+${e}` });
            }}
          />
        </Col>
      </Row>
      <Row className="itp-container-label-input">
        <Col
          xs={5}
          sm={5}
          md={5}
          lg={5}
          xl={5}
          xxl={5}
          className="itp-label-input-col"
        >
          <label className="itp-label-input__label">Address</label>
        </Col>
        <Col
          xs={7}
          sm={7}
          md={7}
          lg={7}
          xl={7}
          xxl={7}
          className="itp-label-input-col"
        >
          <input
            type="text"
            placeholder=": Enter address"
            className="form-control-itp itp-label-input__input"
            name="address"
            value={form.address}
            onChange={onchangeForm}
          />
        </Col>
      </Row>

      {customFields.current.map((element, index) => (
        <div
          // key={element.name}
          // data={index}
          className="itp-edit-contact-container-custom-field"          
        >
          {!editCustomField && (
            <div className="itp-edit-contact-custom-field-actions p-1">              
              <input name={element.label} id={element.pk} defaultValue={element.label}/>
              <label
                onClick={() => activeEditCustomField(element)}
                className="itp-label-input__label option itp-label-border-bottom-property"
              >
                Save
              </label>
              <label
                onClick={() => activeModalDelete(element)}
                className="itp-label-input__label option"
              >
                Delete
              </label>
            </div>
          )}

          <Row
            className={`itp-container-label-${
              element.field_type !== "list" && element.field_type !== "checkbox"
                ? "input"
                : "select"
            }`}
          >
            <Col
              xs={5}
              sm={5}
              md={5}
              lg={5}
              xl={5}
              xxl={5}
              className="itp-label-input-col"
            >
              <label className="itp-label-input__label">{element.name}</label>
            </Col>
            <Col
              xs={7}
              sm={7}
              md={7}
              lg={7}
              xl={7}
              xxl={7}
              className="itp-label-input-col"
            >
              {element.field_type !== "list" &&
                element.field_type !== "checkbox" && (
                  <input
                    type="text"
                    placeholder=": Enter address"
                    className="form-control-itp itp-label-input__input"
                    name={element.name}
                    value={form[element.name] || ""}
                    onChange={onchangeForm}
                  />
                )}

              {element.field_type === "list" && (
                <select
                  className="form-select-itp"
                  onChange={onchangeForm}
                  name={element.name}
                  value={form[element.name] || ""}
                >
                  <option value="" disabled>
                    Select Option
                  </option>
                  {element.field_metadata.list_values.map((val, i) => (
                    <option key={i} value={val}>
                      {val}
                    </option>
                  ))}
                </select>
              )}

              {element.field_type === "checkbox" && (
                <input
                  checked={returnCheck(form[element.name]) || false}
                  name={element.name}
                  onChange={(e) => {
                    setForm({
                      ...form,
                      [e.target.name]: handleCheck(form[element.name] || false),
                    });
                  }}
                  type="checkbox"
                  className="form-check-input itp-custom-checkbox-contact-details"
                />
              )}
            </Col>
          </Row>
        </div>
      ))}


      <Row className="itp-contact-details-row-select">
        <Col xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
          <label className="itp-contact-details-label">Assigned User</label>
          <p className="itp-contact-details-p">
            Select the assigned user for this contact
          </p>
        </Col>
        <Col xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
          <Select
            onChange={(e) => setAsignedUser(e)}
            className="basic-single"
            classNamePrefix="select"
            value={asignedUser}
            isSearchable={true}
            name="owner_id"
            placeholder="Select a User"
            options={users}
          />
        </Col>
      </Row>

      <div className="itp-contact-details-separator"></div>

      {addNote && !deleteNote && (
        <div className="itp-contact-detail-add-note">
          <textarea
            spellCheck={false}
            onChange={(e) => setNoteContent(e.target.value)}
          />
          <div className="itp-contact-details-add-note-buttons">
            <button
              onClick={backNote}
              disabled={loadingNote}
              className="btn-light"
            >
              Back
            </button>
            <button
              className="btn-primary itp-contact-details-save-notes-button"
              disabled={noteContent === "" || loadingNote}
              onClick={saveNote}
            >
              {loadingNote && <LoadingButton />}
              Save
            </button>
          </div>
        </div>
      )}

      {!addNote && deleteNote && (
        <div className="d-flex flex-column justify-content-center align-items-center">
          <p>Do you want to Delete this note?</p>
          <div className="itp-contact-details-add-note-buttons">
            <button
              onClick={backNote}
              disabled={loadingNote}
              className="btn-light"
            >
              Back
            </button>
            <button
              className="btn-primary itp-contact-details-save-notes-button"
              disabled={loadingNote}
              onClick={removeNote}
            >
              {loadingNote && <LoadingButton />}
              Delete
            </button>
          </div>
        </div>
      )}

      {!addNote && !deleteNote && (
        <>
          <label className="itp-contact-details-notes">Notes</label>
          <div className="itp-contact-details-note-title">
            <label className="itp-contact-details-notes-label">
              Add or view the notes related to this specific contact
            </label>
            <button
              onClick={addNewNote}
              className="btn-primary itp-contact-details-notes-button"
            >
              <FontAwesomeIcon icon={faPlus} className="itp-icon-plus" /> Add
              Note
            </button>
          </div>
          {notes.map((note, i) => (
            <div key={note.pk} className="itp-contact-details-note-content">
              <div className="itp-contact-details-note-content__tile">
                <div className="title-note-left-section">
                  <img
                    src={userImage}
                    alt="Profile"
                    className="itp-contact-detail-note-img"
                  />
                  <label className="itp-contact-details-note-text">
                    {myExtension.firstname} {myExtension.lastname}
                  </label>
                  <span className="itp-contact-details-note-text">
                    {formatDate(note.date_added)}
                  </span>
                </div>
                {!editNote[note.pk].edit && (
                  <Dropdown
                    isOpen={i === openDropdownNotes}
                    toggle={(event) => toggleNotes(event, i)}
                    className="title-note-right-section"
                  >
                    <DropdownToggle className="nav-link">
                      <ToggleImage />
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem
                        onClick={() => console.log(note)}
                        className="dropdown-item"
                      >
                        {<FontAwesomeIcon icon={faThumbtack} />} Pin Note
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => setDeleteNote(note.pk)}
                        className="dropdown-item"
                      >
                        {<FontAwesomeIcon icon={faTrash} />} Delete Note
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => handleCopy(note.note_message)}
                        className="dropdown-item"
                      >
                        {<FontAwesomeIcon icon={faCopy} />} Copy Note
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => showEditNote(note.pk)}
                        className="dropdown-item"
                      >
                        {<FontAwesomeIcon icon={faPenToSquare} />} Edit Note
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                )}
              </div>
              {editNote[note.pk].edit && (
                <>
                  <textarea
                    className="itp-contact-details-edit-note"
                    defaultValue={editNote[note.pk].note_message}
                    spellCheck={false}
                    onChange={(e) => setNoteContent(e.target.value)}
                  />
                  <div className="itp-contact-details-add-note-buttons">
                    <button
                      onClick={noShowEditNote}
                      disabled={loadingNote}
                      className="btn-light"
                    >
                      Back
                    </button>
                    <button
                      className="btn-primary itp-contact-details-save-notes-button"
                      disabled={
                        noteContent === "" ||
                        noteContent === editNote[note.pk].note_message
                      }
                      onClick={() => updateNote(note.pk)}
                    >
                      {loadingNote && <LoadingButton />}
                      Edit
                    </button>
                  </div>
                </>
              )}

              {!editNote[note.pk].edit && (
                <div className="itp-contact-details-note-content__content">
                  {
                    <FontAwesomeIcon
                      icon={faFileText}
                      className="icon-text-note"
                    />
                  }
                  <p className="itp-contact-details-p">{note.note_message}</p>
                </div>
              )}
            </div>
          ))}
        </>
      )}

      <button
        onClick={onSaveChange}
        className="btn-primary itp-contact-detail-button-save loading"
        disabled={loading || addNote || deleteNote}
      >
        {loadingEdit && <LoadingButton />}
        Save Changes
      </button>
    </>
  );
};

export default ContactDetails;
