import React, { useState } from "react";
import {
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { UserAvatar, ToggleImage } from "../../index";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import profile from "../../../assets/images/profile.png";
import useScreenSize from "../../../hooks/useScreenSize";
import "../ChatWindow.css";

const ChatWindowHeader = ({
  chatWindowData,
  openMenuData,
  toggleMenu,
  closeChatWindow,
  activeModalDelete,
  changeAiEnabled,
}) => {
  const { width } = useScreenSize();
  const [checked, setChecked] = useState(chatWindowData?.ai_enabled);
  const [openDropdown, setOpenDropdown] = useState(false);

  const toggle = () => {
    setOpenDropdown(!openDropdown);
  };

  const onChangeCheck = (e) => {
    setChecked(e.target.checked)
    changeAiEnabled(e.target.checked)
  }

  return (
    <>
      <Row className="itp-chat-window-header">
        <Col xs={9} sm={9} md={9} lg={9} xl={9} xxl={9}>
          <Row>
            <Col
              className="itp-chat-window__header-containet-img"
              xs={1}
              sm={1}
              md={1}
              lg={1}
              xl={1}
              xxl={1}
            >
              {chatWindowData.isContact ? (
                <UserAvatar
                  firstname={chatWindowData.firstname}
                  lastname={chatWindowData.lastname}
                  className="itp-chat-window__header-user-img"
                />
              ) : (
                <img
                  src={profile}
                  alt="Chat Image"
                  className="itp-chat-window__header-user-img rounded-circle"
                />
              )}
              {width < 768 && (
                <KeyboardBackspaceIcon
                  className="icon-hover"
                  onClick={closeChatWindow}
                  style={{ color: "#1c5586" }}
                />
              )}
            </Col>
            <Col
              xs={5}
              sm={3}
              md={4}
              lg={width > 1099 ? 4 : 5}
              xl={width > 1333 ? 3 : 4}
              xxl={3}
              className="d-flex justify-content-between"
            >
              <div
                className={
                  width > 1399 && openMenuData
                    ? width > 1919
                      ? "margin-info"
                      : "d-none"
                    : "itp-chat-window__header-info-user"
                }
              >
                <p className="itp-chat-window__header-user-name">
                  {chatWindowData.isContact
                    ? chatWindowData.nameContact
                    : chatWindowData.numberChat}
                </p>
                <p className="itp-chat-window__header-last-message">
                  {chatWindowData.last_message}
                </p>
              </div>
              <div
                className={
                  width > 1399 && openMenuData
                    ? "d-none"
                    : "itp-chat-window__header-separator"
                }
              ></div>
            </Col>
            <Col xs={3} sm={3} md={3} lg={3} xl={3} xxl={3}>
              <div className="form-check form-switch itp-chat-window__header-chat-enabled">
                <input
                  className="form-check-input form-check-chat-window"
                  onChange={onChangeCheck}
                  checked={checked}
                  type="checkbox"
                  role="switch"
                  id="flexSwitchCheckDefault"
                />
                <label
                  className="form-check-label itp-login-remember itp-chat-window__header-text-chat-enabled"
                  htmlFor="flexSwitchCheckDefault"
                >
                  AI Enabled
                </label>
              </div>
            </Col>
          </Row>
        </Col>
        <Col
          xs={3}
          sm={3}
          md={3}
          lg={3}
          xl={3}
          xxl={3}
          className="itp-chat-windiow__header-show-profile-container"
        >
          <div className="d-flex">
            <label
              className={
                openMenuData && width > 1399
                  ? "d-none"
                  : "itp-chat-windiow__header-show-profile"
              }
            >
              View Contact
            </label>
            <KeyboardDoubleArrowRightIcon
              onClick={toggleMenu}
              style={{ width: "24px", height: "24px" }}
              className={`itp-chat-windiow__header-show-profile
                        ${openMenuData ? " open-menu-data" : ""}
                      `}
            />
          </div>

          <Dropdown
            className="itp-chat-windiow__header-show-profile-ropdown"
            isOpen={openDropdown}
            toggle={toggle}
          >
            <DropdownToggle className="nav-link">
              <ToggleImage />
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem
                onClick={() => activeModalDelete()}
                className="dropdown-item"
              >
                <FontAwesomeIcon icon={faTrash} /> Delete Messages
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </Col>
      </Row>
    </>
  );
};

export default ChatWindowHeader;
