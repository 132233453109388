import React, { useState } from "react";
import { Col, Row } from "reactstrap";
import { UserAvatar, Loading } from "../index";
import { formatDateTwo } from "../../utils/getDate";
import PhoneInput from "react-phone-input-2";
// import TuneIcon from "@mui/icons-material/Tune";
import AddIcon from "@mui/icons-material/Add";
import profile from "../../assets/images/profile.png";
// import lostCall from "../../assets/icons/lostCall.svg";
import InfiniteScroll from "react-infinite-scroll-component";
import useScreenSize from "../../hooks/useScreenSize";
import "./ChatList.css";

const ChatList = ({
  activeChatWindow,
  showChatList,
  chatList,
  moreData,
  loadingScroll,
  showAlertForNewMessage,
  addNewChat,
  onChageSelect,
  loading,
  chatWindowData,
  noAddIcon,
}) => {
  const { width, height } = useScreenSize();
  const [selectedOption, setSelectedoption] = useState("All");
  const [addChat, setAddChat] = useState(false);
  const [newPhoneNumber, setNewPhoneNumber] = useState("+1 ");
  const [countryCode, setCountryCode] = useState("+1 ");

  // const selectHeight = () => {
  //   if (height < 760) {
  //     return "70vh";
  //   } else if (height > 759 && height < 899) {
  //     return "76vh";
  //   } else if (height > 900 && height < 959) {
  //     return "77vh";
  //   } else if (height > 1000 && height < 1280) {
  //     return "78vh";
  //   } else if (height > 1279 && height < 1599) {
  //     return "85vh";
  //   } else if (height > 1600) {
  //     return "95vh";
  //   }
  // };

  const toggleActiveChat = () => {
    setAddChat(!addChat);
  };

  const handleSubmitNumber = () => {
    let number =
      newPhoneNumber.split(" ")[newPhoneNumber.split(" ").length - 1];

    if (`${number.substring(0, countryCode.length - 1)} ` === countryCode) {
      addNewChat(number);
    } else {
      addNewChat(`${countryCode.split(" ")[0]}${number}`);
    }

    setNewPhoneNumber("");
    toggleActiveChat();
  };

  const onChaneSelect = (e) => {
    onChageSelect(e.target.value);
    setSelectedoption(e.target.value);
  };

  const selectTitle = (title) => {
    switch (title) {
      case "All":
        return "There are no open chats";
      case "Unread":
        return "No Unread Chats";
      case "AI":
        return "No AI Chats";
    }
  };

  return (
    <>
      {(showChatList || width > 767) && (
        <>
          <Col
            style={{height:"calc(100vh - 90px)", display:"flex", flexDirection:"column"}}
            className="itp-chat-list"
            xs={11}
            sm={11}
            md={5}
            lg={width > 1099 ? 4 : 5}
            xl={4}
            xxl={3}
          >
            <div className="itp-chat-list-header">
              <p className="itp-chat-list-header__text">Chat Type</p>
              <div className="d-flex justify-content-between">
                <select
                  value={selectedOption}
                  onChange={onChaneSelect}
                  className="itp-chat-list-header__select"
                >
                  <option
                    className="itp-chat-list-header__select-option"
                    value="All"
                  >
                    All Chats
                  </option>
                  <option
                    className="itp-chat-list-header__select-option"
                    value="Unread"
                  >
                    Unread Chats
                  </option>
                  <option
                    className="itp-chat-list-header__select-option"
                    value="AI"
                  >
                    AI Chats
                  </option>
                </select>
                <div className="d-flex">
                  {/* <div className="itp-chat-list-header__container-icon">
                  <TuneIcon className="itp-chat-list-header__icon" />
                  <div className="itp-chat-list-header__info-icon">
                    <span className="itp-bulk-icon-name">Tune Icon</span>
                  </div>
                </div> */}
                 {!noAddIcon && (
                  <div className="itp-chat-list-header__container-icon">
                  <AddIcon
                    onClick={toggleActiveChat}
                    className={`itp-chat-list-header__icon${
                      addChat ? " rotate-icon" : ""
                    }`}
                  />
                  <div className="itp-chat-list-header__info-icon">
                    <span className="itp-bulk-icon-name">
                      {!addChat ? "Add New Chat" : "Close"}
                    </span>
                  </div>
                </div>
                 )}
                </div>
              </div>
            </div>

            {loading && <Loading />}

            {!addChat && !loading && (
              <>
                {chatList.length === 0 && (
                  <h6 className="itp-chat-list__no-data">
                    {selectTitle(selectedOption)}
                  </h6>
                )}

                {chatList.length > 0 && (
                  <InfiniteScroll
                    dataLength={chatList.length}
                    next={() => moreData()}
                    hasMore={
                      chatList.length > 0 && chatList.length % 15 === 0
                        ? true
                        : false
                    }
                    loader={loadingScroll && <Loading />}
                    // height={selectHeight()}                    
                  >
                    <div className="itp-chat-list-body">
                      {/* <Row onClick={activeChatWindow} className="itp-chat-list-item">
                              <Col
                                xs={2}
                                sm={2}
                                md={2}
                                lg={width > 1099 && width < 1200 ? 2 : 1}
                                xl={width > 1199 && width < 1300 ? 2 : 1}
                                xxl={2}
                                className="itp-chat-list__col-item-img"
                              >
                                <div style={{ position: "relative" }}>
                                  <img
                                    src={profile}
                                    alt="Chat Image"
                                    className="itp-chat-list__img-item rounded-circle"
                                  />
                                  <div className="itp-chat-list-circle"></div>
                                </div>
                              </Col>
                              <Col
                                xs={10}
                                sm={10}
                                md={10}
                                lg={width > 1099 && width < 1200 ? 9 : 10}
                                xl={width > 1199 && width < 1300 ? 9 : 10}
                                xxl={9}
                                className="itp-chat-list__col-item-info"
                              >
                                <div className="itp-chat-list__container-info-item">
                                  <label className="itp-chat-list__item-name">
                                    Stevan Hadley
                                  </label>
                                  <span className="itp-chat-list__item-date">
                                    07/09 03:45 pm
                                  </span>
                                </div>
                                <p className="itp-chat-list__item-last-message">
                                  Problem with a printerrrrrrrrrrrrrrrrrrrrrrrrrr
                                </p>
                              </Col>
                            </Row>
                            <Row onClick={activeChatWindow} className="itp-chat-list-item">
                              <Col
                                xs={2}
                                sm={2}
                                md={2}
                                lg={width > 1099 && width < 1200 ? 2 : 1}
                                xl={width > 1199 && width < 1300 ? 2 : 1}
                                xxl={2}
                                className="itp-chat-list__col-item-img"
                              >
                                <div style={{ position: "relative" }}>
                                  <img
                                    src={profile}
                                    alt="Chat Image"
                                    className="itp-chat-list__img-item rounded-circle"
                                  />
                                  <div className="itp-chat-list-circle"></div>
                                </div>
                              </Col>
                              <Col
                                xs={10}
                                sm={10}
                                md={10}
                                lg={width > 1099 && width < 1200 ? 9 : 10}
                                xl={width > 1199 && width < 1300 ? 9 : 10}
                                xxl={9}
                                className="itp-chat-list__col-item-info"
                              >
                                <div className="itp-chat-list__container-info-item">
                                  <label className="itp-chat-list__item-name">Robert Fox</label>
                                  <span className="itp-chat-list__item-date">
                                    07/09 03:45 pm
                                  </span>
                                </div>
                                <p className="itp-chat-list__item-last-message">
                                  Problem with a printer whileeee
                                </p>
                              </Col>
                            </Row>
                            <Row
                              onClick={activeChatWindow}
                              className="itp-chat-list-item-missed"
                            >
                              <label className="itp-chat-list-item-missed__title">
                                Yesterday
                              </label>
                              <Col
                                xs={2}
                                sm={2}
                                md={2}
                                lg={width > 1099 && width < 1200 ? 2 : 1}
                                xl={width > 1199 && width < 1300 ? 2 : 1}
                                xxl={2}
                                className="itp-chat-list__col-item-img"
                              >
                                <img
                                  src={profile}
                                  alt="Chat Image"
                                  className="itp-chat-list__img-item rounded-circle"
                                />
                              </Col>
                              <Col
                                xs={10}
                                sm={10}
                                md={10}
                                lg={width > 1099 && width < 1200 ? 9 : 10}
                                xl={width > 1199 && width < 1300 ? 9 : 10}
                                xxl={9}
                                className="itp-chat-list__col-item-info"
                              >
                                <div className="itp-chat-list__container-info-item">
                                  <label className="itp-chat-list__item-name">Serah</label>
                                  <span className="itp-chat-list__item-date">
                                    06/09 03:45 pm
                                  </span>
                                </div>
                                <p className="itp-chat-list__item-last-missed">
                                  {<img src={lostCall} alt="Lost" />} Missed call
                                </p>
                              </Col>
                            </Row> */}
                      {chatList &&
                        chatList.map((element, i) => (
                          <Row
                            key={i}
                            onClick={() => activeChatWindow(element)}
                            className={`itp-chat-list-item${
                              chatWindowData?.pk === element.pk ? " active" : ""
                            }`}
                          >
                            <Col
                              xs={2}
                              sm={2}
                              md={2}
                              lg={width > 1099 && width < 1200 ? 2 : 1}
                              xl={width > 1199 && width < 1300 ? 2 : 1}
                              xxl={2}
                              className="itp-chat-list__col-item-img"
                            >
                              <div style={{ position: "relative" }}>
                                {element.isContact ? (
                                  <UserAvatar
                                    firstname={element.firstname}
                                    lastname={element.lastname}
                                    className="itp-chat-list__img-item"
                                  />
                                ) : (
                                  <img
                                    src={profile}
                                    alt="Chat"
                                    className="itp-chat-list__img-item rounded-circle"
                                  />
                                )}
                                {/* <div className="itp-chat-list-circle"></div> */}
                              </div>
                            </Col>
                            <Col
                              xs={10}
                              sm={10}
                              md={10}
                              lg={width > 1099 && width < 1200 ? 9 : 10}
                              xl={width > 1199 && width < 1300 ? 9 : 10}
                              xxl={9}
                              className="itp-chat-list__col-item-info"
                            >
                              <div className="itp-chat-list__container-info-item">
                                <label className="itp-chat-list__item-name">
                                  {element.isContact
                                    ? element.nameContact
                                    : element.numberChat}
                                </label>
                                <span className="itp-chat-list__item-date">
                                  {formatDateTwo(element.last_updated)}
                                </span>
                              </div>
                              <p className="itp-chat-list__item-last-message">
                                {element.last_message}
                              </p>
                              {(element?.pk == showAlertForNewMessage ||
                                !element?.thread_read) &&
                                element?.unread_messages > 0 && (
                                  <div className="itp-chat-lit__notification">
                                    {element.unread_messages}
                                  </div>
                                )}
                            </Col>
                          </Row>
                        ))}
                    </div>
                  </InfiniteScroll>
                )}
              </>
            )}

            {addChat && !loading && (
              <div className="itp-chat-list__add-chat">
                <label className="itp-chat-list__add-chat-text">
                  Enter a Phone Number
                </label>
                <PhoneInput
                  country={"us"}
                  value={newPhoneNumber}
                  disableCountryFlags={true}
                  enableSearch={true}
                  countryCodeEditable={false}
                  className="itp-chat-list__phone-input-add-chat"
                  onChange={(e) => {
                    setCountryCode(`+${e} `);
                    setNewPhoneNumber(`+${e} `);
                  }}
                />
                <div className="itp-chat-list__add-chat-button-container">
                  <button onClick={handleSubmitNumber} className="btn-primary">
                    Open Chat
                  </button>
                </div>
              </div>
            )}
          </Col>
        </>
      )}
    </>
  );
};

export default ChatList;
