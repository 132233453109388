import React, { useEffect, useState } from "react";
import { Card, CardBody } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Loading, CustomTable } from "../../../../components";
import arrowleft from "../../../../assets/icons/arrow-left.svg";
import view from "../../../../assets/icons/view.svg";
import petitionPost from "../../../../services/petitionPost";
import ChartLine from "./ChartLine";
import ViewLogs from "./ViewLogs";
import "./DataReport.css";

const DataReport = ({
  handleBackClick,
  selectedReport,
  selectedUsers,
  selectedNumbers,
  timeframe,
  viewLogs,
  activeViewLogs,
  loadingCall,
  width,
  timeZone,
  completedLogs,
}) => {
  const From = `${timeframe.timeStart.split("-")[2]}-${
    timeframe.timeStart.split("-")[1]
  }-${timeframe.timeStart.split("-")[0]}`;

  const to = `${timeframe.timeEnd.split("-")[2]}-${
    timeframe.timeEnd.split("-")[1]
  }-${timeframe.timeEnd.split("-")[0]}`;

  const [loading, setLoading] = useState(true);
  const [dataTable, setDataTable] = useState(null);
  const [totalCalls, setTotalCalls] = useState(0);
  const [xLabel, setXLabel] = useState([]);
  const [yLabel, setYLabel] = useState([]);

  const formatTime = (seconds) => {
    let hour = Math.floor(seconds / 3600);
    hour = hour < 10 ? `0${hour}h` : `${hour}h`;

    let minute = Math.floor((seconds / 60) % 60);
    minute = minute < 10 ? `0${minute}m` : `${minute}m`;

    let second = seconds % 60;
    second = second < 10 ? `0${second}s` : `${second}s`;

    return `${hour} ${minute} ${second}`;
  };

  const formatData = (data, timezone) => {
    const order = [
      "12 AM",
      "1 AM",
      "2 AM",
      "3 AM",
      "4 AM",
      "5 AM",
      "6 AM",
      "7 AM",
      "8 AM",
      "9 AM",
      "10 AM",
      "11 AM",
      "12 PM",
      "1 PM",
      "2 PM",
      "3 PM",
      "4 PM",
      "5 PM",
      "6 PM",
      "7 PM",
      "8 PM",
      "9 PM",
      "10 PM",
      "11 PM",
    ];

    const convertedData = {};
    for (const key in data) {
      const [hours, period] = key.split(" ");
      let hour = parseInt(hours);
      if (period === "PM" && hour !== 12) {
        hour += 12;
      } else if (period === "AM" && hour === 12) {
        hour = 0;
      }
      const time = new Date();
      time.setUTCHours(hour);
      const formattedTime = time.toLocaleString("en-US", {
        timeZone: timezone,
        hour: "numeric",
        hour12: true,
      });
      convertedData[formattedTime] = data[key];
    }

    const orderedObject = {};
    order.forEach((key) => {
      if (key in convertedData) {
        orderedObject[key] = convertedData[key];
      }
    });

    return orderedObject;
  };

  const petitionUserReport = () => {
    setLoading(true);

    const data = {
      users: selectedUsers.map((user) => user.pk),
    };

    const newTimeStart = new Date(timeframe.timeStart);
    const newTimeEnd = new Date(
      parseInt(timeframe.timeEnd.split("-")[0]),
      parseInt(timeframe.timeEnd.split("-")[1]) - 1,
      parseInt(timeframe.timeEnd.split("-")[2]),
      23,
      59,
      59
    );

    const parameter = `?start_date=${newTimeStart.getTime() / 1000}&end_date=${
      newTimeEnd.getTime() / 1000
    }`;

    petitionPost("reports", { data, parameter })
      .then(({ data: res }) => {
        const newArrayRecords = res.result.user_reports.map((element) => {
          const findUser = selectedUsers.find(
            (elementTwo) => elementTwo.pk === element.user
          );

          if (findUser) {
            element.user = `${findUser.firstname} ${findUser?.lastname || ""}`;
            element.duration = formatTime(element.duration);
          }
          return element;
        });

        const formattedReport = formatData(
          res.result.per_hour_report,
          timeZone
        );

        let ObjectCalls = [];
        let objectHours = Object.keys(formattedReport);

        Object.keys(formattedReport).map((element) => {
          ObjectCalls.push(formattedReport[element]);
        });

        setTotalCalls(res.result.summary.total_calls);
        setXLabel(objectHours);
        setYLabel(ObjectCalls);

        setDataTable({
          title: [
            { title: "Name", key: "user" },
            { title: "Total Calls", key: "total_calls" },
            { title: "Completed Calls", key: "completed" },
            { title: "Missed Calls", key: "missed" },
            { title: "Missed %", key: "missed_percentage" },
            { title: "Total Duration", key: "duration" },
            { title: "Average Duration", key: "avg_duration" },
          ],
          content: [...newArrayRecords],
          actions: {
            title: "Actions",
            content: [
              {
                name: "View Call logs",
                icon: (
                  <img
                    style={{ width: "20px", height: "20px" }}
                    src={view}
                    alt=""
                  />
                ),
                handleClick: (e) => activeViewLogs(e),
              },
            ],
          },
        });
        setLoading(false);
      })
      .catch((error) => console.log(error));
  };

  const petitionNumbersReport = () => {
    setLoading(true);

    const data = {
      numbers: selectedNumbers.map((number) => number.number),
    };

    const newTimeStart = new Date(timeframe.timeStart);
    const newTimeEnd = new Date(
      parseInt(timeframe.timeEnd.split("-")[0]),
      parseInt(timeframe.timeEnd.split("-")[1]) - 1,
      parseInt(timeframe.timeEnd.split("-")[2]),
      23,
      59,
      59
    );

    const parameter = `?start_date=${newTimeStart.getTime() / 1000}&end_date=${
      newTimeEnd.getTime() / 1000
    }`;

    petitionPost("numberCallReports", { data, parameter })
      .then(({ data: res }) => {
        const newArrayRecords = res.result.numbers_report.map((element) => {
          element.duration = formatTime(element.duration);
          element.avg_duration = formatTime(element.avg_duration);

          return element;
        });

        const formattedReport = formatData(
          res.result.per_hour_report,
          timeZone
        );

        let ObjectCalls = [];
        let objectHours = Object.keys(formattedReport);

        Object.keys(formattedReport).map((element) => {
          ObjectCalls.push(formattedReport[element]);
        });

        setTotalCalls(res.result.summary.total_calls);
        setXLabel(objectHours);
        setYLabel(ObjectCalls);

        setDataTable({
          title: [
            { title: "Name", key: "number" },
            { title: "Total Calls", key: "total_calls" },
            { title: "Completed Calls", key: "completed" },
            { title: "Missed Calls", key: "missed" },
            { title: "Missed %", key: "missed_percentage" },
            { title: "Total Duration", key: "duration" },
            { title: "Average Duration", key: "avg_duration" },
          ],
          content: [...newArrayRecords],
          actions: {
            title: "Actions",
            content: [
              {
                name: "View Call logs",
                icon: (
                  <img
                    style={{ width: "20px", height: "20px" }}
                    src={view}
                    alt=""
                  />
                ),
                handleClick: (e) => activeViewLogs(e),
              },
            ],
          },
        });
        setLoading(false);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if (selectedReport === "Users") {
      petitionUserReport();
    } else {
      petitionNumbersReport();
    }
  }, []);

  return (
    <>
      <div onClick={handleBackClick} className="itp-tex-back-container">
        <div className="itp-text-container-funnel-details">
          <img src={arrowleft} alt="arrow-left" />
          <span className="itp-text-back-funnel-details">
            Back to{" "}
            {!viewLogs
              ? "Analytics"
              : selectedReport === "Users"
              ? "User Report"
              : "Number report"}
          </span>
        </div>
      </div>

      {loading ? (
        <Loading />
      ) : (
        <>
          {viewLogs ? (
            <>
              <ViewLogs
                data={viewLogs}
                loadingCall={loadingCall}
                width={width}
                From={From}
                to={to}
                totalCalls={totalCalls}
                completedLogs={completedLogs}
                selectedReport={selectedReport}
              />
            </>
          ) : (
            <>
              <div
                style={{ marginBottom: "-2rem" }}
                className="itp-custom-table-container-actions"
              >
                <div className="itp-custom-table-action"></div>
                <div className="itp-custom-table-add-buttons">
                  <Card className="itp-data-report-card-data">
                    <CardBody>
                      <div className="d-flex justify-content-between align-items-center">
                        <span className="itp-data-report-date">
                          Report Date :
                        </span>
                        <div className="d-flex align-items-center">
                          <span className="itp-data-report-range-date">
                            From : {From}
                          </span>
                          <FontAwesomeIcon
                            style={{ marginLeft: "12px", marginRight: "12px" }}
                            icon={faArrowRight}
                          />
                          <span className="itp-data-report-range-date">
                            To : {to}
                          </span>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                  <Card className="itp-data-report-card-data">
                    <CardBody>
                      <div className="d-flex align-items-center">
                        <span className="itp-data-report-date">
                          Total Calls :
                        </span>
                        <span className="itp-data-report-range-date">
                          {totalCalls}
                        </span>
                      </div>
                    </CardBody>
                  </Card>
                </div>
              </div>
              <div>
                <CustomTable
                  data={dataTable}
                  noPagBack={true}
                  overflowCard={width > 991 ? "inherit" : "auto"}
                />
              </div>
              <Card className="itp-table-container-allf mb-5 mt-4">
                <div
                  className="text-center"
                  style={{ margin: "10px 10px 10px 10px" }}
                >
                  <span className="itp-label-input__label">
                    Call Volume per Hour
                  </span>
                </div>

                <div>
                  <ChartLine xLabel={xLabel} yLabel={yLabel} />
                </div>
              </Card>
            </>
          )}
        </>
      )}
    </>
  );
};

export default DataReport;
