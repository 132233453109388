import React, { Fragment, useEffect, useState } from "react";
import {
  PaginationITP,
  SelectCheckbox,
  ToggleImage,
  Loading,
  SelectSingleCheckbox,
  LoadingButton,
} from "../index";
import arrowDown from "../../assets/icons/arrowDown.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook, faFolder, faPlus } from "@fortawesome/free-solid-svg-icons";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { formatDate } from "../../utils/getDate";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import SearchIcon from "@mui/icons-material/Search";
import Switch from "react-switch";
import "./CustomTableFunnels.css";

function CustomTableFunnels({
  data,
  centered,
  optionSelectCheckbox,
  defaultSelectedCheckbox,
  resize,
  paginationData,
  noPagBack,
  allResult,
  loading,
  textNoData,
  overflowCard,
  loadingIcon,
}) {
  const cantPagination = parseInt(paginationData) || 10;
  const [pagination, setPagination] = useState(cantPagination);
  const [paginationITP, setPaginationITP] = useState(cantPagination);
  const [dataFilter, setDataFilter] = useState([]);
  const [openDropdownIndex, setOpenDropdownIndex] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [loadingPagination, setLoadingPagination] = useState(false);
  const [inputFocused, setInputFocused] = useState(false);
  const [valueSwitchs, setvalueSwitchs] = useState({});
  const [valueSearchInput, setValueSearchInput] = useState(
    data?.search?.defaultValue || ""
  );
  const defaultValuePagination = paginationData ? paginationData : "10";

  const optionsPagination = [
    { value: "10", label: "10" },
    { value: "50", label: "50" },
    { value: "100", label: "100" },
  ];

  const Offsymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 0,
        }}
      >
        {" "}
        No
      </div>
    );
  };

  const OnSymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 0,
        }}
      >
        {" "}
        Yes
      </div>
    );
  };

  const handleInputFocus = () => {
    setInputFocused(true);
  };

  const handleInputBlur = () => {
    setInputFocused(false);
  };

  const handleRowSelect = (e, rowIndex) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      setSelectedRows((prevSelectedRows) => [
        ...prevSelectedRows,
        dataFilter[rowIndex].pk,
      ]);
    } else {
      setSelectedRows((prevSelectedRows) =>
        prevSelectedRows.filter((row) => row !== dataFilter[rowIndex].pk)
      );
    }
  };

  const handleCheckAll = (e) => {
    if (e.target.checked) {
      let currentRows = selectedRows;
      let newRows = dataFilter.map((element) => element.pk);
      let joinedArrays = [...currentRows, ...newRows];

      let deletedDuplicateElements = [...new Set(joinedArrays)];
      setSelectedRows(deletedDuplicateElements);
    } else {
      let newRows = selectedRows;
      let deleteRows = dataFilter;

      deleteRows.forEach((element) => {
        let index = newRows.indexOf(element.pk);
        if (index !== -1) {
          newRows.splice(index, 1);
        }
      });
      setSelectedRows([...newRows]);
    }
  };

  const handleCopyEmail = (event, email) => {
    event.stopPropagation();
    navigator.clipboard
      .writeText(email)
      .then(() => {})
      .catch((error) => {
        console.error(error);
      });
  };

  const toggle = (event, index) => {
    event.stopPropagation();
    setOpenDropdownIndex(index === openDropdownIndex ? null : index);
  };

  const validViewColumn = (arr) => {
    return arr?.filter(
      (el) =>
        el.viewColumn === undefined ||
        el.viewColumn == null ||
        el.viewColumn === true
    );
  };

  const returnDate = (date, spliter) => {
    let formatedDate = formatDate(date, spliter);
    let day = formatedDate.split("at ")[0];
    let hour = formatedDate.split("at ")[1];
    return (
      <>
        <span>{day}</span>
        <span className="itp-custom-table-hour">
          {" at "}
          {hour}
        </span>
      </>
    );
  };

  const returnArray = (arr) => {
    const MAX_ELEMENTS = 3;

    if (!arr || !Array.isArray(arr)) {
      // handle the case where arr is not an array or undefined
      return null;
    }

    if (arr.length <= MAX_ELEMENTS) {
      return arr.map((element, i) => (
        <span key={i} className="itp-custom-table-array">
          {element}
        </span>
      ));
    } else {
      return (
        <>
          {arr.slice(0, 3).map((element, i) => (
            <span className="itp-custom-table-array" key={i}>
              {element}
            </span>
          ))}
          <span className="itp-custom-table-array">
            + {arr.length - 3} {<img src={arrowDown} alt="Arrow Down" />}
          </span>
        </>
      );
    }
  };

  const returnArrayInfo = (arr) => {
    return arr.map((element, i) => (
      <span key={i} className="itp-custom-table-array-info">
        {element.icon} {element.name}
      </span>
    ));
  };

  const returnEmail = (email) => {
    return (
      <>
        <span className="email-text">{email}</span>
        {email && (
          <>
            <span
              className="container-copy"
              onClick={(event) => handleCopyEmail(event, email)}
            >
              <FontAwesomeIcon className="icon-hover" icon={faCopy} />
            </span>
            <span className="text-copy">Copy</span>
          </>
        )}
      </>
    );
  };

  const returnCheck = (state) => {
    if (state === true || state === "true" || state === "True") {
      return "Enabled";
    } else if (state === false || state === "false" || state === "False") {
      return "Disabled";
    }
  };

  const returnRowWithIcon = (element, elementTwo) => {
    if (element.hasIcon) {
      return (
        <div className="d-flex justify-content-between">
          <span className="email-text">
            {elementTwo.secondKey
              ? element[elementTwo.key][elementTwo.secondKey]
              : element[elementTwo.key]}
          </span>
          <span
            onClick={(event) => {
              event.stopPropagation();
              data.iconFunction(element);
            }}
          >
            {elementTwo.icon}
          </span>
        </div>
      );
    } else {
      return elementTwo.secondKey
        ? element[elementTwo.key][elementTwo.secondKey]
        : element[elementTwo.key];
    }
  };

  const returnLink = (element, elementTwo) => {
    return (
      <Link
        to={`/${elementTwo.pag}?${element[elementTwo.keyRedirect]}`}
        className="redirect"
      >
        {elementTwo.secondKey
          ? element[elementTwo.key][elementTwo.secondKey]
          : element[elementTwo.key]}
      </Link>
    );
  };

  const returnBadge = (element, elementTwo) => {
    const data = elementTwo.secondKey
      ? element[elementTwo.key][elementTwo.secondKey]
      : element[elementTwo.key];

    const styles = element[elementTwo.keyStyles];

    return <span style={styles}>{data}</span>;
  };

  useEffect(() => {
    if (!loadingPagination) {
      window.scrollTo(0, scrollPosition);
    }
  }, [loadingPagination, scrollPosition]);

  useEffect(() => {
    if (paginationITP != pagination)
      setPagination(paginationITP * cantPagination);
  }, [paginationITP, pagination]);

  useEffect(() => {
    if (data) {
      if (data.switchs) {
        let values = valueSwitchs;
        data.switchs.forEach((element) => {
          values[element.name] = element.value;
        });

        setvalueSwitchs(values);
      }

      setDataFilter(data.content);
      setLoadingPagination(false);
    }
  }, [data]);

  return (
    <>
      {data.titleTable && (
        <h1 className={`itp-custom-table-title${centered ? " centered" : ""}`}>
          {data.titleTable}
        </h1>
      )}

      {!data.noActions && (
        <div
          style={{width:"96%", marginLeft:"30px", marginTop:"20px", marginBottom:"10px"}}
          className={`itp-custom-table-container-actionsf${
            resize ? " resize" : ""
          }${data.label ? " label" : ""}`}
        >
        <div 
                className={`itp-custom-table-actions${
                  data.subtitleW50 ? " w-50" : ""
                }`}
              >
                {data.columnCheckbox && !centered && (
                  <div className="itp-container-bulk-actions">
                    <div className="itp-bulk-actions">
                      <label className="itp-label-bulk-actions">
                        {data.columnCheckbox.labelBulkActions}
                      </label>
                      {data.columnCheckbox.actions.map((element, i) => (
                        <div
                          className={`itp-icon-bulk-action${
                            i === data.columnCheckbox.actions.length - 1
                              ? " itp-last-icon-bulk-actions"
                              : ""
                          }`}
                          key={i}
                          onClick={() => element.handleClick(selectedRows)}
                        >
                          <div className="icon-hover itp-icon-hover-background">
                            {element.icon}
                          </div>
                          <div className="itp-bulk-icon-hover">
                            <span className="itp-bulk-icon-name">
                              {element.name}
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}

                {data.viewMultiSelect === true && (
                  <SelectCheckbox
                    name="Display Columns"
                    options={optionSelectCheckbox}
                    defaultSelected={defaultSelectedCheckbox}
                    checkFunction={(e) => data.check(e, data)}
                    style={!data.columnCheckbox ? { marginLeft: "1.8rem" } : {}}
                  />
                )}
                {data.label && (
                  <>
                    {data.labelIsTitle ? (
                      <h1
                        className={`itp-custom-table-title${
                          centered ? " centered" : ""
                        }`}
                      >
                        {data.label}
                      </h1>
                    ) : (
                      <label
                        className={`itp-custom-table-label${
                          centered ? " centered" : ""
                        }`}
                      >
                        {data.label}
                      </label>
                    )}
                  </>
                  
                )}
              {data.search && (
                  <div
                        style={{marginLeft:"0px"}}
                        className={`itp-custom-table-container-search-input${
                          centered ? " centered" : ""
                        }${inputFocused ? " focused-input" : ""}`}
                      >
                        <SearchIcon className="itp-custom-table-search-icon" />
                        <input
                          className="itp-custom-table-search-input"
                          placeholder={data.search.placeholder || ""}
                          onChange={(e) => {
                            setValueSearchInput(e.target.value);
                            data.search.handleChange(e);
                          }}
                          onFocus={handleInputFocus}
                          onBlur={handleInputBlur}
                          value={valueSearchInput}
                        />
                  </div>
              )}
              </div>
          
              
              <br></br>
              <br></br>
              
              <div
                  style={{marginLeft:"190px"}}
                  className={`itp-custom-table-add-buttons ${
                    resize ? " resize" : ""
                  }${!data.showFilter ? "mr-top-addButton" : ""}`}
              >
                  <div className="itp-custom-table-container-switchs">
                    {data.switchs && Object.keys(valueSwitchs).length > 0 && (
                      <>
                        {data.switchs.map((element) => (
                          <div
                            key={element.name}
                            className="d-flex align-items-center"
                            >
                            <label className="itp-custom-table-switch-label">
                              {element.name}
                            </label>
                            <Switch
                              uncheckedIcon={<Offsymbol width={4} height={4} />}
                              className="itp-custom-table-switch"
                              checkedIcon={<OnSymbol width={4} height={4} />}
                              onColor="#626ed4"
                              height={20} // Ajusta la altura según tus necesidades
                              width={40}
                              onChange={() => {
                                setvalueSwitchs({
                                  ...valueSwitchs,
                                    [element.name]: !valueSwitchs[element.name],
                                });
                                element.onChange(
                                  !valueSwitchs[element.name],
                                  element.name
                                );
                              }}
                              checked={valueSwitchs[element.name]}
                            />
                          </div>
                        ))}
                      </>
                    )}
                  </div>

                      {data.showFilter && (
                        <button
                          onClick={data.showFilter.handleClick}
                          className="btn-light ipt-show-filter-button"
                        >
                          <FontAwesomeIcon icon={faPlus} className="itp-icon-plus" />
                          Add Filters
                        </button>
                      )}

                      {data.addButton && (
                        <button
                          onClick={data.addButton.handleClick}
                          className={`btn-primary itp-add-button${
                            centered ? " centered" : ""
                          }`}
                        >
                          {!data.addButton.notIcon && (
                            <FontAwesomeIcon icon={faPlus} className="itp-icon-plus" />
                          )}{" "}
                          {data.addButton.label}
                        </button>
                      )}
              </div>
          </div>
      )}
      {!noPagBack && (
        <>
          {dataFilter.length === 0 && parseInt(allResult?.offset) === 0 && (
            <Card
              className={
                centered
                  ? "itp-table-container-centered"
                  : `itp-table-container-all${
                      resize ? " resize" : ""
                    }`
              }
            >
              <CardBody className="itp-custom-table-no-data">
                You currently have no {textNoData || "records"}
              </CardBody>
            </Card>
          )}

          {(dataFilter.length > 0 || parseInt(allResult?.offset) > 0) && (
            <>
              <Card
                style={{ overflow: overflowCard ? overflowCard : "", overflow:"auto", width:"96%"}}
                className={
                  centered
                    ? `itp-table-container-centered${
                        dataFilter.length === 1 ? " overflow-inherit" : ""
                      }`
                    : `itp-table-container-call ${resize ? " resize" : ""}`
                }
              >
                {loadingPagination || loading ? (
                  <Loading />
                ) : (
                  <div>
                    {data.titleCard && (
                      <h1 className="itp-custom-table-title-card">
                        {data.titleCard}
                      </h1>
                    )}

                    {data.subtitleCard && (
                      <p className="itp-custom-table-subtitle-card">
                        {data.subtitleCard}
                      </p>
                    )}

                    <table className="itp-custom-table">
                      <thead>
                        <tr className="itp-custom-table-thead-styles">
                          {data.columnCheckbox && (
                            <th className="itp-custom-table-th-styles">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                checked={dataFilter.every((element) =>
                                  selectedRows.includes(element.pk)
                                )}
                                onChange={handleCheckAll}
                              />
                            </th>
                          )}

                          {validViewColumn(data.title)?.map((element, i) => (
                            <th key={i} className="itp-custom-table-th-styles">
                              {element.title}
                            </th>
                          ))}

                          {data.actions && (
                            <th className="itp-custom-table-th-styles">
                              {data.actions.title}
                            </th>
                          )}

                          {data.iconActions && (
                            <th className="itp-custom-table-th-styles text-center">
                              {data.iconActions.title}
                            </th>
                          )}

                          {data.switchComponent && (
                            <th className="itp-custom-table-th-styles text-center">
                              {data.switchComponent.title}
                            </th>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {dataFilter.map((element, i) => (
                          <tr
                            onClick={
                              data.clickRow
                                ? () => data.clickRow(element)
                                : () => {}
                            }
                            key={i}
                            className="itp-custom-table-row"
                          >
                            {data.columnCheckbox && (
                              <td onClick={(event) => event.stopPropagation()}>
                                <input
                                  type="checkbox"
                                  checked={selectedRows.includes(
                                    dataFilter[i].pk
                                  )}
                                  onChange={(e) => handleRowSelect(e, i)}
                                  className="form-check-input"
                                />
                              </td>
                            )}

                            {validViewColumn(data.title)?.map(
                              (elementTwo, i2) => (
                                <td
                                  key={i2}
                                  className="itp-custom-table-td-styles"
                                  style={{
                                    ...(elementTwo?.style || {}),
                                    borderLeft: elementTwo.keyColor
                                      ? `6px solid ${
                                          element[elementTwo.keyColor]
                                        }`
                                      : "",
                                    color: elementTwo.isColor
                                      ? element[elementTwo.isColor]
                                      : "",
                                  }}
                                >
                                  {elementTwo.isBadge &&
                                    returnBadge(element, elementTwo)}

                                  {elementTwo.isLink && (
                                    <span
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        elementTwo.onChange(element);
                                      }}
                                    >
                                      {element[elementTwo.key]}
                                    </span>
                                  )}

                                  {elementTwo.hasIcon &&
                                    returnRowWithIcon(element, elementTwo)}

                                  {elementTwo.type === "dropdown" && (
                                    <select
                                      onChange={(e) => {
                                        elementTwo.onHandleClick(
                                          data,
                                          element["row"],
                                          e.target.value
                                        );
                                      }}
                                      className="form-select-itp"
                                      value={element[elementTwo.key]}
                                    >
                                      <option value=""> Select One </option>
                                      {elementTwo.options.map(
                                        (option, index2) => (
                                          <option
                                            key={index2}
                                            value={option.value}
                                          >
                                            {option.label}
                                          </option>
                                        )
                                      )}
                                    </select>
                                  )}

                                  {elementTwo.isDate &&
                                    (elementTwo.secondKey
                                      ? returnDate(
                                          element[elementTwo.key][
                                            elementTwo.secondKey
                                          ],
                                          elementTwo.spliter
                                        )
                                      : returnDate(
                                          element[elementTwo.key],
                                          elementTwo.spliter
                                        ))}

                                  {elementTwo.isArray &&
                                    (elementTwo.secondKey
                                      ? returnArray(
                                          element[elementTwo.key][
                                            elementTwo.secondKey
                                          ]
                                        )
                                      : returnArray(element[elementTwo.key]))}

                                  {elementTwo.isArrayInfo &&
                                    (elementTwo.secondKey
                                      ? returnArrayInfo(
                                          element[elementTwo.key][
                                            elementTwo.secondKey
                                          ]
                                        )
                                      : returnArrayInfo(
                                          element[elementTwo.key]
                                        ))}

                                  {elementTwo.isEmail &&
                                    (elementTwo.secondKey
                                      ? returnEmail(
                                          element[elementTwo.key][
                                            elementTwo.secondKey
                                          ]
                                        )
                                      : returnEmail(element[elementTwo.key]))}

                                  {elementTwo.check &&
                                    (elementTwo.secondKey
                                      ? returnCheck(
                                          element[elementTwo.key][
                                            elementTwo.secondKey
                                          ]
                                        )
                                      : returnCheck(element[elementTwo.key]))}

                                  {!elementTwo.isDate &&
                                    !elementTwo.isArray &&
                                    !elementTwo.isEmail &&
                                    !elementTwo.check &&
                                    elementTwo.type !== "dropdown" &&
                                    !elementTwo.hasIcon &&
                                    !elementTwo.isLink &&
                                    !elementTwo.isArrayInfo &&
                                    !elementTwo.isBadge &&
                                    (elementTwo.secondKey
                                      ? `${
                                          element[elementTwo.key][
                                            elementTwo.secondKey
                                          ]
                                        }`
                                      : `${element[elementTwo.key]}`)}
                                </td>
                              )
                            )}

                            {data.actions && (
                              <td>
                                <Dropdown
                                  isOpen={i === openDropdownIndex}
                                  toggle={(event) => toggle(event, i)}
                                >
                                  <DropdownToggle className="nav-link">
                                    <ToggleImage />
                                  </DropdownToggle>
                                  <DropdownMenu
                                    className={
                                      dataFilter.length < 7
                                        ? "max-length-six"
                                        : ""
                                    }
                                  >
                                    {data.actions.content.map(
                                      (elementTwo, i2) => (
                                        <DropdownItem
                                          key={i2}
                                          onClick={() =>
                                            elementTwo.handleClick(element)
                                          }
                                          className="dropdown-item"
                                        >
                                          {elementTwo.icon} {elementTwo.name}
                                        </DropdownItem>
                                      )
                                    )}
                                  </DropdownMenu>
                                </Dropdown>
                              </td>
                            )}

                            {data.iconActions && (
                              <td className="text-center">
                                {loadingIcon === element ? (
                                  <>
                                    <LoadingButton />
                                  </>
                                ) : (
                                  <span
                                    onClick={() => {
                                      data.iconActions.handleClick(element);
                                    }}
                                  >
                                    {data.iconActions.icon}
                                  </span>
                                )}
                              </td>
                            )}

                            {data.switchComponent && (
                              <td className="text-center">
                                {data.switchComponent.content.map(
                                  (elementTwo, indexThree) => (
                                    <Fragment key={indexThree}>
                                      {elementTwo.type === "component" &&
                                        elementTwo.component(element)}
                                      {elementTwo.type !== "component" &&
                                        elementTwo.switch && (
                                          <Switch
                                            className="itp-custom-table-switch"
                                            onColor="#626ed4"
                                            height={20}
                                            width={40}
                                            checked={
                                              element[`${elementTwo.keySwitch}`]
                                            }
                                            onChange={() => {
                                              elementTwo.handleClick(element);
                                            }}
                                          />
                                        )}
                                    </Fragment>
                                  )
                                )}
                              </td>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </Card>
              {allResult && (
                <div
                  className={`tp-custom-table-title${
                    centered ? " centered" : ""
                  } container-footer-table${
                    parseInt(allResult.total_pages) === 0 ? " gap-0" : ""
                  }`}
                >
                  <div className="container-pagination">
                    {parseInt(allResult.total_pages) !== 0 && (
                      <PaginationITP
                        pages={parseInt(allResult.total_pages) + 1}
                        active={
                          allResult.offset === null
                            ? 1
                            : parseInt(allResult.offset) === 0
                            ? parseInt(allResult.items_per_page) /
                              parseInt(allResult.items_per_page)
                            : Math.floor(
                                parseInt(allResult.offset) /
                                  parseInt(allResult.items_per_page)
                              ) + 1
                        }
                        setActive={(page) => {
                          setScrollPosition(window.scrollY);
                          setLoadingPagination(true);
                          data.changePage(page);
                        }}
                      />
                    )}
                  </div>
                  {data.itemsPerPage && (
                    <div className="container-item-per-page">
                      <SelectSingleCheckbox
                        name="Items Per Page"
                        options={optionsPagination}
                        defaultSelected={defaultValuePagination}
                        checkFunction={(e) => data.itemsPerPage(e)}
                      />
                    </div>
                  )}
                </div>
              )}
            </>
          )}
        </>
      )}

      {noPagBack && (
        <>
          {dataFilter.length === 0 && (
            <Card
              className={
                centered
                  ? "itp-table-container-centered"
                  : `itp-table-container-all${
                      resize ? " resize" : ""
                    }`
              }
            >
              <CardBody className="itp-custom-table-no-data">
                You currently have no {textNoData || "records"}
              </CardBody>
            </Card>
          )}

          {dataFilter.length > 0 && (
            <>
              <Card
                style={{zIndex:"0", marginTop:"15px", overflow: overflowCard ? overflowCard : ""}}
                className={
                  centered
                    ? "itp-table-container-centered"
                    : `itp-table-container-all ${resize ? " resize" : ""}`
                }
              >
                <table className="itp-custom-table">
                  <thead>
                    <tr className="itp-custom-table-thead-styles">
                      {data.columnCheckbox && (
                        <th className="itp-custom-table-th-styles">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            checked={selectedRows.length === dataFilter.length}
                            onChange={handleCheckAll}
                          />
                        </th>
                      )}

                      {validViewColumn(data.title)?.map((element, i) => (
                        <th key={i} className="itp-custom-table-th-styles">
                          {element.title}
                        </th>
                      ))}

                      {data.actions && (
                        <th className="itp-custom-table-th-styles">
                          {data.actions.title}
                        </th>
                      )}

                      {data.iconActions && (
                        <th className="itp-custom-table-th-styles text-center">
                          {data.iconActions.title}
                        </th>
                      )}

                      {data.switchComponent && (
                        <th className="itp-custom-table-th-styles text-center">
                          {data.switchComponent.title}
                        </th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {dataFilter.map((element, i) => {
                      if (
                        (i < pagination && i >= pagination - cantPagination) ||
                        !pagination
                      ) {
                        return (
                          <tr
                            onClick={
                              data.clickRow
                                ? () => data.clickRow(element)
                                : () => {}
                            }
                            key={i}
                            className="itp-custom-table-row"
                          >
                            {data.columnCheckbox && (
                              <td onClick={(event) => event.stopPropagation()}>
                                <input
                                  type="checkbox"
                                  checked={selectedRows.includes(i)}
                                  onChange={(e) => handleRowSelect(e, i)}
                                  className="form-check-input"
                                />
                              </td>
                            )}

                            {validViewColumn(data.title)?.map(
                              (elementTwo, i2) => (
                                <td
                                  key={i2}
                                  className="itp-custom-table-td-styles"
                                  style={elementTwo.style || {}}
                                >
                                  {elementTwo.isBadge &&
                                    returnBadge(element, elementTwo)}

                                  {elementTwo.isLink &&
                                    returnLink(element, elementTwo)}

                                  {elementTwo.hasIcon &&
                                    returnRowWithIcon(element, elementTwo)}

                                  {elementTwo.type === "dropdown" && (
                                    <select
                                      onChange={(e) => {
                                        elementTwo.onHandleClick(
                                          data,
                                          element["row"],
                                          e.target.value
                                        );
                                      }}
                                      className="form-select-itp"
                                      value={element[elementTwo.key]}
                                    >
                                      <option value=""> Select One </option>
                                      {elementTwo.options.map(
                                        (option, index2) => (
                                          <option
                                            key={index2}
                                            value={option.value}
                                          >
                                            {option.label}
                                          </option>
                                        )
                                      )}
                                    </select>
                                  )}

                                  {elementTwo.isDate &&
                                    (elementTwo.secondKey
                                      ? returnDate(
                                          element[elementTwo.key][
                                            elementTwo.secondKey
                                          ],
                                          elementTwo.spliter
                                        )
                                      : returnDate(
                                          element[elementTwo.key],
                                          elementTwo.spliter
                                        ))}

                                  {elementTwo.isArray &&
                                    (elementTwo.secondKey
                                      ? returnArray(
                                          element[elementTwo.key][
                                            elementTwo.secondKey
                                          ]
                                        )
                                      : returnArray(element[elementTwo.key]))}

                                  {elementTwo.isEmail &&
                                    (elementTwo.secondKey
                                      ? returnEmail(
                                          element[elementTwo.key][
                                            elementTwo.secondKey
                                          ]
                                        )
                                      : returnEmail(element[elementTwo.key]))}

                                  {elementTwo.check &&
                                    (elementTwo.secondKey
                                      ? returnCheck(
                                          element[elementTwo.key][
                                            elementTwo.secondKey
                                          ]
                                        )
                                      : returnCheck(element[elementTwo.key]))}

                                  {elementTwo.title == "Status" &&
                                    element["page"] == "form" &&
                                    !elementTwo.noInput && (
                                      <div class="form-check form-switch form-switch-lg">
                                        <input
                                          class="form-check-input"
                                          type="checkbox"
                                          role="switch"
                                          id="switchSizeLarge"
                                        />
                                        <label
                                          class="form-check-label"
                                          for="switchSizeLarge"
                                        >
                                          Enabled
                                        </label>
                                      </div>
                                    )}

                                  {elementTwo.title == "My Work flows" && (
                                    element['inherit'] == 'false' ? 
                                    (
                                    <div>
                                      <div className="d-flex">
                                        <FontAwesomeIcon className="icon-hover" icon={faBook} />
                                        <div className="ms-2">{element['my_work_flows']}</div>
                                      </div>
                                    </div>
                                    ) : 
                                    (
                                      <div>
                                        <div className="d-flex">
                                          <FontAwesomeIcon className="icon-hover" icon={faFolder} />
                                          <div className="ms-2">{element['my_work_flows']}</div>
                                        </div>
                                      </div>
                                    )
                                  )}

                                  {!elementTwo.isDate &&
                                    !elementTwo.isArray &&
                                    !elementTwo.isEmail &&
                                    !elementTwo.check &&
                                    elementTwo.type !== "dropdown" &&
                                    !elementTwo.hasIcon &&
                                    !elementTwo.isLink &&
                                    (elementTwo.title != "Status" ||
                                      elementTwo.noInput ||
                                      element["page"] != "form") &&
                                    elementTwo.title != "My Work flows" &&
                                    elementTwo.title != "Status" &&
                                    !elementTwo.isBadge &&
                                    (elementTwo.secondKey
                                      ? `${
                                          element[elementTwo.key][
                                            elementTwo.secondKey
                                          ]
                                        }`
                                      : `${element[elementTwo.key]}`)}
                                </td>
                              )
                            )}

                            {data.actions && (
                              <td>
                                <Dropdown
                                  isOpen={i === openDropdownIndex}
                                  toggle={(event) => toggle(event, i)}
                                >
                                  <DropdownToggle className="nav-link">
                                    <ToggleImage />
                                  </DropdownToggle>
                                  <DropdownMenu
                                    className="dropdown-menu centered"
                                    // tabIndex={9999}
                                    // className={
                                    //   dataFilter.length < 7
                                    //     ? "max-length-six"
                                    //     : ""
                                    // }
                                  >
                                    {data.actions.content.map(
                                      (elementTwo, i2) => (
                                        <DropdownItem
                                          key={i2}
                                          onClick={() =>
                                            elementTwo.handleClick(element)
                                          }
                                          // className="dropdown-item"
                                        >
                                          {elementTwo.icon} {elementTwo.name}
                                        </DropdownItem>
                                      )
                                    )}
                                  </DropdownMenu>
                                </Dropdown>
                              </td>
                            )}

                            {data.iconActions && (
                              <td className="text-center">
                                {loadingIcon === element ? (
                                  <>
                                    <LoadingButton />
                                  </>
                                ) : (
                                  <span
                                    onClick={() => {
                                      data.iconActions.handleClick(element);
                                    }}
                                  >
                                    {data.iconActions.icon}
                                  </span>
                                )}
                              </td>
                            )}

                            {data.switchComponent && (
                              <td className="text-center">
                                {data.switchComponent.content.map(
                                  (elementTwo, indexThree) => (
                                    <Fragment key={indexThree}>
                                      {elementTwo.type === "component" &&
                                        elementTwo.component(element)}
                                      {elementTwo.type !== "component" &&
                                        elementTwo.switch && (
                                          <Switch
                                            className="itp-custom-table-switch"
                                            onColor="#626ed4"
                                            height={20}
                                            width={40}
                                            checked={
                                              element[`${elementTwo.keySwitch}`]
                                            }
                                            onChange={() => {
                                              elementTwo.handleClick(element);
                                            }}
                                          />
                                        )}
                                    </Fragment>
                                  )
                                )}
                              </td>
                            )}
                          </tr>
                        );
                      }
                    })}
                  </tbody>
                </table>
              </Card>
              {dataFilter && dataFilter.length > cantPagination && (
                <div
                  style={{ maxWidth: "991px", margin: "auto" }}
                  className="mt-4"
                >
                  <PaginationITP
                    pages={
                      (dataFilter.length / cantPagination) % 1 === 0
                        ? Math.floor(dataFilter.length / cantPagination)
                        : Math.floor(dataFilter.length / cantPagination + 1)
                    }
                    active={pagination / cantPagination}
                    setActive={setPaginationITP}
                  />
                </div>
              )}
            </>
          )}
        </>
      )}
    </>
  );
}

export default CustomTableFunnels;
