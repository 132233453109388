import React from "react"

export default function UserAvatar({
  firstname,
  lastname,
  onlyFirstName,
  className,
  minHeight,
  style,
  textColor,
}) {
  const backgroundColor = "#efbae6";
  const fontColor= textColor || "#d653c1";

  return (
    <div
      className={`rounded-circle ${className || ""}`}
      style={{
        backgroundColor: `${backgroundColor}`,
        alignItems: "center",
        alignSelf: "start",
        display: "flex",
        fontSize: `${minHeight ? "1rem" : "1.25rem"}`,
        fontWeight: `${minHeight ? "500" : "700"}`,
        justifyContent: "center",
        textAlign: "center",
        ...style || {},
      }}
    >
      <span className="w-100" style={{ color: `${fontColor}` }}>
        {onlyFirstName
          ? firstname.toUpperCase().charAt(0)
          : firstname.toUpperCase().charAt(0) +
            lastname.toUpperCase().charAt(0)}
      </span>
    </div>
  );
}
