import * as type from "../types";
const initialState = {
  login: [],
  loading: false,
  error: null,
};

export default function login(state = initialState, action) {
  switch (action.type) {
    case type.POST_LOGIN_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case type.POST_LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        login: action.login,
      };
    case type.POST_LOGIN_FAILED:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
    case type.LOGOUT_REQUEST:
      return {
        ...state,
        login: [],
      };
    case type.LOGOUT_REQUEST:
      return state;
      
    default:
      return state;
  }
}
