import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrash } from "@fortawesome/free-solid-svg-icons";
import { CustomTableDefault, Loading, MenuRight, Filter } from "../../../components";
import AddLead from "./AddLead/AddLead";
import LeadDetails from "./LeadDetails/LeadDetails";
import ModalDelete from "./ModalUtility/ModalDelete";
import petitionGet from "../../../services/petitionGet";
import petitionPatch from "../../../services/petitionPatch";
import petitionPost from "../../../services/petitionPost";
import petitionDelete from "../../../services/petitionDelete";
import getQueryString from "../../../utils/getQueryString";
import "./Leads.css";
import { useNavigate } from "react-router-dom";

const Leads = () => {
  const navigate = useNavigate()
  const filterRef = useRef("");
  const firstLoad = useRef(true);
  const myExtension = useRef(null);
  const itemsPerPage = useRef("10");
  const pagination = useRef({
    offset: 0,
    limit: 10,
  });
  const [loading, setLoading] = useState(true);
  const [dataTable, setDataTable] = useState(null);
  const [openFilter, setOpenFilter] = useState(false);
  const [addLead, setAddLead] = useState(false);
  const [leadDetails, setLeadDetails] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [loadingFilter, setLoadingFilter] = useState(false);
  const [dataFilter, setDataFilter] = useState(null);
  const [tags, setTags] = useState([]);
  const [loadingLead, setLoadingLead] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [info, setInfo] = useState(null);
  const [defaultSelectedOptions, setDefaultSelectedOptions] = useState([]);
  const [allResult, setAllResult] = useState(null);
  const [leadSource, setLeadSource] = useState([]);
  const [funnels, setFunnels] = useState([]);
  const [loadingChangeItemsPerPage, setLoadingChangeItemsPerPage] =
    useState(false);

  const options = [
    { label: "Contact Name", value: "Contact Name" },
    { label: "Contact Email", value: "Contact Email" },
    { label: "Lead Name", value: "Lead Name" },
    { label: "Lead Source", value: "Lead Source" },
    { label: "Funnel", value: "Funnel" },
    { label: "Funnel Stage", value: "Funnel Stage" },
  ];

  const selectSubTitleMenu = () => {
    if ((openFilter || leadDetails) && openMenu && !addLead) {
      return false;
    } else if (!openFilter && openMenu && addLead && !leadDetails) {
      return "Add the details asked to add new contact";
    }
  };

  const selectTitleMenu = () => {
    if (openFilter && openMenu && !addLead && !leadDetails) {
      return "Add Filters";
    } else if (!openFilter && openMenu && addLead && !leadDetails) {
      return "Add New Lead";
    } else if (!openFilter && openMenu && !addLead && leadDetails) {
      return "Lead Details";
    }
  };

  const closeMenu = (reload) => {
    setLeadDetails(null)
    setAddLead(false);
    setOpenFilter(false);
    setOpenMenu(false);
    setInfo(null);
    setModalDelete(false);

    if (reload) {
      reloadData(false);
    }
  };

  const reloadData = (pag) => {
    closeMenu();
    if(pag){
      let parameter = getQueryString(pag) + filterRef.current
      petition(parameter)
      pagination.current = pag
    } else {
      let parameter = getQueryString(pagination.current) + filterRef.current
      petition(parameter);
    }
  };

  const activeAddLead = () => {
    setAddLead(true);
    setOpenMenu(true);
  };

  const activeFilterDetails = () => {
    setOpenFilter(true);
    setOpenMenu(true);
  };

  const activeLeadDetails = (e) => {
    setLeadDetails(e);
    setOpenMenu(true);
  };

  const activeModalDelete = (e) => {
    setInfo(e);
    setModalDelete(true);
  };

  const showColumns = (arrayColumns) => {
    petitionPatch("userDetails", {
      data: {
        leads_settings_display_columns: {
          columns: arrayColumns,
        },
      },
    })
      .then(({ data: result }) => {})
      .catch((error) => console.log(error));
  };

  const showOrNotShowcolumns = (name, arr) => {
    if (arr.includes(name)) {
      return false;
    } else {
      return true;
    }
  };

  const clickChange = (selected, data, titles) => {
    let noViewColumns = [];
    titles.forEach((element) => {
      if (selected.includes(element.title)) {
        element.viewColumn = true;
      } else {
        element.viewColumn = false;
        noViewColumns.push(element.title);
      }
    });
    showColumns(noViewColumns);
    setDataTable({ ...data, title: titles });
  };

  const changePage = (page) => {
    let paginationTemp = {
      limit: pagination.current.limit,
      offset: page === 1 ? 0 : (page - 1) * pagination.current.limit,
    };

    let parameter = getQueryString(paginationTemp) + filterRef.current

    petition(parameter, true);
    pagination.current = paginationTemp;
  };

  const activeItemsPerPage = (data) => {
    setLoadingChangeItemsPerPage(true);
    let paginationTemp = {
      offset: 0,
      limit: parseInt(data),
    };

    let parameter = getQueryString(paginationTemp) + (filterRef.current || "");
    petition(parameter, true);
    itemsPerPage.current = data;
    pagination.current = paginationTemp;
  };

  const onSaveFunction = (form) => {
    setLoadingFilter(true);
    let paginationTemp = {
      limit: pagination.current.limit,
      offset: 0,
    };

    if (!form.owner_id) {
      delete form.owner_id;
    } else {
      form.owner_id = myExtension.current.pk;
    }

    let filterModal = getQueryString(form).replaceAll("?", "&");
    let parameter = getQueryString(paginationTemp) + filterModal;

    petitionPatch("userDetails", {
      data: {
        crm_filters: {
          leads: form,
        },
      },
    })
      .then(({ data: result }) => {
        setLoadingFilter(false);
        closeMenu();
        firstLoad.current = false;
        setDataFilter({
          onSaveFunction: (form) => onSaveFunction(form),
          onHidde: () => console.log("hidde"),
          parameters: [
            {
              title: "Date",
              inputs: [
                {
                  label: "Lead Added Within",
                  input: "date",
                  inputValue_added_after: form.added_after || "",
                  inputValue_added_before: form.added_before || "",
                  checkbox: true,
                  checkboxValue:
                    form.added_after && form.added_before ? true : false,
                  checkboxName: "checkbox_date",
                  key_added_after: "added_after",
                  key_added_before: "added_before",
                },
              ],
            },
            {
              title: "Assigned Lead",
              inputs: [
                {
                  label: "Only Show Leads Assigned to Me",
                  input: "switch",
                  inputValue: form.owner_id ? true : false,
                  key: "owner_id",
                },
              ],
            },
            {
              title: "Tags",
              inputs: [
                {
                  label: "Tags Added to a Contact",
                  input: "multiSelect",
                  inputValue: form.tags || [],
                  checkbox: true,
                  checkboxValue: form.tags ? true : false,
                  checkboxName: `checkbox_tags`,
                  options: tags,
                  key: "tags",
                },
              ],
            },
            {
              title: "Lead Source",
              inputs: [
                {
                  label: "Lead Assigned to a Lead Source",
                  input: "selectAutoComplete",
                  inputValue: form.lead_source_id || [],
                  checkbox: true,
                  checkboxValue: form.lead_source_id ? true : false,
                  checkboxName: `checkbox_lead_source`,
                  options: leadSource,
                  key: "lead_source_id",
                },
              ],
            },
            {
              title: "Funnel",
              inputs: [
                {
                  label: "Lead Assigned to a Funnel",
                  input: "selectAutoComplete",
                  inputValue: form.funnel_id || [],
                  checkbox: true,
                  checkboxValue: form.funnel_id ? true : false,
                  checkboxName: `checkbox_funnel`,
                  options: funnels,
                  key: "funnel_id",
                },
              ],
            },
          ],
        });
        petition(parameter);
        filterRef.current = filterModal;
        pagination.current = paginationTemp;
      })
      .catch((error) => console.log(error));
  };

  const addNewContactAndLead = (form) => {
    setLoadingLead(true);
    let data = {
      firstname: form.firstname,
      lastname: form.lastname,
      phone: form.phone === "+" || form.phone.length === 2 ? "" : form.phone,
      email: form.email,
      contact_list_id: form.contact_list_id,
    };
    petitionPost("contact", { data })
      .then(({ data: result }) => {
        let newLead = {
          contact_id: result.result.pk,
          lead_source_id: form.lead_source_id,
          funnel_pipeline_stage_id: form.funnel_pipeline_stage_id,
          funnel_id: form.funnel_id,
        };

        addNewLead(newLead);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const addNewLead = (form) => {
    setLoadingLead(true);
    petitionPost("leads", { data: form })
      .then(({ data: result }) => {
        setLoadingLead(false);
        reloadData(false);
      })
      .catch((err) => console.log(err));
  };

  const deleteLead = () => {
    setLoadingLead(true);
    petitionDelete("lead", { lead_id: info.pk })
      .then(({ data: result }) => {
        setLoadingLead(false);
        if (allResult.result.length === 1 && parseInt(allResult.offset) !== 0) {
          let paginationTemp = {
            limit: pagination.current.limit,
            offset: pagination.current.offset - pagination.current.limit,
          };
          reloadData(paginationTemp);
        } else {
          reloadData(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const editContact = (form) => {
    setLoadingLead(true);
    petitionPatch("contact", { data: form, contact_id: leadDetails.contact.pk })
      .then(({ data: result }) => {
        setLoadingLead(false);
        reloadData(false)
      })
      .catch((error) => {
        console.log(error);
        setLoadingLead(false);
      });
  };

  const editLead = (contact, lead) => {
    setLoadingLead(true);
    petitionPatch("leads", {
      lead_id: leadDetails.pk,
      data: lead,
    })
      .then(({ data: result }) => {
        editContact(contact)
      })
      .catch((error) => console.log(error));
    // editContact()
  }

  const petitionTag = async () => {
    try {
      const response = await petitionGet("tags");
      const { data: result } = response;

      result.result.forEach((element) => {
        element.title = element.name;
        element.disabled = false;
        element.label = element.name;
        element.value = element.pk;
      });

      setTags(result.result);

      return result.result; // Devuelve la respuesta del endpoint
    } catch (error) {
      console.log(error);
    }
  };

  const onClickRowContact = (element) => {
    navigate(`/Contacts?${element.contact.pk}`)
  }

  const onClickRowFunnel = (element) => {
    navigate(`/Funnels?${element.funnel_pipeline_stage.funnel.pk}`)
  }

  const petition = async (parameter, noLoading) => {
    if (!noLoading) setLoading(true);

    let tagsTemp = [];
    let myExtensionTemp = {};
    let noViewColumns = [];
    let leadSourcesTemp = []
    let funnelsTemp = []

    tagsTemp = await petitionTag();

    await petitionGet("sources", {})
      .then(({ data: result }) => {
        result.result.forEach((element) => {
          element.label = `${element.name}`;
          element.value = element.pk;
        });

        leadSourcesTemp = result.result
        setLeadSource(result.result);
      })
      .catch((error) => console.log(error))

    await petitionGet("funnels", { parameter: "?offset=0&limit=1000" })
      .then(({ data: result }) => {
        result.result.forEach((funnel) => {
          funnel.label = funnel.name;
          funnel.value = funnel.pk;
        });

        funnelsTemp = result.result
        setFunnels(result.result);
      })
      .catch((error) => console.log(error));

    await petitionGet("userDetails")
      .then(({ data: result }) => {
        noViewColumns =
          result?.result?.leads_settings_display_columns?.columns || [];
        let defaultColumnsSelected = [];

        myExtensionTemp = result?.result;

        if (firstLoad.current && myExtensionTemp?.crm_filters?.leads) {
          if (myExtensionTemp?.crm_filters?.leads?.owner_id) {
            myExtensionTemp.crm_filters.leads.owner_id = myExtensionTemp?.pk;
          }

          let filterModal = getQueryString(myExtensionTemp?.crm_filters?.leads).replaceAll("?", "&");

          parameter = (parameter || "") + filterModal;

          filterRef.current = filterModal;
        }

        options.forEach((element) => {
          if (!noViewColumns.includes(element?.label)) {
            defaultColumnsSelected.push(element?.label);
          }
        });

        myExtension.current = result?.result;
        setDefaultSelectedOptions(defaultColumnsSelected);
      })
      .catch((error) => console.log(error));

    await petitionGet("leads", { parameter })
      .then(({ data: result }) => {
        setAllResult(result);
        result.result.forEach((element) => {
          element.contactName = `${element.contact.firstname} ${
            element.contact.lastname || ""
          }`;
          element.contactEmail = element.contact.email || "";
          element.name = element.name || "Unassigned";
          element.leadSource = element.lead_source.name;
          element.funnelName = element.funnel_pipeline_stage.funnel.name;
          element.funnelStage = element.funnel_pipeline_stage.name;
          element.paramContact = element.contact.pk;
          element.paramFunnel = element.funnel_pipeline_stage.funnel.pk;
        });

        let titles = [
          {
            title: "Contact Name",
            key: "contactName",
            viewColumn: showOrNotShowcolumns("Contact Name", noViewColumns),
            isLink: true,
            keyRedirect: "paramContact",
            pag: "Contacts",
            onChange: onClickRowContact
          },
          {
            title: "Contact Email",
            key: "contactEmail",
            viewColumn: showOrNotShowcolumns("Contact Email", noViewColumns),
          },
          {
            title: "Lead Name",
            key: "name",
            viewColumn: showOrNotShowcolumns("Lead Name", noViewColumns),
          },
          {
            title: "Lead Source",
            key: "leadSource",
            viewColumn: showOrNotShowcolumns("Lead Source", noViewColumns),
          },
          {
            title: "Funnel",
            key: "funnelName",
            viewColumn: showOrNotShowcolumns("Funnel", noViewColumns),
            isLink: true,
            keyRedirect: "paramFunnel",
            pag: "Funnels",
            onChange: onClickRowFunnel
          },
          {
            title: "Funnel Stage",
            key: "funnelStage",
            viewColumn: showOrNotShowcolumns("Funnel Stage", noViewColumns),
          },
        ];

        if (firstLoad.current && !myExtensionTemp?.crm_filters?.leads) {
          setDataFilter({
            onSaveFunction: (form) => onSaveFunction(form),
            onHidde: () => console.log("hidde"),
            parameters: [
              {
                title: "Date",
                inputs: [
                  {
                    label: "Lead Created Within",
                    input: "date",
                    inputValue_added_after: "",
                    inputValue_added_before: "",
                    checkbox: true,
                    checkboxValue: false,
                    checkboxName: "checkbox_date",
                    key_added_after: "added_after",
                    key_added_before: "added_before",
                  },
                ],
              },
              {
                title: "Assigned Lead",
                inputs: [
                  {
                    label: "Only Show Leads Assigned to Me",
                    input: "switch",
                    inputValue: false,
                    key: "owner_id",
                  },
                ],
              },
              {
                title: "Tags",
                inputs: [
                  {
                    label: "Tags Added to a Contact",
                    input: "multiSelect",
                    inputValue: [],
                    checkbox: true,
                    checkboxValue: false,
                    checkboxName: `checkbox_tags`,
                    options: tagsTemp,
                    key: "tags",
                  },
                ],
              },
              {
                title: "Lead Source",
                inputs: [
                  {
                    label: "Lead Assigned to a Lead Source",
                    input: "selectAutoComplete",
                    inputValue: [],
                    checkbox: true,
                    checkboxValue: false,
                    checkboxName: `checkbox_lead_source`,
                    options: leadSourcesTemp,
                    key: "lead_source_id",
                  },
                ],
              },
              {
                title: "Funnel",
                inputs: [
                  {
                    label: "Lead Assigned to a Funnel",
                    input: "selectAutoComplete",
                    inputValue: [],
                    checkbox: true,
                    checkboxValue: false,
                    checkboxName: `checkbox_funnel`,
                    options: funnelsTemp,
                    key: "funnel_id",
                  },
                ],
              },
            ],
          });
        } else {
          setDataFilter({
            onSaveFunction: (form) => onSaveFunction(form),
            onHidde: () => console.log("hidde"),
            parameters: [
              {
                title: "Date",
                inputs: [
                  {
                    label: "Lead Created Within",
                    input: "date",
                    inputValue_added_after:
                      myExtensionTemp?.crm_filters?.leads?.added_after || "",
                    inputValue_added_before:
                      myExtensionTemp?.crm_filters?.leads?.added_before || "",
                    checkbox: true,
                    checkboxValue:
                      myExtensionTemp?.crm_filters?.leads?.added_after &&
                      myExtensionTemp?.crm_filters?.leads?.added_before
                        ? true
                        : false,
                    checkboxName: "checkbox_date",
                    key_added_after: "added_after",
                    key_added_before: "added_before",
                  },
                ],
              },
              {
                title: "Assigned Lead",
                inputs: [
                  {
                    label: "Only Show Leads Assigned to Me",
                    input: "switch",
                    inputValue: myExtensionTemp?.crm_filters?.leads?.owner_id
                      ? true
                      : false,
                    key: "owner_id",
                  },
                ],
              },
              {
                title: "Tags",
                inputs: [
                  {
                    label: "Tags Added to a Contact",
                    input: "multiSelect",
                    inputValue: myExtensionTemp?.crm_filters?.leads?.tags || [],
                    checkbox: true,
                    checkboxValue: myExtensionTemp?.crm_filters?.leads?.tags
                      ? true
                      : false,
                    checkboxName: `checkbox_tags`,
                    options: tagsTemp,
                    key: "tags",
                  },
                ],
              },
              {
                title: "Lead Source",
                inputs: [
                  {
                    label: "Lead Assigned to a Lead Source",
                    input: "selectAutoComplete",
                    inputValue: myExtensionTemp.crm_filters.leads.lead_source_id || [],
                    checkbox: true,
                    checkboxValue: myExtensionTemp.crm_filters.leads.lead_source_id ? true : false,
                    checkboxName: `checkbox_lead_source`,
                    options: leadSourcesTemp,
                    key: "lead_source_id",
                  },
                ],
              },
              {
                title: "Funnel",
                inputs: [
                  {
                    label: "Lead Assigned to a Funnel",
                    input: "selectAutoComplete",
                    inputValue: myExtensionTemp.crm_filters.leads.funnel_id || [],
                    checkbox: true,
                    checkboxValue: myExtensionTemp.crm_filters.leads.funnel_id ? true : false,
                    checkboxName: `checkbox_funnel`,
                    options: funnelsTemp,
                    key: "funnel_id",
                  },
                ],
              },
            ],
          });
        }

        setDataTable({
          title: titles,
          content: [...result.result],
          actions: {
            title: "Action",
            content: [
              {
                name: "Edit Lead",
                handleClick: activeLeadDetails,
                icon: <FontAwesomeIcon icon={faPenToSquare} />,
              },
              {
                name: "Delete Lead",
                handleClick: activeModalDelete,
                icon: <FontAwesomeIcon icon={faTrash} />,
              },
            ],
          },
          viewMultiSelect: true,
          check: (e, data) => {
            clickChange(e, data, titles);
          },
          showFilter: {
            handleClick: activeFilterDetails,
          },
          addButton: {
            label: "Add New Lead",
            handleClick: activeAddLead,
          },
          changePage: (page) => changePage(page),
          itemsPerPage: (e) => activeItemsPerPage(e),
          clickRow: activeLeadDetails,
        });
        setLoading(false);
        setLoadingChangeItemsPerPage(false);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    petition(getQueryString(pagination.current));
  }, []);

  return (
    <div className={`itp-test itp-${itemsPerPage.current}-items`}>
      {loading ? (
        <Loading />
      ) : (
        <>
          {modalDelete && (
            <ModalDelete
              modal={modalDelete}
              closeModal={() => closeMenu(false)}
              loading={loadingLead}
              modalFunction={deleteLead}
            />
          )}
          <div>
            <CustomTableDefault
              data={dataTable}
              optionSelectCheckbox={options}
              defaultSelectedCheckbox={defaultSelectedOptions}
              allResult={allResult}
              paginationData={itemsPerPage.current}
              loading={loadingChangeItemsPerPage}
            />
          </div>

          <MenuRight
            show={openMenu}
            closeMenu={() => {
              closeMenu()
            }}
            title={selectTitleMenu()}
            subtitle={selectSubTitleMenu()}
            to="to Leads"
          >
            {openMenu && openFilter && !addLead && !leadDetails && (
              <Filter info={dataFilter} loading={loadingFilter} />
            )}

            {openMenu && addLead && !openFilter && !leadDetails && (
              <AddLead
                loadingAddLead={loadingLead}
                addNewContactAndLead={addNewContactAndLead}
                addNewLead={addNewLead}
                funnels={funnels}
                leadSource={leadSource}
              />
            )}

            {openMenu && leadDetails && !openFilter && !addLead && (
              <LeadDetails
                leadDetails={leadDetails}
                setLeadDetails={setLeadDetails}
                tagsExternal={tags}
                myExtension={myExtension.current}
                petitionTag={petitionTag}
                loadingEdit={loadingLead}
                editLead={editLead}
                funnels={funnels}
              />
            )}
          </MenuRight>
        </>
      )}
    </div>
  );
};

export default Leads;
