import React, { useEffect, useRef, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faTrash,
  faPenToSquare,
} from "@fortawesome/free-solid-svg-icons";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import {
  Loading,
  ColumnsOfDraggableCards,
  MenuRight,
} from "../../../../components";
import arrow from "../../../../assets/images/arrow.svg";
import userImage from "../../../../assets/images/userImage.png";
import petitionGet from "../../../../services/petitionGet";
import petitionPatch from "../../../../services/petitionPatch";
import LeadDetails from "./LeadDetails/LeadDetails";
import ModalDelete from "../ModalUtility/ModalDelete";
import petitionDelete from "../../../../services/petitionDelete";
import "../Funnels.css";

const FunnelDetails = ({ back, funnelDetails }) => {
  const filterDate = useRef("");
  const [loading, setLoading] = useState(true);
  const [dataFunnel, setDataFunnel] = useState(null);
  const [leads, setLeads] = useState([]);
  const [loadingDragEnd, setLoadingDragEnd] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [currentStartDate, setCurrentStartDate] = useState("");
  const [currentEndDate, setCurrentEndDate] = useState("");
  const [openMenu, setOpenMenu] = useState(false);
  const [loadingLead, setLoadingLead] = useState(false);
  const [leadDetails, setLeadDetails] = useState(null);
  const [modalDelete, setModalDelete] = useState(false);
  const [dashboardByDays, setDashboardByDays] = useState([])
 
  const compareStagesPosition = (a, b) => {
    if (a.stage_position < b.stage_position) {
      return -1;
    }
    if (a.stage_position > b.stage_position) {
      return 1;
    }
    return 0;
  };

  const compareCardPosition = (a, b) => {
    if (a.card_position < b.card_position) {
      return -1;
    }
    if (a.card_position > b.card_position) {
      return 1;
    }
    return 0;
  };

  const closeMenu = () => {
    setModalDelete(false);
    setLeadDetails(null);
    setOpenMenu(false);
  };

  const updateLeadAfterDraggable = (stage_id, data) => {
    setLoadingDragEnd(true);

    const patchPromises = data.map((element) => {
      if (element.card_position !== element.newPosition || element.newItem) {
        let data = {
          funnel_pipeline_stage_id: stage_id,
          card_position: element.newPosition,
        };
        return petitionPatch("leads", { data, lead_id: element.pk });
      } else {
        return Promise.resolve();
      }
    });

    Promise.all(patchPromises)
      .then(() => {
        petition(false, true);
      })
      .catch((error) => console.log(error));
  };

  const activeLeadDetails = (e) => {
    setLeadDetails(e);
    setOpenMenu(true);
  };

  const activeDeleteLead = (e) => {
    setLeadDetails(e);
    setModalDelete(true);
  };

  const applyFilter = () => {
    let parameter = `?added_after=${currentStartDate}&added_before=${currentEndDate}`;
    petition(parameter);
    filterDate.current = parameter;
    setStartDate(currentStartDate);
    setEndDate(currentEndDate);
  };

  const reloadData = () => {
    closeMenu();
    petition(filterDate.current);
  };

  const editLead = (contact, lead) => {
    setLoadingLead(true);
    petitionPatch("leads", {
      lead_id: leadDetails.pk,
      data: lead,
    })
      .then(({ data: result }) => {
        editContact(contact);
      })
      .catch((error) => console.log(error));
  };

  const editContact = (form) => {
    setLoadingLead(true);
    petitionPatch("contact", { data: form, contact_id: leadDetails.contact.pk })
      .then(({ data: result }) => {
        setLoadingLead(false);
        reloadData(false);
      })
      .catch((error) => {
        console.log(error);
        setLoadingLead(false);
      });
  };

  const deleteLead = () => {
    setLoadingLead(true);
    petitionDelete("lead", { lead_id: leadDetails.pk })
      .then(({ data: result }) => {
        setLoadingLead(false);
        reloadData();
      })
      .catch((error) => console.log(error));
  };

  const petition = async (parameter, noLoading) => {
    if (!noLoading) setLoading(true);

    const currentDateTimeLocal = new Date();

    const currentDateTime = new Date(
      currentDateTimeLocal.toLocaleString("en-US", { timeZone: "US/Eastern" })
    );

    let defaultDate =
      currentDateTime.getFullYear() +
      "-" +
      (currentDateTime.getMonth() + 1 < 10
        ? "0" + (currentDateTime.getMonth() + 1)
        : currentDateTime.getMonth() + 1) +
      "-" +
      currentDateTime.getDate();

    let allIdContacts = [];
    let objectTags = {};

    funnelDetails.stages.forEach((stage) => {
      stage.leads.forEach((lead) => {
        if (!allIdContacts.includes(lead.contact.pk)) {
          allIdContacts.push(lead.contact.pk);
        }
      });
    });

    allIdContacts.forEach(async (element) => {
      await petitionGet("contacts", { parameter: `/${element}` })
        .then(({ data: result }) => {
          objectTags[element] = result.result.tags.map((tag) => tag.tag.name);
        })
        .catch((error) => console.log(error));
    });

    await petitionGet("dashboardFunnel", {
      funnel_id: funnelDetails.pk,
      parameter,
    })
      .then(({ data: result }) => {
        result.result.dashboard_info.stages.sort(compareStagesPosition);

        result.result.dashboard_info.stages.forEach((stage) => {
          let totalAmount = 0;
          stage.leads.forEach((lead) => {
            totalAmount = totalAmount + lead.amount;
          });

          stage.total_amount = totalAmount;
          stage.total_leads = stage.leads.length;
        });

        let initialData = {
          columns: {},
        };

        const returnItems = (data) => {
          let number = Math.floor(Math.random() * 1000);
          let item = {
            ...data,
            id: `item-${number + 1}${data.pk}`,
            image: (
              <img src={userImage} alt="User" className="itp-profile-image" />
            ),
            title:
              data?.contact?.firstname + " " + (data?.contact?.lastname || ""),
            subtitle: data.name || "Unassigned",
            description:
              data.description ||
              "Purchase coupon lead is brief description of lead",
            labelFooter: `${data.amount}${data.amount < 10 ? ".0" : ".00"} $`,
            tags: objectTags[data.contact.pk],
            date: data.date_modified,
            actions: [
              {
                name: "Edit",
                icon: <FontAwesomeIcon icon={faPenToSquare} />,
                handleClick: activeLeadDetails,
              },
              {
                name: "Delete",
                icon: <FontAwesomeIcon icon={faTrash} />,
                handleClick: activeDeleteLead,
              },
            ],
          };

          return item;
        };

        result.result.dashboard_info.stages.forEach((stage) => {
          let orgStage = {
            ...stage,
            dataRows: stage.leads.sort(compareCardPosition),
          };
          let number = Math.floor(Math.random() * 1000);
          initialData.columns[`column-${number + 1}${stage.pk}`] = {
            id: `column-${number + 1}${stage.pk}`,
            title: stage.name,
            subtitle: `leads - ${stage.total_leads}&Earning : $${
              stage.total_amount
            }${stage.total_amount < 10 ? ".0" : ".00"}`,
            items: stage.leads
              .sort(compareCardPosition)
              .map((lead) => returnItems(lead)),
            info: orgStage,
          };
          stage.label = stage.name;
          stage.value = stage.pk;
        });

        const dashboardDays = Object.keys(result.result.dashboard_by_days).map((element) => (
          { name: element, value: result.result.dashboard_by_days[element] }
        ))

        setDashboardByDays(dashboardDays)
        setDataFunnel(result.result.dashboard_info);
        setLeads(initialData);
        setStartDate(defaultDate);
        setEndDate(defaultDate);
        setCurrentStartDate(defaultDate);
        setCurrentEndDate(defaultDate);
        setLoading(false);
        setLoadingDragEnd(false);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    petition();
  }, []);

  return loading ? (
    <Loading />
  ) : (
    <>
      {modalDelete && (
        <ModalDelete
          modal={modalDelete}
          closeModal={closeMenu}
          loading={loadingLead}
          modalFunction={deleteLead}
          text="Lead"
        />
      )}

      <div onClick={back} className="itp-tex-back-container">
        <div className="itp-text-container-funnel-details">
          <img src={arrow} alt="Voice360" />
          <span className="itp-text-back-funnel-details">
            Back to Funnels list
          </span>
        </div>
      </div>

      <Card className="itp-funnel-details-card">
        <CardBody>
          <h6 className="itp-funnel-details-title">
            {funnelDetails.name} : Funnel Details
          </h6>
          <Row className="d-flex align-items-center">
            <Col xs={6} sm={5} md={6} lg={6} xl={7} xxl={8}>
              <span className="itp-funel-details-subtitle">
                Details of funnel below
              </span>
            </Col>
            <Col
              className="d-flex justify-content-end flex-column"
              xs={12}
              sm={7}
              md={6}
              lg={6}
              xl={5}
              xxl={4}
            >
              <div className="funnel-details-select-date">
                <span className="name-date-range">Set Date :</span>
                <div className="container-date-range funnel-details">
                  <label className="date-range-label">From:</label>
                  <input
                    type="date"
                    className="date-range-input"
                    name="added_affter"
                    value={currentStartDate}
                    onChange={(e) => {
                      setCurrentStartDate(e.target.value);
                    }}
                  />
                  <div>
                    <FontAwesomeIcon
                      icon={faArrowRight}
                      className="date-range-separator"
                    />
                  </div>
                  <label className="date-range-label">To:</label>
                  <input
                    type="date"
                    className="date-range-input input-right-date"
                    name="added_before"
                    value={currentEndDate}
                    onChange={(e) => {
                      setCurrentEndDate(e.target.value);
                    }}
                  />
                </div>
              </div>
              {(startDate !== currentStartDate || endDate !== currentEndDate) &&
                currentStartDate &&
                currentEndDate && (
                  <div className="funel-details-apply-filter">
                    <button onClick={applyFilter} className="btn-primary">
                      Apply
                    </button>
                  </div>
                )}
            </Col>
          </Row>
          <Row className="itp-funnel-details-row-summary">
            <Col
              className="itp-funnel-details-summary"
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              xxl={12}
            >
              <span className="itp-funnel-details-summary-title">
                Leads Summary
              </span>
              <div className="d-flex">
                <span className="itp-funnel-detaiñ-summary-earnings">
                  Earnings :
                </span>
                <span className="itp-funnel-detaiñ-summary-number">
                  {dataFunnel.amount_total} $
                </span>
              </div>
              <div className="d-flex itp-funnel-details-total-leads">
                <div>
                  <label className="itp-funnel-detaiñ-summary-number">
                    {dataFunnel.total}
                  </label>
                  <br />
                  <label className="itp-funnel-detaiñ-summary-number">
                    Total Leads
                  </label>
                </div>
                <div className="itp-funnel-details-separator"></div>
                <div className="d-flex justify-content-between itp-funnel-details-leads-description">
                  {dataFunnel.stages.map((element, i) => (
                    <div className="d-flex flex-column" key={i}>
                      <label className="itp-funnel-details-lead-data-number">
                        {element.total}
                      </label>
                      <label className="itp-funnel-details-lead-data">
                        {element.name}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            </Col>
            <Col
              className="itp-funnel-details-summary graphic"
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              xxl={12}
            >
              <p>Sales Summary</p>
              <div className="h-100 w-100">
                <LineChart
                  width={1000}
                  height={300}
                  data={dashboardByDays}
                >
                 <CartesianGrid strokeDasharray="3 3" />
                 <XAxis dataKey="name" tick={{stroke: '#3a3a3a99'  }} />
                 <YAxis tick={{stroke: '#3a3a3a99'  }} />
                 <Tooltip />
                 <Legend />
                 <Line type="monotone" dataKey="value" stroke="rgb(20, 98, 181" activeDot={{ r: 5 }} />
                </LineChart>
             </div>
            </Col>
          </Row>
          <div className="itp-funnel-details-new-section">
            <label className="itp-funnel-details-lead-in-funel-title">
              Leads in Funnel
            </label>
            <br></br>
            <label className="itp-funnel-details-lead-in-funel-subtitle">
              View & edit the lead status of this funnel
            </label>
          </div>
          <ColumnsOfDraggableCards
            loading={loading}
            dataColumns={leads}
            setDataColumns={setLeads}
            textNoData="No Leads Added"
            dragEndFunction={updateLeadAfterDraggable}
            dragDisabled={loadingDragEnd}
          />
        </CardBody>
      </Card>

      <MenuRight
        show={openMenu}
        closeMenu={closeMenu}
        title="Lead Details"
        to="to Leads"
      >
        {openMenu && leadDetails && (
          <LeadDetails
            leadDetails={leadDetails}
            setLeadDetails={setLeadDetails}
            loadingEdit={loadingLead}
            editLead={editLead}
            dataFunnel={dataFunnel}
            funnelDetails={funnelDetails}
          />
        )}
      </MenuRight>
    </>
  );
};

export default FunnelDetails;
