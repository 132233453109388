import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
} from "reactstrap";
import { LoadingButton } from "../../../../../components";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Tag from "../../../../../assets/icons/addTag.svg";

const ModalTags = ({
  modal,
  closeModal,
  loading,
  modalFunction,
  title,
  tagsExternal,
}) => {
  const filter = createFilterOptions();
  const keyTag = title === "Add Tags" ? "added_tags" : "removed_tags"
  const [tags, setTags] = useState([]);
  const [optionsTags, setOptionsTags] = useState([]);
  const [form, setForm] = useState({
    [keyTag]: [],
  });


  const handleTag = (newValue) => {
    if (newValue.length === 0) {
      setOptionsTags(tagsExternal);
    } else {
      setTags(newValue);
    }
    let listPk = newValue.map((tag) => {
      return tag.pk;
    });
    setForm({
      [keyTag]: listPk,
    });
  };

  const saveFunction = () => {
    modalFunction(form)
  }

  useEffect(() => {
    setOptionsTags(tagsExternal);
  }, [tagsExternal]);

  return (
    <Modal isOpen={modal ? true : false} className="info">
      <ModalHeader>{title || "Modal Tag"}</ModalHeader>
      <ModalBody>
        <Row>
          <Col>
            <label className="itp-component-tag-label">Tags</label>
            <div className="itp-component-tag-container">
              <img src={Tag} alt="Add Tag" className="itp-component-tag-img" />
              <div className="itp-component-tag-width">
                <Stack>
                  <Autocomplete
                    disableClearable
                    disablePortal
                    filterSelectedOptions
                    freeSolo
                    multiple
                    defaultValue={tags}
                    getOptionLabel={(option) => {
                      if (typeof option === "string") {
                        return "error";
                      }

                      return option.title;
                    }}
                    getOptionDisabled={(option) => !!option.disabled}
                    id="tags-standard"
                    isOptionEqualToValue={(option, value) =>
                      option.title === value.title
                    }
                    onChange={(event, newValue) => handleTag(newValue)}
                    options={optionsTags}
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);

                      const { inputValue } = params;
                      // Suggest the creation of a new value
                      const isExisting = options.some(
                        (option) => inputValue === option.title
                      );

                      return filtered;
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        placeholder="Add Tags"
                      />
                    )}
                    renderOption={(props, option) => (
                      <li {...props}>{option.title}</li>
                    )}
                  />
                </Stack>
              </div>
            </div>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <button disabled={loading} className="btn-light" onClick={closeModal}>
          Cancel
        </button>
        <button
          disabled={loading}
          className="btn-primary loading"
          onClick={saveFunction}
        >
          {loading && <LoadingButton />}
          Save
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalTags;
