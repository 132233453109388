import React, { useState } from "react";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logoutRequest } from "../../redux/actions/login";
import { Search, UserAvatar } from "../index";
import logoHeader from "../../assets/images/logoHeader.png";
import userImage from "../../assets/images/userImage.png";
import phoneIcon from "../../assets/images/phoneIcon.svg";
import checkListIcon from "../../assets/images/checkListIcon.svg";
import settingsIcon from "../../assets/images/settingsIcon.svg";
import calendarIcon from "../../assets/images/calendarIcon.svg";
import arrow from "../../assets/images/arrow.svg";
import json from "../../config.json";
import "./Header.css";

const Header = ({ refHEader, handleToggle, toggleMenu, setShowWebphone }) => {
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];

  const dispatch = useDispatch();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dataLocalStorage = JSON.parse(localStorage.getItem("userDetails"));

  const urlInWorkspace = [
    "General-Settings",
    "API-Keys",
    "Webhooks",
    "10dlc",
    "Advanced-Call-flows",
    "Users",
    "Operator-Panel",
    "Troubleshooting",
  ];

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const onLogout = () => {
    dispatch(logoutRequest());
  };

  return (
    <header ref={refHEader} className="itp-header">
      <div className="itp-content">
        <div className="itp-logo">
          <img src={logoHeader} alt="Voice360" className="itp-logo-img" />
        </div>
        <div className="itp-header-bar"></div>

        <div className="itp-left-section">
          <div className="itp-text">
            {urlInWorkspace.includes(path)
              ? `workspace/${path.replaceAll("-", " ")}`
              : path.replaceAll("-", " ")}
          </div>
        </div>
        <div className="itp-profile">
          <div className="itp-search">
            {/* <input type="text" placeholder="Buscar..." />
                        <button type="button">Buscar</button> */}
            <Search placeholder={"Search contacts, tasks and more"} />
          </div>
          <div className="itp-header-bar"></div>
          <div className="itp-icons">
            <span
              onClick={handleToggle}
              className={`itp-icon menu${toggleMenu ? "" : " hidden"}`}
            >
              <img src={arrow} alt="Voice360" />
            </span>
            <span
              className="itp-icon"
              onClick={() => {
                setShowWebphone((prev) => {
                  return !prev;
                });
              }}
            >
              <img src={phoneIcon} alt="Voice360" />
            </span>
            {!json.prod && (
              <>
                <span className="itp-icon">
                  <img src={checkListIcon} alt="Voice360" />
                </span>
                <span className="itp-icon">
                  <img src={calendarIcon} alt="Voice360" />
                </span>
              </>
            )}
          </div>
          <div className="itp-header-bar"></div>
          <Dropdown
            as="li"
            className="nav-item dropdown header-profile"
            isOpen={dropdownOpen}
            toggle={toggleDropdown}
          >
            <DropdownToggle
              variant=""
              as="a"
              className="nav-link i-false c-pointer"
              role="button"
              data-toggle="dropdown"
            >
              <div className="d-flex">
                {/* <img
                  className="itp-profile-image"
                  src={userImage}
                  alt="Imagen de perfil"
                /> */}
                <UserAvatar
                  firstname={dataLocalStorage.firstname}
                  lastname={dataLocalStorage.lastname || ""}
                  style={{ width: "2.5rem", height: "2.5rem", backgroundColor: "#aeaeae" }}
                  textColor="#002340"
                />
                <div className="itp-profile-details">
                  <span className="itp-profile-name">
                    {dataLocalStorage.firstname}{" "}
                    {dataLocalStorage.lastname || ""}
                  </span>
                  <span className="itp-profile-email">
                    {dataLocalStorage.email || ""}
                  </span>
                </div>
              </div>
            </DropdownToggle>
            <DropdownMenu
              align="right"
              className="mt-3  mt-lg-0 dropdown-menu dropdown-menu-end"
            >
              <Link
                to="#"
                // onClick={() => {
                //   window.location.href = linkPortal;
                // }}
                className="dropdown-item ai-icon"
              >
                <svg
                  id="icon-user1"
                  xmlns="http://www.w3.org/2000/svg"
                  className="text-primary me-1"
                  width={18}
                  height={18}
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                  <circle cx={12} cy={7} r={4} />
                </svg>
                <span className="ms-2">Profile </span>
              </Link>

              <Link className="dropdown-item ai-icon" onClick={onLogout}>
                <svg
                  id="icon-logout"
                  xmlns="http://www.w3.org/2000/svg"
                  className="me-1"
                  width={18}
                  height={18}
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  style={{ color: "rgb(13, 110, 253)" }}
                >
                  <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" />
                  <polyline points="16 17 21 12 16 7" />
                  <line x1={21} y1={12} x2={9} y2={12} />
                </svg>
                <span className="ms-2">Logout </span>
              </Link>
            </DropdownMenu>
          </Dropdown>
        </div>
      </div>
    </header>
  );
};

export default Header;
