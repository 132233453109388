import axios from "axios";
import json from "../config.json";
import jwt_decode from "jwt-decode";
import swal from "sweetalert";

export const login = (form) => {
  const postData = form;
  const urlBase = json.prod ? json.urlBase.prod : json.urlBase.dev;
  const url = urlBase + "/crm/auth/login";
  return axios.post(url, postData);
};

export const services = async (token, data) => {
  try {
    const urlBase = json.prod ? json.urlBase.prod : json.urlBase.dev;
    const url = urlBase + "/crm/services";
    const config = {
      headers: {
        Authorization: "Bearer " + token.replace(/['"]+/g, ""),
      },
    };

    const response = await axios.get(url, config);

    const { data: result } = response;

    let api_id = result.result.find(
      (element) =>
        element.item_type === "itp_voice"
    ).api_id;

    let dataLocalStorage = {
      ...data,
      api_id,
    };

    let dataLoginAndServices = saveDataInLocalStorage(dataLocalStorage);

    return { ...dataLoginAndServices };
  } catch (error) {
    console.log(error);
    formatError(error?.response?.data || { message: "Unexpected Error" })
  }
};

export const saveDataInLocalStorage = (tokenDetails) => {
  let decodedToken = jwt_decode(tokenDetails.access_token);
  decodedToken.expireDate = new Date(new Date().getTime() + decodedToken.exp);
  decodedToken.token = tokenDetails.access_token;
  decodedToken.access_token = tokenDetails.access_token;

  localStorage.setItem(
    "userDetails",
    JSON.stringify({ ...decodedToken, ...tokenDetails })
  );

  return { ...decodedToken, ...tokenDetails };
};

export const formatError = (errorResponse) => {
  switch (errorResponse.message) {
    case "Unexpected Error":
      swal("Oops", "An Error has Occurred", "error");
      break;
    case "Invalid credentials":
      swal("Oops", "Invalid credentials", "error");
      break;
    case "EMAIL_NOT_FOUND":
      swal("Oops", "Email not found", "error", { button: "Try Again!" });
      break;
    case "INVALID_PASSWORD":
      swal("Oops", "Invalid Password", "error", { button: "Try Again!" });
      break;
    case "USER_DISABLED":
      return "User Disabled";

    default:
      return "";
  }
};

export const logout = () => {
  const host = json.prod
    ? json.voice360.prod.split("//")[1]
    : json.voice360.dev.split("//")[1];

  const url = json.prod ? json.voice360.prod : json.voice360.dev;

  const linkVoice360 =
    window.location.host !== host
      ? `http://${window.location.host}/login`
      : `${url}/login`;

  const params = new URL(document.location).searchParams;
  const redirect = params.get("redirect");

  localStorage.removeItem("userDetails");
  window.location.href = `${linkVoice360}${
    redirect ? `?redirect=${redirect}` : ""
  }`;
};

export const loginService = async (data) => {
  return await login(data)
    .then( async (response) => {
      const token = response.data.result.access_token;
      let dataLocalStorage = response.data.result;
      
      let dataLogin = await services(token, dataLocalStorage);
        
      return { ...dataLogin };
      
    })
    .catch((error) => {
      console.log("login error: ", error);
      formatError(error?.response?.data || { message: "Unexpected Error" });
      // {
      //   throw error;
      // }
    });
};
