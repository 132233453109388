import dashboard from "../assets/icons/dashboard.svg";
import contacts from "../assets/icons/contacts.svg";
import leads from "../assets/icons/leads.svg";
import funnels from "../assets/icons/funnels.svg";
// import support from "../assets/icons/support.svg";
import panel from "../assets/icons/panel.svg";
import analytics from "../assets/icons/analytics.svg";
import campaigns from "../assets/icons/campaigns.svg";
import calendar from "../assets/icons/calendar.svg";
import devices from "../assets/icons/devices.svg";
import call from "../assets/icons/call.svg";
import recording from "../assets/icons/recording.svg";
import intercom from "../assets/icons/intercom.svg";
import fax from "../assets/icons/fax.svg";
import settings from "../assets/icons/settings.svg";
import key from "../assets/icons/key.svg";
import form from "../assets/icons/form.svg";
import webhook from "../assets/icons/webhook.svg";
import phoneNumbers from "../assets/icons/phoneNumbers.svg";
import workflow from "../assets/icons/workflow.svg";
import incomingCall from "../assets/icons/incomingCall.svg";
import dlcicon from "../assets/icons/10dlc.svg"
import troubleshooting from "../assets/icons/troubleshooting.svg"

const itemsMenu = [
  {
    name: "Dashboard",
    icon: <img src={dashboard} alt="Dashboard" />,
    start: true,
    url: "Dashboard",
  },
  {
    name: "Contacts",
    icon: <img src={contacts} alt="Contacts" />,
    url: "Contacts",
  },
  {
    name: "Leads",
    icon: <img src={leads} alt="Leads" />,
    url: "Leads",
  },
  {
    name: "Funnels",
    icon: <img src={funnels} alt="Funnels" />,
    active: true,
    url: "Funnels",
  },
  // {
  //   name: "Support Center",
  //   icon: <img src={support} alt="Support Center" />,
  //   url: "Support-Center",
  // },
  {
    name: "Live Panel",
    icon: <img src={panel} alt="Live Panel" />,
    url: "Live-Panel",
  },
  {
    name: "Analytics",
    icon: <img src={analytics} alt="Analytics" />,
    url: "Analytics",
    validate: true,
  },
  {
    name: "Smart Campaigns",
    icon: <img src={campaigns} alt="Smart Campaigns" />,
    url: "Smart-Campaigns",
    prod: true,
  },
  {
    name: "User Settings",
    icon: <img src={devices} alt="Devices" />,
    url: "",
    param: "Devices?param=devices",
  },
];

const secondItemsMenu = [
  {
    isTitle: true,
    start: true,
    title: "",
    items: [
      {
        name: "Calender Integration",
        icon: <img src={calendar} alt="Calender Integration" />,
        active: true,
        url: "Calender-Integration",
        prod: true,
      },
      {
        name: "Devices",
        icon: <img src={devices} alt="Devices" />,
        url: "Devices",
        param: "?param=devices",
      },
      {
        name: "Call Forwarding",
        icon: <img src={call} alt="Call Forwarding" />,
        url: "Call-Forwarding",
        param: "?param=callForwarding",
      },
      {
        name: "Caller ID",
        icon: <img src={contacts} alt="Caller ID" />,
        url: "Caller-ID",
        param: "?param=callerID",
      },
      {
        name: "Call Recordings",
        icon: <img src={recording} alt="Call Recordings" />,
        url: "Call-Recordings",
        param: "?param=callRecordings",
      },
      {
        name: "Intercom / Paging",
        icon: <img src={intercom} alt="Intercom / Paging" />,
        url: "Intercom",
        param: "?param=intercom",
      },
      {
        name: "Fax",
        icon: <img src={fax} alt="Fax" />,
        url: "Fax",
        param: "?param=fax",
      },
    ],
  },
  {
    isTitle: true,
    title: "Workspace Settings",
    validate: true,
    items: [
      {
        name: "General Settings",
        icon: <img src={settings} alt="General Settings" />,
        url: "General-Settings",
      },
      {
        name: "Operator Panel",
        icon: <img src={analytics} alt="Analytics" />,
        url: "Operator-Panel",
      },
      {
        name: "API Keys",
        icon: <img src={key} alt="API Keys" />,
        url: "API-Keys",
      },
      { name: "Forms", icon: <img src={form} alt="Forms" />, url: "Forms" },
      {
        name: "Webhooks",
        icon: <img src={webhook} alt="Webhooks" />,
        url: "Webhooks",
      },
      {
        name: "Phone Numbers",
        icon: <img src={phoneNumbers} alt="Phone Numbers" />,
        url: "Phone-Numbers",
      },
      {
        name: "Workflows",
        icon: <img src={workflow} alt="Workflows" />,
        url: "Workflows",
      },
      {
        name: "Advanced Call flows",
        icon: <img src={incomingCall} alt="Advanced Call flows" />,
        url: "Advanced-Call-flows",
      },
      {
        name: "10DLC",
        icon: <img src={dlcicon} alt="10DLC" />,
        url: "10dlc"
      },
      {
        name: "Troubleshooting",
        icon: <img src={troubleshooting} alt="10DLC" style={{ width: "20px", height: "20px" }} />,
        url: "Troubleshooting"
      },
      {
        name: "Users",
        icon: <img src={contacts} alt="Users" />,
        url: "Users",
      },
    ],
  },
];

export { itemsMenu, secondItemsMenu };
