import React, { useEffect, useRef, useState } from "react";
import { CustomTable } from "../../../../components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Card, CardBody } from "reactstrap";
import "./DataReport.css";

const ViewLogs = ({
  data,
  loadingCall,
  width,
  From,
  to,
  totalCalls,
  completedLogs,
  selectedReport,
}) => {
  const dataTable = useRef(null);
  const [valueLog, setValueLog] = useState("all");
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  useEffect(() => {
    if (data) {
      dataTable.current = data;
      forceUpdate();
    } else {
      dataTable.current = null;
      setValueLog("all");
    }
  }, [data]);

  const onChangeLogs = (e) => {
    setValueLog(e.target.value);
    if (e.target.value === "all") {
      dataTable.current = {
        ...dataTable.current,
        content: [...completedLogs.all_calls],
      };
      forceUpdate();
    }

    if (e.target.value === "in") {
      dataTable.current = {
        ...dataTable.current,
        content: [...completedLogs.incoming_calls],
      };
      forceUpdate();
    }

    if (e.target.value === "out") {
      dataTable.current = {
        ...dataTable.current,
        content: [...completedLogs.outgoing_calls],
      };
      forceUpdate();
    }
  };

  return (
    <>
      {dataTable.current && (
        <>
          {selectedReport === "Users" && (
            <div>
              <h1
                className="itp-custom-table-title centered"
                style={{ fontSize: "18px", fontWeight: "bold" }}
              >
                {completedLogs.user}
              </h1>
              <div
                style={{
                  maxWidth: "991px",
                  margin: "auto",
                  color: "#3a3a3acc",
                  font: "normal normal normal 14px/19px Instrument Sans",
                }}
              >
                Individual call logs for user
              </div>
            </div>
          )}
          <div
            style={{ marginBottom: "-2rem" }}
            className="itp-custom-table-container-actions"
          >
            <div className="itp-custom-table-action">
              {selectedReport === "Users" && (
                <select
                  onChange={onChangeLogs}
                  value={valueLog}
                  className="form-select-itp itp-call-logs-select"
                >
                  <option value="all">All Calls</option>
                  <option value="in">Inbound</option>
                  <option value="out">Outbound</option>
                </select>
              )}
            </div>
            <div className="itp-custom-table-add-buttons">
              <Card className="itp-data-report-card-data">
                <CardBody>
                  <div className="d-flex justify-content-between align-items-center">
                    <span className="itp-data-report-date">Report Date :</span>
                    <div className="d-flex align-items-center">
                      <span className="itp-data-report-range-date">
                        From : {From}
                      </span>
                      <FontAwesomeIcon
                        style={{ marginLeft: "12px", marginRight: "12px" }}
                        icon={faArrowRight}
                      />
                      <span className="itp-data-report-range-date">
                        To : {to}
                      </span>
                    </div>
                  </div>
                </CardBody>
              </Card>
              <Card className="itp-data-report-card-data">
                <CardBody>
                  <div className="d-flex align-items-center">
                    <span className="itp-data-report-date">Total Calls :</span>
                    <span className="itp-data-report-range-date">
                      {totalCalls}
                    </span>
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>
          <div className="mb-5">
            <CustomTable
              data={dataTable.current}
              noPagBack={true}
              loadingIcon={loadingCall}
              overflowCard={width > 991 ? "inherit" : "auto"}
              textNoData="Logs"
            />
          </div>
        </>
      )}
    </>
  );
};

export default ViewLogs;
