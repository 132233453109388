import React, { useEffect, useState } from "react";
import { Card } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { PaginationITP } from "../../../../../../components";
import arrowleft from "../../../../../../assets/icons/arrow-left.svg";

const Step3 = ({
  handleBackClick,
  selectedNumbers,
  setSelectedNumbers,
  pricePerDid,
}) => {
  const cantPagination = 10;
  const [pagination, setPagination] = useState(cantPagination);
  const [paginationITP, setPaginationITP] = useState(cantPagination);

  const deleteNumberOfList = (element) => {
    setSelectedNumbers((prevState) =>
      prevState.filter((number) => number.label !== element.label)
    );
  };

  useEffect(() => {
    if (paginationITP != pagination)
      setPagination(paginationITP * cantPagination);
  }, [paginationITP, pagination]);

  return (
    <>
      <div onClick={handleBackClick} className="itp-tex-back-container">
        <div className="itp-text-container-funnel-details">
          <img src={arrowleft} alt="arrow-left" />
          <span className="itp-text-back-funnel-details">
            Back to Add Numbers
          </span>
        </div>
      </div>

      <h1 className="itp-custom-table-title centered">Call Sumary</h1>
      <div className="itp-custom-table-container-actions label">
        <div>
          <label className="itp-custom-table-label centered">
            View & Confirm the Numbers summary
          </label>
        </div>
      </div>
      <Card
        style={{
          zIndex: "0",
          marginTop: "15px",
          overflow: "",
        }}
        className="itp-table-container-centered"
      >
        <table className="itp-custom-table">
          <thead>
            <tr className="itp-custom-table-thead-styles">
              <th className="itp-custom-table-th-styles">Number</th>
              <th className="itp-custom-table-th-styles">Location</th>
              <th className="itp-custom-table-th-styles">Quantity</th>
              <th className="itp-custom-table-th-styles text-center">Action</th>
            </tr>
          </thead>
          <tbody>
            {selectedNumbers.map((element, i) => {
              if (
                (i < pagination && i >= pagination - cantPagination) ||
                !pagination
              ) {
                return (
                  <tr key={i} className="itp-custom-table-row">
                    <td className="itp-custom-table-td-styles">
                      +1{element.number}
                    </td>
                    <td className="itp-custom-table-td-styles">
                      {element.type !== "City/State" ? `${element.type}: ` : ""}
                      {element.code}
                    </td>
                    <td className="itp-custom-table-td-styles">1</td>
                    <td className="text-center">
                      <span
                        onClick={() => {
                          deleteNumberOfList(element);
                        }}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </span>
                    </td>
                  </tr>
                );
              }
            })}
          </tbody>
        </table>
      </Card>
      {selectedNumbers && selectedNumbers.length > cantPagination && (
        <div style={{ maxWidth: "991px", margin: "auto" }} className="mt-4">
          <PaginationITP
            pages={
              (selectedNumbers.length / cantPagination) % 1 === 0
                ? Math.floor(selectedNumbers.length / cantPagination)
                : Math.floor(selectedNumbers.length / cantPagination + 1)
            }
            active={pagination / cantPagination}
            setActive={setPaginationITP}
          />
        </div>
      )}

      <h1 className="itp-custom-table-title centered mt-4">Order Sumary</h1>
      <Card
        style={{
          zIndex: "0",
          marginTop: "15px",
          overflow: "",
        }}
        className="itp-table-container-centered mb-5"
      >
        <table className="itp-custom-table">
          <thead>
            <tr className="itp-custom-table-thead-styles">
              <th className="itp-custom-table-th-styles">Recurring Changes</th>
              <th className="itp-custom-table-th-styles">Quantity</th>
              <th className="itp-custom-table-th-styles">Subtotal</th>
            </tr>
          </thead>
          <tbody>
            <tr className="itp-custom-table-row">
              <td className="itp-custom-table-td-styles">
                Additional Local Numbers - Domestic
              </td>
              <td className="itp-custom-table-td-styles">
                {selectedNumbers.length}
              </td>
              <td className="itp-custom-table-td-styles">
                {`$${(selectedNumbers.length * pricePerDid).toFixed(2)}`}
              </td>
            </tr>
            <tr>
              <td className="itp-custom-table-td-styles"></td>
              <td className="itp-custom-table-td-styles"></td>
              <td className="itp-custom-table-td-styles"></td>
            </tr>
            <tr>
              <td className="itp-custom-table-td-styles"></td>
              <td className="itp-custom-table-td-styles"></td>
              <td className="itp-custom-table-td-styles"></td>
            </tr>
            <tr>
              <td className="itp-custom-table-td-styles"></td>
              <td className="itp-custom-table-td-styles"></td>
              <td className="itp-custom-table-td-styles"></td>
            </tr>
            <tr>
              <td className="itp-custom-table-td-styles"></td>
              <td className="itp-custom-table-td-styles"></td>
              <td className="itp-custom-table-td-styles"></td>
            </tr>
            <tr>
              <td className="itp-custom-table-td-styles"></td>
              <td className="itp-custom-table-td-styles"></td>
              <td className="itp-custom-table-td-styles"></td>
            </tr>
            <tr style={{ borderTop: "1px solid rgba(0, 0, 0, 0.3)" }}>
              <td className="itp-label-input__label">Estimated Total</td>
              <td className="itp-custom-table-td-styles"></td>
              <td className="itp-label-input__label">{`$${(
                selectedNumbers.length * pricePerDid
              ).toFixed(2)}`}</td>
            </tr>
          </tbody>
        </table>
      </Card>
    </>
  );
};

export default Step3;
