import React, { useState, useEffect } from "react";
import petitionGet from "../../../../../../services/petitionGet";
import { Row, Col, Table, Card } from "reactstrap";

const Step3 = ({
  supportedLosingCarriers,
  formMetadata,
  setFormMetadata,
  step,
  states,
  inputError,
}) => {
  const [selectedState, setSelectedState] = useState(false);
  const [cities, setCities] = useState([]);
  const [enabled, setEnabled] = useState(false);
  const [porting_state_code, setPorting_state_code] = useState("");

  const hangleChangeMetadata = (e) => {
    setFormMetadata({ ...formMetadata, [e.target.name]: e.target.value });
  };

  const changeEstate = (pk) => {
    setSelectedState(false);
    let selected = states.filter((el) => el.pk == pk);
    setFormMetadata({
      ...formMetadata,
      porting_state_code: selected[0].shortcode,
    });
    setPorting_state_code(selected[0].pk);
    petitionGet("cities2", pk)
      .then(({ data: result }) => {
        setCities([{ city_alias: "", name: "Select One" }, ...result.result]);
        setSelectedState(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (enabled === false && states) {
      changeEstate(states[0].pk);
      setPorting_state_code(states[0].pk);
      setEnabled(true);
    }
  }, [states]);

  return (
    <>
      {supportedLosingCarriers && (
        <>
          {supportedLosingCarriers.map((element, i) => {
            if (i === step) {
              return (
                <Row key={i} className="pt-3 pl-3  ">
                  <Col className="col-12 col-md-10 col-lg-6  m-auto m-lg-0 ">
                    <Card className="itp-table-container-all mb-4">
                      <table className="itp-custom-table">
                        <thead>
                          <tr className="itp-custom-table-thead-styles">
                            <th className="itp-custom-table-th-styles text-center">
                              {element.LosingCarrierName} {"   "} ( {i + 1} /{" "}
                              {supportedLosingCarriers.length} )
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {typeof element.TnList.Tn === "string" ? (
                            <tr className="itp-custom-table-row">
                              <td className="itp-custom-table-td-styles text-center">
                                {element.TnList.Tn}
                              </td>
                            </tr>
                          ) : (
                            element.TnList.Tn.map((element, index) => (
                              <tr className="itp-custom-table-row" key={index}>
                                <td className="itp-custom-table-td-styles text-center">
                                  {element}
                                </td>
                              </tr>
                            ))
                          )}
                        </tbody>
                      </table>
                    </Card>
                  </Col>
                  <Col className="col-12 col-md-10 col-lg-6  m-auto m-lg-0  ">
                    <div className="form-group row p-3 p-md-0  px-md-3  ">
                      <label className="col-12  p-0 itp-label-input__label">
                        Current Provider Information:
                      </label>

                      <Row className="itp-container-label-select">
                        <Col
                          xs={5}
                          sm={5}
                          md={5}
                          lg={5}
                          xl={5}
                          xxl={5}
                          className="itp-label-input-col"
                        >
                          <label className="itp-label-input__label">
                            Bussiness Type:
                          </label>
                        </Col>
                        <Col
                          xs={7}
                          sm={7}
                          md={7}
                          lg={7}
                          xl={7}
                          xxl={7}
                          className="itp-label-input-col"
                        >
                          <select
                            value={formMetadata.porting_business_type}
                            onChange={(e) => hangleChangeMetadata(e)}
                            className={`form-select-itp bgc-transparent ${
                              inputError === "porting_business_type" &&
                              "form-select-itp-error"
                            }`}
                            name="porting_business_type"
                            id="porting_business_type"
                          >
                            <option value="">-- Select one --</option>
                            <option value="RESIDENTIAL">Residential</option>
                            <option value="BUSINESS">Business</option>
                          </select>
                        </Col>
                      </Row>

                      {formMetadata.porting_business_type !== "" && (
                        <>
                          <Row className="itp-container-label-input">
                            <Col
                              xs={5}
                              sm={5}
                              md={5}
                              lg={5}
                              xl={5}
                              xxl={5}
                              className="itp-label-input-col"
                            >
                              <label className="itp-label-input__label">
                                First Name:
                              </label>
                            </Col>
                            <Col
                              xs={7}
                              sm={7}
                              md={7}
                              lg={7}
                              xl={7}
                              xxl={7}
                              className="itp-label-input-col"
                            >
                              <input
                                name="porting_firstname"
                                value={formMetadata.porting_firstname}
                                onChange={(e) => hangleChangeMetadata(e)}
                                type="text"
                                id="porting_firstname"
                                className={`form-control-itp bgc-transparent itp-label-input__input ${
                                  inputError === "porting_firstname" &&
                                  "form-control-itp-error"
                                }`}
                              />
                            </Col>
                          </Row>

                          <Row className="itp-container-label-input">
                            <Col
                              xs={5}
                              sm={5}
                              md={5}
                              lg={5}
                              xl={5}
                              xxl={5}
                              className="itp-label-input-col"
                            >
                              <label className="itp-label-input__label">
                                Last Name:
                              </label>
                            </Col>
                            <Col
                              xs={7}
                              sm={7}
                              md={7}
                              lg={7}
                              xl={7}
                              xxl={7}
                              className="itp-label-input-col"
                            >
                              <input
                                name="porting_lastname"
                                value={formMetadata.porting_lastname}
                                onChange={(e) => hangleChangeMetadata(e)}
                                type="text"
                                id="porting_lastname"
                                className={`form-control-itp bgc-transparent itp-label-input__input ${
                                  inputError === "porting_lastname" &&
                                  "form-control-itp-error"
                                }`}
                              />
                            </Col>
                          </Row>

                          {formMetadata.porting_business_type ===
                            "BUSINESS" && (
                            <>
                              <Row className="itp-container-label-input">
                                <Col
                                  xs={5}
                                  sm={5}
                                  md={5}
                                  lg={5}
                                  xl={5}
                                  xxl={5}
                                  className="itp-label-input-col"
                                >
                                  <label className="itp-label-input__label">
                                    Business Name:
                                  </label>
                                </Col>
                                <Col
                                  xs={7}
                                  sm={7}
                                  md={7}
                                  lg={7}
                                  xl={7}
                                  xxl={7}
                                  className="itp-label-input-col"
                                >
                                  <input
                                    name="porting_business_name"
                                    value={formMetadata.porting_business_name}
                                    onChange={(e) => hangleChangeMetadata(e)}
                                    type="text"
                                    id="porting_business_name"
                                    className={`form-control-itp bgc-transparent itp-label-input__input ${
                                      inputError === "porting_business_name" &&
                                      "form-control-itp-error"
                                    }`}
                                  />
                                </Col>
                              </Row>
                            </>
                          )}

                          <Row className="itp-container-label-input">
                            <Col
                              xs={5}
                              sm={5}
                              md={5}
                              lg={5}
                              xl={5}
                              xxl={5}
                              className="itp-label-input-col"
                            >
                              <label className="itp-label-input__label">
                                House Number:
                              </label>
                            </Col>
                            <Col
                              xs={7}
                              sm={7}
                              md={7}
                              lg={7}
                              xl={7}
                              xxl={7}
                              className="itp-label-input-col"
                            >
                              <input
                                name="porting_house_number"
                                value={formMetadata.porting_house_number}
                                onChange={(e) => hangleChangeMetadata(e)}
                                type="text"
                                id="porting_house_number"
                                className={`form-control-itp bgc-transparent itp-label-input__input ${
                                  inputError === "porting_house_number" &&
                                  "form-control-itp-error"
                                }`}
                              />
                            </Col>
                          </Row>

                          <Row className="itp-container-label-input">
                            <Col
                              xs={5}
                              sm={5}
                              md={5}
                              lg={5}
                              xl={5}
                              xxl={5}
                              className="itp-label-input-col"
                            >
                              <label className="itp-label-input__label">
                                Street Name:
                              </label>
                            </Col>
                            <Col
                              xs={7}
                              sm={7}
                              md={7}
                              lg={7}
                              xl={7}
                              xxl={7}
                              className="itp-label-input-col"
                            >
                              <input
                                name="porting_street_name"
                                value={formMetadata.porting_street_name}
                                onChange={(e) => hangleChangeMetadata(e)}
                                type="text"
                                id="porting_street_name"
                                className={`form-control-itp bgc-transparent itp-label-input__input ${
                                  inputError === "porting_street_name" &&
                                  "form-control-itp-error"
                                }`}
                              />
                            </Col>
                          </Row>

                          <Row className="itp-container-label-input">
                            <Col
                              xs={5}
                              sm={5}
                              md={5}
                              lg={5}
                              xl={5}
                              xxl={5}
                              className="itp-label-input-col"
                            >
                              <label className="itp-label-input__label">
                                APT or Suite Number:
                              </label>
                            </Col>
                            <Col
                              xs={7}
                              sm={7}
                              md={7}
                              lg={7}
                              xl={7}
                              xxl={7}
                              className="itp-label-input-col"
                            >
                              <input
                                name="porting_address_line_2"
                                value={formMetadata.porting_address_line_2}
                                onChange={(e) => hangleChangeMetadata(e)}
                                type="text"
                                id="porting_address_line_2"
                                className={`form-control-itp bgc-transparent itp-label-input__input ${
                                  inputError === "porting_address_line_2" &&
                                  "form-control-itp-error"
                                }`}
                              />
                            </Col>
                          </Row>

                          <Row className="itp-container-label-select">
                            <Col
                              xs={5}
                              sm={5}
                              md={5}
                              lg={5}
                              xl={5}
                              xxl={5}
                              className="itp-label-input-col"
                            >
                              <label className="itp-label-input__label">
                                State Code:
                              </label>
                            </Col>
                            <Col
                              xs={7}
                              sm={7}
                              md={7}
                              lg={7}
                              xl={7}
                              xxl={7}
                              className="itp-label-input-col"
                            >
                              <select
                                name="porting_state_code"
                                value={porting_state_code}
                                onChange={(e) => {
                                  changeEstate(e.target.value);
                                }}
                                type="text"
                                id="porting_state_code"
                                className={`form-select-itp bgc-transparent ${
                                  inputError === "porting_state_code" &&
                                  "form-select-itp-error"
                                }`}
                              >
                                {states.map((element, i) => (
                                  <option key={i} value={element.pk}>
                                    {element.name}
                                  </option>
                                ))}
                              </select>
                            </Col>
                          </Row>

                          {selectedState && (
                            <>
                              <Row className="itp-container-label-select">
                                <Col
                                  xs={5}
                                  sm={5}
                                  md={5}
                                  lg={5}
                                  xl={5}
                                  xxl={5}
                                  className="itp-label-input-col"
                                >
                                  <label className="itp-label-input__label">
                                    City Name:
                                  </label>
                                </Col>
                                <Col
                                  xs={7}
                                  sm={7}
                                  md={7}
                                  lg={7}
                                  xl={7}
                                  xxl={7}
                                  className="itp-label-input-col"
                                >
                                  <select
                                    name="porting_city_name"
                                    value={formMetadata.porting_city_name}
                                    onChange={(e) => hangleChangeMetadata(e)}
                                    type="text"
                                    id="porting_city_name"
                                    className={`form-select-itp bgc-transparent ${
                                      inputError === "porting_city_name" &&
                                      "form-select-itp-error"
                                    }`}
                                  >
                                    {cities.map((element, i) => (
                                      <option
                                        key={i}
                                        value={element.city_alias}
                                      >
                                        {element.name}
                                      </option>
                                    ))}
                                  </select>
                                </Col>
                              </Row>
                            </>
                          )}

                          <Row className="itp-container-label-input">
                            <Col
                              xs={5}
                              sm={5}
                              md={5}
                              lg={5}
                              xl={5}
                              xxl={5}
                              className="itp-label-input-col"
                            >
                              <label className="itp-label-input__label">
                                Zip Code:
                              </label>
                            </Col>
                            <Col
                              xs={7}
                              sm={7}
                              md={7}
                              lg={7}
                              xl={7}
                              xxl={7}
                              className="itp-label-input-col"
                            >
                              <input
                                name="porting_zip_code"
                                value={formMetadata.porting_zip_code}
                                onChange={(e) => hangleChangeMetadata(e)}
                                type="text"
                                id="porting_zip_code"
                                className={`form-control-itp bgc-transparent itp-label-input__input ${
                                  inputError === "porting_zip_code" &&
                                  "form-control-itp-error"
                                }`}
                              />
                            </Col>
                          </Row>

                          <Row className="itp-container-label-input">
                            <Col
                              xs={5}
                              sm={5}
                              md={5}
                              lg={5}
                              xl={5}
                              xxl={5}
                              className="itp-label-input-col"
                            >
                              <label className="itp-label-input__label">
                                Btn:
                              </label>
                            </Col>
                            <Col
                              xs={7}
                              sm={7}
                              md={7}
                              lg={7}
                              xl={7}
                              xxl={7}
                              className="itp-label-input-col"
                            >
                              <input
                                name="porting_btn"
                                value={formMetadata.porting_btn}
                                onChange={(e) => hangleChangeMetadata(e)}
                                type="text"
                                id="porting_btn"
                                className={`form-control-itp bgc-transparent itp-label-input__input ${
                                  inputError === "porting_btn" &&
                                  "form-control-itp-error"
                                }`}
                              />
                            </Col>
                          </Row>

                          <Row className="itp-container-label-input">
                            <Col
                              xs={5}
                              sm={5}
                              md={5}
                              lg={5}
                              xl={5}
                              xxl={5}
                              className="itp-label-input-col"
                            >
                              <label className="itp-label-input__label">
                                Account Number:
                              </label>
                            </Col>
                            <Col
                              xs={7}
                              sm={7}
                              md={7}
                              lg={7}
                              xl={7}
                              xxl={7}
                              className="itp-label-input-col"
                            >
                              <input
                                name="porting_account_number"
                                value={formMetadata.porting_account_number}
                                onChange={(e) => hangleChangeMetadata(e)}
                                type="text"
                                id="porting_account_number"
                                className={`form-control-itp bgc-transparent itp-label-input__input ${
                                  inputError === "porting_account_number" &&
                                  "form-control-itp-error"
                                }`}
                              />
                            </Col>
                          </Row>

                          <Row className="itp-container-label-input">
                            <Col
                              xs={5}
                              sm={5}
                              md={5}
                              lg={5}
                              xl={5}
                              xxl={5}
                              className="itp-label-input-col"
                            >
                              <label className="itp-label-input__label">
                                Port Carrier:
                              </label>
                            </Col>
                            <Col
                              xs={7}
                              sm={7}
                              md={7}
                              lg={7}
                              xl={7}
                              xxl={7}
                              className="itp-label-input-col"
                            >
                              <input
                                name="porting_carrier"
                                value={formMetadata.porting_carrier}
                                onChange={(e) => hangleChangeMetadata(e)}
                                type="text"
                                id="porting_carrier"
                                className={`form-control-itp bgc-transparent itp-label-input__input ${
                                  inputError === "porting_carrier" &&
                                  "form-control-itp-error"
                                }`}
                              />
                            </Col>
                          </Row>

                          <Row className="itp-container-label-input">
                            <Col
                              xs={5}
                              sm={5}
                              md={5}
                              lg={5}
                              xl={5}
                              xxl={5}
                              className="itp-label-input-col"
                            >
                              <label className="itp-label-input__label">
                                Pin (opcional):
                              </label>
                            </Col>
                            <Col
                              xs={7}
                              sm={7}
                              md={7}
                              lg={7}
                              xl={7}
                              xxl={7}
                              className="itp-label-input-col"
                            >
                              <input
                                name="pin"
                                value={formMetadata.pin}
                                onChange={(e) => hangleChangeMetadata(e)}
                                type="text"
                                id="pin"
                                className={`form-control-itp bgc-transparent itp-label-input__input ${
                                  inputError === "pin" &&
                                  "form-control-itp-error"
                                }`}
                              />
                            </Col>
                          </Row>
                        </>
                      )}
                    </div>
                  </Col>
                </Row>
              );
            }
          })}
        </>
      )}
    </>
  );
};

export default Step3;
