import React, { useState, useEffect } from "react";
import {
  CustomTable,
  SelectComponent,
  LoadingButton,
} from "../../../../../../../components";
import { FileUploader } from "react-drag-drop-files";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { csv } from "d3-request";
import petitionPost from "../../../../../../../services/petitionPost";
import petitionGet from "../../../../../../../services/petitionGet";
import petitionDelete from "../../../../../../../services/petitionDelete";
import "../Import.css";

const ImportContacts = ({
  defaultContactList,
  backFunction,
  setChangeTab,
  setAddedContactListImort,
  customFields,
}) => {
  const fileTypes = ["csv"];
  const [step, setStep] = useState(1);
  const [file, setFile] = useState(null);
  const [checkValue, setCheckValue] = useState(false);
  const [contactList, setContactList] = useState(defaultContactList);
  const [infoContactList, setInfoContactList] = useState(null);
  const [showdeleteContactList, setShowdeleteContactList] = useState(false);
  const [showAddContactList, setShowAddContactList] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [valueContactList, setValueContactList] = useState(null);
  const [data, setData] = useState(null);
  const [dataUsers, setDataUsers] = useState(null);
  const [columnsCSV, setColumnsCSV] = useState(null);
  const [dataColumns, setDataColumns] = useState(null);
  const [form, setForm] = useState({
    list_name: "",
  });

  const customFieldsModalImport = customFields.map((element) => ({
    label: element.label,
    value: element.label,
  }));

  const handleChange = (file) => {
    setFile(file);
  };

  const changeCheck = () => {
    setCheckValue(!checkValue);
  };

  const handleChangeContactList = (e) => {
    if (e.value === "add") {
      setShowAddContactList(true);
    } else {
      setValueContactList(e);
    }
  };

  const stopFunction = (e, info) => {
    setShowdeleteContactList(true);
    setInfoContactList(info);
  };

  const onChangeAddContactList = (e) => {
    setForm({ [e.target.name]: e.target.value });
  };

  const back = () => {
    setForm({
      list_name: "",
    });
    setInfoContactList(null);
    setValueContactList(null);
    setShowAddContactList(false);
    setShowdeleteContactList(false);
  };

  const addContactList = () => {
    setLoadingButton(true);
    petitionPost("contactList", { data: form })
      .then(({ data: result }) => {
        setAddedContactListImort(true);
        petitionGet("contactList")
          .then(({ data: result }) => {
            result.result.forEach((element) => {
              element.label = `${element.list_name} (${element.contact_count})`;
              element.value = element.pk;
              if (element.list_name !== "Default") {
                element.icon = <FontAwesomeIcon icon={faTrash} />;
              }
            });

            result.result.unshift({
              label: "Add New Contact List",
              value: "add",
            });

            setContactList(result.result);
            setLoadingButton(false);
            back();
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => console.log(error));
  };

  const deleteContactList = () => {
    setLoadingButton(true);
    petitionDelete("contactList", {
      contact_list_id: infoContactList?.pk,
    })
      .then(({ data: result }) => {
        petitionGet("contactList")
          .then(({ data: result }) => {
            result.result.forEach((element) => {
              element.label = `${element.list_name} (${element.contact_count})`;
              element.value = element.pk;
              if (element.list_name !== "Default") {
                element.icon = <FontAwesomeIcon icon={faTrash} />;
              }
            });

            result.result.unshift({
              label: "Add New Contact List",
              value: "add",
            });

            setContactList(result.result);
            setLoadingButton(false);
            back();
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onHandleClick = (data, index, value) => {
    let newData = { ...data };
    let findField = newData.content.find(
      (element) => element.selected === value
    );

    if (findField) return;

    newData.content = newData.content.map((element) =>
      element.row === index ? { ...element, selected: value } : element
    );

    setData(newData);
  };

  const onSubmitContacts = async () => {
    if (file) {
      let pathCsv = URL.createObjectURL(file);

      csv(pathCsv, function (err, dataJson) {
        let tempJson = [];
        let newDataJson = {};
        let tempKeys = Object.keys(dataJson[0]);

        if (dataJson.length > 0) {
          tempKeys.map((element) => {
            tempJson.push({ row: element, selected: "" });
            if (!checkValue) newDataJson[element] = element;
          });
        }

        if (!checkValue) dataJson.unshift(newDataJson);

        const newData = {
          title: [
            { title: "Row ", key: "row" },
            {
              title: "Select Option",
              key: "selected",
              type: "dropdown",
              options: [
                { value: "firstname", label: "First Name" },
                { value: "lastname", label: "Last Name" },
                { value: "email", label: "Email" },
                { value: "phone", label: "Phone" },
                ...customFieldsModalImport,
              ],
              onHandleClick,
            },
          ],
          content: tempJson,
        };
        setData(newData);
        setDataUsers(dataJson);
        setColumnsCSV(tempKeys);
      });

      setStep(2);
    }
  };

  const onSubmitConfig = () => {
    let oldJsonData = data.content;

    let newContacts = [];
    let newPosicion = {};

    dataUsers.map((element) => {
      let newUser = {};
      oldJsonData.map((elementTwo) => {
        if (elementTwo.selected) {
          newUser[elementTwo.selected] = element[elementTwo.row];
          newPosicion[elementTwo.selected] = columnsCSV.indexOf(elementTwo.row);
        }
      });

      newContacts.push(newUser);
    });
    let options = [
      { value: "firstname", label: "First Name" },
      { value: "lastname", label: "Last Name" },
      { value: "email", label: "Email" },
      { value: "phone", label: "Phone" },
      ...customFieldsModalImport,
    ];

    const newData = { title: [], content: newContacts };

    if (newContacts.length > 0) {
      Object.keys(newContacts[0]).map((contact) => {
        let findLabel = options.find((option) => option.value === contact);
        if (findLabel)
          newData.title.push({ title: findLabel.label, key: findLabel.value });
      });
    }

    let findNameRequire = oldJsonData.find(
      (elementTwo) => elementTwo.selected === "firstname"
    );
    if (!findNameRequire) return;

    setDataUsers(newData);
    setDataColumns(newPosicion);
    setStep(3);
  };

  const onSubmitData = () => {
    const customFieldsColumns = customFieldsModalImport.map((element) => element.label);

    let columns = [
      "firstname",
      "lastname",
      "phone",
      "email",
      ...customFieldsColumns,
    ];
    let data = dataColumns;

    columns.forEach((element) => {
      if (!data[element] && data[element] !== 0) delete data[element];
    });

    let payload = JSON.stringify({
      headers: checkValue,
      mapping: data,
      contact_list_id: valueContactList.value,
    });

    const formData = new FormData();
    formData.append("payload", payload);
    formData.append("file", file);

    setLoadingButton(true);
    petitionPost("importContacts", {
      data: formData,
    })
      .then(({ data: result }) => {
        setLoadingButton(false);
        setChangeTab(1);
        setFile(null);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleOnClick = () => {
    if (step === 1) onSubmitContacts();
    if (step === 2) onSubmitConfig();
    if (step === 3) onSubmitData();
  };

  const deleteDuplicateObjects = (array) => {
    let unicObject = {};

    let result = array.filter(function (obj) {
      if (obj.label === "Add New Contact List") {
        if (!unicObject[obj.label]) {
          unicObject[obj.label] = true;
          return true;
        }
      } else {
        return true;
      }
      return false;
    });

    return result;
  };

  useEffect(() => {
    if (!contactList.includes("Add New Contact List")) {
      let currentContactList = contactList;
      currentContactList.unshift({
        label: "Add New Contact List",
        value: "add",
      });

      let newContactList = deleteDuplicateObjects(currentContactList);

      setContactList(newContactList);
    }
  }, []);

  return (
    <>
      <div className="import-contacts-container">
        {step === 1 && (
          <div className="col-12 my-3 mb-5">
            <h5 className="mb-3"> Import contacts from CSV files </h5>
            <div style={{ display: "flex" }}>
              <FileUploader
                handleChange={handleChange}
                onDrop={handleChange}
                name="file"
                types={fileTypes}
              />
            </div>
            {file && (
              <>
                <span>{file.name}</span>
                <div style={{ gap: "10px" }} className="d-flex">
                  <label>CSV has headers</label>
                  <input
                    checked={checkValue}
                    type="checkbox"
                    onChange={changeCheck}
                    className="form-check-input border"
                  />
                </div>
                {!showAddContactList && !showdeleteContactList && (
                  <div style={{ marginTop: "20px" }}>
                    <label>Select Contact List</label>
                    <div className="col-6">
                      <SelectComponent
                        onChange={handleChangeContactList}
                        defaultValue={valueContactList}
                        isSearchable={true}
                        name="contact_list_id"
                        placeholder="Select Option"
                        options={contactList}
                        includeOption={true}
                        functionOption={stopFunction}
                      />
                    </div>
                  </div>
                )}

                {showAddContactList && !showdeleteContactList && (
                  <>
                    <div className="label-input-add-contact-list">
                      <label
                        className="label-add-contact-list"
                        htmlFor="list_name"
                      >
                        List Name:
                      </label>

                      <input
                        onChange={onChangeAddContactList}
                        name="list_name"
                        className="form-control-itp input-add-contact-list"
                        type="text"
                      />
                    </div>
                    <div className="add-contact-list-buttons">
                      <button
                        disabled={loadingButton}
                        className="btn-light"
                        onClick={back}
                      >
                        Cancel
                      </button>
                      <button
                        disabled={loadingButton || form.list_name === ""}
                        className="btn-primary loading"
                        onClick={addContactList}
                      >
                        {loadingButton && <LoadingButton />}
                        Add
                      </button>
                    </div>
                  </>
                )}

                {showdeleteContactList && !showAddContactList && (
                  <>
                    <p className="label-input-add-contact-list">
                      Are You Sure?
                    </p>
                    <div className="add-contact-list-buttons">
                      <button
                        disabled={loadingButton}
                        className="btn-light"
                        onClick={back}
                      >
                        Cancel
                      </button>
                      <button
                        disabled={loadingButton}
                        className="btn-primary loading"
                        onClick={deleteContactList}
                      >
                        {loadingButton && <LoadingButton />}
                        Delete
                      </button>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        )}

        {step === 2 && data && (
          <div>
            <h3 className="text-center"> Settings </h3>
            <p className="text-center">
              {" "}
              Associate the fields of the CSV with the required fields{" "}
            </p>
            <div className="itp-jobs-container-table import-contact-container-table">
              <CustomTable data={data} noPagBack={true} />
            </div>
          </div>
        )}

        {step === 3 && (
          <>
            <small
              style={{ marginBottom: "10px", marginTop: "10px" }}
              className="d-flex justify-content-center"
            >
              This is a preview of some of the data that will be imported
            </small>
            <div className="itp-jobs-container-table import-contact-container-table">
              <CustomTable data={dataUsers} noPagBack={true} />
            </div>
          </>
        )}

        <button
          disabled={!file || !valueContactList}
          className="btn-primary import-contact-nex-button loading"
          onClick={handleOnClick}
        >
          {loadingButton && <LoadingButton />}
          Next
        </button>
      </div>
    </>
  );
};

export default ImportContacts;
