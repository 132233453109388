
import { setupRemoteMedia, cleanupMedia } from "./media"
import { SessionState } from "sip.js";

// const ringtone = new Audio(ringtoneSound);

// ringtone.loop = true;


function onInvite(invitation, setInvitationSession,setCallSessionsFunctionEnd,callAudio,setCallAudio,ringtone) {
  let callAudioTemp = new Audio();
  if (invitation) {
    if (invitation._state === "Initial") {
      console.log("Invitation state: Initial");
      setInvitationSession(invitation)

      ringtone.play();
    }
    invitation.stateChange.addListener((state) => {
      console.log("Invitation state:", state);
      switch (state) {
        case SessionState.Initial:
          console.log("Invitation state: Initial");
          break;
        case SessionState.Establishing:
          console.log("Invitation state: Establishing");
          ringtone.play();
          break;
        case SessionState.Established:
          console.log("Invitation state: Established");
          setupRemoteMedia([invitation], callAudio);
          setCallAudio(callAudioTemp)
          ringtone.pause();
          break;
        case SessionState.Terminating:
        case SessionState.Terminated:
          console.log("Invitation state: Terminating/Terminated");
          setCallSessionsFunctionEnd(invitation)
          ringtone.pause();
          break;
        default:
          throw new Error("Unknown session state.");
      }
    });
  }
}

export default onInvite;