import React, { useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { CustomTable, LoadingButton } from "../../../../components";

export default function IgnoreNumbersModal({
  modal,
  setModal,
  loading,
  invalidNumbers,
  filterValidNumbers,
}) {
  const [data, setData] = useState(null);
  const [check, setCheck] = useState(false);

  useEffect(() => {
    setCheck(false);
    if (invalidNumbers) {
      const newData = {
        title: [{ title: "Number", key: "number" }],
        content: [],
      };

      if (typeof invalidNumbers === "string")
        newData.content.push({ number: invalidNumbers });
      else
        invalidNumbers.map((element) =>
          newData.content.push({ number: element })
        );
      setData(newData);
    }
  }, [invalidNumbers]);

  return (
    <Modal isOpen={modal ? true : false} className="info">
      <ModalHeader className="bg-soft-primary">Ignore Numbers</ModalHeader>
      <ModalBody>
        {data && (
          <div style={{ margin: "-2rem 0 12px -2rem" }}>
            <CustomTable data={data} />
          </div>
        )}

        <div className="d-flex" style={{ gap: "8px" }}>
          <input
            onChange={(e) => {
              setCheck(e.target.checked);
            }}
            checked={check}
            type="checkbox"
            className="form-check-input"
            name="check"
            id="exampleCustomCheckbox"
          />
          <label
            onClick={() => {
              setCheck(!check);
            }}
            htmlFor="exampleCustomCheckbox"
          >
            Ignore these numbers and continue with the process
          </label>
        </div>
      </ModalBody>{" "}
      <ModalFooter>
        <button
          className="btn-light"
          disabled={loading}
          onClick={() => {
            setModal(false);
            setCheck(false);
          }}
        >
          Cancel
        </button>
        <button
          className="btn-primary"
          disabled={!check}
          onClick={() => filterValidNumbers()}
        >
          {loading && <LoadingButton />}
          Continue
        </button>
      </ModalFooter>
    </Modal>
  );
}
