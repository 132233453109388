import React from "react";
import ReactDOM from "react-dom";
import arrow from "../../assets/images/arrow.svg";
import { Col } from "reactstrap";
import "./MenuRight.css";

function MenuRight({ children, show, closeMenu, title, to, w50, subtitle, sizeSubtitle }) {
  return ReactDOM.createPortal(
    <div>
      <div
        className={`itp-menu-right-container ${show ? "open" : "hidden"}`}
      ></div>
      <div className={`itp-menu-right-content ${show ? "open" : "hidden"}${w50 ? " w50" : ""}`}>
        <div onClick={closeMenu} className="itp-menu-right-header">
          <img src={arrow} alt="Voice360" className="back-arrow" />
          <span className="itp-menu-right-back">Back {to ? to : ""}</span>
        </div>
        <div className="itp-menu-right-title">{title}</div>
        {subtitle && (
          <>
            {sizeSubtitle ? (
              <Col xs={sizeSubtitle} sm={sizeSubtitle} md={sizeSubtitle} lg={sizeSubtitle} xl={sizeSubtitle} xxl={sizeSubtitle}>
                <p className="itp-menu-right-subtitle">{subtitle}</p>
              </Col>
            ) : (
              <>
                <p className="itp-menu-right-subtitle">{subtitle}</p>
              </>
            )}
          </>
        )}
        <div className="itp-menu-right-body">{children}</div>
      </div>
    </div>,

    document.getElementById("menu")
  );
}

export default MenuRight;
