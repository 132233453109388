import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPenToSquare,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { Card, CardBody, Col, Row } from "reactstrap";
import { useMenuDispatch } from "../../../../contexts/MenuContext.js";
import {
  CustomTable,
  Loading,
  MenuRight,
  TextAreaVarSelect,
  LoadingButton,
} from "../../../../components/index.js";
import { timeZones } from "../../../../const/timeZones.js";
import Switch from "react-switch";
import Select from "react-select";
import PhoneInput from "react-phone-input-2";
import SettingsIcon from "@mui/icons-material/Settings";
import petitionGet from "../../../../services/petitionGet.js";
import petitionPost from "../../../../services/petitionPost.js";
import petitionPatch from "../../../../services/petitionPatch.js";
import petitionDelete from "../../../../services/petitionDelete.js";
import ModalDelete from "../ModalUtility/ModalDelete.js";
import ModalError from "../ModalUtility/ModalError.js";
import MediaGroupDetails from "./MediaGroupDetails/MediaGroupDetails.js";
import infoIcon from "../../../../assets/icons/information-icon.svg";
import arrowleft from "../../../../assets/icons/arrow-left.svg";
import useScreenSize from "../../../../hooks/useScreenSize.js";
import "../WorkspaceSettings.css";

const GeneralSettings = () => {
  const { width } = useScreenSize();

  const myExtension = useSelector((state) => state.myExtension.userDetails);

  const navigate = useNavigate();
  const dispatch = useMenuDispatch();

  //general
  const [loading, setLoading] = useState(true);
  const [openMenu, setOpenMenu] = useState(false);
  const [info, setInfo] = useState(null);
  const [loadingModal, setLoadingModal] = useState(false);

  //general settings
  const [valueTimeZone, setValueTimeZone] = useState(null);
  const [musicOnHold, setMusicOnHold] = useState([]);
  const [valueMusicOnHold, setValueMusicOnHold] = useState(null);
  const [switchB2B, setSwitchB2B] = useState(false);

  //caller id
  const [formName, setFormName] = useState({ name: "" })
  const [allNumbers, setAllNumbers] = useState([]);
  const [valueNumber, seValueNumber] = useState(null);
  const [firstLoad, setFirstLoad] = useState(true)

  //messages template
  const [dataTableTemplates, setDataTableTemplates] = useState(null);
  const [options, setOptions] = useState([]);
  const [loadingTemplate, setLoadingTemplate] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [formTemplate, setFormTemplate] = useState({
    description: "",
    title: "",
    body: "",
  });

  //lead source
  const [dataTableSources, setDataTableSources] = useState(null);
  const [showAddSource, setShowAddSource] = useState(false);
  const [loadingSource, setLoadingSource] = useState(false);
  const [formSource, setFormSource] = useState({ name: "" });
  const [showDeleteSource, setShowDeleteSource] = useState(false);

  //feature code
  const featuresCallflow = useRef({
    intercomCode: null,
    parkCode: null,
    parkPick: null,
  });

  const dataFeatureCodes = useRef(null);

  const [dataTableFeatureCode, setDataTableFeatureCode] = useState(null);
  const [loadingSwitch, setLoadingSwitch] = useState(false);
  const [errorSwitch, setErrorSwitch] = useState(false);
  const [showEditCallPickup, setShowEditCallPickup] = useState(false);
  const [parkingSlot, setParkingSlot] = useState("5");
  const [timeout, setTimeout] = useState(null);
  const [dataTableCallPickup, setDataTableCallPickup] = useState(null);
  const [loadingCallPickup, setLoadingCallPickup] = useState(false);
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  //blocked callers
  const [dataTableBlockecCallers, setDataTableBlockecCallers] = useState(null);
  const [activeDeleteBlockedCall, setActiveDeleteBlockedCall] = useState(false);
  const [showAddBlockCall, setshowAddBlockCall] = useState(false);
  const [loadingBlockCall, setLoadingBlockCall] = useState(false);
  const [errorNumber, setErrorNumber] = useState("");
  const [formBlockedCall, setFormBlockedCall] = useState({
    phone_number: "",
  });

  //international calls
  const [dataTableInterCalls, setDataTableInterCalls] = useState(null);
  const [allCountries, setAllCountries] = useState([]);
  const [showAdd, setShowAdd] = useState(false);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [loadingInterCountry, setLoadingInterCountry] = useState(false);
  const [modalError, setModalError] = useState(false);
  const [showDeleteInterCountry, setShowDeleteInterCountry] = useState(false);

  //media audio file
  const [dataTableMedia, setDataTableMedia] = useState(null);
  const [showAddMediaGroup, setShowAddMediaGroup] = useState(false);
  const [loadingMediaGroup, setLoadingMediaGroup] = useState(false);
  const [mediaGroupDetails, setMediaGroupDetails] = useState(null);
  const [formMediaGroup, setFormMediaGroup] = useState({
    name: "",
  });

  // media audio file functions
  const activeAddMediaGroup = () => {
    setShowAddMediaGroup(true);
    setOpenMenu(true);
  };

  const addMediaGroup = () => {
    setLoadingMediaGroup(true);
    petitionPost("mediaGroup", { data: formMediaGroup })
      .then(({ data: result }) => {
        setLoadingMediaGroup(false);
        reloadData();
      })
      .catch((error) => console.log(error));
  };

  const activeEditMediaGroup = (e) => {
    setMediaGroupDetails(e);
  };

  const petitionMedia = async () => {
    await petitionGet("mediaGroups")
      .then(({ data: result }) => {
        setDataTableMedia({
          title: [{ title: "Name", key: "name" }],
          content: [...result.result],
          actions: {
            title: "Edit",
            content: [
              {
                icon: <FontAwesomeIcon icon={faPenToSquare} />,
                name: "Edit",
                handleClick: activeEditMediaGroup,
              },
            ],
          },
          labelIsTitle: true,
          addButton: {
            label: "Add Group Name",
            handleClick: activeAddMediaGroup,
          },
        });
      })
      .catch((error) => console.log(error));
  };
  //---------------------------------------------------

  // international calls functions
  const activeDeleteModalCountry = (e) => {
    setInfo(e);
    setShowDeleteInterCountry(true);
    setModalDelete(true);
  };

  const deleteInterCountry = () => {
    setLoadingModal(true);
    petitionDelete("blockedCountries", { country_id: info.pk })
      .then(({ data: result }) => {
        setLoadingModal(false);
        reloadData();
      })
      .catch((error) => console.log(error));
  };

  const addInterCountry = () => {
    setLoadingInterCountry(true);

    let addedCountries = selectedCountries.map((element) => element.pk);

    let data = {
      country_pks: addedCountries,
    };
    petitionPost("blockedCountries", { data })
      .then(({ data: result }) => {
        setLoadingInterCountry(false);
        reloadData();
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data.error.error_detailed.error.error_message
        ) {
          setLoadingInterCountry(false);
          setErrorNumber(
            error.response.data.error.error_detailed.error.error_message
          );
          setModalError(true);
        } else {
          console.log(error);
        }
      });
  };

  const petitonInterCalls = async () => {
    await petitionGet("allCountries")
      .then(({ data: result }) => {
        result.result.forEach((element) => {
          element.label = element.name;
          element.value = element.pk;
        });
        setAllCountries(result.result);
      })
      .catch((error) => console.log(error));
    await petitionGet("blockedCountries")
      .then(({ data: result }) => {
        result.result.forEach((element) => {
          element.label = element.country.name;
          element.value = element.pk;
        });

        setDataTableInterCalls({
          title: [{ title: "Country", key: "label" }],
          content: [...result.result],
          actions: {
            title: "Actions",
            content: [
              {
                name: "Delete",
                icon: <FontAwesomeIcon icon={faTrash} />,
                handleClick: activeDeleteModalCountry,
              },
            ],
          },
          noActions: true,
        });
      })
      .catch((error) => console.log(error));
  };
  //--------------------------------------------

  // blocked callers functions
  const activeDeleteModalBlockedCall = (e) => {
    setActiveDeleteBlockedCall(true);
    setInfo(e);
    setModalDelete(true);
  };

  const deleteBlockedCall = () => {
    setLoadingModal(true);
    petitionDelete("blockedCall", { pk: info.pk })
      .then(({ data: result }) => {
        setLoadingModal(false);
        reloadData();
      })
      .catch((error) => console.log(error));
  };

  const activeAddBlockedCall = () => {
    setshowAddBlockCall(true);
    setOpenMenu(true);
  };

  const addBlockCall = () => {
    setLoadingBlockCall(true);
    petitionPost("blockedCalls", { data: formBlockedCall })
      .then(({ data: result }) => {
        setLoadingBlockCall(false);
        reloadData();
      })
      .catch((error) => {
        console.log(error);
        setLoadingBlockCall(false);
        if (error.response.status === 400) {
          setErrorNumber(
            error.response.data.error.error_detailed.error.error_message
          );
        }
      });
  };

  const petitionBlockedCallers = async () => {
    await petitionGet("blockedCalls")
      .then(({ data: result }) => {
        setDataTableBlockecCallers({
          title: [{ title: "Numbers", key: "phone_number" }],
          content: result.result,
          actions: {
            title: "Delete",
            content: [
              {
                icon: <FontAwesomeIcon icon={faTrash} />,
                name: "Delete",
                handleClick: activeDeleteModalBlockedCall,
              },
            ],
          },
          labelIsTitle: true,
          addButton: {
            label: "Add Number",
            handleClick: activeAddBlockedCall,
          },
        });
      })
      .catch((error) => console.log(error));
  };
  //-----------------------------------------

  // feature code functions
  const onHandleSave = async () => {
    if (!loadingSwitch) {
      try {
        setLoadingSwitch(true);

        if (
          featuresCallflow.current.intercomCode.length > 0 &&
          !dataFeatureCodes.current[0].switch
        )
          await petitionDelete("callFlow", {
            callflow_id: featuresCallflow.current.intercomCode[0].pk,
          }).catch((err) => {
            throw err;
          });
        else if (
          !featuresCallflow.current.intercomCode.length > 0 &&
          dataFeatureCodes.current[0].switch
        ) {
          await petitionPost("callflow", {
            data: {
              callflow_object: {
                type: "featureCode",
                flow: {
                  children: {},
                  data: { action: "compose" },
                  module: "intercom",
                },
                featurecode: { name: "intercom", number: "0" },
                patterns: ["^\\*0([0-9]*)$"],
                metadata: { itpvoice: { name: "intercom" } },
              },
              type: "featureCode",
              numbers: [],
            },
          }).catch((error) => {
            throw error;
          });
        }

        if (
          featuresCallflow.current.parkCode.length > 0 &&
          !dataFeatureCodes.current[1].switch
        ) {
          await petitionDelete("callFlow", {
            callflow_id: featuresCallflow.current.parkCode[0].pk,
          }).catch((err) => {
            throw err;
          });
          if (featuresCallflow.current.parkPick.length > 0)
            featuresCallflow.current.parkPick.map((element) =>
              petitionDelete("callFlow", { callflow_id: element.pk }).catch(
                (err) => {
                  throw err;
                }
              )
            );
        } else if (
          !featuresCallflow.current.parkCode.length > 0 &&
          dataFeatureCodes.current[1].switch
        ) {
          await petitionPost("callflow", {
            data: {
              callflow_object: {
                flow: {
                  children: {},
                  data: {
                    action: "park",
                    timeout: "120",
                  },
                  module: "callparking",
                },
                featurecode: { name: "park_call", number: "70" },
                type: "featureCode",
              },
              type: "featureCode",

              numbers: ["70"],
            },
          }).catch((err) => {
            throw err;
          });

          let objectDataCallflow = (slot) => {
            return {
              data: {
                callflow_object: {
                  flow: {
                    data: {
                      action: "pickup",
                      slot_number: `${slot}`,
                    },
                    module: "callparking",
                    children: {},
                  },
                  featurecode: { name: "park_pickup", number: "70" },
                  type: "featureCode",
                },
                type: "featureCode",
                numbers: [`${slot}`],
              },
            };
          };

          await petitionPost("callparking", {
            data: { slots: [71, 72, 73, 74, 75] },
          }).catch((err) => {
            throw err;
          });

          await petitionPost("callflow", objectDataCallflow("71")).catch(
            (err) => {
              throw err;
            }
          );
          await petitionPost("callflow", objectDataCallflow("72")).catch(
            (err) => {
              throw err;
            }
          );
          await petitionPost("callflow", objectDataCallflow("73")).catch(
            (err) => {
              throw err;
            }
          );
          await petitionPost("callflow", objectDataCallflow("74")).catch(
            (err) => {
              throw err;
            }
          );
          await petitionPost("callflow", objectDataCallflow("75")).catch(
            (err) => {
              throw err;
            }
          );
        }

        let allPetitionFeatureCodes = [];

        dataFeatureCodes.current.forEach((element) => {
          if (element.updateEnable) {
            allPetitionFeatureCodes.push(
              petitionPatch("callFlow", {
                callflow_id: element.pk,
                data: {
                  callflow_object: {
                    flow: element.flow,
                    enabled: element.switch,
                  },
                  numbers: element.numbers,
                  name: element.name || "",
                },
              })
            );
          }
        });

        Promise.all(allPetitionFeatureCodes)
          .then(() => {
            petitionFeatureCode();
          })
          .catch((err) => {
            setErrorSwitch(true);
            setTimeout(() => {
              setErrorSwitch(false);
              setLoadingSwitch(false);
            }, 3000);
            console.log(err);
          });
      } catch (error) {
        console.log(error);
        setErrorSwitch(true);
        setTimeout(() => {
          setErrorSwitch(false);
          setLoadingSwitch(false);
        }, 3000);
      }
    }
  };

  const handleSwitch = (e, dataFeatures, parkPick) => {
    let newData = [];
    dataFeatures.forEach((element) => {
      newData.push({
        ...element,
        switch: element.pk === e.pk ? !element.switch : element.switch,
      });
    });

    dataFeatureCodes.current = newData;
    setDataTableFeatureCode({
      title: [
        { title: "Feature Code", key: "feature_code" },
        { title: "Name", key: "name" },
        { title: "Description", key: "description" },
      ],
      content: [...newData],
      switchComponent: {
        title: "Enabled",
        content: [
          {
            switch: true,
            keySwitch: "switch",
            handleClick: (e) => {
              handleSwitch(e, newData, parkPick);
            },
          },
          {
            component: (e) => {
              if (e.icon && e.switch && parkPick?.length > 0)
                return (
                  <div
                    onClick={() => {
                      console.log({
                        ...e,
                        featureCodeTimeout: featuresCallflow.current.parkCode,
                      });
                    }}
                    style={{ cursor: "pointer" }}
                    className=" mx-2 h-100 d-flex align-items-center"
                  >
                    <SettingsIcon />{" "}
                  </div>
                );
            },
            type: "component",
          },
        ],
      },
      label: "Feature Codes",
      labelIsTitle: true,
      addButton: {
        label: "Save",
        handleClick: onHandleSave,
        notIcon: true,
      },
    });
    forceUpdate();
  };

  const activeEditCallPickupMenu = (e) => {
    if (featuresCallflow.current.parkPick && e) {
      setDataTableCallPickup(null);
      let codeList = [];
      setTimeout(featuresCallflow.current.parkCode[0].flow.data.timeout);
      featuresCallflow.current.parkPick.forEach((element) => {
        codeList.push({ code: element.numbers[0] });
      });
      setDataTableCallPickup({
        title: [{ title: "Parking Slots", key: "code" }],
        content: codeList,
      });
      setParkingSlot(featuresCallflow.current.parkPick.length);

      setShowEditCallPickup(true);
      setInfo(e);
      setOpenMenu(true);
    }
  };

  const updateParkPickup = () => {
    setLoadingCallPickup(true);

    let slotsObject = [];
    for (let i = 1; i <= parseInt(parkingSlot); i++) {
      slotsObject.push(70 + i);
    }

    petitionPatch("callFlow", {
      data: {
        callflow_object: {
          flow: {
            children: {},
            data: {
              action: "park",
              timeout: timeout,
            },
            module: "callparking",
          },
          featurecode: { name: "park_call", number: "70" },
          type: "featureCode",
        },
        type: "featureCode",

        numbers: ["70"],
      },
      callflow_id: featuresCallflow.current.parkCode[0].pk,
    }).catch((err) => {
      throw err;
    });

    if (featuresCallflow.current.parkPick.length > 0) {
      let allDelete = [];

      featuresCallflow.current.parkPick.map((element) =>
        allDelete.push(
          petitionDelete("callFlow", { callflow_id: element.pk }).catch(
            (err) => {
              console.log(err);
            }
          )
        )
      );

      Promise.all(allDelete)
        .then((result) => {
          let objectDataCallflow = (slot) => {
            return {
              data: {
                callflow_object: {
                  flow: {
                    data: {
                      action: "pickup",
                      slot_number: `${slot}`,
                    },
                    module: "callparking",
                    children: {},
                  },
                  type: "featureCode",
                  featurecode: { name: "park_pickup", number: "70" },
                },
                type: "featureCode",

                numbers: [`${slot}`],
              },
              callflow_id: featuresCallflow.current.parkPick[0].pk,
            };
          };

          let updatearray = [];

          slotsObject.map((element) => {
            updatearray.push(
              petitionPost("callflow", objectDataCallflow(element))
            );
          });
          Promise.all(updatearray)
            .then((result) => {
              petitionPost("callparking", { data: { slots: slotsObject } })
                .then((result) => {
                  petitionFeatureCode();
                })
                .catch((err) => {
                  console.log(err);
                });
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const petitionFeatureCode = async () => {
    petitionGet("allCallflows")
      .then(({ data: result }) => {
        const findIntercomCode = result.result.filter(
          (element) => element.featurecode?.name === "intercom"
        );

        const findParkCode = result.result.filter(
          (element) => element.featurecode?.name === "park_call"
        );

        const findParkPickCode = result.result.filter(
          (element) => element.featurecode?.name === "park_pickup"
        );

        const saveAllCallflowsFeactures = result.result.filter(
          (element) =>
            element.type === "featureCode" &&
            element.featurecode?.name !== "intercom" &&
            element.featurecode?.name !== "park_pickup" &&
            element.featurecode?.name !== "park_call"
        );

        let dataFeatureCodesIntern = [
          {
            pk: 0,
            switch: findIntercomCode.length > 0 ? true : false,
            feature_code: "*0",
            name: "Intercom",
            description:
              "Allows for calling an extension and automatically answering the call",
          },
          {
            pk: 1,
            icon: true,
            switch: findParkCode.length > 0 ? true : false,
            feature_code: "*70",
            name: "Park Call",
            description:
              "Place a call in one of the available parking slots automatically",
          },
        ];

        saveAllCallflowsFeactures.forEach((element) => {
          dataFeatureCodesIntern.push({
            ...element,
            feature_code: element.numbers,
            description: "Feature Code",
            switch: element.enabled ? true : false,
            updateEnable: true,
          });
        });

        setDataTableFeatureCode({
          title: [
            { title: "Feature Code", key: "feature_code" },
            { title: "Name", key: "name" },
            { title: "Description", key: "description" },
          ],
          content: [...dataFeatureCodesIntern],
          switchComponent: {
            title: "Enabled",
            content: [
              {
                switch: true,
                keySwitch: "switch",
                handleClick: (e) => {
                  handleSwitch(e, dataFeatureCodesIntern, findParkPickCode);
                },
                type: "",
              },
              {
                component: (e) => {
                  if (e.icon && e.switch && findParkPickCode.length > 0)
                    return (
                      <div
                        onClick={() => {
                          activeEditCallPickupMenu({
                            ...e,
                            featureCodeTimeout:
                              featuresCallflow.current.parkCode,
                          });
                        }}
                        style={{ cursor: "pointer" }}
                        className=" mx-2 h-100 d-flex align-items-center"
                      >
                        <SettingsIcon />{" "}
                      </div>
                    );
                },
                type: "component",
              },
            ],
          },
          label: "Feature Codes",
          labelIsTitle: true,
          addButton: {
            label: "Save",
            handleClick: onHandleSave,
            notIcon: true,
          },
        });

        featuresCallflow.current = {
          intercomCode: findIntercomCode,
          parkCode: findParkCode,
          parkPick: findParkPickCode,
        };

        dataFeatureCodes.current = dataFeatureCodesIntern;

        setLoadingSwitch(false);
        setLoadingCallPickup(false);
        closeMenu();
        forceUpdate();
      })
      .catch((error) => console.log(error));
  };
  //--------------------------------------------

  // lead source functions
  const activeAddSource = () => {
    setShowAddSource(true);
    setOpenMenu(true);
  };

  const onChangeFormSource = (e) => {
    setFormSource({ ...formSource, [e.target.name]: e.target.value });
  };

  const addEditSource = () => {
    if (!info) {
      setLoadingSource(true);
      petitionPost("leadSource", { data: formSource })
        .then(({ data: result }) => {
          setLoadingSource(false);
          reloadData();
        })
        .catch((error) => console.log(error));
    } else {
      setLoadingSource(true);
      petitionPatch("leadSource", { data: formSource, source_lead_id: info.pk })
        .then(({ data: result }) => {
          setLoadingSource(false);
          reloadData();
        })
        .catch((error) => console.log(error));
    }
  };

  const activeDeleteSource = (e) => {
    setInfo(e);
    setModalDelete(true);
    setShowDeleteSource(true);
  };

  const deleteSource = () => {
    setLoadingModal(true);
    petitionDelete("leadSource", { source_lead_id: info.pk })
      .then(({ data: result }) => {
        setLoadingModal(false);
        reloadData();
      })
      .catch((error) => console.log(error));
  };

  const activeEditSource = (e) => {
    setFormSource({ name: e.name });
    setInfo(e);
    setShowAddSource(true);
    setOpenMenu(true);
  };

  const petitionLeadSource = async () => {
    await petitionGet("sources", {})
      .then(({ data: result }) => {
        setDataTableSources({
          title: [{ title: "Name", key: "name" }],
          content: [...result.result],
          actions: {
            title: "Actions",
            content: [
              {
                icon: <FontAwesomeIcon icon={faPenToSquare} />,
                name: "Edit",
                handleClick: activeEditSource,
              },
              {
                icon: <FontAwesomeIcon icon={faTrash} />,
                name: "Delete",
                handleClick: activeDeleteSource,
              },
            ],
          },
          addButton: {
            label: "Add Lead Source",
            handleClick: activeAddSource,
          },
        });
      })
      .catch((error) => console.log(error));
  };
  //------------------------------------------

  // messages template functions
  const onChangeFormTemplate = (e) => {
    setFormTemplate({ ...formTemplate, [e.target.name]: e.target.value });
  };

  const onChangeTextArea = (e) => {
    setFormTemplate({ ...formTemplate, body: e });
  };

  const addEditTemplate = () => {
    if (!info) {
      setLoadingTemplate(true);
      petitionPost("messageTemplates", { data: formTemplate })
        .then(({ data: result }) => {
          setLoadingTemplate(false);
          reloadData();
        })
        .catch((error) => console.log(error));
    } else {
      setLoadingTemplate(true);
      petitionPatch("messageTemplates", {
        data: formTemplate,
        message_template_id: info.pk,
      })
        .then(({ data: result }) => {
          setLoadingTemplate(false);
          reloadData();
        })
        .catch((error) => console.log(error));
    }
  };

  const deleteTemplate = () => {
    setLoadingModal(true);
    petitionDelete("messageTemplates", { message_template_id: info.pk })
      .then(({ data: result }) => {
        setLoadingModal(false);
        reloadData();
      })
      .catch((error) => console.log(error));
  };

  const activeAddTemplate = () => {
    setOpenMenu(true);
  };

  const activeDeleteTemplate = (e) => {
    setInfo(e);
    setModalDelete(true);
  };

  const activeEditTemplate = (e) => {
    setInfo(e);
    setFormTemplate({
      description: e?.description || "",
      title: e?.title || "",
      body: e?.body || "",
    });

    setOpenMenu(true);
  };

  const petitionMessageTemplate = async () => {
    await petitionGet("customFieldAdmin")
      .then(({ data: result }) => {
        result.result.forEach((element) => {
          element.label = element.name;
          element.value = `{{contact.custom_field.${element.name}}}`;
        });

        setOptions([
          {
            title: "Variables",
            options: [
              {
                title: "Contact",
                options: [
                  {
                    label: "First Name",
                    value: "{{contact.standard_field.first_name}}",
                  },
                  {
                    label: "Last Name",
                    value: "{{contact.standard_field.last_name}}",
                  },
                  { label: "Phone", value: "{{contact.standard_field.phone}}" },
                  { label: "Email", value: "{{contact.standard_field.email}}" },
                  ...result.result,
                ],
              },
            ],
            dropdownOpen: false,
          },
        ]);
      })
      .catch((error) => console.log(error));

    await petitionGet("messageTemplates")
      .then(({ data: result }) => {
        result.result.forEach((element) => {
          element.description = element?.description || "";
        });

        setDataTableTemplates({
          title: [
            { title: "Template Name", key: "title" },
            { title: "Description", key: "description" },
            { title: "Message", key: "body" },
          ],
          content: [...result.result],
          actions: {
            title: "Actions",
            content: [
              {
                icon: <FontAwesomeIcon icon={faPenToSquare} />,
                name: "Edit",
                handleClick: activeEditTemplate,
              },
              {
                icon: <FontAwesomeIcon icon={faTrash} />,
                name: "Delete",
                handleClick: activeDeleteTemplate,
              },
            ],
          },
          addButton: {
            label: "Add Template",
            handleClick: activeAddTemplate,
          },
        });
      })
      .catch((error) => console.log(error));
  };
  //-------------------------------------------------

  // caller id functions
  const onChangeNumberCallerId = (e) => {
    seValueNumber(e)

    let data = {
      default_outbound_callerid_number: e.value,
    };

    petitionPatch("accountDetails", { data })
    .then(({ data: result }) => {})
    .catch((error) => console.log(error));
  }

  const onChangeName = (e) => {
    setFormName({ ...formName, [e.target.name]: e.target.value })
  }

  useEffect(() => {
    if (!firstLoad) {
      const timer = setTimeout(() => {
        let data = {
          default_outbound_callerid_name: formName.name,
        };


        petitionPatch("accountDetails", { data })
          .then(({ data: result }) => {})
          .catch((error) => console.log(error));
      }, 2000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [formName.name]);

  //-------------------------------------------------

  // general settings functions
  const handleChangeMusicOnHold = (e) => {
    setValueMusicOnHold(e);

    const data = {
      music_on_hold_id: e.value,
    };

    petitionPatch("accountDetails", { data })
      .then(({ data: result }) => {})
      .catch((error) => console.log(error));
  };

  const handleChangeTimeZone = (e) => {
    setValueTimeZone(e);

    const data = {
      time_zone: e.value,
    };

    petitionPatch("accountDetails", { data })
      .then(({ data: result }) => {})
      .catch((error) => console.log(error));
  };

  const petitionGeneralSettings = async () => {
    let musicOnHoldTemp = [];
    let tempDefaultOutboundNumber = null

    await petitionGet("musicOnHold")
      .then(({ data: result }) => {
        result.result.forEach((element) => {
          element.label = element.name;
          element.value = element.pk;
        });

        musicOnHoldTemp = result.result;
        setMusicOnHold(result.result);
      })
      .catch((error) => console.log(error));

    await petitionGet("accountDetails")
      .then(({ data: result }) => {
        //caller id
        if (result.result.default_outbound_callerid_number) {
          tempDefaultOutboundNumber =
            result.result.default_outbound_callerid_number;
          seValueNumber({
            name: result.result.default_outbound_callerid_number,
            value: result.result.default_outbound_callerid_number,
            label: result.result.default_outbound_callerid_number,
          });
        }

        if (result.result.default_outbound_callerid_name) {
          setFormName({ name: result.result.default_outbound_callerid_name })
        }
        //----------------------------

        const defaultTimeZone = timeZones.find(
          (element) => element.value === result.result.time_zone
        );
        const defaultMusicOnHold = musicOnHoldTemp.find(
          (element) => element.pk === result.result.moh_media_id
        );

        if (defaultTimeZone) {
          setValueTimeZone(defaultTimeZone);
        }

        if (defaultMusicOnHold) {
          setValueMusicOnHold(defaultMusicOnHold);
        }

        setSwitchB2B(result.result.accounts_b2b_enabled);
      })
      .catch((error) => console.log(error));

    //petition caller id
    await petitionGet("viewNumbers")
      .then(({ data: result }) => {
        let preAllData = [];

        result.result.forEach((element) => {
          preAllData.push({
            name: element.number,
            value: element.number,
            label: element.number,
          });
        });

        let findUsedNumber = result.result.find(
          (element) => element.number === tempDefaultOutboundNumber
        );

        if (!findUsedNumber)
          preAllData.push({
            name: tempDefaultOutboundNumber,
            value: tempDefaultOutboundNumber,
            label: tempDefaultOutboundNumber,
          });

        preAllData.push({ name: "Empty", value: "", label: "Empty" });

        setAllNumbers(preAllData);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  };
  //-----------------------------------------------

  // general functions
  const selectModuleName = () => {
    if (
      !showDeleteSource &&
      !activeDeleteBlockedCall &&
      !showDeleteInterCountry
    )
      return "Message Template";

    if (showDeleteSource && !activeDeleteBlockedCall && !showDeleteInterCountry)
      return "Lead Source";

    if (!showDeleteSource && activeDeleteBlockedCall && !showDeleteInterCountry)
      return "Blocked Call";

    if (!showDeleteSource && !activeDeleteBlockedCall && showDeleteInterCountry)
      return "Country";
  };

  const selectTitleMenu = () => {
    if (
      !showAddSource &&
      !showEditCallPickup &&
      !showAddBlockCall &&
      !showAddMediaGroup
    )
      return "Messages Template";

    if (
      showAddSource &&
      !showEditCallPickup &&
      !showAddBlockCall &&
      !showAddMediaGroup
    )
      return "Lead Source";

    if (
      !showAddSource &&
      showEditCallPickup &&
      !showAddBlockCall &&
      !showAddMediaGroup
    )
      return "Call Pickup";

    if (
      !showAddSource &&
      !showEditCallPickup &&
      showAddBlockCall &&
      !showAddMediaGroup
    )
      return "Number To Block";

    if (
      !showAddSource &&
      !showEditCallPickup &&
      !showAddBlockCall &&
      showAddMediaGroup
    )
      return "Media Group";
  };

  const selectModalFunction = () => {
    if (
      !showDeleteSource &&
      !activeDeleteBlockedCall &&
      !showDeleteInterCountry
    )
      deleteTemplate();

    if (showDeleteSource && !activeDeleteBlockedCall && !showDeleteInterCountry)
      deleteSource();

    if (!showDeleteSource && activeDeleteBlockedCall && !showDeleteInterCountry)
      deleteBlockedCall();

    if (!showDeleteSource && !activeDeleteBlockedCall && showDeleteInterCountry)
      deleteInterCountry();
  };

  const closeMenu = () => {
    setMediaGroupDetails(null);
    setShowAddMediaGroup(false);
    setShowDeleteInterCountry(false);
    setModalError(false);
    setFormBlockedCall({ phone_number: "" });
    setShowAddSource(false);
    setshowAddBlockCall(false);
    setActiveDeleteBlockedCall(false);
    setShowEditCallPickup(false);
    setShowDeleteSource(false);
    setModalDelete(false);
    setInfo(null);
    setOpenMenu(false);
  };

  const reloadData = () => {
    closeMenu();
    petition();
  };

  const handleChangeswitch = () => {
    setSwitchB2B(!switchB2B);

    const data = {
      accounts_b2b_enabled: !switchB2B,
    };

    petitionPatch("accountDetails", { data })
      .then(({ data: result }) => {})
      .catch((error) => console.log(error));
  };

  const handleBackClick = () => {
    if (!mediaGroupDetails) {
      dispatch({ type: "SET_DEFAULT_MENU" });
      navigate("/Dashboard");
    } else {
      reloadData();
    }
  };
  //------------------------------------

  const petition = async () => {
    setLoading(true);

    await petitionMedia();

    await petitonInterCalls();

    await petitionBlockedCallers();

    await petitionFeatureCode();

    await petitionLeadSource();

    await petitionMessageTemplate();

    await petitionGeneralSettings();

    setFirstLoad(false)
  };

  useEffect(() => {
    if (
      Object.keys(myExtension).length > 0 &&
      myExtension.priv_level !== "admin"
    ) {
      handleBackClick();
    }
  }, [myExtension]);

  useEffect(() => {
    petition();
  }, []);

  return (
    <>
      <div onClick={handleBackClick} className="itp-tex-back-container">
        <div className="itp-text-container-funnel-details">
          <img src={arrowleft} alt="arrow-left" />
          <span className="itp-text-back-funnel-details">
            {!mediaGroupDetails ? "Back to Dashboard" : "Back to Settings"}
          </span>
        </div>
      </div>

      {loading ? (
        <Loading />
      ) : (
        <>
          <div
            className={`itp-general-settings-update-alert ${
              loadingSwitch ? "open" : "hidden"
            }`}
          >
            <label className="itp-general-settings-update-alert-label">
              {errorSwitch
                ? "an error has occurred"
                : "Updating, please do not reload or leave the page."}
            </label>
          </div>

          {modalError && (
            <ModalError
              modal={modalError}
              closeModal={closeMenu}
              error={errorNumber}
            />
          )}

          {modalDelete && (
            <ModalDelete
              modal={modalDelete}
              closeModal={closeMenu}
              loading={loadingModal}
              modalFunction={selectModalFunction}
              module={selectModuleName()}
            />
          )}

          <MenuRight
            show={openMenu}
            closeMenu={closeMenu}
            title={`${!info ? "Add" : "Edit"} ${selectTitleMenu()}`}
            to=""
          >
            {openMenu &&
              !showAddMediaGroup &&
              !showAddSource &&
              !showEditCallPickup &&
              !showAddBlockCall && (
                <>
                  <Row className="itp-container-label-input">
                    <Col
                      xs={5}
                      sm={5}
                      md={5}
                      lg={5}
                      xl={5}
                      xxl={5}
                      className="itp-label-input-col"
                    >
                      <label className="itp-label-input__label">
                        Template Name
                      </label>
                    </Col>
                    <Col
                      xs={7}
                      sm={7}
                      md={7}
                      lg={7}
                      xl={7}
                      xxl={7}
                      className="itp-label-input-col"
                    >
                      <input
                        type="text"
                        placeholder=": Add Name to this template"
                        className="form-control-itp itp-label-input__input"
                        name="title"
                        value={formTemplate.title}
                        onChange={onChangeFormTemplate}
                      />
                    </Col>
                  </Row>
                  <Row className="itp-container-label-input">
                    <Col
                      xs={5}
                      sm={5}
                      md={5}
                      lg={5}
                      xl={5}
                      xxl={5}
                      className="itp-label-input-col"
                    >
                      <label className="itp-label-input__label">
                        Description
                      </label>
                    </Col>
                    <Col
                      xs={7}
                      sm={7}
                      md={7}
                      lg={7}
                      xl={7}
                      xxl={7}
                      className="itp-label-input-col"
                    >
                      <input
                        type="text"
                        placeholder=": Add Name to this switch"
                        className="form-control-itp itp-label-input__input"
                        name="description"
                        value={formTemplate.description}
                        onChange={onChangeFormTemplate}
                      />
                    </Col>
                  </Row>
                  <label className="itp-label-input__label">
                    Template Name
                  </label>
                  <Col>
                    <TextAreaVarSelect
                      options={options}
                      onChangeTextArea={onChangeTextArea}
                      textAreaDefaultValue={formTemplate.body}
                    />
                  </Col>
                  <button
                    onClick={addEditTemplate}
                    className="btn-primary itp-lead-detail-button-save loading mb-4"
                    disabled={
                      !formTemplate.body ||
                      !formTemplate.title ||
                      !formTemplate.description
                    }
                  >
                    {loadingTemplate && <LoadingButton />}
                    Save
                  </button>
                </>
              )}

            {openMenu &&
              !showAddMediaGroup &&
              showAddSource &&
              !showEditCallPickup &&
              !showAddBlockCall && (
                <>
                  <Row className="itp-container-label-input">
                    <Col
                      xs={5}
                      sm={5}
                      md={5}
                      lg={5}
                      xl={5}
                      xxl={5}
                      className="itp-label-input-col"
                    >
                      <label className="itp-label-input__label">Name</label>
                    </Col>
                    <Col
                      xs={7}
                      sm={7}
                      md={7}
                      lg={7}
                      xl={7}
                      xxl={7}
                      className="itp-label-input-col"
                    >
                      <input
                        type="text"
                        placeholder=": Add Name to this lead source"
                        className="form-control-itp itp-label-input__input"
                        name="name"
                        value={formSource.name}
                        onChange={onChangeFormSource}
                      />
                    </Col>
                  </Row>
                  <button
                    onClick={addEditSource}
                    className="btn-primary itp-lead-detail-button-save loading mb-4"
                    disabled={formSource.name ? false : true}
                  >
                    {loadingSource && <LoadingButton />}
                    Save
                  </button>
                </>
              )}

            {openMenu &&
              !showAddMediaGroup &&
              showEditCallPickup &&
              !showAddSource &&
              !showAddBlockCall && (
                <>
                  <Row className="itp-container-label-input">
                    <Col
                      xs={5}
                      sm={5}
                      md={5}
                      lg={5}
                      xl={5}
                      xxl={5}
                      className="itp-label-input-col"
                    >
                      <label className="itp-label-input__label">
                        Park Timeout
                      </label>
                    </Col>
                    <Col
                      xs={7}
                      sm={7}
                      md={7}
                      lg={7}
                      xl={7}
                      xxl={7}
                      className="itp-label-input-col"
                    >
                      <input
                        type="text"
                        placeholder=": Add Park Timeout"
                        className="form-control-itp itp-label-input__input"
                        name="name"
                        value={timeout}
                        onChange={(e) => {
                          setTimeout(e.target.value);
                        }}
                      />
                    </Col>
                  </Row>
                  <Row className="itp-container-label-select">
                    <Col
                      xs={6}
                      sm={6}
                      md={6}
                      lg={6}
                      xl={6}
                      xxl={6}
                      className="itp-label-input-col"
                    >
                      <label className="itp-label-input__label itp-analytics-label-select">
                        Parking Slots
                      </label>
                    </Col>
                    <Col
                      xs={6}
                      sm={6}
                      md={6}
                      lg={6}
                      xl={6}
                      xxl={6}
                      className="itp-label-input-col"
                    >
                      <select
                        className="form-select-itp"
                        onChange={(e) => {
                          setParkingSlot(e.target.value);
                        }}
                        name="selectedReport"
                        value={parkingSlot}
                      >
                        <option value="" disabled>
                          Select Option
                        </option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                        <option value="12">12</option>
                        <option value="13">13</option>
                        <option value="14">14</option>
                        <option value="15">15</option>
                        <option value="16">16</option>
                        <option value="17">17</option>
                        <option value="18">18</option>
                        <option value="19">19</option>
                        <option value="20">20</option>
                      </select>
                    </Col>
                  </Row>
                  <div style={{ width: "8rem", marginLeft: "-2rem" }}>
                    {dataTableCallPickup && (
                      <CustomTable data={dataTableCallPickup} />
                    )}
                  </div>
                  <button
                    onClick={updateParkPickup}
                    className="btn-primary itp-lead-detail-button-save loading mb-4"
                    disabled={!parkingSlot || !timeout || loadingCallPickup}
                  >
                    {loadingCallPickup && <LoadingButton />}
                    Save
                  </button>
                </>
              )}

            {openMenu &&
              !showAddMediaGroup &&
              !showEditCallPickup &&
              !showAddSource &&
              showAddBlockCall && (
                <>
                  <Row className="itp-container-label-select">
                    <Col
                      xs={5}
                      sm={5}
                      md={5}
                      lg={5}
                      xl={5}
                      xxl={5}
                      className="itp-label-input-col"
                    >
                      <label className="itp-label-input__label">
                        Phone Number
                      </label>
                    </Col>
                    <Col
                      xs={7}
                      sm={7}
                      md={7}
                      lg={7}
                      xl={7}
                      xxl={7}
                      className="itp-label-input-col"
                    >
                      <PhoneInput
                        country={"us"}
                        value={formBlockedCall.phone_number}
                        disableCountryFlags={true}
                        enableSearch={true}
                        countryCodeEditable={false}
                        onChange={(e) => {
                          setErrorNumber("");
                          setFormBlockedCall({ phone_number: `+${e}` });
                        }}
                      />
                    </Col>
                  </Row>
                  {errorNumber && <p className="text-danger">{errorNumber}</p>}
                  <button
                    onClick={addBlockCall}
                    className="btn-primary itp-lead-detail-button-save loading mb-4"
                    disabled={
                      !formBlockedCall.phone_number ||
                      formBlockedCall.phone_number === "*" ||
                      loadingBlockCall
                    }
                  >
                    {loadingBlockCall && <LoadingButton />}
                    Save
                  </button>
                </>
              )}

            {openMenu &&
              showAddMediaGroup &&
              !showEditCallPickup &&
              !showAddSource &&
              !showAddBlockCall && (
                <>
                  <Row className="itp-container-label-input">
                    <Col
                      xs={5}
                      sm={5}
                      md={5}
                      lg={5}
                      xl={5}
                      xxl={5}
                      className="itp-label-input-col"
                    >
                      <label className="itp-label-input__label">Name</label>
                    </Col>
                    <Col
                      xs={7}
                      sm={7}
                      md={7}
                      lg={7}
                      xl={7}
                      xxl={7}
                      className="itp-label-input-col"
                    >
                      <input
                        type="text"
                        placeholder=": Add Park Timeout"
                        className="form-control-itp itp-label-input__input"
                        name="name"
                        value={formMediaGroup.name}
                        onChange={(e) => {
                          setFormMediaGroup({ name: e.target.value });
                        }}
                      />
                    </Col>
                  </Row>
                  <button
                    onClick={addMediaGroup}
                    className="btn-primary itp-lead-detail-button-save loading mb-4"
                    disabled={!formMediaGroup.name || loadingMediaGroup}
                  >
                    {loadingMediaGroup && <LoadingButton />}
                    Save
                  </button>
                </>
              )}
          </MenuRight>

          {mediaGroupDetails ? (
            <>
              <MediaGroupDetails infoGroup={mediaGroupDetails} />
            </>
          ) : (
            <>
              <div id="generalsettings">
                <div>
                  <Row>
                    <Col>
                      <div>
                        <h1
                          className="itp-custom-table-title centered"
                          style={{ fontSize: "18px", fontWeight: "bold" }}
                        >
                          General Settings
                        </h1>

                        <div
                          style={{
                            maxWidth: "991px",
                            margin: "auto",
                            color: "#3a3a3acc",
                            font: "normal normal normal 14px/19px Instrument Sans",
                          }}
                        >
                          General settings that affect your entire workspace
                        </div>
                      </div>

                      <Card
                        className="itp-table-container-centered card mt-4"
                        style={{ marginLeft: "0px" }}
                      >
                        <CardBody>
                          <span className="itp-user-settings-card-title">
                            Time Zone
                          </span>

                          <Row className="itp-user-settings-card-help-text">
                            <Col md={8} className="d-flex">
                              <div>
                                <img
                                  src={infoIcon}
                                  alt="myExtensionTemp-icon"
                                />
                              </div>
                              <div className="ms-3">
                                <p>
                                  Used to determine TimeZone in Callflows or
                                  workflows
                                </p>
                              </div>
                            </Col>

                            <Col md={4}>
                              <div className="itp-custom-table-container-switchs d-flex align-items-center pl-0 justify-content-end">
                                <Select
                                  onChange={(e) => handleChangeTimeZone(e)}
                                  className="basic-single"
                                  classNamePrefix="select"
                                  value={valueTimeZone}
                                  isSearchable={true}
                                  name="time_zone"
                                  options={timeZones}
                                />
                              </div>
                            </Col>
                          </Row>

                          <span className="itp-user-settings-card-title">
                            Music On Hold
                          </span>

                          <Row className="itp-user-settings-card-help-text">
                            <Col md={8} className="d-flex">
                              <div>
                                <img
                                  src={infoIcon}
                                  alt="myExtensionTemp-icon"
                                />
                              </div>
                              <div className="ms-3">
                                <p>
                                  Default music to play when a customer is on
                                  hold in a Queue or when placed on hold
                                  manually.
                                </p>
                              </div>
                            </Col>

                            <Col md={4}>
                              <div className="itp-custom-table-container-switchs d-flex align-items-center pl-0 justify-content-end">
                                <Select
                                  onChange={(e) => handleChangeMusicOnHold(e)}
                                  className="basic-single"
                                  classNamePrefix="select"
                                  value={valueMusicOnHold}
                                  isSearchable={true}
                                  name="music_on_hold_id"
                                  options={musicOnHold}
                                />
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </div>
              </div>

              <br></br>
              <div>
                <Row>
                  <Col style={{ zIndex: "-1 " }}>
                    <div className="itp-user-settings-separator-line"></div>
                  </Col>
                </Row>
              </div>
              <br></br>

              <div id="callForwarding">
                <div>
                  <Row>
                    <Col>
                      <div>
                        <h1
                          className="itp-custom-table-title centered"
                          style={{ fontSize: "18px", fontWeight: "bold" }}
                        >
                          Advanced Features
                        </h1>

                        <div
                          style={{
                            maxWidth: "991px",
                            margin: "auto",
                            color: "#3a3a3acc",
                            font: "normal normal normal 14px/19px Instrument Sans",
                          }}
                        >
                          Features that are not enabled by default can be
                          enabled below for advanced users
                        </div>
                      </div>

                      <Card className="itp-table-container-centered card mt-4" style={{zIndex:"-1"}}>
                        <CardBody>
                          <span className="itp-user-settings-card-title">
                            Enable B2B Accounts
                          </span>

                          <Row className="itp-user-settings-card-help-text">
                            <Col md={10} className="d-flex">
                              <div>
                                <img
                                  src={infoIcon}
                                  alt="myExtensionTemp-icon"
                                />
                              </div>
                              <div className="ms-3">
                                <p>
                                  This option will enable the 'Accounts' field
                                  in Contacts which is useful for Business to
                                  Business Contacts. Account objects can have
                                  multiple contacts under an Account and allows
                                  for a more centralized view when dealing with
                                  organizations.
                                </p>
                              </div>
                            </Col>

                            <Col md={2}>
                              <div className="itp-custom-table-container-switchs d-flex align-items-center pl-0 justify-content-end">
                                <Switch
                                  className="itp-custom-table-switch"
                                  onColor="#626ed4"
                                  height={20} // Ajusta la altura según tus necesidades
                                  width={40}
                                  checked={switchB2B}
                                  onChange={handleChangeswitch}
                                />
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </div>
              </div>

              <br></br>
              <div>
                <Row>
                  <Col style={{ zIndex: "-1 " }}>
                    <div className="itp-user-settings-separator-line"></div>
                  </Col>
                </Row>
              </div>
              <br></br>

              <div id="callerId">
                <div>
                  <Row>
                    <Col>
                    <div>
                        <h1
                          className="itp-custom-table-title centered"
                          style={{ fontSize: "18px", fontWeight: "bold" }}
                        >
                          Caller ID
                        </h1>

                        <div
                          style={{
                            maxWidth: "991px",
                            margin: "auto",
                            color: "#3a3a3acc",
                            font: "normal normal normal 14px/19px Instrument Sans",
                          }}
                        >
                          Default Caller ID to be used if not set on the User
                        </div>
                      </div>

                      <Card
                        className="itp-table-container-centered mt-4 "
                        style={{ marginLeft: "0px" }}
                      >
                        <CardBody>
                          <Row className="itp-user-settings-card-help-text">
                            <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8} className="d-flex align-items-center">
                              <div className="ms-3">
                                <span className="itp-user-settings-card-title">
                                  Default Name
                                </span>
                              </div>
                            </Col>

                            <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                              <div className="itp-custom-table-container-switchs d-flex align-items-center pl-0 justify-content-end">
                                <input
                                  name="name"
                                  value={formName.name}
                                  onChange={onChangeName}
                                  className="form-control-itp"
                                />
                              </div>
                            </Col>
                          </Row>

                          <Row className="itp-user-settings-card-help-text mt-4">
                            <Col xs={7} sm={7} md={7} lg={7} xl={7} xxl={7} className="d-flex align-items-center">
                              <div className="ms-3">
                                <span className="itp-user-settings-card-title">
                                  Default Number
                                </span>
                              </div>
                            </Col>

                            <Col xs={3} sm={3} md={3} lg={3} xl={3} xxl={3}>
                              <div className="itp-custom-table-container-switchs d-flex align-items-center pl-0 justify-content-end">
                                <Select
                                  onChange={(e) => onChangeNumberCallerId(e)}
                                  className="basic-single"
                                  classNamePrefix="select"
                                  value={valueNumber}
                                  isSearchable={true}
                                  name="music_on_hold_id"
                                  options={allNumbers}
                                />
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </div>
              </div>

              <br></br>
              <div>
                <Row>
                  <Col style={{ zIndex: "-1 " }}>
                    <div className="itp-user-settings-separator-line"></div>
                  </Col>
                </Row>
              </div>
              <br></br> 

              <div>
                        <h1
                          className="itp-custom-table-title centered"
                          style={{ fontSize: "18px", fontWeight: "bold" }}
                        >
                          Message Templates
                        </h1>

                        <div
                          style={{
                            maxWidth: "991px",
                            margin: "auto",
                            color: "#3a3a3acc",
                            font: "normal normal normal 14px/19px Instrument Sans",
                          }}
                        >
                          Create templates that can be used inside workflows or messages.
                        </div>
                      </div>

              <div id="messageTemplates">
                <div className="mb-2">
                  {dataTableTemplates && (
                    <CustomTable
                      data={dataTableTemplates}
                      noPagBack={true}
                      centered={width > 991 ? true : false}
                      overflowCard={width > 991 ? "inherit" : "auto"}
                    />
                  )}
                </div>
              </div>

              <br></br>
              <div>
                <Row>
                  <Col style={{ zIndex: "-1" }}>
                    <div className="itp-user-settings-separator-line"></div>
                  </Col>
                </Row>
              </div>
              <br></br>

              <div>
                        <h1
                          className="itp-custom-table-title centered"
                          style={{ fontSize: "18px", fontWeight: "bold" }}
                        >
                          Lead Source
                        </h1>

                        <div
                          style={{
                            maxWidth: "991px",
                            margin: "auto",
                            color: "#3a3a3acc",
                            font: "normal normal normal 14px/19px Instrument Sans",
                          }}
                        >
                          Add lead sources here to be used in Leads
                        </div>
                      </div>

              <div id="leadSource">
                <div className="mb-2">
                  {dataTableSources && (
                    <CustomTable
                      data={dataTableSources}
                      noPagBack={true}
                      centered={width > 991 ? true : false}
                      overflowCard={width > 991 ? "inherit" : "auto"}
                    />
                  )}
                </div>
              </div>

              <br></br>
              <div className="mt-1">
                <Row>
                  <Col style={{ zIndex: "-1" }}>
                    <div className="itp-user-settings-separator-line"></div>
                  </Col>
                </Row>
              </div>
              <br></br>

              <div id="featureCode">
                <div className="mb-2">
                  {dataTableFeatureCode && (
                    <CustomTable
                      data={dataTableFeatureCode}
                      noPagBack={true}
                      centered={width > 991 ? true : false}
                    />
                  )}
                </div>
              </div>

              <br></br>
              <div>
                <Row>
                  <Col>
                    <div className="itp-user-settings-separator-line"></div>
                  </Col>
                </Row>
              </div>
              <br></br>

              <div>
                        <h1
                          className="itp-custom-table-title centered"
                          style={{ fontSize: "18px", fontWeight: "bold" }}
                        >
                          Blocked Callers
                        </h1>

                        <div
                          style={{
                            maxWidth: "991px",
                            margin: "auto",
                            color: "#3a3a3acc",
                            font: "normal normal normal 14px/19px Instrument Sans",
                          }}
                        >
                          Adding a number here will prevent them from calling any of your numbers.
                        </div>
                      </div>

              <div id="blockedCallers">
                <div className="mb-2">
                  {dataTableBlockecCallers && (
                    <CustomTable
                      data={dataTableBlockecCallers}
                      noPagBack={true}
                      centered={width > 991 ? true : false}
                      overflowCard={width > 991 ? "inherit" : "auto"}
                    />
                  )}
                </div>
              </div>

              <br></br>
              <div>
                <Row>
                  <Col style={{ zIndex: "-1" }}>
                    <div className="itp-user-settings-separator-line"></div>
                  </Col>
                </Row>
              </div>
              <br></br>

              <div>
                        <h1
                          className="itp-custom-table-title centered"
                          style={{ fontSize: "18px", fontWeight: "bold" }}
                        >
                          Blocked Countries
                        </h1>

                        <div
                          style={{
                            maxWidth: "991px",
                            margin: "auto",
                            color: "#3a3a3acc",
                            font: "normal normal normal 14px/19px Instrument Sans",
                          }}
                        >
                          Adding a Country here will prevent this country from calling any of your numbers.
                        </div>
                      </div>

              <div id="internationalCalls">
                <div className="mb-2">
                  <div className="itp-custom-table-container-actions label">
                    <div className="itp-custom-table-actions">
                     
                    </div>
                    <div className="itp-custom-table-add-buttons mr-top-addButton">
                      {!showAdd ? (
                        <button
                          onClick={() => setShowAdd(true)}
                          className={`btn-primary itp-add-button${
                            width > 991 ? " centered" : ""
                          }`}
                        >
                          <FontAwesomeIcon
                            icon={faPlus}
                            className="itp-icon-plus"
                          />
                          Add Country
                        </button>
                      ) : (
                        <div
                          style={{ width: width > 991 ? "17rem" : "auto" }}
                          className={`d-flex flex-column itp-add-button${
                            width > 991 ? " centered" : ""
                          }`}
                        >
                          <Select
                            isMulti
                            onChange={(e) => setSelectedCountries(e)}
                            className="basic-single"
                            classNamePrefix="select"
                            value={selectedCountries}
                            isSearchable={true}
                            name="country_pks"
                            options={allCountries}
                          />
                          <div style={{ gap: "8px" }} className="d-flex mt-2">
                            <button
                              onClick={() => {
                                setSelectedCountries([]);
                                setShowAdd(false);
                              }}
                              className="btn-light"
                            >
                              Cancel
                            </button>
                            <button
                              onClick={addInterCountry}
                              className="btn-primary loading"
                              disabled={
                                selectedCountries.length > 0 ? false : true
                              }
                            >
                              {loadingInterCountry && <LoadingButton />}
                              Save
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  {dataTableInterCalls && (
                    <CustomTable
                      data={dataTableInterCalls}
                      noPagBack={true}
                      centered={width > 991 ? true : false}
                      overflowCard={width > 991 ? "inherit" : "auto"}
                    />
                  )}
                </div>
              </div>

              <br></br>
              <div>
                <Row>
                  <Col style={{ zIndex: "-1" }}>
                    <div className="itp-user-settings-separator-line"></div>
                  </Col>
                </Row>
              </div>
              <br></br>

              <div>
                        <h1
                          className="itp-custom-table-title centered"
                          style={{ fontSize: "18px", fontWeight: "bold" }}
                        >
                          Media Files
                        </h1>

                        <div
                          style={{
                            maxWidth: "991px",
                            margin: "auto",
                            color: "#3a3a3acc",
                            font: "normal normal normal 14px/19px Instrument Sans",
                          }}
                        >
                          Upload media files (mp3 or wav) that can be used for Music on Hold or Menus inside Callflows.
                        </div>
                      </div>

              <div id="mediaAudioFile">
                <div className="mb-2">
                  {dataTableMedia && (
                    <CustomTable
                      data={dataTableMedia}
                      noPagBack={true}
                      centered={width > 991 ? true : false}
                      overflowCard={width > 991 ? "inherit" : "auto"}
                    />
                  )}
                </div>
              </div>

              <br></br>
              <div>
                <Row>
                  <Col>
                    <div className="itp-user-settings-separator-line"></div>
                  </Col>
                </Row>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default GeneralSettings;
