import React, { useState } from "react";
import { Col, Row } from "reactstrap";
import "./SelectSingleCheckbox.css";

const SelectSingleCheckbox = ({ name, options, defaultSelected, checkFunction }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(defaultSelected || null);

  const toggleSelect = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (value) => {
    if (selectedOption === value) {
      checkFunction(null);
      setSelectedOption(null);
    } else {
      checkFunction(value);
      setSelectedOption(value);
    }
  };

  return (
    <div className="select-container">
      <div
        className={`select-header${isOpen ? " active-menu" : ""}`}
        onClick={toggleSelect}
      >
        {name}
      </div>
      {isOpen && (
        <div className="select-options">
          {options.map((option, i) => (
            <Row
              key={i}
              className={`select-option ${
                selectedOption === option.value ? "selected" : ""
              }`}
              onClick={() => handleOptionClick(option.value)}
            >
              <Col lg={8}>
                <span>{option.label}</span>
              </Col>
              <Col lg={4}>
                <input
                  type="checkbox"
                  className="form-check-input"
                  checked={selectedOption === option.value}
                  readOnly
                />
              </Col>
            </Row>
          ))}
        </div>
      )}
    </div>
  );
};

export default SelectSingleCheckbox;
