import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { useMenuDispatch } from "../../../../contexts/MenuContext";
import { Loading, CustomTable } from "../../../../components";
import arrowleft from "../../../../assets/icons/arrow-left.svg";
import petitionGet from "../../../../services/petitionGet";
import petitionDelete from "../../../../services/petitionDelete";
import ModalDelete from "../ModalUtility/ModalDelete";
import EditUSer from "./EditUser/EditUSer";
import "./Users.css";

const Users = () => {
  const myExtension = useSelector((state) => state.myExtension.userDetails);

  const navigate = useNavigate();
  const dispatch = useMenuDispatch();

  const users = useRef([]);
  const dataTable = useRef(null);

  const [loading, setLoading] = useState(true);
  const [modalDelete, setModalDelete] = useState(false);
  const [info, setInfo] = useState(null);
  const [loadingUser, setLoadingUser] = useState(false);
  const [showEditUSer, setShowEditUSer] = useState(null);
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const handleBackClick = () => {
    if (!showEditUSer) {
      dispatch({ type: "SET_DEFAULT_MENU" });
      navigate("/Dashboard");
    } else {
      reloadData()
    }
  };

  const closeModal = () => {
    setShowEditUSer(null)
    setInfo(null);
    setModalDelete(false);
  };

  const activeEditUser = (e) => {
    setShowEditUSer(e);
  };

  const activeModalDelete = (e) => {
    setInfo(e);
    setModalDelete(true);
  };

  const searchUser = (e) => {
    const { value } = e.target;

    let usersTemp = users.current;

    if (value !== "") {
      usersTemp = usersTemp.filter(
        (element) =>
          element.userName.toLowerCase().includes(value.toLowerCase()) ||
          element.email.toLowerCase().includes(value.toLowerCase()) ||
          element.presence_id.toLowerCase().includes(value.toLowerCase()) ||
          element.priv_level.toLowerCase().includes(value.toLowerCase())
      );

      const newDataTable = {
        ...dataTable.current,
        content: [...usersTemp],
      };

      dataTable.current = newDataTable;
      forceUpdate();
    } else {
      const defaultDataTable = {
        ...dataTable.current,
        content: [...users.current],
      };

      dataTable.current = defaultDataTable;
      forceUpdate();
    }
  };

  const reloadData = () => {
    closeModal();
    petition();
  };

  const deleteUser = () => {
    setLoadingUser(true);
    petitionDelete("user", { user_id: info.pk })
      .then(({ data: result }) => {
        setLoadingUser(false);
        reloadData();
      })
      .catch((error) => console.log(error));
  };

  const petition = () => {
    setLoading(true);
    petitionGet("itpvoiceUsers")
      .then(({ data: result }) => {
        result.result.forEach((element) => {
          element.userName = `${element.firstname} ${element?.lastname || ""}`;
        });

        dataTable.current = {
          title: [
            { title: "Name", key: "userName" },
            { title: "Email", key: "email" },
            { title: "Extension Number", key: "presence_id" },
            { title: "Type", key: "priv_level" },
          ],
          content: [...result.result],
          actions: {
            title: "Actions",
            content: [
              {
                icon: <FontAwesomeIcon icon={faPenToSquare} />,
                name: "Edit",
                handleClick: activeEditUser,
              },
              {
                icon: <FontAwesomeIcon icon={faTrash} />,
                name: "Delete",
                handleClick: activeModalDelete,
              },
            ],
          },
          search: {
            placeholder: "Search User, Email, Extension Number or Type",
            handleChange: searchUser,
          },
        };

        users.current = result.result;
        setLoading(false);
        forceUpdate();
      })
      .catch((error) => console.log(error));
  };
  
  useEffect(() => {
    if (
      Object.keys(myExtension).length > 0 &&
      myExtension.priv_level !== "admin"
    ) {
      handleBackClick()
    }
  }, [myExtension]);

  useEffect(() => {
    petition();
  }, []);

  return (
    <>
      <div onClick={handleBackClick} className="itp-tex-back-container">
        <div className="itp-text-container-funnel-details">
          <img src={arrowleft} alt="arrow-left" />
          <span className="itp-text-back-funnel-details">
            {!showEditUSer ? "Back to Dashboard" : "Back to Users"}
          </span>
        </div>
      </div>

      {loading ? (
        <Loading />
      ) : (
        <>
          {modalDelete && (
            <ModalDelete
              modal={modalDelete}
              closeModal={closeModal}
              loading={loadingUser}
              modalFunction={deleteUser}
              module="User"
              text="Are you sure you wish to delete this User? All user information including Voicemails will be removed."
            />
          )}

          {showEditUSer ? (
            <>
              <EditUSer details={showEditUSer} />
            </>
          ) : (
            <>
              <CustomTable data={dataTable.current} noPagBack={true} />
            </>
          )}
        </>
      )}
    </>
  );
};

export default Users;
