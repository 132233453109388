import { DateTime } from 'luxon';

const formatHourof24at12 = (date, spliter) => {
  if (date) {
    let h = parseInt(date.split(spliter || " ")[1].split(":")[0]);
    let minutes = date.split(spliter || " ")[1].split(":")[1];
    let formatHour = ((h + 11) % 12) + 1;

    return `${formatHour}:${minutes} ${h >= 12 ? "PM" : "AM"}`;
  } else {
    return "";
  }
};

const selectedMonths = (number) => {
  switch (true) {
    case number === "01" || number === "1":
      return "January";
    case number === "02" || number === "2":
      return "February";
    case number === "03" || number === "3":
      return "March";
    case number === "04" || number === "4":
      return "April";
    case number === "05" || number === "5":
      return "May";
    case number === "06" || number === "6":
      return "June";
    case number === "07" || number === "7":
      return "July";
    case number === "08" || number === "8":
      return "August";
    case number === "09" || number === "9":
      return "September";
    case number === "010" || number === "10":
      return "October";
    case number === "011" || number === "11":
      return "November";
    case number === "012" || number === "12":
      return "December";
    default:
      return "Error";
  }
};

const formatDate = (date, spliter) => {
  let numberMonth = date.split(spliter || " ")[0].split("-")[1];

  let day = date.split(spliter || " ")[0].split("-")[2];
  let month = selectedMonths(numberMonth).slice(0, 3);
  let year = date.split(spliter || " ")[0].split("-")[0];
  let hour = formatHourof24at12(date, spliter);

  return `${month} ${day}, ${year} at ${hour}`;
};

const formatDateTwo = (date, spliter) => {
  if (date) {
    let day = date.split(spliter || " ")[0].split("-")[2];
    let numberMonth = date.split(spliter || " ")[0].split("-")[1];
    let year = date.split(spliter || " ")[0].split("-")[0];
    let hour = formatHourof24at12(date, spliter);

    return `${day}/${numberMonth}/${year} ${hour}`;
  } else {
    return "";
  }
};

const convertLocalTimeToTimezoneTime = (timeZone) => {
  const currentDateTimeLocal = new Date();

  const currentDateTime = new Date(
    currentDateTimeLocal.toLocaleString("en-US", { timeZone: timeZone })
  );

  return currentDateTime;
};

const convertLocalTimeToTimezoneTimeOnFormated = (timeZone) => {
  const currentDateTimeLocal = new Date();

  const currentDateTime = new Date(
    currentDateTimeLocal.toLocaleString("en-US", { timeZone: timeZone })
  );

  const year = currentDateTime.getFullYear()
  const month = currentDateTime.getMonth() + 1
  const day = currentDateTime.getDate()
  const hour = currentDateTime.getHours()
  const minutes = currentDateTime.getMinutes()

  const timeStap = `${year}-${month > 9 ? month : `0${month}`}-${day} ${hour}:${minutes}:00` 

  return timeStap
};

const covertUtcTimeToTimeZoneTime = (date, timeZone) => {
  const utcDateTime = DateTime.fromISO(date, { zone: 'utc' });

  const targetDateTime = utcDateTime.setZone(timeZone);

  return targetDateTime.toISO()
}

const convertTimeStapToTimeZoneOnFormated = (value, timeZone) => {
  let time = new Date(parseInt(value) * 1000);
  let timeZoneTemp = time.toLocaleString("en-US", { timeZone: timeZone });

  const year = timeZoneTemp.split(",")[0].split("/")[2]
  const month = timeZoneTemp.split(",")[0].split("/")[0]
  const day = timeZoneTemp.split(",")[0].split("/")[1]
  const hour = timeZoneTemp.split(",")[1].split(" ")[1]

  const formatedDate = `${year}-${month}-${day} ${hour}`
  
  return formatedDate
}

export {
  formatHourof24at12,
  formatDate,
  selectedMonths,
  formatDateTwo,
  convertLocalTimeToTimezoneTime,
  covertUtcTimeToTimeZoneTime,
  convertLocalTimeToTimezoneTimeOnFormated,
  convertTimeStapToTimeZoneOnFormated,
};
