import React, { useEffect, useState } from "react";
import {
  CustomTable,
  Loading,
  MenuRight,
  LoadingButton,
} from "../../../../../components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faPenToSquare,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import { Row, Col, Card } from "reactstrap";
import SearchIcon from "@mui/icons-material/Search";
import useScreenSize from "../../../../../hooks/useScreenSize";
import ModalDelete from "../ModalUtitlity/ModalDelete";
import petitionGet from "../../../../../services/petitionGet";
import petitionPost from "../../../../../services/petitionPost";
import petitionPatch from "../../../../../services/petitionPatch";
import petitionDelete from "../../../../../services/petitionDelete";
import "./TimeGroupsDetails.css";

const TimeGroupsDetails = ({ timeGroupDetails }) => {
  const { width } = useScreenSize();
  const [loading, setLoading] = useState(true);
  const [dataTable, setDataTable] = useState(null);
  const [inputFocused, setInputFocused] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [firstRender, setFirstRender] = useState(true);
  const [timeRules, setTimeRules] = useState([]);
  const [modalDelete, setModalDelete] = useState(false);
  const [info, setInfo] = useState(null);
  const [loadingDateTime, setLoadingDateTime] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [formNameTimeGroup, setFormNameTimeGroup] = useState({
    name: timeGroupDetails?.name || "",
  });
  const [formAddEditDateTime, setformAddEditDateTime] = useState({
    name: "",
    start_date: "",
    end_date: "",
    start_time: "",
    end_time: "",
    start_day_of_week: 0,
    end_day_of_week: 4,
  });

  const wDaysOptions = [
    { label: "Monday", value: 0 },
    { label: "Tuesday", value: 1 },
    { label: "Wednesday", value: 2 },
    { label: "Thursday", value: 3 },
    { label: "Friday", value: 4 },
    { label: "Saturday", value: 5 },
    { label: "Sunday", value: 6 },
  ];

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const reloadFormAddEditDateTime = () => {
    setformAddEditDateTime({
        name: "",
        start_date: "",
        end_date: "",
        start_time: "",
        end_time: "",
        start_day_of_week: 0,
        end_day_of_week: 4,
    })
  }

  const addZeroString = (value) => {
    if (value < 10) return `0${value}`;

    return value;
  };

  const handleInputFocus = () => {
    setInputFocused(true);
  };

  const handleInputBlur = () => {
    setInputFocused(false);
  };

  const handleChaneSearch = (e) => {
    const { value } = e.target;

    setSearchValue(value);

    let timeRulesTemp = timeRules;

    if (value !== "") {
      timeRulesTemp = timeRulesTemp.filter(
        (element) =>
          element.name.toLowerCase().includes(value.toLocaleLowerCase()) ||
          element.pk
            .toString()
            .toLowerCase()
            .includes(value.toLocaleLowerCase()) ||
          element.startDate.toLowerCase().includes(value.toLocaleLowerCase()) ||
          element.endDate.toLowerCase().includes(value.toLocaleLowerCase())
      );

      setDataTable({
        ...dataTable,
        content: [...timeRulesTemp],
      });
    } else {
      setDataTable({
        ...dataTable,
        content: [...timeRules],
      });
    }
  };

  const handleChangeForm = (e) => {
    setformAddEditDateTime({
      ...formAddEditDateTime,
      [e.target.name]: e.target.value,
    });
  };

  const formatedDate = (day, month, year) => {
    const monthName = months[month - 1].slice(0, 3);

    return `${monthName} ${day}, ${year}`;
  };

  const closeModal = () => {
    reloadFormAddEditDateTime()
    setOpenMenu(false);
    setInfo(null);
    setModalDelete(false);
  };

  const activeModalDelete = (e) => {
    setInfo(e);
    setModalDelete(true);
  };

  const activeAddDateTime = () => {
    setOpenMenu(true);
  };

  const activeEditDateTime = (e) => {
    setInfo(e)

    setformAddEditDateTime({
        name: e?.name,
        start_date: `${e?.start_month_year}-${addZeroString(
          e?.start_month
        )}-${addZeroString(e?.start_month_day)}`,
        end_date: `${e?.end_month_year}-${addZeroString(
          e?.end_month
        )}-${addZeroString(e?.end_month_day)}`,
        start_time: `${addZeroString(e?.start_hour)}:${addZeroString(
          e?.start_minute
        )}`,
        end_time: `${addZeroString(e?.end_hour)}:${addZeroString(
          e?.end_minute
        )}`,
        start_day_of_week: e?.start_day_of_week,
        end_day_of_week: e?.end_day_of_week,
    })

    setOpenMenu(true)
  }

  const reloadData = (noLoading) => {
    closeModal();
    petition(noLoading);
  };

  const deleteDateTime = () => {
    setLoadingDateTime(true);
    petitionDelete("timeRule", { time_id: info.pk })
      .then(({ data: result }) => {
        setLoadingDateTime(false);
        reloadData(true);
      })
      .catch((error) => console.log(error));
  };

  const addEditDateTime = () => {
    setLoadingDateTime(true)

    let newData = {
      name: formAddEditDateTime?.name,
      start_hour: parseInt(formAddEditDateTime?.start_time.split(":")[0]),
      start_minute: parseInt(formAddEditDateTime?.start_time.split(":")[1]),
      start_month: parseInt(formAddEditDateTime?.start_date.split("-")[1]),
      start_month_day: parseInt(formAddEditDateTime?.start_date.split("-")[2]),
      start_month_year: parseInt(formAddEditDateTime?.start_date.split("-")[0]),
      end_hour: parseInt(formAddEditDateTime?.end_time.split(":")[0]),
      end_minute: parseInt(formAddEditDateTime?.end_time.split(":")[1]),
      end_month: parseInt(formAddEditDateTime?.end_date.split("-")[1]),
      end_month_day: parseInt(formAddEditDateTime?.end_date.split("-")[2]),
      end_month_year: parseInt(formAddEditDateTime?.end_date.split("-")[0]),
      start_day_of_week: parseInt(formAddEditDateTime?.start_day_of_week),
      end_day_of_week: parseInt(formAddEditDateTime?.end_day_of_week),
      timegroup_id: timeGroupDetails?.pk.toString(),
    };

    if (!newData.end_month) delete newData.end_month;
    if (!newData.end_month_day) delete newData.end_month_day;
    if (!newData.end_month_year) delete newData.end_month_year;
    if (!newData.start_month) delete newData.start_month;
    if (!newData.start_month_day) delete newData.start_month_day;
    if (!newData.start_month_year) delete newData.start_month_year;

    if (!info) {
        petitionPost("timeRules", { data: newData })
        .then(({ data: result }) => {
          setLoadingDateTime(false);
          reloadData(true)
        })
        .catch((error) => console.log(error));
    } else {
        petitionPatch("timeRules", { data: newData, time_id: info.pk  })
        .then(({ data: result }) => {
          setLoadingDateTime(false);
          reloadData(true)
        })
        .catch((error) => console.log(error));
    }
  };

  const petition = (noLoading) => {
    if (!noLoading) setLoading(true);

    petitionGet("timeRules", {
      parameter: `?timegroup_id=${timeGroupDetails.pk}`,
    })
      .then(({ data: result }) => {
        result.result.forEach((element) => {
          element.startDate = formatedDate(
            element.start_month_day,
            element.start_month,
            element.start_month_year
          );

          element.endDate = formatedDate(
            element.end_month_day,
            element.end_month,
            element.end_month_year
          );
        });

        setDataTable({
          title: [
            { title: "Name", key: "name" },
            { title: "ID", key: "pk" },
            { title: "Start Date", key: "startDate" },
            { title: "End Date", key: "endDate" },
          ],
          content: [...result.result],
          actions: {
            title: "Actions",
            content: [
              {
                name: "Edit time Dates",
                icon: <FontAwesomeIcon icon={faPenToSquare} />,
                handleClick: activeEditDateTime,
              },
              {
                name: "Delete",
                icon: <FontAwesomeIcon icon={faTrash} />,
                handleClick: activeModalDelete,
              },
            ],
          },
          titleTable: "Time Dates for group",
          label: "Create & manage dates for this specific time group",
          addButton: {
            label: "Add Time Date",
            handleClick: activeAddDateTime,
          },
        });

        setTimeRules(result.result);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if (!firstRender) {
      const timer = setTimeout(() => {
        petitionPatch("timeGroup", {
          data: formNameTimeGroup,
          time_id: timeGroupDetails.pk,
        })
          .then(({ data: result }) => {})
          .catch((error) => console.log(error));
      }, 1000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [formNameTimeGroup]);

  useEffect(() => {
    if (timeGroupDetails) {
      petition();
      setFirstRender(false);
    } else {
      setFirstRender(true);
    }
  }, [timeGroupDetails]);

  return loading ? (
    <Loading />
  ) : (
    <>
      {modalDelete && (
        <ModalDelete
          modal={modalDelete}
          closeModal={closeModal}
          loading={loadingDateTime}
          modalFunction={deleteDateTime}
          module="Time Date"
        />
      )}

      <h1
        className={`itp-custom-table-title${
          width > 991 ? " centered" : ""
        } itp-time-groups-details-title`}
      >
        Time Group Details : Manage settings for this Time group
      </h1>

      <div className="itp-custom-table-container-actions label">
        <div className="itp-custom-table-actions">
          <label
            className={`itp-custom-table-label${
              width > 991 ? " centered" : ""
            } itp-time-groups-details-subtitle`}
          >
            {`Time Group : ${timeGroupDetails?.name}`}
          </label>
        </div>
      </div>

      <div className="itp-custom-table-container-actions label mt-3">
        <div className="itp-custom-table-actions">
          <div
            className={`itp-custom-table-container-search-input${
              width > 991 ? " centered" : ""
            }${inputFocused ? " focused-input" : ""}`}
          >
            <SearchIcon className="itp-custom-table-search-icon" />
            <input
              className="itp-custom-table-search-input"
              placeholder="Search time date"
              onChange={handleChaneSearch}
              onFocus={handleInputFocus}
              onBlur={handleInputBlur}
              value={searchValue}
            />
          </div>
        </div>
      </div>

      <br></br>
      <div>
        <Row>
          <Col>
            <div className="itp-user-settings-separator-line"></div>
          </Col>
        </Row>
      </div>
      <br></br>

      <Card
        className={
          width > 991
            ? `itp-table-container-centered overflow-hidden itp-edit-number-card`
            : `itp-table-container-all itp-edit-number-card`
        }
      >
        <div className="d-flex justify-content-between">
          <div className="d-flex flex-column">
            <label className="itp-edit-number-label-1">Name</label>
            <label className="itp-edit-number-label-2">
              Change the name of the specific time group
            </label>
          </div>
          <input
            placeholder="Add Name"
            className="form-control-itp itp-edit-number-input"
            name="name"
            value={formNameTimeGroup.name}
            onChange={(e) => {
              setFormNameTimeGroup({ name: e.target.value });
            }}
          />
        </div>
      </Card>

      <div className="mt-4 mb-4">
        <CustomTable
          data={dataTable}
          centered={width > 991 ? true : false}
          noPagBack={true}
        />
      </div>

      <MenuRight
        show={openMenu}
        closeMenu={closeModal}
        title="Add Time Date"
        to=""
      >
        {openMenu && (
          <>
            <Row className="itp-container-label-input">
              <Col
                xs={5}
                sm={5}
                md={5}
                lg={5}
                xl={5}
                xxl={5}
                className="itp-label-input-col"
              >
                <label className="itp-label-input__label">Time Date Name</label>
              </Col>
              <Col
                xs={7}
                sm={7}
                md={7}
                lg={7}
                xl={7}
                xxl={7}
                className="itp-label-input-col"
              >
                <input
                  type="text"
                  placeholder=": Add Name to the time group"
                  className="form-control-itp itp-label-input__input"
                  name="name"
                  value={formAddEditDateTime.name}
                  onChange={handleChangeForm}
                />
              </Col>
            </Row>

            <label className="itp-edit-number-label-1">Set Week :</label>
            <Col xs={9} sm={9} md={9} lg={9} xl={9} xxl={7}>
              <div className="container-date-range justify-content-around">
                <label className="date-range-label">From:</label>
                <select
                  value={formAddEditDateTime.start_day_of_week}
                  onChange={handleChangeForm}
                  name="start_day_of_week"
                  className="date-range-input bgc-transparent itp-add-date-time-select"
                  type="date"
                >
                  {wDaysOptions.map((element, i) => (
                    <option key={i} value={element.value}>
                      {element.label}
                    </option>
                  ))}
                </select>
                <div>
                  <FontAwesomeIcon
                    icon={faArrowRight}
                    className="date-range-separator"
                  />
                </div>
                <label className="date-range-label">To:</label>
                <select
                  value={formAddEditDateTime.end_day_of_week}
                  onChange={handleChangeForm}
                  name="end_day_of_week"
                  className="date-range-input bgc-transparent itp-add-date-time-select"
                  type="date"
                >
                  {wDaysOptions.map((element, i) => (
                    <option key={i} value={element.value}>
                      {element.label}
                    </option>
                  ))}
                </select>
              </div>
            </Col>

            <label className="itp-edit-number-label-1 mt-4">Set Date :</label>
            <Col xs={9} sm={9} md={9} lg={9} xl={9} xxl={7}>
              <div className="container-date-range">
                <label className="date-range-label">From:</label>
                <input
                  type="date"
                  className="date-range-input bgc-transparent"
                  name="start_date"
                  value={formAddEditDateTime.start_date}
                  onChange={handleChangeForm}
                />
                <div>
                  <FontAwesomeIcon
                    icon={faArrowRight}
                    className="date-range-separator"
                  />
                </div>
                <label className="date-range-label">To:</label>
                <input
                  type="date"
                  className="date-range-input bgc-transparent"
                  name="end_date"
                  value={formAddEditDateTime.end_date}
                  onChange={handleChangeForm}
                />
              </div>
            </Col>

            <label className="itp-edit-number-label-1 mt-4">Set Date :</label>
            <Col xs={9} sm={9} md={9} lg={9} xl={9} xxl={7}>
              <div className="container-date-range justify-content-around">
                <div style={{ gap: "12px" }} className="d-flex">
                  <label className="date-range-label">From:</label>
                  <input
                    type="time"
                    className="date-range-input bgc-transparent"
                    name="start_time"
                    value={formAddEditDateTime.start_time}
                    onChange={handleChangeForm}
                  />
                </div>
                <div>
                  <FontAwesomeIcon
                    icon={faArrowRight}
                    className="date-range-separator"
                  />
                </div>
                <div style={{ marginRight: "1rem" }} className="d-flex">
                  <label className="date-range-label">To:</label>
                  <input
                    type="time"
                    className="date-range-input bgc-transparent"
                    name="end_time"
                    value={formAddEditDateTime.end_time}
                    onChange={handleChangeForm}
                  />
                </div>
              </div>
            </Col>

            <button
              onClick={addEditDateTime}
              className="btn-primary itp-lead-detail-button-save loading"
              disabled={
                !formAddEditDateTime.name ||
                !formAddEditDateTime.start_time ||
                !formAddEditDateTime.end_time ||
                formAddEditDateTime.start_day_of_week === "" ||
                formAddEditDateTime.end_day_of_week === ""
              }
            >
              {loadingDateTime && <LoadingButton />}
              Save
            </button>
          </>
        )}
      </MenuRight>
    </>
  );
};

export default TimeGroupsDetails;
