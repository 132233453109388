import React, { Fragment } from "react";
import ReactApexChart from "react-apexcharts";

const Chart = ({ dataX, dataY }) => {
  const options = {
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 3,
    },

    colors: ["#556ee6", "#34c38f"],
    chart: {
      events: {
        beforeZoom: function (ctx) {
          // we need to clear the range as we only need it on the iniital load.
          ctx.w.config.xaxis.range = undefined;
        },
      },
    },
    xaxis: {
      type: "date",
      categories: dataY,
      range: 10,
    },
    grid: {
      borderColor: "#f1f1f1",
    },
    tooltip: {
      x: {
        format: "dd/MM/yy",
      },
    },
  };

  const series = [
    {
      name: "Calls",
      data: dataX,
    },
  ];

  return (
    <Fragment>
      <ReactApexChart
        options={options}
        series={series}
        type="area"
        height="350"
      />
    </Fragment>
  );
};

export default Chart;
