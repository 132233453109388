import ChatIcon from "@mui/icons-material/Chat";
import { capitalizeWords } from "../../../../../utils/capitalizeWords";

// devuelve el nombre del timeGroup en base al pk que se identifique
// returns the name of the timeGroup based on the pk that is identified
const returnTimeGroup = (id, timeGroups, fatherModule) => {
  if (id === "_") {
    return "All other times";
  }

  if (fatherModule !== "timecondition") return id;

  const dataFind = timeGroups.find((element) => parseInt(element.pk) === parseInt(id));

  if (dataFind) {
    return dataFind?.name;
  } else {
    return "This Time Condition Was Removed";
  }
};

const selectSubTitleModule = (
  module,
  id,
  queues,
  switches,
  voicemails,
  users,
  plays
) => {
  switch (module) {
    case "voicemail":
      const findVoicemail = voicemails.find((element) => element.pk === id);
      if (findVoicemail) {
        return findVoicemail?.label;
      } else {
        return "This Voicemail Was Deleted";
      }

    case "queue":
      const findQueue = queues?.find((element) => element.pk === id);
      if (findQueue) {
        return findQueue?.queue_name;
      } else {
        return "This Queue Was Deleted";
      }

    case "switch":
      const findSwitch = switches.find((element) => element.pk === id);
      if (findSwitch) {
        return findSwitch?.name;
      } else {
        return "This Switch Was Deleted";
      }

    case "user":
      const findUser = users.find((element) => element.pk === id);
      if (findUser) {
        return findUser?.label;
      } else {
        return "This User Was Deleted";
      }

    case "callflow":
      const findUserr = users.find((element) => element.pk === id);
      if (findUserr) {
        return findUserr?.label;
      } else {
        return "This User Was Deleted";
      }

    case "play":
      const findPlay = plays.find((element) => element.pk === id);
      if (findPlay) {
        return findPlay?.name;
      } else {
        return "This Media Was Deleted";
      }

    case "externaltransfer":
      return id;

    default:
      return "";
  }
};
//verifica si todos los campos del objeto children son objetos
// checks if all fields of the children object are objects
const areAllChildrenObjects = (children) => {
  if (!children || typeof children !== "object") {
    return false; // Si no hay children o no es un objeto, retorna false.
  }
  for (const key in children) {
    if (children.hasOwnProperty(key)) {
      if (typeof children[key] !== "object") {
        return false; // Si alguna propiedad no es un objeto, retorna false.
      }
    }
  }
  return true; // Si todas las propiedades son objetos, retorna true.
};

// transforma los datos para que los hijos queden en un array en vez de un objeto
// transforms the data so that the children are an array instead of an object
export const transformDataCallFlows = (
  info,
  key,
  timeGroups,
  activePanelCallFlows,
  queues,
  switches,
  voicemails,
  users,
  plays,
  fatherModule
) => {
  const arrBranchModule = ["timecondition", "menu", "switch"];

  const transformedItem = {
    ...info,
    title:
      info.isFirst === true
        ? info?.module
        : info?.module === "externaltransfer"
        ? "External Transfer"
        : `${capitalizeWords(info?.module)}`,

    subtitle: selectSubTitleModule(
      info.module,
      info.module === "user" || info.module === "callflow"
        ? parseInt(info.data.idUser)
        : info.module === "externaltransfer"
        ? info.data.destination || info.data.number
        : parseInt(info.data.id),
      queues,
      switches,
      voicemails,
      users,
      plays
    ),
    cardClick: () => console.log("click card"),
    addClick: (element, end, edit) => activePanelCallFlows(element, end, edit),
    icon: <ChatIcon style={{ transform: "scale(1.5)" }}></ChatIcon>,
    child: [],
    branchName: key
      ? returnTimeGroup(
          key,
          timeGroups,
          fatherModule
        )
      : null,
    isDeleted:
      key &&
      returnTimeGroup(key, timeGroups, fatherModule) ===
        "This Time Condition Was Removed"
        ? true
        : false,
    isKeyObject: key ? key : null,
    noMoreAction: info.module === "voicemail" ? true : null,
  };

  const childKeys = Object.keys(info?.children || {});
  const hasMultipleChildren = areAllChildrenObjects(info?.children || {});
  const mustHaveBranchName = arrBranchModule.includes(info.module);

  if (childKeys.length > 0 && hasMultipleChildren && mustHaveBranchName) {
    transformedItem.child = childKeys.map((key) =>
      transformDataCallFlows(
        info?.children[key],
        key,
        timeGroups,
        activePanelCallFlows,
        queues,
        switches,
        voicemails,
        users,
        plays,
        info.module
      )
    );
  } else if (
    childKeys.length === 1 &&
    hasMultipleChildren &&
    !mustHaveBranchName
  ) {
    const uniqueKey = childKeys[0];
    const returnData = transformDataCallFlows(
      info?.children[uniqueKey],
      false,
      timeGroups,
      activePanelCallFlows,
      queues,
      switches,
      voicemails,
      users,
      plays,
      false
    );
    transformedItem.child = [returnData];
  } else if (childKeys.length > 0 && !hasMultipleChildren) {
    const returnData = transformDataCallFlows(
      info?.children,
      false,
      timeGroups,
      activePanelCallFlows,
      queues,
      switches,
      voicemails,
      users,
      plays,
      false
    );
    transformedItem.child = [returnData];
  }
  return transformedItem;
};

//actualiza el campo route de cada objeto en el arry child para saber la ubiacion en la que está
//updates the route field of each object in the arry child to know the location it is in
export const updateChildRoute = (data, parentRoute = "", key) => {
  if (!data) {
    return;
  }

  if (parentRoute === "" && !key) {
    data.route = "";
  } else if ((parentRoute || parentRoute === "") && key) {
    data.route = `${parentRoute === "" ? "" : `${parentRoute}.`}child.${key}`;
  }

  if (data.child && data.child.length > 0) {
    data.child.forEach((obj, index) => {
      updateChildRoute(obj, data.route, `${index}`);
    });
  }

  if (data.module === "voicemail") {
    data.noMoreAction = true;
  } else {
    data.noMoreAction = null;
  }

  return data;
};

//actualiza el campo route de cada objeto para saber la ubiacion en la que está
//updates the route field of each object to know the location it is in
export const updateRoute = (data, parentRoute = "", key) => {
  // console.log(data)
  // console.log(parentRoute)
  if (!data) {
    return;
  }

  // Agrega la ruta de este objeto al parentRoute
  if (!parentRoute && !key) {
    data.route = "flow";
  } else if (parentRoute && !key) {
    data.route = `${parentRoute}.children`;
  } else if (parentRoute && key) {
    data.route = `${parentRoute}.children.${key}`;
  }

  // Llama recursivamente a esta función para los hijos
  if (data.children && Object.keys(data.children).length > 0) {
    const hasMultipleChildren = areAllChildrenObjects(data.children);
    if (typeof data.children === "object" && hasMultipleChildren) {
      Object.keys(data.children).forEach((key) => {
        updateRoute(data.children[key], data.route, key);
      });
    } else if (typeof data.children === "object" && !hasMultipleChildren) {
      updateRoute(data.children, data.route);
    }
  }

  return data;
};

//Transformar los hijos que están en array de nuevo a objetos
//Transform the children that are in array back to objects
export const convertToNestedObject = (data, route = "flow.children") => {
  const result = {};

  result.route = route;
  result.module = data.module;
  result.data = data.data;
  result.children = {};

  if (Array.isArray(data.child) && data.child.length > 0) {
    if (data.child.length === 1 && !data.child[0].fatherModule) {
      // Si solo hay un objeto en child, y el padre de ese child no es un timecondition, menu o switch no agregamos un objeto adicional
      result.children = convertToNestedObject(data.child[0], route);
    } else {
      result.children = {};
      data.child.forEach((item, index) => {
        const key = item.isKeyObject ? item.isKeyObject : index + 1;
        const childRoute = `${route}.child.${index}`;
        result.children[key] = convertToNestedObject(item, childRoute);
      });
    }
  }

  return result;
};
