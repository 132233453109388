import * as type from "../types";

const initialState = {
  userDetails: {},
};

export default function myExtension(state = initialState, action) {
  switch (action.type) {
    case type.GET_MY_EXTENTION:
      return state;

    case type.GET_MY_EXTENTION_SUCCESS:
      return {
        userDetails: action.payload,
      };

    case type.UPDATE_MY_EXTENTION_SUCCESS:
      return {
        userDetails: action.payload,
      };

    case type.UPDATE_MY_EXTENTION:
      return state;

    case type.GET_MY_EXTENTION_FAILED:
      return state;

    case type.UPDATE_MY_EXTENTION_FAILED:
      return state;
    default:
      return state;
  }
}
