import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { updateMyExtention } from "../../../../../redux/actions/myExtension";
import { Loading, CustomTable } from "../../../../../components";
import Switch from "react-switch";
import Select from "react-select";
import SipDetailsModal from "../../../UserSettings/SipDetailsModal";
import useScreenSize from "../../../../../hooks/useScreenSize";
import petitionGet from "../../../../../services/petitionGet";
import petitionPost from "../../../../../services/petitionPost";
import petitionPatch from "../../../../../services/petitionPatch";
import petitionDelete from "../../../../../services/petitionDelete";
import infoIcon from "../../../../../assets/icons/information-icon.svg";
import "./EditUser.css";

const EditUSer = ({ details }) => {
  const dispatch = useDispatch();

  const { width } = useScreenSize();

  const userDetails = useSelector((state) => state.myExtension.userDetails);

  const [firstRender, setFirstRender] = useState(true);
  const [dataTableUserDevices, setDataTableUserDevices] = useState(null);
  const [loading, setLoading] = useState(true);
  const [modalSip, setModalSip] = useState(false);
  const [info, setInfo] = useState(null);
  const [accountDetails, setAccountDetails] = useState({});
  const [numberCallForwarding, setNumberCallForwarding] = useState("");
  const [valuenumberCallerID, setValueNumberCallerID] = useState("");
  const [numbersCallerId, setNumbersCallerId] = useState([]);
  const [intercom, setIntercom] = useState(null);

  const [formUserDetails, setFormUserDetails] = useState({
    presence_id: details?.presence_id || "",
    priv_level: details?.priv_level || "",
  });

  const [formCallerId, setFormCallerId] = useState({
    default_outbound_callerid_number: "",
    default_outbound_callerid_name: "",
  });

  const [switchs, setSwitchs] = useState({
    callforward_enable: false,
    callforward_keep_caller_caller_id: false,
    callforward_queue_calls: false,
    callforward_call_confirmation: false,
    switchOverrideDefaultCallerID: false,
    call_recording_internal: false,
    call_recording_external: false,
    switchIntercomCalls: false,
    faxing_settings_enable_notifications: false,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormUserDetails({ ...formUserDetails, [name]: value });

    if (name === "priv_level") {
      const data = {
        ...formUserDetails,
        priv_level: value,
      };

      petitionPatch("user", {
        data,
        user_id: details.pk,
      })
        .then(({ data: result }) => {
          if (details.pk === userDetails.pk) {
            const newUserDetails = result.result;

            dispatch(updateMyExtention(newUserDetails));
          }
        })
        .catch((error) => console.log(error));
    }
  };

  const handleChangeSwitchCallForwarding = (switchName) => {
    setSwitchs({ ...switchs, [switchName]: !switchs[switchName] });

    let data = {};

    if (switchName === "callforward_enable" && switchs.callforward_enable) {
      data = {
        callforward_enable: false,
        callforward_keep_caller_caller_id: false,
        callforward_queue_calls: false,
        callforward_call_confirmation: false,
        callforward_number: "",
      };

      setSwitchs({
        ...switchs,
        callforward_enable: false,
        callforward_keep_caller_caller_id: false,
        callforward_queue_calls: false,
        callforward_call_confirmation: false,
      });
    } else {
      data = { [switchName]: !switchs[switchName] };
    }

    petitionPatch("user", { data, user_id: details.pk })
      .then(({ data: result }) => {
        if (details.pk === userDetails.pk) {
          const newUserDetails = result.result;

          dispatch(updateMyExtention(newUserDetails));
        }
      })
      .catch((error) => console.log(error));
  };

  const handleChangeSwitchCallerID = () => {
    setSwitchs({
      ...switchs,
      switchOverrideDefaultCallerID: !switchs.switchOverrideDefaultCallerID,
    });

    if (switchs.switchOverrideDefaultCallerID) {
      let data = {
        default_outbound_callerid_number: "",
        default_outbound_callerid_name: "",
      };

      petitionPatch("user", { data, user_id: details.pk })
        .then(({ data: result }) => {
          if (details.pk === userDetails.pk) {
            const newUserDetails = result.result;

            dispatch(updateMyExtention(newUserDetails));
          }
        })
        .catch((error) => console.log(error));
    }
  };

  const handleChangeNumberCallerID = (e) => {
    setValueNumberCallerID(e);
    setFormCallerId({
      ...formCallerId,
      default_outbound_callerid_number: e.value,
    });

    let data = {
      default_outbound_callerid_number: e.value,
    };

    petitionPatch("userDetails", { data })
      .then(({ data: result }) => {})
      .catch((error) => console.log(error));
  };

  const handleChangeSwitchCallRecording = (switchName) => {
    setSwitchs({ ...switchs, [switchName]: !switchs[switchName] });

    let data = {
      [switchName]: !switchs[switchName] ? "enabled" : "disabled",
    };

    petitionPatch("user", { data, user_id: details.pk })
      .then(({ data: result }) => {
        if (details.pk === userDetails.pk) {
          const newUserDetails = result.result;

          dispatch(updateMyExtention(newUserDetails));
        }
      })
      .catch((error) => console.log(error));
  };

  const handleChangeOtherSwitch = (switchName) => {
    setSwitchs({ ...switchs, [switchName]: !switchs[switchName] });

    if (switchName === "switchIntercomCalls" && !switchs.switchIntercomCalls) {
      let data = {
        numbers: [`*80${details.presence_id}`],
        type: "UserIntercom",
        callflow_object: {
          flow: {
            data: {
              id: `${details.pk}`,
            },
            module: "intercom",
            children: {},
          },
        },
        enabled: true,
        owner_id: `${details.pk}`,
      };

      petitionPost("callflow", { data })
        .then(({ data: result }) => {})
        .catch((error) => console.log(error));
    } else if (
      switchName === "switchIntercomCalls" &&
      switchs.switchIntercomCalls
    ) {
      petitionDelete("callFlow", { callflow_id: intercom.pk })
        .then(({ data: result }) => {})
        .catch((error) => console.log(error));
    } else if (switchName !== "switchIntercomCalls") {
      let data = {
        faxing_settings_enable_notifications:
          !switchs.faxing_settings_enable_notifications,
      };

      petitionPatch("user", { data, user_id: details.pk })
        .then(({ data: result }) => {
          if (details.pk === userDetails.pk) {
            const newUserDetails = result.result;

            dispatch(updateMyExtention(newUserDetails));
          }
        })
        .catch((error) => console.log(error));
    }
  };

  const closeModal = () => {
    setInfo(null);
    setModalSip(false);
  };

  const activeSipModal = (e) => {
    setInfo(e);
    setModalSip(true);
  };

  const petition = async () => {
    setLoading(true);

    let myExtensionTemp = {};

    await petitionGet("userDetailsAdmin", { user_id: details.pk })
      .then(({ data: result }) => {
        setNumberCallForwarding(result.result.callforward_number);

        myExtensionTemp = result.result;
      })
      .catch((error) => console.log(error));

    await petitionGet("intercomAdmin", { user_id: details.pk })
      .then(({ data: result }) => {
        let dataFind = result.result.find(
          (element) => element.type === "UserIntercom"
        );

        setIntercom(dataFind);

        setSwitchs({
          ...switchs,

          faxing_settings_enable_notifications:
            myExtensionTemp.faxing_settings_enable_notifications,

          switchIntercomCalls: dataFind ? true : false,

          call_recording_internal:
            myExtensionTemp?.call_recording_internal === "enabled",

          call_recording_external:
            myExtensionTemp?.call_recording_external === "enabled",

          callforward_enable: myExtensionTemp.callforward_enable || false,

          callforward_keep_caller_caller_id:
            myExtensionTemp.callforward_keep_caller_caller_id || false,

          callforward_queue_calls:
            myExtensionTemp.callforward_queue_calls || false,

          callforward_call_confirmation:
            myExtensionTemp.callforward_call_confirmation || false,

          switchOverrideDefaultCallerID:
            (myExtensionTemp.default_outbound_callerid_number === null ||
              myExtensionTemp.default_outbound_callerid_number === "") &&
            (myExtensionTemp.default_outbound_callerid_name === null ||
              myExtensionTemp.default_outbound_callerid_name === "")
              ? false
              : true,
        });
      })
      .catch((error) => console.log(error));

    await petitionGet("viewNumbers")
      .then(({ data: result }) => {
        result.result.forEach((element) => {
          element.label = element.number;
          element.value = element.number;
        });

        let defaultNumber = result.result.find(
          (element) =>
            element.number === myExtensionTemp.default_outbound_callerid_number
        );

        setFormCallerId({
          default_outbound_callerid_name:
            myExtensionTemp.default_outbound_callerid_name || "",
          default_outbound_callerid_number:
            myExtensionTemp.default_outbound_callerid_number || "",
        });

        setValueNumberCallerID(defaultNumber);
        setNumbersCallerId(result.result);
      })
      .catch((error) => console.log(error));

    await petitionGet("accountDetails")
      .then(({ data: result }) => {
        result.result = {
          ...result.result,
          voice_account: {
            realm: result.result.realm,
          },
        };

        setAccountDetails(result.result);
      })
      .catch((error) => console.log(error));

    await petitionGet("devicesAdmin", { owner_id: details.pk })
      .then(({ data: result }) => {
        result.result.forEach((element) => {
          element.deviceName = element.device_type;
          element.details = "Details";
        });

        setDataTableUserDevices({
          title: [
            {
              title: "Name",
              key: "deviceName",
            },
            { title: "Device Type", key: "device_type" },
            {
              title: "Details",
              key: "details",
              isLink: true,
              onChange: activeSipModal,
            },
          ],
          content: [...result.result],
        });

        setLoading(false);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if (formCallerId.default_outbound_callerid_name) {
      const timer = setTimeout(() => {
        let data = {
          default_outbound_callerid_name:
            formCallerId.default_outbound_callerid_name,
        };

        petitionPatch("user", { data, user_id: details.pk })
          .then(({ data: result }) => {
            if (details.pk === userDetails.pk) {
              const newUserDetails = result.result;

              dispatch(updateMyExtention(newUserDetails));
            }
          })
          .catch((error) => console.log(error));
      }, 1000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [formCallerId.default_outbound_callerid_name]);

  useEffect(() => {
    if (numberCallForwarding) {
      const timer = setTimeout(() => {
        let data = {
          callforward_number: numberCallForwarding,
        };

        petitionPatch("user", { data, user_id: details.pk })
          .then(({ data: result }) => {
            if (details.pk === userDetails.pk) {
              const newUserDetails = result.result;

              dispatch(updateMyExtention(newUserDetails));
            }
          })
          .catch((error) => console.log(error));
      }, 1000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [numberCallForwarding]);

  useEffect(() => {
    if (!firstRender) {
      const timer = setTimeout(() => {
        petitionPatch("user", {
          data: formUserDetails,
          user_id: details.pk,
        })
          .then(({ data: result }) => {
            if (details.pk === userDetails.pk) {
              const newUserDetails = result.result;

              dispatch(updateMyExtention(newUserDetails));
            }
          })
          .catch((error) => console.log(error));
      }, 1000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [formUserDetails.presence_id]);

  useEffect(() => {
    petition();
    setFirstRender(false);
  }, []);

  return loading ? (
    <Loading />
  ) : (
    <>
      {modalSip && (
        <SipDetailsModal
          modal={modalSip}
          closeModal={closeModal}
          device={info}
          myExtension={accountDetails}
        />
      )}

      <h1
        style={{width:"935px"}}
        className={`itp-custom-table-title${
          width > 991 ? " centered" : ""
        } itp-time-groups-details-title`}
      >
        User Details : Manage settings for this User
      </h1>

      <div className="itp-custom-table-container-actions label" style={{width:"935px"}}>
        <div className="itp-custom-table-actions" style={{marginLeft:"30px"}}>
          <label
            // style={{maxWidth:"991px", margin:"auto"}}
            className={`itp-c ustom-table-label${
              width > 991 ? " centered" : ""
            } itp-time-groups-details-subtitle`}
          >
            {`User : ${details?.userName}`}
          </label>
        <br/>
        <br/>
        <label
          style={{Width:"991px", marginLeft:"auto"}}
          className={`itp-custom-table-title${
            width > 991 ? " centered" : ""
          } itp-time-groups-details-title`}
          >
          {`Email: ${details?.email}`}
          </label>
        </div>
      </div>
      
      <br></br>
      <div>
        <Row>
          <Col>
            <div className="itp-user-settings-separator-line"></div>
          </Col>
        </Row>
      </div>
      <br></br>

      <Card
        style={{width:"960px", marginLeft:"15px"}}
        className={
          width > 991
            ? `itp-table-container-centered overflow-hidden itp-edit-number-card mb-4`
            : `itp-table-container-all itp-edit-number-card mb-4`
        }
      >
        <div className="d-flex justify-content-between">
          <div className="d-flex flex-column">
            <label className="itp-edit-number-label-1">Name</label>
            <label className="itp-edit-number-label-2">
              Change the extension number
            </label>
          </div>
          <input
            placeholder="Add Extension Number"
            className="form-control-itp itp-edit-number-input"
            name="presence_id"
            type="number"
            value={formUserDetails.presence_id}
            onChange={handleChange}
          />
        </div>
        <div className="d-flex justify-content-between mt-4">
          <div className="d-flex flex-column">
            <label className="itp-edit-number-label-1">Type</label>
            <label className="itp-edit-number-label-2">
              Change user permissions
            </label>
          </div>
          <select
            className="form-select-itp itp-select-edit-user"
            name="priv_level"
            value={formUserDetails.priv_level}
            onChange={handleChange}
          >
            <option value="" disabled>
              Select Option
            </option>
            <option value="admin">Admin</option>
            <option value="user">User</option>
          </select>
        </div>
      </Card>

      <br></br>
      <div>
        <Row>
          <Col>
            <div className="itp-user-settings-separator-line"></div>
          </Col>
        </Row>
      </div>
      <br></br>

      <div
        className={
          width > 991
            ? `itp-table-container-centered mb-4 overflow-hidden`
            : `itp-table-container-all mb-4`
        }
      >
        <Row>
          <Col>
            <div className="text-align-with-table">
              <span className="table-alt-text-title">Devices</span>
              <br></br>

              <span className="table-alt-text-title-description">
                Here is all the information related to devices integrated with
                their respective SIP Credential details & You can register the
                devices manually if you have a desk phone.
              </span>
            </div>

            {dataTableUserDevices ? (
              <CustomTable data={dataTableUserDevices} />
            ) : (
              <div className="no-data-message">No data available.</div>
            )}
          </Col>
        </Row>
      </div>

      <br></br>
      <div>
        <Row>
          <Col>
            <div className="itp-user-settings-separator-line"></div>
          </Col>
        </Row>
      </div>
      <br></br>

      <div>
        <div
          className={
            width > 991
              ? `itp-table-container-centered mb-4 overflow-hidden`
              : `itp-table-container-all mb-4`
          }
        >
          <Row>
            <Col>
              <div className="text-align-with-table">
                <span className="table-alt-text-title">Call Forwarding</span>
                <br></br>

                <span className="table-alt-text-title-description">
                  Adjust call forwarding settings for direct calls or queue
                  calls
                </span>
              </div>

              <Card className="itp-user-settings-card-col">
                <CardBody>
                  <span className="itp-user-settings-card-title">
                    Enable Call Forwarding
                  </span>

                  <Row className="itp-user-settings-card-help-text">
                    <Col md={10} className="d-flex">
                      <div>
                        <img src={infoIcon} alt="result.result-icon" />
                      </div>
                      <div className="ms-3">
                        <p>
                          Enabling this option will forward any incoming calls to
                          your user and will not ring any of your devices,
                          including the web phone
                        </p>
                      </div>
                    </Col>

                    <Col md={2}>
                      <div className="itp-custom-table-container-switchs d-flex align-items-center pl-0 justify-content-end">
                        <Switch
                          className="itp-custom-table-switch"
                          onColor="#626ed4"
                          height={20} // Ajusta la altura según tus necesidades
                          width={40}
                          checked={switchs.callforward_enable}
                          onChange={() =>
                            handleChangeSwitchCallForwarding(
                              "callforward_enable"
                            )
                          }
                        />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>

        {switchs.callforward_enable && (
          <div className="itp-user-settings-card-container-centered">
            <Row>
              <Col>
                <Card className="itp-user-settings-card-col" style={{maxWidth:"960px", marginLeft:"75px"}}>
                  <CardBody>
                    <span className="itp-user-settings-card-title">
                      Forward the call to:
                    </span>

                    <Row className="itp-user-settings-card-help-text">
                      <Col md={10} className="d-flex">
                        <div>
                          <img src={infoIcon} alt="result.result-icon" />
                        </div>
                        <div className="ms-3">
                          <p>
                            You can enter an extension number or a phone numbr to
                            forward calls to
                          </p>
                        </div>
                      </Col>

                      <Col md={2}>
                        <div className="itp-custom-table-container-switchs d-flex align-items-center pl-0 justify-content-end">
                          <input
                            type="text"
                            placeholder=""
                            className="form-control-itp"
                            name="numberCallForwarding"
                            value={numberCallForwarding}
                            onChange={(e) =>
                              setNumberCallForwarding(e.target.value)
                            }
                          />
                        </div>
                      </Col>
                    </Row>

                    <span className="itp-user-settings-card-title">
                      Keep Original Caller ID
                    </span>

                    <Row className="itp-user-settings-card-help-text">
                      <Col md={10} className="d-flex">
                        <div>
                          <img src={infoIcon} alt="result.result-icon" />
                        </div>
                        <div className="ms-3">
                          <p>
                            If enabled, this will show the caller's phone number
                            on your screen. If disabled, the number that appears
                            will either be the default caller ID or your
                            customized outbound caller ID number.
                          </p>
                        </div>
                      </Col>

                      <Col md={2}>
                        <div className="itp-custom-table-container-switchs d-flex align-items-center pl-0 justify-content-end">
                          <Switch
                            className="itp-custom-table-switch"
                            onColor="#626ed4"
                            height={20} // Ajusta la altura según tus necesidades
                            width={40}
                            checked={switchs.callforward_keep_caller_caller_id}
                            onChange={() =>
                              handleChangeSwitchCallForwarding(
                                "callforward_keep_caller_caller_id"
                              )
                            }
                          />
                        </div>
                      </Col>
                    </Row>

                    <span className="itp-user-settings-card-title">
                      Forward queue calls
                    </span>

                    <Row className="itp-user-settings-card-help-text">
                      <Col md={10} className="d-flex">
                        <div>
                          <img src={infoIcon} alt="result.result-icon" />
                        </div>
                        <div className="ms-3">
                          <p>
                            If enabled, any queues you are a member of will have
                            calls forwarded. If disabled, queue calls will not be
                            forwarded and will only ring your device or web phone.
                          </p>
                        </div>
                      </Col>

                      <Col md={2}>
                        <div className="itp-custom-table-container-switchs d-flex align-items-center pl-0 justify-content-end">
                          <Switch
                            className="itp-custom-table-switch"
                            onColor="#626ed4"
                            height={20} // Ajusta la altura según tus necesidades
                            width={40}
                            checked={switchs.callforward_queue_calls}
                            onChange={() =>
                              handleChangeSwitchCallForwarding(
                                "callforward_queue_calls"
                              )
                            }
                          />
                        </div>
                      </Col>
                    </Row>

                    <span className="itp-user-settings-card-title">
                      Call screening
                    </span>

                    <Row className="itp-user-settings-card-help-text">
                      <Col md={10} className="d-flex">
                        <div>
                          <img src={infoIcon} alt="result.result-icon" />
                        </div>
                        <div className="ms-3">
                          <p>
                            If enabled, when answering a forwarded call, a message
                            will prompt you to accept the call or deny.
                          </p>
                        </div>
                      </Col>

                      <Col md={2}>
                        <div className="itp-custom-table-container-switchs d-flex align-items-center pl-0 justify-content-end">
                          <Switch
                            className="itp-custom-table-switch"
                            onColor="#626ed4"
                            height={20} // Ajusta la altura según tus necesidades
                            width={40}
                            checked={switchs.callforward_call_confirmation}
                            onChange={() =>
                              handleChangeSwitchCallForwarding(
                                "callforward_call_confirmation"
                              )
                            }
                          />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        )}
      </div>

      <br></br>
      <div>
        <Row>
          <Col>
            <div className="itp-user-settings-separator-line"></div>
          </Col>
        </Row>
      </div>
      <br></br>

      <div>
        <div
          className={
            width > 991
              ? `itp-table-container-centered overflow-hidden`
              : `itp-table-container-all`
          }
        >
          <Row>
            <Col>
              <div className="text-align-with-table">
                <span className="table-alt-text-title">Caller ID</span>
                <br></br>

                <span className="table-alt-text-title-description">
                  Adjust your outbound caller ID settings when placing calls
                </span>
              </div>

              <Card className="itp-user-settings-card-col">
                <CardBody>
                  <span className="itp-user-settings-card-title">
                    Override default Caller ID{" "}
                  </span>

                  <Row className="itp-user-settings-card-help-text">
                    <Col md={10} className="d-flex">
                      <div>
                        <img src={infoIcon} alt="myExtensionTemp-icon" />
                      </div>
                      <div className="ms-3">
                        <p>
                          Enabling this option will force your devices to use a
                          specific phone number when placing a call
                        </p>
                      </div>
                    </Col>

                    <Col md={2}>
                      <div className="itp-custom-table-container-switchs d-flex align-items-center pl-0 justify-content-end">
                        <Switch
                          className="itp-custom-table-switch"
                          onColor="#626ed4"
                          height={20} // Ajusta la altura según tus necesidades
                          width={40}
                          checked={switchs.switchOverrideDefaultCallerID}
                          onChange={() => handleChangeSwitchCallerID()}
                        />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>

        {switchs.switchOverrideDefaultCallerID && (
          <div
            className={
              width > 991
                ? `itp-table-container-centered mb-4 overflow-hidden`
                : `itp-table-container-all mb-4`
            }
          >
            <Row>
              <Col>
                <Card className="itp-user-settings-card-col">
                  <CardBody>
                    <span className="itp-user-settings-card-title">
                      Caller ID Number:
                    </span>

                    <Row className="itp-user-settings-card-help-text">
                      <Col md={7} className="d-flex">
                        <div>
                          <img src={infoIcon} alt="myExtensionTemp-icon" />
                        </div>
                        <div className="ms-3">
                          <p>Select the number to use for outbound calls</p>
                        </div>
                      </Col>

                      <Col md={5}>
                        <div className="itp-custom-table-container-switchs d-flex align-items-center pl-0 justify-content-end">
                          <Select
                            onChange={(e) => {
                              handleChangeNumberCallerID(e);
                            }}
                            className="basic-single"
                            classNamePrefix="select"
                            value={valuenumberCallerID}
                            isSearchable={true}
                            name="contact_list_id"
                            options={numbersCallerId}
                          />
                        </div>
                      </Col>
                    </Row>

                    <span className="itp-user-settings-card-title">
                      Caller ID Name:
                    </span>

                    <Row className="itp-user-settings-card-help-text">
                      <Col md={9} className="d-flex">
                        <div>
                          <img src={infoIcon} alt="myExtensionTemp-icon" />
                        </div>
                        <div className="ms-3">
                          <p>
                            Type the name that appears when placing outbound calls
                          </p>
                        </div>
                      </Col>

                      <Col md={3}>
                        <div className="itp-custom-table-container-switchs d-flex align-items-center pl-0 justify-content-end">
                          <input
                            type="text"
                            placeholder=""
                            className="form-control-itp"
                            name="contactname"
                            value={formCallerId.default_outbound_callerid_name}
                            onChange={(e) => {
                              setFormCallerId({
                                ...formCallerId,
                                default_outbound_callerid_name: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        )}
      </div>

      <br></br>
      <div>
        <Row>
          <Col>
            <div className="itp-user-settings-separator-line"></div>
          </Col>
        </Row>
      </div>
      <br></br>

      <div
        className={
          width > 991
            ? `itp-table-container-centered overflow-hidden`
            : `itp-table-container-all`
        }
      >
        <Row>
          <Col>
            <div className="text-align-with-table">
              <span className="table-alt-text-title">Call Recording</span>
              <br></br>

              <span className="table-alt-text-title-description">
                Adjust your call recording preferences
              </span>
            </div>

            <Card className="itp-user-settings-card-col">
              <CardBody>
                <span className="itp-user-settings-card-title">
                  Internal Calls{" "}
                </span>

                <Row className="itp-user-settings-card-help-text">
                  <Col md={10} className="d-flex">
                    <div>
                      <img src={infoIcon} alt="myExtensionTemp-icon" />
                    </div>
                    <div className="ms-3">
                      <p>Record all internal calls (User to User)</p>
                    </div>
                  </Col>

                  <Col md={2}>
                    <div className="itp-custom-table-container-switchs d-flex align-items-center pl-0 justify-content-end">
                      <Switch
                        className="itp-custom-table-switch"
                        onColor="#626ed4"
                        height={20} // Ajusta la altura según tus necesidades
                        width={40}
                        checked={switchs?.call_recording_internal}
                        onChange={() =>
                          handleChangeSwitchCallRecording(
                            "call_recording_internal"
                          )
                        }
                      />
                    </div>
                  </Col>
                </Row>

                <span className="itp-user-settings-card-title">
                  External Calls{" "}
                </span>

                <Row className="itp-user-settings-card-help-text">
                  <Col md={10} className="d-flex">
                    <div>
                      <img src={infoIcon} alt="myExtensionTemp-icon" />
                    </div>
                    <div className="ms-3">
                      <p>Record all external calls (Incoming and Outgoing)</p>
                    </div>
                  </Col>

                  <Col md={2}>
                    <div className="itp-custom-table-container-switchs d-flex align-items-center pl-0 justify-content-end">
                      <Switch
                        className="itp-custom-table-switch"
                        onColor="#626ed4"
                        height={20} // Ajusta la altura según tus necesidades
                        width={40}
                        checked={switchs?.call_recording_external}
                        onChange={() =>
                          handleChangeSwitchCallRecording(
                            "call_recording_external"
                          )
                        }
                      />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>

      <br></br>
      <div>
        <Row>
          <Col>
            <div className="itp-user-settings-separator-line"></div>
          </Col>
        </Row>
      </div>
      <br></br>

      <div
        className={
          width > 991
            ? `itp-table-container-centered overflow-hidden`
            : `itp-table-container-all`
        }
      >
        <Row>
          <Col>
            <div className="text-align-with-table">
              <span className="table-alt-text-title">Intercom / Paging</span>
              <br></br>

              <span className="table-alt-text-title-description">
                Intercom and Paging Settings
              </span>
            </div>

            <Card className="itp-user-settings-card-col">
              <CardBody>
                <span className="itp-user-settings-card-title">
                  Intercom Calls{" "}
                </span>

                <Row className="itp-user-settings-card-help-text">
                  <Col md={10} className="d-flex">
                    <div>
                      <img src={infoIcon} alt="myExtensionTemp-icon" />
                    </div>
                    <div className="ms-3">
                      <p>
                        If enabled, any intercom attemps will automatically be
                        answered by your device if supported. Does not work on Web
                        Phone
                      </p>
                    </div>
                  </Col>

                  <Col md={2}>
                    <div className="itp-custom-table-container-switchs d-flex align-items-center pl-0 justify-content-end">
                      <Switch
                        className="itp-custom-table-switch"
                        onColor="#626ed4"
                        height={20} // Ajusta la altura según tus necesidades
                        width={40}
                        checked={switchs.switchIntercomCalls}
                        onChange={() =>
                          handleChangeOtherSwitch("switchIntercomCalls")
                        }
                      />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>

      <br></br>
      <div>
        <Row>
          <Col>
            <div className="itp-user-settings-separator-line"></div>
          </Col>
        </Row>
      </div>
      <br></br>

      <div
        className={
          width > 991
            ? `itp-table-container-centered overflow-hidden`
            : `itp-table-container-all`
        }
      >
        <Row>
          <Col>
            <div className="text-align-with-table">
              <span className="table-alt-text-title">Faxing</span>
              <br></br>

              <span className="table-alt-text-title-description">
                Adjust fax settings
              </span>
            </div>

            <Card className="itp-user-settings-card-col">
              <CardBody>
                <span className="itp-user-settings-card-title">
                  Fax Notifications{" "}
                </span>

                <Row className="itp-user-settings-card-help-text">
                  <Col md={10} className="d-flex">
                    <div>
                      <img src={infoIcon} alt="myExtensionTemp-icon" />
                    </div>
                    <div className="ms-3">
                      <p>Send email when a fax has been received.</p>
                    </div>
                  </Col>

                  <Col md={2}>
                    <div className="itp-custom-table-container-switchs d-flex align-items-center pl-0 justify-content-end">
                      <Switch
                        className="itp-custom-table-switch"
                        onColor="#626ed4"
                        height={20} // Ajusta la altura según tus necesidades
                        width={40}
                        checked={switchs.faxing_settings_enable_notifications}
                        onChange={() =>
                          handleChangeOtherSwitch(
                            "faxing_settings_enable_notifications"
                          )
                        }
                      />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default EditUSer;
