// Importar React y los componentes necesarios
import React, { useState } from "react";
import { faEllipsis, faUserPlus, faMicrophone, faGrip, faHand, faPhoneFlip, faDeleteLeft, faMicrophoneSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DropdownMenu, ButtonDropdown, DropdownToggle, DropdownItem } from 'reactstrap';
import "./Keyboard.css"
// Definir el componente TransferScreen
const IncomingCall = ({ numberToCall, setNumberTocall, handleCall, sipconfiguration,setCloseSettings,enablePhone }) => {
  // Definir el estado del número de teléfono
  // const [numberToCall, setNumberTocall] = useState('');
  const [keyboardOpen, setkeyboardOpen] = useState(true);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [addNumber, setAddNumber] = useState(false);
  const [mute, setMute] = useState(false)


  // Definir la función que se ejecuta al presionar una tecla
  const handleKeyPress = (key) => {
    // Añadir el símbolo al número de teléfono
    setNumberTocall(numberToCall + key);
  };

  // Definir la función que se ejecuta al presionar el botón de transferir
  const handleGreenButton = () => {
    if(enablePhone)handleCall()
    
    // Llamar a la función que realiza la transferencia de llamada
    // con el número de teléfono introducido
    // transferCall(phoneNumber);
  };

  // Definir la función que se ejecuta al presionar el botón de cancelar
  const handleCancel = () => {
    // Llamar a la función que cancela la transferencia de llamada
    // cancelTransfer();
  };

  const handleNumberDelete = () => {
    if (numberToCall.length > 0) {
      // Elimina el último carácter utilizando slice
      const newNumber = numberToCall.slice(0, -1);
      setNumberTocall(newNumber);
    }
  };

  const toggle = () => {
    setDropdownOpen(!dropdownOpen)
  }

  const toggleMute = () => {
    setMute(!mute)
  }
  // Retornar el elemento JSX que representa la pantalla de transferencia
  return (
    <div className={`keyboard ${!keyboardOpen ? "keyboard-close" : "keyboard-open"}`}>
      <div className="keyboard-header">
        <div className="keyboard-top-data"><div>{sipconfiguration.name}
        </div>
          <ButtonDropdown isOpen={dropdownOpen} toggle={toggle}>
            <DropdownToggle className="onCall-dropdown">
              <FontAwesomeIcon icon={faEllipsis} />
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={()=>{setCloseSettings(false)}}>{"Config"}</DropdownItem>
            </DropdownMenu>
          </ButtonDropdown>
        </div>

      </div>
      <div className="keyboard-call-info"><div>
      </div>
      </div>
      {!keyboardOpen && <div className="keyboard-call-info"><div><FontAwesomeIcon icon={faUserPlus} /> <span className="keyboard-span" onClick={() => { setAddNumber(true); setkeyboardOpen(true) }}>Add Call</span>
      </div>
      </div>
      }
      {!keyboardOpen && <div className="keyboard-buttons">
        <div className="keyboard-buttons-item keyboard-buttons-item-mic" onClick={toggleMute}>{mute ? <FontAwesomeIcon icon={faMicrophoneSlash} /> : <FontAwesomeIcon icon={faMicrophone} />}</div>

        <div className="keyboard-buttons-item keyboard-buttons-item-grip" onClick={() => { setkeyboardOpen(!keyboardOpen) }}><FontAwesomeIcon icon={faGrip} /></div>

        <div className="keyboard-buttons-item keyboard-buttons-item-hand"><FontAwesomeIcon icon={faHand} /></div>

        <div className="keyboard-buttons-item keyboard-buttons-item-bye"><FontAwesomeIcon className="keyboard-buttons-item-bye" icon={faPhoneFlip} /></div>

      </div>}
      {keyboardOpen && <div className="keyboard-number">
        <span className="keyboard-number-span">{numberToCall}</span>
        <div className="keyboard-number-delete" >
          <FontAwesomeIcon icon={faDeleteLeft} onClick={handleNumberDelete} />
        </div>

      </div>}
      {(keyboardOpen) && <>   <div className="keyboard-keypad">
        <div className="keyboard-button-keyboard" onClick={() => {if(enablePhone)handleKeyPress("1")}} >1</div>
        <div className="keyboard-button-keyboard" onClick={() => {if(enablePhone)handleKeyPress("2")}} >2</div>
        <div className="keyboard-button-keyboard" onClick={() => {if(enablePhone)handleKeyPress("3")}} >3</div>
        <div className="keyboard-button-keyboard" onClick={() => {if(enablePhone)handleKeyPress("4")}} >4</div>
        <div className="keyboard-button-keyboard" onClick={() => {if(enablePhone)handleKeyPress("5")}} >5</div>
        <div className="keyboard-button-keyboard" onClick={() => {if(enablePhone)handleKeyPress("6")}} >6</div>
        <div className="keyboard-button-keyboard" onClick={() => {if(enablePhone)handleKeyPress("7")}} >7</div>
        <div className="keyboard-button-keyboard" onClick={() => {if(enablePhone)handleKeyPress("8")}} >8</div>
        <div className="keyboard-button-keyboard" onClick={() => {if(enablePhone)handleKeyPress("9")}} >9</div>
        <div className="keyboard-button-keyboard" onClick={() => {if(enablePhone)handleKeyPress("*")}} >*</div>
        <div className="keyboard-button-keyboard" onClick={() => {if(enablePhone)handleKeyPress("0")}} >0</div>
        <div className="keyboard-button-keyboard" onClick={() => {if(enablePhone)handleKeyPress("#")}} >#</div>
      </div>
        <div className="buttons">
          <div className="keyboard-buttons-actions keyboard-call" onClick={handleGreenButton}>
            <FontAwesomeIcon icon={faPhoneFlip} />
          </div>
        </div></>}
    </div>
  );
};

export default IncomingCall
