import React from "react";
import { Col, Table, Card } from "reactstrap";

const Step2 = ({ supportedLosingCarriers }) => {
  return (
    <div>
      {supportedLosingCarriers && (
        <>
          {supportedLosingCarriers.map((element, i) => (
            <Card key={i} className="itp-table-container-centered mb-4">
              <table className="itp-custom-table">
                <thead>
                  <tr className="itp-custom-table-thead-styles">
                    <th className="itp-custom-table-th-styles text-center">
                      {element.LosingCarrierName}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {typeof element.TnList.Tn === "string" ? (
                    <tr className="itp-custom-table-row">
                      <td className="itp-custom-table-td-styles text-center">
                        {element.TnList.Tn}
                      </td>
                    </tr>
                  ) : (
                    element.TnList.Tn.map((element, index) => (
                      <tr className="itp-custom-table-row" key={index}>
                        <td className="itp-custom-table-td-styles text-center">
                          {element}
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </Card>
          ))}
        </>
      )}
    </div>
  );
};

export default Step2;
