import React, { useEffect, useState } from "react";
import { Card } from "reactstrap";
import Switch from "react-switch";
import circleCall from "../../../../assets/icons/circleCall.svg";
import SearchIcon from "@mui/icons-material/Search";

const Extensions = ({ users, setUsers }) => {
  const [inputFocused, setInputFocused] = useState(false);
  const [searchFilter, setSearchFilter] = useState("");
  const [switchUsersCall, setSwitchUsersCall] = useState(true);
  const [switchUsersOffline, setSwitchUsersOffline] = useState(true);
  const [dataUsers, setDataUsers] = useState(null);
  const [currentTime, setCurrentTime] = useState(new Date());

  const Offsymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 0,
        }}
      >
        {" "}
        No
      </div>
    );
  };

  const OnSymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 0,
        }}
      >
        {" "}
        Yes
      </div>
    );
  };

  const handleChangeFilterInput = (e) => {
    setSearchFilter(e.target.value);
  };

  const handleInputFocus = () => {
    setInputFocused(true);
  };

  const handleInputBlur = () => {
    setInputFocused(false);
  };

  const selectColor = (element) => {
    let conferenceActive = false;
    if (element.channels.length !== 0) {
      users.forEach((elementTwo) => {
        if (elementTwo.channels.length > 1) {
          elementTwo.channels.forEach((elementThree) => {
            if (
              element.presence_id === elementThree.callee_id_number ||
              element.presence_id === elementThree.caller_id_number
            ) {
              conferenceActive = true;
            }
          });
        }
      });
    }

    const type = `${
      element.channels.length === 0
        ? "READY"
        : element.channels.length > 1 || conferenceActive
        ? "conference"
        : element.channels[0].answered
    }`;

    if (element.devices_registered === false) return "#c8c8c8";
    else if (type === "READY") return "#199F28";
    else if (type === "true" || type === "false" || type === "conference")
      return "#D60C0C";
  };

  const selectNameStatus = (element) => {
    let conferenceActive = false;
    if (element.channels.length !== 0) {
      users.forEach((elementTwo) => {
        if (elementTwo.channels.length > 1) {
          elementTwo.channels.forEach((elementThree) => {
            if (
              element.presence_id === elementThree.callee_id_number ||
              element.presence_id === elementThree.caller_id_number
            ) {
              conferenceActive = true;
            }
          });
        }
      });
    }

    const type = `${
      element.channels.length === 0
        ? "READY"
        : element.channels.length > 1 || conferenceActive
        ? "conference"
        : element.channels[0].answered
    }`;

    if (element.devices_registered === false) return "Offline";
    else if (type === "READY") return "Available";
    else if (type === "true" || type === "false" || type === "conference")
      return "Busy";
  };

  const startTimer = (initialTime) => {
    const timer = setInterval(() => {
      setCurrentTime(new Date(initialTime * 1000).toISOString().substr(11, 8));
    }, 1000);
  
    return () => {
      clearInterval(timer);
    };
  };

  const handleTermination = (pk) => {
    setTimeout(() => {
      const updatedUsers = users.map(user => {
        if (user.pk === pk) {
          user.channels = []
          return user;
        } else {
          return user;
        }
      })
  
      setUsers(updatedUsers);
    }, 3000); // Delay in milliseconds (3 seconds)
  };

  const getData = (channels, pk) => {
    if (channels.length === 0) return <></>;

    if (channels.length > 1) {
      return (
        <>
          {" "}
          <img src={circleCall} alt="" /> Conference
        </>
      );
    }

    let time = ""

    if (channels[0]?.time) {
      startTimer(channels[0]?.time ? channels[0]?.time : 0); // Start the timer for updating time
      time = new Date((channels[0]?.time ? channels[0]?.time : 0) * 1000).toISOString().substr(11, 8);
    }

    if (channels[0].terminated) {
      handleTermination(pk)
      return (
        <>
          {" "}
          <img src={circleCall} alt="" /> {`Terminated ${time}`}
        </>
      );
    }

    if (!channels[0].answered) {
      return (
        <>
          {" "}
          <img src={circleCall} alt="" />
          ing...
        </>
      );
    }

    return (
      <>
        {" "}
        <img src={circleCall} alt="" /> {`${channels[0].withNumber} ${time}`}
      </>
    );
  };

  useEffect(() => {
    if (dataUsers) {
      let userTemp = users;
      if (searchFilter.length > 0) {
        userTemp = userTemp?.filter(
          (el) =>
            el.presence_id.toLowerCase().includes(searchFilter) ||
            el.callerID.toLowerCase().includes(searchFilter.toLocaleLowerCase())
        );
      }
      if (!switchUsersCall) {
        userTemp = userTemp?.filter((el) => el.channels.length > 0);
      }
      if (!switchUsersOffline) {
        userTemp = userTemp?.filter((el) => el.devices_registered === true);
      }

      setDataUsers(userTemp);
    }
  }, [searchFilter, switchUsersCall, switchUsersOffline]);

  useEffect(() => {
    if (users.length > 0) setDataUsers(users);
  }, [users]);

  return (
    dataUsers && (
      <>
        <div className="itp-custom-table-container-actionsf row" style={{width:"100%", marginTop:"20px", marginBottom:"20px"}}>
          <div className="col-9">
            <div className="itp-custom-table-actions">
              <div
                style={{width:"991px"}}
                className={`itp-custom-table-container-search-input${
                  inputFocused ? " focused-input" : ""
                }`}
              >
                <SearchIcon className="itp-custom-table-search-icon" />
                <input
                  className="itp-custom-table-search-input"
                  placeholder="Search Number, name or assigned user"
                  onChange={handleChangeFilterInput}
                  onFocus={handleInputFocus}
                  onBlur={handleInputBlur}
                  value={searchFilter}
                />
              </div>
            </div>
          </div>
          <div className="col-3">
            <div className="itp-custom-table-add-buttons" style={{marginLeft:"55px"}}>
              <div className="itp-custom-table-container-switchs">
                <div className="d-flex align-items-center">
                  <label className="itp-custom-table-switch-label">
                    <b>Show users on call</b>
                  </label>
                  <Switch
                    uncheckedIcon={<Offsymbol width={4} height={4} />}
                    className="itp-custom-table-switch"
                    checkedIcon={<OnSymbol width={4} height={4} />}
                    onColor="#626ed4"
                    height={20} // Ajusta la altura según tus necesidades
                    width={40}
                    onChange={() => setSwitchUsersCall(!switchUsersCall)}
                    checked={switchUsersCall}
                  />
                </div>
                <div className="d-flex align-items-center">
                  <label className="itp-custom-table-switch-label">
                    <b>Show users offline</b>
                  </label>
                  <Switch
                    uncheckedIcon={<Offsymbol width={4} height={4} />}
                    className="itp-custom-table-switch"
                    checkedIcon={<OnSymbol width={4} height={4} />}
                    onColor="#626ed4"
                    height={20}
                    width={40}
                    onChange={() => setSwitchUsersOffline(!switchUsersOffline)}
                    checked={switchUsersOffline}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <Card className="itp-table-container-alll" style={{width:"98%"}}>
            <div>
              <h1 className="itp-custom-table-title-card">Extensions</h1>

              <p className="itp-custom-table-subtitle-card">
                View & communicate with all the users with their respective
                extension in the system
              </p>

              <table className="itp-custom-table">
                <thead>
                  <tr className="itp-custom-table-thead-styles">
                    <th className="itp-custom-table-th-styles">Status</th>
                    <th className="itp-custom-table-th-styles">Extension</th>
                    <th className="itp-custom-table-th-styles">Caller ID</th>
                    <th className="itp-custom-table-th-styles">Communicate</th>
                  </tr>
                </thead>
                <tbody>
                  {dataUsers.map((element, i) => (
                    <tr className="itp-custom-table-row" key={i}>
                      <td
                        style={{
                          borderLeft: `6px solid ${selectColor(element)}`,
                          color: selectColor(element),
                        }}
                        className="itp-custom-table-td-styles"
                      >
                        {selectNameStatus(element)}
                      </td>
                      <td className="itp-custom-table-td-styles">
                        {element.presence_id}
                      </td>
                      <td className="itp-custom-table-td-styles">
                        {element.callerID}
                      </td>
                      <td className="itp-custom-table-td-styles">
                        {getData(element.channels, element.pk)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </Card>
        </div>
      </>
    )
  );
};

export default Extensions;
