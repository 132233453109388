import React from "react";
import { Loading } from "../index";
import { convertLocalTimeToTimezoneTimeOnFormated } from "../../utils/getDate";
import ChatWindowHeader from "./ChatWindowHeader/ChatWindowHeader";
import ChatWindowContent from "./ChatWindowContent/ChatWindowContent";
import ChatWindowComposer from "./ChatWindowComposer/ChatWindowComposer";
import "./ChatWindow.css";

const ChatWindow = ({
  closeChatWindow,
  openMenuData,
  toggleMenu,
  loading,
  messages,
  setMessages,
  chatWindowData,
  timeZone,
  number,
  sendMessages,
  messagesContainerRef,
  activeModalDelete,
  loadingMoreMessages,
  getMoreMessages,
  changeAiEnabled,
  noSendMessage,
  noHover,
}) => {
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const submitMessage = async (messageBody, imgMessages) => {
    let currentMessages = messages;

    if (imgMessages && imgMessages.length > 0) {
      imgMessages.forEach((img) => {
        currentMessages.push({
          message_participant: number,
          message_body: null,
          message_timestamp: convertLocalTimeToTimezoneTimeOnFormated(),
          sending: true,
          message_mms_media: null,
          url: img.data_url,
        });
      });
    }

    if(messageBody) {
      currentMessages.push({
        message_participant: number,
        message_body: messageBody,
        message_timestamp: convertLocalTimeToTimezoneTimeOnFormated(),
        sending: true,
      });
    }

    setMessages(currentMessages);
    setTimeout(() => {
      messagesContainerRef.current.scrollTop =
        messagesContainerRef.current.scrollHeight;
    }, 10);

    forceUpdate();

    await sendMessages(messageBody, imgMessages);
  };

  return (
    <>
      <div className="itp-chat-window">
        {loading ? (
          <Loading />
        ) : (
          <>
            <ChatWindowHeader
              chatWindowData={chatWindowData}
              openMenuData={openMenuData}
              toggleMenu={toggleMenu}
              closeChatWindow={closeChatWindow}
              activeModalDelete={activeModalDelete}
              changeAiEnabled={changeAiEnabled}
            />

            <ChatWindowContent
              messagesContainerRef={messagesContainerRef}
              messages={messages}
              timeZone={timeZone}
              chatWindowData={chatWindowData}
              activeModalDelete={activeModalDelete}
              loading={loadingMoreMessages}
              getMoreMessages={getMoreMessages}
              noHover={noHover}
            />

            {!noSendMessage && (
              <ChatWindowComposer submitMessage={submitMessage} />
            )}
          </>
        )}
      </div>
    </>
  );
};

export default ChatWindow;
