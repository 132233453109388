import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { ChatList, ChatWindow, Loading } from "../../../../components";
import getQueryString from "../../../../utils/getQueryString";
import useScreenSize from "../../../../hooks/useScreenSize";
import petitionGet from "../../../../services/petitionGet";
import petitionPost from "../../../../services/petitionPost";
import petitionPatch from "../../../../services/petitionPatch";
import petitionDelete from "../../../../services/petitionDelete";
import json from "../../../../config.json";
import ringMessage from "../../../../assets/audio/ringmessage.mp3";
import ModalDelete from "../ModalUtility/ModalDelete";
import ChatMenu from "./ChatMenu/ChatMenu";
import "../Conversations.css";

const Messages = ({ number, handleActiveCall }) => {
  const myExtension = useSelector((state) => state.myExtension.userDetails);

  const messagesContainerRef = useRef(null);
  const selectParameter = useRef("");
  const chatListPagination = useRef({
    offset: 0,
    limit: 15,
  });
  const messagesPagination = useRef({
    offset: 0,
    limit: 20,
  });

  const id = JSON.parse(localStorage.getItem("userDetails")).api_id;
  const urlBase = json.prod ? json.urlBase.prod : json.urlBase.dev;
  const token = JSON.parse(localStorage.getItem("userDetails")).access_token;

  const chatList = useRef(null);
  const chatWindowData = useRef({});
  const { width } = useScreenSize();
  const [openChatWindow, setOpenChatWindow] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingScroll, setLoadingScroll] = useState(false);
  const [currentChatOpen, setCurrentChatOpen] = useState("");
  const [loadingChatWindow, setLoadingChatWindow] = useState(false);
  const [messages, setMessages] = useState(null);
  const [errorWebSocket, setErrorWebSocket] = useState(false);
  // const [addMessageSocket, setAddMessageSocket] = useState(null);
  const [showAlertForNewMessage, setShowAlertForNewMessage] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [addedNewChat, setAddedNewChat] = useState(false);
  const [loadingChatList, setLoadingChatList] = useState(false);
  const [loadingMoreMessages, setLoadingMoreMessages] = useState(false);
  const [editedContact, setEditedContact] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const urlWebSocket = json.prod ? json.webSocket.prod : json.webSocket.dev;
  const rindingMessage = new Audio(ringMessage);

  const selectStatus = (callStatus) => {
    if (callStatus === "missed") return "Missed Call";
    if (callStatus === "answered") return "Call Answered";
  };

  const selectMessageBody = (callStatus, callDirection) => {
    if (callStatus === "missed") return "Unanswered call";

    if (callStatus === "answered" && callDirection === "inbound") {
      return "Call answered by you";
    }

    if (callStatus === "answered" && callDirection === "outbound") {
      return "Call made by you";
    }
  };

  const selectImage = (callStatus, callDirection) => {
    if (callStatus === "missed") return "missed";

    if (callStatus === "answered" && callDirection === "inbound") {
      return "answeredIn";
    }

    if (callStatus === "answered" && callDirection === "outbound") {
      return "answeredOut";
    }
  };

  const activeModalDelete = (message) => {
    console.log(message)
    if (!message) {
      setModalDelete(chatWindowData.current.pk);
    } else {
      setModalDelete(message);
    }
  };

  const closeModal = () => {
    setModalDelete(false);
  };

  const toggleMenu = () => {
    if (openMenu && editedContact) {
      let parameter =
        getQueryString(chatListPagination.current) + selectParameter.current;
      petition(number, parameter, false, true, editedContact);
    }
    setOpenMenu(!openMenu);
  };

  const activeChatWindow = async (
    chat,
    noLoading,
    noUpdateChatList,
    forceOpen
  ) => {
    if (
      (chat?.notIsSelf?.message_thread_id !==
        currentChatOpen?.message_thread_id ||
      forceOpen) && !chat?.notIsSelf.addedFronEnd
    ) {
      if (chatList.current[0].notIsSelf.addedFronEnd === true) {
        chatList.current = chatList.current.slice(1)
      }
      setShowAlertForNewMessage(false);
      setOpenChatWindow(true);

      setOpenMenu(false);

      if (!noLoading) setLoadingChatWindow(true);

      let messageThreadId = chat?.notIsSelf?.message_thread_id;

      await petitionGet("messageThread", {
        number,
        thread: messageThreadId,
        parameter: "?offset=0&limit=20",
      })
        .then(({ data: result }) => {
          result.result.messages.forEach((element) => {
            if (element.item_type === "call") {
              element.status = selectStatus(element.call_status);
              element.message_body = selectMessageBody(
                element.call_status,
                element.call_direction
              );
              element.selectImage = selectImage(
                element.call_status,
                element.call_direction
              );
            } else if (element.item_type === "voicemail") {
              element.audio_message = `${urlBase}/itpvoice/v2/${id}/my-extension/voicemail-messages/${element.voicemail_id}?token=${token}`;
              element.message_body = null;
            }
          });
          let newMessages = result?.result?.messages?.reverse();

          chatWindowData.current = chat;
          setCurrentChatOpen(chat?.notIsSelf);

          updateChatThreadRead(messageThreadId, newMessages, noUpdateChatList);
          messagesPagination.current = { offset: 0, limit: 20 };
        })
        .catch((error) => console.log(error));
    }
  };

  const updateChatThreadRead = (pk, newMessages, noUpdateChatList) => {
    petitionPost("markRead", {
      thread: pk,
      number,
      data: {},
    })
      .then(({ data: result }) => {
        if (!noUpdateChatList) {
          let newChats = [];
          chatList.current.forEach((element) => {
            if (element?.pk.toString() === pk) element.thread_read = true;
            newChats.push(element);
          });

          chatList.current = newChats;
        }

        setMessages(newMessages);
        forceUpdate();
        setLoadingChatWindow(false);
        setTimeout(() => {
          if (messagesContainerRef?.current)
            messagesContainerRef.current.scrollTop =
              messagesContainerRef?.current?.scrollHeight;
        }, 10);
      })
      .catch((error) => console.log(error?.response));
  };

  const closeChatWindow = () => {
    setOpenChatWindow(false);
    setCurrentChatOpen("");
    setMessages(null);
    chatWindowData.current = {};
    messagesPagination.current = { offset: 0, limit: 20 };
  };

  const moreData = () => {
    setLoadingScroll(true);
    let paginationtemp = {
      offset: 0,
      limit:
        chatListPagination.current.limit + chatListPagination.current.limit,
    };

    let parameter = getQueryString(paginationtemp) + selectParameter.current;

    petition(number, parameter, true);
    chatListPagination.current = paginationtemp;
  };

  const sendMessages = async (new_message, imgMessages) => {
    let petitions = [];

    imgMessages.forEach((img) => {
      const formData = new FormData();
      formData.append("file", img.file);
      formData.append("body", "");
      formData.append("from_number", number);
      formData.append("item_type", "sms");
      formData.append(
        "to_numbers_list",
        JSON.stringify({ list: [`${chatWindowData.current.numberChat}`] })
      );

      petitions.push(petitionPost("sendSms", { data: formData, number }));
    });

    Promise.all(petitions)
      .then(async (result) => {
        if (!new_message) {
          petitionGet("messageThread", {
            number,
            thread: chatWindowData.current?.notIsSelf?.message_thread_id,
            parameter: getQueryString(messagesPagination.current),
          })
            .then(({ data: response }) => {
              response.result.messages.forEach((element) => {
                if (element.item_type === "call") {
                  element.status = selectStatus(element.call_status);
                  element.message_body = selectMessageBody(
                    element.call_status,
                    element.call_direction
                  );
                  element.selectImage = selectImage(
                    element.call_status,
                    element.call_direction
                  );
                } else if (element.item_type === "voicemail") {
                  element.audio_message = `${urlBase}/itpvoice/v2/${id}/my-extension/voicemail-messages/${element.voicemail_id}?token=${token}`;
                  element.message_body = null;
                }
              });
              let addedNewMessages = response.result.messages.reverse();
              petitionPost("markRead", {
                thread: chatWindowData.current?.notIsSelf?.message_thread_id,
                number,
                data: {},
              })
                .then(({ data: res }) => {
                  setMessages(addedNewMessages);
                  forceUpdate();
                  setTimeout(() => {
                    if (messagesContainerRef?.current)
                      messagesContainerRef.current.scrollTop =
                        messagesContainerRef?.current?.scrollHeight;
                  }, 10);
                  setAddedNewChat(false);

                  let parameter =
                    "?offset=0&limit=15" + selectParameter.current;
                  petition(number, parameter, true);
                  chatListPagination.current = { offset: 0, limit: 15 };
                })
                .catch((error) => console.log(error));
            })
            .catch((error) => console.log(error));
        } else {
          const formData = new FormData();
          formData.append("body", new_message);
          formData.append("from_number", number);
          formData.append("item_type", "sms");
          formData.append(
            "to_numbers_list",
            JSON.stringify({ list: [`${chatWindowData.current.numberChat}`] })
          );

          petitionPost("sendSms", { data: formData, number })
            .then(({ data: result }) => {
              petitionGet("messageThread", {
                number,
                thread: result.result.message_thread_id,
                parameter: getQueryString(messagesPagination.current),
              })
                .then(({ data: response }) => {
                  response.result.messages.forEach((element) => {
                    if (element.item_type === "call") {
                      element.status = selectStatus(element.call_status);
                      element.message_body = selectMessageBody(
                        element.call_status,
                        element.call_direction
                      );
                      element.selectImage = selectImage(
                        element.call_status,
                        element.call_direction
                      );
                    } else if (element.item_type === "voicemail") {
                      element.audio_message = `${urlBase}/itpvoice/v2/${id}/my-extension/voicemail-messages/${element.voicemail_id}?token=${token}`;
                      element.message_body = null;
                    }
                  });
                  let addedNewMessages = response.result.messages.reverse();
                  petitionPost("markRead", {
                    thread: result.result.message_thread_id,
                    number,
                    data: {},
                  })
                    .then(({ data: res }) => {
                      setMessages(addedNewMessages);
                      if (addedNewChat) {
                        chatWindowData.current = {
                          ...chatWindowData.current,
                          pk: result.result.message_thread_id,
                        };
                      }
                      forceUpdate();
                      setTimeout(() => {
                        if (messagesContainerRef?.current)
                          messagesContainerRef.current.scrollTop =
                            messagesContainerRef?.current?.scrollHeight;
                      }, 10);

                      let parameter =
                        "?offset=0&limit=15" + selectParameter.current;
                      petition(number, parameter, true);
                      chatListPagination.current = { offset: 0, limit: 15 };
                    })
                    .catch((error) => console.log(error));
                })
                .catch((error) => console.log(error));
            })
            .catch((error) => console.log(error));
        }
      })
      .catch((error) => console.log(error));
  };

  const addNewChat = (numberChat) => {
    let findOneChat = false;
    chatList.current.forEach((element) => {
      let findNumber = element.participants.find(
        (part) => part.number === numberChat
      );

      if (findNumber) {
        findOneChat = element;
      }
    });

    if (findOneChat) {
      activeChatWindow(findOneChat);
      setAddedNewChat(true);
    } else {
      let newChatList = chatList.current;

      let newChat = {
        last_message: "",
        last_updated: "",
        numberChat: numberChat,
        notIsSelf: { message_thread_id: 99999, addedFronEnd: true, },
        participants: [
          {
            is_self: false,
            message_thread_id: 99999,
            number: numberChat,
            pk: 99999,
          },
          {
            is_self: true,
            message_thread_id: 99998,
            number: number,
            pk: 99998,
          },
        ],
        thread_read: true,
      };

      newChatList.unshift(newChat);

      chatWindowData.current = newChat;
      setCurrentChatOpen(newChat?.notIsSelf);
      setMessages([]);
      setShowAlertForNewMessage(false);
      setOpenChatWindow(true);
      setAddedNewChat(true);
      chatList.current = newChatList;
      forceUpdate();
    }
  };

  const deleteChat = async () => {
    setLoadingDelete(true);
    petitionDelete("deleteThreadSms", { number, smsId: modalDelete })
      .then(({ data: result }) => {
        setLoadingDelete(false);
        closeModal();
        closeChatWindow();
        petition(number, "?offset=0&limit=15", false);
      })
      .catch((error) => console.log(error));
  };

  const deleteMessage = () => {
    setLoadingDelete(true);
    petitionDelete("deleteMessageSms", {
      number: modalDelete?.message_participant,
      message_thread_id: modalDelete?.pk,
      smsId: modalDelete?.pk,
    })
      .then(({ data: result }) => {
        let newMessages = [];

        messages.forEach((element) => {
          if (element.pk !== modalDelete.pk) {
            newMessages.push(element);
          }
        });

        setMessages(newMessages);
        forceUpdate();
        setLoadingDelete(false);
        closeModal();
        petition(number, "?offset=0&limit=15", true);
      })
      .catch((error) => {
        console.log(error);
        setLoadingDelete(false);
      });
  };

  const chatsSelectedByTheDropdown = (value) => {
    setOpenMenu(false);
    setLoadingChatList(true);

    let parameter = "";

    if (value === "All") {
      parameter = "?offset=0&limit=15";
      selectParameter.current = "";
      chatListPagination.current = { offset: 0, limit: 15 };
    } else if (value === "Unread") {
      parameter = "?offset=0&limit=15&threads_read=false";
      selectParameter.current = "&threads_read=false";
      chatListPagination.current = { offset: 0, limit: 15 };
    } else if (value === "AI") {
      parameter = "?offset=0&limit=15&ai_enabled=true";
      selectParameter.current = "&ai_enabled=true";
      chatListPagination.current = { offset: 0, limit: 15 };
    }

    petitionGet("listSms", { number, parameter })
      .then(({ data: result }) => {
        result.result.message_threads.forEach((element) => {
          let notIsSelf = element.participants.find((par) => !par.is_self);
          element.isContact = notIsSelf?.contact ? true : false;
          element.numberChat = notIsSelf.number;
          element.notIsSelf = notIsSelf;

          if (!element.last_message) {
            element.last_message = "📂 file";
          }

          if (notIsSelf.contact) {
            element.firstname = notIsSelf.contact.firstname;
            element.lastname = notIsSelf?.contact?.lastname || "";
            element.nameContact = `${notIsSelf.contact.firstname} ${
              notIsSelf.contact.lastname || ""
            }`;
          }
        });

        closeChatWindow();
        forceUpdate();
        chatList.current = result.result.message_threads;
        chatListPagination.current = { offset: 0, limit: 15 };
        setLoadingChatList(false);
      })
      .catch((error) => console.log(error.response));
  };

  const getMoreMessages = async () => {
    setLoadingMoreMessages(true);

    let paginationtemp = {
      offset: 0,
      limit: messagesPagination?.current?.limit + 20,
    };

    petitionGet("messageThread", {
      number,
      thread: currentChatOpen?.message_thread_id,
      parameter: getQueryString(paginationtemp),
    })
      .then(({ data: result }) => {
        result.result.messages.forEach((element) => {
          if (element.item_type === "call") {
            element.status = selectStatus(element.call_status);
            element.message_body = selectMessageBody(
              element.call_status,
              element.call_direction
            );
            element.selectImage = selectImage(
              element.call_status,
              element.call_direction
            );
          } else if (element.item_type === "voicemail") {
            element.audio_message = `${urlBase}/itpvoice/v2/${id}/my-extension/voicemail-messages/${element.voicemail_id}?token=${token}`;
            element.message_body = null;
          }
        });

        let newMessages = result?.result?.messages?.reverse();

        setMessages(newMessages);
        setLoadingMoreMessages(false);
        messagesPagination.current = paginationtemp;
      })
      .catch((error) => console.log(error));
  };

  const changeAiEnabled = (value) => {
    let data = {
      ai_enabled: value,
    };
    petitionPatch("aiEnabled", {
      data,
      thread_id: chatWindowData?.current?.pk,
    })
      .then(({ data: response }) => {
        let parameter =
          getQueryString(chatListPagination.current) + selectParameter.current;
        petitionGet("listSms", { number, parameter })
          .then(({ data: result }) => {
            result.result.message_threads.forEach((element) => {
              let notIsSelf = element.participants.find((par) => !par.is_self);
              element.isContact = notIsSelf?.contact ? true : false;
              element.numberChat = notIsSelf.number;
              element.notIsSelf = notIsSelf;

              if (!element.last_message) {
                element.last_message = "📂 file";
              }

              if (notIsSelf.contact) {
                element.firstname = notIsSelf.contact.firstname;
                element.lastname = notIsSelf?.contact?.lastname || "";
                element.nameContact = `${notIsSelf.contact.firstname} ${
                  notIsSelf.contact.lastname || ""
                }`;
              }
            });

            if (result.result.message_threads.length === 0) {
              closeChatWindow();
            }

            chatList.current = result.result.message_threads;
            chatWindowData.current = {
              ...chatWindowData?.current,
              ai_enabled: value,
            };
            forceUpdate();
          })
          .catch((error) => console.log(error));
      })
      .catch((error) => console.log(error));
  };

  const petition = async (
    number,
    parameter,
    noLoading,
    activateChatWindow,
    thread
  ) => {
    if (!noLoading) setLoading(true);

    await petitionGet("listSms", { number, parameter })
      .then(({ data: result }) => {
        result.result.message_threads.forEach((element) => {
          let notIsSelf = element.participants.find((par) => !par.is_self);
          element.isContact = notIsSelf?.contact ? true : false;
          element.numberChat = notIsSelf.number;
          element.notIsSelf = notIsSelf;

          if (!element.last_message) {
            element.last_message = "📂 file";
          }

          if (notIsSelf.contact) {
            element.firstname = notIsSelf.contact.firstname;
            element.lastname = notIsSelf?.contact?.lastname || "";
            element.nameContact = `${notIsSelf.contact.firstname} ${
              notIsSelf.contact.lastname || ""
            }`;
          }
        });

        chatList.current = result.result.message_threads;
        forceUpdate();

        setLoading(false);
        setLoadingScroll(false);

        if (activateChatWindow) {
          let findChat = result.result.message_threads.find(
            (chat) => chat.pk === parseInt(thread)
          );

          if (findChat) {
            activeChatWindow(findChat, false, false, true);
            setEditedContact(false);
          }
        }

        if (result.result.message_threads.length === 0) {
          closeChatWindow();
        }
      })
      .catch((error) => console.log(error));
  };

  const petitions = async () => {
    chatList.current = null;
    setOpenChatWindow(false);
    setOpenMenu(false);
    setLoading(true);
    setLoadingScroll(false);
    setCurrentChatOpen("");
    setMessages(null);
    // setAddMessageSocket(null);
    forceUpdate();

    let parameter =
      getQueryString(chatListPagination.current) + selectParameter.current;

    // await petitionGet("userDetails")
    //   .then(({ data: result }) => {
    //     myExtension.current = result?.result;
    //   })
    //   .catch((error) => console.log(error));

    await petition(number, parameter);
  };

  // useEffect(() => {
  //   if (number) {
  //     const token = JSON.parse(
  //       localStorage.getItem("userDetails")
  //     ).access_token;

  //     const id = JSON.parse(localStorage.getItem("userDetails")).api_id;

  //     let socket = new WebSocket(`${urlWebSocket}`);

  //     socket.onmessage = (e) => {
  //       let repeatSound = false;
  //       let payload;
  //       let result = JSON.parse(e.data);
  //       if (result.payload !== "Logged in" && typeof result !== "string") {
  //         payload = JSON.parse(result?.payload);
  //       }

  //       console.log(result)
  //       console.log(result)

  //       if (
  //         (result.message_type === "sms" ||
  //           result.message_type === "sms-status") &&
  //         payload?.user_id &&
  //         payload?.user_id === myExtension?.pk &&
  //         payload.message_status === "received"
  //       ) {
  //         //mensaje recibido
  //         if (
  //           payload.to_numbers_list[0] !== chatWindowData.current.numberChat
  //         ) {
  //           if (
  //             chatWindowData.current &&
  //             payload.message_thread_pk === chatWindowData.current?.pk
  //           ) {
  //             petitionPost("markRead", {
  //               thread: chatWindowData.current?.notIsSelf?.message_thread_id,
  //               number,
  //               data: {},
  //             })
  //               .then(({ data: result }) => {
  //                 petitionGet("messageThread", {
  //                   number,
  //                   thread:
  //                     chatWindowData.current?.notIsSelf?.message_thread_id,
  //                   parameter: "?offset=0&limit=20",
  //                 })
  //                   .then(({ data: result }) => {
  //                     let newMessages = result?.result?.messages?.reverse();
  //                     setMessages(newMessages);
  //                     forceUpdate();
  //                     setTimeout(() => {
  //                       if (messagesContainerRef?.current)
  //                         messagesContainerRef.current.scrollTop =
  //                           messagesContainerRef?.current?.scrollHeight;
  //                     }, 10);

  //                     if (!repeatSound) {
  //                       rindingMessage.play();
  //                     }

  //                     repeatSound = true;

  //                     petitionGet("listSms", {
  //                       number,
  //                       parameter: "?offset=0&limit=15",
  //                     })
  //                       .then(({ data: result }) => {
  //                         result.result.message_threads.forEach((element) => {
  //                           let notIsSelf = element.participants.find(
  //                             (par) => !par.is_self
  //                           );
  //                           element.isContact = notIsSelf?.contact
  //                             ? true
  //                             : false;
  //                           element.numberChat = notIsSelf.number;
  //                           element.notIsSelf = notIsSelf;

  //                           if (!element.last_message) {
  //                             element.last_message = "📂 file";
  //                           }

  //                           if (notIsSelf.contact) {
  //                             element.firstname = notIsSelf.contact.firstname;
  //                             element.lastname =
  //                               notIsSelf?.contact?.lastname || "";
  //                             element.nameContact = `${
  //                               notIsSelf.contact.firstname
  //                             } ${notIsSelf.contact.lastname || ""}`;
  //                           }
  //                         });

  //                         chatList.current = result.result.message_threads;
  //                         forceUpdate();
  //                         chatListPagination.current = { offset: 0, limit: 15 };
  //                       })
  //                       .catch((error) => console.log(error));
  //                   })
  //                   .catch((error) => console.log(error));
  //               })
  //               .catch((error) => console.log(error));
  //           } else if (
  //             chatWindowData.current &&
  //             payload.message_thread_pk !== chatWindowData.current?.pk
  //           ) {
  //             petitionGet("listSms", {
  //               number,
  //               parameter: "?offset=0&limit=15",
  //             })
  //               .then(({ data: result }) => {
  //                 result.result.message_threads.forEach((element) => {
  //                   let notIsSelf = element.participants.find(
  //                     (par) => !par.is_self
  //                   );
  //                   element.isContact = notIsSelf?.contact ? true : false;
  //                   element.numberChat = notIsSelf.number;
  //                   element.notIsSelf = notIsSelf;

  //                   if (!element.last_message) {
  //                     element.last_message = "📂 file";
  //                   }

  //                   if (notIsSelf.contact) {
  //                     element.firstname = notIsSelf.contact.firstname;
  //                     element.lastname = notIsSelf?.contact?.lastname || "";
  //                     element.nameContact = `${notIsSelf.contact.firstname} ${
  //                       notIsSelf.contact.lastname || ""
  //                     }`;
  //                   }
  //                 });

  //                 chatList.current = result.result.message_threads;
  //                 forceUpdate();
  //                 chatListPagination.current = { offset: 0, limit: 15 };

  //                 if (!repeatSound) {
  //                   rindingMessage.play();
  //                 }

  //                 repeatSound = true;
  //               })
  //               .catch((error) => console.log(error));
  //           }
  //         }
  //       }
  //     };

  //     socket.onerror = (e) => {
  //       setErrorWebSocket(true);
  //     };

  //     //Connect
  //     socket.onopen = (e) => {
  //       socket.onclose = (e) => {
  //         setErrorWebSocket(true);
  //       };

  //       socket.send(
  //         JSON.stringify({
  //           action: "login",
  //           payload: {
  //             jwt_token: token.replace(/['"]+/g, ""),
  //             account_id: id,
  //             phone_number: number,
  //           },
  //         })
  //       );
  //     };
  //   }
  // }, [number, errorWebSocket]);

  useEffect(() => {
    if (number) {
      const token = JSON.parse(
        localStorage.getItem("userDetails")
      ).access_token;

      const id = JSON.parse(localStorage.getItem("userDetails")).api_id;

      let socket = new WebSocket(`${urlWebSocket}`);

      socket.onmessage = (e) => {
        let repeatSound = false; //Repeat sound play
        let payload;
        let result = JSON.parse(e.data);
        if (result.payload !== "Logged in" && typeof result !== "string") {
          payload = JSON.parse(result?.payload);
        }

        if (
          result.message_type === "sms" ||
          result.message_type === "sms-status"
        ) {
          if (payload.user_id) {
            if (payload.user_id === myExtension?.pk) {
              let findPk = true;

              chatList.current.forEach((element) => {
                console.log(element)
                if (
                  payload.message_thread_pk === null ||
                  element.pk === payload.message_thread_pk
                ) {
                  findPk = false;
                }

                //If you recive a message
                if (element.pk === payload.message_thread_pk) {
                  petitionPost("markRead", {
                    thread:
                      chatWindowData.current?.notIsSelf?.message_thread_id,
                    number,
                    data: {},
                  })
                    .then(({ data: result }) => {
                      petitionGet("messageThread", {
                        number,
                        thread:
                          chatWindowData.current?.notIsSelf?.message_thread_id,
                        parameter: "?offset=0&limit=20",
                      })
                        .then(({ data: result }) => {
                          result.result.messages.forEach((element) => {
                            if (element.item_type === "call") {
                              element.status = selectStatus(
                                element.call_status
                              );
                              element.message_body = selectMessageBody(
                                element.call_status,
                                element.call_direction
                              );
                              element.selectImage = selectImage(
                                element.call_status,
                                element.call_direction
                              );
                            } else if (element.item_type === "voicemail") {
                              element.audio_message = `${urlBase}/itpvoice/v2/${id}/my-extension/voicemail-messages/${element.voicemail_id}?token=${token}`;
                              element.message_body = null;
                            }
                          });
                          let newMessages = result?.result?.messages?.reverse();
                          setMessages(newMessages);
                          forceUpdate();
                          setTimeout(() => {
                            if (messagesContainerRef?.current)
                              messagesContainerRef.current.scrollTop =
                                messagesContainerRef?.current?.scrollHeight;
                          }, 10);

                          if (
                            (!repeatSound &&
                              (!chatWindowData.current ||
                                chatWindowData.current.numberChat !==
                                  payload.to_numbers_list[0])) ||
                            !isActive
                          ) {
                            rindingMessage.play();
                          }

                          repeatSound = true;

                          petitionGet("listSms", {
                            number,
                            parameter: "?offset=0&limit=15",
                          })
                            .then(({ data: result }) => {
                              result.result.message_threads.forEach(
                                (element) => {
                                  let notIsSelf = element.participants.find(
                                    (par) => !par.is_self
                                  );
                                  let isSelf = element.participants.find(
                                    (par) => par.is_self
                                  );
                                  element.isContact = notIsSelf?.contact
                                    ? true
                                    : false;
                                  element.numberChat = notIsSelf.number;
                                  element.notIsSelf = notIsSelf;
                                  element.fromNumber = isSelf.number;

                                  if (!element.last_message) {
                                    element.last_message = "📂 file";
                                  }

                                  if (notIsSelf.contact) {
                                    element.firstname =
                                      notIsSelf.contact.firstname;
                                    element.lastname =
                                      notIsSelf?.contact?.lastname || "";
                                    element.nameContact = `${
                                      notIsSelf.contact.firstname
                                    } ${notIsSelf.contact.lastname || ""}`;
                                  }
                                }
                              );

                              chatList.current = result.result.message_threads;
                              forceUpdate();
                              chatListPagination.current = {
                                offset: 0,
                                limit: 15,
                              };
                            })
                            .catch((error) => console.log(error));
                        })
                        .catch((error) => console.log(error));
                    })
                    .catch((error) => console.log(error));
                }

                //If you send a message
                if (
                  chatWindowData.current.fromNumber === payload.from_number &&
                  payload.from_number !== chatWindowData.current.numberChat &&
                  payload.message_provider_id
                ) {
                  petitionPost("markRead", {
                    thread:
                      chatWindowData.current?.notIsSelf?.message_thread_id,
                    number,
                    data: {},
                  })
                    .then(({ data: result }) => {
                      petitionGet("messageThread", {
                        number,
                        thread:
                          chatWindowData.current?.notIsSelf?.message_thread_id,
                        parameter: "?offset=0&limit=20",
                      })
                        .then(({ data: result }) => {
                          result.result.messages.forEach((element) => {
                            if (element.item_type === "call") {
                              element.status = selectStatus(
                                element.call_status
                              );
                              element.message_body = selectMessageBody(
                                element.call_status,
                                element.call_direction
                              );
                              element.selectImage = selectImage(
                                element.call_status,
                                element.call_direction
                              );
                            } else if (element.item_type === "voicemail") {
                              element.audio_message = `${urlBase}/itpvoice/v2/${id}/my-extension/voicemail-messages/${element.voicemail_id}?token=${token}`;
                              element.message_body = null;
                            }
                          });
                          let newMessages = result?.result?.messages?.reverse();
                          setMessages(newMessages);
                          forceUpdate();
                          setTimeout(() => {
                            if (messagesContainerRef?.current)
                              messagesContainerRef.current.scrollTop =
                                messagesContainerRef?.current?.scrollHeight;
                          }, 10);

                          petitionGet("listSms", {
                            number,
                            parameter: "?offset=0&limit=15",
                          })
                            .then(({ data: result }) => {
                              result.result.message_threads.forEach(
                                (element) => {
                                  let notIsSelf = element.participants.find(
                                    (par) => !par.is_self
                                  );
                                  let isSelf = element.participants.find(
                                    (par) => par.is_self
                                  );
                                  element.isContact = notIsSelf?.contact
                                    ? true
                                    : false;
                                  element.numberChat = notIsSelf.number;
                                  element.notIsSelf = notIsSelf;
                                  element.fromNumber = isSelf.number;

                                  if (!element.last_message) {
                                    element.last_message = "📂 file";
                                  }

                                  if (notIsSelf.contact) {
                                    element.firstname =
                                      notIsSelf.contact.firstname;
                                    element.lastname =
                                      notIsSelf?.contact?.lastname || "";
                                    element.nameContact = `${
                                      notIsSelf.contact.firstname
                                    } ${notIsSelf.contact.lastname || ""}`;
                                  }
                                }
                              );

                              chatList.current = result.result.message_threads;
                              forceUpdate();
                              chatListPagination.current = {
                                offset: 0,
                                limit: 15,
                              };
                            })
                            .catch((error) => console.log(error));
                        })
                        .catch((error) => console.log(error));
                    })
                    .catch((error) => console.log(error));
                }
              });
            }
          } else {
            petitionGet("listSms", {
              number,
              parameter: "?offset=0&limit=15",
            })
              .then(({ data: result }) => {
                result.result.message_threads.forEach((element) => {
                  let notIsSelf = element.participants.find(
                    (par) => !par.is_self
                  );
                  let isSelf = element.participants.find((par) => par.is_self);
                  element.isContact = notIsSelf?.contact ? true : false;
                  element.numberChat = notIsSelf.number;
                  element.notIsSelf = notIsSelf;
                  element.fromNumber = isSelf.number;

                  if (!element.last_message) {
                    element.last_message = "📂 file";
                  }

                  if (notIsSelf.contact) {
                    element.firstname = notIsSelf.contact.firstname;
                    element.lastname = notIsSelf?.contact?.lastname || "";
                    element.nameContact = `${notIsSelf.contact.firstname} ${
                      notIsSelf.contact.lastname || ""
                    }`;
                  }
                });

                chatList.current = result.result.message_threads;
                forceUpdate();
                chatListPagination.current = { offset: 0, limit: 15 };

                if (
                  (!repeatSound &&
                    (!chatWindowData.current ||
                      chatWindowData.current.numberChat !==
                        payload.to_numbers_list[0])) ||
                  !isActive
                ) {
                  rindingMessage.play();
                }

                repeatSound = true;
              })
              .catch((error) => console.log(error));
          }
        }
      };

      socket.onerror = (e) => {
        setErrorWebSocket(true);
      };

      //Connect
      socket.onopen = (e) => {
        socket.onclose = (e) => {
          setErrorWebSocket(true);
        };

        socket.send(
          JSON.stringify({
            action: "login",
            payload: {
              jwt_token: token.replace(/['"]+/g, ""),
              account_id: id,
              phone_number: number,
            },
          })
        );
      };
    }
  }, [number, errorWebSocket, myExtension]);

  useEffect(() => {
    petitions();
  }, [number]);

  useEffect(() => {
    // Función para manejar el evento de visibilidad
    const handleVisibilityChange = () => {
      if (document.hidden) {
        // La página se volvió inactiva o se cambió de pestaña
        setIsActive(false);
      } else {
        // La página se volvió activa o se cambió a esta pestaña
        setIsActive(true);
      }
    };

    // Agregar un event listener para el evento de visibilidad
    document.addEventListener("visibilitychange", handleVisibilityChange);

    // Limpiar el event listener cuando el componente se desmonte
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  return loading ? (
    <Loading />
  ) : (
    <>
      {modalDelete && (
        <ModalDelete
          modal={modalDelete}
          closeModal={closeModal}
          loading={loadingDelete}
          modalFunction={
            typeof modalDelete === "number" ? deleteChat : deleteMessage
          }
        />
      )}

      <div className="itp-all-chats">
        <ChatList
          activeChatWindow={activeChatWindow}
          showChatList={!openChatWindow}
          chatList={chatList.current}
          moreData={moreData}
          loadingScroll={loadingScroll}
          showAlertForNewMessage={showAlertForNewMessage}
          addNewChat={addNewChat}
          onChageSelect={chatsSelectedByTheDropdown}
          loading={loadingChatList}
          chatWindowData={chatWindowData.current}
        />
        {openChatWindow && (
          <div
            className={`all-container-chat${
              width > 1399 && openMenu ? " resize" : ""
            }`}
          >
            <ChatWindow
              closeChatWindow={closeChatWindow}
              width={width}
              openMenu={openMenu}
              toggleMenu={toggleMenu}
              loading={loadingChatWindow}
              messages={messages}
              setMessages={setMessages}
              chatWindowData={chatWindowData.current}
              timeZone={myExtension?.time_zone}
              number={number}
              sendMessages={sendMessages}
              messagesContainerRef={messagesContainerRef}
              activeModalDelete={activeModalDelete}
              deleteMessage={deleteMessage}
              loadingMoreMessages={loadingMoreMessages}
              getMoreMessages={getMoreMessages}
              changeAiEnabled={changeAiEnabled}
            />
          </div>
        )}
        <div
          className={`${
            width > 1399 && openMenu
              ? "all-container-menu"
              : width < 1400 && openMenu
              ? "all-container-menu-absolute"
              : "no-show"
          }`}
        >
          <ChatMenu
            openMenu={openMenu}
            toggleMenu={toggleMenu}
            chatWindowData={chatWindowData}
            chatList={chatList}
            setEditedContact={setEditedContact}
            number={number}
            timeZone={myExtension?.time_zone}
            handleActiveCall={handleActiveCall}
          />
        </div>
      </div>
    </>
  );
};

export default Messages;
