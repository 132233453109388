import React, { useEffect, useRef, useState } from "react";
import { Loading, ChatList, ChatWindow } from "../../../../components";
import getQueryString from "../../../../utils/getQueryString";
import petitionGet from "../../../../services/petitionGet";
import useScreenSize from "../../../../hooks/useScreenSize";
import ModalDelete from "../ModalUtility/ModalDelete";
import json from "../../../../config.json";
import petitionDelete from "../../../../services/petitionDelete";

const MyInbox = () => {
  const { width } = useScreenSize();

  const id = JSON.parse(localStorage.getItem("userDetails")).api_id;
  const urlBase = json.prod ? json.urlBase.prod : json.urlBase.dev;
  const token = JSON.parse(localStorage.getItem("userDetails")).access_token;

  const messagesContainerRef = useRef(null);
  const myExtension = useRef({});
  const chatWindowData = useRef({});
  const pagination = useRef({
    offset: 0,
    limit: 15,
  });

  const [loading, setLoading] = useState(true);
  const [voicemails, setVoicemails] = useState([]);
  const [openChatWindow, setOpenChatWindow] = useState(false);
  const [loadingScroll, setLoadingScroll] = useState(false);
  const [messages, setMessages] = useState(null);
  const [loadingChatWindow, setLoadingChatWindow] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [currentChatOpen, setCurrentChatOpen] = useState("");

  const activeChatWindow = (chat) => {
    if (chat.number !== currentChatOpen) {
      setOpenChatWindow(true);
      setLoadingChatWindow(true);

      setTimeout(() => {
        chatWindowData.current = chat;
        setCurrentChatOpen(chat.pk);
        setMessages(chat.voicemails);

        setLoadingChatWindow(false);

        setTimeout(() => {
          if (messagesContainerRef?.current)
            messagesContainerRef.current.scrollTop =
              messagesContainerRef?.current?.scrollHeight;
        }, 10);
      }, 1000);
    }
  };

  const activeModalDelete = (message) => {
    setModalDelete(message);
  };

  const closeChatWindow = () => {
    setCurrentChatOpen("");
    setOpenChatWindow(false);
    setMessages(null);
    chatWindowData.current = {};
  };

  const closeModal = () => {
    setModalDelete(false);
  };

  const deleteVoicemailChat = () => {
    setLoadingDelete(true)
    petitionDelete("voicemail", { media_id: modalDelete.pk })
      .then(({ data: result }) => {
        closeChatWindow()
        setLoadingDelete(false)
        closeModal()
        let parameter = getQueryString(pagination.current);
        petition(parameter);
      })
      .catch((error) => console.log(error))
  };

  const moreData = () => {
    setLoadingScroll(true);
    let paginationtemp = {
      offset: 0,
      limit: pagination.current.limit + pagination.current.limit,
    };

    let parameter = getQueryString(paginationtemp);

    petition(parameter, true);
    pagination.current = paginationtemp;
  };

  const chatsSelectedByTheDropdown = (value) => {
    console.log(value);
  };

  const petition = (parameter, noLoading) => {
    if (!noLoading) setLoading(true);

    petitionGet("voicemailDetails", { parameter })
      .then(({ data: result }) => {
        let newVoicemails = [];

        Object.keys(result.result).forEach((element) => {
          const name =
            element.split("'").length < 2 && element.split('"').length < 2
              ? element
              : element.split("'")[1]
              ? element.split("'")[1]
              : element.split('"')[1];

          const number =
            element.split("'").length < 2 && element.split('"').length < 2
              ? element
              : element.split("'")[2]
              ? element.split("'")[2].split("<")[1].split(">")[0]
              : element.split('"')[2].split("<")[1].split(">")[0];

          let voicemails = result.result[element];
          voicemails.forEach((item) => {
            item.message_participant = 0;
            item.audio_message = `${urlBase}/itpvoice/v2/${id}/my-extension/voicemail-messages/${item.pk}?token=${token}`;
            item.message_timestamp = item.usertime.slice(0, 19);
            item.firstname = name.split(" ")[0]
            item.lastname = name.split(" ")[1] ? name.split(" ")[1] : ""
          });

          newVoicemails.push({
            isContact: true,
            firstname: name.split(" ")[0],
            lastname: name.split(" ")[1],
            nameContact: name,
            last_updated: "",
            last_message: number,
            voicemails: voicemails,
            pk: number,
          });
        });

        setVoicemails(newVoicemails);
        setLoadingScroll(false);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    let parameter = getQueryString(pagination.current);
    petition(parameter);
  }, []);

  return loading ? (
    <Loading />
  ) : (
    <>
      {modalDelete && (
        <ModalDelete
          modal={modalDelete}
          closeModal={closeModal}
          loading={loadingDelete}
          modalFunction={deleteVoicemailChat}
        />
      )}

      <div className="itp-all-chats">
        <ChatList
          activeChatWindow={activeChatWindow}
          showChatList={!openChatWindow}
          chatList={voicemails}
          moreData={moreData}
          loadingScroll={loadingScroll}
          onChageSelect={chatsSelectedByTheDropdown}
          loading={loading}
          chatWindowData={chatWindowData}
          noAddIcon={true}
        />

        {openChatWindow && (
          <div className="all-container-chat">
            <ChatWindow
              closeChatWindow={closeChatWindow}
              width={width}
              openMenu={() => {}}
              toggleMenu={() => {}}
              loading={loadingChatWindow}
              messages={messages}
              setMessages={setMessages}
              chatWindowData={chatWindowData.current}
              timeZone={myExtension?.current?.time_zone}
              number={""}
              sendMessages={() => {}}
              messagesContainerRef={messagesContainerRef}
              activeModalDelete={activeModalDelete}
              deleteMessage={() => {}}
              loadingMoreMessages={false}
              getMoreMessages={() => {}}
              changeAiEnabled={() => {}}
              noSendMessage={true}
            />
          </div>
        )}

        {/* <div className="itp-audio-message">
          <AudioMessage audioUrl={audioUrl} duration={15} />
        </div> */}
      </div>
    </>
  );
};

export default MyInbox;
