import React, { useEffect, useState } from "react";
import { LoadingButton, Loading } from "../../../../../components";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import petitionGet from "../../../../../services/petitionGet";
import "../AddLead.css";

const AddLeadOnly = ({ loadingAddLead, addNewLead, funnels, leadSource }) => {
  const [valueLeadSource, setValueLeadSource] = useState(null);
  const [valueFunnel, setValueFunnel] = useState(null);
  const [valueStage, setValueStage] = useState(null);
  const [stages, setStages] = useState([]);
  const [loadingChangeFunnel, setLoadingChangeFunnel] = useState(false);
  const [form, setForm] = useState({
    lead_source_id: "",
    funnel_id: "",
    funnel_pipeline_stage_id: "",
    contact_id: "",
  });

  const onChangeFunnel = (e) => {
    setLoadingChangeFunnel(true);
    petitionGet("stages", { parameter: `?funnel_id=${e.pk}` })
      .then(({ data: result }) => {
        if (result.result.length > 0) {
          result.result.forEach((stage) => {
            stage.label = stage.name;
            stage.value = stage.pk;
          });

          setStages(result.result);
        }
        setForm({ ...form, funnel_id: e.pk, funnel_pipeline_stage_id: "" });
        setValueFunnel(e);
        setValueStage(null);
        setLoadingChangeFunnel(false);
      })
      .catch((error) => console.log(error));
  };

  const loadOptions = (inputValue, callback) => {
    petitionGet("contacts", {
      parameter: `?firstname=${inputValue}&lastname=${inputValue}&mobile=${inputValue}&phone=${inputValue}`,
    })
      .then(({ data: result }) => {
        result.result.forEach((element) => {
          element.label = `${element.firstname} ${element.lastname || ""}`;
          element.value = element.pk;
        });

        callback(result.result);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <div className="add-lead-container-field">
        <label className="itp-label-input__label">Contact</label>
        <AsyncSelect
          onChange={(e) => setForm({ ...form, contact_id: e.value })}
          className="basic-single add-lead-select"
          classNamePrefix="select"
          loadOptions={loadOptions}
          isSearchable={true}
          name="contact_id"
          placeholder="Choose contact"
          defaultOptions
          cacheOptions
        />
      </div>
      <div className="add-lead-container-field">
        <label className="itp-label-input__label">Lead Source</label>
        <Select
          onChange={(e) => {
            setForm({ ...form, lead_source_id: e.value });
            setValueLeadSource(e);
          }}
          className="basic-single add-lead-select"
          classNamePrefix="select"
          value={valueLeadSource}
          isSearchable={true}
          name="lead_source_id"
          placeholder="Choose lead source"
          options={leadSource}
        />
      </div>
      <div className="add-lead-container-field">
        <label className="itp-label-input__label">Funnel</label>
        <Select
          onChange={(e) => {
            setForm({ ...form, funnel_pipeline_stage_id: e.value });
            onChangeFunnel(e);
          }}
          className="basic-single add-lead-select"
          classNamePrefix="select"
          value={valueFunnel}
          isSearchable={true}
          name="funnel_id"
          placeholder="Choose a funnel"
          options={funnels}
        />
      </div>
      {loadingChangeFunnel ? (
        <Loading />
      ) : (
        <>
          {form.funnel_id && (
            <>
              {stages.length > 0 ? (
                <div className="add-lead-container-field">
                  <label className="itp-label-input__label">Funnel Stage</label>
                  <Select
                    onChange={(e) => {
                      setForm({ ...form, funnel_pipeline_stage_id: e.value });
                      setValueStage(e);
                    }}
                    className="basic-single add-lead-select"
                    classNamePrefix="select"
                    value={valueStage}
                    isSearchable={true}
                    name="funnel_pipeline_stage_id"
                    placeholder="Choose funnel stage"
                    options={stages}
                  />
                </div>
              ) : (
                <p>There are no stages created for this funnel</p>
              )}
            </>
          )}
        </>
      )}

      <button
        className="btn-primary loading add-lead-new-contact"
        disabled={
          !form.lead_source_id ||
          !form.funnel_id ||
          !form.funnel_pipeline_stage_id ||
          !form.contact_id
        }
        onClick={() => addNewLead(form)}
      >
        {loadingAddLead && <LoadingButton />}
        Save
      </button>
    </>
  );
};

export default AddLeadOnly;
