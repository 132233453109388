import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "reactstrap";
import { useSelector } from "react-redux";
import { itemsMenu, secondItemsMenu } from "../../const/itemsMenu";
import { useMenu, useMenuDispatch } from "../../contexts/MenuContext";
import points from "../../assets/icons/points.svg";
import petitionGet from "../../services/petitionGet";
import json from "../../config.json";
import "./Sidebar.css";

function Sidebar({
  items,
  navigate,
  refSidebar,
  toggleMenu,
  onUserSettingsClick,
}) {
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];

  const myExtension = useSelector((state) => state.myExtension.userDetails);

  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [myInbox, setMyInbox] = useState({});
  const [currentItems, setCurrentItems] = useState(items);
  const activeNumber =
    window.location.search && window.location.search.slice(1, 7) === "number"
      ? window.location.search.slice(8)
      : "";
      
  const activeMyInbox =
    window.location.search && window.location.search.slice(1, 8) === "myInbox"
      ? true
      : false;

  const currentMenu = useMenu();
  const dispatchMenu = useMenuDispatch();

  const returnNumber = (number) => {
    let firstThreeNumbers = number.slice(2, 5);
    let secondThreNumbers = number.slice(5, 8);
    let lastFourtNumbers = number.slice(8);

    let formatedNumber = `+1 (${firstThreeNumbers}) ${secondThreNumbers}-${lastFourtNumbers}`;

    return formatedNumber;
  };

  const handleUserSettingsClick = () => {
    if (currentMenu === "default") {
      dispatchMenu({ type: "SET_USER_SETTINGS_MENU" });
    } else {
      dispatchMenu({ type: "SET_DEFAULT_MENU" });
    }
  };

  const petition = async () => {
    await petitionGet("assignedNumbers")
      .then(({ data: result }) => {
        setPhoneNumbers(result.result);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    setMyInbox(myExtension);
  }, [myExtension]);

  useEffect(() => {
    if (currentMenu === "default") {
      setCurrentItems(itemsMenu);

      // Call the petition function here to populate inboxes.
      setMyInbox(myExtension);
      petition();
    } else {
      setCurrentItems(secondItemsMenu);

      // If you wish, you can clear the inboxes here when not in the default menu.
      setMyInbox(null);
      setPhoneNumbers([]);
    }
  }, [currentMenu]);

  useEffect(() => {
    let isUrlSecondMenu = false;

    for (let i = 0; i < secondItemsMenu.length; i++) {
      for (let j = 0; j < secondItemsMenu[i].items.length; j++) {
        if (secondItemsMenu[i].items[j].url === path) {
          isUrlSecondMenu = true;
          break;
        }
      }
      if (isUrlSecondMenu) {
        break;
      }
    }

    if (isUrlSecondMenu) {
      dispatchMenu({ type: "SET_USER_SETTINGS_MENU" });
    }
  }, []);

  return (
    <div
      ref={refSidebar}
      className={`itp-sidebar${toggleMenu ? " hidden" : ""}`}
    >
      <div className="itp-sidebar-container-before">
        <Col className="itp-sidebar-containter">
          {currentItems.map((element, i) => (
            <Fragment key={i}>
              {element.isTitle &&
                !element.inboxes &&
                (!element.validate ||
                  (element.validate && myExtension.priv_level === "admin")) && (
                  <div>
                    <p
                      className={`itp-sidebar-title-section${
                        element.start === true ? "" : " no-start"
                      }`}
                    >
                      {element.title}
                    </p>
                    {element.items.map((elementTwo) => (
                      <Fragment key={elementTwo.name}>
                        {(!elementTwo.prod ||
                          (elementTwo.prod && !json.prod)) && (
                          <Link
                            className="itp-sidebar-items"
                            to={`${elementTwo.url}${elementTwo.param || ""}`}
                          >
                            <Row
                              className={`itp-sidebar-row-item${
                                elementTwo.url === path ? " active" : ""
                              }`}
                            >
                              <Col xl={2} className="itp-sidebar-icon">
                                {elementTwo.icon}
                              </Col>
                              <Col className="itp-sidebar-col-span">
                                <span className="itp-sidebar-span">
                                  {elementTwo.name}
                                </span>
                              </Col>
                            </Row>
                          </Link>
                        )}
                      </Fragment>
                    ))}
                  </div>
                )}

              {!element.isTitle &&
                !element.inboxes &&
                (!element.prod || (element.prod && !json.prod)) &&
                (!element.validate ||
                  (element.validate && myExtension.priv_level === "admin")) && (
                  <Link
                    to={`${element.url}${element.param || ""}`}
                    className="itp-sidebar-items"
                    onClick={
                      element.name === "User Settings"
                        ? handleUserSettingsClick
                        : () => {}
                    }
                  >
                    <Row
                      className={`itp-sidebar-row-item${
                        path === element.url ? " active" : ""
                      }`}
                    >
                      <Col
                        xs={2}
                        sm={2}
                        md={2}
                        lg={2}
                        xl={2}
                        className="itp-sidebar-icon"
                      >
                        {element.icon}
                      </Col>
                      <Col className="itp-sidebar-col-span">
                        <span className="itp-sidebar-span">{element.name}</span>
                      </Col>
                    </Row>
                  </Link>
                )}
            </Fragment>
          ))}
          <Col className="itp-sidebar-inboxes">
            <p className="itp-sidebar-inboxes-title">Inboxes</p>

            {myInbox && (
              <Link to={`My-Inbox?myInbox=true`} className="itp-sidebar-items">
                <Row
                  className={`itp-sidebar-container-notification ${
                    activeMyInbox ? "active" : ""
                  }`}
                >
                  <Col
                    xs={3}
                    sm={3}
                    md={3}
                    lg={3}
                    xl={3}
                    className="itp-sidebar-container-circle"
                  >
                    <div className="circle">M</div>
                  </Col>
                  <Col
                    xs={6}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={6}
                    className="container-inboxes-data"
                  >
                    <p className="itp-sidebar-inboxes-subtitle">My Inbox</p>
                    <p className="itp-sidebar-inboxes-number">
                      {myInbox?.presence_id}
                    </p>
                  </Col>
                  <Col>
                    <img
                      onClick={() => console.log(myInbox)}
                      src={points}
                      alt="Points"
                    />
                  </Col>
                </Row>
              </Link>
            )}

            {phoneNumbers.map((element) => (
              <Link
                to={`Conversations?number=${element.number}`}
                key={element.number}
                className="itp-sidebar-items"
              >
                <Row
                  className={`itp-sidebar-container-notification ${
                    activeNumber === element.number ? "active" : ""
                  }`}
                >
                  <Col
                    xs={3}
                    sm={3}
                    md={3}
                    lg={3}
                    xl={3}
                    className="itp-sidebar-container-circle"
                  >
                    <div className="circle">P</div>
                  </Col>
                  <Col
                    xs={6}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={6}
                    className="container-inboxes-data"
                  >
                    <p className="itp-sidebar-inboxes-subtitle">Phone Number</p>
                    <p className="itp-sidebar-inboxes-number">
                      {returnNumber(element.number)}
                    </p>
                  </Col>
                  <Col>
                    <img
                      onClick={() => console.log()}
                      src={points}
                      alt="Points"
                    />
                  </Col>
                </Row>
              </Link>
            ))}
          </Col>
        </Col>
      </div>
    </div>
  );
}

export default Sidebar;
