import React from "react";
import { Card } from "reactstrap";

const Step5 = ({ form, serviceItpVoice }) => {
  return (
    <div>
      <span style={{ fontSize: "1rem", margin: "1rem 0" }}>Orden Summary</span>
      <Card className="itp-table-container-all">
        <table className="itp-custom-table">
          <thead>
            <tr className="itp-custom-table-thead-styles">
              <th className="itp-custom-table-th-styles">
                Recurring Changes - monthly
              </th>
              <th className="itp-custom-table-th-styles">Qty</th>
              <th className="itp-custom-table-th-styles">SubTotal*</th>
            </tr>
          </thead>
          <tbody>
            {form.length > 0 && (
              <tr className="itp-custom-table-row">
                <td className="itp-custom-table-td-styles">
                  Additional Local Numbers - Domestic{" "}
                </td>
                <td className="itp-custom-table-td-styles">
                  {form.length}x$
                  {parseInt(serviceItpVoice[0]?.metadata.price_per_did)}{" "}
                </td>
                <td className="itp-custom-table-td-styles">
                  $
                  {form.length *
                    parseInt(serviceItpVoice[0]?.metadata.price_per_did)}{" "}
                </td>
              </tr>
            )}

            <tr className="itp-custom-table-row">
              <td className="itp-custom-table-td-styles pt-5">
                <strong>Estimated Total</strong>
              </td>
              <td className="itp-custom-table-td-styles pt-5"></td>
              <td className="itp-custom-table-td-styles pt-5">
                <strong>
                  $
                  {form.length *
                    parseInt(serviceItpVoice[0]?.metadata.price_per_did)}
                </strong>
              </td>
            </tr>
          </tbody>
        </table>
      </Card>
      <label className="itp-custom-table-td-styles col-12">
        *This price does not include calculations for prorated amounts, taxes,
        fees and/or shipping.
      </label>
    </div>
  );
};

export default Step5;
