import React, { useEffect, useRef, useState } from "react";
import { CustomTableFunnels, Loading, MenuRight } from "../../../components/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPenToSquare,
  faPencilAlt,
  faThumbtack,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router";
import ModalDelete from "./ModalUtility/ModalDelete";
import AddFunnel from "./AddFunnel/AddFunnel";
import FunnelDetails from "./FunnelDetails/FunnelDetails";
import Stages from "./Stages/Stages";
import petitionGet from "../../../services/petitionGet";
import petitionDelete from "../../../services/petitionDelete";
import petitionPost from "../../../services/petitionPost";
import getQueryString from "../../../utils/getQueryString";
import "./Funnels.css";

function Funnels() {
  const navigate = useNavigate();
  const itemsPerPage = useRef("10");
  const pagination = useRef({
    offset: 0,
    limit: 10,
  });
  const [loading, setLoading] = useState(true);
  const [dataTable, setDataTable] = useState(null);
  const [modalDelete, setModalDelete] = useState(false);
  const [info, setInfo] = useState(null);
  const [loadingFunnel, setLoadingFunnel] = useState(false);
  const [showAddFunnel, setShowAddFunnel] = useState(false);
  const [showEditStages, setShowEditStages] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [funnelDetails, setFunnelDetails] = useState(null);
  const [allResult, setAllResult] = useState(null);
  const [params, setParams] = useState(window.location.search);
  const [loadingChangeItemsPerPage, setLoadingChangeItemsPerPage] =
  useState(false);

  const closeMenu = () => {
    setShowEditStages(null);
    setFunnelDetails(null);
    setShowAddFunnel(false);
    setInfo(null);
    setModalDelete(false);
    setOpenMenu(false);
  };

  const activeDeleteFunnel = (e) => {
    setInfo(e);
    setModalDelete(true);
  };

  const activeAddFunnel = () => {
    setOpenMenu(true);
    setShowAddFunnel(true);
  };

  const activeFunnelDetails = (e) => {
    setFunnelDetails(e);
  };

  const activeEditStages = (e) => {
    setShowEditStages(e)
    setOpenMenu(true)
  }

  const selectTitleMenu = () => {
    if(openMenu && showAddFunnel && !showEditStages){
      return "Add New Funnel"
    } else if(openMenu && !showAddFunnel && showEditStages){
      return "Stages of the funnel"
    }
  }

  const selectSubTitleMenu = () => {
    if(openMenu && showAddFunnel && !showEditStages){
      return "Add the details asked to add new funnel"
    } else if(openMenu && !showAddFunnel && showEditStages){
      return "View & edit the stages of this funnel"
    }
  }

  const reloadData = (pag) => {
    closeMenu();
    if(pag){
      let parameter = getQueryString(pag)
      petition(parameter)
      pagination.current = pag
    } else {
      let parameter = getQueryString(pagination.current)
      petition(parameter);
    }
  };

  const backFunction = () => {
    closeMenu();
    if(params){
      navigate("/Funnels");
      setParams("");
      reloadData(false)
    } else {
      reloadData(false)
    }
  }

  const deleteFunnel = () => {
    setLoadingFunnel(true);
    petitionDelete("funnel", { funnel_id: info.pk })
      .then(({ data: result }) => {
        setLoadingFunnel(false);
        if (allResult.result.length === 1 && parseInt(allResult.offset) !== 0) {
          let paginationTemp = {
            limit: pagination.current.limit,
            offset: pagination.current.offset - pagination.current.limit,
          };
          reloadData(paginationTemp);
        } else {
          reloadData(false);
        }
      })
      .catch((error) => console.log(error));
  };

  const addFunnel = (form) => {
    setLoadingFunnel(true);
    petitionPost("funnel", { data: form })
      .then(({ data: result }) => {
        setLoadingFunnel(false);
        reloadData(false);
      })
      .catch((error) => console.log(error));
  };

  const changePage = (page) => {
    let paginationTemp = {
      limit: pagination.current.limit,
      offset: page === 1 ? 0 : (page - 1) * pagination.current.limit,
    };

    let parameter = getQueryString(paginationTemp);

    petition(parameter, true);
    pagination.current = paginationTemp;
  };

  const activeItemsPerPage = (data) => {
    setLoadingChangeItemsPerPage(true);
    let paginationTemp = {
      offset: 0,
      limit: parseInt(data),
    };

    let parameter = getQueryString(paginationTemp);
    petition(parameter, true);
    itemsPerPage.current = data;
    pagination.current = paginationTemp;
  };

  const petition = async (parameter, noLoading, onlyPk) => {
    if (!noLoading) setLoading(true);

    if(!onlyPk){
      let priveLevel = "";

      await petitionGet("userDetails")
        .then(({ data: result }) => {
          priveLevel = result.result.priv_level;
        })
        .catch((error) => console.log(error));
  
      await petitionGet("funnels", { parameter })
        .then(({ data: result }) => {
          setAllResult(result);
          setDataTable({
            title: [
              { title: "Name", key: "name" },
              { title: "Description", key: "description" },
            ],
            content: [...result.result],
            actions:
              priveLevel !== "admin"
                ? null
                : {
                    title: "Action",
                    content: [
                      {
                        name: "Pin Funnel",
                        handleClick: (e) => console.log(e),
                        icon: <FontAwesomeIcon icon={faThumbtack} />,
                      },
                      {
                        name: "Edit Stages",
                        handleClick: activeEditStages,
                        icon: <FontAwesomeIcon icon={faPencilAlt} />,
                      },
                      {
                        name: "Edit Details",
                        handleClick: activeFunnelDetails,
                        icon: <FontAwesomeIcon icon={faPenToSquare} />,
                      },
                      {
                        name: "Delete Funnel",
                        handleClick: activeDeleteFunnel,
                        icon: <FontAwesomeIcon icon={faTrash} />,
                      },
                    ],
                  },
            search: {
              label: "search funnel",
            },
            addButton: {
              label: "Add New Funnel",
              handleClick: activeAddFunnel,
            },
            changePage: (page) => changePage(page),
            itemsPerPage: (e) => activeItemsPerPage(e),
            clickRow: activeFunnelDetails,
          });
          setLoading(false);
          setLoadingChangeItemsPerPage(false);
        })
        .catch((error) => console.log(error));
    } else {
      await petitionGet("funnels", { parameter: onlyPk })
        .then(({ data: result }) => {
          setLoading(false);
          activeFunnelDetails(result.result)
        })
        .catch((error) => console.log(error));
    }
  };

  useEffect(() => {
    if(params){
      let redirect = "/" + params.slice(1) 
      petition(false, false, redirect);
    } else {
      petition(getQueryString(pagination.current));
    }
  }, []);

  return (
    <div className={`itp-test itp-${itemsPerPage.current}-items`}>
      {loading ? (
        <Loading />
      ) : (
        <>
          {/* {modalDelete && (
            <ModalDelete
              modal={modalDelete}
              closeModal={closeMenu}
              loading={loadingFunnel}
              modalFunction={deleteFunnel}
            />
          )} */}

          {funnelDetails ? (
            <FunnelDetails back={backFunction} funnelDetails={funnelDetails} />
          ) : (
            <>
            <div>
              {dataTable && (
                <CustomTableFunnels
                  data={dataTable} 
                  allResult={allResult}
                  paginationData={itemsPerPage.current}
                  loading={loadingChangeItemsPerPage}
                />
              )}
            </div>
            </>

          )}

          <MenuRight
            show={openMenu}
            closeMenu={closeMenu}
            title={selectTitleMenu()}
            subtitle={selectSubTitleMenu()}
            to="to Funnels"
          >
            {openMenu && showAddFunnel && !showEditStages && (
              <AddFunnel addFunnel={addFunnel} loading={loadingFunnel} />
            )}

            {openMenu && !showAddFunnel && showEditStages && (
              <Stages funnelInfo={showEditStages} backFunction={reloadData} />
            )}
          </MenuRight>
        </>
      )}
    </div>
  );
}

export default Funnels;
