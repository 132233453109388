import React, { useState, useEffect } from "react";
import {
  Row,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import happyFace from "../../../assets/icons/happyFace.svg";
import clip from "../../../assets/icons/clip.svg";
import recordingAudio from "../../../assets/icons/recordingAudio.svg";
import printer from "../../../assets/icons/printer.svg";
import sendMessage from "../../../assets/icons/sendMessage.svg";
import AddPhotoAlternateOutlinedIcon from "@mui/icons-material/AddPhotoAlternateOutlined";
import ModalPhotos from "./ModalPhotos";
import Picker from "@emoji-mart/react";
import data from "@emoji-mart/data";
import "../ChatWindow.css";
{/* <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.12.9/umd/popper.min.js"></script>
<script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js"></script> */}
// import "https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js";
// import "https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.12.9/umd/popper.min.js";
// import "https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js";



const ChatWindowComposer = ({ submitMessage }) => {
  const [messageBody, setMessageBody] = useState("");
  const [imgMessages, setImgMessages] = useState([]);
  const [modalImages, setModalImages] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(null);
  const [emojis, setEmojis] = useState(false);

  const isOnlyWhitespace = /^\s*$/.test(messageBody);

  const handleChange = (e) => {
    setMessageBody(e.target.value);
  };

  const activeModalImages = () => {
    setModalImages(true);
  };

  const toggle = (icon) => {
    if (openDropdown) {
      setOpenDropdown(null);
    } else {
      setOpenDropdown(icon);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !event.shiftKey && messageBody && !isOnlyWhitespace) {
      event.preventDefault(); // Evita el salto de línea en el textarea
      sendMessages();
    }
  };

  // const handlePaste = (event) => {
  //   let newImages = pasteImages

  //   const items = (event.clipboardData || event.originalEvent.clipboardData).items;
  //   for (const item of items) {
  //     if (item.type.indexOf('image') !== -1) {
  //       const blob = item.getAsFile();
  //       console.log(item)
  //       console.log(blob)
  //       const imageURL = URL.createObjectURL(blob);
  //       newImages.push(imageURL)
  //       console.log(newImages)
  //       setPasteImages(newImages)
  //       forceUpdate()
  //     }
  //   }
  // };

  const handlePaste = (event) => {
    let newImages = imgMessages

    const items = (event.clipboardData || event.originalEvent.clipboardData).items;
    for (const item of items) {
      if (item.type.indexOf('image') !== -1) {
        const blob = item.getAsFile();
        const reader = new FileReader();
        
        reader.onload = (e) => {
          const base64Image = e.target.result;
          newImages.push({
            data_url: base64Image,
            file: blob
          })

          setImgMessages(newImages)
          activeModalImages()
        };

        reader.readAsDataURL(blob);
      }
    }
  };

  const sendMessages = () => {
    if ((messageBody && !isOnlyWhitespace) || imgMessages.length > 0) {
      submitMessage(messageBody, imgMessages);
      setMessageBody("");
      setImgMessages([])
    }
  }

  return (
    <>
      {modalImages && (
        <ModalPhotos
          modal={modalImages}
          setModal={setModalImages}
          imgMessages={imgMessages}
          setImgMessages={setImgMessages}
        />
      )}
      <Row className="itp-chat-window-footer" style={{bottom:"10px"}}>
        {/* <div>
          <Picker
            data={data}
            onEmojiSelect={(e) => {
              console.log(e);
            }}
            style={{ width: "100%" }}
            previewPosition="none"
            theme="light"
            skinTonePosition="none"
            set="Apple"
          />
        </div> */}
        {/* <form onSubmit={submitMessage}> */}
        <textarea
          className="itp-chat-window-footer__textarea"
          placeholder="Write a message"
          onChange={handleChange}
          onPaste={handlePaste}
          value={messageBody}
          onKeyDown={handleKeyDown}
        />

        <div className="d-flex flex-column justify-content-end">
          <div className="d-flex">
            <div style={{ gap: "4px" }} className="d-flex">
              {/* <img src={happyFace} alt="Face" className="itp-icon-footer" /> */}
              <div className="itp-footer-container-clip-icon">
                {imgMessages.length > 0 && (
                  <div className="add-photo-label itp-clip-icon-menu">
                    {imgMessages.length}
                  </div>
                )}
                <Dropdown
                  toggle={() => toggle("clip")}
                  isOpen={openDropdown === "clip" ? true : false}
                >
                  <DropdownToggle className="nav-link clip">
                    <img src={clip} alt="Clip" className="itp-icon-footer" />
                  </DropdownToggle>
                  <DropdownMenu className="clip">
                    <DropdownItem
                      onClick={activeModalImages}
                      className="dropdown-item"
                    >
                      <AddPhotoAlternateOutlinedIcon className="add-photo-icon" />{" "}
                      Add Images
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>
              {/* <img
                src={recordingAudio}
                alt="Recording"
                className="itp-icon-footer"
              />
              <img src={printer} alt="Printer" className="itp-icon-footer" /> */}
            </div>           
            <div className="w-100">
              <div className="d-flex">
                <div style={{marginTop:"-6px"}}>
                  <button style={{border:"none", backgroundColor:"white"}} onClick={()=>{document.getElementById('message-box').style.display="block";}}>
                    <img src="message-text-1.svg" style={{opacity:"0.6"}}></img>
                  </button>
                </div>
                <div className="">
                  <input id="message-part" className="w-100" onClick={()=> {document.getElementById('message-part').style.border="none"}} style={{border:"none"}}></input>
                </div>
              </div>
            </div>
            <button
              className="itp-footer-send-button"
              onClick={sendMessages}
            >
              <img src={sendMessage} alt="send" className="itp-icon-footer" />
            </button>
          </div>
        </div>
        <div className="p-2 messagetemplate-property" id="message-box" style={{zIndex:"1000", marginLeft:"-7px", width:"500px", height:"300px", position:"absolute", bottom:"140px", left:"8px", borderRadius:"4px", backgroundColor:"#ffffff", display:"none", boxShadow:"#000 0px 1px 3px"}}>
          <div>
            <input type="search" className="w-100 mt-2 ps-2" style={{borderRadius:"20px", backgroundColor:"#fffffe"}}></input>
          </div>
          <hr></hr>
          <div></div>
        </div>
        {/* </form> */}
      </Row>      
    </>
  );
};

export default ChatWindowComposer;
