import React, { useEffect, useState } from "react";
import { Card, Row, Col } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import {
  Loading,
  CustomTable,
  MenuRight,
  LoadingButton,
} from "../../../../../components";
import Switch from "react-switch";
import Select from "react-select";
import useScreenSize from "../../../../../hooks/useScreenSize";
import petitionGet from "../../../../../services/petitionGet";
import petitionPost from "../../../../../services/petitionPost";
import petitionPatch from "../../../../../services/petitionPatch";
import petitionDelete from "../../../../../services/petitionDelete";
import ModalDelete from "../ModalUtitlity/ModalDelete";
import "./QueueDetails.css";

const QueueDetails = ({ details }) => {
  const { width } = useScreenSize();

  const [dataTable, setDataTable] = useState(null);
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [modalDelete, setModalDelete] = useState(false);
  const [info, setInfo] = useState(null);
  const [loadingUser, setLoadingUser] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [defaultUsers, setDefaultUsers] = useState([]);
  const [firstRender, setFirstRender] = useState(true);
  const [form, setForm] = useState({
    queue_name: details?.queue_name,
    queue_description: details?.queue_description,
    timeout: details?.timeout.toString() || null,
    queue_timeout: details?.queue_timeout
      ? details?.queue_timeout.toString()
      : null,
    ringinuse: details?.ringinuse,
    autopause: details?.autopause,
    strategy: details?.strategy,
    joinempty: details?.joinempty,
    leavewhenempty: details?.leavewhenempty,
    queue_args: details?.queue_args,
    wrapuptime: details?.wrapuptime,
    retry: details?.retry ? details?.retry.toString() : null,
  });

  const allAreTrue = (arr) => {
    return arr.every((element) => element === true);
  };

  const disabledButton = () => {
    if (selectedUsers.length === defaultUsers.length) {
      let iquals = [];
      selectedUsers.forEach((element) => {
        defaultUsers.forEach((elementTwo) => {
          if (element.pk === elementTwo.pk) iquals.push(true);
        });
      });

      if (iquals.length === selectedUsers.length && allAreTrue(iquals)) {
        return true;
      }

      return false;
    } else {
      if (selectedUsers.length === 0) return true;

      return false;
    }
  };

  const closeModal = () => {
    setSelectedUsers(defaultUsers);
    setOpenMenu(false);
    setInfo(null);
    setModalDelete(false);
  };

  const activeDeleteModal = (e) => {
    setInfo(e);
    setModalDelete(true);
  };

  const activeAddUserQueue = () => {
    setOpenMenu(true);
  };

  const onChangeForm = (e) => {
    if (
      e.target.name === "wrapuptime" ||
      e.target.name === "timeout" ||
      e.target.name === "queue_timeout" ||
      e.target.name === "retry"
    ) {
      const newValue = /^\d+$/.test(e.target.value);

      if (newValue) {
        setForm({ ...form, [e.target.name]: e.target.value });
      }
    } else {
      setForm({ ...form, [e.target.name]: e.target.value });

      if (e.target.name === "strategy") {
        const data = {
          queue_name: form.queue_name,
          queue_description: form.queue_description,
          strategy: e.target.value,
        };

        petitionPatch("queue", { queue_id: details.pk, data })
          .then(({ data: result }) => {})
          .catch((error) => console.log(error));
      }
    }
  };

  const onChangeSwitchs = (name) => {
    let data = {};

    if (name === "ringinuse") {
      setForm({
        ...form,
        ringinuse: form.ringinuse === "yes" ? "no" : "yes",
      });

      data = {
        queue_name: form.queue_name,
        queue_description: form.queue_description,
        ringinuse: form.ringinuse === "yes" ? "no" : "yes",
      };
    }

    if (name === "autopause") {
      setForm({
        ...form,
        autopause: form.autopause === "yes" ? "no" : "yes",
      });

      data = {
        queue_name: form.queue_name,
        queue_description: form.queue_description,
        autopause: form.autopause === "yes" ? "no" : "yes",
      };
    }

    if (name === "joinempty") {
      setForm({
        ...form,
        joinempty: form.joinempty === "yes" ? "no" : "yes",
      });

      data = {
        queue_name: form.queue_name,
        queue_description: form.queue_description,
        joinempty: form.joinempty === "yes" ? "no" : "yes",
      };
    }

    if (name === "leavewhenempty") {
      setForm({
        ...form,
        leavewhenempty: form.leavewhenempty === "yes" ? "no" : "yes",
      });

      data = {
        queue_name: form.queue_name,
        queue_description: form.queue_description,
        leavewhenempty: form.leavewhenempty === "yes" ? "no" : "yes",
      };
    }

    if (name === "queue_args") {
      setForm({
        ...form,
        queue_args: form.queue_args === "r" ? "" : "r",
      });

      data = {
        queue_name: form.queue_name,
        queue_description: form.queue_description,
        queue_args: form.queue_args === "r" ? null : "r",
      };
    }

    petitionPatch("queue", { queue_id: details.pk, data })
      .then(({ data: result }) => {})
      .catch((error) => console.log(error));
  };

  const onChangeUsers = (e) => {
    setSelectedUsers(e);
  };

  const deleteUser = () => {
    setLoadingUser(true);
    petitionDelete("queueMember", {
      queue_id: details.pk,
      member_id: info.queueMemberId,
    })
      .then(({ data: result }) => {
        setLoadingUser(false);
        reloadData();
      })
      .catch((error) => console.log(error));
  };

  const addUsers = () => {
    setLoadingUser(true);

    let petitionMembers = [];

    defaultUsers.forEach((element) => {
      const dataFind = selectedUsers.find(
        (elementTwo) => elementTwo.pk === element.pk
      );

      if (!dataFind) {
        petitionMembers.push(
          petitionDelete("queueMember", {
            queue_id: details.pk,
            member_id: element.queueMemberId,
          })
        );
      }
    });

    selectedUsers.forEach((element) => {
      const dataFind = defaultUsers.find(
        (elementTwo) => elementTwo.pk === element.pk
      );

      if (!dataFind) {
        const data = {
          user_id: element.pk,
          penalty: "0",
          wrapuptime: "1",
          paused: "0",
          ringinuse: "0",
        };

        petitionMembers.push(
          petitionPost("queueMember", { queue_id: details.pk, data })
        );
      }
    });

    Promise.all(petitionMembers)
      .then((result) => {
        setLoadingUser(false);
        reloadData();
      })
      .catch((error) => console.log(error));
  };

  const reloadData = () => {
    closeModal();
    petition();
  };

  const petition = async () => {
    setLoading(true);

    let usersTemp = [];

    await petitionGet("itpvoiceUsers")
      .then(({ data: result }) => {
        result.result.forEach((element) => {
          element.label = `${element.firstname} ${element?.lastname || ""}`;
          element.value = element.pk;
        });

        usersTemp = result.result;
        setUsers(usersTemp);
      })
      .catch((error) => console.log(error));

    await petitionGet("queueMembers", { queue_id: details?.pk })
      .then(({ data: result }) => {
        const listMembers = result.result.map((element) => {
          const findUser = usersTemp.find(
            (user) => user.pk === element.user_id
          );

          if (findUser) {
            findUser.queueMemberId = element.pk;
            return findUser;
          }
        });

        setDefaultUsers(listMembers);
        setSelectedUsers(listMembers);

        setDataTable({
          title: [
            { title: "Name", key: "label" },
            { title: "Extension Number", key: "presence_id" },
          ],
          content: [...listMembers],
          iconActions: {
            title: "Actions",
            handleClick: activeDeleteModal,
            icon: <FontAwesomeIcon icon={faTrash} />,
          },
          addButton: {
            label: "Add Member",
            handleClick: activeAddUserQueue,
          },
          label: "Members",
          labelIsTitle: true,
        });
        setLoading(false);
        setFirstRender(false);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if (!firstRender) {
      const timer = setTimeout(() => {
        const data = {
          queue_name: form.queue_name,
          queue_description: form.queue_description,
        };
        petitionPatch("queue", { queue_id: details.pk, data })
          .then(({ data: result }) => {})
          .catch((error) => console.log(error));
      }, 1000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [form.queue_name, form.queue_description]);

  useEffect(() => {
    if (!firstRender) {
      const timer = setTimeout(() => {
        const data = {
          queue_name: form.queue_name,
          queue_description: form.queue_description,
          timeout: form.timeout,
        };
        petitionPatch("queue", { queue_id: details.pk, data })
          .then(({ data: result }) => {})
          .catch((error) => console.log(error));
      }, 1000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [form.timeout]);

  useEffect(() => {
    if (!firstRender) {
      const timer = setTimeout(() => {
        const data = {
          queue_name: form.queue_name,
          queue_description: form.queue_description,
          wrapuptime: form.wrapuptime,
        };
        petitionPatch("queue", { queue_id: details.pk, data })
          .then(({ data: result }) => {})
          .catch((error) => console.log(error));
      }, 1000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [form.wrapuptime]);

  useEffect(() => {
    if (!firstRender) {
      const timer = setTimeout(() => {
        const data = {
          queue_name: form.queue_name,
          queue_description: form.queue_description,
          queue_timeout: form.queue_timeout,
        };
        petitionPatch("queue", { queue_id: details.pk, data })
          .then(({ data: result }) => {})
          .catch((error) => console.log(error));
      }, 1000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [form.queue_timeout]);

  useEffect(() => {
    if (!firstRender) {
      const timer = setTimeout(() => {
        const data = {
          queue_name: form.queue_name,
          queue_description: form.queue_description,
          retry: form.retry,
        };
        petitionPatch("queue", { queue_id: details.pk, data })
          .then(({ data: result }) => {})
          .catch((error) => console.log(error));
      }, 1000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [form.retry]);

  useEffect(() => {
    petition();
  }, []);

  return loading ? (
    <Loading />
  ) : (
    <>
      {modalDelete && (
        <ModalDelete
          modal={modalDelete}
          closeModal={closeModal}
          loading={loadingUser}
          modalFunction={deleteUser}
          module="User"
        />
      )}

      <h1
        className={`itp-custom-table-title${
          width > 991 ? " centered" : ""
        } itp-time-groups-details-title`}
      >
        Queue Details : Manage settings for this queue
      </h1>

      <div className="itp-custom-table-container-actions label">
        <div className="itp-custom-table-actions">
          <label
            className={`itp-custom-table-label${
              width > 991 ? " centered" : ""
            } itp-time-groups-details-subtitle`}
          >
            {`Queue : ${details?.queue_name}`}
          </label>
        </div>
      </div>

      <br></br>
      <div>
        <Row>
          <Col>
            <div className="itp-user-settings-separator-line"></div>
          </Col>
        </Row>
      </div>
      <br></br>

      <h1
        className={`itp-custom-table-title mb-2${
          width > 991 ? " centered" : ""
        }`}
      >
        Basic Settings
      </h1>

      <Card
        className={
          width > 991
            ? `itp-table-container-centered overflow-hidden itp-edit-number-card mb-4`
            : `itp-table-container-all itp-edit-number-card mb-4`
        }
      >
        <div className="d-flex justify-content-between">
          <div className="d-flex flex-column">
            <label className="itp-edit-number-label-1">Name</label>
            <label className="itp-edit-number-label-2">Change the name</label>
          </div>
          <input
            placeholder="Add Name"
            className="form-control-itp itp-edit-number-input"
            name="queue_name"
            value={form?.queue_name || ""}
            onChange={onChangeForm}
          />
        </div>
        <div className="d-flex justify-content-between mt-4">
          <div className="d-flex flex-column">
            <label className="itp-edit-number-label-1">Description</label>
            <label className="itp-edit-number-label-2">
              Description related to this queue
            </label>
          </div>
          <input
            placeholder="Add Description"
            className="form-control-itp itp-edit-number-input"
            name="queue_description"
            value={form?.queue_description || ""}
            onChange={onChangeForm}
          />
        </div>
      </Card>

      <br></br>
      <div>
        <Row>
          <Col>
            <div className="itp-user-settings-separator-line"></div>
          </Col>
        </Row>
      </div>
      <br></br>

      <div>
        {dataTable && (
          <CustomTable
            data={dataTable}
            noPagBack={true}
            centered={width > 991 ? true : false}
          />
        )}
      </div>

      <br></br>
      <div>
        <Row>
          <Col>
            <div className="itp-user-settings-separator-line"></div>
          </Col>
        </Row>
      </div>
      <br></br>

      <h1
        className={`itp-custom-table-title mb-2${
          width > 991 ? " centered" : ""
        }`}
      >
        Advanced Settings
      </h1>

      <Card
        className={
          width > 991
            ? `itp-table-container-centered overflow-hidden itp-edit-number-card mb-4`
            : `itp-table-container-all itp-edit-number-card mb-4`
        }
      >
        <Row className="d-flex align-items-center">
          <Col xs={9} sm={9} md={9} lg={9} xl={9} xxl={9}>
            <label className="itp-edit-number-label-1">Member Timeout</label>
            <label className="itp-edit-number-label-2">
              Number of seconds to ring Member until pausing for the amount of
              seconds set in the "Retry Seconds" field
            </label>
          </Col>
          <Col xs={3} sm={3} md={3} lg={3} xl={3} xxl={3}>
            <input
              type="number"
              placeholder="change timeout"
              className="form-control-itp itp-edit-number-input"
              name="timeout"
              value={form?.timeout || ""}
              onChange={onChangeForm}
            />
          </Col>
        </Row>
        <Row className="mt-4 d-flex align-items-center">
          <Col xs={9} sm={9} md={9} lg={9} xl={9} xxl={9}>
            <label className="itp-edit-number-label-1">Retry Member In</label>
            <label className="itp-edit-number-label-2">
              Number of seconds to wait before attempting to call a member again
              after the Member Timeout was reached
            </label>
          </Col>
          <Col xs={3} sm={3} md={3} lg={3} xl={3} xxl={3}>
            <input
              type="number"
              placeholder="Change retry member in"
              className="form-control-itp itp-edit-number-input"
              name="retry"
              value={form?.retry || ""}
              onChange={onChangeForm}
            />
          </Col>
        </Row>
        <Row className="mt-4 d-flex align-items-center">
          <Col xs={9} sm={9} md={9} lg={9} xl={9} xxl={9}>
            <label className="itp-edit-number-label-1">Queue Timeout</label>
          </Col>
          <Col xs={3} sm={3} md={3} lg={3} xl={3} xxl={3}>
            <input
              type="number"
              placeholder="Change Queue Timeout"
              className="form-control-itp itp-edit-number-input"
              name="queue_timeout"
              value={form?.queue_timeout || ""}
              onChange={onChangeForm}
            />
          </Col>
        </Row>
        <Row className="mt-4 d-flex align-items-center">
          <Col xs={10} sm={10} md={10} lg={10} xl={10} xxl={10}>
            <label className="itp-edit-number-label-1">Strategy</label>
          </Col>
          <Col xs={2} sm={2} md={2} lg={2} xl={2} xxl={2}>
            <select
              className="form-select-itp itp-queue-details-select mt-2 ms-3"
              name="strategy"
              value={form?.strategy || ""}
              onChange={onChangeForm}
            >
              <option value="" disabled>
                Select Option
              </option>
              <option value="ringall">Ring all</option>
              <option value="leastrecent">Least Recent</option>
              <option value="fewestcalls">Fewest Calls</option>
              <option value="random">Random</option>
              <option value="rrmemory">RR Memory</option>
              <option value="linear">Linear</option>
              <option value="wrandom">Wrandom</option>
              <option value="rrordered">RR Ordered</option>
            </select>
          </Col>
        </Row>
        <Row className="mt-4 d-flex align-items-center">
          <Col xs={9} sm={9} md={9} lg={9} xl={9} xxl={9}>
            <label className="itp-edit-number-label-1">Wrap up Time</label>
          </Col>
          <Col xs={3} sm={3} md={3} lg={3} xl={3} xxl={3}>
            <input
              type="number"
              placeholder="Add Description"
              className="form-control-itp itp-edit-number-input"
              name="wrapuptime"
              value={form?.wrapuptime || ""}
              onChange={onChangeForm}
            />
          </Col>
        </Row>
        <Row className="mt-4 d-flex align-items-center">
          <Col xs={11} sm={11} md={11} lg={11} xl={11} xxl={11}>
            <label className="itp-edit-number-label-1">Ring in Use</label>
          </Col>
          <Col xs={1} sm={1} md={1} lg={1} xl={1} xxl={1}>
            <Switch
              className="itp-custom-table-switch"
              onColor="#626ed4"
              height={20}
              width={40}
              checked={form.ringinuse === "yes" ? true : false}
              onChange={() => onChangeSwitchs("ringinuse")}
            />
          </Col>
        </Row>
        <Row className="mt-4 d-flex align-items-center">
          <Col xs={11} sm={11} md={11} lg={11} xl={11} xxl={11}>
            <label className="itp-edit-number-label-1">Auto Pause</label>
          </Col>
          <Col xs={1} sm={1} md={1} lg={1} xl={1} xxl={1}>
            <Switch
              className="itp-custom-table-switch"
              onColor="#626ed4"
              height={20}
              width={40}
              onChange={() => onChangeSwitchs("autopause")}
              checked={form.autopause === "yes" ? true : false}
            />
          </Col>
        </Row>
        <Row className="mt-4 d-flex align-items-center">
          <Col xs={11} sm={11} md={11} lg={11} xl={11} xxl={11}>
            <label className="itp-edit-number-label-1">Join Empty</label>
          </Col>
          <Col xs={1} sm={1} md={1} lg={1} xl={1} xxl={1}>
            <Switch
              className="itp-custom-table-switch"
              onColor="#626ed4"
              height={20}
              width={40}
              onChange={() => onChangeSwitchs("joinempty")}
              checked={form.joinempty === "yes" ? true : false}
            />
          </Col>
        </Row>
        <Row className="mt-4 d-flex align-items-center">
          <Col xs={11} sm={11} md={11} lg={11} xl={11} xxl={11}>
            <label className="itp-edit-number-label-1">Leave When Empty</label>
          </Col>
          <Col xs={1} sm={1} md={1} lg={1} xl={1} xxl={1}>
            <Switch
              className="itp-custom-table-switch"
              onColor="#626ed4"
              height={20}
              width={40}
              onChange={() => onChangeSwitchs("leavewhenempty")}
              checked={form.leavewhenempty === "yes" ? true : false}
            />
          </Col>
        </Row>
        <Row className="mt-4 d-flex align-items-center">
          <Col xs={11} sm={11} md={11} lg={11} xl={11} xxl={11}>
            <label className="itp-edit-number-label-1">
              Ring instead of Playing Music
            </label>
          </Col>
          <Col xs={1} sm={1} md={1} lg={1} xl={1} xxl={1}>
            <Switch
              className="itp-custom-table-switch"
              onColor="#626ed4"
              height={20}
              width={40}
              onChange={() => onChangeSwitchs("queue_args")}
              checked={form.queue_args === "r" ? true : false}
            />
          </Col>
        </Row>
      </Card>

      <MenuRight
        show={openMenu}
        closeMenu={closeModal}
        title="Add Member"
        to=""
      >
        {openMenu && (
          <>
            <label className="itp-label-input__label">Users</label>
            <Select
              isMulti
              onChange={onChangeUsers}
              className="basic-single mb-4"
              classNamePrefix="select"
              value={selectedUsers}
              isSearchable={true}
              placeholder="Select User(s)"
              options={users}
            />
            <button
              onClick={addUsers}
              className="btn-primary itp-lead-detail-button-save loading"
              disabled={disabledButton()}
            >
              {loadingUser && <LoadingButton />}
              Save
            </button>
          </>
        )}
      </MenuRight>
    </>
  );
};

export default QueueDetails;
