import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import {
  faArrowTrendUp,
  faPhoneVolume,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import { ToggleImage, Loading } from "../../../components/index";
import petitionGet from "../../../services/petitionGet";
import Chart from "./Chart";
import userMyExtension from "../../../assets/icons/userMyExtension.svg";
import notificationStatus from "../../../assets/icons/notificationStatus.svg";
import "./Dashboard.css";

function Dashboard() {
  const myExtension = useSelector((state) => state.myExtension.userDetails);

  const [loading, setLoading] = useState(true);
  const [dataX, setDataX] = useState([]);
  const [dataY, setDataY] = useState([]);
  const [firstLoad, setFirstLoad] = useState(true);
  const [voicemails, setVoicemails] = useState(0)

  const returnDataY = (data) => {
    return data.map((element) => {
      let date = new Date(parseInt(element.split(".")[0]) * 1000);
      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    });
  };

  const petition = async () => {
    setLoading(true);

    await petitionGet("voicemailDetails", { parameter: "?pagination=false" })
      .then(({ data: result }) => {
        let totalVoicemails = 0;
        Object.keys(result.result).forEach((element) => {
          let voicemails = result.result[element];
          totalVoicemails = totalVoicemails + voicemails.length;
        });

        setVoicemails(totalVoicemails);
      })
      .catch((error) => console.log(error));

    await petitionGet("dashboard")
      .then(({ data: result }) => {
        const dataxTemp = Object.values(result.result.call_count).reverse();
        const datayTemp = returnDataY(Object.keys(result.result.call_count));

        setDataX(dataxTemp);
        setDataY(datayTemp);

        setLoading(false);
        setFirstLoad(false);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if (Object.keys(myExtension).length > 0 && firstLoad) petition();
  }, [myExtension]);

  return loading ? (
    <Loading />
  ) : (
    <>
      <div className="mt-4">
        <div className="row">
          <div className="col-7">
            <div className="row">
              <div className="col-4">
                <div className="board ms-4 shadow-sm p-3 mb-5 bg-body rounded">
                  <div className="row">
                    <div className="col-10 d-flex">
                      <div
                        className="rounded-pill d-flex justify-content-center align-items-center"
                        style={{
                          width: "30px",
                          height: "30px",
                          background: "#3257C31F",
                        }}
                      >
                        <img src={userMyExtension} alt="" />
                      </div>
                      <div className="ms-2 pt-1">
                        <p>My Extension</p>
                      </div>
                    </div>
                    <div className="col-2">
                      <ToggleImage />
                    </div>
                  </div>
                  <div>
                    <h5>{myExtension.presence_id}</h5>
                  </div>
                  <div>
                    <p>
                      {/* <FontAwesomeIcon
                        icon={faArrowTrendUp}
                        style={{ color: "green" }}
                        className="me-1"
                      ></FontAwesomeIcon>
                      8 % increased than average */}
                    </p>
                  </div>
                </div>
              </div>
              <div className="ms-5 col-4">
                <div className="board ms-4 shadow-sm p-3 mb-5 bg-body rounded">
                  <div className="row">
                    <div className="col-10 d-flex">
                      <div
                        className="rounded-pill d-flex justify-content-center align-items-center"
                        style={{
                          width: "30px",
                          height: "30px",
                          background: "#3257C31F",
                        }}
                      >
                        {/* <FontAwesomeIcon icon={faPhoneVolume}></FontAwesomeIcon> */}
                        <img src={notificationStatus} alt="" />
                      </div>
                      <div className="ms-2 pt-1">
                        <p>Voicemails</p>
                      </div>
                    </div>
                    <div className="col-2">
                      <ToggleImage />
                    </div>
                  </div>
                  <div>
                    <h5>{voicemails}</h5>
                  </div>
                  <div>
                    <p>
                      {/* <FontAwesomeIcon
                        icon={faArrowTrendUp}
                        style={{ color: "green" }}
                        className="me-1"
                      ></FontAwesomeIcon> */}
                      {/* 8 % increased than average */}
                      Total Voicemails
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-5"></div>
        </div>
      </div>
      <Row className="mb-5 ms-3">
        <Col xs={11} sm={11} md={11} lg={11} xl={11} xxl={11}>
          <Card>
            <CardBody className="itp-dashboard-chart__card-body">
              <h5 className="ms-4">Calls Stats</h5>
              <p className="ms-4">Last 30 days</p>
              <Chart dataX={dataX} dataY={dataY} />
            </CardBody>
          </Card>
        </Col>
        {/* <Col xs={5} sm={5} md={5} lg={5} xl={5} xxl={5}></Col> */}
      </Row>
    </>
  );
}

export default Dashboard;
