import React from "react";
import { Row, Col } from "reactstrap";
import PhoneInput from "react-phone-input-2";
import Select from "react-select";
import "react-phone-input-2/lib/style.css";
import "./Manually.css";

const Manually = ({
  form,
  setForm,
  contactList,
  valueContactList,
  setValueContactList,
  onChangeForm,
}) => {
  return (
    <>
      <Row className="itp-container-label-input">
        <Col
          xs={5}
          sm={5}
          md={5}
          lg={5}
          xl={5}
          xxl={5}
          className="itp-label-input-col"
        >
          <label className="itp-label-input__label">Contact Name</label>
        </Col>
        <Col
          xs={7}
          sm={7}
          md={7}
          lg={7}
          xl={7}
          xxl={7}
          className="itp-label-input-col"
        >
          <input
            type="text"
            placeholder=": Add a name of contact"
            className="form-control-itp itp-label-input__input"
            onChange={onChangeForm}
            name="contactname"
          />
        </Col>
      </Row>
      <Row className="itp-container-label-input">
        <Col
          xs={5}
          sm={5}
          md={5}
          lg={5}
          xl={5}
          xxl={5}
          className="itp-label-input-col"
        >
          <label className="itp-label-input__label">E-mail</label>
        </Col>
        <Col
          xs={7}
          sm={7}
          md={7}
          lg={7}
          xl={7}
          xxl={7}
          className="itp-label-input-col"
        >
          <input
            type="text"
            placeholder=": Enter email address"
            className="form-control-itp itp-label-input__input"
            onChange={onChangeForm}
            name="email"
          />
        </Col>
      </Row>
      <label className="itp-add-contact-manually-label">Phone Number</label>
      <PhoneInput
        disableCountryFlags={true}
        enableSearch={true}
        countryCodeEditable={false}
        country={"us"}
        placeholder="Ingrese su número de teléfono"
        className="itp-add-contact-phone-input"
        onChange={(e) => {
          setForm({ ...form, phone: `+${e}` });
        }}
      />

      <label className="itp-add-contact-manually-label">Contact List</label>
      <Select
        onChange={(e) => {
          setForm({ ...form, contact_list_id: e.value });
          setValueContactList(e);
        }}
        className="basic-single itp-add-contact-manually-select"
        classNamePrefix="select"
        value={valueContactList}
        isSearchable={true}
        name="contact_list_id"
        placeholder="Select Option"
        options={contactList}
      />
    </>
  );
};

export default Manually;

{
  /* <div className="itp-container-input">
  <span className="input-icon"></span>
  <input
    style={{ border: "0" }}
    type="text"
    placeholder=": Add a name of contact"
    className="form-control-itp"
  />{" "}
</div> */
}
